import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconShirt: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-16 w-[18px] stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Shirt Icon</title>
      <path d="M11.0312 15.5579v-3.7127h4.0411v3.7127M9.0938 15.5581v-11.58" />
      <path d="M13.1343 2.2983l4.1252 1.2376-.7576 12.0221H1.1796L.4219 3.536l4.462-1.2377" />
      <path d="M11.7869.7954c0 1.5912-1.2628 2.9171-2.7782 2.9171S6.2305 2.3865 6.2305.7955" />
      <path d="M9.0922 4.0662l-2.694 2.1216-1.5154-3.8896L6.4824.442h5.0513l1.5996 1.8563-1.347 3.8896-2.694-2.1216z" />
    </svg>
  );
};

export default IconShirt;
