import { observable, action, computed } from 'mobx';
import { OwnerForm } from '../types';

class OwnerFormStore {
  @observable firstName: OwnerForm['firstName'] = '';
  @observable lastName: OwnerForm['lastName'] = '';
  @observable phone: OwnerForm['phone'] = '';
  @observable email: OwnerForm['email'] = '';

  @computed get owner(): OwnerForm {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      email: this.email,
    };
  }

  @action update = <K extends keyof OwnerForm>(field: K, value: OwnerForm[K]): void => {
    this[field] = value as any;
  };

  @action reset = () => {
    this.firstName = undefined;
    this.lastName = undefined;
    this.phone = undefined;
    this.email = undefined;
  };
}

const singleton = new OwnerFormStore();
export default singleton;
