import React from 'react';

interface IconPersonProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconPersonProps>;

const IconPerson: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Person</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10371 3.39887C5.68558 3.92422 5.5 4.56482 5.5 5C5.5 6.43889 5.81327 7.30093 6.24159 7.79898C6.65782 8.28297 7.24985 8.5 8 8.5C8.68099 8.5 9.28238 8.26977 9.72142 7.76088C10.1678 7.24347 10.5 6.37548 10.5 5C10.5 3.89749 9.65128 2.5 8 2.5C7.12093 2.5 6.50857 2.8902 6.10371 3.39887ZM5.32129 2.77613C5.89143 2.0598 6.77907 1.5 8 1.5C10.3487 1.5 11.5 3.50251 11.5 5C11.5 6.52452 11.1322 7.65653 10.4786 8.41412C9.81762 9.18023 8.91901 9.5 8 9.5C7.05015 9.5 6.14218 9.21703 5.48341 8.45102C4.83673 7.69907 4.5 6.56111 4.5 5C4.5 4.33518 4.76442 3.47578 5.32129 2.77613Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 14C2.5 10.6919 5.25691 8.5 8 8.5C10.7431 8.5 13.5 10.6919 13.5 14V14.5H2.5V14ZM3.52636 13.5H12.4736C12.2162 11.0866 10.1141 9.5 8 9.5C5.88585 9.5 3.78383 11.0866 3.52636 13.5Z"
      />
    </svg>
  );
};

export default IconPerson;
