import {
  Member,
  Item,
  PotentialMember,
  GTEvent,
  GtEventType,
  PartyRole,
  Order,
  Shipment,
  Look,
  Bundle,
  BlockoutDate,
  Product,
} from '../types';
import { convertObjectToFormData, getItemsToAddFromProductsAndBundles } from '../utils/utils';
import fetchMiddleware from '../utils/fetchMiddleware';
import { eventsUrl, omniUrl } from './urls';
import { getDataFromResponse, GraphQLResponse } from '../utils/GraphQL';

export const createEventv2 = async (
  accountId: string,
  startDate: string,
  name: string,
  type: string,
  partyRoleId: number,
  source: string,
  usedDefaultEventDate: boolean
) =>
  fetch(`/api/v2/create-event`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({
      accountId,
      startDate,
      name,
      type,
      partyRoleId,
      source,
      usedDefaultEventDate,
    }),
  });

export const getEventsAndPrimaryEventId = async (accountId: string) =>
  fetch(`/api/get-events-and-primary-event-id`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({
      accountId,
    }),
  });

export const updateEvent = async (event: { eventId: string; name: string; start_date: string }) =>
  fetchMiddleware(`${eventsUrl()}/v2/events/${event.eventId}`, {
    method: 'PUT',
    credentials: 'same-origin',
    body: JSON.stringify({ start_date: event.start_date, name: event.name }),
  });

export const getPrimaryEventDetails = async () =>
  fetchMiddleware(`${omniUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: getPrimaryEventDetailsQuery,
      variables: {
        id: window.gt.user.id,
      },
    }),
  });

export const getCheckoutEventDetails = async (eventId: string) =>
  fetchMiddleware(`${omniUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: checkoutEventDetails,
      variables: {
        id: eventId,
      },
    }),
  });

export const updateOutfit = async (eventId: string, roleId: string, name: string) =>
  fetchMiddleware(`${eventsUrl()}/v2/events/${eventId}/roles/${roleId}`, {
    method: 'PUT',
    body: JSON.stringify({ accountId: window.gt.user.id, name }),
  });

export const getEventDetails = async (eventId: string) =>
  fetchMiddleware(`${omniUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: getEventDetailsQuery,
      variables: {
        id: eventId,
      },
    }),
  });

export const addRole = async (eventId: string, roleName: string) =>
  fetchMiddleware(`${eventsUrl()}/v2/events/${eventId}/roles`, {
    method: 'POST',
    body: JSON.stringify({ name: roleName }),
  });

export const updateMember = async (memberId: string, roleId: string) =>
  fetchMiddleware(`${eventsUrl()}/v3/members/${memberId}`, {
    method: 'PUT',
    body: JSON.stringify({ role_id: roleId }),
  });

export const updateOutfitItems = async (
  id: string,
  memberId: string,
  productsToAdd: number[],
  productsToDelete: number[],
  bundleToAdd?: number,
  bundleToDelete?: number
) =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: updateRoleMutation,
      variables: {
        input: {
          id,
          memberId,
          productsToAdd,
          productsToDelete,
          bundleToAdd,
          bundleToDelete,
        },
      },
    }),
  });

export const createHTOEvent = async (
  eventDate: string,
  eventName: string,
  eventType: string,
  role: string,
  itemsToAdd: Array<Item>,
  source?: string
) => {
  const body: {} = {
    eventDate,
    eventName,
    eventType,
    role,
    source,
    ...getItemsToAddFromProductsAndBundles(itemsToAdd),
  };
  return fetch(`/api/hto/create-event`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(body),
  });
};

export const getMemberships = async (customerId: string): Promise<Membership[]> => {
  const response: GraphQLResponse<GetMembershipsQueryResponse> = await fetchMiddleware(`${omniUrl()}/graphql`, {
    method: 'POST',
    body: JSON.stringify({
      query: getMembershipsQuery,
      variables: {
        customerId,
        orgId: Number(process.env.REACT_APP_ORGANIZATION_ID),
      },
    }),
  });

  const data = await getDataFromResponse(response);

  if (!data.customer?.members) {
    throw new Error(`Expected an array of memberships but got ${typeof data.customer?.members} instead`);
  }

  return data.customer.members;
};

export const getShareLookCode = async (eventId: string, lookId: string): Promise<Response> =>
  fetch(`/api/${eventId}/${lookId}/share-url`, {
    method: 'PUT',
  });

export const addMember = async (eventId: string, member: Member, roleId: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/v2/members?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      first_name: member.customer!.firstName,
      last_name: member.customer!.lastName,
      email: member.customer!.email,
      phone: member.customer!.phone,
      event_id: eventId,
      role_id: roleId,
    }),
  });

export const addInvitedMember = async (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  eventId: string,
  roleId: string,
  potentialMemberId: string
): Promise<Response> =>
  fetch(`/api/add-invited-member`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      eventId: eventId,
      roleId: roleId,
      potentialMemberId: potentialMemberId,
    }),
  });

export const getMember = async (memberId: string): Promise<Response> =>
  fetchMiddleware(`${omniUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: getMemberQuery,
      variables: {
        memberId,
      },
    }),
  });

export const getPartyRoles = async (): Promise<Response> =>
  fetchMiddleware(`${omniUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: getPartyRolesQuery,
    }),
  });

export const createPotentialMember = async (
  nickname: string,
  partyRoleId: number,
  roleId: string,
  gtEventId: string,
  memberId?: string
): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: createPotentialMemberMutation,
      variables: {
        input: {
          nickname,
          partyRoleId,
          roleId,
          gtEventId,
          memberId,
        },
      },
    }),
  });

export const getShortCode = async (code: string): Promise<Response> =>
  fetch(`/api/short-code`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({ code }),
  });

export const createShortCode = async (gtEventId: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: createShortCodeMutation,
      variables: {
        input: {
          gtEventId,
        },
      },
    }),
  });

export const sendInviteLinkToMember = async (memberId: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: sendLinkToMemberMutation,
      variables: {
        input: {
          memberId,
        },
      },
    }),
  });

export const sendMemberInvites = async (emails: string, eventId: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: sendMemberInvitesMutation,
      variables: {
        input: {
          emails,
          eventId,
        },
      },
    }),
  });

export const updatePotentialMember = async (
  id: string,
  input: { nickname?: string; roleId?: string; partyRoleId?: number; memberId?: string }
): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: updatePotentialMemberMutation,
      variables: {
        id,
        input,
      },
    }),
  });

export const deletePotentialMember = async (potentialMemberId: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: deletePotentialMemberMutation,
      variables: {
        id: potentialMemberId,
      },
    }),
  });

export const joinEvent = async (potentialMemberId: string, accountId: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: joinEventMutation,
      variables: {
        input: {
          potentialMemberId,
          accountId,
        },
      },
    }),
  });

export const getLook = async (lookId: string): Promise<Response> =>
  fetchMiddleware(`${omniUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: getLookQuery,
      variables: {
        lookId,
      },
    }),
  });

export const addLook = async (lookName: string, eventId: string): Promise<Response> =>
  fetch('/api/add-role', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({
      eventId,
      roleName: lookName,
    }),
  });

export const deleteLook = async (lookId: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: deleteLookMutation,
      variables: {
        id: lookId,
      },
    }),
  });

export const addOwner = async (owner: Member, eventId: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/v2/members/owners`, {
    method: 'POST',
    body: JSON.stringify({
      organization_id: window.gt.orgId,
      first_name: owner.customer!.firstName!,
      last_name: owner.customer!.lastName!,
      email: owner.customer!.email!,
      phone: owner.customer!.phone!,
      event_id: eventId,
    }),
  });

export const updateOwnership = async (memberId: string, isOwner: boolean): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/v3/members/${memberId}`, {
    method: 'PUT',
    body: JSON.stringify({
      is_owner: isOwner,
      ...(isOwner && { is_invited: true }),
    }),
  });

export const deleteMember = async (id: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: deleteMemberMutation,
      variables: {
        id,
      },
    }),
  });

export const deleteOwner = async (id: string): Promise<Response> =>
  fetch(`/api/delete-owner`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({ id }),
  });

export const deleteEvent = async (id: string): Promise<Response> =>
  fetch(`/api/delete-event`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({ id, accountId: window.gt.user.id }),
  });

export const getInvitedMemberDetails = async (eventId: string): Promise<Response> =>
  fetch(`/api/invited-member-details`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({ eventId }),
  });

export const getMembersInEvent = async (id: string): Promise<Response> =>
  fetchMiddleware(`${omniUrl()}/graphql`, {
    method: 'POST',
    body: JSON.stringify({
      query: getMembersInEventQuery,
      variables: {
        id,
      },
    }),
  });

export const updateOwnerRole = async (memberId: string, partyRoleName: string): Promise<Response> =>
  fetchMiddleware(`${eventsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: updateOwnerRoleMuation,
      variables: {
        input: {
          memberId,
          partyRoleName,
        },
      },
    }),
  });

export const getUnclaimedPotentialMembers = (potentialMembers: PotentialMember[]) =>
  potentialMembers.filter(
    ({ member }) => member == null || (!member.isOwner && !member.customer?.isRegistrationComplete)
  );

export const getClaimedPotentialMembers = (potentialMembers: PotentialMember[]) =>
  potentialMembers.filter(({ member }) => member != null && !member.isOwner && member.customer?.isRegistrationComplete);

const getPrimaryEventDetailsQuery = `query fetchCustomerAndPrimaryEvent($id: String!) {
  customer(customerId: $id) {
    id
    firstName
    lastName
    phone
    primaryEvent {
      id
      startDate
      name
      type
      status
      isTrial
      shortCode {
        id
        code
      }
      members {
        id
        isPaid
        isOwner
        isCreator
        isShipped
        isMeasured
        isSignedIn
        isInvited
        accountId
        role {
          id
          name
          bundles(organizationId: ${window.gt.orgId}) {
            id: bundleId
            category
            name
            displayName
            cost
            looks {
              id: bundleId
              type
              category
              displayName
              shortDescription
              cost
              isActive
              rentalTerms
              displayIndex
              color
              availableInSlim
              availableInBoys
              urlSlug
              blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
                id
                availability
                blockableType
                startDate
                endDate
              }
              details {
                id
                detail
              }
              products {
                id
                displayable
                pattern
                urlSlug
                cost: costToCustomer
                sku: catalogNumber
                displayName: catalogName
                type: availability
                category: type
                color: colorGroup
                shortDescription: catalogShortDescription
                rentalTerms: catalogRentalTerms
                isActive
                displayIndex
                attributePrimary
                attributeSecondary
                attributeTertiary
                blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
                  id
                  availability
                  blockableType
                  startDate
                  endDate
                }
                details {
                  id
                  detail
                }
                media {
                  description
                  label
                  url
                  displayIndex
                }
              }
            }
            blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              availability
              startDate
              endDate
            }
            media {
              label
              url
            }
          }
          items: products(organizationId: ${window.gt.orgId}) {
            id
            catalogNumber
            color
            description
            category: type
            displayName: catalogName
            cost: costToCustomer
            blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              startDate
              endDate
            }
            media {
              label
              url
            }
          }
        }
        customer {
          id
          firstName
          lastName
          phone
          email
          primaryEventId
          isRegistrationComplete
        }
      }
      roles {
        id
        name
        bundles(organizationId: ${window.gt.orgId}) {
          id: bundleId
          category
          displayName
          cost
          blockoutDates(organizationId: ${window.gt.orgId}) {
            id
            availability
            startDate
            endDate
          }
          media {
            label
            url
          }
        }
        items: products(organizationId: ${window.gt.orgId}) {
          id
          catalogNumber
          color
          description
          category: type
          displayName: catalogName
          cost: costToCustomer
          blockoutDates(organizationId: ${window.gt.orgId}) {
            id
            startDate
            endDate
          }
          media {
            label
            url
          }
        }
        members {
            id
            isPaid
            isOwner
            isCreator
            isShipped
            isMeasured
            isSignedIn
            isInvited
            accountId
        }
      }
      potentialMembers {
        id
        nickname
        partyRole {
          id
          name
          isRenting
        }
        role {
          id
          name
        }
        member {
          id
          isPaid
          isOwner
          isCreator
          customer {
            id
            firstName
            lastName
            phone
            email
            primaryEventId
            isRegistrationComplete
            addresses {
              id
            }
          }
        }
      }
    }
  }
}`;

const getEventDetailsQuery = `query event($id: String!) {
  event(id: $id) {
    allRoles: roles (filterDeleted: false) {
      deletedAt
      id
      name
    }
    clonedEvent {
      id
      members {
        accountId
        id
      }
    }
    gtEventType {
      id
      name
    }
    id
    isTrial
    lastCampaign {
      attributions {
        key
        value
      }
    }
    members {
      accountId
      customer {
        addresses {
          id
        }
        email
        firstName
        id
        isRegistrationComplete
        lastName
        phone
        primaryEventId
      }
      id
      isCreator
      isInvited
      isMeasured
      isOwner
      isPaid
      isShipped
      isSignedIn
      orders {
        id
        isSwatch
        isTrial
        shipment {
          cartons {
            trackingUrl
            type
          }
        }
        status
      }
      role {
        bundles(organizationId: ${window.gt.orgId}) {
          blockoutDates(organizationId: ${window.gt.orgId}) {
            availability
            endDate
            id
            startDate
          }
          category
          cost
          displayName
          id: bundleId
          looks {
            id: bundleId
            type
            category
            displayName
            shortDescription
            cost
            isActive
            rentalTerms
            displayIndex
            color
            availableInSlim
            availableInBoys
            urlSlug
            blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              availability
              blockableType
              startDate
              endDate
            }
            details {
              id
              detail
            }
            products {
              id
              displayable
              pattern
              urlSlug
              cost: costToCustomer
              sku: catalogNumber
              displayName: catalogName
              type: availability
              category: type
              color: colorGroup
              shortDescription: catalogShortDescription
              rentalTerms: catalogRentalTerms
              isActive
              displayIndex
              attributePrimary
              attributeSecondary
              attributeTertiary
              blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
                id
                availability
                blockableType
                startDate
                endDate
              }
              details {
                id
                detail
              }
              media {
                description
                label
                url
                displayIndex
              }
            }
          }
          media {
            label
            url
          }
          name
          products {
            category: type
            id
            sku: catalogNumber
          }
          swatch {
            id
            catalogNumber
            blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              startDate
              endDate
            }
          }
          type
        }
        id
        items: products(organizationId: ${window.gt.orgId}) {
          blockoutDates(organizationId: ${window.gt.orgId}) {
            endDate
            id
            startDate
          }
          catalogNumber
          category: type
          color
          cost: costToCustomer
          description
          displayName: catalogName
          id
          media {
            label
            url
          }
          swatch {
            blockoutDates(organizationId: ${window.gt.orgId}) {
              endDate
              id
              startDate
            }
            catalogNumber
            id
          }
        }
        name
      }
      orders {
          status
          isSwatch
      }
      potentialMember {
        id
        member {
          customer {
            addresses {
              id
            }
            email
            firstName
            id
            isRegistrationComplete
            lastName
            phone
            primaryEventId
          }
          id
          isCreator
          isOwner
          isPaid
        }
        nickname
        partyRole {
          id
          isRenting
          name
        }
        role {
          id
          name
        }
      }
    }
    name
    potentialMembers {
      id
      nickname
      member {
        customer {
          addresses {
            id
          }
          email
          firstName
          id
          isRegistrationComplete
          lastName
          phone
          primaryEventId
        }
        id
        isCreator
        isOwner
        isPaid
      }
      partyRole {
        id
        isRenting
        name
      }
      role {
        id
        name
      }
    }
    roles {
      bundles(organizationId: ${window.gt.orgId}) {
        blockoutDates(organizationId: ${window.gt.orgId}) {
          availability
          endDate
          id
          startDate
        }
        category
        cost
        displayName
        id: bundleId
        isRetail
        looks {
          availableInBoys
          availableInSlim
          blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
            availability
            blockableType
            endDate
            id
            startDate
          }
          category
          color
          cost
          details {
            detail
            id
          }
          displayIndex
          displayName
          id: bundleId
          isActive
          products {
            attributePrimary
            attributeSecondary
            attributeTertiary
            blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
              availability
              blockableType
              endDate
              id
              startDate
            }
            category: type
            color: colorGroup
            cost: costToCustomer
            details {
              detail
              id
            }
            displayable
            displayIndex
            displayName: catalogName
            id
            isActive
            media {
              description
              displayIndex
              label
              url
            }
            pattern
            rentalTerms: catalogRentalTerms
            shortDescription: catalogShortDescription
            sku: catalogNumber
            type: availability
            urlSlug
          }
          rentalTerms
          shortDescription
          type
          urlSlug
        }
        media {
          label
          url
        }
        products {
          category: type
          id
          sku: catalogNumber
        }
        swatch {
          blockoutDates(organizationId: ${window.gt.orgId}) {
            endDate
            id
            startDate
          }
          catalogNumber
          id
        }
        type
      }
      deletedAt
      id
      items: products(organizationId: ${window.gt.orgId}) {
        blockoutDates(organizationId: ${window.gt.orgId}) {
          endDate
          id
          startDate
        }
        catalogNumber
        category: type
        color
        cost: costToCustomer
        description
        displayName: catalogName
        id
        media {
          label
          url
        }
        swatch {
          blockoutDates(organizationId: ${window.gt.orgId}) {
            endDate
            id
            startDate
          }
          catalogNumber
          id
        }
        type: availability
      }
      members {
        accountId
        id
        isCreator
        isInvited
        isMeasured
        isOwner
        isPaid
        isShipped
        isSignedIn

        orders {
          isSwatch
          status
        }
        potentialMember {
          id
          member {
            customer {
              addresses {
                id
              }
              email
              firstName
              id
              isRegistrationComplete
              lastName
              phone
              primaryEventId
            }
            id
            isCreator
            isOwner
            isPaid
          }
          nickname
          partyRole {
            gtEventType {
              id
              name
            }
            id
            isRenting
            name
          }
          role {
            id
            name
          }
        }
      }
      name
      potentialMembers {
        id
        nickname
        member {
          customer {
            addresses {
              id
            }
            email
            firstName
            id
            isRegistrationComplete
            lastName
            phone
            primaryEventId
          }
          id
          isCreator
          isOwner
          isPaid
        }
        partyRole {
          id
          isRenting
          name
        }
        role {
          id
          name
        }
      }
      shareLinkCode
    }
    shortCode {
      code
      id
    }
    startDate
    status
    type
  }
}`;

const getMemberQuery = `query ($memberId: String!) {
  member(id: $memberId) {
    id
    isPaid
    isOwner
    isCreator
    isShipped
    isMeasured
    isSignedIn
    isInvited
    accountId
    orders {
        isSwatch
        isTrial
        status
        shipment {
            cartons {
                type
                trackingUrl
            }
        }
    }
    role {
      id
      name
      bundles(organizationId: ${window.gt.orgId}) {
        id: bundleId
        category
        name
        displayName
        cost
        looks {
          id: bundleId
          type
          category
          displayName
          shortDescription
          cost
          isActive
          rentalTerms
          displayIndex
          color
          availableInSlim
          availableInBoys
          urlSlug
          blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
            id
            availability
            blockableType
            startDate
            endDate
          }
          details {
            id
            detail
          }
          products {
            id
            displayable
            pattern
            urlSlug
            cost: costToCustomer
            sku: catalogNumber
            displayName: catalogName
            type: availability
            category: type
            color: colorGroup
            shortDescription: catalogShortDescription
            rentalTerms: catalogRentalTerms
            isActive
            displayIndex
            attributePrimary
            attributeSecondary
            attributeTertiary
            blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              availability
              blockableType
              startDate
              endDate
            }
            details {
              id
              detail
            }
            media {
              description
              label
              url
              displayIndex
            }
          }
        }
        swatch {
          id
          catalogNumber
          blockoutDates(organizationId: ${window.gt.orgId}) {
            id
            startDate
            endDate
          }
        }
        type
        products {
          id
          sku: catalogNumber
        }
        blockoutDates(organizationId:${window.gt.orgId}) {
          id
          availability
          startDate
          endDate
        }
        media {
          label
          url
        }
      }
      items: products(organizationId: ${window.gt.orgId}) {
        id
        catalogNumber
        color
        description
        category: type
        displayName: catalogName
        cost: costToCustomer
        swatch {
          id
          catalogNumber
          blockoutDates(organizationId: ${window.gt.orgId}) {
            id
            startDate
            endDate
          }
        }
        blockoutDates(organizationId: ${window.gt.orgId}) {
          id
          startDate
          endDate
        }
        media {
          label
          url
        }
      }
    }
    customer {
      id
      firstName
      lastName
      phone
      email
      primaryEventId
      isRegistrationComplete
      addresses {
        id
      }
    }
    orders {
        status
        isSwatch
    }
    potentialMember {
      id
      nickname
      partyRole {
        id
        name
        isRenting
      }
      role {
        id
        name
      }
      member {
        id
        isPaid
        isOwner
        isCreator
        customer {
          id
          firstName
          lastName
          phone
          email
          primaryEventId
          isRegistrationComplete
          addresses {
            id
          }
        }
      }
    }
  }
}`;

const getLookQuery = `query ($lookId: String!) {
  role(id: $lookId)  {
    id
    name
    bundles(organizationId: ${window.gt.orgId}) {
      id: bundleId
      category
      displayName
      cost
      isRetail
      swatch {
        id
        catalogNumber
      }
      type
      products {
        id
        sku: catalogNumber
        category: type
      }
      blockoutDates(organizationId: ${window.gt.orgId}) {
        id
        availability
        startDate
        endDate
      }
      media {
        label
        url
      }
      looks {
        id: bundleId
        type
        category
        displayName
        shortDescription
        cost
        isActive
        rentalTerms
        displayIndex
        color
        availableInSlim
        availableInBoys
        urlSlug
        blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
          id
          availability
          blockableType
          startDate
          endDate
        }
        details {
          id
          detail
        }
        products {
          id
          displayable
          pattern
          urlSlug
          cost: costToCustomer
          sku: catalogNumber
          displayName: catalogName
          type: availability
          category: type
          color: colorGroup
          shortDescription: catalogShortDescription
          rentalTerms: catalogRentalTerms
          isActive
          displayIndex
          attributePrimary
          attributeSecondary
          attributeTertiary
          blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
            id
            availability
            blockableType
            startDate
            endDate
          }
          details {
            id
            detail
          }
          media {
            description
            label
            url
            displayIndex
          }
        }
      }
    }
    items: products(organizationId: ${window.gt.orgId}) {
      id
      catalogNumber
      color
      description
      category: type
      displayName: catalogName
      cost: costToCustomer
      swatch {
        id
        catalogNumber
        blockoutDates(organizationId: ${window.gt.orgId}) {
          id
          startDate
          endDate
        }
      }
      blockoutDates(organizationId: ${window.gt.orgId}) {
        id
        startDate
        endDate
      }
      media {
        label
        url
      }
    }
    members {
        id
        isPaid
        isOwner
        isCreator
        isShipped
        isMeasured
        isSignedIn
        isInvited
        accountId
        orders {
            status
            isSwatch
        }
        potentialMember {
          id
          nickname
          partyRole {
            id
            name
            isRenting
          }
          role {
            id
            name
          }
          member {
            id
            isPaid
            isOwner
            isCreator
          }
        }
    }
    potentialMembers {
      id
      nickname
      partyRole {
        id
        name
        isRenting
      }
      role {
        id
        name
      }
      member {
        id
        isPaid
        isOwner
        isCreator
        customer {
          id
          firstName
          lastName
          phone
          email
          primaryEventId
          isRegistrationComplete
          addresses {
            id
          }
        }
      }
    }
  }
}`;

const checkoutEventDetails = `
query event($id: String!) {
  event(id: $id) {
    id
    startDate
    name
    members {
      id
      isPaid
      isOwner
      isCreator
      isShipped
      isMeasured
      accountId
      role {
        id
        name
        bundles(organizationId: ${window.gt.orgId}) {
          id: bundleId
          category
          name
          displayName
          cost
          isRetail
          media {
            label
            url
          }
          products {
              id
              cost: costToCustomer
              sku: catalogNumber
              category: type
              name: catalogName
              displayName: catalogName
              type
              media {
                  url
                  label
              }
          }
          looks {
            id: bundleId
            type
            category
            displayName
            shortDescription
            cost
            isActive
            rentalTerms
            displayIndex
            color
            availableInSlim
            availableInBoys
            urlSlug
            blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              availability
              blockableType
              startDate
              endDate
            }
            details {
              id
              detail
            }
            products {
              id
              displayable
              pattern
              urlSlug
              cost: costToCustomer
              sku: catalogNumber
              displayName: catalogName
              type: availability
              category: type
              color: colorGroup
              shortDescription: catalogShortDescription
              rentalTerms: catalogRentalTerms
              isActive
              displayIndex
              attributePrimary
              attributeSecondary
              attributeTertiary
              blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
                id
                availability
                blockableType
                startDate
                endDate
              }
              details {
                id
                detail
              }
              media {
                description
                label
                url
                displayIndex
              }
            }
          }
        }
      }
      customer {
        id
        firstName
        lastName
        phone
        email
        isRegistrationComplete
      }
      productToMember {
          isPaidFor
          isDeleted
          product(organizationId: ${window.gt.orgId}) {
            id
            cost: costToCustomer
            sku: catalogNumber
            catalogNumber
            displayName: catalogName
            type: availability
            category: type
            isActive
            displayIndex
            media {
              description
              label
              url
              displayIndex
            }
            
          }
      }
    }
  }
}
`;

const getMembershipsQuery = `query ($customerId: String!, $orgId: Int!) {
  customer(customerId: $customerId) {
    members {
      id
      isReturned
      isOwner
      isCreator
      potentialMember {
        id
        nickname
        partyRole {
          name
        }
      }
      gtEvent {
        id
        name
        isTrial
        startDate
        gtEventType {
          name
        }
        roles {
          id
          bundles(organizationId: $orgId) {
            id: bundleId
            isRetail
            blockoutDates(organizationId: $orgId) {
              startDate
              endDate
            }
          }
          products(organizationId: $orgId) {
            id
          }
        }
      }
      orders {
        status
        isTrial
        shipment {
          status
        }
      }
    }
  }
}`;

type GetMembershipsQueryResponse = {
  customer: {
    members: Membership[];
  };
};

export type Membership = Required<Pick<Member, 'id' | 'isOwner' | 'isCreator' | 'isReturned'>> & {
  gtEvent: GetMembershipsEventSlice;
  potentialMember: GetMembershipsPotentialMemberSlice | null;
  orders: GetMembershipsOrderSlice[];
};

type GetMembershipsEventSlice = Required<Pick<GTEvent, 'id' | 'name' | 'isTrial' | 'startDate'>> & {
  gtEventType: Pick<GtEventType, 'name'> | null;
  roles: GetMembershipsLooksSlice[];
};

type GetMembershipsLooksSlice = Required<Pick<Look, 'id'>> & {
  bundles: GetMembershipsBundleSlice[];
  products: GetMembershipsProductSlice[];
};

type GetMembershipsBundleSlice = Required<Pick<Bundle, 'id' | 'isRetail' | 'sku'>> & {
  blockoutDates: GetMembershipsBlockoutDateSlice[];
};

type GetMembershipsProductSlice = Required<Pick<Product, 'id'>>;

type GetMembershipsBlockoutDateSlice = Required<Pick<BlockoutDate, 'startDate' | 'endDate'>>;

type GetMembershipsPotentialMemberSlice = Required<Pick<PotentialMember, 'id'>> & {
  partyRole: Pick<PartyRole, 'name'>;
};

type GetMembershipsOrderSlice = Required<Pick<Order, 'status' | 'isTrial'>> & {
  shipment: Required<Pick<Shipment, 'status'>> | null;
};

const getPartyRolesQuery = `query {
  partyRoles {
      id
      name
      isRenting
      gtEventType {
        id
        name
    }
  }
}`;

const getMembersInEventQuery = `query event($id: String!) {
  event(id: $id) {
    members {
      id
      isPaid
      isOwner
      isCreator
      isShipped
      isMeasured
      isSignedIn
      isInvited
      accountId
      orders {
        isSwatch
        isTrial
        status
        shipment {
          cartons {
            type
            trackingUrl
          }
        }
      }
      role {
        id
        name
        bundles(organizationId: ${window.gt.orgId}) {
          id: bundleId
          category
          name
          displayName
          cost
          looks {
            id: bundleId
            type
            category
            displayName
            shortDescription
            cost
            isActive
            rentalTerms
            displayIndex
            color
            availableInSlim
            availableInBoys
            urlSlug
            blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              availability
              blockableType
              startDate
              endDate
            }
            details {
              id
              detail
            }
            products {
              id
              displayable
              pattern
              urlSlug
              cost: costToCustomer
              sku: catalogNumber
              displayName: catalogName
              type: availability
              category: type
              color: colorGroup
              shortDescription: catalogShortDescription
              rentalTerms: catalogRentalTerms
              isActive
              displayIndex
              attributePrimary
              attributeSecondary
              attributeTertiary
              blockout_dates: blockoutDates(organizationId: ${window.gt.orgId}) {
                id
                availability
                blockableType
                startDate
                endDate
              }
              details {
                id
                detail
              }
              media {
                description
                label
                url
                displayIndex
              }
            }
          }
          swatch {
            id
            catalogNumber
            blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              startDate
              endDate
            }
          }
          type
          products {
            id
            sku: catalogNumber
          }
          blockoutDates(organizationId: ${window.gt.orgId}) {
            id
            availability
            startDate
            endDate
          }
          media {
            label
            url
          }
        }
        items: products(organizationId: ${window.gt.orgId}) {
          id
          catalogNumber
          color
          description
          category: type
          displayName: catalogName
          cost: costToCustomer
          swatch {
            id
            catalogNumber
            blockoutDates(organizationId: ${window.gt.orgId}) {
              id
              startDate
              endDate
            }
          }
          blockoutDates(organizationId: ${window.gt.orgId}) {
            id
            startDate
            endDate
          }
          media {
            label
            url
          }
        }
      }
      customer {
        id
        firstName
        lastName
        phone
        email
        primaryEventId
        isRegistrationComplete
        addresses {
          id
        }
      }
      orders {
        status
        isSwatch
      }
      potentialMember {
        id
        nickname
        partyRole {
          id
          name
          isRenting
        }
        role {
          id
          name
        }
        member {
          id
          isPaid
          isOwner
          isCreator
          customer {
            id
            firstName
            lastName
            phone
            email
            primaryEventId
            isRegistrationComplete
            addresses {
              id
            }
          }
        }
      }
    }
  }
}`;

const createPotentialMemberMutation = `mutation($input: CreatePotentialMember!) {
  CreatePotentialMember (input: $input) {
    id
    nickname
    partyRole {
      id
      name
    }
    role {
      id
      name
    }
    member {
      id
      isPaid
      isOwner
      isCreator
    }
  }
}`;

const updatePotentialMemberMutation = `mutation($id: String!, $input: UpdatePotentialMemberInputType!) {
  UpdatePotentialMember (id: $id, input: $input) {
    id
    nickname
    partyRole {
      id
      name
    }
    role {
      id
      name
    }
    member {
      id
      isPaid
      isOwner
      isCreator
    }
  }
}`;

const deletePotentialMemberMutation = `mutation($id: String!) {
  DeletePotentialMember (id: $id)
}`;

const deleteLookMutation = `mutation($id: String!) {
  DeleteRole (id: $id)
}`;

const createShortCodeMutation = `mutation($input: CreateShortCodeInputType!) {
  CreateShortCode (input: $input) {
    id
    code
  }
}`;

const sendLinkToMemberMutation = `mutation($input: SendInviteLinkToMemberInputType!) {
  SendInviteLinkToMember (input: $input)
}`;

const joinEventMutation = `mutation($input: JoinEventInputType!) {
  JoinEvent (input: $input) {
    id
    gtEvent {
      id
    }
    isOwner
  }
}`;

const sendMemberInvitesMutation = `mutation SendMemberInvites($input: SendMemberInvitesInputType!) {
  SendMemberInvites(input: $input)
}`;

const deleteMemberMutation = `mutation DeleteMember($id: String!) {
  DeleteMember(id: $id)
}`;

const updateRoleMutation = `mutation UpdateRole($input: UpdateRoleInputType!) {
  UpdateRole (input: $input) {
    id
    name
  }
}`;

const updateOwnerRoleMuation = `mutation UpdateOwnerRole($input: UpdateOwnerRoleInputType!) {
  UpdateOwnerRole (input: $input) {
    id
    isOwner
    role {
      id
      name
    }
    potentialMember {
      id
      nickname
      role {
        id
        name
      }
      partyRole {
        id
        name
      }   
    }
  }
}`;
