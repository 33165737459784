import { observable, action, computed } from 'mobx';
import { CustomerForm } from '../types';

class CustomerFormStore {
  @observable email: CustomerForm['email'] = '';
  @observable password: CustomerForm['password'] = '';
  @observable firstName: CustomerForm['firstName'] = '';
  @observable lastName: CustomerForm['lastName'] = '';
  @observable phone: CustomerForm['phone'] = '';
  @observable smsOptIn: CustomerForm['smsOptIn'] = true;
  @observable emailOptIn: CustomerForm['emailOptIn'] = true;

  @computed get customer(): CustomerForm {
    return {
      email: this.email,
      password: this.password,
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      smsOptIn: this.smsOptIn,
      emailOptIn: this.emailOptIn,
    };
  }

  @action update = <K extends keyof CustomerForm>(field: K, value: CustomerForm[K]): void => {
    this[field] = value as any;
  };

  @action reset = () => {
    this.email = undefined;
    this.password = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.phone = undefined;
    this.smsOptIn = undefined;
    this.emailOptIn = undefined;
  };
}

const singleton = new CustomerFormStore();
export default singleton;
