import { observable, action } from 'mobx';

class InvitedMemberFlowStore {
  @observable fitConfirmed = false;

  @action updateFitConfirmed = (bool: boolean) => {
    this.fitConfirmed = bool;
  };
}

const singleton = new InvitedMemberFlowStore();
export default singleton;
