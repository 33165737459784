import React from 'react';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { EventRouteProps, GTUser } from '../types';
import { url } from '../utils/window';
import Loading from '../utils/Component/Loading';
import auth from '../services/Auth';
import { updatePrimaryEventId } from '../services/Accounts';

interface EventMiddleWareProps extends RouteProps {
  render?: (props: EventRouteProps<any>) => React.ReactNode;
}

const requiredFields: Array<{ key: keyof GTUser; redirect: string }> = [
  { key: 'phone', redirect: '/signup/name' },
  { key: 'firstName', redirect: '/signup/name' },
  { key: 'lastName', redirect: '/signup/name' },
  { key: 'state', redirect: '/signup/missing-information' },
];
const checkMissingAccountInformation = (user: GTUser, eventId: string): string | undefined => {
  for (const field of requiredFields) {
    if (user[field.key] === undefined || user[field.key] === '') {
      return `${field.redirect}?edit=${user.id}&eventId=${eventId}`;
    }
  }
  return undefined;
};

/**
 * You would use this exactly like a Route in react router.
 */
const EventRouteMiddleware: React.FC<EventMiddleWareProps> = ({ component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={function <P extends EventRouteProps<P>>(props: EventRouteProps<any>) {
        if (
          auth.user() === null ||
          auth.user() === undefined ||
          auth.user().id === '' ||
          auth.user().id === undefined
        ) {
          return (
            <>
              <Loading />
              {props.history.push(`/gate/login?redirect=${props.location.pathname}${props.location.search}`)}
            </>
          );
        }

        const eventId = url.param(props.location.search, 'eventId') ?? auth.user().primaryEventId;
        props.eventId = eventId!;
        if (eventId !== null && eventId !== 'null') {
          const missingAccountRedirect = checkMissingAccountInformation(auth.user(), eventId);
          if (missingAccountRedirect !== undefined && !window.gt.user.isCx) {
            return (
              <>
                <Loading />
                {props.history.push(missingAccountRedirect)}
              </>
            );
          }
        }
        if (
          !!eventId &&
          eventId !== 'undefined' &&
          eventId !== 'null' &&
          eventId.length > 0 &&
          eventId !== window.gt.user.primaryEventId
        ) {
          updatePrimaryEventId(eventId);
        }

        return eventId !== null && eventId !== 'null' && eventId.length > 0 ? (
          component ? (
            React.createElement(component, props)
          ) : (
            render!(props)
          )
        ) : (
          <>
            <Loading />
            {props.history.replace({ ...props.location, pathname: '/' })}
          </>
        );
      }}
    />
  );
};

export default EventRouteMiddleware;
