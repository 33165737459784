import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { loggedOut } from '../../../src/utils/metrics';
import Line from '../../components/Line';

interface Props extends RouteComponentProps<any> {}

const navItemClassnames = `
  relative
  flex
  items-center
  whitespace-nowrap
  after:absolute
  after:left-0
  after:bottom-0
  after:h-1
  after:w-full
  after:bg-brand-darker
  after:transform
  after:origin-bottom-left
  after:transition-all
  after:duration-200
  after:ease-linear
  focus:after:scale-x-100
  hover:after:scale-x-100
  focus:text-brand-darker
  cursor-pointer 
  hover:no-underline
  hover:text-brand-darker
  mb-8
`;

const MyAccountNav: React.FC<Props> = (props) => {
  const activeClass = (partialPath: string) => {
    if (props.location.pathname.includes(partialPath)) {
      return `
        after:scale-x-100
        text-brand-darker
      `;
    }

    return `
      after:scale-x-0
      text-gray-dark
    `;
  };

  return (
    <div className="flex flex-col items-start">
      <h1 className="text-h3 pb-16 sm:pt-64">My Account</h1>
      <div
        className={navItemClassnames + activeClass('contact') + ' tracker-nav-account-contact_info-200619-113050'}
        onClick={() => props.history.push(`/account/contact${props.location.search}`)}
      >
        Contact Info
      </div>
      <div
        onClick={() => props.history.push(`/account/preferences${props.location.search}`)}
        className={
          navItemClassnames +
          activeClass('preferences') +
          ' tracker-nav-account-communication_preferences-200619-113050'
        }
      >
        Communication Preferences
      </div>
      <div
        onClick={() => props.history.push(`/account/password${props.location.search}`)}
        className={navItemClassnames + activeClass('password') + ' tracker-nav-account-password_reset-200619-113050'}
      >
        Password Reset
      </div>
      <div
        onClick={() => props.history.push(`/account/fit${props.location.search}`)}
        className={navItemClassnames + activeClass('fit') + ' tracker-nav-account-fit_profile-200619-113050'}
      >
        Fit Profile
      </div>
      <div
        onClick={() => props.history.push(`/account/events${props.location.search}`)}
        className={navItemClassnames + activeClass('events') + ' tracker-nav-account-events-200619-113050'}
      >
        Events
      </div>
      <div
        onClick={() => props.history.push(`/account/orders${props.location.search}`)}
        className={navItemClassnames + activeClass('orders') + ' tracker-nav-account-orders-200619-113050'}
      >
        Orders
      </div>
      <Line />
      <button
        className="tracker-nav-account-log_out-200619-113050 btn btn-sm btn-info-outline mt-8"
        onClick={() => {
          window.localStorage.removeItem('jwt');
          loggedOut();
          window.location.replace('/account/logout');
        }}
      >
        Log Out
      </button>
    </div>
  );
};

export default withRouter(MyAccountNav);
