import React, { Component } from 'react';
import { FlowRouteProps } from '../../types';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import Flow from '../../utils/HOC/Flow';
import { url } from '../../utils/window';
import Line from '../../components/Line';
import IconArrowRight from '../../components/IconArrowRight';

interface Props extends FlowRouteProps<{}> {}

class MissingInformation extends Component<Props> {
  nextPage = () => {
    if (!window.gt.user.primaryEventId) {
      const eventId = url.param(this.props.location.search, 'eventId');
      this.props.flow!(`?eventId=${eventId}`);
    }
    this.props.flow!(`?eventId=${window.gt.user.primaryEventId}`);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-lg">
            <h1 className="normal-case text-h2-display mb-16 xs:mb-32">Oops!</h1>
            <Line />
            <p className="mb-32 text-gray-dark">Looks like we are missing some information.</p>
            <button
              className="tracker-cta-welcome-next-200619-111519 btn btn-info w-full"
              onClick={() => this.nextPage()}
              aria-label="Next"
            >
              <IconArrowRight />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(MissingInformation);
