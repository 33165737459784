import React from 'react';

interface IconPhoneProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconPhoneProps>;

const IconPhone: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 text-black ${className ? className : ''}`}
    >
      <title>Phone Icon</title>
      <rect x="4.5" y="1.5" width="7" height="13" rx="1.5" fill="none" stroke="none" className="stroke-current" />
      <rect x="5" y="3" width="6" height="1" fill="none" stroke="none" className="fill-current" />
      <rect x="5" y="12" width="6" height="1" fill="none" stroke="none" className="fill-current" />
    </svg>
  );
};

export default IconPhone;
