import Auth from '../services/Auth';

const refreshJwt = async () => {
  const res = await fetch('/api/refresh-token', {
    method: 'POST',
    credentials: 'same-origin',
  });

  // If session has expired or user passes an
  // invalid refresh token: Log user out.
  if (res.status === 200) {
    const json = await res.json();
    Auth.setToken(json.jwt);
  } else {
    window.location.replace('/account/logout');
  }
};

const attachHeaders = (data: RequestInit, auth: boolean) => {
  const headers = data.headers
    ? {
        ...data.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    : {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

  const augmentedHeaders = auth ? { ...headers, Authorization: `Bearer ${Auth.getToken()}` } : headers;

  return {
    ...data,
    headers: augmentedHeaders,
  };
};

const fetchMiddleware = async (url: string, data: RequestInit, auth = true) => {
  if (auth && Auth.isJwtExpired()) {
    await refreshJwt();
  }
  const res = await fetch(
    `${url}${url.indexOf('?') === -1 ? '?' : '&'}organization_id=${process.env.REACT_APP_ORGANIZATION_ID}`,
    attachHeaders(data, auth)
  );

  // If user is unauthorized to view endpoint information:
  // Log user out to retrieve appropriate jwt.
  if (res.status === 401 || res.status === 403) {
    window.location.replace('/account/logout');
  }
  return res;
};

export default fetchMiddleware;
