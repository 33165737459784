import { observable, action, computed } from 'mobx';
import { SideCar, SideCarType } from '../../types';

class SideCarStore {
  @observable car: SideCarType;
  @observable category: string | null;
  @observable id: number | null;

  @computed get sidecar() {
    return {
      car: this.car,
      category: this.category,
      id: this.id,
    };
  }

  @action
  set = (sidecar: SideCar) => {
    if (sidecar.car === 'list') {
      this.car = sidecar.car;
      this.category = null;
      this.id = null;
      return;
    }
    if (sidecar.car === 'gallery') {
      if (sidecar.category === null) {
        throw new Error('Sidecar with car: "gallery" cannot have a "null" category');
      }
      this.car = sidecar.car;
      if (this.category !== sidecar.category) {
        this.category = sidecar.category;
      }
      if (this.id !== sidecar.id) {
        this.id = null;
      }
      return;
    }

    if (sidecar.car === 'details') {
      if (sidecar.category === null) {
        throw new Error('Sidecar with car: "details" cannot have a "null" category');
      }
      if (sidecar.id === null) {
        throw new Error('Sidecar with car: "id" cannot have a "null" id');
      }
      this.car = sidecar.car;
      if (this.category !== sidecar.category) {
        this.category = sidecar.category;
      }
      if (this.id !== sidecar.id) {
        this.id = sidecar.id;
      }
      return;
    }
  };
}

const singleton = new SideCarStore();

export default singleton;
