import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import type { GlobalContextTyping, FlowRouteProps } from 'types';
import Loading from '../../utils/Component/Loading';
import Message from '../../components/Message';
import EventFormStore from '../../stores/EventFormStore';
import PartyRoleStore from '../../stores/PartyRoleStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import Flow from '../../utils/HOC/Flow';
import { AccessContext } from '../../utils/HOC';
import IconButton from './IconButton';
import IconWeddingRing from '../../components/IconWeddingRing';
import IconGraduationCap from '../../components/IconGraduationCap';
import IconPartyConfetti from '../../components/IconPartyConfetti';
import { SVG } from '../../types';
import { Link } from 'react-router-dom';

type EventTypeOption = { icon: React.ReactElement<SVG>; label: string; eventTypeName: string };

const eventTypeOptions: EventTypeOption[] = [
  { icon: <IconWeddingRing />, label: 'Wedding', eventTypeName: 'Wedding' },
  { icon: <IconGraduationCap />, label: 'Prom', eventTypeName: 'Prom' },
  { icon: <IconPartyConfetti />, label: 'Other', eventTypeName: 'Other' },
];
interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

const EventType = ({ flow, location, history }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function loadData() {
      try {
        await PartyRoleStore.load();

        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
        setError('There was a problem loading roles.');
      }
    }

    loadData();
  }, []);

  const onClickHandler = (eventType: string) => {
    const roles = PartyRoleStore.getRolesByEventType(eventType);

    if (roles.length === 0) {
      setError('There was a problem finding roles.');
      return;
    }

    EventFormStore.update('type', eventType!);

    if (roles.length === 1) {
      // update role & navigate to event date page if the event type has single role
      EventFormStore.update('partyRoleId', roles[0].id);

      const basePath = location.pathname.split('/').slice(0, -1).join('/');

      history.push(`${basePath}/date${location.search}`);
    } else {
      flow!(location.search);
    }
  };

  return (
    <>
      <FormFlowLogo />

      <div className="container">
        <div className="mx-auto max-w-xl">
          {loading && <Loading />}
          {error && (
            <Message type="error">
              Oops, we encountered an issue while processing your request. Please refresh the page and try again. If the
              issue persists, please reach out to our{' '}
              <Link to={`${process.env.REACT_APP_ECOMM_URL}/support`}>support team</Link> for assistance.
            </Message>
          )}

          {!loading && !error && (
            <>
              <h1 className="text-h2-display mb-56 text-center">What is the occasion?</h1>

              <div className="mb-32 flex flex-col items-stretch">
                <div className="flex"></div>
                <div className="p-32 xs:pb-56">
                  <div className={`flex flex-col items-center justify-evenly gap-16`}>
                    {eventTypeOptions?.map(({ icon, label, eventTypeName }, index) => (
                      <IconButton key={index} icon={icon} label={label} onClick={() => onClickHandler(eventTypeName)} />
                    )) || null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Flow(AccessContext(observer(EventType)));
