import React from 'react';
import { observer } from 'mobx-react';

import ItemStore from '../../../../stores/ItemStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import { JACKET_AND_PANTS } from '../../../data/categories';

import SaveBox from '../SaveBox';
import GalleryItemConfirm from './GalleryItemConfirm';
import IconArrowLeft from '../../../../components/IconArrowLeft';
import { Transition } from '@headlessui/react';

interface Props {
  mediaQueryXsMatches?: boolean;
  animateDirection?: string;
  setAnimateDirection?: React.Dispatch<React.SetStateAction<string>>;
}

const GalleryDetails = ({ mediaQueryXsMatches, animateDirection, setAnimateDirection }: Props) => {
  const product =
    SideCarStore.category !== JACKET_AND_PANTS
      ? ItemStore.cachedProducts.find((p) => p.id === SideCarStore.id)
      : ItemStore.cachedBundles.find((p) => p.id === SideCarStore.id);

  return (
    <Transition
      appear
      show
      enter="transition duration-500"
      enterFrom={`opacity-0 ${
        mediaQueryXsMatches ? '' : animateDirection === 'right' ? 'translate-x-[-50%]' : 'translate-x-[50%]'
      }`}
      enterTo="opacity-100"
    >
      <div data-testid="lookbuilder-gallery-details" style={{ maxWidth: 800 }}>
        <div>
          <div className="top-0 z-50 bg-gray-light py-4 xs:sticky">
            <button
              className="tracker-btn-look_builder-back_to_gallery-20200508-111100 group flex w-full cursor-pointer justify-between bg-white p-16 transition hover:bg-gray-lighter"
              onClick={() => {
                setAnimateDirection && setAnimateDirection('right');
                window.scrollTo(0, 0);
                SideCarStore.set({ car: 'gallery', category: SideCarStore.category, id: null });
              }}
            >
              <h5 className="text-h5">{product!.displayName}</h5>
              <div className="flex items-center gap-8">
                <IconArrowLeft className="group-hover:text-brand-darker" />{' '}
                <p className="text-xs group-hover:text-brand-darker">Back</p>
              </div>
            </button>
          </div>

          <div className="relative bg-white">
            {product!.media &&
              product!.media
                .filter(
                  (image) =>
                    image.label!.includes('PDP') &&
                    !(
                      image.label!.includes('PDP_Hero') &&
                      product!.category === 'preconfigured' &&
                      process.env.REACT_APP_ORGANIZATION_ID === '2'
                    )
                )
                .map((image) => (
                  <img
                    loading="lazy"
                    key={image.url}
                    style={{ display: 'block' }}
                    alt={`${product!.displayName} ${image.label}`}
                    src={`${image.url}?auto=format&fit=crop&fm=jpg&q=75&w=800`}
                  />
                ))}
          </div>

          <div className="mt-4 bg-white p-32">
            <div className="my-16" style={{ maxWidth: '30em' }}>
              <h3 className="text-h3-display mb-16">{product!.displayName}</h3>

              <h4 className="text-h5 mb-16">Details</h4>

              <div className="mb-32">
                <ul className="text-sm text-gray-dark">
                  {product!.details && product!.details.map((detail) => <li key={detail.id}>{detail.detail}</li>)}
                </ul>
              </div>

              <h4 className="text-h5 mb-8">Stylist Notes</h4>
              <p className="text-sm text-gray-dark">{product!.shortDescription}</p>
            </div>
            <GalleryItemConfirm item={product!} isDetailsView={true} setAnimateDirection={setAnimateDirection} />
          </div>
        </div>

        <SaveBox display="none" displayXs="flex" setAnimateDirection={setAnimateDirection} />
      </div>
    </Transition>
  );
};

export default observer(GalleryDetails);
