import { Membership } from '../../../services/Events';
import { checkLookHTOStatus, HTO_STATUSES, isPartyRoleEligibleForHto } from '../../../utils/utils';
import { GTEvent } from '../../../types';

/**
 * Returns a boolean that indicates whether the given member is able to see the
 * modal that prompts them to select from their existing looks
 */
export const canSeeExistingLooksPrompt = (membership: Membership) => {
  if (membership.gtEvent.isTrial) {
    return false;
  }

  const eventType = membership.gtEvent.gtEventType?.name?.toLowerCase() ?? '';
  const partyRole = membership.potentialMember?.partyRole.name.toLowerCase() ?? '';

  if (eventType !== 'wedding' || !membership.isOwner || !isPartyRoleEligibleForHto(partyRole)) {
    return false;
  }

  const hasEligibleLook = membership.gtEvent.roles.some((look) => {
    const numberOfItems = look.bundles.length + look.products.length;

    if (numberOfItems === 0) {
      return false;
    }

    return (
      checkLookHTOStatus({
        look,
        event: membership.gtEvent as GTEvent,
        memberships: [membership],
      }) === HTO_STATUSES.AVAILABLE
    );
  });

  return hasEligibleLook;
};

/**
 * Searches the given events that the customer's a member of, and returns one that
 * makes the most sense to direct them to if they want to pick from their existing
 * looks for an HTO
 */
export const getPreferredMembershipForPrompt = (qualifyingMemberships: Membership[], primaryEventId: string | null) => {
  if (!primaryEventId) {
    // If the customer's primary event ID isn't set, we'll go with the first
    // membership in the list instead
    return qualifyingMemberships[0];
  }

  let index = qualifyingMemberships.findIndex((membership) => {
    return membership.gtEvent.id === primaryEventId;
  });

  if (index === -1) {
    // We'll also go with the first membership in case the primary event ID isn't
    // found in the list
    index = 0;
  }

  return qualifyingMemberships[index];
};
