import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconBelt: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 20 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-[10px] w-[20px] stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Belt Icon</title>

      <rect x="0.5" y="2.5" width="19" height="5" />
      <path d="M8.5 3V0.5H16V9.5H8.5V7" />
      <rect x="3.5" y="0.5" width="2" height="9" fill="none" />
      <path d="M12.5 5H17" strokeLinecap="round" />
    </svg>
  );
};

export default IconBelt;
