import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Address, GTEvent, Member, GlobalContextTyping } from '../../types';

import EventStore from '../../stores/EventStore';
import MemberStore from '../../stores/MemberStore';
import CustomerStore from '../../stores/CustomerStore';

import { AccessContext } from '../../utils/HOC/';
import usStates from '../../utils/usStates';
import FormNavigation from '../../utils/Component/FormNavigation';
import { getStringCookie } from '../../utils/utils';

import { createAddress } from '../../services/Accounts';
import { orderSwag } from '../../services/Payments';

import Spinner from '../../shared/components/Spinner';
import { measuringTapeOrdered } from '../../utils/metrics';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';

// interfaces
interface Props extends RouteComponentProps<any> {
  nextUrl?: string;
  globalContext?: GlobalContextTyping;
}

interface State {
  address: Address;
  member?: Member;
  event?: GTEvent;
  loading: boolean;
  submitting: boolean;
  success: boolean;
  error: boolean;
}

// constants
const TAPE_MEAUSURE_CATEGLOG_NUMBER = 'P10517';

class FitOrderTapeMeasure extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      member: undefined,
      event: undefined,
      loading: true,
      success: false,
      error: false,
      submitting: false,
      address: {
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        isDefault: false,
      },
    };
  }

  async componentDidMount() {
    await EventStore.loadEvent(window.gt.user.primaryEventId);

    this.setState({ loading: false });
  }

  // Load the users member associated with the event to state.
  loadMember = async (event: GTEvent) => {
    const member = event.members && event.members.filter((m) => m.customer && m.customer.id === CustomerStore.id)[0];

    this.setState({
      member,
    });
  };

  orderTapeMeasure = async (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({ submitting: true, error: false });
    const event = EventStore.event;
    const member = MemberStore.getSignedInMember();
    const { address } = this.state;

    // validation
    if (
      event === undefined ||
      event.id === undefined ||
      member === undefined ||
      member.id === undefined ||
      address === undefined
    ) {
      this.setState({ error: true });
      return;
    }

    // create address and then order the swag
    try {
      const res = await createAddress(address);
      const data = await res.json();
      await orderSwag({
        memberId: member.id,
        eventId: event.id,
        addressId: data.data.createAddress.id.toString(),
        catalogNumbers: [TAPE_MEAUSURE_CATEGLOG_NUMBER],
        campaignId:
          getStringCookie('iterableEmailCampaignId') !== undefined
            ? Number(getStringCookie('iterableEmailCampaignId'))
            : undefined,
        isSwatch: false,
      });

      measuringTapeOrdered();

      this.setState({ success: true, submitting: false });
    } catch (e) {
      console.error(e);
      this.setState({ error: true, submitting: false });
    }
  };

  addressFieldChange = (
    key: 'firstName' | 'lastName' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip',
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value; // instead of event.persists due to callback
    this.setState((state: State) => {
      const address = { ...state.address, [key]: value };
      return { address };
    });
  };

  isAddressValid = () => {
    const { address } = this.state;
    const { addressLine2, ...modifiedAddress } = address;

    const errors = Object.values(modifiedAddress).map((value) => {
      if (typeof value === 'string') {
        return value === '';
      } else {
        return false;
      }
    });

    return !errors.includes(true);
  };

  render() {
    const { address, loading, submitting, success, error } = this.state;
    const { firstName, lastName, city, state, zip, addressLine1, addressLine2 } = address;

    if (loading) return <Spinner type="minimal" />;

    if (error) {
      return (
        <div className="mx-auto max-w-sm text-center">
          <h1 className="text-h1 mb-32">There was a problem.</h1>
          <p>Please contact our customer support team.</p>
        </div>
      );
    }

    if (success) {
      return (
        <div className="mx-auto max-w-sm text-center">
          <h1 className="text-h2-display mb-32">Your tape measure is on the&nbsp;way.</h1>
          <p className="mb-32">
            We just sent you a confirmation to your email. Please allow 5-7 business days for delivery.
          </p>

          <Link
            to={`/account/orders${this.props.location.search}`}
            className="tracker-cta-tape_measure-view_orders-200619-105820 btn btn-info"
          >
            View Orders
          </Link>
        </div>
      );
    }

    return (
      <>
        <div className="mx-auto max-w-sm">
          <h1 className="text-h2-display mb-32 text-center">Order a free tape measure.</h1>
          <form className="flex flex-col gap-12" onSubmit={this.orderTapeMeasure}>
            <FormInput
              combineLabel
              name="first"
              value={firstName}
              type="text"
              onChange={this.addressFieldChange.bind(this, 'firstName')}
              label="First Name"
              placeholder="_"
              inputClassName="!placeholder-gray"
            />

            <FormInput
              combineLabel
              name="last"
              value={lastName}
              type="text"
              onChange={this.addressFieldChange.bind(this, 'lastName')}
              label="Last Name"
              placeholder="_"
              inputClassName="!placeholder-gray"
            />

            <FormInput
              combineLabel
              name="address"
              value={addressLine1}
              type="text"
              onChange={this.addressFieldChange.bind(this, 'addressLine1')}
              label="Street Address"
              placeholder="_"
              inputClassName="!placeholder-gray"
            />

            <FormInput
              combineLabel
              name="address"
              value={addressLine2}
              type="text"
              onChange={this.addressFieldChange.bind(this, 'addressLine2')}
              label="Apartment, Suite, Company Name"
              placeholder="_"
              inputClassName="!placeholder-gray"
            />

            <FormInput
              combineLabel
              name="city"
              type="text"
              value={city}
              onChange={this.addressFieldChange.bind(this, 'city')}
              label="City"
              placeholder="_"
              inputClassName="!placeholder-gray"
            />

            <FormSelect
              combineLabel
              label="State"
              placeholder="_"
              name="state"
              value={state}
              onChange={this.addressFieldChange.bind(this, 'state')}
              selectClassName={state === '' ? 'text-gray' : ''}
            >
              <option value="">_</option>
              {usStates.map((s) => (
                <option key={s.abbreviation} value={s.abbreviation}>
                  {s.name}
                </option>
              ))}
            </FormSelect>

            <FormInput
              combineLabel
              name="zip"
              value={zip}
              type="text"
              onChange={this.addressFieldChange.bind(this, 'zip')}
              label="Zip"
              placeholder="_"
              inputClassName="!placeholder-gray"
            />

            <FormNavigation
              className="mt-32"
              next={this.orderTapeMeasure}
              disabled={submitting || !this.isAddressValid()}
            />
          </form>
        </div>
      </>
    );
  }
}

export default AccessContext(observer(FitOrderTapeMeasure));
