import type { HTMLAttributes } from 'react';

type IconBriefcaseProps = HTMLAttributes<HTMLOrSVGElement>;

const IconBriefcase = ({ className, ...props }: IconBriefcaseProps) => (
  <svg
    fill="none"
    height="12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`h-[12px] w-[12px] stroke-current text-gray-dark ${className ? className : ''}`}
  >
    <title>Briefcase Icon</title>
    <path d="M3.52244 1.235c-.17552.28084-.29726.55781-.37561.765H0v10h12V2H8.87105c-.07835-.20719-.20009-.48416-.37561-.765C8.14649.676685 7.51428 0 6.50894 0h-1C4.5036 0 3.87139.676685 3.52244 1.235ZM11 6V3H1v3h10ZM1 7v4h10V7H1Zm6.64744-5.235c.04919.07871.09325.15796.1324.235h-3.5418c.03915-.07704.08321-.15629.1324-.235.27605-.44169.64384-.765 1.1385-.765h1c.49466 0 .86245.32331 1.1385.765Z" />
  </svg>
);

export default IconBriefcase;
