import React from 'react';

interface IconArrowRightProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconArrowRightProps>;

const IconArrowRight: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Arrow pointing right</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 8.5H2V7.5H9V8.5Z" />
      <path d="M9 12L14 8L9 4V12Z" />
    </svg>
  );
};

export default IconArrowRight;
