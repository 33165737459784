import React, { Component } from 'react';

// local components
import FormNavigation from '../../utils/Component/FormNavigation';
import Loading from '../../utils/Component/Loading';

// types
import { FlowRouteProps } from '../../types';
import Flow from '../../utils/HOC/Flow';
import FormInput from '../../components/FormInput';
import Message from '../../components/Message';

// interfaces
interface Props extends FlowRouteProps<any> {
  imageSrc: string;
  onChange: (value: string) => void;
  submitting: boolean;
  error: string | undefined;
  onSubmit?: () => void;
  title: string;
  value: string;
  nextUrl?: string;
  type: 'neck' | 'overarm' | 'chest' | 'sleeve' | 'stomach' | 'waist' | 'outseam';
}

class FitOptionalMeasurements extends Component<Props> {
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value);
  };

  onSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { onSubmit } = this.props;

    if (onSubmit) {
      await onSubmit();
    }

    this.props.flow!(`${this.props.location.search}`);
  };

  render() {
    const { title, value, imageSrc, type } = this.props;

    return (
      <>
        {this.props.submitting ? (
          <Loading />
        ) : (
          <>
            <div>
              <form onSubmit={this.onSubmit}>
                <h2 className="normal-case text-h2-display mb-32 text-center">{title}</h2>
                <div className="row gap-y-32">
                  <div className="col-span-12 mb-32 sm:col-start-7 sm:col-end-12 sm:mb-0 lg:col-end-11">
                    <div className="mb-32">
                      <FormInput
                        className="w-full"
                        id={title}
                        name={title}
                        autoFocus={true}
                        required={true}
                        type="text"
                        placeholder={title}
                        value={value}
                        onChange={this.onChange}
                      />
                      {this.props.error && <Message type="error" message={this.props.error} />}
                      <p className="text-sm mt-16 text-gray-dark">Please enter whole or half inch increments.</p>
                    </div>
                    <FormNavigation disabled={value.length === 0} />
                  </div>
                  <div className="col-span-12 sm:col-span-6 sm:row-start-1 lg:col-span-4 lg:col-start-3">
                    <div className="bg-white p-32 shadow-xl">
                      <div>
                        <img
                          src={imageSrc}
                          alt={title + ' measurement demonstration'}
                          style={{
                            maxHeight: '180px',
                            margin: '0 auto',
                            display: 'block',
                          }}
                        />
                      </div>

                      {/* You */}
                      <div>
                        <h4 className="text-h4 mb-16">You</h4>
                        <ol>
                          {questions[type].you.map((q: string, i: number) => (
                            <li key={i}> {q} </li>
                          ))}
                        </ol>
                      </div>

                      {/* Someone Else */}
                      <div>
                        <h4 className="text-h4 mb-16">Someone Else</h4>
                        <ol>
                          {questions[type].them.map((q: string, i: number) => (
                            <li key={i}> {q} </li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </>
    );
  }
}

const questions = {
  neck: {
    you: ['Stand tall with your chin up.'],
    them: ['Measure just below the Adam’s apple.', 'Leave one finger width of slack under the tape.'],
  },
  overarm: {
    you: ['Let your arms hang naturally at your side.'],
    them: [
      'Measure around the widest part of the chest.',
      'Go over their arms and shoulder blades.',
      'Keep the tape parallel to the floor.',
    ],
  },
  chest: {
    you: ['Stand up straight.', 'Relax your shoulders.', 'Let your arms hang naturally at your side.'],
    them: [
      'Place the tape measure under their armpits.',
      'Measure around the widest part of the chest',
      'Keep the tape parallel to the floor.',
    ],
  },
  sleeve: {
    you: ['Let your arms hang naturally at your side.', 'Hold your hands in a loose fist.'],
    them: [
      'Place the end of the tape at the base of the neck.',
      'With your other hand, run the tape to the end of the shoulder.',
      'Hold the tape at the end of the shoulder.',
      'Release the end of tape at the neck.',
      'Without moving the tape at the shoulder, run the tape down to the bone of the wrist.',
    ],
  },
  stomach: {
    you: ['Place your index finger on your belly button.'],
    them: [
      'Wrap the tape around the widest part of the stomach.',
      'Make sure the tape is below the belly button.',
      'Keep the tape parallel to the floor.',
    ],
  },
  waist: {
    you: ['Tuck in your shirt.', 'Let your pants rest where you prefer.'],
    them: ['Measure around the top of the pants.', 'Leave no slack in the tape.'],
  },
  outseam: {
    you: ['Take off your shoes', 'Tuck in your shirt.', 'Let your pants rest where you prefer.'],
    them: [
      'Hold one end of the tape at the top of the pant waistband.',
      'Run the tape straight down the side of the leg to the floor.',
    ],
  },
};

export default Flow(FitOptionalMeasurements);
