import React from 'react';

interface IconPencilProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconPencilProps>;

const IconPencil: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Pencil</title>
      <path d="M13 2.01l-.99.99.99.99.99-.99-.99-.99zM3 3h6v1H4v8h8V7h1v6H3V3z" />
      <path d="M12 5l-1-1-4 4-1 2 2-1 4-4z" />
    </svg>
  );
};

export default IconPencil;
