import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';

import { GlobalContextTyping } from '../../types';
import { forgotPassword } from '../../services/Auth';
import { validateEmail } from '../../utils/utils';

import MyAccountWrapper from '../hoc/MyAccountWrapper';
import { pageFadeIn, pageFadeInDelayed } from '../../utils/Component/Animations';
import Line from '../../components/Line';
import FormInput from '../../components/FormInput';
import Message from '../../components/Message';
import { Transition } from '@headlessui/react';

interface Props extends RouteComponentProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  email: string;
  emailError: boolean;
  submitSuccess: boolean;
  submitError: boolean;
  submitting: boolean;
}

class PasswordReset extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
      submitSuccess: false,
      submitError: false,
      submitting: false,
    };
  }

  handleChange = (field: keyof State, value: string | boolean) => {
    this.setState((state) => ({
      ...state,
      [field]: value,
    }));
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail(this.state.email)) {
      this.setState({ emailError: true });
      return;
    }

    this.setState(() => ({
      submitting: true,
      emailError: false,
      submitError: false,
    }));

    try {
      const result = await forgotPassword(this.state.email);

      if (result.status === 200 || result.status === 202) {
        this.setState({
          submitting: false,
          submitSuccess: true,
        });
        return;
      }

      // There was an error
      this.setState({
        submitting: false,
        emailError: true,
      });
    } catch {
      // There was an error
      this.setState({
        submitting: false,
        emailError: true,
      });
    }
  };

  render() {
    const { email, submitting, submitSuccess, submitError } = this.state;

    return (
      <>
        <Transition {...pageFadeIn}>
          <h2 className="normal-case text-h2-display">Password Reset</h2>
          <Line />
        </Transition>

        <Transition {...pageFadeInDelayed}>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <div className="bg-white p-32 shadow-2xl">
              <div>
                <FormInput
                  className="w-full"
                  combineLabel
                  errorMessage={this.state.emailError ? 'Please provide a valid email address' : undefined}
                  inputMode="email"
                  label="Email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange('email', e.target.value)}
                  type="email"
                  value={email}
                />
              </div>
            </div>

            <button
              type="submit"
              className="tracker-cta-reset_password-send-200619-111519 btn btn-info mt-32"
              disabled={submitting}
            >
              Send Link
            </button>
          </form>
          {submitSuccess && <Message type="success">You should receive a reset password email shortly.</Message>}
          {submitError && <Message type="error">Customer with that email does not exist.</Message>}
        </Transition>
      </>
    );
  }
}

export default observer(MyAccountWrapper(PasswordReset));
