import React, { Component } from 'react';
import { Transition } from '@headlessui/react';

import {
  createSignificantOther,
  updateSignificantOther,
  getSignificantOther,
  validateEmail,
} from '../../../../services/Accounts';

import { segmentIdentify } from '../../../../utils/metrics';
import { pageFadeInDelayed } from '../../../../utils/Component/Animations';

import Message from '../../../../components/Message';
import FormInput from '../../../../components/FormInput';
import { GTUser } from '../../../../types';

class EmailValidationError extends Error {}

const EMAIL_IS_INVALID = 'Please provide a valid email.';

interface Props {
  customer: GTUser;
}

interface State {
  id: string;
  email: string;
  emailError: string;
  submitting: boolean;
  submitError: Boolean;
  submitSuccess: Boolean;
}

class SignificantOtherContactInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: '',
      email: '',
      emailError: '',
      submitting: false,
      submitError: false,
      submitSuccess: false,
    };
  }

  componentDidMount = async () => {
    try {
      const response = await getSignificantOther(this.props.customer.id);

      if (response.status !== 200) {
        const dataError = await response.json();
        throw new Error(dataError);
      }

      const data = await response.json();

      this.setState(() => ({
        email: data.email,
        id: data.id,
      }));
    } catch (e) {
      console.error(e);
    }
  };

  updateSignificantOther = async () => {
    try {
      const response = await updateSignificantOther(this.state.id, { email: this.state.email });
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      await segmentIdentify({ ...this.props.customer, significantOther: { email: this.state.email } });
      this.setState(() => ({
        submitting: false,
        submitSuccess: true,
        submitError: false,
        emailError: '',
      }));
    } catch (e) {
      console.error(e);
      this.setState(() => ({ submitting: false, submitError: true }));
    }
  };

  createSignificantOther = async () => {
    try {
      const response = await createSignificantOther({ email: this.state.email, customerId: this.props.customer.id });

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      await segmentIdentify({ ...this.props.customer, significantOther: { email: this.state.email } });

      this.setState(() => ({
        id: data.id,
        submitting: false,
        submitSuccess: true,
        submitError: false,
        emailError: '',
      }));
    } catch (e) {
      console.error(e);
      this.setState(() => ({ submitting: false, submitError: true }));
    }
  };

  isEmailValid = async (email: string): Promise<boolean> => {
    const response = await validateEmail(email);

    const data = await response.json();

    if (data.errors) {
      return false;
    }

    return data.isValid;
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState(() => ({
      submitting: true,
      submitSuccess: false,
      submitError: false,
      emailError: '',
    }));

    try {
      const emailIsValid = await this.isEmailValid(this.state.email);

      if (!emailIsValid) {
        throw new EmailValidationError(EMAIL_IS_INVALID);
      }

      this.state.id ? await this.updateSignificantOther() : await this.createSignificantOther();
    } catch (e) {
      const isValidationError = e instanceof EmailValidationError;

      const submitError = !isValidationError;
      const emailError = isValidationError ? e.message : '';

      this.setState(() => ({
        emailError,
        submitError,
        submitting: false,
      }));
    }
  };

  render() {
    return (
      <>
        <Transition {...pageFadeInDelayed}>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <h2 className="text-h3 normal-case mb-8">Significant Other Info</h2>
            <div className="bg-white p-32 shadow-2xl">
              <div className="space-y-8">
                <FormInput
                  className="w-full"
                  combineLabel
                  value={this.state.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value })}
                  label="Email"
                />
                {this.state.emailError && <Message type="error">{this.state.emailError}</Message>}
              </div>
              <button className="btn btn-info mb-8 mt-32" type="submit" disabled={this.state.submitting}>
                Save Changes
              </button>
            </div>
          </form>
        </Transition>
        {this.state.submitError && (
          <>
            <Message type="error">There was an error updating your profile.</Message>
          </>
        )}
        {this.state.submitSuccess && (
          <>
            <Message type="success">Profile updated!</Message>
          </>
        )}
      </>
    );
  }
}

export default SignificantOtherContactInfo;
