import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import { EventRouteProps, GetMeasurementsResponse } from '../../types';
import MemberStore from '../../stores/MemberStore';
import EventStore from '../../stores/EventStore';
import { getMeasurements } from '../../services/Fit';
import { IsOwner } from '../../utils/HOC';
import { isRentingOwner } from '../../utils/utils';
import Navbar from '../../partials/Navbar';
import PageHeader from '../../partials/PageHeader';
import NavBarSub from '../../partials/NavBarSub';
import Spinner from '../../shared/components/Spinner';
import FitPreview from '../../shared/components/FitPreview';
import IconPlus from '../../components/IconPlus';
import Line from '../../components/Line';
import { TaggedText } from '../../components/TaggedText';
import { pageFadeIn } from '../../utils/Component/Animations';
import { Transition } from '@headlessui/react';
import HowItWorks from '../../partials/HowItWorks';
import { startedFit } from '../../utils/metrics';
import Modal from '../../utils/Component/Modal';
import { ManualMeasurementsForm } from '../../event-flow/components/ManualMeasurementsForm';
import { ManualMeasurements } from '../../utils/manualMeasurements';
import { submitManualMeasurements } from '../../services/Accounts';
import auth from '../../services/Auth';
import IsGentux from '../../components/IsGentux';

type Props = EventRouteProps<any> & {};

const handleMeasurementsSubmission = async (measurements: ManualMeasurements) => {
  const response = await submitManualMeasurements({
    customerId: auth.user().id,
    ...measurements,
  });

  if (response.status !== 200) {
    let errorMessage = await response.text();

    if (response.headers.get('Content-Type') === 'application/json') {
      errorMessage = JSON.parse(errorMessage);
    }

    throw new Error(errorMessage);
  }
};

const FitLink = (props: Props) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showError, setError] = useState('');
  const [measurements, setMeasurements] = useState<GetMeasurementsResponse | null>(null);

  const handleEditClick = () => {
    startedFit({ partyRole: MemberStore.getSignedInMember()?.potentialMember?.partyRole?.name });
    props.history.push(`/fit-flow/height?eventId=${EventStore.event!.id!}`);
  };

  const closeModal = () => setShowModal(false);

  useEffect(() => {
    (async () => {
      try {
        const measurements = (await getMeasurements()) as GetMeasurementsResponse;

        if (measurements !== undefined) {
          setMeasurements(measurements);
        }
      } catch (e) {
        let errorMessage = 'Failed to get measurements.';

        if (e instanceof Error) {
          errorMessage = e.message;
        }

        console.error(errorMessage);
        setError('We were unable to retrieve your measurements. Please try refreshing the page.');
      }

      setShowLoading(false);
    })();
  }, []);

  return (
    <>
      <Navbar />
      <div data-testid="eventflow-fit" className="fit-page container">
        <div className="row">
          <div className="col-span-12 sm:col-span-10 sm:col-start-2">
            <div className="m-section mt-32 hidden sm:block">
              <NavBarSub />
            </div>
            <Transition {...pageFadeIn}>
              <PageHeader header="Fit" pageName="fit">
                <p className="fit-page__description">
                  Our algorithm will fit you at a higher success rate than hand measuring. If the fit doesn’t meet your
                  style preference, we offer free shipping and replacements to get it just right.
                </p>
              </PageHeader>

              <div className="relative">
                <Transition
                  show={showLoading}
                  enter="transition duration-150"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                >
                  <div className="py-128 md:py-32">
                    <Spinner className="h-[30vh]" type="minimal" />
                  </div>
                </Transition>

                <Transition
                  show={!showLoading}
                  enter="transition duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {isRentingOwner(MemberStore.getSignedInMember()!) ? (
                    measurements ? (
                      <div className="fit-page-content--after-fit-submitted row justify-center">
                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7 lg:col-start-4 xl:col-span-7 xl:col-start-4">
                          <FitPreview
                            measurements={measurements!}
                            showEdit={true}
                            handleEdit={handleEditClick}
                            editButtonClasses="fit-page-content--after-fit-submitted__button"
                            showManualInputToggle={true}
                            toggleManualInput={() => setShowModal(true)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="fit-page-content--before-fit-submitted mb-32 flex flex-col items-center justify-between border border-gray-light px-32 py-16 sm:py-32 md:flex-row">
                        <div className="mr-0 w-full md:mr-16 md:w-auto">
                          <h2 className="text-h4 mb-8">Your Measurements</h2>

                          <p className="fit-page-content--before-fit-submitted__description text-sm my-8 text-gray-dark">
                            (Members will enter their measurements when they sign up.)
                          </p>

                          <IsGentux>
                            <TaggedText
                              tag="New"
                              className="fit-page-content--before-fit-submitted__announcement my-16 hidden md:mt-32 md:block"
                            >
                              Enter your sizes after fitting (optional)
                            </TaggedText>
                          </IsGentux>
                        </div>

                        <button
                          data-testid="btn-add-fit"
                          className="tracker-cta-fit-add_fit-20191029-134723 fit-page-content--before-fit-submitted__button btn btn-info mt-16 w-full px-32 xs:px-64 sm:px-128 md:mt-0 md:w-auto"
                          onClick={() => handleEditClick()}
                        >
                          Add <IconPlus />
                        </button>

                        <IsGentux>
                          <div className="mt-16 block self-baseline md:hidden">
                            <TaggedText
                              tag="New"
                              className="fit-page-content--before-fit-submitted__announcement block"
                            >
                              Enter your sizes after fitting (optional)
                            </TaggedText>
                          </div>
                        </IsGentux>
                      </div>
                    )
                  ) : (
                    <div className="fit-page-content--no-fit-needed col-span-12 mb-64 border border-brand p-32 shadow-2xl md:col-span-10 lg:col-span-8 lg:p-64">
                      <h2 className="text-h3">Fit not needed</h2>
                      <Line />
                      <p className="fit-page-content--no-fit-needed__description">
                        You aren't renting a suit for yourself so we won’t need your fit information. And don't worry
                        about your members, they'll enter their fit information when they sign&nbsp;up.
                      </p>
                    </div>
                  )}
                </Transition>
              </div>

              {showError !== '' && <p className="text-sm mb-8 bg-red p-16 font-sans text-white">{showError}</p>}
            </Transition>
            <div className="pt-32 xs:pt-64 sm:hidden">
              <NavBarSub />
            </div>
          </div>
        </div>
        <HowItWorks className="mt-256" />
      </div>

      {showModal &&
        createPortal(
          <Modal
            id="manual-measurements-form-modal"
            onClose={() => closeModal()}
            render={({ contentRef, scrollToTop }) => (
              <ManualMeasurementsForm
                ref={contentRef}
                onError={(_) => scrollToTop()}
                onSubmit={handleMeasurementsSubmission}
              />
            )}
          />,
          document.body
        )}
    </>
  );
};

export default IsOwner(observer(FitLink));
