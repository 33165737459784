import React from 'react';

const AddMember = () => (
  <svg width="133px" height="100px" viewBox="0 0 133 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Add Members Illustration</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="add-members-linearGradient-1">
        <stop stopColor="#4B494B" offset="0%" />
        <stop stopColor="#383638" offset="100%" />
      </linearGradient>
      <rect id="add-members-path-2" x="58" y="37" width="65" height="38" rx="2" />
      <filter
        x="-15.4%"
        y="-13.2%"
        width="130.8%"
        height="152.6%"
        filterUnits="objectBoundingBox"
        id="add-members-filter-3"
      >
        <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-37.5%"
        y="-20.7%"
        width="175.0%"
        height="162.1%"
        filterUnits="objectBoundingBox"
        id="add-members-filter-4"
      >
        <feOffset dx="0" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter
        x="-20.0%"
        y="-7.2%"
        width="140.0%"
        height="129.0%"
        filterUnits="objectBoundingBox"
        id="add-members-filter-5"
      >
        <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g id="add-members-Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="add-members-menguin-welcome" transform="translate(-547.000000, -373.000000)">
        <g id="add-members-Group-4" transform="translate(552.000000, 375.000000)">
          <rect id="add-members-Rectangle-Copy" fill="#FFFFFF" x="33" y="12" width="42" height="70" />
          <g id="add-members-Rectangle-Copy-6">
            <use fill="black" fillOpacity="1" filter="url(#add-members-filter-3)" xlinkHref="#add-members-path-2" />
            <use fill="url(#add-members-linearGradient-1)" fillRule="evenodd" xlinkHref="#add-members-path-2" />
          </g>
          <polygon id="add-members-Rectangle-2" fill="#525254" points="60 37 121 37 90.5 62.4734786" />
          <g
            id="add-members-Group-8"
            filter="url(#add-members-filter-4)"
            transform="translate(62.000000, 0.000000)"
            fill="#00B8FF"
          >
            <path
              d="M12,29 C18.627417,29 24,27.0981445 24,27 C24,20.372583 18.627417,15 12,15 C5.372583,15 4.05811665e-16,20.3725926 0,27 C0,27.0693359 5.372583,29 12,29 Z"
              id="add-members-Oval-Copy"
            />
            <path
              d="M12,13 C16.4147949,13 18,9.3137085 18,6 C18,2.6862915 15.3137085,0 12,0 C8.6862915,0 6,2.6862915 6,6 C6,9.3137085 7.58520508,13 12,13 Z"
              id="add-members-Oval-2"
            />
          </g>
          <g
            id="add-members-Group-6"
            filter="url(#add-members-filter-5)"
            transform="translate(0.000000, 19.000000)"
            fill="url(#add-members-linearGradient-1)"
          >
            <rect
              id="add-members-Rectangle-Copy-7"
              transform="translate(25.000000, 62.500000) scale(1, -1) translate(-25.000000, -62.500000) "
              x="0"
              y="56"
              width="50"
              height="13"
              rx="2"
            />
            <rect
              id="add-members-Rectangle-Copy-8"
              transform="translate(25.000000, 43.500000) scale(1, -1) translate(-25.000000, -43.500000) "
              x="0"
              y="37"
              width="50"
              height="13"
              rx="2"
            />
            <rect
              id="add-members-Rectangle-Copy-9"
              transform="translate(25.000000, 24.500000) scale(1, -1) translate(-25.000000, -24.500000) "
              x="0"
              y="18"
              width="50"
              height="13"
              rx="2"
            />
            <rect
              id="add-members-Rectangle-Copy-10"
              transform="translate(25.000000, 6.500000) scale(1, -1) translate(-25.000000, -6.500000) "
              x="0"
              y="0"
              width="50"
              height="13"
              rx="2"
            />
          </g>
          <path d="M14,44 L6,44" id="add-members-Path-3-Copy" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
          <path d="M35,26 L6,26" id="add-members-Path-3-Copy" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
          <path d="M18,63 L6,63" id="add-members-Path-3-Copy" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
          <path d="M27,81 L6,81" id="add-members-Path-3-Copy" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
        </g>
      </g>
    </g>
  </svg>
);

export default AddMember;
