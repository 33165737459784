import { observable, action, runInAction } from 'mobx';
import { Member, Look, PotentialMember } from '../types';
import {
  createPotentialMember,
  deletePotentialMember,
  updatePotentialMember,
  joinEvent,
  getMember,
  getUnclaimedPotentialMembers,
  getClaimedPotentialMembers,
} from '../services/Events';
import LookStore from '../stores/LookStore';
import MemberStore from '../stores/MemberStore';

class PotentialMembers {
  @observable potentialMembers: PotentialMember[] = [];

  @action set = (potentialMembers: PotentialMember[]) => (this.potentialMembers = potentialMembers);

  @action updateLookOnMembers = (look: Look) =>
    (this.potentialMembers = this.potentialMembers.map((m: PotentialMember) => {
      if (m.role!.id === look.id) {
        return { ...m, role: look };
      } else {
        return m;
      }
    }));

  @action updateMemberOnPotentialMembers = (member: Member) =>
    (this.potentialMembers = this.potentialMembers.map((m: PotentialMember) => {
      if (m.member!.id === member.id) {
        return { ...m, member };
      } else {
        return m;
      }
    }));

  @action updateRoleAndPartyRoleOnPotentialMembers = (member: Member) =>
    (this.potentialMembers = this.potentialMembers.map((m: PotentialMember) => {
      if (m.member && m.member!.id === member.id) {
        return { ...m, member, role: member.role, partyRole: member.potentialMember!.partyRole };
      } else {
        return m;
      }
    }));

  @action setMemberOnPotentialMember = (id: string, member: Member) =>
    (this.potentialMembers = this.potentialMembers.map((m: PotentialMember) => {
      if (m.id === id) {
        return { ...m, member };
      } else {
        return m;
      }
    }));

  @action removePotentialMember = (id: string) => {
    this.potentialMembers = this.potentialMembers.filter(m => m.id !== id);
  };

  add = async (potentialMemberInfo: {
    nickname: string;
    partyRoleId: number;
    eventId: string;
    roleId: string;
    memberId?: string;
  }) => {
    try {
      const res = await createPotentialMember(
        potentialMemberInfo.nickname,
        potentialMemberInfo.partyRoleId,
        potentialMemberInfo.roleId,
        potentialMemberInfo.eventId,
        potentialMemberInfo.memberId
      );

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const data = await res.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      const potentialMember = data.data.CreatePotentialMember;
      LookStore.setPotentialMemberOnLook(potentialMember, potentialMemberInfo.roleId);
      if (potentialMemberInfo.memberId) {
        MemberStore.setPotentialMemberOnMembers(potentialMemberInfo.memberId, potentialMember);
      }

      runInAction(() => {
        this.potentialMembers = this.potentialMembers.concat(potentialMember);
      });

      return potentialMember;
    } catch (e) {
      var errorMessage = 'Failed to add potential member.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  update = async (
    id: string,
    input: { nickname?: string; roleId?: string; partyRoleId?: number; memberId?: string }
  ) => {
    try {
      const res = await updatePotentialMember(id, input);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const data = await res.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      if (input.roleId) {
        LookStore.removePotentialMemberFromLook(id, this.potentialMembers.find(m => m.id === id)!.role!.id);
        LookStore.setPotentialMemberOnLook(data.data.UpdatePotentialMember, input.roleId);
      } else {
        LookStore.updatePotentialMemberOnLook(
          data.data.UpdatePotentialMember,
          data.data.UpdatePotentialMember.role!.id
        );
      }

      if (data.data.UpdatePotentialMember.member) {
        MemberStore.updatePotentialMemberOnMembers(data.data.UpdatePotentialMember);
      }

      runInAction(() => {
        this.potentialMembers = this.potentialMembers.map(m => {
          if (m.id === id) {
            return data.data.UpdatePotentialMember;
          } else {
            return m;
          }
        });
      });
    } catch (e) {
      var errorMessage = 'Failed to update potential member.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  joinEvent = async (potentialMemberId: string, accountId: string) => {
    try {
      const joinRes = await joinEvent(potentialMemberId, accountId);
      if (joinRes.status !== 200) {
        throw new Error(joinRes.statusText);
      }

      const data = await joinRes.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      const resMember = await getMember(data.data.JoinEvent.id!);
      if (resMember.status !== 200) {
        throw new Error(resMember.statusText);
      }
      const omniRes = await resMember.json();

      if (omniRes.data.errors) {
        throw new Error(omniRes.data.errors);
      }
      const fetchedMember = omniRes.data.member;

      if (!Boolean(data.data.JoinEvent.isOwner)) {
        MemberStore.setMembers(MemberStore.members.concat(fetchedMember));
      } else {
        const currentMember = MemberStore.getSignedInMember();
        this.removePotentialMember(currentMember!.potentialMember!.id!);
        MemberStore.updateMember(fetchedMember);
      }

      LookStore.setMemberOnLook(fetchedMember, fetchedMember.role.id);
      this.setMemberOnPotentialMember(potentialMemberId, fetchedMember);
    } catch (e) {
      var errorMessage = 'Failed to join event.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(e);
      throw new Error(errorMessage);
    }
  };

  delete = async (id: string) => {
    try {
      const res = await deletePotentialMember(id);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const data = await res.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }
      const potentialMember = this.potentialMembers.find(m => m.id === id)!;
      LookStore.removePotentialMemberFromLook(id, potentialMember.role!.id);
      if (potentialMember.member) {
        MemberStore.removePotentialMemberOnMembers(potentialMember.member.id!);
      }

      runInAction(() => {
        this.potentialMembers = this.potentialMembers.filter((m: PotentialMember) => m.id !== id);
      });
    } catch (e) {
      var errorMessage = 'Failed to delete potential member.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw Error(errorMessage);
    }
  };

  @action reset = () => (this.potentialMembers = []);

  getUnclaimed = () => getUnclaimedPotentialMembers(this.potentialMembers);

  getClaimed = () => getClaimedPotentialMembers(this.potentialMembers);
}

const singleton = new PotentialMembers();
export default singleton;
