import React from 'react';

interface FormRadioItemProps extends React.HTMLProps<HTMLLabelElement & HTMLInputElement> {
  combineLabel?: Boolean;
  darkMode?: Boolean;
  inputAddOn?: String;
  errorMessage?: String;
  disabledMessage?: String;
  description?: string;
  onChange?: (event: React.ChangeEvent<HTMLLabelElement & HTMLInputElement>) => void;
}

type Props = React.FC<FormRadioItemProps>;

const FormRadioItem: Props = ({ placeholder = 'Enter something...', ...props }) => {
  const { combineLabel, label, inputAddOn, errorMessage, disabledMessage, darkMode, className, ...cleanedProps } =
    props;

  return (
    <label
      {...props}
      className={`
        group
        relative
        z-0
        mx-8
        grid
        items-center
        gap-16
        p-8
        ${className ? className : ''}
      `}
      style={{ gridTemplateColumns: 'auto 1fr' }}
    >
      <input
        data-testid="radio"
        type="radio"
        {...cleanedProps}
        className="
          peer
          absolute
          col-start-1
          row-start-1
          m-0
          h-0
          w-0
          border-0
          p-0
          opacity-0
        "
      />
      <span
        className="
          pointer-events-none 
          relative
          z-10
          col-start-1
          row-start-1
          flex
          h-16
          w-16
          items-center
          justify-center
          place-self-center
          rounded-full
          border
          border-gray-dark
          bg-gray-lighter
          group-hover:bg-brand-lighter 
          peer-checked:border-brand-darker
          peer-disabled:bg-transparent
        "
      />
      <span
        className="
            z-20
            col-start-1
            row-start-1
            block
            h-8
            w-8
            place-self-center
            rounded-full
            bg-brand-darker
            opacity-0
            peer-checked:opacity-100
          "
      />
      <div
        className="
          pointer-events-none
          z-10
          peer-checked:text-brand-darker
          peer-disabled:cursor-not-allowed
          peer-disabled:text-gray-dark
        "
      >
        <span className="relative block leading-none">{props.label}</span>
        {props.description && <span className="text-sm block">{props.description}</span>}
      </div>
      <span
        className="
            pointer-events-auto
            absolute
            inset-0
            z-0
            cursor-pointer
            border
            border-transparent
            bg-white
            transition
            group-hover:border-brand-darker
            group-hover:bg-brand-lighter
            peer-focus:border-brand
            peer-focus:shadow-form-focus
            peer-disabled:cursor-not-allowed
            peer-disabled:border-transparent
            peer-disabled:bg-gray-lighter
          "
      />
    </label>
  );
};

export default FormRadioItem;
