import React from 'react';

interface IconCheckProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconCheckProps>;

const IconCheck: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Checkmark</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.854 4.854L6.5 11.207 3.146 7.854l.708-.708L6.5 9.793l5.646-5.647.707.708z"
      />
    </svg>
  );
};

export default IconCheck;
