import React from 'react';

interface IconAlertProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconAlertProps>;

const IconAlert: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Alert</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C4.65 2 2 4.65 2 8s2.65 6 6 6 6-2.65 6-6-2.65-6-6-6zM1 8a7 7 0 1114 0A7 7 0 011 8z"
      />
      <path d="M9 4H7v5h2V4zM9 10H7v2h2v-2z" />
    </svg>
  );
};

export default IconAlert;
