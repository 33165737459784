export const pageFadeInDelayed = {
  appear: true,
  show: true,
  enter: 'transition-opacity duration-[400ms] delay-100',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
};

export const pageFadeIn = {
  appear: true,
  show: true,
  enter: 'transition-opacity duration-[400ms]',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
};
