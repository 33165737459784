export const authUrl = () => {
  switch (window.gt.env) {
    case 'local':
      return 'https://qa-auth-svc.gentux.com';
    case 'qa':
      return 'https://qa-auth-svc.gentux.com';
    case 'staging':
      return 'https://staging-auth-svc.gentux.com';
    case 'production':
      return 'https://auth-svc.generationtux.com';
  }
};

export const accountsUrl = () => {
  switch (window.gt.env) {
    case 'local':
      return 'https://qa-accounts-svc.gentux.com';
    case 'qa':
      return 'https://qa-accounts-svc.gentux.com';
    case 'staging':
      return 'https://staging-accounts-svc.gentux.com';
    case 'production':
      return 'https://accounts-svc.generationtux.com';
  }
};

export const eventsUrl = () => {
  switch (window.gt.env) {
    case 'local':
      return 'https://qa-events-svc.gentux.com';
    case 'qa':
      return 'https://qa-events-svc.gentux.com';
    case 'staging':
      return 'https://staging-events-svc.gentux.com';
    case 'production':
      return 'https://events-svc.generationtux.com';
    default:
      return 'https://qa-events-svc.gentux.com';
  }
};

export const omniUrl = () => {
  switch (window.gt.env) {
    case 'local':
      return 'https://qa-omni-svc.gentux.com';
    case 'qa':
      return 'https://qa-omni-svc.gentux.com';
    case 'staging':
      return 'https://staging-omni-svc.gentux.com';
    case 'production':
      return 'https://omni-svc.generationtux.com';
    default:
      return 'https://qa-omni-svc.gentux.com';
  }
};

export const aggUrl = () => {
  switch (window.gt.env) {
    case 'local':
      return 'https://qa-aggregation-svc.gentux.com';
    case 'qa':
      return 'https://qa-aggregation-svc.gentux.com';
    case 'staging':
      return 'https://staging-aggregation-svc.gentux.com';
    case 'production':
      return 'https://aggregation-svc.generationtux.com';
    default:
      return 'https://qa-aggregation-svc.gentux.com';
  }
};

export const paymentsUrl = () => {
  switch (window.gt.env) {
    case 'local':
      return 'https://qa-payments-svc.gentux.com';
    case 'qa':
      return 'https://qa-payments-svc.gentux.com';
    case 'staging':
      return 'https://staging-payments-svc.gentux.com';
    case 'production':
      return 'https://payments-svc.generationtux.com';
    default:
      return 'https://qa-payments-svc.gentux.com';
  }
};
