import { JsonResponse } from '../types';

export interface QueryError {
  message?: string;
  path?: string[];

  locations?: Array<{
    line: number;
    column: number;
  }>;

  trace?: Array<{
    file: string;
    line: number;
    function: string;
  }>;
}

export type GraphQLResponse<T> = JsonResponse<{
  data?: T;
  errors?: QueryError[];
}>;

/**
 * Returns the data from a GraphQL response unless there was an error, in which case
 * it raises an exception
 */
export const getDataFromResponse = async <T extends any>(response: GraphQLResponse<T>): Promise<T> => {
  const payload = await response.json();

  if (response.status >= 400 || payload?.errors) {
    const errors = payload.errors ?? [];

    const message = errors[0]?.message ?? `API request failed with HTTP ${response.status}`;

    throw new GraphQLError(message);
  }

  if (!payload || !payload.data) {
    throw new GraphQLError(`Response failed to return any data, but didn't indicate an error`);
  }

  return payload.data;
};

export class GraphQLError<T = unknown> extends Error {
  public readonly name = 'GraphQLError';

  constructor(
    message?: string,
    protected readonly httpResponse?: GraphQLResponse<T>,
    public readonly errors?: QueryError[],
  ) {
    super(message);

    Object.setPrototypeOf(this, GraphQLError.prototype);
  }

  get response() {
    // The response object is cloned in case the caller wants to run json() et al.
    // since the stream will have already been consumed by this point, meaning that
    // subsequent calls on the original instance would raise an error
    return this.httpResponse?.clone();
  }

  set response(_: Response | undefined) {
    throw new Error(`GraphQLError.response cannot be set`);
  }
}
