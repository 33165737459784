import React from 'react';
import { Disclosure } from '@headlessui/react';
import IconTriangleDown from './IconTriangleDown';
import AnimateHeight from './AnimateHeight';

interface AccordionItemProps {
  title: string;
}

type Props = React.FC<React.HTMLProps<HTMLDivElement> & AccordionItemProps>;

const AccordionItem: Props = ({ title, className, children, ...props }) => {
  return (
    <Disclosure as="div" className="border-b border-gray">
      {({ open }) => (
        <>
          <Disclosure.Button className="group flex w-full items-center justify-between py-16">
            <p className={`grow pr-16 text-left text-brand-darker`}>{title}</p>

            <IconTriangleDown
              className={`shrink-0 fill-current transition  duration-150 group-hover:text-brand-darker ${
                !open ? '' : 'rotate-90 transform'
              }`}
            />
          </Disclosure.Button>

          <AnimateHeight open={open}>
            <Disclosure.Panel static className="space-y-16 pb-32">
              {children}
            </Disclosure.Panel>
          </AnimateHeight>
        </>
      )}
    </Disclosure>
  );
};

export default AccordionItem;
