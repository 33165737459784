import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import CustomerStore from '../../stores/CustomerStore';
import auth from '../../services/Auth';
import OwnerFormStore from '../../stores/OwnerFormStore';
import EventStore from '../../stores/EventStore';
import MemberStore from '../../stores/MemberStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { getCustomerByEmailV2, validateEmail } from '../../services/Accounts';
import { Customer, FlowEventRouteProps, Member } from '../../types';
import Flow from '../../utils/HOC/Flow';
import PotentialMemberStore from '../../stores/PotentialMemberStore';
import PartyRoleStore from '../../stores/PartyRoleStore';
import { getMembersInEvent } from '../../services/Events';

interface Props extends FlowEventRouteProps<{}> {}
interface State {
  email?: string | undefined;
  existingCustomer: boolean;
  loading: boolean;
  error: string;
  validating: boolean;
}

const MEMBER_IS_SELF_ERROR_MSG = `You can not add yourself as a member to
  this event.`;

const ADD_MEMBER_ERROR_MSG = 'There was a problem adding this member. Please try again.';

const getMemberByEmail = async (eventId: string, email: string) => {
  const response = await getMembersInEvent(eventId);

  if (response.status !== 200) {
    throw new Error(`Response returned the following status code, but 200 was expected: ${response.status}`);
  }

  const body = await response.json();

  if (body.errors && body.errors.length > 0) {
    throw new Error(`Query returned errors: ${JSON.stringify(body.errors)}`);
  }

  const members: Member[] = body.data.event?.members ?? [];

  const member = members.find((member) => member.customer?.email?.toLowerCase() === email);

  return member ?? null;
};

class OwnerEmail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: OwnerFormStore.email || undefined,
      existingCustomer: false,
      error: '',
      loading: false,
      validating: false,
    };
  }

  componentDidMount() {
    CustomerStore.loadCustomer(auth.user());
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ error: '', email: e.target.value.trim() });
  };

  isEmailValid = async () => {
    this.setState(() => ({
      validating: true,
      loading: true,
    }));

    try {
      const response = await validateEmail(this.state.email!);

      const data = await response.json();

      if (data.errors) {
        const error = Array.isArray(data.errors)
          ? 'There was an error validating your email. Please try again.'
          : 'The provided email is invalid. Please confirm your entry and try again.';

        this.setState({ error });
      } else if (data.isValid === false) {
        this.setState({
          error: 'The provided email is invalid, please try another one.',
        });
      }

      return data.isValid ?? false;
    } catch (e) {
      console.error(e);

      this.setState(() => ({
        error: 'There was an error validating your email. Please try again.',
      }));

      return false;
    } finally {
      this.setState({
        validating: false,
        loading: false,
      });
    }
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(await this.isEmailValid())) {
      return;
    }

    // If entered email is the same as owner's email:
    // display error message
    if (this.state.email === CustomerStore.email) {
      this.setState({ error: MEMBER_IS_SELF_ERROR_MSG });
      return;
    }

    // If entered email is found on an existing member:
    // make existing member owner
    try {
      const existingMember = await getMemberByEmail(EventStore.event?.id!, this.state.email!.toLowerCase());

      if (existingMember) {
        if (existingMember.isOwner) {
          return this.setState({
            error: 'An owner already exists on this event with the entered email.',
          });
        }

        if (!MemberStore.hasMember(existingMember.id!)) {
          MemberStore.setMembers(MemberStore.members.concat(existingMember));
        }

        MemberStore.updateMemberOwnership(existingMember.id!, true);

        return this.props.history.push(`/event-flow/details${this.props.location.search}`);
      }
    } catch (e) {
      return this.setState({ error: ADD_MEMBER_ERROR_MSG });
    }

    this.setState({ loading: true });
    const res = await getCustomerByEmailV2(this.state.email!);

    OwnerFormStore.reset();

    if (res.status === 200) {
      const data = await res.json();
      // Attempt to find customer with
      // entered email in current org
      const customer =
        data.length > 0 ? data.find((c: Customer) => c.organization && c.organization.id === window.gt.orgId) : null;

      // If customer in current org is found:
      // autofill next memberflow form fields
      if (customer) {
        OwnerFormStore.update('email', customer.email);
        OwnerFormStore.update('firstName', customer.first_name);
        OwnerFormStore.update('lastName', customer.last_name);
        OwnerFormStore.update('phone', customer.phone);

        const newOwner = await MemberStore.addOwner(
          { customer: { ...OwnerFormStore.owner, isRegistrationComplete: true } },
          EventStore.event.id!
        );

        const partyRoleId = PartyRoleStore.partyRoles.find((p) => p.name.toLowerCase() === 'not renting')!.id!;

        await PotentialMemberStore.add({
          nickname: `${newOwner.customer.firstName} ${newOwner.customer.lastName}`,
          partyRoleId,
          eventId: EventStore.event!.id!,
          roleId: newOwner.role.id,
          memberId: newOwner.id,
        });

        return this.setState(
          () => ({ existingCustomer: true }),
          () => {
            this.props.history.push(`/event/owner/confirmation${this.props.location.search}`);
          }
        );
        // If customer in current org is not found:
        // send owner through full member flow
      } else {
        OwnerFormStore.update('email', this.state.email!);
        this.nextPage();
        return;
      }
      // If no customer is found with entered email address:
      // send owner through full member flow
    } else if (res.status === 404) {
      OwnerFormStore.update('email', this.state.email!);
      this.nextPage();
      return;
    } else {
      this.setState({ error: ADD_MEMBER_ERROR_MSG });
      return;
    }
  };

  nextPage = () => {
    this.setState({
      loading: false,
    });

    this.props.flow!(this.props.location.search);
  };

  regexValidEmail = () => {
    const { email } = this.state;

    // fail fast, check if temp email exists
    if (!email || email === '') {
      return false;
    }

    // simple regex that that will allow stuff like test@test@test.com, but
    // will keep it fast for our users.
    return /\S+@\S+\.\S+/.test(email);
  };

  missingInfo = () => !(OwnerFormStore.firstName && OwnerFormStore.lastName && OwnerFormStore.phone);

  render() {
    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          error={this.state.error}
          inputMode="email"
          key="member-email"
          label="Email"
          loading={this.state.loading}
          onChange={this.onChange}
          onSubmit={(e) => this.handleSubmit(e)}
          placeholder="What is their email?"
          property="email"
          required={true}
          title={'What is their email?'}
          type="email"
          validating={this.state.validating}
          validExternally={this.regexValidEmail()}
          value={this.state.email || ''}
        />
      </>
    );
  }
}

export default Flow(observer(OwnerEmail));
