import React from 'react';

interface IconTruckProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconTruckProps>;

const IconTruck: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 17 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-[11px] w-[17px] stroke-current text-black ${className ? className : ''}`}
    >
      <title>Truck Icon</title>
      <path fill="none" d="M6.5.5h10v8h-10zM6.5 2.5h-2l-2 2h-2v4h6v-6z" />
      <circle cx="5" cy="9" r="2" />
      <circle cx="13" cy="9" r="2" />
    </svg>
  );
};

export default IconTruck;
