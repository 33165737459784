import { Item } from '../../types';
import { getSwatchImageUrl } from '../../utils/items';
import { Link } from 'react-router-dom';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';
import SwatchComponentStyles from './SwatchComponent.module.css';

const SwatchComponent = (props: {
  swatch: Item;
  linkText: string;
  iconLeft?: boolean;
  iconRight?: boolean;
  to: string;
  className: string;
}) => {
  return (
    <Link
      key={props.swatch.id}
      className={`group relative z-10 flex items-center justify-center ${props.className}`}
      to={props.to}
    >
      <div
        key={props.swatch.id}
        style={{ backfaceVisibility: 'hidden' }}
        className={`w-full ${SwatchComponentStyles.swatch} origin-center-bottom relative w-full transform cursor-pointer opacity-100 outline-none `}
      >
        <div className="bg-white transition-transform will-change-transform after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:z-0 after:opacity-0 after:shadow-2xl after:transition group-hover:transform group-hover:after:opacity-100 group-focus:after:opacity-100 group-active:scale-[0.95]">
          <div className="relative z-20 space-y-8 p-16">
            <div className="relative z-10">
              <div className="absolute top-0 w-full translate-x-[1px] translate-y-[1px] transform bg-[url('https://gentux.imgix.net/1604575069_swatchmask.png')] bg-cover pb-[100%] opacity-[0.1] blur-[1px]" />

              <div
                className={`relative bg-cover pb-[100%] bg-blend-multiply translate-z-0 ${SwatchComponentStyles.fabric}`}
              >
                <img
                  className="absolute top-0 h-full w-full object-cover"
                  src={getSwatchImageUrl(props.swatch)}
                  alt={`${props.swatch.displayName} Swatch`}
                />
              </div>
            </div>

            {props.swatch.displayName && (
              <p className="swatch-label text-h4 text-gray-darker translate-z-0">{props.swatch.displayName}</p>
            )}
          </div>

          <div className="tran text-h6 relative z-20 flex items-center justify-center gap-4 border-t border-gray-lighter px-4 py-16 text-brand-darker transition-colors group-hover:bg-brand group-hover:text-black group-focus:bg-brand group-focus:text-black">
            {props.iconLeft && (
              <IconArrowLeft className="!text-brand-darker translate-z-0 group-hover:!text-black group-focus:!text-black" />
            )}
            <span className="translate-z-0">{props.linkText}</span>
            {props.iconRight && (
              <IconArrowRight className="!text-brand-darker translate-z-0 group-hover:!text-black group-focus:!text-black" />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SwatchComponent;
