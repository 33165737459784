import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import auth from '../../services/Auth';
import { formatPhone, validatePhoneArea } from '../../utils/utils';
import { AccessContext } from '../../utils/HOC/';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import Flow from '../../utils/HOC/Flow';
import InvitedMemberFormStore from '../../stores/InvitedMemberFormStore';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  phone?: string;
  error?: string;
  loading: boolean;
}

class Phone extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phone: '',
      error: undefined,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      phone: InvitedMemberFormStore.phone,
    });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ phone: formatPhone(e.target.value) });

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.phone && validatePhoneArea(this.state.phone)) {
      InvitedMemberFormStore.update('phone', this.state.phone);
      this.nextPage();
    } else {
      this.setState(() => ({ error: 'Area codes cannot begin with "1".' }));
    }
  };

  nextPage = () => {
    this.setState({
      error: undefined,
      loading: false,
    });

    this.props.flow!(this.props.location.search);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          backEnabled={!auth.signedIn()}
          error={this.state.error}
          inputMode="tel"
          key="phone"
          label="Phone"
          loading={this.state.loading}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          property="phone"
          required={true}
          requiredLength={14}
          title="What is your phone?"
          type="tel"
          value={this.state.phone!}
        />
      </>
    );
  }
}

export default Flow(AccessContext(observer(Phone)));
