import React from 'react';

interface IconTrashcanProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconTrashcanProps>;

const IconTrashcan: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Trashcan</title>

      <path fillRule="evenodd" clipRule="evenodd" d="M3 2H13V14H3V2ZM4 3V13H12V3H4Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 11V5H10V11H9Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7 5V11H6V5H7Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 2H14V3H2V2Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6 1H10V2H6V1Z" />
    </svg>
  );
};

export default IconTrashcan;
