import { PropsWithChildren } from 'react';

type TaggedTextProps = PropsWithChildren<{
  tag: string;
  className?: string;
}>;

/**
 * A component that allows you to render some text with a small, rectangular "tag"
 * alongside it
 */
export const TaggedText = ({ tag, className = '', children = null }: TaggedTextProps) => (
  <span className={className}>
    <span className="text-sm mr-8 inline-block border border-solid border-brand-darker px-8 py-2 font-semibold uppercase text-brand-darker !no-underline">
      {tag}
    </span>

    {children}
  </span>
);
