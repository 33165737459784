interface HelpButtonProps {
  trackerClass: string;
}

const HelpButton = ({ trackerClass }: HelpButtonProps) => {
  return (
    <>
      <a
        className={`ml-16 flex items-center self-stretch bg-gray-light px-8 text-black no-underline transition-all hover:bg-white xs:ml-32 xs:px-16 ${trackerClass}`}
        href={`${process.env.REACT_APP_ECOMM_URL}/support`}
        title="Help Page"
      >
        <div className="relative grid h-[20px] w-[20px] place-items-center rounded-[50%] border border-black">
          <div className="text-h5 translate-x-[12%]" style={{ lineHeight: 1 }}>
            ?
          </div>
        </div>
      </a>
    </>
  );
};

export default HelpButton;
