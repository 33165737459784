import { Component } from 'react';
import CheckoutStore from '../../stores/CheckoutStore';
import { format, subDays } from 'date-fns';
import { formatCurrency, toQueryString } from '../../utils/utils';
import FormFLowLogo from '../../utils/Component/FormFlowLogo';
import { observer } from 'mobx-react';
import { IsEventMember } from '../../utils/HOC';
import Flow from '../../utils/HOC/Flow';
import { EventRouteProps } from '../../types';
import Line from '../../components/Line';
import IconArrowRight from '../../components/IconArrowRight';
import DottedRow from '../../components/DottedRow';
import { Redirect } from 'react-router';
import IsMenguin from '../../components/IsMenguin';
import { SupportNumber, getSupportNumber } from '../../utils/utils';

interface Props extends EventRouteProps<any> {}

interface State {
  supportNumber: SupportNumber;
}

interface ShareASalePixelProps {
  orderId: string;
  subtotal: number;
  merchantId: number;
}

const ShareASalePixel = (props: ShareASalePixelProps) => {
  const { merchantId, orderId, subtotal } = props;

  const queryString = toQueryString({
    tracking: orderId,
    amount: `${subtotal.toFixed(2)}`,
    merchantID: merchantId,
    transtype: 'sale',
  });

  return (
    <img src={`https://www.shareasale.com/sale.cfm?${queryString}`} className="hidden" width="1" height="1" alt="" />
  );
};

class Confirmation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const organizationId = Number(process.env.REACT_APP_ORGANIZATION_ID ?? 1);

    this.state = {
      supportNumber: getSupportNumber(organizationId),
    };
  }

  userNavigatedFromCheckoutFlow() {
    return CheckoutStore.response.orderId !== '';
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  goToOrderPage = () => this.props.history.push(this.getOrderPageUrl());

  getOrderPageUrl = () => `/account/orders${this.props.location.search}`;

  render() {
    if (!this.userNavigatedFromCheckoutFlow()) {
      return <Redirect to={this.getOrderPageUrl()} />;
    }

    return (
      <>
        <FormFLowLogo />

        <div data-testid="shared-confirmation" className="container">
          <div className="grid grid-cols-12" id="confirmation">
            <div className="col-span-12 sm:col-span-8 sm:col-start-3 lg:col-span-6 lg:col-start-4">
              <h1 className="text-h1-display mb-16">Thank You!</h1>

              <h2 className="text-h3 mb-16">Order Details</h2>

              <h3 className="text-h4 mb-8">Estimated Delivery:</h3>

              <p className="text-sm mb-16">{format(subDays(CheckoutStore.response.eventDate!, 14), 'MMM Do, YYYY')}</p>

              <h3 className="text-h4 mb-8">Order ID:</h3>
              <p className="text-sm mb-16">{CheckoutStore.response.orderId}</p>

              <Line />

              <h3 className="text-h4 mb-8">Event Date:</h3>
              <p className="text-sm mb-16">{format(CheckoutStore.response.eventDate!, 'MMM Do, YYYY')}</p>

              <div className="mb-16 border border-gray-light" id="total-block">
                <div className="space-y-8 px-16 pt-16 sm:px-32 sm:pt-32">
                  <DottedRow title="Items" value={formatCurrency(CheckoutStore.response.rentalCost!)} />

                  {CheckoutStore.response.discount! > 0 && (
                    <DottedRow title="Discounts" value={`-${formatCurrency(CheckoutStore.response.discount!)}`} />
                  )}

                  <DottedRow title="Shipping" value={formatCurrency(CheckoutStore.response.shippingCost!)} />

                  {CheckoutStore.response.damageWaiverFees! > 0 && (
                    <DottedRow
                      title="Damage Waiver Fees"
                      value={formatCurrency(CheckoutStore.response.damageWaiverFees)}
                    />
                  )}

                  {CheckoutStore.response.rushCost && (
                    <DottedRow
                      title="Rush Shipping and Processing"
                      value={formatCurrency(Number(CheckoutStore.response.rushCost))}
                    />
                  )}

                  <DottedRow title="Tax" value={formatCurrency(CheckoutStore.response.tax!)} />
                </div>

                <Line className="!w-full text-gray-light" />

                <DottedRow
                  className="px-16 pb-16 sm:px-32 sm:pb-32"
                  title="Total"
                  value={formatCurrency(CheckoutStore.response.total!)}
                />
              </div>

              <p className="mb-16">{`We charged your card ending in ${CheckoutStore.response.last4} on ${format(
                CheckoutStore.response.chargeDate!,
                'MMM Do, YYYY'
              )}`}</p>

              {CheckoutStore.response.shipAddressLine1 !== null && (
                <>
                  <h3 className="text-h4 mb-8">Address:</h3>

                  <div className="mb-16">
                    <p>
                      {CheckoutStore.response.shipFirstName} {CheckoutStore.response.shipLastName}
                    </p>
                    <p>{CheckoutStore.response.shipAddressLine1}</p>
                    <p>
                      {CheckoutStore.response.shipCity}, {CheckoutStore.response.shipState}{' '}
                      {CheckoutStore.response.shipZip}
                    </p>
                  </div>
                </>
              )}

              <button
                className="tracker-cta-confirmation-view_orders-200619-111519 btn btn-info"
                onClick={this.goToOrderPage}
              >
                View Orders
                <IconArrowRight />
              </button>

              <Line className="my-16 text-gray-lighter" />

              <p className="text-sm">
                Thank you for your order! If you have any questions about your rental, we're here to help. You can check
                out our <a href={`${process.env.REACT_APP_ECOMM_URL}/support/faq`}>FAQ</a> or call{' '}
                <a href={this.state.supportNumber.asTelLink()}>{this.state.supportNumber.value}</a> to speak with
                someone on our Customer Experience team.
              </p>
            </div>
          </div>
        </div>

        <IsMenguin>
          <ShareASalePixel
            orderId={CheckoutStore.response.orderId}
            merchantId={Number(process.env.REACT_APP_SHAREASALE_MERCHANT_ID)}
            subtotal={CheckoutStore.response.rentalCost}
          />
        </IsMenguin>
      </>
    );
  }
}

export default Flow(IsEventMember(observer(Confirmation)));
