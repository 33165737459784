import React from 'react';

interface IconMagnifyingGlassProps {
  iconColor?: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconMagnifyingGlassProps>;

const IconMagnifyingGlass: Props = ({ className, iconColor = 'black', ...props }) => {
  return (
    <svg
      className={`h-16 w-16 ${className ? className : ''}`}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
    >
      <title>Magnifying Glass</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.237 14L10 10.763L10.763 10L14 13.237L13.237 14Z"
        fill={iconColor}
      />
      <circle cx="7.5" cy="7.5" r="4" stroke={iconColor} />
    </svg>
  );
};

export default IconMagnifyingGlass;
