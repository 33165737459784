import React, { Component } from 'react';

// local components
import FormSingleInput from '../../utils/Component/FormSingleInput';

// types
import { FitActions, FlowRouteProps } from '../../types';

// utils
import Flow from '../../utils/HOC/Flow';

interface Props extends FlowRouteProps<any> {
  reducer: (action: FitActions, changes: any) => void;
  weight?: string;
}

class FitWeight extends Component<Props> {
  setWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { reducer } = this.props;
    const weight = event.target.value;

    reducer('WEIGHT_SET', { weight });
  };

  onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    // prevent default
    event.preventDefault();

    this.props.flow!(`${this.props.location.search}`);
  };

  render() {
    const { weight } = this.props;

    return (
      <div data-testid="fitflow-weight">
        <FormSingleInput
          key="fit-weight"
          property="text"
          label="Weight"
          type="number"
          value={weight || ''}
          title="How much do you weigh?"
          onChange={this.setWeight}
          onSubmit={this.onSubmit}
          validExternally={weight ? weight.length > 1 : false}
        />
      </div>
    );
  }
}

export default Flow(FitWeight);
