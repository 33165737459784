import React from 'react';
import { observer } from 'mobx-react';

import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import GalleryStore from '../../../../stores/look-builder/GalleryStore';
import { prettifyCategory } from '../../../utils/utils';
import IconX from '../../../../components/IconX';

import useOptimizelyData from '../../../../utils/hooks/useOptimizelyData';
import { EXPERIMENT_LOOK_BUILDER_VARIATION } from '../../../../constants/experiments';

type Props = {
  setAnimateDirection: React.Dispatch<React.SetStateAction<string>>;
};

const GalleryHeader = (props: Props) => {
  const sideCarCategory = SideCarStore.category;

  const { category } = GalleryStore.galleryItems.filter((x) => x.category === sideCarCategory)[0];

  const optimizely = useOptimizelyData();
  const optimizelyUserBucket = optimizely.data?.lookBuilderTest;

  const showExitIcon = optimizelyUserBucket !== EXPERIMENT_LOOK_BUILDER_VARIATION || category !== 'jacket-and-pants';

  return (
    <>
      <div className="mb-4 bg-white">
        <button
          aria-label="Back To All Items"
          title="Back To All Items"
          className="tracker-btn-look_builder-back_to_list-20200508-111100 group flex w-full cursor-pointer items-center p-16 transition hover:bg-gray-lighter"
          onClick={() => {
            props.setAnimateDirection && props.setAnimateDirection('right');
            window.scrollTo(0, 0);
            SideCarStore.set({
              car: 'list',
              category: null,
              id: null,
            });
          }}
        >
          <h2 className="text-h5">{prettifyCategory(category)}</h2>
          <div className="flex grow items-center justify-end">
            {showExitIcon && <IconX className="pointer-events-none group-hover:text-brand-darker" />}
          </div>
        </button>
      </div>
    </>
  );
};

export default observer(GalleryHeader);
