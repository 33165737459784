import {
  GlobalContextTyping,
  FitShoeTypes,
  FitBuildStomach,
  FitbuildProfile,
  FitOutfitPreferences,
  CreateOrUpdateMeasurementsData,
} from '../../types';

import {
  getMeasurements,
  validateMeasurements,
  predictMeasurements,
  createOrUpdateMeasurements,
} from '../../services/Fit';

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import messages from '../../utils/fitErrorMessages';
import { snakeToCamel, roundToNearestHalf, isNumber, objectToCamel } from '../../utils/utils';
import Spinner from '../../shared/components/Spinner';
import MyAccountWrapper from '../hoc/MyAccountWrapper';
import FitProfileCard from './fit/components/FitProfileCard';
import EnterMeasurements from './fit/components/EnterMeasurements';
import { Transition } from '@headlessui/react';
import { pageFadeIn, pageFadeInDelayed } from '../../utils/Component/Animations';
import Message from '../../components/Message';
import Line from '../../components/Line';
import { Link } from 'react-router-dom';
import { completedFitProfile, updatedFitProfile } from '../../utils/metrics';
import MemberStore from '../../stores/MemberStore';
import auth from '../../services/Auth';

const OUT_OF_VALID_RANGE = 'MEASUREMENT_OUT_OF_VALID_RANGE';
const OUT_OF_NORMAL_RANGE = 'MEASUREMENT_OUT_OF_NORMAL_RANGE';
const BAD_MEASUREMENT = 'BAD_MEASUREMENT';

interface Props extends RouteComponentProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  id?: string;
  heightFeet: string;
  heightInches: string;
  weight: string;
  shoeSize: string;
  shoeWidth: FitShoeTypes;
  age: string;
  stomachProfile: FitBuildStomach;
  buildProfile: FitbuildProfile;
  fitPreference: FitOutfitPreferences;
  pantWaist: string;
  overarm: string;
  neck: string;
  armLength: string;
  chest: string;
  stomach: string;
  waist: string;
  outseam: string;
  algorithmId?: number;
  overarmError: string;
  neckError: string;
  armLengthError: string;
  chestError: string;
  stomachError: string;
  waistError: string;
  outseamError: string;
  overarmWarning: string;
  neckWarning: string;
  armLengthWarning: string;
  chestWarning: string;
  stomachWarning: string;
  waistWarning: string;
  outseamWarning: string;
  outOfNormalRangeErrors: any[];
  activeMeasurement: string;
  measurementId: string;
  heightFeetError: boolean;
  heightInchesError: boolean;
  weightError: boolean;
  shoeSizeError: boolean;
  pantWaistError: boolean;
  buildProfileError: boolean;
  stomachProfileError: boolean;
  fitPreferenceError: boolean;
  ageError: boolean;
  savingMeasurements: boolean;
  saveMeasurementsError: boolean;
  loadError: boolean;
  activeCard: string;
  isLoaded: boolean;
  onSubmitSuccess: boolean;
  submitting: boolean;
}

class FitProfile extends Component<Props, State> {
  static setMeasurements(measurements: any) {
    return {
      measurementId: measurements.id,
      algorithmId: measurements.algorithmId,
      heightFeet: measurements.height ? Math.floor(measurements.height / 12).toString() : '',
      heightInches: measurements.height ? Math.floor(measurements.height % 12).toString() : '',
      weight: measurements.weight ? measurements.weight.toString() : '',
      shoeSize: measurements.shoeSize ? measurements.shoeSize.toFixed(1).toString() + measurements.shoeWidth : '',
      shoeWidth: measurements.shoeWidth,
      age: measurements.age ? measurements.age.toString() : '',
      stomachProfile: measurements.stomachProfile || '',
      buildProfile: measurements.buildProfile || '',
      fitPreference: measurements.fitPreference || '',
      pantWaist: measurements.pantWaist ? measurements.pantWaist.toString() : '',
      overarm: measurements.overarm ? roundToNearestHalf(measurements.overarm).toString() : '',
      neck: measurements.neck ? roundToNearestHalf(measurements.neck).toString() : '',
      armLength: measurements.armLength ? roundToNearestHalf(measurements.armLength).toString() : '',
      chest: measurements.chest ? roundToNearestHalf(measurements.chest).toString() : '',
      stomach: measurements.stomach ? roundToNearestHalf(measurements.stomach).toString() : '',
      waist: measurements.waist ? roundToNearestHalf(measurements.waist).toString() : '',
      outseam: measurements.outseam ? roundToNearestHalf(measurements.outseam).toString() : '',
      isLoaded: true,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      heightFeet: '',
      heightInches: '',
      weight: '',
      shoeSize: '',
      shoeWidth: 'M',
      age: '',
      stomachProfile: undefined,
      buildProfile: undefined,
      fitPreference: undefined,
      pantWaist: '',
      overarm: '',
      neck: '',
      armLength: '',
      chest: '',
      stomach: '',
      waist: '',
      outseam: '',
      algorithmId: undefined,
      overarmError: '',
      neckError: '',
      armLengthError: '',
      chestError: '',
      stomachError: '',
      waistError: '',
      outseamError: '',
      overarmWarning: '',
      neckWarning: '',
      armLengthWarning: '',
      chestWarning: '',
      stomachWarning: '',
      waistWarning: '',
      outseamWarning: '',
      outOfNormalRangeErrors: [],
      activeMeasurement: 'neck',
      measurementId: '',
      heightFeetError: false,
      heightInchesError: false,
      weightError: false,
      shoeSizeError: false,
      pantWaistError: false,
      buildProfileError: false,
      stomachProfileError: false,
      fitPreferenceError: false,
      ageError: false,
      savingMeasurements: false,
      saveMeasurementsError: false,
      loadError: false,
      activeCard: 'fitProfile',
      isLoaded: false,
      onSubmitSuccess: false,
      submitting: false,
    };
  }

  async componentDidMount() {
    await this.getMeasurements();
  }

  // TO-DO: replace jump('#fit-profile-component')
  setActiveCard = (value: string) => this.setState({ activeCard: value });

  getMeasurements = async () => {
    try {
      const measurements = await getMeasurements();
      if (measurements !== undefined) {
        this.setState(() => FitProfile.setMeasurements(measurements));
      } else {
        this.setState({ isLoaded: true });
      }
    } catch (e) {
      let errorMessage = 'Failed to get measurements.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(errorMessage);
      this.setState({
        isLoaded: true,
        loadError: true,
      });
    }
  };

  setActiveMeasurement = (value: string) => this.setState({ activeMeasurement: value });

  isFitCardValid = () => {
    // get variables from state
    const { heightFeet, heightInches, weight, shoeSize, pantWaist, age, buildProfile, stomachProfile, fitPreference } =
      this.state;

    const heightFeetError = heightFeet === '';
    const heightInchesError = heightInches === '';
    const weightError = weight === '' || !isNumber(weight);
    const shoeSizeError = shoeSize === '';
    const pantWaistError = pantWaist === '' || !isNumber(pantWaist);
    const ageError = age === '' || !isNumber(age);
    const buildProfileError = buildProfile === undefined;
    const stomachProfileError = stomachProfile === undefined;
    const fitPreferenceError = fitPreference === undefined;

    this.setState({
      heightFeetError,
      heightInchesError,
      weightError,
      shoeSizeError,
      pantWaistError,
      ageError,
      buildProfileError,
      stomachProfileError,
      fitPreferenceError,
    });

    return !(
      heightFeetError ||
      heightInchesError ||
      weightError ||
      shoeSizeError ||
      pantWaistError ||
      ageError ||
      buildProfileError ||
      stomachProfileError ||
      fitPreferenceError
    );
  };

  handleFitProfileChange = (field: string, value: React.ChangeEvent<HTMLFormElement> | string | number | boolean) =>
    this.setState({
      [field]: this.fitProfileFieldFormatter(field, value),
      [`${field}Error`]: value === '',
    } as Pick<State, keyof State>);

  fitProfileFieldFormatter = (field: string, value: any) => {
    const fieldsToFormat = ['weight', 'pantWaist', 'age'];

    if (value && fieldsToFormat.includes(field)) {
      if (!isNumber(value)) {
        value = 0;
      }

      value = Math.max(parseInt(value, 10), 0);

      return value.toString();
    }

    return value;
  };

  handleMeasurementChange = (field: string, value: any) =>
    this.setState({
      [field]: value,
      [`${field}Error`]: value === '' ? 'This measurement is required.' : '',
    } as Pick<State, keyof State>);

  fitProfileData = () => ({
    height: (parseInt(this.state.heightFeet, 10) * 12 + parseInt(this.state.heightInches, 10)).toString(),
    weight: this.state.weight,
    neck: this.state.neck,
    chest: this.state.chest,
    overarm: this.state.overarm,
    armLength: this.state.armLength,
    stomach: this.state.stomach,
    waist: this.state.waist,
    outseam: this.state.outseam,
    shoeSize: parseFloat(this.state.shoeSize.slice(0, -1)).toString(),
    shoeWidth: this.state.shoeWidth,
    fitPreference: this.state.fitPreference,
    buildProfile: this.state.buildProfile,
    stomachProfile: this.state.stomachProfile,
    pantWaist: this.state.pantWaist,
    age: this.state.age,
    algorithmId: this.state.algorithmId || 1,
  });

  mapFitProfileDataForValidation = () => ({
    height: (parseInt(this.state.heightFeet, 10) * 12 + parseInt(this.state.heightInches, 10)).toString(),
    weight: this.state.weight,
    neck: this.state.neck,
    chest: this.state.chest,
    overarm: this.state.overarm,
    arm_length: this.state.armLength,
    stomach: this.state.stomach,
    waist: this.state.waist,
    outseam: this.state.outseam,
    shoe_size: parseFloat(this.state.shoeSize.slice(0, -1)).toString(),
    shoe_width: this.state.shoeWidth,
    fit_preference: this.state.fitPreference,
    build_profile: this.state.buildProfile,
    stomach_profile: this.state.stomachProfile,
    pant_waist: this.state.pantWaist,
    age: this.state.age,
    algorithm_id: this.state.algorithmId || 1,
  });

  /**
   * If the user is under 16 move on to the measurements form,
   * otherwise generate measurements, fit card, and display sizes
   */
  handleFitProfileSubmit = async () => {
    if (!this.isFitCardValid()) {
      return;
    }

    if (parseInt(this.state.age, 10) < 16) {
      return this.setState({ activeCard: 'enterMeasurements' });
    }

    this.setState({ submitting: true });

    const fitData = this.fitProfileData();

    try {
      const result = await predictMeasurements(fitData);

      const data = await result.json();

      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors[0].message);
      }

      if (result.status !== 200) {
        throw new Error(data);
      }

      this.saveFitProfile(Object.assign({ is_machine_tailored: 1 }, fitData, objectToCamel(data)));
    } catch (e) {
      let errorMessage = 'Failed to save fit profile.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      this.handleFitProfileSaveError(errorMessage);
    }
  };

  handleFitProfileSaveError = (error: any) => {
    console.error(error);
    this.setState({ activeCard: 'error' });
  };

  saveFitProfile = async (fitData = this.fitProfileData()) => {
    this.setState({ submitting: true });

    const nextState: Pick<State, keyof State & CreateOrUpdateMeasurementsData> = {
      submitting: false,
      onSubmitSuccess: true,
    };

    const request = this.state.measurementId
      ? {
          ...fitData,
          id: this.state.measurementId,
        }
      : fitData;

    try {
      const result = await createOrUpdateMeasurements(request);

      const data = await result.json();

      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors[0].message);
      }

      if (result.status !== 200) {
        throw new Error(data);
      }

      // skipping tracking event if logged in as CX because MemberStore isn't loaded
      // @TODO remove this after rewriting CX log in to load MemberStore
      if (!auth.user().isCx) {
        const currentMember = MemberStore.getSignedInMember()!;

        currentMember.isMeasured ? updatedFitProfile() : completedFitProfile();
      }

      // TO-DO: replace jump('#fit-profile-component');

      this.setState({
        ...nextState,
        id: data.createOrUpdateMeasurement.id,
      });
    } catch (e) {
      let errorMessage = 'Failed to update measurements.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      this.handleFitProfileSaveError(errorMessage);
    }
  };

  handleMeasurementSubmit = async () => {
    if (!this.measurementsValid()) {
      return;
    }

    this.setState({ savingMeasurements: true });

    try {
      const result = await validateMeasurements(this.mapFitProfileDataForValidation());

      const data = await result.json();

      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors[0].message);
      }

      if (result.status !== 200) {
        throw new Error(data);
      }

      this.setState({ savingMeasurements: false });

      if (data.length > 0) {
        return this.handleValidationErrors(data);
      }

      this.saveFitProfile();
    } catch (e) {
      this.setState({
        savingMeasurements: false,
        saveMeasurementsError: true,
        activeCard: 'error',
      });
    }
  };

  handleValidationErrors = (response: any) => {
    const errors = response.reduce(
      (a: any, m: any) => {
        const newM = Object.assign(m, {
          field: snakeToCamel(m.data.measurement),
        });

        const index = newM.category === OUT_OF_NORMAL_RANGE ? 1 : 0;

        a[index].push(newM);

        return a;
      },
      [[], []]
    );

    if (errors[0].length > 0) {
      return this.handleOutOfValidRangeErrors(errors[0]);
    }

    this.handleOutOfNormalRangeErrors(errors[1]);
  };

  handleOutOfValidRangeErrors = (errors: any) =>
    errors.forEach((e: any) => {
      const display = e.data.measurement === 'arm_length' ? 'sleeve' : e.data.measurement;

      if (e.category === OUT_OF_VALID_RANGE) {
        this.setState({
          [`${e.field}Error`]: messages.getOutOfValidRangeMessage(display, e.data),
        } as unknown as Pick<State, keyof State>);
      } else if (e.category === BAD_MEASUREMENT) {
        this.setState({
          [`${e.field}Error`]: `Can you double-check this? ${messages.getBadMeasurementMessage(e.data.code)}`,
        } as unknown as Pick<State, keyof State>);
      }
    });

  handleOutOfNormalRangeErrors = (errors: any) => {
    const outOfNormalRangeErrors: any = [];

    errors.forEach((e: any) => {
      const display = e.data.measurement === 'arm_length' ? 'sleeve' : e.data.measurement;

      const message = messages.getOutOfNormalRangeListItemMessage(display, e.data);

      outOfNormalRangeErrors.push(message);

      this.setState({
        [`${e.field}Warning`]: messages.getOutOfNormalRangeMessage(display, e.data),
      } as unknown as Pick<State, keyof State>);
    });

    this.setState({ outOfNormalRangeErrors });
  };

  measurementsValid = () => {
    let overarmError = '';
    let neckError = '';
    let armLengthError = '';
    let chestError = '';
    let stomachError = '';
    let waistError = '';
    let outseamError = '';

    const errorMessage = 'This measurement is required.';
    if (this.state.overarm === '') overarmError = errorMessage;
    if (this.state.neck === '') neckError = errorMessage;
    if (this.state.armLength === '') armLengthError = errorMessage;
    if (this.state.chest === '') chestError = errorMessage;
    if (this.state.stomach === '') stomachError = errorMessage;
    if (this.state.waist === '') waistError = errorMessage;
    if (this.state.outseam === '') outseamError = errorMessage;

    this.setState({
      overarmError,
      neckError,
      armLengthError,
      chestError,
      stomachError,
      waistError,
      outseamError,
    });

    return !(overarmError || neckError || armLengthError || chestError || stomachError || waistError || outseamError);
  };

  resetOutOfNormalRangeErrors = () => this.setState({ outOfNormalRangeErrors: [] });

  render() {
    const { isLoaded, loadError } = this.state;

    return (
      <>
        <Transition {...pageFadeIn}>
          <h1 className="text-h2-display">Fit Profile</h1>
          <Line />
        </Transition>
        <Transition {...pageFadeInDelayed}>
          <p className="mb-32">
            Our eTailor algorithm has fit over 200,000 people just like you. To ensure proper fit, we ship all orders 2
            weeks in advance of your wear date and offer FREE fit replacements if there are any&nbsp;size&nbsp;issues.
          </p>

          {!isLoaded && !loadError && <Spinner type="minimal" />}

          {isLoaded && loadError && (
            <Message type="error">There was an issue loading this page. Please refresh and try again.</Message>
          )}

          {isLoaded && !loadError && (
            <>
              {this.state.activeCard === 'fitProfile' && (
                <FitProfileCard
                  heightFeet={this.state.heightFeet}
                  heightInches={this.state.heightInches}
                  weight={this.state.weight}
                  shoeSize={this.state.shoeSize}
                  pantWaist={this.state.pantWaist}
                  age={this.state.age}
                  stomachProfile={this.state.stomachProfile}
                  buildProfile={this.state.buildProfile}
                  fitPreference={this.state.fitPreference}
                  heightFeetError={this.state.heightFeetError}
                  heightInchesError={this.state.heightInchesError}
                  weightError={this.state.weightError}
                  shoeSizeError={this.state.shoeSizeError}
                  pantWaistError={this.state.pantWaistError}
                  ageError={this.state.ageError}
                  buildProfileError={this.state.buildProfileError}
                  stomachProfileError={this.state.stomachProfileError}
                  fitPreferenceError={this.state.fitPreferenceError}
                  handleSubmit={this.handleFitProfileSubmit}
                  handleChange={this.handleFitProfileChange}
                  isLoaded={this.state.isLoaded}
                  loadError={this.state.loadError}
                  submitting={this.state.submitting}
                />
              )}

              {this.state.activeCard === 'error' && (
                <div className="my-32">
                  <Message type="error">
                    <div className="p-32">
                      <h2 className="text-h2 text-white">Apologies,</h2>
                      <p className="mb-32 mt-16">
                        Please retry submitting your fit profile. If you need a hand reach out to our{' '}
                        <Link to="/support" className="text-white underline hover:no-underline">
                          customer service team
                        </Link>
                        .
                      </p>
                      <button
                        className="tracker-cta-fit_profile-retry-200619-111519 btn btn-danger-inverted"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        Retry
                      </button>
                    </div>
                  </Message>
                </div>
              )}

              {this.state.activeCard === 'enterMeasurements' && (
                <EnterMeasurements
                  overarm={this.state.overarm}
                  neck={this.state.neck}
                  armLength={this.state.armLength}
                  chest={this.state.chest}
                  stomach={this.state.stomach}
                  waist={this.state.waist}
                  outseam={this.state.outseam}
                  overarmError={this.state.overarmError}
                  neckError={this.state.neckError}
                  armLengthError={this.state.armLengthError}
                  chestError={this.state.chestError}
                  stomachError={this.state.stomachError}
                  waistError={this.state.waistError}
                  outseamError={this.state.outseamError}
                  overarmWarning={this.state.overarmWarning}
                  neckWarning={this.state.neckWarning}
                  armLengthWarning={this.state.armLengthWarning}
                  chestWarning={this.state.chestWarning}
                  stomachWarning={this.state.stomachWarning}
                  waistWarning={this.state.waistWarning}
                  outseamWarning={this.state.outseamWarning}
                  outOfNormalRangeErrors={this.state.outOfNormalRangeErrors}
                  activeMeasurement={this.state.activeMeasurement}
                  saveError={this.state.saveMeasurementsError}
                  isSubmitting={this.state.savingMeasurements}
                  setActiveMeasurement={this.setActiveMeasurement}
                  setActiveCard={this.setActiveCard}
                  handleChange={this.handleMeasurementChange}
                  handleSubmit={this.handleMeasurementSubmit}
                  saveMeasurements={this.saveFitProfile}
                  resetOutOfNormalRangeErrors={this.resetOutOfNormalRangeErrors}
                />
              )}

              {this.state.onSubmitSuccess && (
                <Message className="mt-16" type="success">
                  Fit Updated!
                </Message>
              )}
            </>
          )}
        </Transition>
      </>
    );
  }
}

export default observer(MyAccountWrapper(FitProfile));
