import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { format, addDays } from 'date-fns';

import CustomerStore from '../../stores/CustomerStore';
import { updateAddress } from '../../services/Accounts';
import { updateShipment, validateAddress } from '../../services/Warehouse';

import { formatCurrency } from '../../utils/utils';
import { url } from '../../utils/window';
import { Address, Fee, Order, GlobalContextTyping, Member } from '../../types';

import Spinner from '../../shared/components/Spinner';
import MyAccountWrapper from '../hoc/MyAccountWrapper';
import EditAddressForm from './orders/components/EditAddressForm';
import { orderHasShipped } from '../../utils/checkout';
import Line from '../../components/Line';
import Message from '../../components/Message';
import IconArrowLeft from '../../components/IconArrowLeft';

const DD: React.SFC<{}> = ({ children }) => {
  return <dd className="m-0 p-0">{children}</dd>;
};

const DT: React.SFC<{}> = ({ children }) => {
  return <dt className="m-0 p-0">{children}</dt>;
};

interface Props extends RouteComponentProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  editing: boolean;
  address?: Address;
  submitting: boolean;
  error: string;
  order?: Order;
  success: boolean;
  isLoading: boolean;
  loadError: boolean;
}

class OrderDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false,
      address: undefined,
      order: undefined,
      submitting: false,
      error: '',
      success: false,
      isLoading: true,
      loadError: false,
    };
  }

  async componentDidMount() {
    try {
      await CustomerStore.getMembers();
      const order: Order | undefined = CustomerStore.fetchedMembers.reduce((order, m: Member) => {
        const foundOrder: Order | undefined = m.orders!.find((o) => o.id! === this.props.match.params.orderId);
        if (foundOrder !== undefined) {
          return foundOrder;
        }
        return order;
      }, {} as Order);

      this.setState({
        order,
        address: order!.address!,
        isLoading: false,
        editing: !!url.param(this.props.location.search, 'editing'),
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isLoading: false,
        loadError: true,
      });
    }
  }

  editAddress = () =>
    this.setState(() => ({
      editing: true,
      address: this.state.order!.address!,
    }));

  cancelEdit = () =>
    this.setState(() => ({
      editing: false,
      address: this.state.order!.address!,
      error: '',
    }));

  updateAddressState = (address: Address) => {
    this.setState((state) => ({
      order: {
        ...state.order,
        address: address,
      },
      editing: false,
      submitting: false,
      address,
    }));
  };

  saveAddress = async () => {
    this.setState(() => ({
      submitting: true,
      error: '',
    }));
    const shipmentAddress = {
      id: this.state.address!.id,
      firstName: this.state.address!.firstName,
      lastName: this.state.address!.lastName,
      addressLine1: this.state.address!.addressLine1,
      addressLine2: this.state.address!.addressLine2,
      city: this.state.address!.city,
      state: this.state.address!.state,
      country: 'US',
      zip: this.state.address!.zip,
      isDefault: Boolean(this.state.address!.isDefault),
    };

    try {
      const addressRes = await updateAddress(shipmentAddress);
      const data = await addressRes.json();

      if (data.errors && data.errors.length > 0) {
        throw data.errors[0].message;
      }

      if (this.state.order!.shipment) {
        await updateShipment({
          id: this.state.order!.shipment.id!,
          firstName: this.state.address!.firstName,
          lastName: this.state.address!.lastName,
          addressLine1: this.state.address!.addressLine1,
          addressLine2: this.state.address!.addressLine2,
          city: this.state.address!.city,
          state: this.state.address!.state,
          zipCode: this.state.address!.zip,
        });

        this.updateAddressState(data.data.updateAddress);
      } else {
        this.updateAddressState(data.data.updateAddress);
      }
    } catch (e) {
      this.setState(() => ({
        error:
          'Error updating address on your shipment. Please contact customer service. We will be happy to assist you.',
        submitting: false,
      }));
    }
  };

  handleAddressChange = (
    key:
      | 'firstName'
      | 'lastName'
      | 'addressLine1'
      | 'addressLine2'
      | 'city'
      | 'state'
      | 'zip'
      | 'country'
      | 'isDefault',
    value: string | number | boolean | undefined
  ) =>
    this.setState((state) => {
      const address = { ...state.address!, [key]: value };
      return { address };
    });

  validateAddress = async () => {
    try {
      const { validatedAddress } = await validateAddress({
        addressLine1: this.state.address!.addressLine1,
        addressLine2: this.state.address!.addressLine2 || '',
        city: this.state.address!.city,
        state: this.state.address!.state,
        zip: this.state.address!.zip,
        country: this.state.address!.country,
      });

      return new Promise((resolve) => {
        this.setState(
          (state) => ({
            address: { ...state.address!, ...validatedAddress },
          }),
          () => resolve(true)
        );
      });
    } catch (e) {
      let errorMessage = 'Failed to create HTO event.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      this.setState({
        error: errorMessage,
      });
    }
  };

  shouldShowAddressEditForm = () =>
    this.state.order!.address && this.state.editing && !orderHasShipped(this.state.order!);

  render() {
    const { isLoading, loadError } = this.state;

    return (
      <>
        <h2 className="text-h2-display">Order Details</h2>
        <Line className="my-16 !w-full" />

        {isLoading && !loadError && <Spinner type="minimal" />}

        {!isLoading && loadError && (
          <Message type="error">There was an issue loading this page. Please refresh and try again.</Message>
        )}

        {!isLoading && !loadError && (
          <>
            <div className="mb-16 grid grid-cols-12">
              <div className="col-span-6">
                <DT>
                  <h3 className="text-h5">Estimated Delivery</h3>
                </DT>
                <DD>
                  <p className="text-sm mb-4">
                    {this.state.order!.isSwatch
                      ? format(addDays(this.state.order!.needsByDate!, 3), 'MMM Do, YYYY')
                      : format(this.state.order!.needsByDate!, 'MMM Do, YYYY')}
                  </p>
                </DD>
                <DT>
                  <h3 className="text-h5">Order ID:</h3>
                </DT>
                <DD>
                  <p className="text-sm mb-4">{this.state.order!.gtuxId}</p>
                </DD>
                <DT>
                  <h3 className="text-h5">Order Status:</h3>
                </DT>
                <DD>
                  <p className="text-sm mb-4">
                    {!this.state.order!.shipment || ['BLOCKED', 'CREATED'].includes(this.state.order!.shipment.status!)
                      ? this.state.order!.status!.replace('_', ' ')
                      : this.state.order!.shipment.status!.replace('_', ' ')}
                  </p>
                </DD>

                <Line />

                <DT>
                  <h3 className="text-h5">Event ID:</h3>
                </DT>
                <DD>
                  <p className="text-sm mb-4">{this.state.order!.member!.gtEvent!.gtuxId!}</p>
                </DD>
                <DT>
                  <h3 className="text-h5">Event Date:</h3>
                </DT>
                <DD>
                  <p className="text-sm mb-4">
                    {format(this.state.order!.member!.gtEvent!.startDate!, 'MMM Do, YYYY')}
                  </p>
                </DD>
              </div>
              <div className="col-span-6">
                <table className="w-full">
                  <tbody>
                    <tr className="odd:bg-gray-light even:bg-white">
                      <th align="left" className="px-16 py-8">
                        <h3 className="text-h5">Items</h3>
                      </th>
                      <td align="right" className="px-16 py-8">
                        {formatCurrency(this.state.order!.subtotal!)}
                      </td>
                    </tr>
                    <tr className="odd:bg-gray-light even:bg-white">
                      <th align="left" className="px-16 py-8">
                        <h3 className="text-h5">Shipping</h3>
                      </th>
                      <td align="right" className="px-16 py-8">
                        {this.state.order!.fees!.filter((fee: Fee) => fee.type === 'SHIPPING_FEE').length > 0
                          ? formatCurrency(
                              this.state.order!.fees!.filter((fee: Fee) => fee.type === 'SHIPPING_FEE')[0].amount!
                            )
                          : '$0.00'}
                      </td>
                    </tr>
                    {this.state
                      .order!.fees!.filter((fee: Fee) => fee.type !== 'SHIPPING_FEE')
                      .map((fee: Fee) => (
                        <tr key={fee.id} className="odd:bg-gray-light even:bg-white">
                          <th align="left" className="px-16 py-8">
                            <h3 className="text-h5">
                              {fee.type === 'RUSH_FEE' ? 'Rush Shipping and Processing' : fee.type!.replace(/_/g, ' ')}
                            </h3>
                          </th>
                          <td align="right" className="px-16 py-8">
                            {formatCurrency(fee.amount!)}
                          </td>
                        </tr>
                      ))}
                    <tr className="odd:bg-gray-light even:bg-white">
                      <th align="left" className="px-16 py-8">
                        <h3 className="text-h5">Tax</h3>
                      </th>
                      <td align="right" className="px-16 py-8">
                        {formatCurrency(this.state.order!.tax!)}
                      </td>
                    </tr>
                    <tr className="odd:bg-gray-light even:bg-white">
                      <th align="left" className="px-16 py-8">
                        <h3 className="text-h5">Total</h3>
                      </th>
                      <td align="right" className="px-16 py-8">
                        {formatCurrency(this.state.order!.total!)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mb-16 bg-gray-light">
              {this.state.address && !this.state.editing && (
                <>
                  <h3 className="text-h3 px-16 py-12">Shipping Address</h3>
                  <div className="bg-white p-16">
                    <address style={{ fontStyle: 'normal' }}>
                      <p>
                        <strong>
                          {this.state.address.firstName} {this.state.address.lastName}
                        </strong>
                        <br />
                        {this.state.address.addressLine1} {this.state.address.addressLine2} <br />
                        {this.state.address.city}, {this.state.address.state} &nbsp;
                        {this.state.address.zip}
                      </p>
                    </address>
                    {(!this.state.order!.shipment ||
                      !['STAGED', 'SHIPPED'].includes(this.state.order!.shipment!.status!)) && (
                      <button
                        className="tracker-cta-order_details-edit-200619-111519 btn btn-sm btn-default-outline mt-12"
                        onClick={() => this.editAddress()}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </>
              )}
              {this.shouldShowAddressEditForm() && (
                <div className="bg-white p-16">
                  <EditAddressForm
                    address={this.state.address!}
                    cancelEdit={this.cancelEdit}
                    handleAddressChange={this.handleAddressChange}
                    saveAddress={this.saveAddress}
                    error={this.state.error}
                    success={this.state.success}
                    validateAddress={this.validateAddress}
                    submitting={this.state.submitting}
                    order={this.state.order}
                  />
                </div>
              )}
              {!this.state.order!.address && (
                <div className="bg-white p-16">
                  <a
                    href={`/fit-flow/invited/height?memberId=${this.state.order!.member!.id}&eventId=${
                      this.state.order!.member!.gtEvent!.id
                    }`}
                  >
                    Enter Shipping Address
                  </a>
                </div>
              )}
            </div>
            <>
              <>
                <button
                  className="tracker-cta-order_details-back-200619-111519 btn btn-info mt-12"
                  onClick={() => this.props.history.push('/account/orders')}
                >
                  <IconArrowLeft />
                  Back to Orders
                </button>
              </>
            </>
          </>
        )}
      </>
    );
  }
}

export default observer(MyAccountWrapper(OrderDetails));
