import { useRef, useEffect, useState, useCallback, ReactNode, RefObject } from 'react';
import useClickOutside from '../hooks/useClickOutside';
import IconX from '../../components/IconX';

/**
 * Temporary extension of the `HTMLDialogElement` interface, to give it more
 * recent properties & methods until we're able to update TypeScript (which
 * has these fields in `lib.dom.d.ts`)
 *
 * @todo Remove when using TypeScript >= 4.7
 */
declare global {
  type CloseDialog = (() => void) | (<T extends string = string>(returnValue?: T) => void);

  export interface HTMLDialogElement {
    showModal: () => void;
    close: CloseDialog;
    open: boolean;
  }
}

type RenderArgs = {
  /**
   * A ref that should be passed to the topmost element of the content rendered
   * within a modal instance
   *
   * This will be used to enable the "click outside to close" functionality
   */
  contentRef: RefObject<HTMLElement | null>;

  /**
   * A callback that can set the scroll position of the modal's `dialog` element
   * back to the top
   */
  scrollToTop: () => void;
};

type LabelAttribute =
  | {
      'aria-label': string;
      'aria-labelledby': never;
    }
  | {
      'aria-label': never;
      'aria-labelledby': string;
    };

type ModalProps = {
  /**
   * A callback that runs after the modal is closed
   */
  onClose: () => void;

  /**
   * (Optional) Text to label the close button with
   *
   * When not provided, and when on a mobile viewport, the close button is just
   * the "X" icon
   */
  closeButtonLabel?: string;

  /**
   * A callback used to render the modal's inner content
   */
  render: (args: RenderArgs) => ReactNode;

  /**
   * An ID that will applied to the `<dialog>` element
   */
  id: string;

  className?: string;

  /**
   * (Optional) A label that will be used with the `aria-label` attribute
   *
   * If not provided, it's recommended to use the `labelledBy` prop to point
   * to an element within the dialog's content that will be used instead
   */
  label?: string;

  /**
   * (Optional) An ID pointing to an element that will be used as the dialog's label
   *
   * If not provided, it's recommended to use the `label` prop to specify a label
   * instead
   */
  labelledBy?: string;
};

const Modal = ({ id, className, onClose, closeButtonLabel, render, label, labelledBy }: ModalProps) => {
  const [hasShownDialog, setHasShownDialog] = useState(false);

  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const contentRef = useRef<HTMLElement | null>(null);

  const closeDialog = useCallback(() => {
    if (dialogRef.current?.open) {
      dialogRef.current?.close();

      onClose();
    }
  }, [onClose]);

  const handleEscKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Escape' || !dialogRef.current?.open) {
        return;
      }

      closeDialog();
    },
    [closeDialog]
  );

  const scrollToTop = () => {
    dialogRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    dialogRef.current?.showModal();

    document.body.classList.add('overflow-hidden');

    setHasShownDialog(true);

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [handleEscKey]);

  useClickOutside(contentRef, () => {
    if (hasShownDialog) {
      closeDialog();
    }
  });

  const labelAttribute = {} as LabelAttribute;

  if (label) {
    labelAttribute['aria-label'] = label;
  } else if (labelledBy) {
    labelAttribute['aria-labelledby'] = labelledBy;
  }

  return (
    <dialog
      id={id}
      className={`m-auto w-3/4 bg-white shadow-2xl shadow-gray-darker backdrop:backdrop-blur sm:w-2/3 md:w-2/4 xl:w-2/5 ${
        className ?? ''
      }`}
      ref={dialogRef}
      {...labelAttribute}
    >
      <button onClick={(_) => closeDialog()} className="absolute right-0 top-0 flex cursor-pointer items-center p-16">
        {closeButtonLabel ? <span className="text-xs mr-4 hidden md:block">{closeButtonLabel}</span> : null}
        <IconX className="h-24 w-24 text-gray-darker" />
      </button>

      {render({ contentRef, scrollToTop })}
    </dialog>
  );
};

export default Modal;
