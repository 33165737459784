import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { validateEmailIsAvailable, validateEmail } from '../../services/Accounts';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import Flow from '../../utils/HOC/Flow';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import InvitedMemberFormStore from '../../stores/InvitedMemberFormStore';
import { Link } from 'react-router-dom';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
  loginUrl?: string;
}

interface State {
  tempEmail?: string;
  error?: string;
  loading: boolean;
  validating: boolean;
}

class Email extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tempEmail: '',
      error: undefined,
      loading: false,
      validating: false,
    };
  }

  componentDidMount() {
    this.setState({
      tempEmail: InvitedMemberFormStore.email,
    });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ tempEmail: e.target.value });

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(await this.isEmailValid())) {
      return;
    }
    this.setState(
      () => ({
        loading: true,
      }),
      async () => {
        try {
          const res = await validateEmailIsAvailable(this.state.tempEmail!);

          if (res.status === 422) {
            throw new Error((await res.json())['email']);
          }

          if (res.status !== 200) {
            throw new Error('There has been an error validating your email. Please try again.');
          }

          InvitedMemberFormStore.update('email', this.state.tempEmail!);
          this.nextPage();
        } catch (e) {
          let errorMessage = 'Failed to validate email.';

          if (e instanceof Error) {
            errorMessage = e.message;
          }

          this.setState(() => ({
            error: errorMessage,
            loading: false,
          }));
        }
      }
    );
  };

  isEmailValid = async () => {
    this.setState(() => ({
      validating: true,
      loading: true,
    }));

    try {
      const response = await validateEmail(this.state.tempEmail!);

      const data = await response.json();

      if (data.errors) {
        const error = Array.isArray(data.errors)
          ? 'There was an error validating your email. Please try again.'
          : 'The provided email is invalid. Please confirm your entry and try again.';

        this.setState({ error });
      } else if (data.isValid === false) {
        this.setState({
          error: 'The provided email is invalid, please try another one.',
        });
      }

      return data.isValid ?? false;
    } catch (e) {
      console.error(e);

      this.setState(() => ({
        error: 'There was an error validating your email. Please try again.',
      }));

      return false;
    } finally {
      this.setState({
        validating: false,
        loading: false,
      });
    }
  };

  regexValidEmail = () => {
    const { tempEmail } = this.state;

    // fail fast, check if temp email exists
    if (!tempEmail || tempEmail === '') {
      return false;
    }

    // simple regex that that will allow stuff like test@test@test.com, but
    // will keep it fast for our users.
    return /\S+@\S+\.\S+/.test(tempEmail);
  };

  nextPage = () => {
    this.setState({
      error: undefined,
    });

    this.props.flow!(this.props.location.search);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          error={this.state.error!}
          inputMode="email"
          key="email"
          label="Email"
          loading={this.state.loading}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          property="email"
          title="What is your email?"
          type="email"
          validating={this.state.validating}
          validExternally={this.regexValidEmail()}
          value={this.state.tempEmail!}
          buttonText="Begin Your Journey"
        />

        <p className="pt-32 text-center">
          {'Already have an account? '}
          <Link
            to={`/invited/login${this.props.location.search}`}
            className="tracker-link-invited_email-login-200619-111857"
          >
            {'Login'}
          </Link>
        </p>
      </>
    );
  }
}

export default Flow(observer(Email));
