import { Component } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { GlobalContextTyping } from '../../types';
import { AccessContext } from '../../utils/HOC/';
import IconArrowRight from '../../components/IconArrowRight';
import Line from '../../components/Line';
import LookStore from '../../stores/LookStore';
import EventStore from '../../stores/EventStore';

interface Props extends RouteComponentProps<any> {
  globalContext?: GlobalContextTyping;
}
interface State {
  error?: string;
  loading: boolean;
}

class Confirmation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: undefined,
      loading: false,
    };
  }

  eventHasCompletedLook = () => LookStore.looks.some((l) => l?.items?.length || l?.bundles?.length);

  nextPage = () => {
    this.setState({
      error: undefined,
    });

    this.props.history.push(
      this.eventHasCompletedLook()
        ? `/event-flow/looks?eventId=${EventStore.event.id}`
        : `/account/orders${this.props.location.search}`
    );
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-md">
            <h1 className="text-h2-display">Your home try-on is on the&nbsp;way.</h1>

            <Line />

            <p className="mb-32 text-gray-dark">
              Our staff is working on getting your order together. You will receive your home try-on outfit within 6
              business days and we will send you an email with tracking information when your home try-on ships. If you
              have questions, our support team is here to help.&nbsp;Thanks!
            </p>

            {this.eventHasCompletedLook() ? (
              <button
                role="link"
                onClick={() => this.nextPage()}
                className="tracker-btn-hto_confirmation-return_to_looks-240905-135601 btn btn-info w-full"
              >
                Return To Your Looks <IconArrowRight />
              </button>
            ) : (
              <button
                role="link"
                onClick={() => this.nextPage()}
                className="tracker-btn-hto_confirmation-view_orders-200619-110945 btn btn-info w-full"
              >
                View Orders <IconArrowRight />
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default AccessContext(observer(Confirmation));
