import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import type { GlobalContextTyping, FlowRouteProps } from '../../types';
import Loading from '../../utils/Component/Loading';
import Message from '../../components/Message';
import EventFormStore from '../../stores/EventFormStore';
import PartyRoleStore from '../../stores/PartyRoleStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import Flow from '../../utils/HOC/Flow';
import { AccessContext } from '../../utils/HOC';
import IconButton from './IconButton';
import IconLetter from '../../components/IconLetter';
import IconGroomsmanMic from '../../components/IconGroomsmanMic';
import IconBrideFlowers from '../../components/IconBrideFlowers';
import IconGroomJacket from '../../components/IconGroomJacket';
import { SVG } from '../../types';
import { Link } from 'react-router-dom';

type RoleOption = { icon: React.ReactElement<SVG>; label: string; roleName: string };

const roleOptions: { [eventType: string]: RoleOption[] } = {
  Wedding: [
    { icon: <IconGroomJacket />, label: 'Groom', roleName: 'Groom' },
    { icon: <IconBrideFlowers />, label: 'Bride', roleName: 'Bride' },
    { icon: <IconGroomsmanMic />, label: 'Groomsman', roleName: 'Groomsmen' },
    { icon: <IconLetter />, label: 'Other', roleName: 'Other' },
  ],
};

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}
const Role = ({ flow, location }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [eventType, setEventType] = useState<string>('');

  useEffect(() => {
    async function loadData() {
      try {
        await PartyRoleStore.loadIfEmpty();

        const type = EventFormStore.type ?? 'Wedding';

        setEventType(type);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
        setError('There was a problem loading roles.');
      }
    }

    loadData();
  }, []);

  const onClickHandler = (roleName: string) => {
    const role = PartyRoleStore.findByName(roleName, eventType);

    if (!role) {
      setError('There was a problem finding a role.');
      return;
    }

    EventFormStore.update('partyRoleId', role!.id);
    flow!(location.search);
  };

  return (
    <>
      <FormFlowLogo />

      <div className="container">
        <div className="mx-auto max-w-xl">
          {loading && <Loading />}
          {error && (
            <Message type="error">
              Oops, we encountered an issue while processing your request. Please refresh the page and try again. If the
              issue persists, please reach out to our{' '}
              <Link to={`${process.env.REACT_APP_ECOMM_URL}/support`}>support team</Link> for assistance.
            </Message>
          )}

          {!loading && !error && (
            <>
              <h1 className="text-h2-display mb-56 text-center">What is your role?</h1>

              <div className="mb-32 flex flex-col items-stretch">
                <div className="flex"></div>
                <div className="p-32 xs:pb-56">
                  <div className={`flex flex-col items-center justify-evenly gap-16`}>
                    {roleOptions[eventType]?.map(({ icon, label, roleName }, index) => (
                      <IconButton key={index} icon={icon} label={label} onClick={() => onClickHandler(roleName)} />
                    )) || null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Flow(AccessContext(observer(Role)));
