import React from 'react';

const CreateLooks = () => (
  <svg width="148px" height="76px" viewBox="0 0 148 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Create Looks Illustration</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="web-welcome-" transform="translate(-245.000000, -372.000000)">
        <g id="create-looks" transform="translate(245.000000, 371.000000)">
          <path d="M41.1984,19.2634 L76.9674,19.2634" id="Stroke-1" stroke="#CCCCCC" />
          <path d="M35.9484,43.9226 L35.9484,25.3276" id="Stroke-2" stroke="#CCCCCC" />
          <polyline
            id="Stroke-3"
            stroke="#CCCCCC"
            points="122.6969 71.7976 122.6969 76.0116 35.9479 76.0116 35.9479 48.8036"
          />
          <polyline id="Stroke-5" stroke="#CCCCCC" points="118.5927 19.2634 122.6967 19.2634 122.6967 51.1724" />
          <polygon id="Fill-6" fill="#FFFFFF" points="116.543 69.7048 128.65 69.7048 128.65 53.1958 116.543 53.1958" />
          <polygon
            id="Stroke-7"
            stroke="#CCCCCC"
            points="116.543 69.7048 128.65 69.7048 128.65 53.1958 116.543 53.1958"
          />
          <polygon
            id="Fill-8"
            fill="#FFFFFF"
            points="134.726 69.7048 146.833 69.7048 146.833 53.1958 134.726 53.1958"
          />
          <polygon
            id="Stroke-9"
            stroke="#CCCCCC"
            points="134.726 69.7048 146.833 69.7048 146.833 53.1958 134.726 53.1958"
          />
          <polygon id="Fill-11" fill="#FFFFFF" points="97.797 69.7048 109.904 69.7048 109.904 53.1958 97.797 53.1958" />
          <polygon
            id="Stroke-12"
            stroke="#CCCCCC"
            points="97.797 69.7048 109.904 69.7048 109.904 53.1958 97.797 53.1958"
          />
          <polygon id="Fill-13" fill="#FFFFFF" points="79.052 69.7048 91.159 69.7048 91.159 53.1958 79.052 53.1958" />
          <polygon
            id="Stroke-14"
            stroke="#CCCCCC"
            points="79.052 69.7048 91.159 69.7048 91.159 53.1958 79.052 53.1958"
          />
          <polygon id="Fill-15" fill="#FFFFFF" points="60.869 69.7048 72.976 69.7048 72.976 53.1958 60.869 53.1958" />
          <polygon
            id="Stroke-16"
            stroke="#CCCCCC"
            points="60.869 69.7048 72.976 69.7048 72.976 53.1958 60.869 53.1958"
          />
          <polygon id="Fill-17" fill="#FFFFFF" points="42.686 69.7048 54.793 69.7048 54.793 53.1958 42.686 53.1958" />
          <polygon
            id="Stroke-18"
            stroke="#CCCCCC"
            points="42.686 69.7048 54.793 69.7048 54.793 53.1958 42.686 53.1958"
          />
          <g id="Group-40" transform="translate(0.000000, 0.511800)">
            <path
              d="M66.7213,32.7076 C69.4303,33.5506 71.3973,36.0766 71.3973,39.0626 C71.3973,42.7386 68.4173,45.7176 64.7423,45.7176 L7.1543,45.7176 C3.4793,45.7176 0.5003,42.7386 0.5003,39.0626 C0.5003,36.1026 2.4323,33.5946 5.1043,32.7296"
              id="Stroke-19"
              stroke="#B0893A"
              strokeLinecap="round"
            />
            <path
              d="M35.9484,21.0084 C35.9484,18.5024 37.3334,16.3194 39.3804,15.1844"
              id="Stroke-21"
              stroke="#B0893A"
              strokeLinecap="round"
            />
            <path
              d="M28.4494,8.4872 C28.4494,4.3452 31.8064,0.9882 35.9484,0.9882 C40.0904,0.9882 43.4474,4.3452 43.4474,8.4872 C43.4474,11.2962 41.9024,13.7452 39.6164,15.0292"
              id="Stroke-23"
              stroke="#B0893A"
              strokeLinecap="round"
            />
            <path d="M35.9484,21.3981 L66.7914,32.7331" id="Stroke-25" stroke="#B0893A" strokeLinecap="round" />
            <path d="M35.9484,21.3981 L5.1054,32.7331" id="Stroke-27" stroke="#B0893A" strokeLinecap="round" />
            <mask id="mask-2" fill="white">
              <polygon id="path-1" points="0 76 147.333 76 147.333 0.488 0 0.488" />
            </mask>
            <g id="Clip-30" />
            <polygon
              id="Fill-29"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="79.563 47.542 116.543 47.542 116.543 10.562 79.563 10.562"
            />
            <polygon
              id="Stroke-31"
              stroke="#CCCCCC"
              mask="url(#mask-2)"
              points="79.563 47.542 116.543 47.542 116.543 10.562 79.563 10.562"
            />
            <path
              d="M109.893,29.0519 C109.893,35.5909 104.592,40.8919 98.053,40.8919 C91.514,40.8919 86.213,35.5909 86.213,29.0519 C86.213,22.5129 91.514,17.2119 98.053,17.2119 C104.592,17.2119 109.893,22.5129 109.893,29.0519"
              id="Fill-32"
              fill="#FFFFFF"
              mask="url(#mask-2)"
            />
            <path
              d="M109.893,29.0519 C109.893,35.5909 104.592,40.8919 98.053,40.8919 C91.514,40.8919 86.213,35.5909 86.213,29.0519 C86.213,22.5129 91.514,17.2119 98.053,17.2119 C104.592,17.2119 109.893,22.5129 109.893,29.0519 Z"
              id="Stroke-33"
              stroke="#CCCCCC"
              mask="url(#mask-2)"
            />
            <g id="Group-36" mask="url(#mask-2)">
              <g transform="translate(98.000000, 23.000000)">
                <path d="M0.0529,0.42 L0.0529,11.684" id="Fill-34" stroke="none" fill="#606060" fillRule="evenodd" />
                <path d="M0.0529,0.42 L0.0529,11.684" id="Stroke-35" stroke="#B0893A" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-39" mask="url(#mask-2)">
              <g transform="translate(92.000000, 29.000000)">
                <path d="M0.4211,0.0519 L11.6851,0.0519" id="Fill-37" stroke="none" fill="#606060" fillRule="evenodd" />
                <path d="M0.4211,0.0519 L11.6851,0.0519" id="Stroke-38" stroke="#B0893A" strokeWidth="1" fill="none" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CreateLooks;
