import Spinner from '../../shared/components/Spinner';

const Loading = () => (
  <div className="container">
    <div className="row">
      <div className="col-span-12 py-32 md:py-64">
        <Spinner>
          <h2 className="text-h3 text-center text-gray-dark">Loading</h2>
        </Spinner>
      </div>
    </div>
  </div>
);

export default Loading;
