import { Component } from 'react';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { FlowRouteProps } from '../../types';
import EventStore from '../../stores/EventStore';
import CustomerStore from '../../stores/CustomerStore';
import Flow from '../../utils/HOC/Flow';
import Loading from '../../utils/Component/Loading';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';

interface State {
  loading: boolean;
}

class EventTooSoon extends Component<FlowRouteProps<any>, State> {
  constructor(props: FlowRouteProps<any>) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    if (!EventStore.event.id) {
      await EventStore.loadEvent(window.gt.user.primaryEventId);
    }
    this.setState({ loading: false });
  }

  nextPage = () => {
    this.onExit();
  };

  onExit = async () => {
    // Direct the flow of the /hto/redirect stream depending on what customer/event info we have
    let outflowIndex = 0;

    if (window.gt.user.firstName === '' || window.gt.user.lastName === '') {
      outflowIndex = 1;
    }

    const params = new URLSearchParams(this.props.location.search);
    if (EventStore.event.id) {
      params.set('eventId', EventStore.event.id!);
    } else {
      outflowIndex = 2;
    }

    params.set('edit', CustomerStore.customer.id!);
    this.props.flow!(`?${params.toString()}`, outflowIndex);
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-md space-y-32">
            <h1 className="text-h2-display ">
              {EventStore.event.startDate
                ? `We're sorry, your event (${format(
                    EventStore.event.startDate,
                    'MMM D, YYYY'
                  )}) is too close for a home try-on.`
                : `You must have an event to start your home try-on.`}
            </h1>

            <p className="text-gray-dark">
              If you have any questions please contact&nbsp;
              <a href={`${window.location.protocol}//${window.location.host}/support`}>support</a>.
            </p>

            <div className={`flex flex-row-reverse gap-8`}>
              <button
                role="link"
                onClick={() => this.nextPage()}
                className="tracker-cta-too_soon-next-200619-110945 btn btn-info grow"
              >
                Next <IconArrowRight />
              </button>
              <button
                onClick={() => (window.location.href = `${process.env.REACT_APP_ECOMM_URL}/home-try-on`)}
                className="tracker-cta-too_soon-back-200619-110945 btn btn-default"
              >
                <IconArrowLeft />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(observer(EventTooSoon));
