import { Component } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { isAfter } from 'date-fns';
import { GlobalContextTyping, Member } from '../../types';
import Event from './events/components/Event';
import CustomerStore from '../../stores/CustomerStore';
import MyAccountWrapper from '../hoc/MyAccountWrapper';
import Spinner from '../../shared/components/Spinner';
import { pageFadeIn, pageFadeInDelayed } from '../../utils/Component/Animations';
import Line from '../../components/Line';
import Message from '../../components/Message';
import { Transition } from '@headlessui/react';

interface Props extends RouteComponentProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  isLoading: boolean;
  loadError: boolean;
}

class Events extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      loadError: false,
    };
  }

  async componentDidMount() {
    try {
      await CustomerStore.getMembers();
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isLoading: false,
        loadError: true,
      });
    }
  }

  render() {
    const { isLoading, loadError } = this.state;

    const memberships = CustomerStore.fetchedMembers.filter(
      (member: Member) =>
        member &&
        isAfter(member.gtEvent!.startDate!, new Date()) &&
        member.gtEvent!.clonedEvent === null &&
        !member.gtEvent!.isTrial
    );

    return (
      <>
        <Transition {...pageFadeIn}>
          <h1 className="text-h2-display">Events</h1>
          <Line />
        </Transition>
        <Transition {...pageFadeInDelayed}>
          {isLoading && !loadError && <Spinner type="minimal" />}

          {!isLoading && loadError && (
            <Message type="error">There was an issue loading this page. Please refresh and try again.</Message>
          )}

          {!isLoading && !loadError && (
            <>
              {memberships.length === 0 ? (
                <>
                  <p className="mb-24"> You have no active events. Please create a new one to get started.</p>
                  <button className="btn btn-info" onClick={() => this.props.history.push('/create-event/occasion')}>
                    Create An Event
                  </button>
                </>
              ) : (
                <>
                  {/* Owned Events */}
                  {memberships.filter((membership) => membership.isOwner).length !== 0 && (
                    <div className="mb-32">
                      <h3 className="text-h4 mb-32 text-gray-dark">Owned Events</h3>

                      {memberships
                        .filter((membership) => membership.isOwner)
                        .map((m) => (
                          <Event
                            key={m.id}
                            id={m.gtEvent!.id!}
                            primaryEvent={CustomerStore.primaryEventId === m.gtEvent!.id!}
                            name={m.gtEvent!.name!}
                            startDate={m.gtEvent!.startDate!}
                            isOwner={true}
                            memberId={m.id!}
                          />
                        ))}
                    </div>
                  )}
                  {/* Invited Events */}
                  {memberships.filter((membership) => !membership.isOwner).length !== 0 && (
                    <div className="mb-32">
                      <h3 className="text-h4 mb-32 text-gray-dark">Invited Events</h3>

                      {memberships
                        .filter((membership) => !membership.isOwner)
                        .map((m) => (
                          <Event
                            key={m.id}
                            id={m.gtEvent!.id!}
                            name={m.gtEvent!.name!}
                            startDate={m.gtEvent!.startDate!}
                            isOwner={false}
                            memberId={m.id!}
                            primaryEvent={CustomerStore.primaryEventId === m.gtEvent!.id!}
                          />
                        ))}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Transition>
      </>
    );
  }
}

export default observer(MyAccountWrapper(Events));
