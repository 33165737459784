import React from 'react';

interface IconArrowLeftProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconArrowLeftProps>;

const IconArrowLeft: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Arrow pointing left</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M7 7.5H14V8.5H7V7.5Z" />
      <path d="M7 4L2 8L7 12V4Z" />
    </svg>
  );
};

export default IconArrowLeft;
