import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconSocks: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 15 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-[18px] w-[15px] stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Socks Icon</title>
      <path d="M9.0003 13.3364l-2.8636.5728M10.7188 3.1091h-4.5M6.2199 3.1091H.6563M13.9105 13.3364l-2.8636.5728M.4102.4092h5.809v8.7545l2.3728 2.7818c.7363.8182 1.1454 1.9637 1.1454 3.0273 0 1.4727-1.1454 2.6182-2.6181 2.6182-1.0637 0-2.0455-.4909-2.6182-1.3091L.492 11.0455.4102.4092z" />
      <path d="M5.3164.4092h5.4818v8.7545l2.3728 2.7818c.7363.8182 1.1454 1.9637 1.1454 3.0273 0 1.4727-1.1454 2.6182-2.6182 2.6182-.7363 0-1.4727-.3273-1.9636-.9" />
    </svg>
  );
};

export default IconSocks;
