import React from 'react';

interface IconCalendarProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconCalendarProps>;

const IconCalendar: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 10 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-[11px] w-[10px] stroke-current text-black ${className ? className : ''}`}
    >
      <title>Calendar Icon</title>
      <g>
        <rect x="0.5" y="2.5" width="9" height="8" fill="none" />
        <line x1="9" y1="5.5" x2="1" y2="5.5" fill="none" />
        <line x1="7.5" x2="7.5" y2="2" fill="none" />
        <line x1="2.5" x2="2.5" y2="2" fill="none" />
      </g>
    </svg>
  );
};

export default IconCalendar;
