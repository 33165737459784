import React, { useEffect, useState } from 'react';
import { Switch, BrowserRouter, type RouteComponentProps, Redirect } from 'react-router-dom';
import { type RouteProps } from 'react-router';

// page components
import Page404 from './utils/Component/Page404';
import Looks from './event-flow/components/Looks';
import Members from './event-flow/components/Members';
import FitLink from './event-flow/components/FitLink';
import AddressForm from './customer-flow/components/Address';
import Password from './customer-flow/components/Password';
import { default as CustomerName } from './customer-flow/components/Name';
import Email from './customer-flow/components/Email';
import Phone from './customer-flow/components/Phone';
import MarketingPreferences from './customer-flow/components/MarketingPreferences';
import MissingInformation from './customer-flow/components/MissingInformation';
import ReferralSurvey from './customer-flow/components/ReferralSurvey';
import Role from './event-form-flow/components/Role';
import EventType from './event-form-flow/components/EventType';
import EventDate from './event-form-flow/components/EventDate';
import Footer from './partials/Footer';
import EventRouteMiddleware from './route-middleware/EventRouteMiddleware';
import InvitedRouteMiddleware from './route-middleware/InvitedRouteMiddleware';
import RouteMiddleware from './route-middleware/RouteMiddleware';
import Login from './login/components/Login';
import ForgotPassword from './login/components/ForgotPassword';
import Fit from './fit-flow/Fit';
import Build from './swatch-flow/components/Build';
import Shipping from './shared/components/Shipping';
import { default as SwatchSummary } from './swatch-flow/components/Summary';
import Confirmation from './swatch-flow/components/Confirmation';
import HtoConfirmation from './hto-flow/components/Confirmation';
import CheckoutConfirmation from './shared/components/Confirmation';
import Payment from './hto-flow/components/Payment';
import OutfitPreview from './invited-member-flow/components/OutfitPreview';
import Completed from './invited-member-flow/components/Completed';
import Join from './invited-member-flow/components/Join';
import { default as InvitedMemberEmail } from './invited-member-flow/components/Email';
import { default as InvitedMemberPassword } from './invited-member-flow/components/Password';
import { default as InvitedMemberPhone } from './invited-member-flow/components/Phone';
import { default as InvitedMemberFirstName } from './invited-member-flow/components/FirstName';
import { default as InvitedMemberLastName } from './invited-member-flow/components/LastName';
import { default as InvitedMemberPreferences } from './invited-member-flow/components/MarketingPreferences';
import Checkout from './shared/components/Checkout';
import WelcomeBack from './login/components/WelcomeBack';
import EventTooSoon from './hto-flow/components/EventTooSoon';
import ExistingHTO from './hto-flow/components/ExistingHTO';
import Details from './event-flow/components/Details';
import ContactInfo from './my-account/components/ContactInfo';
import PasswordReset from './my-account/components/PasswordReset';
import FitProfile from './my-account/components/FitProfile';
import Events from './my-account/components/Events';
import OwnerFirstName from './add-owner-flow/components/OwnerFirstName';
import OwnerLastName from './add-owner-flow/components/OwnerLastName';
import OwnerEmail from './add-owner-flow/components/OwnerEmail';
import OwnerPhone from './add-owner-flow/components/OwnerPhone';
import CheckoutLink from './event-flow/components/CheckoutLink';
import Assign from './event-flow/components/Assign';
import Invite from './event-flow/components/Invite';
import NPS from './nps/components/NPS';
import Browse from './color-recommendations/pages/Browse';
import Recommendations from './color-recommendations/pages/Recommendations';
import OptOutLanding from './my-account/components/OptOutLanding';
import OptInLanding from './my-account/components/OptInLanding';
import ConfirmFit from './invited-member-flow/components/ConfirmFit';
import OwnerConfirmation from './add-owner-flow/components/OwnerConfirmation';
import CommunicationPreferences from './my-account/components/CommunicationPreferences';
import Orders from './my-account/components/Orders';
import OrderDetails from './my-account/components/OrderDetails';
import LookCreate from './look-builder/pages/LookCreate';
import LookEdit from './look-builder/pages/LookEdit';
import ScrollToTheTopWrapper from './utils/Component/ScrollToTopWrapper';

// types & services
import type { EventRouteProps } from './types';
import LookIsLocked from './look-builder/pages/LookIsLocked';
import Loading from './utils/Component/Loading';
import auth, { fetchSession } from './services/Auth';
import { pageViewed } from './utils/metrics';
import { getClickIdFromSession, getClickIdFromUrl, rememberClickId } from './utils/TikTok';
import { waitUntilGtagHasLoaded } from './services/google';
import { siteIsLoadedInOptimizelyProxy, sendQueuedOptimizelyEvents } from './services/optimizely';

// CSS
import './tailwind/tailwind.css';

const Route: React.FC<RouteProps> = ({ render, ...rest }) => (
  <RouteMiddleware
    {...rest}
    render={(props) => {
      pageViewed();
      return render!(props);
    }}
  />
);

const InvitedRoute: React.FC<RouteProps> = ({ render, ...rest }) => (
  <InvitedRouteMiddleware
    {...rest}
    render={(props) => {
      pageViewed();
      return render!(props);
    }}
  />
);

interface EventRouteRenderProps extends RouteProps {
  render?: (props: EventRouteProps<any>) => React.ReactNode;
}

const EventRoute: React.FC<EventRouteRenderProps> = ({ render, ...rest }) => (
  <EventRouteMiddleware
    {...rest}
    render={(props) => {
      pageViewed();
      return render!(props);
    }}
  />
);

const setClickId = async () => {
  let clickId = getClickIdFromUrl();

  const previousClickId = await getClickIdFromSession();

  if (!clickId && previousClickId) {
    clickId = previousClickId;
  } else if (clickId && previousClickId !== clickId) {
    rememberClickId(clickId);
  }

  window.gt.ttclid = clickId;
};

const MATCH_APP_PATH = /(?:qa-)?app\.(?:menguin|gen(?:eration)?tux)\.com(.*)/i;

/**
 * Detects whether the site has been loaded through Optimizely's proxy, and then attempts to fix the
 * pathname that React Router tries to match against
 *
 * Refer to [the doc page on the Optimizely proxy](../documentation/optimizely-proxy.md) for more info
 */
export const HandleOptimizelyProxyOrShow404 = (props: RouteComponentProps) => {
  const pathname = decodeURIComponent(props.location.pathname);

  const extractedPathname = MATCH_APP_PATH.exec(pathname)?.[1] ?? null;

  // 404 if we're on the main site, or if we've already redirected
  // the user to the corrected route from the Optimizely proxy site
  if (!siteIsLoadedInOptimizelyProxy() || extractedPathname === null) {
    return <Page404 />;
  }

  return (
    <Redirect
      to={{
        ...props.location,
        pathname: extractedPathname ?? '/',
      }}
    />
  );
};

/**
 * Configures Google Tag Manager for reporting to our GA4 profile, and then tells
 * Optimizely to release any queued events so they can be tracked in GA4 as well
 */
const configureAnalyticsReporting = async () => {
  try {
    await waitUntilGtagHasLoaded();

    gtag('config', process.env.REACT_APP_GA4_MEASUREMENT_ID ?? '');
  } catch (e) {
    console.error(e);
  } finally {
    // These should be sent regardless of whether GTM is available,
    // but when it is we'll wait until it's loaded
    sendQueuedOptimizelyEvents();
  }
};

const App: React.FC<{}> = () => {
  const [fetchedSession, setFetchedSession] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setClickId();
      } catch (e) {
        console.error(`Encountered an issue setting the user's click ID`, e);
      }

      const res = await fetchSession();

      if (res.status === 200) {
        const data = await res.json();

        auth.storeUserJson(data);

        if (window.gt.user && window.analytics) {
          window.analytics.identify(
            window.gt.user.id,
            {
              firstName: window.gt.user.firstName,
              lastName: window.gt.user.lastName,
              email: window.gt.user.email,
              phone: window.gt.user.phone,
              smsOptIn: window.gt.user.smsOptIn,
              emailOptIn: window.gt.user.emailOptIn,
              state: window.gt.user.state,
              primaryEventId: window.gt.user.primaryEventId || '',
              brand: window.gt.orgId === 1 ? 'gentux' : 'menguin',
            },
            {
              integrations: {
                All: true,
                Iterable: {
                  phoneNumber: window.gt.user.phone,
                },
                Pinterest: {
                  initWithExistingTraits: true,
                },
              },
            }
          );
        }
      } else {
        window.localStorage.removeItem('jwt');
      }

      configureAnalyticsReporting();

      setFetchedSession(true);
    })();
  }, []);

  return (
    <>
      {!fetchedSession ? (
        <Loading />
      ) : (
        <BrowserRouter>
          <ScrollToTheTopWrapper>
            <Switch>
              <Route exact={true} path="/customer/forgot-password" render={(props) => <ForgotPassword {...props} />} />
              <EventRoute
                exact={true}
                path="/event-flow/looks/build/:lookId?"
                render={(props) => <LookEdit {...props} />}
              />

              <EventRoute exact={true} path="/event-flow/details" render={(props) => <Details {...props} />} />

              <Route exact={true} path="/account/contact" render={(props) => <ContactInfo {...props} />} />
              <Route
                exact={true}
                path="/account/preferences"
                render={(props) => <CommunicationPreferences {...props} />}
              />
              <Route exact={true} path="/account/password" render={(props) => <PasswordReset {...props} />} />
              <Route exact={true} path="/account/fit" render={(props) => <FitProfile {...props} />} />
              <Route exact={true} path="/account/events" render={(props) => <Events {...props} />} />
              <Route exact={true} path="/account/orders" render={(props) => <Orders {...props} />} />
              <Route exact={true} path="/account/orders/:orderId" render={(props) => <OrderDetails {...props} />} />
              <EventRoute exact={true} path="/event-flow/looks" render={(props) => <Looks {...props} />} />
              <EventRoute
                exact={true}
                path="/event-flow/looks/locked/:lookId?"
                render={(props) => <LookIsLocked {...props} />}
              />
              <EventRoute exact={true} path="/event-flow/members" render={(props) => <Members {...props} />} />
              <EventRoute exact={true} path="/event-flow/assign" render={(props) => <Assign {...props} />} />
              <EventRoute exact={true} path="/event-flow/invite" render={(props) => <Invite {...props} />} />
              <EventRoute exact={true} path="/event-flow/fit" render={(props) => <FitLink {...props} />} />
              <EventRoute exact={true} path="/event-flow/checkout" render={(props) => <CheckoutLink {...props} />} />
              <EventRoute exact={true} path="/event/shipping" render={(props) => <Shipping {...props} />} />
              <EventRoute exact={true} path="/event/checkout" render={(props) => <Checkout {...props} />} />
              <EventRoute
                exact={true}
                path="/event/confirmation"
                render={(props) => <CheckoutConfirmation {...props} />}
              />
              <EventRoute path="/fit-flow" render={(props) => <Fit {...props} />} />

              {/** Begin Login Flow */}
              <Route exact={true} path="/account/login" render={(props) => <Login {...props} />} />
              {/** End Login Flow */}

              {/** Begin SignUp Flow */}
              <Route exact={true} path="/signup/password" render={(props) => <Password {...props} />} />
              <Route
                exact={true}
                path="/signup/email"
                render={(props) => <Email loginUrl={'/account/login'} {...props} />}
              />
              <Route exact={true} path="/signup/phone" render={(props) => <Phone {...props} />} />
              <Route exact={true} path="/signup/referral-survey" render={(props) => <ReferralSurvey {...props} />} />
              <Route exact={true} path="/signup/address" render={(props) => <AddressForm {...props} />} />
              <Route exact={true} path="/signup/preferences" render={(props) => <MarketingPreferences {...props} />} />
              <Route
                exact={true}
                path="/signup/missing-information"
                render={(props) => <MissingInformation {...props} />}
              />
              <Route exact={true} path="/signup/occasion" render={(props) => <EventType {...props} />} />
              <Route exact={true} path="/signup/role" render={(props) => <Role {...props} />} />
              <Route exact={true} path="/signup/date" render={(props) => <EventDate {...props} />} />
              <Route exact={true} path="/signup/name" render={(props) => <CustomerName {...props} />} />
              {/** End SignUp Flow */}

              {/** Begin Create Event Flow */}
              <Route exact={true} path="/create-event/occasion" render={(props) => <EventType {...props} />} />
              <Route exact={true} path="/create-event/role" render={(props) => <Role {...props} />} />
              <Route exact={true} path="/create-event/date" render={(props) => <EventDate {...props} />} />
              <Route exact={true} path="/create-event/name" render={(props) => <CustomerName {...props} />} />
              <Route exact={true} path="/create-event/phone" render={(props) => <Phone {...props} />} />
              <Route
                exact={true}
                path="/create-event/preferences"
                render={(props) => <MarketingPreferences {...props} />}
              />
              {/** End Create Event Flow */}

              {/** Begin Add Owner Flow */}
              <EventRoute
                exact={true}
                path="/event/owner/first-name"
                render={(props) => <OwnerFirstName {...props} />}
              />
              <EventRoute exact={true} path="/event/owner/last-name" render={(props) => <OwnerLastName {...props} />} />
              <EventRoute exact={true} path="/event/owner/email" render={(props) => <OwnerEmail {...props} />} />
              <EventRoute exact={true} path="/event/owner/phone" render={(props) => <OwnerPhone {...props} />} />
              <EventRoute
                exact={true}
                path="/event/owner/confirmation"
                render={(props) => <OwnerConfirmation {...props} />}
              />
              {/** End Add Owner Flow */}

              {/** Begin Swatch Flow */}
              <Route exact={true} path="/swatch/build" render={(props) => <Build {...props} />} />
              <Route
                exact={true}
                path="/swatch/login"
                render={(props) => <Login signUpUrl={'/swatch/email'} {...props} />}
              />
              <Route
                exact={true}
                path="/swatch/email"
                render={(props) => <Email loginUrl={'/swatch/login'} {...props} />}
              />
              <Route exact={true} path="/swatch/password" render={(props) => <Password {...props} />} />
              <Route exact={true} path="/swatch/occasion" render={(props) => <EventType {...props} />} />
              <Route exact={true} path="/swatch/role" render={(props) => <Role {...props} />} />
              <Route exact={true} path="/swatch/date" render={(props) => <EventDate {...props} />} />
              <Route exact={true} path="/swatch/name" render={(props) => <CustomerName {...props} />} />
              <Route exact={true} path="/swatch/phone" render={(props) => <Phone {...props} />} />
              <Route exact={true} path="/swatch/referral-survey" render={(props) => <ReferralSurvey {...props} />} />
              <Route exact={true} path="/swatch/preferences" render={(props) => <MarketingPreferences {...props} />} />
              <EventRoute exact={true} path="/swatch/shipping" render={(props) => <Shipping {...props} />} />
              <Route exact={true} path="/swatch/summary" render={(props) => <SwatchSummary {...props} />} />
              <Route exact={true} path="/swatch/confirmation" render={(props) => <Confirmation {...props} />} />
              {/** End Swatch Flow */}

              {/** Begin HTO Flow */}
              <Route exact={true} path="/hto/looks/build" render={(props) => <LookCreate {...props} />} />
              <Route exact={true} path="/hto/login" render={(props) => <Login signUpUrl={'/hto/email'} {...props} />} />
              <Route exact={true} path="/hto/email" render={(props) => <Email loginUrl={'/hto/login'} {...props} />} />
              <Route exact={true} path="/hto/password" render={(props) => <Password {...props} />} />
              <Route exact={true} path="/hto/occasion" render={(props) => <EventType {...props} />} />
              <Route exact={true} path="/hto/role" render={(props) => <Role {...props} />} />
              <Route exact={true} path="/hto/date" render={(props) => <EventDate inHtoFlow {...props} />} />
              <Route exact={true} path="/hto/name" render={(props) => <CustomerName {...props} />} />
              <Route exact={true} path="/hto/phone" render={(props) => <Phone {...props} />} />
              <Route exact={true} path="/hto/referral-survey" render={(props) => <ReferralSurvey {...props} />} />
              <Route exact={true} path="/hto/preferences" render={(props) => <MarketingPreferences {...props} />} />
              <EventRoute exact={true} path="/hto/shipping" render={(props) => <Shipping {...props} />} />
              <Route exact={true} path="/hto/payment" render={(props) => <Payment {...props} />} />
              <Route exact={true} path="/hto/confirmation" render={(props) => <HtoConfirmation {...props} />} />
              {/** End HTO Flow */}

              {/** Begin Customize Look Flow */}
              <Route
                exact={true}
                path="/customize/login"
                render={(props) => <Login signUpUrl={'/customize/email'} {...props} />}
              />
              <Route
                exact={true}
                path="/customize/email"
                render={(props) => <Email loginUrl={'/customize/login'} {...props} />}
              />
              <Route exact={true} path="/customize" render={(props) => <LookCreate {...props} />} />
              <Route exact={true} path="/customize/swatch/browse" render={(props) => <Browse {...props} />} />
              <Route
                exact={true}
                path="/customize/swatch/browse/:swatchId"
                render={(props) => <Recommendations {...props} />}
              />
              {/** Begin Gated Customize Look Flow */}
              <Route
                exact={true}
                path="/customize/signup/login"
                render={(props) => <Login signUpUrl={'/customize/signup/email'} {...props} />}
              />
              <Route exact={true} path="/customize/signup/date" render={(props) => <EventDate {...props} />} />
              <Route exact={true} path="/customize/signup/name" render={(props) => <CustomerName {...props} />} />
              <Route exact={true} path="/customize/signup/password" render={(props) => <Password {...props} />} />
              <Route
                exact={true}
                path="/customize/signup/email"
                render={(props) => <Email loginUrl={'/customize/signup/login'} {...props} />}
              />
              <Route exact={true} path="/customize/signup/phone" render={(props) => <Phone {...props} />} />
              <Route
                exact={true}
                path="/customize/signup/referral-survey"
                render={(props) => <ReferralSurvey {...props} />}
              />
              <Route exact={true} path="/customize/signup/address" render={(props) => <AddressForm {...props} />} />
              <Route
                exact={true}
                path="/customize/signup/preferences"
                render={(props) => <MarketingPreferences {...props} />}
              />
              <Route exact={true} path="/customize/signup/occasion" render={(props) => <EventType {...props} />} />
              <Route exact={true} path="/customize/signup/role" render={(props) => <Role {...props} />} />
              {/** End Gated Customize Look Flow */}

              {/** Begin Normal Customize Look Flow */}
              <Route exact={true} path="/customize/password" render={(props) => <Password {...props} />} />
              <Route exact={true} path="/customize/occasion" render={(props) => <EventType {...props} />} />
              <Route exact={true} path="/customize/role" render={(props) => <Role {...props} />} />
              <Route exact={true} path="/customize/date" render={(props) => <EventDate {...props} />} />
              <Route exact={true} path="/customize/name" render={(props) => <CustomerName {...props} />} />
              <Route exact={true} path="/customize/phone" render={(props) => <Phone {...props} />} />
              <Route exact={true} path="/customize/referral-survey" render={(props) => <ReferralSurvey {...props} />} />
              <Route exact={true} path="/customize/address" render={(props) => <AddressForm {...props} />} />
              <Route
                exact={true}
                path="/customize/preferences"
                render={(props) => <MarketingPreferences {...props} />}
              />
              {/** End Customize Look Flow */}

              {/** Begin Generic Gate Flow */}
              <Route
                exact={true}
                path="/gate/login"
                render={(props) => <Login signUpUrl={'/gate/signup/email'} {...props} />}
              />
              <Route
                exact={true}
                path="/gate/signup/email"
                render={(props) => <Email loginUrl={'/gate/login'} {...props} />}
              />
              <Route exact={true} path="/gate/signup/password" render={(props) => <Password {...props} />} />
              <Route exact={true} path="/gate/signup/phone" render={(props) => <Phone {...props} />} />
              <Route
                exact={true}
                path="/gate/signup/referral-survey"
                render={(props) => <ReferralSurvey {...props} />}
              />
              <Route exact={true} path="/gate/signup/address" render={(props) => <AddressForm {...props} />} />
              <Route
                exact={true}
                path="/gate/signup/preferences"
                render={(props) => <MarketingPreferences {...props} />}
              />
              <Route exact={true} path="/gate/signup/occasion" render={(props) => <EventType {...props} />} />
              <Route exact={true} path="/gate/signup/role" render={(props) => <Role {...props} />} />
              <Route exact={true} path="/gate/signup/date" render={(props) => <EventDate {...props} />} />
              <Route exact={true} path="/gate/signup/name" render={(props) => <CustomerName {...props} />} />
              {/** End Generic Gate Flow */}

              {/** Begin Welcome Back Flow */}
              <Route exact={true} path="/login/event/welcome-back" render={(props) => <WelcomeBack {...props} />} />
              <Route exact={true} path="/login/customer/welcome-back" render={(props) => <WelcomeBack {...props} />} />
              <Route
                exact={true}
                path="/swatch/login/event/welcome-back"
                render={(props) => <WelcomeBack {...props} />}
              />
              <Route
                exact={true}
                path="/swatch/login/customer/welcome-back"
                render={(props) => <WelcomeBack {...props} />}
              />
              <Route exact={true} path="/hto/login/event/welcome-back" render={(props) => <WelcomeBack {...props} />} />
              <Route
                exact={true}
                path="/hto/login/customer/welcome-back"
                render={(props) => <WelcomeBack {...props} />}
              />
              <Route
                exact={true}
                path="/hto-abr/login/event/welcome-back"
                render={(props) => <WelcomeBack {...props} />}
              />
              <Route
                exact={true}
                path="/hto-abr/login/customer/welcome-back"
                render={(props) => <WelcomeBack {...props} />}
              />
              <Route
                exact={true}
                path="/customize/login/event/welcome-back"
                render={(props) => <WelcomeBack {...props} />}
              />
              <Route
                exact={true}
                path="/customize/login/customer/welcome-back"
                render={(props) => <WelcomeBack {...props} />}
              />
              {/** End Welcome Back Flow */}

              {/** Begin Event Too Soon Flow */}
              <Route exact={true} path="/hto/redirect" render={(props) => <EventTooSoon {...props} />} />
              {/** End Event Too Soon Flow */}

              {/** Begin Existing HTO Flow */}
              <Route exact={true} path="/hto/existing" render={(props) => <ExistingHTO {...props} />} />
              {/** End Existing HTO Flow */}

              {/** Begin Invited Member Flow */}
              <InvitedRoute exact={true} path="/invited/join/:shortCode" render={(props) => <Join {...props} />} />
              <InvitedRoute
                exact={true}
                path="/invited/login"
                render={(props) => <Login signUpUrl={'/invited/join'} {...props} />}
              />
              <InvitedRoute
                exact={true}
                path="/invited/signup/email"
                render={(props) => <InvitedMemberEmail {...props} />}
              />
              <InvitedRoute
                exact={true}
                path="/invited/signup/password"
                render={(props) => <InvitedMemberPassword {...props} />}
              />
              <InvitedRoute
                exact={true}
                path="/invited/signup/phone"
                render={(props) => <InvitedMemberPhone {...props} />}
              />
              <InvitedRoute
                exact={true}
                path="/invited/signup/first-name"
                render={(props) => <InvitedMemberFirstName {...props} />}
              />
              <InvitedRoute
                exact={true}
                path="/invited/signup/last-name"
                render={(props) => <InvitedMemberLastName {...props} />}
              />
              <InvitedRoute
                exact={true}
                path="/invited/signup/preferences"
                render={(props) => <InvitedMemberPreferences {...props} />}
              />
              <InvitedRoute
                exact={true}
                path="/invited/signup/missing-information"
                render={(props) => <MissingInformation {...props} />}
              />
              <EventRoute exact={true} path="/invited/confirm-fit" render={(props) => <ConfirmFit {...props} />} />
              <EventRoute exact={true} path="/invited/shipping" render={(props) => <Shipping {...props} />} />
              <EventRoute exact={true} path="/invited/preview" render={(props) => <OutfitPreview {...props} />} />
              <EventRoute exact={true} path="/invited/completed" render={(props) => <Completed {...props} />} />
              <EventRoute
                exact={true}
                path="/invited/confirmation"
                render={(props) => <CheckoutConfirmation {...props} />}
              />
              <EventRoute exact={true} path="/invited/checkout" render={(props) => <Checkout {...props} />} />
              {/** End Invited Member Flow */}

              <Route exact={true} path="/subscribe" render={(props) => <OptInLanding {...props} />} />
              <Route exact={true} path="/unsubscribe" render={(props) => <OptOutLanding {...props} />} />
              <Route exact={true} path="/nps" render={(props) => <NPS {...props} />} />

              <Route path="*" render={HandleOptimizelyProxyOrShow404} />
            </Switch>
          </ScrollToTheTopWrapper>
        </BrowserRouter>
      )}

      <Footer />
    </>
  );
};

export default App;
