import { observable, action } from 'mobx';

class FlowStore {
  @observable redirect: string | undefined = undefined;
  @observable redirectType: 'app' | 'ecomm' = 'app';

  @action setRedirect = (redirect: string) => (this.redirect = redirect);

  @action unsetRedirect = () => (this.redirect = undefined);

  @action setRedirectType = (redirectType: 'app' | 'ecomm') => (this.redirectType = redirectType);

  @action resetRedirectType = () => (this.redirectType = 'app');
}

export default new FlowStore();
