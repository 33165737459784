import { Component, useState } from 'react';
import EventStore from '../../stores/EventStore';
import CustomerStore from '../../stores/CustomerStore';
import MemberStore from '../../stores/MemberStore';
import PotentialMemberStore from '../../stores/PotentialMemberStore';
import InvitedMemberFormStore from '../../stores/InvitedMemberFormStore';
import { observer } from 'mobx-react';
import { FlowRouteProps, PotentialMember } from '../../types';
import { getClaimedPotentialMembers, getShortCode, getUnclaimedPotentialMembers } from '../../services/Events';
import Loading from '../../utils/Component/Loading';
import styles from './Join.module.css';
import Line from '../../components/Line';
import LogoGentuxWordmark from '../../components/LogoGentuxWordmark';
import LogoMenguinWordmark from '../../components/LogoMenguinWordmark';
import IconCheck from '../../components/IconCheck';
import IconX from '../../components/IconX';
import Message from '../../components/Message';
import IconStopwatch from '../../components/IconStopwatch';
import IsGentux from '../../components/IsGentux';
import IsMenguin from '../../components/IsMenguin';
import screens from '../../tailwind/screens.json';
import { isGentux } from '../../utils/utils';

interface Props extends FlowRouteProps<any> {}

const JoinCard = ({
  potentialMember,
  onSubmit,
  submitting,
}: {
  potentialMember: PotentialMember;
  onSubmit: (m: PotentialMember) => void;
  submitting: boolean;
}) => {
  const [confirmSelect, setConfirmSelect] = useState(false);

  return (
    <>
      {confirmSelect ? (
        <div className="flex items-center justify-start gap-16 py-16">
          <h2 className="text-h5">Sign Up As {potentialMember.nickname}?</h2>

          <button
            className="tracker-cta-join-cancel_confirm_member-20191031-110228 btn btn-sm btn-default grow"
            onClick={() => setConfirmSelect(false)}
            aria-label="Close"
          >
            <IconX />
          </button>

          <button
            className="tracker-cta-join-confirm_member-20191031-110228 btn btn-sm btn-info grow"
            disabled={submitting}
            onClick={() => onSubmit(potentialMember)}
            aria-label="Confirm"
          >
            <IconCheck />
          </button>
        </div>
      ) : (
        <div key={potentialMember.id}>
          <button
            className="tracker-cta-join-member_name-20191031-110228 btn btn-info-outline w-full"
            onClick={() => {
              setConfirmSelect(true);
            }}
            aria-label={potentialMember.nickname}
          >
            {potentialMember.nickname}
          </button>
        </div>
      )}
    </>
  );
};

const GentuxHero = () => (
  <>
    <div className="relative mb-[-140px] xs:mb-[-100px] md:mb-[-200px] lg:mb-[-250px]">
      <div className="pr-[60%] xs:pr-[30%]">
        <LogoGentuxWordmark className="mb-16 h-auto w-[172px] max-w-full" />
      </div>

      <div
        className="
          mt-16 flex 
          flex-col gap-4 pl-[10%] pr-[40%] xs:mt-32 xs:pl-0 
          xs:pr-1/2 sm:pr-[60%] md:mt-128
          lg:pr-[70%]
          "
      >
        <div className="pr-1/2 xs:pr-[40%] sm:pr-[30%] lg:pr-0">
          <img
            src="https://gentux.imgix.net/1581529270_hero-icon.png"
            width="124"
            alt="gentux process icon"
            loading="lazy"
          />
        </div>

        <p className="text-sm mt-8 max-w-[18em] text-gray-dark xs:mt-16">
          Online suit and tux rentals delivered <span className="hidden sm:inline">straight</span> to your&nbsp;door.
        </p>
      </div>
    </div>

    <div className="-mr-16 flex items-start justify-end pl-1/2 xs:pl-[20%] md:pl-0">
      <picture>
        <source
          media={`(min-width: ${screens.xs})`}
          srcSet="https://gentux.imgix.net/1581529790_200212-124936-invited-hero.jpg"
        />
        <img
          loading="lazy"
          src="https://gentux.imgix.net/1581529790_200212-124936-invited-hero.jpg?w=250"
          alt="suit on rack with gentux box"
        />
      </picture>
    </div>
  </>
);

const MenguinHero = () => (
  <>
    <div className="pr-[60%] xs:pr-[30%]">
      <LogoMenguinWordmark className="!mb-16 h-auto !w-[172px] max-w-full sm:!mb-64" />
    </div>

    <p className="max-w-[24em] text-gray-dark">
      Great tuxes. Delivered.
      <br />
      Made easy for any wedding.
    </p>

    <div className="mb-0 mt-0 flex items-start justify-end xs:mt-32 sm:mt-128">
      <img
        loading="lazy"
        src="https://gentux.imgix.net/1581695714_200214-105414-invited-hero.png"
        alt="suit items and menguin box"
      />
    </div>
  </>
);

interface State {
  loading: boolean;
  error: string;
  showWelcome: boolean;
  submitting: boolean;
}
class Join extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      error: '',
      showWelcome: true,
      submitting: false,
    };
  }

  async componentDidMount() {
    try {
      const res = await getShortCode(this.props.match.params.shortCode);
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const data = await res.json();

      if (data.data.errors) {
        throw new Error(data.data.errors);
      }

      EventStore.set(data.data.shortCode.gtEvent);
      InvitedMemberFormStore.update('shortCode', this.props.match.params.shortCode);

      const availablePotentialMembers = PotentialMemberStore.getUnclaimed();

      if (availablePotentialMembers.length === 0) {
        return this.setState({
          error: 'All members have been claimed on this event! Contact the event owner if you wish to join this party.',
          loading: false,
        });
      }

      if (window.gt.user) {
        const existingMember = MemberStore.getSignedInMember();
        if (existingMember) {
          if (existingMember.isOwner) {
            return this.props.history.push(`/event-flow/looks?eventId=${EventStore.event.id}`);
          }
          return this.props.history.push(
            `/invited/preview?memberId=${existingMember.id!}&eventId=${EventStore.event.id}`
          );
        }
      }
    } catch (e) {
      console.error(e);
      this.setState({
        error: 'Something went wrong when loading this event. Please try again.',
      });
    }

    this.setState({
      loading: false,
    });
  }

  onSubmit = async (potentialMember: PotentialMember) => {
    this.setState({ submitting: true });
    if (potentialMember.member && Boolean(potentialMember.member.customer!.isRegistrationComplete) === false) {
      return this.props.history.push(`/signup/password?email=${potentialMember.member.customer!.email}`);
    }

    if (window.gt.user) {
      try {
        await PotentialMemberStore.joinEvent(potentialMember.id!, window.gt.user.id);
        await CustomerStore.update({
          ...CustomerStore.customer,
          primaryEventId: EventStore.event.id,
        });
      } catch (e) {
        console.error(e);
        return this.setState({
          submitting: false,
          error: 'Something went wrong adding you to this event. Please try again.',
        });
      }

      const member = MemberStore.getSignedInMember()!;

      if (member.isOwner) {
        return this.props.history.push(`/event-flow/looks?eventId=${EventStore.event.id}`);
      } else {
        return this.props.history.push(
          `/fit-flow/invited/height?memberId=${MemberStore.getSignedInMember()!.id!}&eventId=${EventStore.event.id}`
        );
      }
    } else {
      // If user is not signed in
      InvitedMemberFormStore.update('potentialMemberId', potentialMember.id!);
      this.nextPage();
    }
  };

  monthConverter = (monthNum: number) => {
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    return months[monthNum - 1];
  };

  nextPage = () => {
    this.onExit();
  };

  onExit = () => {
    return this.props.history.push(`/invited/signup/email?eventId=${EventStore.event.id}`);
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className={`${isGentux() ? styles['bg-gentux'] : styles['bg-menguin']} border-b border-white`}>
        <div className="container">
          <div className="row min-h-[100vh] gap-0">
            <div className="col-span-12 py-16 xs:col-span-5 xs:py-64">
              <IsGentux>
                <GentuxHero />
              </IsGentux>

              <IsMenguin>
                <MenguinHero />
              </IsMenguin>
            </div>

            <div
              className={`hidden xs:col-span-1 xs:block ${
                this.state.showWelcome ? 'md:col-span-1' : 'md:col-span-2'
              } bg-white`}
            />

            <div
              className={`
              _xs:col-start-1
              col-span-12 
              xs:bg-white 
              ${this.state.showWelcome ? 'xs:col-span-6' : 'xs:col-span-5'} 
              ${this.state.showWelcome ? 'md:col-span-5' : 'md:col-span-4'} 
              pb-128 pt-0 xs:pb-64 xs:pt-64
              `}
            >
              <div className="grow">
                {this.state.error !== '' && <Message message={this.state.error} type="error" />}
                {this.state.loading ? (
                  <Loading />
                ) : (
                  <>
                    {this.state.showWelcome ? (
                      <div className="mt-16 border border-gray py-32 pl-64 pr-32 xs:mt-0 xs:pl-32 sm:mt-64 sm:pl-64 md:mt-128 lg:pl-[96px] lg:pr-[96px]">
                        <h1 className="text-h5 text-gray-dark">You've been invited to the</h1>

                        <div className="relative">
                          <h2 className="text-h2-display">{EventStore.event.name}!</h2>

                          <div
                            className="absolute left-0 top-1/2 -ml-16 inline-block border border-gray bg-gray-lighter px-8 py-4 text-center uppercase"
                            style={{
                              transform: 'translate3d(-100%,-50%,0)',
                            }}
                          >
                            <p className="text-xs" style={{ lineHeight: '1.2' }}>
                              {this.monthConverter(parseInt(EventStore.event.startDate!.split('-')[1], 10))}
                            </p>

                            <p className="text-sm text-gray-dark" style={{ lineHeight: '1' }}>
                              {EventStore.event.startDate!.split('-')[2]}
                            </p>
                          </div>
                        </div>

                        <Line />

                        <button
                          onClick={() => this.setState({ showWelcome: false })}
                          className="tracker-cta-invited-get_started-200619-111942 btn btn-info mb-16 w-full"
                        >
                          Get Started
                        </button>

                        <p className="text-sm relative text-gray-dark">
                          <IconStopwatch
                            className="absolute left-0 top-1/2 -ml-16 !text-gray-dark xs:-ml-8 sm:-ml-16"
                            style={{ transform: 'translate3d(-100%,-50%,0)' }}
                          />
                          This should only take about 2&nbsp;min.
                        </p>
                      </div>
                    ) : (
                      <div className="space-y-32">
                        <h1 className="text-h2-display">
                          {EventStore.event
                            .potentialMembers!.filter((m) => m.member && Boolean(m.member.isCreator) === true)
                            .map((m) => (
                              <span className="text-h2-display" key={m.id}>
                                {m.member && m.member.customer && m.member.customer.firstName}
                              </span>
                            ))}{' '}
                          has picked out a look just for&nbsp;you.
                        </h1>

                        <Line />

                        <div className="space-y-16">
                          <h2 className="text-h5">Claim your name</h2>

                          {getUnclaimedPotentialMembers(EventStore.event.potentialMembers!).map((m) => (
                            <JoinCard
                              key={m.id}
                              potentialMember={m}
                              onSubmit={this.onSubmit}
                              submitting={this.state.submitting}
                            />
                          ))}
                        </div>

                        <div className="space-y-16">
                          {getClaimedPotentialMembers(EventStore.event.potentialMembers!).length > 0 && (
                            <h2 className="text-h5">Claimed</h2>
                          )}

                          {getClaimedPotentialMembers(EventStore.event.potentialMembers!).map((m) => (
                            <button
                              key={m.id}
                              className="tracker-text-join-already_joined-20191230-104904 btn btn-default-outline w-full"
                              onClick={() => this.props.history.push('/account/login')}
                            >
                              <IconCheck /> {m.nickname}
                            </button>
                          ))}
                        </div>

                        <Line />

                        <h3 className="text-h5">Don't see your name?</h3>

                        <p className="text-sm">
                          Make sure your event owner has added your name to the member&nbsp;list.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Join);
