import React, { Component } from 'react';
import auth from '../../../../services/Auth';
import { createAddress } from '../../../../services/Accounts';
import { orderSwag } from '../../../../services/Payments';
import usStates from '../../../../utils/usStates';
import { Member, GTEvent } from '../../../../types';
import { measuringTapeOrdered } from '../../../../utils/metrics';
import { getEventsAndPrimaryEventId } from '../../../../services/Events';
import Message from '../../../../components/Message';
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';

const shippingAddress = (context: any) => ({
  firstName: context.firstName,
  lastName: context.lastName,
  addressLine1: context.streetAddress,
  city: context.city,
  state: context.state,
  zip: context.zip,
  country: 'US',
  isDefault: false,
});

interface Props {}

interface State {
  firstName?: string;
  lastName?: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  firstNameError: boolean;
  lastNameError: boolean;
  streetAddressError: boolean;
  cityError: boolean;
  stateError: boolean;
  zipError: boolean;
  isSubmitting: boolean;
  orderSuccess: boolean;
  serverError: boolean;
  memberId: string;
  eventId: string;
  eventNeededError: boolean;
}

class TapeMeasureForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: auth.signedIn() ? auth.user().firstName : '',
      lastName: auth.signedIn() ? auth.user().lastName : '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      firstNameError: false,
      lastNameError: false,
      streetAddressError: false,
      cityError: false,
      stateError: false,
      zipError: false,
      isSubmitting: false,
      orderSuccess: false,
      serverError: false,
      memberId: '',
      eventId: '',
      eventNeededError: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (!this.state.eventId) {
      if (window.gt.events && window.gt.user.primaryEventId) {
        // return in a promise because the ajax call below does as well
        return new Promise((resolve) =>
          resolve({
            events: window.gt.events,
            primaryEventId: window.gt.user.primaryEventId,
          })
        );
      }
      try {
        const response = await getEventsAndPrimaryEventId(window.gt.user.id);
        if (response.status !== 200) {
          const dataError = await response.json();
          throw new Error(dataError);
        }

        const data = await response.json();

        const events = data.members
          .map((m: Member) => Object.assign(m.gtEvent || {}, { isOwner: m.isOwner, memberId: m.id }))
          .filter((e: GTEvent) => !e.isTrial);

        window.gt.events = events;

        if (events.length > 0) {
          const eventId = events[0].id;

          this.setState({
            memberId: events[0].memberId,
            eventId,
          });
        } else {
          throw new Error();
        }
      } catch (e) {
        this.setState(() => ({ eventNeededError: true }));
      }
    }
  }

  isValid() {
    let firstNameError = false;
    let lastNameError = false;
    let streetAddressError = false;
    let cityError = false;
    let stateError = false;
    let zipError = false;

    if (this.state.firstName === '') firstNameError = true;
    if (this.state.lastName === '') lastNameError = true;
    if (this.state.streetAddress === '') streetAddressError = true;
    if (this.state.city === '') cityError = true;
    if (this.state.state === '') stateError = true;
    if (this.state.zip === '') zipError = true;

    this.setState({
      firstNameError,
      lastNameError,
      streetAddressError,
      cityError,
      stateError,
      zipError,
    });

    return !(firstNameError || lastNameError || streetAddressError || cityError || stateError || zipError);
  }

  async handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    // Validate required fields
    if (this.isValid() && !this.state.eventNeededError) {
      // Submit Form
      this.setState({ isSubmitting: true });
      // create a shipping address

      try {
        const res = await createAddress(shippingAddress(this.state));
        const data = await res.json();
        await orderSwag({
          memberId: this.state.memberId,
          eventId: this.state.eventId,
          addressId: data.data.createAddress.id.toString(),
          catalogNumbers: ['P10517'],
          isSwatch: false,
        });
        measuringTapeOrdered();

        window.scrollTo(0, 0);
        this.setState({ orderSuccess: true, isSubmitting: false });
      } catch (e) {
        console.error(e);
        this.setState({ serverError: true, isSubmitting: false });
      }
    }
  }

  render() {
    return (
      <div className="mb-32 mt-8 bg-white" id="ModalTapeMeasure">
        {!this.state.orderSuccess && (
          <div className="p-32">
            <h4 className="text-h4 mb-8">Shipping Address</h4>
            <form id="shipping-address-form" className="flex flex-col gap-16">
              <div className="flex flex-col gap-16 xs:flex-row">
                <FormInput
                  className="grow"
                  errorMessage={this.state.firstNameError ? 'This field is required.' : ''}
                  id="input-firstName"
                  value={this.state.firstName}
                  label="First Name"
                  combineLabel
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ firstName: e.target.value })}
                />
                <FormInput
                  className="grow"
                  errorMessage={this.state.lastNameError ? 'This field is required.' : ''}
                  id="input-lastName"
                  value={this.state.lastName}
                  label="Last Name"
                  combineLabel
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ lastName: e.target.value })}
                />
              </div>
              <FormInput
                errorMessage={this.state.streetAddressError ? 'This field is required.' : ''}
                className="w-full"
                id="input-streetAddress"
                value={this.state.streetAddress}
                label="Street Address"
                combineLabel
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ streetAddress: e.target.value })}
              />
              <FormInput
                errorMessage={this.state.cityError ? 'This field is required.' : ''}
                className="w-full"
                id="input-city"
                value={this.state.city}
                label="City"
                combineLabel
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ city: e.target.value })}
              />
              <div className="flex flex-col gap-16 xs:flex-row">
                <FormSelect
                  errorMessage={this.state.stateError ? 'This field is required.' : ''}
                  id="input-state"
                  label="State"
                  combineLabel
                  defaultValue={this.state.state}
                  onChange={(e: React.ChangeEvent<HTMLLabelElement & HTMLSelectElement>) =>
                    this.setState({ state: e.target.value })
                  }
                >
                  <option value="">State</option>
                  {usStates.map((item) => (
                    <option value={item.abbreviation} key={item.abbreviation}>
                      {item.name}
                    </option>
                  ))}
                </FormSelect>
                <FormInput
                  errorMessage={this.state.zipError ? 'This field is required.' : ''}
                  className="w-full"
                  id="input-zip"
                  value={this.state.zip}
                  label="Zip"
                  combineLabel
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ zip: e.target.value })}
                />
              </div>
              <div className="mt-8">
                <button
                  id="btn-submit-get-tape-order"
                  className="tracker-cta-tape-submit-200619-111519 btn btn-info"
                  type="submit"
                  onClick={this.handleSubmit}
                  disabled={this.state.isSubmitting}
                >
                  {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                </button>

                {this.state.serverError && (
                  <Message type="error" message="Oops. We ran into an error. Please try again later." />
                )}
              </div>
            </form>
          </div>
        )}
        {this.state.orderSuccess && (
          <div className="mb-32 mt-8 bg-white">
            <div className="p-32">
              <h4 className="text-h4 mb-4">Your Tape Measure Is On The Way</h4>
              <p>We just sent you a confirmation to your email. Please allow 5-7 business days for delivery.</p>
            </div>
          </div>
        )}

        {this.state.eventNeededError && (
          <>
            <Message type="error" message="Event Needed" />
          </>
        )}
      </div>
    );
  }
}

export default TapeMeasureForm;
