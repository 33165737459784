import { HTO_MAX_DAYS } from '../constants';
import { Device, DeviceType, EventCreated, GTEvent, Item, Look, Member, Order, PartyRole } from '../types';
import { isDateLessThanNDaysAway } from './date';
import CustomerStore from '../stores/CustomerStore';
import EventStore from '../stores/EventStore';
import { Membership } from '../services/Events';
import { hasCurrentBlockOutDates } from './items/blockoutDates';

export const snakeToCamel = (word: string): string =>
  word
    .split('_')
    .map((chars: string, i) => (i > 0 ? chars[0].toUpperCase().concat(chars.slice(1)) : chars))
    .join('');

export const camelToSnake = (word: string) =>
  word
    .split('')
    .map((char) => (char === char.toUpperCase() ? `_${char.toLowerCase()}` : char))
    .join('');

export const unique = <T>(set: Array<T>) => set.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

export const duplicates = <T>(set: Array<T>) =>
  set.reduce((arr: T[], s, index) => {
    // Find if there is a duplicate or not
    if (set.indexOf(s, index + 1) > -1) {
      // Find if the element is already in the result array or not
      if (arr.indexOf(s) === -1) {
        return [...arr, s];
      }
    }
    return arr;
  }, []);

export function objectToCamel<T extends { [index: string]: any }>(obj: T): {} {
  let newObj: { [index: string]: any } = {};
  Object.keys(obj).forEach((key) => {
    newObj[snakeToCamel(key)] = obj[key];
  });
  return newObj;
}

export const isInArray = <T>(val: T, arr: Array<T>): boolean => arr.indexOf(val) !== -1;

export const flatten = (arr: Array<any>): Array<any> => arr.reduce((acc, val) => acc.concat(val), []);

export const flattenGeneric = <T>(arr: Array<Array<T>>): Array<T> => arr.reduce((acc, val) => acc.concat(val), []);

export const convertObjectToFormData = (obj: { [key: string]: any }, snake: boolean = false): FormData => {
  let formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      if (Array.isArray(obj[key])) {
        formData.append(snake ? camelToSnake(key) : `${key}[]`, obj[key]);
      } else {
        formData.append(snake ? camelToSnake(key) : key, obj[key]);
      }
    }
  });
  return formData;
};

/**
 *  This is a way to provide exhaustiveness checking for
 *  the typescript compiler.
 *  https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 *
 *  You can view an example of how to use this, checkout BrowseLooks.tsx.
 */
export const assertNever = (x: never): never => {
  throw new Error('Unexpected object: ' + x);
};

export const formatPhone = (phone: string): string => {
  let formattedPhone = phone.replace(/[^0-9]/g, '');

  if (formattedPhone.length > 3) {
    formattedPhone = `(${formattedPhone.substring(0, 3)}) ${formattedPhone.substring(3)}`;
  }
  if (formattedPhone.length >= 6) {
    formattedPhone = formattedPhone.substring(0, 6) + formattedPhone.substring(6);
  }
  if (formattedPhone.length >= 10) {
    formattedPhone = `${formattedPhone.substring(0, 9)}-${formattedPhone.substring(9)}`;
  }
  if (formattedPhone.length > 14) {
    formattedPhone = formattedPhone.substring(0, 14);
  }

  return formattedPhone;
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const lowerCaseFirstLetter = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function defined(...vals: any[]): boolean {
  return vals.filter((v: any) => v !== undefined).length > 0;
}

export function isSet(...vals: (string | null)[]): boolean {
  return vals.filter((v: string | null) => v !== null && v.length > 0).length > 0;
}

interface PrevStep {
  url: string;
  step: string;
}

export function getMissingSignupData(prevSteps: Array<string>): string | null {
  const c = CustomerStore.customer;
  const e = EventStore.event;
  const errors = ([] as Array<PrevStep>).concat(
    defined(c.email) && isSet(c.email!) ? [] : { url: '/signup/email', step: 'email' },
    // We need to check against the password and id because we do not save the
    // password as part of the customer once saved.
    (defined(c.password) && isSet(c.password!)) || (defined(c.id) && isSet(c.id!))
      ? []
      : { url: '/signup/password', step: 'password' },
    defined(e.role) && isSet(e.role!) ? [] : { url: '/create-event/role', step: 'role' },
    defined(e.year) ? [] : { url: '/create-event/date', step: 'date' },
    defined(e.month) ? [] : { url: '/create-event/date', step: 'date' },
    defined(e.day) ? [] : { url: '/create-event/date', step: 'date' },
    defined(c.phone) && isSet(c.phone!) ? [] : { url: '/signup/phone', step: 'phone' },
    defined(c.firstName) && isSet(c.firstName!) ? [] : { url: '/signup/name', step: 'first-name' },
    defined(c.lastName) && isSet(c.lastName!) ? [] : { url: '/signup/name', step: 'last-name' }
  );
  const missing = prevSteps
    .map((step: string) => errors.find((error: PrevStep) => error.step === step))
    .filter((val: PrevStep) => val !== undefined);
  if (missing.length > 0) {
    return (missing[0] as PrevStep).url;
  }
  return null;
}

export function isNonParticipant(member: Member) {
  const NON_PARTICIPANT = 'Non-participant';
  if (member.role !== undefined) {
    return member.role.name !== undefined
      ? member.role.name!.toLowerCase().includes(NON_PARTICIPANT.toLowerCase())
      : false;
  }
  return false;
}

export function isNonParticipantLook(look: Look) {
  const NON_PARTICIPANT = 'Non-participant';
  return look.name !== undefined ? look.name!.toLowerCase().includes(NON_PARTICIPANT.toLowerCase()) : false;
}

interface Match {
  <T>(arr1: Array<T>, arr2: Array<T>): boolean;
}
export const match: Match = (arr1, arr2) => arr1.filter((x) => x === arr2.find((y) => x === y)).length === arr1.length;

/**
 * Since Logical Disjunction is a Monoid with an identity element of false,
 * (meaning we can add 'false' and nothing is affected) we can use reduce
 * on an array of booleans and start with the identity element (false) to combine
 * them together. Example: combineUsingOr([true, true, false]) // true, combineUsingOr([false, false]) // false.
 * */
export const combineUsingOr = (arrOfBools: Array<boolean>) =>
  arrOfBools.reduce((id: boolean, bool: boolean) => id || bool, false);
/**
 * Since Logical Conjunction is a Monoid with an identity element of true,
 * (meaning we can add 'true' and nothing is affected) we can use reduce
 * on an array of booleans and start with the identity element (true) to combine
 * them together. Example: combineUsingAnd([true, true, false]) // false, combineUsingAnd([true, true]) // true.
 * */
export const combineUsingAnd = (arrOfBools: Array<boolean>) =>
  arrOfBools.reduce((id: boolean, bool: boolean) => id && bool, true);

export const range = (start: number, end: number) => Array.from({ length: end - start + 1 }, (x, i) => i + start);

export const measurementsRange = (start: number, increment: number, size = 16) => {
  let current = start;
  const range = [];
  for (let i = 0; i < size; i++) {
    range.push(current.toString());
    current += increment;
  }
  return range;
};

export const repeatString = (string: string, count: number) =>
  range(0, count - 1)
    .map(() => string)
    .join('');

export const padStart = (stringToPad: string, length: number, chars: string): string => {
  if (stringToPad.length > length) {
    return stringToPad;
  }
  const targetLength = length - stringToPad.length;
  if (targetLength > stringToPad.length) {
    const x = repeatString(chars, targetLength);
    const result = x.concat(stringToPad);
    return result; //append to original to ensure we are longer than needed
  }
  //append to original to ensure we are longer than needed
  return chars.slice(0, targetLength).concat(stringToPad);
};

export const validatePhoneArea = (phone: string): boolean => phone[1] !== '1';

export const makeSwatchParams = (look: Look) => {
  return (
    look &&
    look.items &&
    look.items
      .map((item) => item.swatch && item.swatch.catalogNumber)
      .filter((str) => str !== null)
      .join(',')
  );
};

export const makeSwatchParamsFromItems = (items: Item[]) =>
  items
    .reduce((acc, item) => {
      const itemSwatch = item.swatch;
      if (itemSwatch && (!!itemSwatch.catalogNumber || !!itemSwatch.sku)) {
        return acc + `${itemSwatch.catalogNumber || itemSwatch.sku},`;
      }
      return acc;
    }, '')
    .slice(0, -1);

export const isParticipant = (roleName: string) => {
  return roleName.toLowerCase().indexOf('non-participant') === -1;
};

export const isNumber = (number: string) => /^\d+$/.test(number);

export const isPOBox = (string: string) => {
  // lower case and remove spaces
  const testStr = string.toLowerCase().replace(/\s/g, '');
  return /p\.?o\.?box/g.test(testStr);
};

export const getItemsToAddFromProductsAndBundles = (itemsToAdd: Array<Item>) => {
  if (itemsToAdd.length === 0) return {};

  let bundleIdNewIndex = 0;
  let productIdNewIndex = 0;

  return itemsToAdd.reduce((acc: {}, item) => {
    if (item.category === 'preconfigured') {
      const bundleToAdd = {
        ...acc,
        [`bundleIdNew-${bundleIdNewIndex}`]: item.id,
      };
      bundleIdNewIndex++;
      return bundleToAdd;
    }

    const productToAdd = {
      ...acc,
      [`productIdNew-${productIdNewIndex}`]: item.id,
    };
    productIdNewIndex++;
    return productToAdd;
  }, {});
};

export const getStringCookie = (name: string) => {
  const cookie = document.cookie.split('; ').reduce((acc, val) => {
    const parts = val.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : acc;
  }, '');
  return cookie === '' ? undefined : cookie;
};

export const roundToNearestHalf = (value: number) => {
  return Math.round(value * 2) / 2;
};

export const slugify = (text: string) => text.split(' ').join('-').toLowerCase();

export const hasCancelledTrialOrder = (member: Member) =>
  member.orders &&
  member.orders.length > 0 &&
  member.orders.filter(
    (order: Order) => order.status!.trim().toUpperCase() === 'CANCELLED' && Boolean(order.isTrial) === true
  ).length > 0;

export const hasCancelledTrialOrderOrShipment = (member: Member) =>
  member.orders &&
  member.orders.length > 0 &&
  member.orders.filter(
    (order: Order) =>
      (order.status!.trim().toUpperCase() === 'CANCELLED' ||
        (order.shipment && order.shipment.status === 'CANCELLED')) &&
      Boolean(order.isTrial) === true
  ).length > 0;

export const hasNonCancelledStandardOrders = (member: Member) =>
  member.orders?.some((order) => {
    return order.status?.trim().toUpperCase() !== 'CANCELLED' && !order.isSwatch && !order.isTrial;
  }) ?? false;

export const hasNonCancelledOrders = (member: Member) =>
  member.orders?.some((order) => order.status?.trim().toUpperCase() !== 'CANCELLED') ?? false;

export const isRentingOwner = (member: Member) => {
  return member.isOwner && member.role && member.role.name!.toLowerCase().indexOf('non-participant') === -1;
};

export const isMemberLocked = (member: Member) =>
  member !== undefined && member.isPaid !== undefined ? member.isPaid : false;

export const numericStringSort = (a: string, b: string) => {
  return Number(a.match(/\d+/)![0]) - Number(b.match(/\d+/)![0]);
};

// Accepts inches and returns an array where the first number is feet, and the second number
// is inches.
export const convertToFtIn = (heightIn: number): [number, number] => [Math.floor(heightIn / 12), heightIn % 12];

export const getHeightInInches = (feet?: number, inches?: number): string => {
  if (feet === undefined || inches === undefined) {
    return '';
  }

  feet *= 12;

  return (feet + inches).toString();
};

export const formatCurrency = (num: number) => `$${num.toFixed(2)}`;

export const isGroomOrBride = (event: EventCreated) =>
  event &&
  event.partyRoleId &&
  event.eventType!.toLowerCase().includes('wedding') &&
  (event.partyRoleId === 1 || event.partyRoleId === 14);

export const getCookie = (name: string) => {
  const cookie = getStringCookie(name) ?? '{}';

  return JSON.parse(cookie);
};

export const rentingRoles = (partyRoles: PartyRole[], eventTypeId: number) =>
  partyRoles.filter((role) => role.isRenting && eventTypeId === role.gtEventType.id);

export const nonRentingRoles = (partyRoles: PartyRole[], eventTypeId: number) =>
  partyRoles.filter((role) => !role.isRenting && eventTypeId === role.gtEventType.id);

export const isRentingRole = (roles: PartyRole[], eventTypeId: number, roleName: string) =>
  rentingRoles(roles, eventTypeId).some((role) => roleName.includes(role.name));

export const random = <T extends any>(items: T[]) => {
  const randomIndex = Math.floor(Math.random() * items.length);

  return items[randomIndex];
};

export const scrollTop = () => {
  window && window.scrollTo(0, 0);
};

export const getUrlToTermsPage = (organization: string) => {
  if (organization === 'gentux') {
    return `${process.env.REACT_APP_ECOMM_URL}/terms-and-conditions`;
  }

  return `${process.env.REACT_APP_ECOMM_URL}/support/terms-and-conditions`;
};

export const getUrlToPrivacyPage = (organization: string) => {
  if (organization === 'gentux') {
    return `${process.env.REACT_APP_ECOMM_URL}/privacy`;
  }

  return `${process.env.REACT_APP_ECOMM_URL}/support/privacy-policy`;
};

export const isGentux = () => {
  return Number(process.env.REACT_APP_ORGANIZATION_ID) === 1;
};

export const isMenguin = () => {
  return Number(process.env.REACT_APP_ORGANIZATION_ID) === 2;
};

export const toQueryString = (params: { [key: string]: string | number | boolean }) => {
  const entries = Object.entries(params).map(([key, value]) => {
    return `${key}=${encodeURIComponent(value)}`;
  });

  return entries.join('&');
};

export const getDevice = (navigator: Navigator): Device => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isPhone = /iphone|ipod|android|windows phone/g.test(userAgent);
  const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

  if (isPhone) return { type: DeviceType.Phone };

  if (isTablet) return { type: DeviceType.Tablet };

  return { type: DeviceType.Desktop };
};

export function ArrayShuffle(array: any[]) {
  const newArray = [...array];
  let currentIndex = newArray.length;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [newArray[currentIndex], newArray[randomIndex]] = [newArray[randomIndex], newArray[currentIndex]];
  }

  return newArray;
}

/**
 * Determines if an image is already loaded and cached by the browser.
 * @param imgUrl
 */
export function isImgCached(imgUrl: string) {
  const img = new Image();
  img.src = imgUrl;
  const isCached = img.complete || img.width + img.height > 0;

  // Explicitly clear out the img object after checking
  img.onload = img.onerror = null;
  img.src = '';

  return isCached;
}

export interface SupportNumber {
  asTelLink: () => string;
  value: string;
}

export const getSupportNumber = (organization: number): SupportNumber => {
  let supportNumber = '';

  switch (organization) {
    case 1:
      supportNumber = '844.726.4889';
      break;
    case 2:
      supportNumber = '844.636.4846';
      break;
    default:
      throw new Error(`Unexpected organization value: ${organization}`);
  }

  return {
    value: supportNumber,
    asTelLink: () => `tel:1-${supportNumber.replaceAll('.', '-')}`,
  };
};

export enum HTO_STATUSES {
  ALREADY_IN_PROGRESS,
  AVAILABLE,
  DATE_TOO_CLOSE,
  HAS_BLOCKOUT_DATE,
  IS_RETAIL, // not allowed to HTO on retail garments
}

/**
 * Returns a boolean indicating whether a member's given party role is one that's
 * eligible for renting an HTO
 */
export const isPartyRoleEligibleForHto = (partyRole: string) => ['bride', 'groom'].includes(partyRole.toLowerCase());

export const checkEventHTOStatus = ({ event, memberships }: { event: GTEvent; memberships: Membership[] }) => {
  if (event && event.startDate && isDateLessThanNDaysAway(event.startDate, HTO_MAX_DAYS)) {
    return HTO_STATUSES.DATE_TOO_CLOSE;
  }

  if (
    memberships.filter(
      (m: Member) => !Boolean(m.isReturned) && Boolean(m.gtEvent!.isTrial) && !hasCancelledTrialOrderOrShipment(m)
    ).length > 0
  ) {
    return HTO_STATUSES.ALREADY_IN_PROGRESS;
  }

  return HTO_STATUSES.AVAILABLE;
};

export const checkLookHTOStatus = ({
  event,
  look,
  memberships,
}: {
  event: GTEvent;
  look: Look;
  memberships: Membership[];
}): HTO_STATUSES => {
  const eventHtoStatus = checkEventHTOStatus({ event, memberships });

  // if the HTO check failed at event level return it right away
  if (eventHtoStatus !== HTO_STATUSES.AVAILABLE) return eventHtoStatus;

  const bundle = look.bundles?.[0];

  if (bundle && hasCurrentBlockOutDates((bundle.blockoutDates || bundle.blockout_dates) ?? [])) {
    return HTO_STATUSES.HAS_BLOCKOUT_DATE;
  }

  if (bundle?.isRetail === true || String(bundle?.displayName).toUpperCase().includes('PURCHASE')) {
    return HTO_STATUSES.IS_RETAIL;
  }

  return HTO_STATUSES.AVAILABLE;
};
