import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import CustomerStore from '../../stores/CustomerStore';
import CustomerFormStore from '../../stores/CustomerFormStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import auth from '../../services/Auth';
import { AccessContext } from '../../utils/HOC/';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import Flow from '../../utils/HOC/Flow';
import { validateEmail } from '../../services/Accounts';
import Loading from '../../utils/Component/Loading';
import { parse } from 'query-string';
import { accountCreated } from '../../utils/metrics';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  password?: string;
  loading: boolean;
  submitting: boolean;
  error?: string;
}

class Password extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      password: CustomerFormStore.password || '',
      loading: true,
      submitting: false,
      error: undefined,
    };
  }

  async componentDidMount() {
    if (auth.signedIn()) {
      return this.props.history.replace(`/signup/phone${this.props.location.search}`);
    }

    const parsed = parse(this.props.location.search);
    if (parsed.email) {
      try {
        await this.isEmailValid(parsed.email);
        CustomerFormStore.update('email', parsed.email);
      } catch (e) {
        console.error(e);
        return this.props.history.replace(`/signup/email`);
      }
    } else {
      if (!CustomerFormStore.email || CustomerFormStore.email === '') {
        return this.props.history.replace(`/signup/email${this.props.location.search}`);
      }
    }

    this.setState({ loading: false });
  }

  isEmailValid = async (email: string) => {
    try {
      const response = await validateEmail(email);

      const data = await response.json();

      if (data.errors || data.isValid === false) {
        throw new Error('Provided email is invalid.');
      }

      return data.isValid;
    } catch (e) {
      let errorMessage = 'Failed to validate email.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(e);
      throw new Error(errorMessage);
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value });

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    CustomerFormStore.update('password', this.state.password!);
    this.setState(() => ({
      submitting: true,
    }));
    try {
      await CustomerStore.create(CustomerFormStore.email!, CustomerFormStore.password!);
      accountCreated(CustomerStore.customer);
      auth.storeUserJson(CustomerStore.customer);
      if (CustomerStore.customer.primaryEventId) {
        return this.props.history.push(`/account/events${this.props.location.search}`);
      }
      this.nextPage();
    } catch (e) {
      let errorMessage = 'Failed to create customer.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      this.setState(() => ({
        error: errorMessage,
        submitting: false,
      }));
    }
  };

  nextPage = () => {
    this.setState(() => ({
      error: undefined,
    }));

    this.props.flow!(this.props.location.search);
  };

  render() {
    const customSubtitle = (
      <p className="text-center">
        By creating an account I agree to the{' '}
        <a
          href={
            window.organization === 'gentux'
              ? `${process.env.REACT_APP_ECOMM_URL}/home/terms-and-conditions`
              : `${process.env.REACT_APP_ECOMM_URL}/support/terms-and-conditions`
          }
        >
          Terms and Conditions
        </a>
      </p>
    );

    return (
      <>
        <FormFlowLogo />

        {this.state.loading && <Loading />}
        {!this.state.loading && (
          <FormSingleInput
            customSubtitle={customSubtitle}
            error={this.state.error!}
            key="password"
            label="Password"
            loading={this.state.submitting}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            property="password"
            title="Set a password."
            type="password"
            value={this.state.password!}
          />
        )}
      </>
    );
  }
}

export default Flow(AccessContext(observer(Password)));
