// types
import { FitActions } from '../types';
import { isNumber } from '../utils/utils';

const isValidMeasurement = (number: string) => /^\d+(\.)?(5)?$/.test(number);

// the reducer
const FitReducer = (action: FitActions, changes: any): any => {
  switch (action) {
    case 'HEIGHT_SET':
      const { heightFt, heightIn } = changes;
      return { heightFt, heightIn };

    case 'WEIGHT_SET':
      const { weight } = changes;

      // no changes if the below conditions are met
      if (weight.length > 3 || (weight !== '' && !isNumber(weight))) {
        return {};
      }

      // set the new weight
      return { weight: weight };

    case 'AGE_SET':
      const { age } = changes;

      if (age === undefined || age.length > 2) {
        return;
      }

      // check if its a number, unless the value is an empty string
      if (age !== '' && !isNumber(age)) {
        return;
      }

      return { age: age };

    case 'SHOE_SET':
      const { shoeSize, shoeType } = changes;
      return {
        shoeSize: shoeSize === undefined ? undefined : parseFloat(shoeSize),
        shoeType,
      };

    case 'WAIST_IN_SET':
      const { pantWaist } = changes;

      // no reason to go over 2 digits
      if (pantWaist.length > 2) {
        return {};
      }

      // check if its a number, unless the value is an empty string
      if (pantWaist !== '' && !isNumber(pantWaist)) {
        return {};
      }

      return { pantWaist };
    case 'BUILD_SET':
      const { buildProfile, buildStomach } = changes;
      return { buildProfile, buildStomach };

    case 'PREFERENCE_SET':
      const { preference } = changes;
      return { preference };

    case 'MEASURED_BY_TAILOR_SET':
      const { measuredByTailor } = changes;
      return { measuredByTailor };

    case 'NECK_SET':
      const { neck } = changes;
      if (isValidMeasurement(neck) || neck === '') {
        return { neck };
      }
      return {};

    case 'OVERARM_SET':
      const { overarm } = changes;
      if (isValidMeasurement(overarm) || overarm === '') {
        return { overarm };
      }
      return {};

    case 'CHEST_SET':
      const { chest } = changes;
      if (isValidMeasurement(chest) || chest === '') {
        return { chest };
      }
      return {};

    case 'ARMLENGTH_SET':
      const { armLength } = changes;
      if (isValidMeasurement(armLength) || armLength === '') {
        return { armLength };
      }
      return {};

    case 'STOMACH_SET':
      const { stomach } = changes;
      if (isValidMeasurement(stomach) || stomach === '') {
        return { stomach };
      }
      return {};

    case 'WAIST_SET':
      const { waist } = changes;
      if (isValidMeasurement(waist) || waist === '') {
        return { waist };
      }
      return {};

    case 'OUTSEAM_SET':
      const { outseam } = changes;
      if (isValidMeasurement(outseam) || outseam === '') {
        return { outseam };
      }
      return {};

    default:
      return {};
  }
};

export default FitReducer;
