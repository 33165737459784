import React from 'react';

interface IconUndoProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconUndoProps>;

const IconUndo: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-[10px] w-[10px] fill-current stroke-current text-black ${className ? className : ''}`}
    >
      <title>Undo Icon</title>
      <path d="M5 6L1 3L5 0V6Z" stroke="none" />
      <path d="M4 3H9.5V9H0" fill="none" />
    </svg>
  );
};

export default IconUndo;
