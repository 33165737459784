import React, { Component } from 'react';
import usStates from '../../../../utils/usStates';
import { Address, Order } from '../../../../types';
import { orderHasShipped } from '../../../../utils/checkout';
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';
import Message from '../../../../components/Message';

interface Props {
  address: Address;
  validateAddress: () => Promise<unknown>;
  saveAddress: () => void;
  submitting: boolean;
  handleAddressChange: (field: string, value: string) => void;
  cancelEdit: () => void;
  order?: Order;
  error?: string;
  success?: boolean;
}

interface State {
  firstNameError: boolean;
  lastNameError: boolean;
  addressLine1Error: boolean;
  cityError: boolean;
  stateError: boolean;
  zipError: boolean;
}

class EditAddressForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstNameError: false,
      lastNameError: false,
      addressLine1Error: false,
      cityError: false,
      stateError: false,
      zipError: false,
    };
  }

  validateForm = () => {
    let firstNameError = false;
    let lastNameError = false;
    let addressLine1Error = false;
    let cityError = false;
    let stateError = false;
    let zipError = false;

    if (this.props.address.firstName === '') firstNameError = true;
    if (this.props.address.lastName === '') lastNameError = true;
    if (this.props.address.addressLine1 === '') addressLine1Error = true;
    if (this.props.address.city === '') cityError = true;
    if (this.props.address.state === '') stateError = true;
    if (this.props.address.zip === '') zipError = true;

    this.setState(() => ({
      firstNameError,
      lastNameError,
      addressLine1Error,
      cityError,
      stateError,
      zipError,
    }));

    return !(firstNameError || lastNameError || addressLine1Error || cityError || stateError || zipError);
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.validateForm() || orderHasShipped(this.props.order!)) return;
    if (this.props.validateAddress) {
      const isValidated = await this.props.validateAddress();

      if (isValidated) {
        await this.props.saveAddress();
      }
    }
  };

  render() {
    return (
      <div id="address-section">
        <h3 className="text-h3 mb-16">Edit Shipping Address</h3>

        <form className="flex flex-col gap-16" id="address-form-section" onSubmit={(e) => this.handleSubmit(e)}>
          <FormInput
            errorMessage={this.state.firstNameError ? 'This field is required.' : ''}
            id="input-first1"
            type="text"
            label="First Name"
            combineLabel
            value={this.props.address.firstName || ''}
            disabled={this.props.submitting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleAddressChange('firstName', e.target.value)
            }
          />
          <FormInput
            errorMessage={this.state.lastNameError ? 'This field is required.' : ''}
            id="input-last1"
            type="text"
            label="Last Name"
            combineLabel
            value={this.props.address.lastName || ''}
            disabled={this.props.submitting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleAddressChange('lastName', e.target.value)
            }
          />
          <FormInput
            errorMessage={this.state.addressLine1Error ? 'This field is required.' : ''}
            id="input-addr-1"
            type="text"
            label="Street Addess"
            combineLabel
            value={this.props.address.addressLine1 || ''}
            disabled={this.props.submitting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleAddressChange('addressLine1', e.target.value)
            }
          />
          <FormInput
            id="input-addr-2"
            type="text"
            label="Apartment, Suite, Company Name"
            combineLabel
            value={this.props.address.addressLine2 || ''}
            disabled={this.props.submitting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleAddressChange('addressLine2', e.target.value)
            }
          />
          <FormInput
            errorMessage={this.state.cityError ? 'This field is required.' : ''}
            id="input-city1"
            type="text"
            label="City"
            combineLabel
            value={this.props.address.city || ''}
            disabled={this.props.submitting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleAddressChange('city', e.target.value)
            }
          />
          <FormSelect
            combineLabel
            errorMessage={this.state.stateError ? 'This field is required.' : ''}
            label="State"
            id="shippingState"
            value={this.props.address.state || ''}
            disabled={this.props.submitting}
            onChange={(e: React.ChangeEvent<HTMLLabelElement & HTMLSelectElement>) =>
              this.props.handleAddressChange('state', e.target.value)
            }
          >
            <option value="">State</option>
            {usStates.map((state) => (
              <option key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </option>
            ))}
          </FormSelect>
          <FormInput
            errorMessage={this.state.zipError ? 'This field is required.' : ''}
            id="input-zip1"
            type="text"
            label="Zip"
            combineLabel
            value={this.props.address.zip || ''}
            disabled={this.props.submitting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.handleAddressChange('zip', e.target.value)}
          />
          <div>
            <button
              type="button"
              disabled={this.props.submitting}
              onClick={() => this.props.cancelEdit()}
              className="tracker-cta-edit_addr-cancel-200619-111519 btn btn-default mr-16"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={this.props.submitting}
              className="tracker-cta-edit_addr-save-200619-111519 btn btn-info"
            >
              Save
            </button>
          </div>
        </form>
        {this.props.error && (
          <div className="mt-16">
            <Message type="error" message={this.props.error} />
          </div>
        )}
      </div>
    );
  }
}

export default EditAddressForm;
