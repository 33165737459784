import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { themeComponents } from '../utils/theme';
import { Member } from '../types';
import { isNonParticipant } from '../utils/utils';
import EventStore from '../stores/EventStore';
import MemberStore from '../stores/MemberStore';
import CustomerStore from '../stores/CustomerStore';
import { format } from 'date-fns';
import HelpButton from './HelpButton';
import IconPencil from '../components/IconPencil';
import IconPerson from '../components/IconPerson';

interface Props extends RouteComponentProps<any> {}

class NavBar extends React.Component<Props> {
  hasPaidMembers = (members: Array<Member>): boolean =>
    members.filter((x) => x.isPaid && !isNonParticipant(x)).length > 0;

  isEventEditable = () => {
    return !this.hasPaidMembers(MemberStore.members);
  };

  renderLinks = () =>
    [
      { url: '/event-flow/looks', title: '1. Looks', key: 1 },
      { url: '/event-flow/members', title: '2. Members', key: 2 },
      { url: '/event-flow/assign', title: '3. Assign', key: 3 },
      { url: '/event-flow/invite', title: '4. Invite', key: 4 },
      { url: '/event-flow/fit', title: '5. Fit', key: 5 },
      { url: '/event-flow/checkout', title: '6. Checkout', key: 6 },
    ].map((link) => {
      const selected = new RegExp(link.url).test(window.location.href);
      return (
        <button
          key={link.key}
          className={`
          tracker-nav-main_nav-${link.key}-20190821-160819
          relative mr-16 flex cursor-pointer items-center justify-center whitespace-nowrap
          py-8 text-center no-underline after:absolute after:inset-x-0
          after:bottom-0 after:h-1 after:origin-bottom-left after:bg-black after:duration-200 xs:py-16 md:mr-32
          ${
            selected
              ? 'text-black after:scale-x-100 after:opacity-100'
              : 'text-brand-darker after:scale-x-0 after:opacity-0'
          }
          hover:text-black hover:after:scale-x-100 hover:after:opacity-100
          `}
          onClick={() => {
            this.props.history.push(`${link.url}${this.props.location.search}`);
          }}
        >
          <p className="text-sm">{link.title}</p>
        </button>
      );
    });

  render() {
    return (
      <div className="relative mb-32 bg-white shadow-lg" style={{ zIndex: 900 }}>
        <div className="relative z-20 flex items-stretch">
          <div className="mx-16 flex items-center sm:mx-32">
            <a className="tracker-nav-main_nav-logo-20190821-160819 block" href={`${process.env.REACT_APP_ECOMM_URL}`}>
              {themeComponents.logo[window.organization]({})}
            </a>
          </div>
          <div style={{ flexGrow: 1 }}>
            <div
              className={`
            relative inline-flex cursor-pointer flex-col justify-center py-8 no-underline after:absolute 
            after:inset-x-0 after:bottom-[-1px] after:h-1 after:origin-bottom-left after:bg-black after:duration-200 xs:py-16
            ${
              new RegExp(`/event-flow/details`).test(window.location.href)
                ? 'after:scale-x-100 after:opacity-100'
                : 'after:scale-x-0 after:opacity-0'
            }
            tracker-nav-main_nav-event_name-20190821-160819 hover:after:scale-x-100
            hover:after:opacity-100
            `}
              onClick={() => {
                this.props.history.push(`/event-flow/details${this.props.location.search}`);
              }}
            >
              <div className="normalcase text-h4-display mb-4 flex items-center gap-4 text-black">
                {EventStore.event.name}&nbsp;
                <IconPencil />
              </div>
              <div className="text-h5 leading-none text-black">
                {EventStore.event.startDate !== undefined
                  ? format(EventStore.event.startDate || '', 'MMM D, YYYY')
                  : ''}
              </div>
            </div>
          </div>

          <button
            onClick={() => this.props.history.push(`/account/contact${this.props.location.search}`)}
            className="
              tracker-nav-main_nav-my_account-200317-070539 relative flex
              items-center after:absolute after:inset-x-0 after:bottom-[-1px] after:h-1 after:origin-bottom-left after:scale-x-0 after:bg-black after:opacity-0
              after:duration-200 hover:after:scale-x-100
              hover:after:opacity-100
              "
          >
            <IconPerson />
            <span className="text-h5 ml-8 hidden xs:inline-block">
              {CustomerStore.firstName ? CustomerStore.firstName : 'Account'}
            </span>
          </button>

          <HelpButton trackerClass="tracker-nav-main_nav-help-210730-130721" />
        </div>

        <hr className="border-gray-lighter" />

        <div className="container p-0">
          <div className="row">
            <div className="col-span-12 flex flex-wrap items-stretch sm:col-span-11 sm:col-start-2">
              <div className="relative max-w-full grow after:pointer-events-none after:absolute after:right-0 after:top-0 after:block after:h-full after:w-56 after:bg-gradient-to-r after:from-[rgba(255,255,255,0)] after:to-white xs:after:hidden">
                <div className="relative flex justify-between overflow-x-auto px-16 scrolling-touch scrollbar-none after:block after:text-transparent after:content-['spacer'] xs:after:content-[''] sm:px-32">
                  {this.renderLinks()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(NavBar));
