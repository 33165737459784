import { camelToSnake } from '../utils/utils';
import { GTEvent, Member, CheckoutRequest } from '../types';

const convertObjectToFormData = (obj: { [key: string]: any }, snake: boolean = false): FormData => {
  let formData = new FormData();
  Object.keys(obj).forEach(key => {
    if (obj[key] !== undefined) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((a: any) => {
          formData.append(snake ? camelToSnake(key) : `${key}[]`, a);
        });
      } else {
        formData.append(snake ? camelToSnake(key) : key, obj[key]);
      }
    }
  });
  return formData;
};

export const orderSwag = async (order: {
  memberId: string;
  eventId: string;
  addressId: string;
  catalogNumbers: Array<string>;
  campaignId?: number;
  isSwatch: boolean;
}): Promise<Response> =>
  fetch(`/api/order-swag`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(order, false),
  });

export const checkout = async (data: CheckoutRequest) =>
  fetch(`/api/checkout`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(data, false),
  });

export const orderDetails = async (event: GTEvent, membersToPayFor: Member[]) =>
  fetch(`/api/order-details`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(
      {
        eventId: event.id,
        membersToPayFor: membersToPayFor.map(member => member.id).join(','),
      },
      false
    ),
  });

export const validatePromos = async (event: GTEvent, promoCodes: string, membersToPayFor: Member[]) =>
  fetch('/api/validate-promos', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(
      {
        eventId: event.id,
        membersToPayFor: membersToPayFor.map(member => member.id).join(','),
        promos: promoCodes,
      },
      false
    ),
  });
