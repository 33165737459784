export const createNPS = async (
  email: string,
  score: number,
  comments: string,
  shipmentId: number | null
): Promise<Response> => {
  return fetch(`/api/nps/create-score`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      email,
      score,
      comments,
      shipment_id: shipmentId,
    }),
  });
};
