import { HTMLProps } from 'react';

import LookCardBaseModal, { BaseModalProps } from './BaseModal';
import { MENGUIN_SUPPORT_EMAIL } from '../../../constants';
import IconCheck from '../../../components/IconCheck';
import IconBriefcase from '../../../components/IconBriefcase';
import { getSupportNumber, HTO_STATUSES, isGentux } from '../../../utils/utils';

const organizationId = Number(process.env.REACT_APP_ORGANIZATION_ID ?? 1);

export type LookCardHtoModalProps = BaseModalProps & {
  bundleId: number | undefined;
  htoStatus?: HTO_STATUSES;
  productSkus: string[];
};

const ModalButton = ({ className, children, onClick }: HTMLProps<HTMLButtonElement>) => (
  <button
    className={`btn w-full px-8 uppercase${
      isGentux() ? ' text-[9px] sm:text-xs' : ' font-condensed text-base'
    } ${className}`}
    data-testid="hto-modal-btn"
    onClick={onClick}
  >
    {children}
  </button>
);

const LookCardHtoModal = ({ bundleId, htoStatus, productSkus, onClose, show }: LookCardHtoModalProps) => {
  const linkToURL = `/hto/looks/build?htoFlow=true&sidecar=list&product_skus=${productSkus.join()}&bundle_ids=${bundleId}
  &redirect=true&htoSource=app`;
  const supportEmailLink = isGentux()
    ? `${process.env.REACT_APP_ECOMM_URL}/support/email-us`
    : `mailto:${MENGUIN_SUPPORT_EMAIL}`;

  return (
    <LookCardBaseModal show={show} onClose={onClose}>
      {htoStatus === HTO_STATUSES.AVAILABLE && (
        <div className="grid gap-8" data-testid="hto-modal-available">
          <h2 className="text-h6" data-testid="hto-modal-title">
            {'How our free home try-on works:'}
          </h2>
          <p
            className={`${isGentux() ? 'text-[9px] sm:text-[11px]' : 'text-[10px] sm:text-xs'}`}
            data-testid="hto-modal-desc1"
          >
            {
              'We created our free Home Try-On program so Grooms can try on their outfits months in advance and ensure they love their Look.'
            }
          </p>

          <div className="mb-16 mt-8 grid gap-4 text-[10px] sm:text-xs" data-testid="hto-modal-desc2">
            {[
              'First we’ll confirm your sizing.',
              'Then we ship straight to you.',
              'You try it on!',
              'Return in the pre-paid package.',
            ].map((text, i) => (
              <div className="flex" key={`hto-desc-${i}`}>
                <IconCheck className="mr-4" />
                {text}
              </div>
            ))}
          </div>

          <a href={linkToURL} data-testid="hto-modal-anchor">
            <ModalButton className={`btn-info`} data-testid="hto-modal-btn">
              <IconBriefcase style={{ cursor: 'pointer' }} />
              <span className="ml-8">{'Order free home try-on'}</span>
            </ModalButton>
          </a>
        </div>
      )}

      {htoStatus === HTO_STATUSES.ALREADY_IN_PROGRESS && (
        <div data-testid="hto-modal-in-progress">
          <h2 className="text-h6 sm:text-h5" data-testid="hto-modal-title">
            {'Free home try-on status:'}
          </h2>
          <p className="text-xs my-8 sm:my-16" data-testid="hto-modal-desc1">
            We see you currently have a Home Try-On in process. If you have any changes or questions, please contact our
            Customer Support team by{' '}
            <a href={supportEmailLink} rel="noreferrer" target={`${isGentux() ? '_blank' : '_self'}`}>
              {isGentux() ? 'Email' : MENGUIN_SUPPORT_EMAIL}
            </a>
            , or call{' '}
            <a href={getSupportNumber(organizationId).asTelLink()}>{getSupportNumber(organizationId).value}</a> so we
            can help you finalize your wedding look.
          </p>
          <ModalButton className="btn-default mt-8 sm:mt-16" data-testid="hto-modal-btn" onClick={() => onClose()}>
            <IconCheck style={{ cursor: 'pointer' }} />
            <span className="ml-8">{'Dismiss'}</span>
          </ModalButton>
        </div>
      )}

      {htoStatus !== HTO_STATUSES.AVAILABLE && htoStatus !== HTO_STATUSES.ALREADY_IN_PROGRESS && (
        <div data-testid="hto-modal-not-available">
          <h2 className="text-h6 sm:text-h5" data-testid="hto-modal-title">
            {'Sorry but This Look is temporarily unavailable for Home Try-On.'}
          </h2>
          <p className="text-xs my-8 sm:my-16" data-testid="hto-modal-desc1">
            {'Please select another Look.'}
          </p>
          <ModalButton className="btn-default mt-8 sm:mt-16" data-testid="hto-modal-btn" onClick={() => onClose()}>
            <IconCheck style={{ cursor: 'pointer' }} />
            <span className="ml-8">{'Dismiss'}</span>
          </ModalButton>
        </div>
      )}
    </LookCardBaseModal>
  );
};

export default LookCardHtoModal;
