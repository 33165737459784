import React, { Component } from 'react';

// local components
import FormNavigation from '../../utils/Component/FormNavigation';
import { themeImages } from '../../utils/theme';

// types
import { FitActions, FitBuildStomach, FitbuildProfile, FlowRouteProps } from '../../types';

// utils
import Flow from '../../utils/HOC/Flow';
import FormRadio from '../../components/FormRadio';
import FormRadioItem from '../../components/FormRadioItem';

interface Props extends FlowRouteProps<any> {
  reducer: (action: FitActions, changes: any) => void;
  buildStomach?: FitBuildStomach;
  buildProfile?: FitbuildProfile;
}

// Constants
// Images[stomach][build]
const IMAGES = {
  flat: {
    skinny: themeImages.flatSkinny[window.organization],
    average: themeImages.flatAverage[window.organization],
    muscular: themeImages.flatMuscular[window.organization],
  },
  average: {
    skinny: themeImages.averageSkinny[window.organization],
    average: themeImages.averageAverage[window.organization],
    muscular: themeImages.averageMuscular[window.organization],
  },
  rounded: {
    skinny: themeImages.roundedSkinny[window.organization],
    average: themeImages.roundedAverage[window.organization],
    muscular: themeImages.roundedMuscular[window.organization],
  },
};

class FitBuild extends Component<Props> {
  setStomach = (buildStomach: Props['buildStomach']) => {
    const { reducer, buildProfile } = this.props;
    reducer('BUILD_SET', { buildProfile, buildStomach });
  };

  setBuild = (buildProfile: Props['buildProfile']) => {
    const { reducer, buildStomach } = this.props;
    reducer('BUILD_SET', { buildProfile, buildStomach });
  };

  onSubmit = () => {
    this.props.flow!(`${this.props.location.search}`);
  };

  render() {
    const { buildStomach, buildProfile } = this.props;

    return (
      <div data-testid="fitflow-build" className="mx-auto max-w-lg">
        <h2 className="text-h2-display mb-32 text-center">How would you describe your&nbsp;build?</h2>

        <div className="mb-32 flex flex-col items-stretch justify-center gap-16 xs:flex-row">
          <div className="flex grow items-center bg-white p-16">
            <img
              style={{
                margin: '0 auto',
                display: 'block',
                opacity: buildProfile === undefined || buildStomach === undefined ? '.3' : '1',
              }}
              src={IMAGES[buildStomach || 'average'][buildProfile || 'average']}
              alt={`Profile of a male body with ${buildStomach} stomach and ${buildProfile} build`}
            />
          </div>

          <div className="flex grow flex-col gap-16">
            <FormRadio label="Stomach" darkMode>
              <FormRadioItem label="Flat" checked={buildStomach === 'flat'} onChange={() => this.setStomach('flat')} />
              <FormRadioItem
                label="Average"
                checked={buildStomach === 'average'}
                onChange={() => this.setStomach('average')}
              />
              <FormRadioItem
                label="Rounded"
                checked={buildStomach === 'rounded'}
                onChange={() => this.setStomach('rounded')}
              />
            </FormRadio>

            <FormRadio label="Build" darkMode>
              <FormRadioItem
                label="Thin"
                checked={buildProfile === 'skinny'}
                onChange={() => this.setBuild('skinny')}
              />
              <FormRadioItem
                label="Average"
                checked={buildProfile === 'average'}
                onChange={() => this.setBuild('average')}
              />
              <FormRadioItem
                label="Muscular"
                checked={buildProfile === 'muscular'}
                onChange={() => this.setBuild('muscular')}
              />
            </FormRadio>
          </div>
        </div>

        <FormNavigation next={this.onSubmit} disabled={buildProfile === undefined || buildStomach === undefined} />
      </div>
    );
  }
}

export default Flow(FitBuild);
