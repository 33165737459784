import React from 'react';
import FormContainer from './FormContainer';
import { formBaseClassNames, formBaseClassNamesDarkMode, hasCombinedLabel } from './FormUtils';

interface FormTextAreaProps extends React.HTMLProps<HTMLLabelElement & HTMLTextAreaElement> {
  combineLabel?: Boolean;
  darkMode?: Boolean;
  inputAddOn?: String;
  errorMessage?: String;
  disabledMessage?: String;
  description?: string;
  onChange?: (event: React.ChangeEvent<HTMLLabelElement & HTMLTextAreaElement>) => void;
}

type Props = React.FC<FormTextAreaProps>;

const FormTextArea: Props = ({ placeholder = 'Enter something...', ...props }) => {
  var {
    combineLabel,
    label,
    inputAddOn,
    errorMessage,
    disabledMessage,
    darkMode,
    className,
    description,
    ...cleanedProps
  } = props;

  const combinedLabel = hasCombinedLabel(props);

  return (
    <FormContainer
      className={className}
      label={label}
      disabled={props.disabled}
      darkMode={darkMode}
      inputAddOn={inputAddOn}
      errorMessage={errorMessage}
      disabledMessage={disabledMessage}
      description={description}
      hasCombinedLabel={combinedLabel}
    >
      <textarea
        cols={40}
        rows={4}
        placeholder={placeholder}
        {...cleanedProps}
        className={`
          ${formBaseClassNames} 
          ${combinedLabel ? 'pt-40' : ''} 
          ${darkMode ? formBaseClassNamesDarkMode : ''}
        `}
      />
    </FormContainer>
  );
};

export default FormTextArea;
