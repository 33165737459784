import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconCufflinks: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-[15px] w-16 stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Cufflinks Icon</title>
      <path d="M11.5547 10.6666V4.4443M12.7407 13.3334h-2.3703c-1.3037 0-2.3704-1.2-2.3704-2.6667h7.1111c0 1.4667-1.0667 2.6667-2.3704 2.6667zM8.8906 6.2222h5.3334M4.4453 9.7779V3.5557M3.261.889h2.3704c1.3037 0 2.3703 1.2 2.3703 2.6666H.8907C.8906 2.0889 1.9572.8889 3.261.8889zM7.1107 8H1.7773" />
    </svg>
  );
};

export default IconCufflinks;
