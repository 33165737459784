import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import IconX from '../../components/IconX';
import IconArrowLeft from '../../components/IconArrowLeft';
import Line from '../../components/Line';
import { url } from '../../utils/window';
import { themeComponents } from '../../utils/theme';
import CustomerStore from '../../stores/CustomerStore';
import auth from '../../services/Auth';

import MyAccountNav from '../components/MyAccountNav';
import CustomerClonedEventPromos from '../components/CustomerClonedEventPromos';

const MyAccountWrapper = <P extends RouteComponentProps<any>>(WrappedComponent: React.ComponentType<P>) =>
  class MyAccountWrapper extends React.Component<P, {}> {
    componentDidMount() {
      if (!window.gt.user) {
        return this.props.history.push(
          `/gate/login?redirect=${this.props.location.pathname}${this.props.location.search}`
        );
      } else {
        CustomerStore.loadCustomer(auth.user());
      }
    }

    exit = (eventId: string | null) => {
      window.scrollTo(0, 0);
      eventId
        ? this.props.history.push(`/event-flow/looks${this.props.location.search}`)
        : (window.location.href = `${process.env.REACT_APP_ECOMM_URL}`);
    };

    render() {
      const eventId = url.param(this.props.location.search, 'eventId');

      return (
        <div className="m-auto max-w-4xl px-16">
          <div className="pt-16">
            <div className="flex items-center justify-between">
              <a className="tracker-nav-main_nav-logo-20190821-160819" href={`${process.env.REACT_APP_ECOMM_URL}`}>
                {themeComponents.logo[window.organization]({})}
              </a>
              <div
                onClick={() => this.exit(eventId)}
                aria-label="Close"
                className="tracker-button-account-close-200619-111519"
              >
                <IconX />
              </div>
            </div>
          </div>
          <div className="py-32 md:py-64">
            <CustomerClonedEventPromos />
            <div className="row">
              <div className="col-span-full mb-32 sm:col-span-3">
                <MyAccountNav />
              </div>
              <div className="col-span-0 sm:col-span-1" />
              <div className="col-span-full sm:col-span-8">
                <WrappedComponent {...this.props} />
                {!this.props.location.pathname.includes('/orders/') && (
                  <div className="pt-16 sm:pt-0">
                    <Line className="pb-16 sm:pb-0" />
                    <button
                      className="tracker-cta-account-back-200619-111519 btn btn-default"
                      onClick={() => this.exit(eventId)}
                    >
                      <IconArrowLeft /> Back to {eventId ? 'My Event' : 'Home'}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

export default MyAccountWrapper;
