import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import type { Customer, ShareData } from '../../../types';
import LookCardBaseModel from './BaseModal';
import HorizontalRuleWithText from '../../../components/HorizontalRuleWithText';
import IconLink from '../../../components/IconLink';
import IconPhone from '../../../components/IconPhone';
import IconCheck from '../../../components/IconCheck';
import IconAlert from '../../../components/IconAlert';
import auth from '../../../services/Auth';
import { getMember } from '../../../services/Events';
import { setCommunicationPreferences } from '../../../services/Accounts';
import MemberStore from '../../../stores/MemberStore';
import { MessageMedium, MessageType } from '../../../utils/CommunicationPreferences';
import { sharedLookCopyLinkBtnClickTrack, sharedLookTrack, sharedLookSmsBtnClickTrack } from '../../../utils/metrics';
import { isGentux } from '../../../utils/utils';

type Props = {
  onClose: () => void;
  shareData?: ShareData;
};

const getCustomerData = async (): Promise<Customer> => {
  const res = await getMember(MemberStore.getSignedInMember()!.id!);

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const { data } = await res.json();

  if (!data?.member?.customer) {
    throw new Error('Failed to retrieve customer data.');
  }

  return data.member.customer;
};

export const sendShareLink = async (lookUrl: string, lookName: string) => {
  const { phone } = await getCustomerData();

  sharedLookTrack({
    phone,
    lookUrl,
    lookName,
  });
};

export const sendTextWithCommunicationPrefs = async (lookUrl: string, lookName: string) => {
  await setCommunicationPreferences(auth.user().id, {
    [MessageMedium.SMS]: {
      [MessageType.Updates]: true,
    },
  });

  await sendShareLink(lookUrl, lookName);
};

type InteractionResult = {
  type: 'success' | 'error';
  message: string;
};

const InteractionResultDisplay = (props: { result: InteractionResult }) => (
  <div className="flex flex-col items-center justify-start pb-32 md:pb-64">
    {props.result.type === 'success' ? (
      <IconCheck className="h-80 w-80 text-green" />
    ) : (
      <IconAlert className="h-80 w-80 text-red" />
    )}

    <p className="text-xs mt-16 text-center">{props.result.message}</p>
  </div>
);

const ShareModal = ({ shareData, onClose }: Props) => {
  const [isSendingLink, setIsSendingLink] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [interactionResult, setInteractionResult] = useState<InteractionResult | null>(null);

  const displayErrorMessage = () =>
    setInteractionResult({
      type: 'error',
      message: 'An error occurred, please try again.',
    });

  const handleTextToMyselfClick = async () => {
    try {
      if (!shareData) {
        throw new Error(`Can't send text message because shareData is undefined`);
      }

      sharedLookSmsBtnClickTrack(shareData);

      setIsSendingLink(true);

      const { url, lookName } = shareData;

      await sendTextWithCommunicationPrefs(url, lookName);

      setInteractionResult({
        type: 'success',
        message: 'Link texted to you.',
      });
    } catch (e) {
      console.error(e);
      displayErrorMessage();
    } finally {
      setIsSendingLink(false);
    }
  };

  const handleCopyLinkClick = async () => {
    try {
      const customer = await getCustomerData();

      sharedLookCopyLinkBtnClickTrack({
        phone: customer.phone,
        ...shareData,
      });

      setInteractionResult({
        type: 'success',
        message: 'Link copied to clipboard.',
      });
    } catch (err) {
      console.error(err);
      displayErrorMessage();
    }
  };

  useEffect(() => {
    if (interactionResult && !isClosing) {
      setIsClosing(true);

      setTimeout(() => {
        setIsClosing(false);
        onClose();
      }, 1200);
    }
  }, [interactionResult, isClosing, onClose]);

  return (
    <LookCardBaseModel onClose={onClose}>
      {interactionResult ? (
        <InteractionResultDisplay result={interactionResult} />
      ) : (
        <>
          <CopyToClipboard text={shareData?.url!}>
            <button
              className={`btn btn-info w-full uppercase ${
                isGentux() ? 'text-xs md:text-sm' : 'font-condensed text-base'
              }`}
              onClick={() => handleCopyLinkClick()}
            >
              <IconLink className="cursor-pointer" />
              Copy Link
            </button>
          </CopyToClipboard>

          <HorizontalRuleWithText text="or" className="text-sm items-center py-8 text-gray-dark" />

          <button
            className={`btn btn-info w-full uppercase ${
              isGentux() ? 'text-xs md:text-sm' : 'font-condensed text-base'
            }`}
            disabled={isSendingLink}
            onClick={() => handleTextToMyselfClick()}
          >
            <IconPhone style={{ cursor: 'pointer' }} />
            <span className="xl:hidden">Text Me</span>
            <span className="hidden xl:block">Text Me Link to Share</span>
          </button>

          <p className="mt-8 text-[10px] text-gray-dark">* Clicking this will opt you into SMS communication</p>
        </>
      )}
    </LookCardBaseModel>
  );
};

export default ShareModal;
