import { Item } from '../types';

export const SWATCH_SIZE = '71';

export const getImageUrl = (item: Item) => {
  if (item) {
    var imgixProps = '';
    if (item.category === 'Swatch (accessory)') {
      imgixProps = `&w=${SWATCH_SIZE}&fit=crop&crop=focalpoint&fp-z=2`;
    }

    return `${window.gt.lookBuilderAssetsBaseUrl}/${item.sku}-swatch.png?auto=format${imgixProps}`;
  }
  return 'https://gentux.imgix.net/no-image.png';
};

export const getImageAlt = (item: Item) => `${item.displayName} swatch`;
