import { Component } from 'react';
import { format } from 'date-fns';
import { Member, Item, FlowEventRouteProps } from '../../types';
import Flow from '../../utils/HOC/Flow';
import { IsEventMember } from '../../utils/HOC';
import { observer } from 'mobx-react';
import EventStore from '../../stores/EventStore';
import FormFLowLogo from '../../utils/Component/FormFlowLogo';
import { Link } from 'react-router-dom';
import IconArrowRight from '../../components/IconArrowRight';

import LookPreview from '../../look-builder/components/look-builder/preview/LookPreview';

import { getInvitedMemberDetails } from '../../services/Events';
import { url } from '../../utils/window';
import { objectToCamel } from '../../utils/utils';
import Loading from '../../utils/Component/Loading';

interface Props extends FlowEventRouteProps<{}> {}

interface State {
  member?: Member;
  items: Item[];
  loading?: Boolean;
}

class Completed extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      member: undefined,
      items: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const invitedMemberDetailsRes = await getInvitedMemberDetails(this.props.eventId);
      if (invitedMemberDetailsRes.status !== 200) {
        throw new Error('Unable to retrieve event member information');
      }

      const invitedMemberDetailsJson = await invitedMemberDetailsRes.json();
      const memberId = url.param(this.props.location.search, 'memberId')!;
      const member = invitedMemberDetailsJson.event.members.find((m: Member) => m.id === memberId);

      this.setState({
        member: member,
        items: member.role.bundles.concat(member.role.items).map((item: Item) => objectToCamel(item)),
        loading: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <>
        <FormFLowLogo />

        <div className="mx-auto my-64 space-y-16 sm:space-y-32 md:max-w-2xl">
          <div className="grid grid-cols-12 place-items-center p-32 sm:p-0">
            <div className="col-span-12 gap-2 md:col-span-8">
              <h1>
                <div className="text-h5 mb-12 text-gray-dark">
                  {format(EventStore.event!.startDate!, 'MMMM D, YYYY')} - {EventStore.event!.name}
                </div>
                <div className="text-h2-display mb-24">You&apos;re all set.</div>
              </h1>

              <p className="mb-24">
                Congratulations, you&apos;ve completed the process. You can kick back, relax, and wait for
                the&nbsp;party.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-12 p-32 sm:p-0">
            <div className="-md:content-center col-span-12 content-center gap-2 md:col-span-8">
              <LookPreview items={this.state.items} />
            </div>
            <div className="col-span-12 mx-auto md:col-span-4">
              <ul className="mt-0 md:mt-128">
                {this.state.items.map((item) => (
                  <li className="text-sm text-gray-dark">{item.displayName}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="grid grid-cols-12 place-items-center px-32 sm:px-0">
            <div className="col-span-12 gap-2 md:col-span-8">
              <p className="mb-32 mt-24">
                To review your order, visit the orders section in your account dashboard below.
              </p>

              <Link
                to={`/account/orders${this.props.location.search}`}
                className="tracker-cta-completed-review_orders-200619-111839 btn btn-info"
              >
                Review Your Orders <IconArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(IsEventMember(observer(Completed)));
