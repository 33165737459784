interface Layers {
  [key: string]: { name: string }[];
  'Bow Tie': { name: string }[];
  'Long Tie': { name: string }[];
  Belt: { name: string }[];
  Cummerbund: { name: string }[];
  Jacket: { name: string }[];
  Pants: { name: string }[];
  Shirt: { name: string }[];
  Shoes: { name: string }[];
  Suspenders: { name: string }[];
  Vest: { name: string }[];
  'Pocket Square': { name: string }[];
  Socks: { name: string }[];
  'Lapel Pin': { name: string }[];
  Cufflinks: { name: string }[];
  'Tie Bar': { name: string }[];
}

interface Shadows {
  [key: string]: string[];
  'Bow Tie': string[];
  'Long Tie': string[];
  'Pocket Square': string[];
  'Lapel Pin': string[];
  Jacket: string[];
  Shirt: string[];
  Vest: string[];
  Cummerbund: string[];
  Socks: string[];
}

interface Hanger {
  [key: string]: string[];
  Pants: string[];
  Socks: string[];
}

interface ThumbnailCrop {
  [key: string]: { value: string }[];
  'Bow Tie': { value: string }[];
  'Long Tie': { value: string }[];
  Belt: { value: string }[];
  Jacket: { value: string }[];
  Shirt: { value: string }[];
  Shoes: { value: string }[];
  Suspenders: { value: string }[];
  Vest: { value: string }[];
  Cummerbund: { value: string }[];
  'Pocket Square': { value: string }[];
  Socks: { value: string }[];
  'Lapel Pin': { value: string }[];
  Cufflinks: { value: string }[];
  'Tie Bar': { value: string }[];
}

export const layers: Layers = {
  'Bow Tie': [{ name: '_no_strap' }],
  'Lapel Pin': [{ name: '' }],
  'Long Tie': [{ name: '_full' }],
  'Pocket Square': [{ name: '' }],
  'Tie Bar': [{ name: '' }],
  Belt: [{ name: '' }],
  Cufflinks: [{ name: '' }],
  Cummerbund: [{ name: '' }],
  Jacket: [{ name: '_front' }, { name: '_full' }],
  Pants: [{ name: '' }],
  Shirt: [{ name: '_collar' }, { name: '_full' }, { name: '_tail' }],
  Shoes: [{ name: '' }],
  Socks: [{ name: '' }],
  Suspenders: [{ name: '' }],
  Vest: [{ name: '_full' }, { name: '_front' }],
};

export const shadows: Shadows = {
  'Bow Tie': ['shadow-tie_no_strap_shadows.png'],
  'Long Tie': ['shadow-tie_full_shadows.png'],
  'Pocket Square': ['shadow-pocket_square_shadows.png'],
  'Lapel Pin': ['shadow-lapel_pin_shadows.png'],
  Jacket: ['shadow-jacket_shadows.png'],
  Shirt: ['shadow-shirt_point_shadows.png', 'shadow-shirt_spread_shadows.png', 'shadow-shirt_wingtip_shadows.png'],
  Vest: ['shadow-vest_shadows.png'],
  Cummerbund: ['shadow-cummerbund_shadows.png'],
  Socks: ['shadow-socks_shadows.png'],
};

export const hanger: Hanger = {
  Pants: ['hanger-hanger.png'],
  Socks: ['hanger-hanger.png'],
};

export const thumbnailCrop: ThumbnailCrop = {
  Jacket: [{ value: 'fp-x=.5&fp-y=0&fp-z=1' }],
  Shirt: [{ value: 'fp-x=.5&fp-y=0&fp-z=1' }],
  'Long Tie': [{ value: 'fp-x=.5&fp-y=0.15&fp-z=2' }],
  'Tie Bar': [{ value: 'fp-x=.5&fp-y=0.15&fp-z=2' }],
  'Bow Tie': [{ value: 'fp-x=.5&fp-y=0.1&fp-z=3.5' }],
  Vest: [{ value: 'fp-x=.5&fp-y=.2&fp-z=1' }],
  Cummerbund: [{ value: 'fp-x=.5&fp-y=.2&fp-z=1' }],
  'Pocket Square': [{ value: 'fp-x=.65&fp-y=.24&fp-z=5' }],
  'Lapel Pin': [{ value: 'fp-x=.62&fp-y=.155&fp-z=14' }],
  Cufflinks: [{ value: 'fp-x=.84&fp-y=.89&fp-z=6' }],
  Belt: [{ value: 'fp-x=.59&fp-y=.87&fp-z=3' }],
  Suspenders: [{ value: 'fp-x=.59&fp-y=.87&fp-z=4' }],
  Shoes: [{ value: 'fp-x=.25&fp-y=.87&fp-z=2' }],
  Socks: [{ value: 'fp-x=.14&fp-y=.22&fp-z=3' }],
};
