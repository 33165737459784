import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import OwnerFormStore from '../../stores/OwnerFormStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { formatPhone, validatePhoneArea } from '../../utils/utils';
import Flow from '../../utils/HOC/Flow';
import { FlowEventRouteProps } from '../../types';

interface Props extends FlowEventRouteProps<{}> {}
interface State {
  phone: string;
  error: string | undefined;
}
class OwnerPhone extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phone: OwnerFormStore.phone || '',
      error: undefined,
    };
  }

  handleChange = (field: keyof State, value: string | boolean) => {
    this.setState((state) => ({
      ...state,
      [field]: value,
      error: undefined,
    }));
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validatePhoneArea(this.state.phone)) {
      OwnerFormStore.update('phone', this.state.phone!);
      this.nextPage();
    } else {
      this.setState({
        error: 'Area codes cannot begin with "1"',
      });
    }
  };

  nextPage() {
    this.props.flow!(this.props.location.search);
  }

  render() {
    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          error={this.state.error}
          inputMode="tel"
          key="member-phone"
          label="Phone"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange('phone', formatPhone(e.target.value))}
          onSubmit={(e) => this.handleSubmit(e)}
          property="phone"
          required={true}
          requiredLength={14}
          title={`What is their phone number?`}
          type="tel"
          value={this.state.phone}
        />
      </>
    );
  }
}

export default Flow(observer(OwnerPhone));
