import { observable, action, computed } from 'mobx';
import { InvitedMemberForm } from '../types';

class InvitedMemberFormStore {
  @observable firstName: InvitedMemberForm['firstName'] = '';
  @observable lastName: InvitedMemberForm['lastName'] = '';
  @observable phone: InvitedMemberForm['phone'] = '';
  @observable email: InvitedMemberForm['email'] = '';
  @observable password: InvitedMemberForm['password'] = '';
  @observable potentialMemberId: InvitedMemberForm['potentialMemberId'] = '';
  @observable shortCode: InvitedMemberForm['shortCode'] = '';
  @observable smsOptIn: InvitedMemberForm['smsOptIn'] = true;
  @observable emailOptIn: InvitedMemberForm['emailOptIn'] = true;

  @computed get member(): InvitedMemberForm {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      email: this.email,
      password: this.password,
      potentialMemberId: this.potentialMemberId,
      shortCode: this.shortCode,
      smsOptIn: this.smsOptIn,
      emailOptIn: this.emailOptIn,
    };
  }

  @action update = <K extends keyof InvitedMemberForm>(field: K, value: InvitedMemberForm[K]): void => {
    this[field] = value as any;
  };

  @action reset = () => {
    this.firstName = undefined;
    this.lastName = undefined;
    this.phone = undefined;
    this.email = undefined;
    this.password = undefined;
    this.potentialMemberId = undefined;
    this.shortCode = undefined;
    this.smsOptIn = undefined;
    this.emailOptIn = undefined;
  };
}

const singleton = new InvitedMemberFormStore();
export default singleton;
