import { type SVG } from 'types';

const IconGraduationCap = ({ className, ...props }: SVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="28"
      viewBox="0 0 48 28"
      fill="#404042"
      className={`${className ?? ''}`}
      {...props}
    >
      <title>Graduation Cap</title>
      <g clipPath="url(#clip0_4872_191)">
        <path d="M23.5631 19.7052C23.4923 19.7052 23.4199 19.6921 23.3524 19.6657L0.365384 10.6352C0.146491 10.5496 0.00166001 10.339 1.42008e-05 10.1036C-0.00163161 9.86825 0.139908 9.65594 0.357155 9.56542L23.5071 0.0427752C23.6453 -0.0148281 23.8017 -0.0148281 23.9416 0.0427752C27.7516 1.57831 31.8448 3.34591 35.8029 5.05591C39.7562 6.76261 43.8427 8.52856 47.6396 10.0592C47.8568 10.1464 48 10.3571 48 10.5924C48 10.8278 47.8585 11.0384 47.6412 11.1273C43.7933 12.6941 39.6656 14.0749 35.6762 15.4097C31.7032 16.7395 27.5936 18.1138 23.7803 19.6657C23.7112 19.6937 23.6371 19.7085 23.5631 19.7085V19.7052ZM2.11982 10.0871L23.5598 18.5087C27.3468 16.9781 31.3938 15.6236 35.3108 14.3136C38.8279 13.1368 42.4504 11.9255 45.8819 10.5858C42.4569 9.1836 38.8493 7.62501 35.3454 6.11087C31.4695 4.43708 27.4636 2.70569 23.7276 1.19649L2.11982 10.0871Z" />
        <path d="M23.9009 27.4949C19.1445 27.4949 12.9184 26.0877 10.95 22.1131C10.9286 22.0703 10.9122 22.0242 10.9023 21.9765C10.4003 19.618 10.4069 17.2941 10.9187 15.7619C11.0191 15.4607 11.3466 15.2978 11.6478 15.3982C11.949 15.4986 12.1119 15.8261 12.0116 16.1273C11.5721 17.4373 11.5738 19.5538 12.0132 21.6638C13.6755 24.8961 19.1593 26.3411 23.8976 26.3411H23.9437C29.625 26.3296 34.3715 24.4551 35.7968 21.6671C36.2049 19.6246 36.2066 17.5295 35.8001 16.3132C35.6997 16.0121 35.8626 15.6845 36.1638 15.5841C36.4666 15.4838 36.7925 15.6467 36.8929 15.9479C37.3702 17.3797 37.3784 19.6871 36.911 21.9715C36.9011 22.0193 36.8847 22.0653 36.8633 22.1098C34.9228 26.0679 28.6918 27.4833 23.9453 27.4932H23.8976L23.9009 27.4949Z" />
        <path d="M33.7231 13.5548C33.4894 13.5548 33.2688 13.41 33.1833 13.178C33.073 12.8801 33.2261 12.5476 33.5239 12.4373C34.6892 12.0078 35.81 11.6111 36.8946 11.2277C37.8179 10.9002 38.7642 10.5661 39.7566 10.204C39.5015 10.0838 39.2448 9.96205 38.9913 9.8419C38.1931 9.46337 37.3669 9.07166 36.6378 8.76554C36.3449 8.64211 36.2066 8.30472 36.3301 8.01176C36.4535 7.71881 36.7909 7.58056 37.0838 7.704C37.836 8.01999 38.6737 8.41828 39.4834 8.80175C40.1598 9.12268 40.861 9.45514 41.4962 9.73163C41.7118 9.82544 41.8484 10.041 41.8419 10.2748C41.8353 10.5085 41.6872 10.7158 41.4683 10.7981C39.9772 11.3577 38.6046 11.8432 37.278 12.3123C36.1984 12.6941 35.0809 13.0907 33.9222 13.517C33.8564 13.5417 33.7889 13.5532 33.7231 13.5532V13.5548Z" />
        <path d="M31.4082 14.3975C31.7345 14.3975 31.9991 14.133 31.9991 13.8067C31.9991 13.4804 31.7345 13.2158 31.4082 13.2158C31.0819 13.2158 30.8174 13.4804 30.8174 13.8067C30.8174 14.133 31.0819 14.3975 31.4082 14.3975Z" />
        <path d="M6.54088 27.4768C6.22324 27.4768 5.96484 27.2184 5.96484 26.9008V14.1457C5.96484 13.8281 6.22324 13.5697 6.54088 13.5697C6.85852 13.5697 7.11691 13.8281 7.11691 14.1457V26.9008C7.11691 27.2184 6.85852 27.4768 6.54088 27.4768Z" />
        <path d="M6.5043 22.8093C6.22616 22.8093 5.94966 22.7533 5.76698 22.6249C5.53492 22.4636 5.42629 22.197 5.48225 21.9288C5.48554 21.9156 5.76533 20.6648 6.53228 20.6483C6.53228 20.6483 6.5438 20.6483 6.54874 20.6483C7.37987 20.6483 7.60534 21.9551 7.60699 21.9699C7.64649 22.2299 7.5247 22.4817 7.28935 22.6299C7.10666 22.7434 6.80548 22.8109 6.50594 22.8109L6.5043 22.8093ZM6.53557 21.8004C6.53557 21.8004 6.53886 21.8004 6.54051 21.8004C6.54051 21.8004 6.53886 21.7971 6.53721 21.7954C6.53721 21.7954 6.53557 21.7987 6.53392 21.7987L6.53557 21.8004Z" />
      </g>
      <defs>
        <clipPath id="clip0_4872_191">
          <rect width="48" height="27.4949" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconGraduationCap;
