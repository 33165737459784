import React from 'react';

import { observer } from 'mobx-react';
import {
  makeColorFilters,
  makePatternFilters,
  makeCategoryFilters,
  mapFilterName,
  getOrderedProductColors,
} from '../../../utils/utils';
import GalleryStore from '../../../../stores/look-builder/GalleryStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import ItemStore from '../../../../stores/ItemStore';
import Filter from '../../../../shared/components/Filter';
import { Disclosure } from '@headlessui/react';
import AnimateHeight from '../../../../components/AnimateHeight';
import IconTriangleDown from '../../../../components/IconTriangleDown';
import screens from '../../../../tailwind/screens.json';
import { Screens } from '../../../../types';

const FilterWrapStyle = (styleClass: string, itemCount: number): string => `
    .${styleClass} {
      display: grid;
      grid-template-rows: repeat(${Math.ceil(itemCount / 2)}, auto);
      grid-template-columns: repeat(${itemCount < 3 ? 1 : 2}, 1fr);
      grid-auto-flow: ${itemCount < 3 ? 'row' : 'column'};
      grid-row-gap: 4px;
    }
  @media (min-width: ${(screens as unknown as Screens).md}) {
    .${styleClass} {
    grid-template-rows: repeat(${Math.ceil(itemCount / 3)}, auto);
    grid-template-columns: repeat(${itemCount < 3 ? 1 : 3}, 1fr);
    }
  }
  @media (min-width: ${(screens as unknown as Screens).xl}) {
    .${styleClass} {
    grid-column-gap: 16px;
    grid-template-rows: repeat(7, auto);
    grid-template-columns: repeat(${itemCount < 8 ? 1 : 2}, 1fr);
    }
  }
  `;

interface FilterWrapProps extends React.HTMLProps<HTMLDivElement> {
  styleClass: string;
  itemCount: number;
}

const FilterWrap: React.FC<FilterWrapProps> = ({ children, styleClass, itemCount }) => (
  <>
    <style>{FilterWrapStyle(styleClass, itemCount)}</style>
    <div className={styleClass}>{children}</div>
  </>
);

interface Props {
  isBundle?: boolean;
}

const GalleryFilter = (props: Props) => {
  const sidecarCategory = SideCarStore.category;
  const colorFilters = getOrderedProductColors(
    makeColorFilters(sidecarCategory!, props.isBundle, ItemStore.cachedProducts, ItemStore.cachedBundles)
  );
  const patternFilters = makePatternFilters(sidecarCategory!, props.isBundle, ItemStore.cachedProducts);
  const categoryFilters = makeCategoryFilters(sidecarCategory!, props.isBundle, ItemStore.cachedProducts);

  const getSelectedFilterCount = () =>
    GalleryStore.getFilters('colors').length +
    GalleryStore.getFilters('patterns').length +
    GalleryStore.getFilters('categories').length;

  const selectedFilterCount = getSelectedFilterCount();

  return (
    <Disclosure as="div" className="bg-white">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`relative flex h-full w-full cursor-pointer items-center pr-32 transition-all hover:bg-gray-lighter active:bg-gray-lighter`}
            onClick={() => GalleryStore.setIsFilterOpen(!GalleryStore.isFilterOpen)}
          >
            <div className="block p-16">
              <h6 className="text-h6 flex items-center gap-8">
                Filters{' '}
                {selectedFilterCount > 0 && (
                  <span className="text-xs grid h-16 w-16 place-items-center rounded-full bg-brand font-sans leading-none ">
                    {selectedFilterCount}
                  </span>
                )}
              </h6>
            </div>
            <div>
              <div className={`absolute right-16 top-1/2 flex -translate-y-1/2 transform`}>
                <IconTriangleDown className={`transition ${open ? '' : 'rotate-90'}`} />
              </div>
            </div>
          </Disclosure.Button>
          <AnimateHeight open={open}>
            <Disclosure.Panel static className="flex flex-col gap-16 p-16 pt-8 xl:flex-row">
              {categoryFilters && categoryFilters.length > 1 && (
                <div className="xl:min-w-[25%]">
                  <h3 className="text-h6 text-gray-dark">Category</h3>
                  <hr className="my-8 text-gray-lighter sm:my-8" />
                  <FilterWrap styleClass="category-filter" itemCount={categoryFilters.length}>
                    {categoryFilters.map((filter: string) => (
                      <Filter
                        id={`filter-${filter}`}
                        key={filter}
                        isActive={GalleryStore.isActiveFilter('categories', filter)}
                        filterLabel={mapFilterName(filter)}
                        className={`tracker-filter-look_builder-${sidecarCategory!.replace(
                          /-/g,
                          '_'
                        )}-category_filter-20200508-111100`}
                        onChange={() => {
                          if (GalleryStore.isActiveFilter('categories', filter)) {
                            return GalleryStore.removeFilters('categories', filter);
                          }
                          GalleryStore.setFilters('categories', filter);
                        }}
                      />
                    ))}
                  </FilterWrap>
                </div>
              )}
              {patternFilters && patternFilters.length > 1 && (
                <div className="xl:min-w-[25%]">
                  <h3 className="text-h6 text-gray-dark">Patterns</h3>
                  <hr className="my-8 text-gray-lighter sm:my-8" />
                  <FilterWrap styleClass="pattern-filter" itemCount={patternFilters.length}>
                    {patternFilters.map((filter: string) => (
                      <Filter
                        id={`filter-${filter}`}
                        key={filter}
                        isActive={GalleryStore.isActiveFilter('patterns', filter)}
                        filterLabel={mapFilterName(filter)}
                        className={`tracker-filter-look_builder-${sidecarCategory!.replace(
                          /-/g,
                          '_'
                        )}-pattern_filter-20200508-111100`}
                        onChange={() => {
                          if (GalleryStore.isActiveFilter('patterns', filter)) {
                            return GalleryStore.removeFilters('patterns', filter);
                          }
                          GalleryStore.setFilters('patterns', filter);
                        }}
                      />
                    ))}
                  </FilterWrap>
                </div>
              )}
              {colorFilters.length && colorFilters.length > 1 && (
                <div className="flex flex-col xl:min-w-[25%]">
                  <h3 className="text-h6 text-gray-dark">Colors</h3>
                  <hr className="my-8 text-gray-lighter sm:my-8" />
                  <FilterWrap styleClass="color-filter" itemCount={colorFilters.length}>
                    {colorFilters.map((filter: string) => (
                      <Filter
                        id={`filter-${filter}`}
                        key={filter}
                        isActive={GalleryStore.isActiveFilter('colors', filter)}
                        filterColor={filter}
                        filterLabel={mapFilterName(filter)}
                        className={`tracker-filter-look_builder-${sidecarCategory!.replace(
                          /-/g,
                          '_'
                        )}-color_filter-20200508-111100`}
                        onChange={() => {
                          if (GalleryStore.isActiveFilter('colors', filter)) {
                            return GalleryStore.removeFilters('colors', filter);
                          }
                          GalleryStore.setFilters('colors', filter);
                        }}
                      />
                    ))}
                  </FilterWrap>
                </div>
              )}
            </Disclosure.Panel>
          </AnimateHeight>
        </>
      )}
    </Disclosure>
  );
};

export default observer(GalleryFilter);
