import { observer } from 'mobx-react';

import { useLookBuilderState } from './context';
import PreviewStore from '../../../stores/look-builder/PreviewStore';
import { formatCurrency } from '../../../utils/utils';

type Props = {
  displayLookCost?: boolean;
};

const TitleBar = (props: Props) => {
  const { htoFlow } = useLookBuilderState();
  const title = htoFlow ? 'Home Try-On' : PreviewStore.lookName;

  return (
    <div className="flex items-center">
      <h2 className="text-h3">{title}</h2>
      {props?.displayLookCost && (
        <>
          <span className="LookBuilder__price1 mx-16">-</span>
          <span className="LookBuilder__price1 text-sm text-gray-darker">{formatCurrency(PreviewStore.getCost())}</span>
        </>
      )}
    </div>
  );
};

export default observer(TitleBar);
