import type { Look, Member, GTEvent } from '../types';
import { numericStringSort } from './utils';

export const lookHasItems = (look: Look) => {
  const products = look.items ?? [];
  const bundles = look.bundles ?? [];

  return products.length > 0 || bundles.length > 0;
};

export const lookIsDeletable = (look: Look) => look.id && !lookHasItems(look) && look.members?.length === 0;

export const sortLooks = (lookA: Look, lookB: Look) => {
  if (lookA.name!.match(/\d+/) === null) {
    return 1;
  }

  if (lookB.name!.match(/\d+/) === null) {
    return -1;
  }
  return numericStringSort(lookA.name!, lookB.name!);
};

export const lookIsEmpty = (look: Look) => look && look.items!.length === 0 && look.bundles!.length === 0;

export const lookIsLocked = (look: Look | undefined, members: Member[], customerId: string): boolean => {
  if (members && members.length > 0) {
    const member: Member | undefined = members!.find((member: Member) => member.accountId === customerId);
    if (member) {
      const customerIsOwner = member.isOwner;
      // If the current customer isn't an owner, the look is locked.
      if (!customerIsOwner) {
        return true;
      }
    }
  }
  // If the look has no members the look is not locked because they can't have paid.
  if (look!.members!.length === 0) {
    return false;
  }
  return look!.members!.filter((member: Member) => member.isPaid).length > 0;
};

export const eventHasOnlyEmptyLooks = (event: GTEvent): boolean | undefined =>
  event && event.roles && event.roles.filter((r) => !lookIsEmpty(r)).length === 0;
