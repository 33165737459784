import React from 'react';
import RouteMiddleware, { RouteMiddlewareProps } from './RouteMiddleware';
import { RouteComponentProps } from 'react-router';
import Loading from '../utils/Component/Loading';
import InvitedMemberFormStore from '../stores/InvitedMemberFormStore';

const GATED_INVITED_MEMBER_PATHS = ['/invited/signup/phone', '/invited/signup/first-name', '/invited/signup/last-name'];

const InvitedRouteMiddleware: React.FC<RouteMiddlewareProps> = ({ component, render, ...rest }) => {
  const renderWrapped = (props: RouteComponentProps<any>): React.ReactNode => {
    const isFromInvitedMemberFLow = InvitedMemberFormStore.potentialMemberId !== '';
    if (GATED_INVITED_MEMBER_PATHS.includes(props.location.pathname) && !isFromInvitedMemberFLow) {
      window.location.href = process.env.REACT_APP_ECOMM_URL || '/';
      return <Loading />;
    }

    return render ? render(props) : component && React.createElement(component, props);
  };

  return <RouteMiddleware {...rest} render={renderWrapped} component={component} />;
};

export default InvitedRouteMiddleware;
