import IsGentux from '../../components/IsGentux';
import IsMenguin from '../../components/IsMenguin';
import LogoGentux from '../../components/LogoGentux';
import LogoMenguin from '../../components/LogoMenguin';

type FormFlowLogoProps = ClassNameProp & DataTestIdProp;

const FormFlowLogo = ({ className = '', ...props }: FormFlowLogoProps) => (
  <div className={`my-32 flex justify-center ${className}`} data-testid={props['data-testid'] ?? 'form-flow-logo'}>
    <IsGentux>
      <LogoGentux />
    </IsGentux>

    <IsMenguin>
      <LogoMenguin />
    </IsMenguin>
  </div>
);

export default FormFlowLogo;
