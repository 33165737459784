import React from 'react';
import PropTypes from 'prop-types';
import Line from '../../../../components/Line';
import Message from '../../../../components/Message';
import FormSelect from '../../../../components/FormSelect';

function getImageUrl(measurement: string) {
  let imgUrl = '';
  switch (measurement) {
    case 'neck':
      imgUrl = 'https://gentux.imgix.net/1557939427_tape-neck.gif';
      break;
    case 'overarm':
      imgUrl = 'https://gentux.imgix.net/1557939426_tape-overarm.gif';
      break;
    case 'chest':
      imgUrl = 'https://gentux.imgix.net/1557939427_tape-chest.gif';
      break;
    case 'armLength':
      imgUrl = 'https://gentux.imgix.net/1557939427_tape-armLength.gif';
      break;
    case 'stomach':
      imgUrl = 'https://gentux.imgix.net/1557939426_tape-stomach.gif';
      break;
    case 'waist':
      imgUrl = 'https://gentux.imgix.net/1557939426_tape-waist.gif';
      break;
    case 'outseam':
      imgUrl = 'https://gentux.imgix.net/1557939427_tape-outseam.gif';
      break;
    default:
      imgUrl = 'https://gentux.imgix.net/1557939427_tape-outseam.gif';
  }
  return imgUrl;
}

interface Props {
  title?: string;
  measurement: string;
  currentValue: string;
  handleChange: (key: string, e: React.ChangeEvent<HTMLFormElement> | string) => void;
  setActiveMeasurement: (value: string) => void;
  nextMeasurment: string;
  activeMeasurement: string;
  options: string[];
  error: string;
  warning: string;
  children: Element[] | JSX.Element[];
  buttonId: string;
}

const MeasurementCard: React.StatelessComponent<Props> = ({
  title,
  measurement,
  currentValue,
  handleChange,
  setActiveMeasurement,
  nextMeasurment,
  activeMeasurement,
  options,
  error,
  warning,
  children,
  buttonId,
}) => {
  const displayClass = activeMeasurement !== measurement ? 'hidden' : 'block';

  return (
    <div className={displayClass}>
      <h3 className="text-h3 mb-4 mt-32">Measurement</h3>
      <div className="relative mb-32 mt-8 bg-white p-16 shadow-2xl">
        <FormSelect
          combineLabel
          label={measurement.toUpperCase()}
          id={measurement}
          value={currentValue}
          onChange={(e: React.ChangeEvent<HTMLLabelElement & HTMLSelectElement>) =>
            handleChange(measurement, e.target.value)
          }
          className="mb-16 w-full grow"
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
              {option ? '"' : ''}
            </option>
          ))}
        </FormSelect>
        <div className="flex justify-end">
          <button
            className="tracker-cta-measurement_card-next-200619-111519 btn btn-info"
            id={buttonId}
            onClick={() => {
              setActiveMeasurement(nextMeasurment);
            }}
          >
            Next
          </button>
        </div>
        {error && <Message type="error" message={error} className="mt-16" />}
        {warning && <Message type="info" message={warning} className="mt-16" />}
      </div>
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-4 ">
          <img loading="lazy" src={`${getImageUrl(measurement)}?a=${Math.random()}`} alt={measurement} />
        </div>
        <div className="col-span-8 ">
          <h3 className="text-h3-display capitalize">{title || measurement}</h3>
          <Line />
          {children}
        </div>
      </div>
    </div>
  );
};

MeasurementCard.propTypes = {
  title: PropTypes.string,
  measurement: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  setActiveMeasurement: PropTypes.func.isRequired,
  nextMeasurment: PropTypes.string.isRequired,
  activeMeasurement: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  warning: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
};

MeasurementCard.defaultProps = {
  title: '',
};

export default MeasurementCard;
