import React from 'react';

interface IsMenguinProps {}

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & IsMenguinProps>;

const IsMenguin: Props = ({ children }) => {
  if (Number(process.env.REACT_APP_ORGANIZATION_ID) === 2) {
    return <>{children}</>;
  }

  return null;
};

export default IsMenguin;
