import React from 'react';
import Dots from './Dots';

interface DottedRowProps {
  title: string;
  titleDesc?: string;
  value: string;
}

const DottedRow: React.FC<React.HTMLProps<HTMLDivElement> & DottedRowProps> = ({
  className,
  title,
  titleDesc = undefined,
  value,
  ...props
}) => (
  <div {...props} className={`flex items-baseline justify-between ${className ? className : ''}`}>
    <h3 className="text-h5 shrink-0" title={titleDesc}>
      {title}
    </h3>

    <Dots />

    <p>{value}</p>
  </div>
);

export default DottedRow;
