import React, { Component } from 'react';

// local components
import Loading from '../../utils/Component/Loading';
import MemberStore from '../../stores/MemberStore';
import Message from '../../components/Message';
import FormRadio from '../../components/FormRadio';
import FormRadioItem from '../../components/FormRadioItem';
import FormNavigation from '../../utils/Component/FormNavigation';

// types
import { FitActions, FitOutfitPreferences, FlowRouteProps } from '../../types';

// utils
import Flow from '../../utils/HOC/Flow';

// interfaces
interface State {
  fitPreference: string | undefined;
}

interface Props extends FlowRouteProps<any> {
  reducer: (action: FitActions, changes: any) => void;
  preference: FitOutfitPreferences;
  age: string;
  submitting: boolean;
  error: string | undefined;
  submit: () => void;
}

class FitPreference extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fitPreference: this.props.preference,
    };
  }

  setFitPreference = (fitPreference: string) => {
    this.setState({ fitPreference: fitPreference });

    const { reducer } = this.props;
    reducer('PREFERENCE_SET', { preference: fitPreference });
  };

  onSubmit = async () => {
    let outflowIndex = parseInt(this.props.age, 10) <= 16 ? 1 : 0;

    if (outflowIndex === 0) {
      await this.props.submit();
    }

    if (this.props.history.location.pathname.includes('/fit-flow/invited')) {
      outflowIndex = outflowIndex === 1 ? 2 : MemberStore.getSignedInMember()!.isPaid ? 0 : 1;
    }

    if (!this.props.error) {
      return this.props.flow!(`${this.props.location.search}`, outflowIndex);
    }
  };

  render() {
    if (this.props.submitting) {
      return <Loading />;
    }

    return (
      <div data-testid="fitflow-preference" className="mx-auto max-w-sm">
        <h2 className="text-h2-display mb-32 text-center">How do you prefer your suit to fit?</h2>

        <FormRadio label={'Fit Preference'} darkMode className="w-full">
          <FormRadioItem
            label={'Modern'}
            description={'Standard fit, flatters most body\u00a0types.'}
            checked={this.state.fitPreference !== undefined && this.state.fitPreference === 'modern'}
            onChange={() => this.setFitPreference('modern')}
            className={`tracker-button-yes_no-Modern-200619-111519`}
          />
          <FormRadioItem
            label={'Slim'}
            description={'Close-fitting, shorter coat, and tapered pant\u00a0legs.'}
            checked={this.state.fitPreference !== undefined && this.state.fitPreference === 'slim'}
            onChange={() => this.setFitPreference('slim')}
            className={`tracker-button-yes_no-Slim-200619-111519`}
          />
        </FormRadio>

        {this.props.error && <Message className="my-16" type="error" message={this.props.error} />}
        <FormNavigation next={this.onSubmit} className="mt-32" />
      </div>
    );
  }
}

export default Flow(FitPreference);
