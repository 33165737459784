import { convertObjectToFormData, isInArray } from '../utils/utils';
import { Item, Product, Bundle } from '../types';

interface GetProductsQuery {
  [key: string]: string | number | boolean | undefined;
  category?: string;
  with?: string;
  displayable?: boolean;
}

interface GetCategoryOrder {
  [key: string]: number;
  preconfigured: number;
  Shirt: number;
  Tie: number;
  Vest: number;
  Cummerbund: number;
  Shoe: number;
  'Pocket Square': number;
  Socks: number;
  Belt: number;
  Suspenders: number;
  'Lapel Pin': number;
  Cufflinks: number;
  'Tie Bar': number;
}

const menguinTuxes: Array<string> = [
  'The Shanghai',
  'The Manhattan',
  'The Dublin',
  'The Hollywood',
  'The Amsterdam',
  'The London',
  'The Monte Carlo',
  'The Savannah',
];
const menguinSuits: Array<string> = [
  'The Lyon',
  'The Prague',
  'The Bali',
  'The Brooklyn',
  'The Zurich',
  'The Copenhagen',
  'The Oban',
  'The Ginza',
  'The Chicago',
  'The Kyoto',
  'The Macau',
  'The Havana',
];

const Products = {
  isMenguinTux: (str: string): boolean => menguinTuxes.indexOf(str) !== -1,

  isMenguinSuit: (str: string): boolean => menguinSuits.indexOf(str) !== -1,

  categories: [
    {
      name: 'preconfigured',
      displayName: 'Tuxedos & Suits',
      filters: ['Tux', 'Suit'],
      cost: 129,
      products: [],
      visualizer: true,
      slug: 'tuxedos-and-suits',
    },
    {
      name: 'Shirt',
      displayName: 'Shirts',
      filters: ['Twill', 'Pleated'],
      cost: 20,
      products: [],
      visualizer: true,
      slug: 'shirts',
    },
    {
      name: 'Tie',
      displayName: 'Ties',
      filters: ['Long', 'Bow', 'Skinny', 'Pre-Tied'],
      cost: 10,
      products: [],
      visualizer: true,
      slug: 'ties',
    },
    {
      name: 'Vest,Cummerbund',
      displayName: 'Vests & Cummerbunds',
      filters: ['Vest', 'Cummerbund'],
      cost: 10,
      products: [],
      visualizer: true,
      slug: 'vests-and-cummerbunds',
    },
    {
      name: 'Shoe',
      displayName: 'Shoes',
      filters: [],
      cost: 20,
      products: [],
      visualizer: true,
      slug: 'shoes',
    },
    {
      name: 'Pocket Square',
      displayName: 'Pocket Squares',
      filters: [],
      cost: 10,
      products: [],
      visualizer: true,
      slug: 'pocket squares',
    },
    {
      name: 'Socks',
      displayName: 'Socks',
      filters: [],
      cost: 10,
      products: [],
      visualizer: true,
      slug: 'socks',
    },
    {
      name: 'Belt,Suspenders',
      displayName: 'Belts & Suspenders',
      filters: ['Belt', 'Suspenders'],
      cost: 10,
      products: [],
      visualizer: true,
      slug: 'belts & suspenders',
    },
    {
      name: 'Lapel Pin',
      displayName: 'Lapel Pins',
      filters: ['Rose', 'Carnation'],
      cost: 10,
      products: [],
      visualizer: true,
      slug: 'lapel pins',
    },
    {
      name: 'Cufflinks',
      displayName: 'Cufflinks',
      filters: [],
      cost: 25,
      products: [],
      visualizer: true,
      slug: 'cufflinks',
    },
    {
      name: 'Tie Bar',
      displayName: 'Tie Bars',
      filters: [],
      cost: 25,
      products: [],
      visualizer: true,
      slug: 'tie bars',
    },
  ],

  getCategoryOrder: {
    preconfigured: 0,
    Shirt: 1,
    Tie: 2,
    Vest: 3,
    Cummerbund: 3,
    Shoe: 4,
    'Pocket Square': 5,
    Socks: 6,
    Belt: 7,
    Suspenders: 7,
    'Lapel Pin': 8,
    Cufflinks: 9,
    'Tie Bar': 10,
  } as GetCategoryOrder,

  getProducts: async function(productsQuery: GetProductsQuery) {
    return fetch('/api/get-products', {
      method: 'POST',
      credentials: 'same-origin',
      body: convertObjectToFormData(productsQuery),
    });
  },

  getProductsV2: async function() {
    return fetch('/api/v3/get-products', {
      method: 'POST',
      credentials: 'same-origin',
    });
  },

  /**
   * Get bundles
   * @param bundlesQuery
   * @returns {*}
   */
  getBundles: async function() {
    return fetch('/api/v3/get-bundles', {
      method: 'POST',
      credentials: 'same-origin',
    });
  },

  /**
   *
   * @param items Array<Item>
   * @param categories string eg: 'preconfigured,shirt'
   */
  getItemsFromLookByCategory(items: (Product | Bundle)[], categories: string) {
    const arrayOfCategories: Array<string> = categories.split(',');
    return items.filter(item => isInArray(item.category, arrayOfCategories));
  },

  /**
   *
   * @param items Array<Item>
   * @param categories string eg: 'preconfigured,shirt'
   */
  getItemFromLookByCategory(items: Array<Item>, categories: string): Item | undefined {
    const arrayOfCategories: Array<string> = categories.split(',');
    return items.find(item => isInArray(item.category, arrayOfCategories));
  },
};

export default Products;
