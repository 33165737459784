import { Customer, Address, JsonResponse } from '../types';
import { GraphQLResponse } from '../utils/GraphQL';
import { camelToSnake } from '../utils/utils';
import fetchMiddleware from '../utils/fetchMiddleware';
import { accountsUrl, omniUrl } from './urls';
import { CommunicationPreferencesByMedium, IsSubscribedByMessageType } from '../utils/CommunicationPreferences';
import { ManualMeasurements } from '../utils/manualMeasurements';
import auth from './Auth';

const convertObjectToFormData = (obj: { [key: string]: any }, snake: boolean = false): FormData => {
  let formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      formData.append(snake ? camelToSnake(key) : key, obj[key]);
    }
  });
  return formData;
};

function customerToUpdateFormData(customer: Customer) {
  return {
    id: customer.id || window.gt.user.id,
    email: customer.email || window.gt.user.email,
    firstName: customer.firstName!,
    lastName: customer.lastName!,
    phone: customer.phone!,
    sms_opt_in: customer.smsOptIn,
    emailOptIn: customer.emailOptIn,
    state: customer.state,
    ...(customer.primaryEventId && { primaryEventId: customer.primaryEventId }),
  };
}

export const updateInvitedMember = async (id: string, password: string): Promise<Response> => {
  return fetch(`/api/sign-up-invited-member`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      customerId: id,
      password,
    }),
  });
};

export async function updateCustomer(customer: Customer): Promise<Response> {
  return fetch(`/api/event-flow/update-customer`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(customerToUpdateFormData(customer)),
  });
}

export const updateCustomerV2 = async (customerId: string, obj: { [key: string]: any }) =>
  fetch(`/api/event-flow/update-customer`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({ id: customerId, ...obj }),
  });

export const createCustomer = async (customer: Customer): Promise<Response> =>
  fetch('/api/create-customer', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(customer, true),
  });

export const validateEmailIsAvailable = async (email: string) =>
  fetchMiddleware(
    `${accountsUrl()}/v3/customers/email/validate`,
    {
      method: 'POST',
      body: JSON.stringify({ email, organization_id: window.gt.orgId }),
    },
    false
  );

export const validateEmail = async (address: string): Promise<EmailValidationResponse> =>
  fetchMiddleware(
    `${accountsUrl()}/v3/email/legacy-validate`,
    {
      method: 'POST',
      body: JSON.stringify({ address }),
    },
    false
  );

type OkEmailValidationResponse = {
  address: string;
  isValid: boolean;
  errors: undefined;
};

type ErrorEmailValidationResponse = {
  address: undefined;
  isValid: undefined;
  errors: string[] | { address: string[] };
};

type EmailValidationResponse = JsonResponse<OkEmailValidationResponse | ErrorEmailValidationResponse>;

export const getCustomerByEmail = async (email: string): Promise<Response> =>
  fetchMiddleware(
    `${accountsUrl()}/v3/customers/unregistered?email=${encodeURIComponent(email)}&organization_id=${window.gt.orgId}`,
    {
      method: 'GET',
    },
    false
  );

export const createAddress = async (address: Address) =>
  fetchMiddleware(`${accountsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: createAddressQuery,
      variables: { input: { ...address, customerId: window.gt.user.id } },
    }),
  });

export const updateAddress = async (address: Address) =>
  fetchMiddleware(`${accountsUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: updateAddressQuery,
      variables: {
        input: {
          firstName: address.firstName,
          lastName: address.lastName,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          state: address.state,
          zip: address.zip,
          isDefault: Boolean(address.isDefault),
          country: address.country,
        },
        id: address.id,
      },
    }),
  });

export const getCustomerByEmailV2 = async (email: string): Promise<Response> =>
  fetch(`/api/get-customer-by-email`, {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({ email }),
  });

export const forgotPassword = async (email: string) =>
  fetchMiddleware(
    `${accountsUrl()}/v2/password/email`,
    {
      method: 'POST',
      body: JSON.stringify({ email, organization_id: window.gt.orgId }),
    },
    false
  );

export const login = async (email: string, password: string) =>
  fetch('/api/v3/customer-login', {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({ email, password }),
  });

export const invitedMemberLogin = async (customerId: string, password: string) =>
  fetch('/api/sign-up-invited-member', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData({ customerId, password }),
  });

export const getAddresses = async () =>
  fetchMiddleware(`${omniUrl()}/graphql?organization_id=${window.gt.orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      query: getAddressesQuery,
      variables: {
        id: window.gt.user.id,
      },
    }),
  });

export const getCustomer = async () =>
  fetch('/api/get-customer', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  });

export const getClonedEventCustomerPromotions = async () =>
  fetch('/api/get-cloned-event-customer-promotions', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  });

export const setPrimaryEvent = async (primaryEventId: string) =>
  fetch('/api/set-primary-event', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      primaryEventId,
    }),
  });

export const updatePrimaryEventId = async (eventId: string) => {
  const res = await setPrimaryEvent(eventId);

  if (res.status === 200) {
    const user = await res.json();

    auth.storeUserJson(user);
  }
};

export const getCommunicationPreferences = async (customerId: string): Promise<GetPreferencesResponse> =>
  fetchMiddleware(`${accountsUrl()}/graphql`, {
    method: 'POST',
    body: JSON.stringify({
      query: getCommunicationPreferencesQuery,
      variables: { customerId },
    }),
  });

export const setCommunicationPreferences = async (
  customerId: string,
  preferences: PreferencesInput
): Promise<SetPreferencesResponse> =>
  fetchMiddleware(`${accountsUrl()}/graphql`, {
    method: 'POST',
    body: JSON.stringify({
      query: setCommunicationPreferencesQuery,
      variables: { customerId, preferences },
    }),
  });

type GetPreferencesResponse = GraphQLResponse<{
  communicationPreferences: CommunicationPreferencesByMedium | null;
}>;

type SetPreferencesResponse = GraphQLResponse<{
  updateCommunicationPreferences: boolean | null;
}>;

type PreferencesInput = {
  [key in keyof CommunicationPreferencesByMedium]?: Partial<IsSubscribedByMessageType>;
};

const getCommunicationPreferencesQuery = `
  query ($customerId: String!) {
    communicationPreferences(customerId: $customerId) {
      email {
        marketing
        transactional
      }
      sms {
        marketing
        transactional
      }
    }
  }
`;

const setCommunicationPreferencesQuery = `
  mutation (
    $customerId: String!,
    $preferences: CommunicationPreferencesByMediumInput!
  ) {
    updateCommunicationPreferences(
      customerId: $customerId,
      preferences: $preferences
    )
  }
`;

const getAddressesQuery = `
  query customer($id: String!) {
    customer(customerId: $id) {
        addresses {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          city
          state
          zip
          country
          isDefault
        }
    }
}`;

const createAddressQuery = `
  mutation createAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
        id,
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        isDefault
    }
  }`;

const updateAddressQuery = `
  mutation ($id: Int!, $input: UpdateAddressInput!) {
    updateAddress(id: $id, input: $input) {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        city
        state
        zip
        country
        isDefault
    }
  }`;

export const updateSignificantOther = async (id: string, obj: { [key: string]: any }) =>
  fetch(`/api/significant-other/${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    credentials: 'same-origin',
    body: JSON.stringify(obj),
  });

export const getSignificantOther = async (customerId: string) =>
  fetch(`/api/significant-other/${customerId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    credentials: 'same-origin',
  });

export const createSignificantOther = async (obj: { [key: string]: string }) =>
  fetch(`/api/significant-other`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(obj),
  });

type ManualMeasurementsRequest = { customerId: string } & ManualMeasurements;

export const submitManualMeasurements = async (body: ManualMeasurementsRequest) =>
  fetch(`/api/manual-measurements`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(body),
  });
