import { Component, SyntheticEvent, ChangeEvent } from 'react';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import { parse } from 'query-string';
import auth from '../../services/Auth';
import CustomerStore from '../../stores/CustomerStore';
import { segmentIdentify } from '../../utils/metrics';
import { themeCopy, themeUrls } from '../../utils/theme';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import usStates from '../../utils/usStates';
import FormNavigation from '../../utils/Component/FormNavigation';
import { setCommunicationPreferences } from '../../services/Accounts';
import { getDataFromResponse } from '../../utils/GraphQL';
import { CommunicationPreferences, getUnsubscribedFromAll, MessageMedium } from '../../utils/CommunicationPreferences';
import FormSelect from '../../components/FormSelect';
import FormCheckGroup from '../../components/FormCheckGroup';
import FormCheckItem from '../../components/FormCheckItem';
import Message from '../../components/Message';
import IconArrowRight from '../../components/IconArrowRight';
import IconArrowLeft from '../../components/IconArrowLeft';

type PreviousStateCallback<T> = (
  prevState: Readonly<T> | Readonly<Partial<T>>,
  props: Readonly<{}>
) => T | Partial<T> | Pick<T, keyof T> | null;

export type SetStateFunction<T = State> = (
  state: T | Partial<T> | PreviousStateCallback<T> | Pick<T, keyof T> | null,
  callback?: () => void
) => void;

export type SaveCustomerPreferencesCallback<DerivedState = State> = (
  props: Props,
  state: DerivedState,
  setState: SetStateFunction,
  next: VoidFunction
) => Promise<void>;

export type OnComponentMountCallback<DerivedState = State> = (
  props: Props,
  state: DerivedState,
  setState: SetStateFunction
) => Promise<any>;

export type SetQueryParamsCallback<DerivedState = State> = (
  container: URLSearchParams,
  parsed: any,
  state: DerivedState
) => void;

export type ConditionalDisplayCallback<DerivedState = State> = (props: Props, state: DerivedState) => boolean;

const showNavigationByDefault = (_: Props, __: State & unknown) => true;

const hideSuccessByDefault = (_: Props, __: State & unknown) => false;

const getDefaultSaveErrorMessage = (e: Error) => e.message ?? 'There was an error saving your account preferences.';

const doNothingOnComponentMount = async (_: Props, __: State, ___: SetStateFunction) => {};

/**
 * A callback that returns a dynamic label or description for one of the preferences
 * checkboxes with the given arguments
 */
export type PreferenceDescriptionCallback = (args: { organization: string }) => string;

export type PreferenceToInputText = {
  [key in MessageMedium]: {
    label: string;
    description: PreferenceDescriptionCallback;
  };
};

const defaultInputText: PreferenceToInputText = {
  sms: {
    label: 'Get Promotions Over SMS',
    description: ({ organization }) =>
      `Optional. I would like to receive promotional text messages from ${organization} via SMS.`,
  },
  email: {
    label: 'Get Promos Over Email',
    description: (_) => 'I would like to receive special offers and marketing emails for my event rental.',
  },
};

/**
 * Experimental input text for marketing's effort to get more people to sign up
 * for promotional emails
 */
export const experimentalInputText: Partial<PreferenceToInputText> = {
  email: {
    label: 'Get Important Email Updates',
    description: (_) => 'Including: Next Steps, Fit & Color Guides, Timelines, and Event Management.',
  },
};

export interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

export interface State {
  loading: boolean;
  error?: string;
  smsOptIn?: boolean;
  emailOptIn?: boolean;
  smsAccountOptIn?: boolean;
  smsPromotionOptIn?: boolean;
  state: string;
  stateErr: boolean;
  saving: boolean;
  channels: CommunicationPreferences;
}

/**
 * Options that can be passed to `MarketingPreferences` to customize the look and
 * functionality of the resulting component
 *
 * These used to be arguments of the function, but an object felt more appropriate
 * as more were added
 */
export type MarketingPreferencesOptions<ChildState = {}> = {
  updateUserOnSave: SaveCustomerPreferencesCallback;
  setQueryParamsOnExit: SetQueryParamsCallback<State & ChildState>;
  defaultChildState?: ChildState;
  onComponentMount?: OnComponentMountCallback<State & ChildState>;
  getSaveErrorMessage?: (e: Error) => string;
  shouldShowNavigation?: ConditionalDisplayCallback<State & ChildState>;
  shouldShowSuccess?: ConditionalDisplayCallback<State & ChildState>;
  headerText?: string;
  preferenceToInputText?: Partial<PreferenceToInputText>;
};

export default function MarketingPreferences<ChildState = {}>(options: MarketingPreferencesOptions) {
  type DerivedState = State & ChildState;

  const {
    updateUserOnSave,
    setQueryParamsOnExit,
    defaultChildState = {},
    onComponentMount = doNothingOnComponentMount,
    getSaveErrorMessage = getDefaultSaveErrorMessage,
    shouldShowNavigation = showNavigationByDefault,
    shouldShowSuccess = hideSuccessByDefault,
    headerText = 'Marketing Preferences',
  } = options;

  const preferenceToInputText = {
    ...defaultInputText,
    ...options.preferenceToInputText,
  };

  return class extends Component<Props, DerivedState> {
    constructor(props: Props) {
      super(props);

      this.state = {
        loading: true,
        error: undefined,
        smsOptIn: true,
        emailOptIn: true,
        smsAccountOptIn: false,
        smsPromotionOptIn: false,
        channels: getUnsubscribedFromAll(),
        state: '',
        stateErr: false,
        saving: false,
        ...(defaultChildState as ChildState),
      };

      this.setState = this.setState.bind(this);
    }

    componentDidMount = async () => {
      await onComponentMount(this.props, this.state, this.setState);

      this.setState({ loading: false } as DerivedState);
    };

    toggleEmailPromos = () => {
      this.state.channels.getEmailMarketing().toggle();

      this.setState((state: DerivedState) => ({
        ...state,
        emailOptIn: !this.state.emailOptIn,
      }));
    };

    toggleSms = () => {
      this.state.channels.getSmsTransactional().toggle();
      this.state.channels.getSmsMarketing().toggle();

      this.setState((state: DerivedState) => ({
        ...state,
        smsAccountOptIn: !state.smsAccountOptIn,
        smsPromotionOptIn: !state.smsPromotionOptIn,
      }));
    };

    handleStateChange = (state: string) => {
      this.setState({ state } as DerivedState, () => {
        if (state !== 'CA') {
          return;
        }

        const promoEmailChannel = this.state.channels.getEmailMarketing();

        if (promoEmailChannel.subscribed) {
          promoEmailChannel.toggle();
        }

        this.setState((state: DerivedState) => ({
          ...state,
          emailOptIn: false,
        }));
      });
    };

    nextPage = () => {
      this.setState({
        error: undefined,
        saving: false,
      } as DerivedState);
    };

    onExit = () => {
      const parsed = parse(this.props.location.search);
      const params = new URLSearchParams(this.props.location.search);

      setQueryParamsOnExit(params, parsed, this.state);

      if (this.props.flow) {
        this.props.flow(`?${params.toString()}`);
      }
    };

    handleSubmit = async (e: SyntheticEvent<HTMLElement>) => {
      e.preventDefault();

      this.setState({ saving: true } as DerivedState);

      try {
        if (this.state.state === '') {
          throw new Error('The state field cannot be blank.');
        }

        const response = await setCommunicationPreferences(auth.user().id, this.state.channels.asObject());

        const data = await getDataFromResponse(response);

        if (!data.updateCommunicationPreferences) {
          throw new Error(`Something went wrong while trying to update the customer's communication preferences`);
        }

        await updateUserOnSave(this.props, this.state, this.setState, this.storeCustomerAndProceed);
      } catch (e) {
        if (typeof e === 'string') {
          /* eslint-disable-next-line no-ex-assign */
          e = new Error(e);
        }

        if (!(e instanceof Error)) {
          // this is unlikely to occur, but if we encounter
          // an exception that isn't a string or instance of
          // Error, we should just throw it again and let an
          // outer error layer deal with it
          throw e;
        }

        this.setState({
          error: getSaveErrorMessage(e),
          stateErr: this.state.state === '',
        } as DerivedState);
      } finally {
        this.setState({ saving: false } as DerivedState);

        this.onExit();
      }
    };

    storeCustomerAndProceed = async () => {
      auth.storeUserJson(CustomerStore.customer);

      await segmentIdentify(auth.user());

      this.nextPage();
    };

    render() {
      const preferenceInputArgs = {
        organization: themeCopy['brandName'][window.organization],
      };

      return (
        <>
          <FormFlowLogo />

          <div className="container">
            <div className="mx-auto max-w-sm space-y-32">
              <h1 className="text-h2-display text-center">{headerText}</h1>

              <FormSelect
                darkMode
                className="w-full"
                id="stateSelect"
                label="State"
                disabled={this.state.loading || this.state.saving}
                errorMessage={this.state.stateErr ? 'This field is required' : undefined}
                description={`We will only use this information to comply with your state marketing regulations. Additionally,
                  ${themeCopy.brandName[window.organization]} only ships to the United States.`}
                value={this.state.state}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => this.handleStateChange(e.target.value)}
              >
                <option value="">Select State</option>
                {usStates.map((state) => (
                  <option key={`${state.abbreviation}-${state.name}`} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </FormSelect>

              <FormCheckGroup darkMode label="Communication Preferences">
                <FormCheckItem
                  name="marketing-email"
                  label={preferenceToInputText.email.label}
                  description={preferenceToInputText.email.description(preferenceInputArgs)}
                  disabled={this.state.loading || this.state.saving}
                  checked={this.state.channels.getEmailMarketing().subscribed}
                  onChange={this.toggleEmailPromos}
                />
                <FormCheckItem
                  name="sms"
                  label={preferenceToInputText.sms.label}
                  description={preferenceToInputText.sms.description(preferenceInputArgs)}
                  disabled={this.state.loading || this.state.saving}
                  checked={
                    this.state.channels.getSmsTransactional().subscribed &&
                    this.state.channels.getSmsMarketing().subscribed
                  }
                  onChange={this.toggleSms}
                />
              </FormCheckGroup>

              <div className="text-sm">
                Message and data rates may apply for email and SMS messaging. Stop SMS by replying "STOP" at any time.{' '}
                <a
                  href={`${process.env.REACT_APP_ECOMM_URL + themeUrls.smsTerms[window.organization]}`}
                  className="tracker-link-mkt-pref-terms-220106-134347"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href={`${process.env.REACT_APP_ECOMM_URL + themeUrls.smsPrivacy[window.organization]}`}
                  className="tracker-link-mkt-pref-privcy-220106-134347"
                >
                  Privacy Policy
                </a>
                .
              </div>

              {this.state.error && <Message message={this.state.error} type="error" />}

              {shouldShowNavigation(this.props, this.state) ? (
                <FormNavigation
                  next={this.handleSubmit}
                  disabled={this.state.state === '' || this.state.loading || this.state.saving}
                />
              ) : (
                <div className="flex flex-col gap-8 sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="btn btn-info w-full grow"
                    onClick={this.handleSubmit}
                    disabled={this.state.state === '' || this.state.loading || this.state.saving}
                  >
                    Save Subscriptions <IconArrowRight />
                  </button>

                  <button
                    title={`Back to ${themeCopy.brandName[window.organization]}`}
                    className="btn btn-default w-full grow"
                    onClick={() => (window.location.href = process.env.REACT_APP_ECOMM_URL ?? '/')}
                    disabled={this.state.state === '' || this.state.loading || this.state.saving}
                  >
                    <IconArrowLeft /> Back
                  </button>
                </div>
              )}

              {shouldShowSuccess(this.props, this.state) && <Message type="success" message="Subscriptions updated!" />}
            </div>
          </div>
        </>
      );
    }
  };
}
