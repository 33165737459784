import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';

interface Props extends RouteComponentProps<any> {}

class Nav extends React.Component<Props> {
  render() {
    const buttonClass = 'text-sm py-16 block text-center flex-grow border-b hover:bg-gray-lighter';
    const buttonNonActiveClass = 'border-b-transparent';
    const buttonActiveClass = 'text-black border-b-black';

    return (
      <div className="flex justify-around bg-white shadow-2xl">
        <button
          className={`${buttonClass} tracker-nav-look_builder-look_builder-211028-123919 ${
            !window.location.toString().includes('/customize/swatch') ? buttonActiveClass : buttonNonActiveClass
          }`}
          onClick={() => {
            this.props.history.push(`/customize${window.location.search}`);
          }}
        >
          Look Builder
        </button>

        <button
          onClick={() => {
            this.props.history.push(`/customize/swatch/browse${window.location.search}`);
          }}
          className={`${buttonClass} tracker-nav-look_builder-recommendations-211028-123919 ${
            window.location.toString().includes('/customize/swatch') ? buttonActiveClass : buttonNonActiveClass
          }`}
        >
          Recommendations
        </button>
      </div>
    );
  }
}

export default withRouter(observer(Nav));
