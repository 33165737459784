import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { setCommunicationPreferences } from '../../services/Accounts';
import { withAuthRequired } from '../../utils/HOC/RequiresAuthentication';
import Loading from '../../utils/Component/Loading';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import auth from '../../services/Auth';
import { getUrlToTermsPage, getUrlToPrivacyPage } from '../../utils/utils';
import Line from '../../components/Line';
import { MessageMedium, MessageType } from '../../utils/CommunicationPreferences';

interface InfoProps {
  phoneNumber: string;
  shouldShowError: boolean;
}

const InfoText = (props: InfoProps) => {
  if (props.shouldShowError) {
    return (
      <p>
        There was an error opting you in. If you received a confirmation text anyways, you can disregard this message.
        Otherwise you can refresh the page to try again.
      </p>
    );
  }

  return (
    <>
      <p>
        Thank you for signing up to receive text messages about your order. We've gone ahead and sent a confirmation to{' '}
        {props.phoneNumber}. To make renting your suit or tuxedo the simplest part of your wedding planning process,
        we'll send you order updates and reminders on key planning steps like Free Swatches, your Free Home Try-On, and
        more!
      </p>

      <Line />

      <p className="text-sm text-gray-darker">
        By signing up via text, you agree to receive recurring automated promotional and personalized marketing text
        messages (e.g. cart reminders) from Generation Tux at the cell number used when signing up. Consent is not a
        condition of any purchase. Reply STOP to cancel. Msg frequency varies. Msg and data rates may apply. View{' '}
        <a className="text-sm" href={getUrlToTermsPage(window.organization)}>
          Terms
        </a>{' '}
        &amp;{' '}
        <a className="text-sm" href={getUrlToPrivacyPage(window.organization)}>
          Privacy
        </a>
        .
      </p>
    </>
  );
};

const OptInLanding = (_: RouteComponentProps<{}>) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const customer = auth.user();

  useEffect(() => {
    const response = setCommunicationPreferences(customer.id, {
      [MessageMedium.SMS]: {
        [MessageType.Updates]: true,
        [MessageType.Promos]: true,
      },
    });

    response.catch((_) => setError(true)).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <FormFlowLogo />

      <div className="container max-w-lg px-8">
        <h1 className="text-h2-display mb-32 text-center">{error ? 'There was an issue' : "You're in!"}</h1>
        <InfoText phoneNumber={customer.phone} shouldShowError={error} />
      </div>
    </>
  );
};

export default withAuthRequired(OptInLanding);
