import React, { MouseEvent } from 'react';
import PropTypes from 'prop-types';
import MeasurementNavItem from './MeasurementNavItem';

interface Props {
  overarm: string;
  neck: string;
  armLength: string;
  chest: string;
  stomach: string;
  waist: string;
  outseam: string;
  overarmError: string;
  neckError: string;
  armLengthError: string;
  chestError: string;
  stomachError: string;
  waistError: string;
  outseamError: string;
  overarmWarning: string;
  neckWarning: string;
  armLengthWarning: string;
  chestWarning: string;
  stomachWarning: string;
  waistWarning: string;
  outseamWarning: string;
  activeMeasurement: string;
  isSubmitting: boolean;
  setActiveMeasurement: (value: string) => void;
  handleSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
}

const EnterMeasurementsNav: React.FC<Props> = ({
  setActiveMeasurement,
  activeMeasurement,
  overarm,
  neck,
  armLength,
  chest,
  stomach,
  waist,
  outseam,
  neckError,
  overarmError,
  chestError,
  armLengthError,
  stomachError,
  waistError,
  outseamError,
  neckWarning,
  overarmWarning,
  chestWarning,
  armLengthWarning,
  stomachWarning,
  waistWarning,
  outseamWarning,
  handleSubmit,
  isSubmitting,
}) => {
  var activeClassSummary = activeMeasurement === 'summary' ? 'btn-info-outline' : 'btn-default';
  var activeClassSubmit = activeMeasurement !== 'summary' ? 'hidden' : 'block';

  return (
    <>
      <MeasurementNavItem
        title="Neck"
        measurement="neck"
        warning={neckWarning}
        currentValue={neck}
        activeMeasurement={activeMeasurement}
        error={neckError}
        setActiveMeasurement={setActiveMeasurement}
      />
      <MeasurementNavItem
        title="Overarm"
        measurement="overarm"
        warning={overarmWarning}
        currentValue={overarm}
        activeMeasurement={activeMeasurement}
        error={overarmError}
        setActiveMeasurement={setActiveMeasurement}
      />
      <MeasurementNavItem
        title="Chest"
        measurement="chest"
        warning={chestWarning}
        currentValue={chest}
        activeMeasurement={activeMeasurement}
        error={chestError}
        setActiveMeasurement={setActiveMeasurement}
      />
      <MeasurementNavItem
        title="Sleeve"
        measurement="armLength"
        warning={armLengthWarning}
        currentValue={armLength}
        activeMeasurement={activeMeasurement}
        error={armLengthError}
        setActiveMeasurement={setActiveMeasurement}
      />
      <MeasurementNavItem
        title="Stomach"
        measurement="stomach"
        warning={stomachWarning}
        currentValue={stomach}
        activeMeasurement={activeMeasurement}
        error={stomachError}
        setActiveMeasurement={setActiveMeasurement}
      />
      <MeasurementNavItem
        title="Waist"
        measurement="waist"
        warning={waistWarning}
        currentValue={waist}
        activeMeasurement={activeMeasurement}
        error={waistError}
        setActiveMeasurement={setActiveMeasurement}
      />
      <MeasurementNavItem
        title="Outseam"
        measurement="outseam"
        warning={outseamWarning}
        currentValue={outseam}
        activeMeasurement={activeMeasurement}
        error={outseamError}
        setActiveMeasurement={setActiveMeasurement}
      />
      <button
        className={`${activeClassSummary} tracker-cta-enter_measurements-summary-200619-111519 btn block w-full text-left`}
        onClick={() => {
          setActiveMeasurement('summary');
        }}
      >
        Summary
      </button>

      <button
        className={`${activeClassSubmit} tracker-cta-enter_measurements-submit-200619-111519 btn btn-info mt-32 block w-full`}
        disabled={isSubmitting}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          handleSubmit(e);
        }}
      >
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </>
  );
};

EnterMeasurementsNav.propTypes = {
  setActiveMeasurement: PropTypes.func.isRequired,
  activeMeasurement: PropTypes.string.isRequired,
  overarm: PropTypes.string.isRequired,
  neck: PropTypes.string.isRequired,
  armLength: PropTypes.string.isRequired,
  chest: PropTypes.string.isRequired,
  stomach: PropTypes.string.isRequired,
  waist: PropTypes.string.isRequired,
  outseam: PropTypes.string.isRequired,
  neckError: PropTypes.string.isRequired,
  overarmError: PropTypes.string.isRequired,
  chestError: PropTypes.string.isRequired,
  armLengthError: PropTypes.string.isRequired,
  stomachError: PropTypes.string.isRequired,
  waistError: PropTypes.string.isRequired,
  outseamError: PropTypes.string.isRequired,
  neckWarning: PropTypes.string.isRequired,
  overarmWarning: PropTypes.string.isRequired,
  chestWarning: PropTypes.string.isRequired,
  armLengthWarning: PropTypes.string.isRequired,
  stomachWarning: PropTypes.string.isRequired,
  waistWarning: PropTypes.string.isRequired,
  outseamWarning: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default EnterMeasurementsNav;
