import { useRef, useState, useEffect, useCallback } from 'react';

const IO = ({
  root = null,
  rootMargin = '0px',
  threshold = 0,
}): [(node: Element | null) => void, { entry: IntersectionObserverEntry | undefined }] => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  const refCallback = useCallback(
    (node: Element | null) => {
      function getObserver() {
        // If there is no observer, then create it.
        // So, we only create it once.
        if (!observerRef.current) {
          observerRef.current = new IntersectionObserver(
            ([entry]) => {
              setEntry(entry);
            },
            { root, rootMargin, threshold }
          );
        }

        return observerRef.current;
      }

      const observer = getObserver();
      observer.disconnect();

      if (node) {
        observer.observe(node);
      }
    },
    [root, rootMargin, threshold]
  );

  return [refCallback, { entry }];
};

export default IO;
