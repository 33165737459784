type Props = {
  text: string;
  className?: string;
};

const HorizontalRuleWithText = ({ text, className }: Props) => (
  <div className={`flex ${className ?? ''}`}>
    <div className="flex-grow border-t border-gray" />
    <span className="mx-4 flex-shrink">{text}</span>
    <div className="flex-grow border-t border-gray" />
  </div>
);

export default HorizontalRuleWithText;
