import React, { useState } from 'react';
import IconCheck from '../../components/IconCheck';
import IconX from '../../components/IconX';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';

interface ConfirmAddressProps {
  suggestedAddressStreet: string;
  suggestedAddressCity: string;
  suggestedAddressState: string;
  suggestedAddressZip: string;
  currentAddressStreet: string;
  currentAddressCity: string;
  currentAddressState: string;
  currentAddressZip: string;
  setEntryAddress: () => void;
  setSuggestionAddress: () => void;
  unconfirmAddress: () => void;
}

const ConfirmAddress: React.FC<ConfirmAddressProps> = ({
  suggestedAddressStreet,
  suggestedAddressCity,
  suggestedAddressState,
  suggestedAddressZip,
  currentAddressStreet,
  currentAddressCity,
  currentAddressState,
  currentAddressZip,
  setEntryAddress,
  setSuggestionAddress,
  unconfirmAddress,
}) => {
  const [chosenAddress, setChosenAddress] = useState('');
  return (
    <>
      {chosenAddress === '' ? (
        <>
          <h2 className="text-h5 mb-8 mt-32">Entered Address</h2>
          <div className="mb-32 bg-white px-16 py-16 xs:px-32">
            <p>{currentAddressStreet}</p>
            <p>{`${currentAddressCity}, ${currentAddressState} ${currentAddressZip}`}</p>
          </div>

          <h2 className="text-h5 mb-8">Did you mean?</h2>
          <div className="mb-32 flex items-center justify-between xs:mb-64">
            <div className="mr-32">
              <p>{suggestedAddressStreet}</p>
              <p>{`${suggestedAddressCity}, ${suggestedAddressState} ${suggestedAddressZip}`}</p>
            </div>
            <div className="flex grow items-start" style={{ justifyContent: 'stretch' }}>
              <button
                className="tracker-button-addr_confirm-cancel-200619-111519 btn btn-sm btn-default mr-8 grow"
                onClick={() => setChosenAddress('entry')}
                aria-label="Cancel"
              >
                <IconX />
              </button>
              <button
                className="tracker-cta-addr_confirm-confirm-200619-111519 btn btn-sm btn-info grow"
                role="link"
                onClick={() => setChosenAddress('suggested')}
                aria-label="Confirm"
              >
                <IconCheck />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="my-32 flex items-center justify-between bg-white px-16 py-16 xs:px-32">
            <div>
              {chosenAddress === 'suggested' ? (
                <>
                  <p>{suggestedAddressStreet}</p>
                  <p>{`${suggestedAddressCity}, ${suggestedAddressState} ${suggestedAddressZip}`}</p>
                </>
              ) : (
                <>
                  <p>{currentAddressStreet}</p>
                  <p>{`${currentAddressCity}, ${currentAddressState} ${currentAddressZip}`}</p>
                </>
              )}
            </div>
            <IconCheck className="text-green" />
          </div>
        </>
      )}

      <div className="row mb-32 mt-32 xs:mb-64">
        <button
          className="tracker-button-addr_confirm-cancel-200619-115620 btn btn-default col-span-3"
          onClick={() => unconfirmAddress()}
          aria-label="Cancel"
        >
          <IconArrowLeft />
        </button>

        <button
          className={`tracker-cta-addr_confirm-continue-200619-111519 btn col-span-9 px-8 ${
            !!!chosenAddress ? 'btn-default' : 'btn-info'
          }`}
          role="link"
          type="submit"
          disabled={!!!chosenAddress}
          onClick={chosenAddress === 'suggested' ? () => setSuggestionAddress() : () => setEntryAddress()}
        >
          Continue
          {<IconArrowRight />}
        </button>
      </div>
    </>
  );
};

export default ConfirmAddress;
