import { makeProductImageObjects } from '../../../utils/utils';
import { Item } from '../../../../types';

interface Props {
  product: Item;
}

const PreviewLayerThumbnails = (props: Props) => {
  const productObjects = makeProductImageObjects(props.product);

  return (
    <div className="relative flex max-w-[40px] items-start justify-start">
      {productObjects.map((layer, index) => {
        const { classStr, url, catalogNumber } = layer;
        return (
          <img
            loading="lazy"
            key={`${url}${catalogNumber}${index}`}
            src={`${url}?fit=crop&crop=fp&width=80&ar=1:1&${layer.thumbnailCrop}`}
            alt={`${catalogNumber}`}
            className={`absolute left-0 top-0 h-auto w-full first:relative ${classStr} ${
              classStr.includes('collar') ? 'z-10' : ''
            }`}
          />
        );
      })}
    </div>
  );
};

export default PreviewLayerThumbnails;
