import type { PropsWithChildren } from 'react';
import { useContext, createContext, useState } from 'react';

type LookBuilderContextState = {
  htoFlow: boolean;
  htoSource: string | null;
};

type LookBuilderContextValue = LookBuilderContextState & {
  actions: {}; // context wide actions go here
};

const LookBuilderContext = createContext<LookBuilderContextValue>({
  actions: {},
  htoFlow: false,
  htoSource: null,
});

type Props = PropsWithChildren<LookBuilderContextState>;

export const LookBuilderProvider = ({ children, htoFlow, htoSource }: Props) => {
  const [state] = useState<LookBuilderContextState>({ htoFlow, htoSource });
  const actions = {};

  return (
    <LookBuilderContext.Provider
      value={{
        actions,
        ...state,
      }}
    >
      {children}
    </LookBuilderContext.Provider>
  );
};

export const useLookBuilderState = (): LookBuilderContextValue => {
  const context = useContext(LookBuilderContext);

  if (context === undefined) {
    throw new Error('useLookBuilderState must be used within a LookBuilderProvider');
  }

  return context;
};
