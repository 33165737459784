export const ALLURE_BEIGE_SUIT = 'Allure Beige Suit' as const;
export const ALLURE_DARK_BLUE_SUIT = 'Allure Dark Blue Suit' as const;
export const ALLURE_DARK_BROWN_SUIT = 'Allure Dark Brown Suit' as const;
export const ALLURE_LIGHT_GRAY_SUIT = 'Allure Light Gray Suit' as const;
export const BLACK_NOTCH_LAPEL_TUXEDO = 'Black Notch Lapel Tuxedo' as const;
export const BLACK_PEAK_LAPEL_TUXEDO = 'Black Peak Lapel Tuxedo' as const;
export const BLACK_SHAWL_LAPEL_TUXEDO = 'Black Shawl Lapel Tuxedo' as const;
export const BLACK_SUIT = 'Black Suit' as const;
export const BRIGHT_BLUE_SUIT = 'Bright Blue Suit' as const;
export const BRITISH_TAN_SUIT = 'British Tan Suit' as const;
export const BURGUNDY_SUIT = 'Burgundy Suit' as const;
export const CEMENT_GRAY_SUIT = 'Cement Gray Suit' as const;
export const CHARCOAL_GRAY_NOTCH_LAPEL_TUXEDO = 'Charcoal Gray Notch Lapel Tuxedo' as const;
export const CHARCOAL_SUIT = 'Charcoal Suit' as const;
export const GRAY_SHARKSKIN_SUIT = 'Gray Sharkskin Suit' as const;
export const HUNTER_GREEN_SUIT = 'Hunter Green Suit' as const;
export const INDIGO_BLUE_SUIT = 'Indigo Blue Suit' as const;
export const IRON_GRAY_SUIT = 'Iron Gray Suit' as const;
export const LIGHT_GRAY_NOTCH_LAPEL_TUXEDO = 'Light Gray Notch Lapel Tuxedo' as const;
export const LIGHT_GRAY_PLAID_SUIT = 'Light Gray Plaid Suit' as const;
export const MIDNINGHT_BLUE_PEAK_LAPEL_TUXEDO = 'Midnight Blue Peak Lapel Tuxedo' as const;
export const MYSTIC_BLUE_EDGE_NOTCH_LAPEL_TUXEDO = 'Mystic Blue Edge Notch Lapel Tuxedo' as const;
export const MYSTIC_BLUE_SUIT = 'Mystic Blue Suit' as const;
export const NAVY_BLUE_SUIT = 'Navy Blue Suit' as const;
export const OXFORD_NAVY_BLAZER = 'Oxford Navy Blazer' as const;
export const POSTMAN_BLUE_SUIT = 'Postman Blue Suit' as const;
export const SLATE_BLUE_SUIT = 'Slate Blue Suit' as const;
export const WHITE_TUXEDO_JACKET = 'White Tuxedo Jacket' as const;

// sort order defined here: https://docs.google.com/spreadsheets/d/19DA64vRm4IRDPpa1kcrBnEh0qN_Oy9tLIoePWkojA2k/edit?gid=0#gid=0
export const bundleSortOrderMap: { [key: string]: number } = {
  [BLACK_NOTCH_LAPEL_TUXEDO]: 0,
  [BRITISH_TAN_SUIT]: 1,
  [MYSTIC_BLUE_SUIT]: 2,
  [BLACK_SUIT]: 3,
  [SLATE_BLUE_SUIT]: 4,
  [GRAY_SHARKSKIN_SUIT]: 5,
  [BLACK_PEAK_LAPEL_TUXEDO]: 6,
  [BLACK_SHAWL_LAPEL_TUXEDO]: 7,
  [NAVY_BLUE_SUIT]: 8,
  [BRIGHT_BLUE_SUIT]: 9,
  [INDIGO_BLUE_SUIT]: 10,
  [POSTMAN_BLUE_SUIT]: 11,
  [OXFORD_NAVY_BLAZER]: 12,
  [ALLURE_DARK_BLUE_SUIT]: 13,
  [MYSTIC_BLUE_EDGE_NOTCH_LAPEL_TUXEDO]: 14,
  [MIDNINGHT_BLUE_PEAK_LAPEL_TUXEDO]: 15,
  [ALLURE_DARK_BROWN_SUIT]: 16,
  [BURGUNDY_SUIT]: 17,
  [HUNTER_GREEN_SUIT]: 18,
  [IRON_GRAY_SUIT]: 19,
  [CEMENT_GRAY_SUIT]: 20,
  [CHARCOAL_SUIT]: 21,
  [LIGHT_GRAY_PLAID_SUIT]: 22,
  [ALLURE_LIGHT_GRAY_SUIT]: 23,
  [CHARCOAL_GRAY_NOTCH_LAPEL_TUXEDO]: 24,
  [LIGHT_GRAY_NOTCH_LAPEL_TUXEDO]: 25,
  [ALLURE_BEIGE_SUIT]: 26,
  [WHITE_TUXEDO_JACKET]: 27,
} as const;
