import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconTie: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 20 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-[10px] w-[20px] stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Tie Icon</title>
      <path d="M2.5206 3.6519H.4335v2.4347h2.087V3.652z" />
      <path d="M2.5195.4346v8.6956l.7826-.1739c1.4783-.2609 2.7826-.8696 4-1.7391l1.3044-.9565V3.478l-.8696-.6957C6.2587 1.4781 4.4326.6954 2.5195.4346zM19.5635 3.6519h-2.0869v2.4347h2.0869V3.652z" />
      <path d="M17.4776 9.1302V.4346c-1.9131.2608-3.7391 1.0434-5.2174 2.3478l-.8696.6957v2.8695l1.3044.9565c1.2174.8696 2.5217 1.4783 4 1.7392l.7826.087zM8.6078 4.8691H5.7383M14.2602 4.8691h-2.8696" />
      <path d="M11.4789 3.478H8.6094v2.8696h2.8695V3.478z" />
    </svg>
  );
};

export default IconTie;
