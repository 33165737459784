import { observable, computed, action, configure, runInAction } from 'mobx';
import { GTUser, Customer, Member, CustomerClonedEventPromo } from '../types';
import auth from '../services/Auth';
import { objectToCamel } from '../utils/utils';
import {
  updateCustomer,
  createCustomer,
  getCustomerByEmail,
  updateInvitedMember,
  getCustomer,
  getClonedEventCustomerPromotions,
} from '../services/Accounts';

configure({ enforceActions: 'observed' });

class CustomerStore {
  constructor() {
    auth.signedIn() && this.loadCustomer(auth.user());
  }

  @observable membersFetched = false;
  @observable fetchedMembers: Member[] = [];
  @observable clonedEventPromosFetched = false;
  @observable clonedEventPromos: CustomerClonedEventPromo[] = [];

  @observable id: Customer['id'] = undefined;
  @observable email: Customer['email'] = undefined;
  @observable firstName: Customer['firstName'] = undefined;
  @observable lastName: Customer['lastName'] = undefined;
  @observable phone: Customer['phone'] = undefined;
  @observable password: Customer['password'] = undefined;
  @observable isRegistrationComplete: Customer['isRegistrationComplete'] = false;
  @observable primaryEventId: Customer['primaryEventId'] = undefined;
  @observable organizationId: Customer['organizationId'] = undefined;
  @observable smsOptIn: Customer['smsOptIn'] = undefined;
  @observable emailOptIn: Customer['emailOptIn'] = undefined;
  @observable state: Customer['state'] = undefined;

  @computed get customer(): Customer {
    return {
      id: this.id,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      password: this.password,
      isRegistrationComplete: this.isRegistrationComplete,
      primaryEventId: this.primaryEventId,
      organizationId: this.organizationId,
      smsOptIn: this.smsOptIn,
      emailOptIn: this.emailOptIn,
      state: this.state,
    };
  }

  @action set = (customer: Customer) => {
    this.id = customer.id;
    this.email = customer.email;
    this.firstName = customer.firstName;
    this.lastName = customer.lastName;
    this.phone = customer.phone;
    this.password = customer.password;
    this.isRegistrationComplete = customer.isRegistrationComplete;
    this.primaryEventId = customer.primaryEventId;
    this.organizationId = customer.organizationId;
    this.smsOptIn = customer.smsOptIn;
    this.emailOptIn = customer.emailOptIn;
    this.state = customer.state;
  };

  @action
  loadCustomer(user: GTUser): void {
    this.id = user.id;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.phone = user.phone;
    this.primaryEventId = user.primaryEventId;
    this.state = user.state;
  }

  getMembers = async () => {
    try {
      if (this.membersFetched) {
        return this.fetchedMembers;
      }

      const omniRes = await getCustomer();

      if (omniRes.status !== 200) {
        throw new Error('Unable to retrieve customer information');
      }

      const data = await omniRes.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      runInAction(() => {
        this.membersFetched = true;
        this.fetchedMembers = data.customer.members;
      });

      return data.customer.members;
    } catch (e) {
      let errorMessage = 'Failed to get members.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  getClonedEventPromos = async () => {
    try {
      if (this.clonedEventPromosFetched) return this.clonedEventPromos;

      const omniRes = await getClonedEventCustomerPromotions();
      if (omniRes.status !== 200) throw new Error('Unable to retrieve customer information');

      const data = await omniRes.json();
      if (data.errors) throw new Error(data.errors[0].message);

      const usablePromos = data.clonedEventCustomerPromotionsByCustomerId.filter(
        (promo: CustomerClonedEventPromo) =>
          // if no orders exist, customer has not used promo
          promo.promotion && promo.promotion.orders && promo.promotion.orders.length === 0
      );

      runInAction(() => {
        this.clonedEventPromosFetched = true;
        this.clonedEventPromos = usablePromos;
      });

      return usablePromos;
    } catch (e) {
      let errorMessage = 'Failed to get cloned event promos.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  clonedEventByPromo = (promo: CustomerClonedEventPromo) =>
    this.fetchedMembers.filter(m => m.gtEvent!.id === promo.clonedEventId)[0].gtEvent;

  sourceEventByPromo = (promo: CustomerClonedEventPromo) =>
    this.fetchedMembers.filter(m => m.gtEvent!.id === promo.sourceEventId)[0].gtEvent;

  create = async (email: string, password: string) => {
    try {
      const res = await createCustomer({
        email: email,
        password: password,
        isRegistrationComplete: true,
      });

      if (res.status !== 422 && res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.status === 422) {
        const error = await res.json();
        if (error.password) {
          throw new Error(error.password);
        }
        return await this.registerInvitedMember(email, password);
      }

      if (res.status === 200) {
        const customerResponse = await res.json();
        const customer = objectToCamel(customerResponse) as Customer;
        auth.signIn(customer);
        this.set(customer);
        return customer;
      }
    } catch (e) {
      let errorMessage = 'Failed to create customer.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(e);

      throw new Error(errorMessage);
    }
  };

  registerInvitedMember = async (email: string, password: string) => {
    try {
      const response = await getCustomerByEmail(email);
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      const json = await response.json();
      const invitedMemberRes = await updateInvitedMember(json.id!, password);
      if (invitedMemberRes.status !== 200) {
        throw new Error(invitedMemberRes.statusText);
      }
      const customer = objectToCamel(await invitedMemberRes.json()) as Customer;
      auth.signIn(customer);
      auth.storeUserJson(customer);
      this.set(customer);
      return customer;
    } catch (e) {
      let errorMessage = 'Failed to register invited member.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(e);
      throw new Error(errorMessage);
    }
  };

  update = async (customer: Customer) => {
    try {
      const res = await updateCustomer({
        id: customer.id,
        email: customer.email,
        smsOptIn: customer.smsOptIn,
        phone: customer.phone,
        firstName: customer.firstName,
        lastName: customer.lastName,
        isRegistrationComplete: true,
        primaryEventId: customer.primaryEventId,
        emailOptIn: customer.emailOptIn,
        state: customer.state,
      });

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const customerResponse = await res.json();
      const updatedCustomer = objectToCamel(customerResponse) as Customer;
      auth.storeUserJson(updatedCustomer);

      this.set(updatedCustomer);
    } catch (e) {
      let errorMessage = 'Failed to update customer.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }
      console.error(e);
      throw new Error(errorMessage);
    }
  };
}

const singleton = new CustomerStore();
export default singleton;
