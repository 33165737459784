import React from 'react';
import { observer } from 'mobx-react';
import IconTriangleUp from '../../../components/IconTriangleUp';

interface Props {
  display?: string;
  displayXs?: string;
  setAnimateDirection?: React.Dispatch<React.SetStateAction<string>>;
}

const SaveBox = ({ setAnimateDirection }: Props) => {
  return (
    <div className="sticky bottom-16 right-0 z-[100] my-4 ml-auto flex justify-end xl:bottom-[2vh]">
      <button
        className={` ${`tracker-btn-look-builder-gallery-scroll_to_top-20200514-150206`} btn btn-default-outline bg-white shadow-lg`}
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }}
        aria-label="Back to top"
      >
        <IconTriangleUp />
      </button>
    </div>
  );
};

export default observer(SaveBox);
