import { Transition } from '@headlessui/react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import type { GalleryItem as GalleryItemType } from '../../../../types';
import useIntersect from '../../../hooks/IntersectionObserver';
import { JACKET_AND_PANTS, VEST_AND_CUMMERBUND, LAPEL_PIN, TIE, SHIRT } from '../../../data/categories';

import Line from '../../../../components/Line';
import GalleryFilter from './GalleryFilter';
import GalleryHeader from './GalleryHeader';
import GalleryItem from './GalleryItem';
import CategoryIcon from '../../../shared/components/CategoryIcon';
import Spinner from '../../../../shared/components/Spinner';
import ItemStore from '../../../../stores/ItemStore';
import GalleryStore from '../../../../stores/look-builder/GalleryStore';
import PreviewStore from '../../../../stores/look-builder/PreviewStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import SaveBox from '../SaveBox';
import {
  customBundleSort,
  getBundleRecommendedItems,
  inHTOFlow,
  isProductInListCategory,
  sortProductRealPretty,
} from '../../../utils/utils';

import styles from './Gallery.module.css';

// how many products to load
// use a common multiple of all
// column sizes (2,3,4) - 1 (for the empty)
const START_PRODUCT_COUNT = 11;

const galleryFilter = (item: any): boolean => {
  // Exclude recommended items
  if (item.displayName.includes('RECOMMENDED')) return false;
  // Exclude items that are not meant to be displayed
  if (!item.displayable) return false;
  // Exclude items with no pictures/videos
  if (item.media.length === 0) return false;
  // Exclude retail items that are associated with a rental bundle
  if (item.isRetail && !!item.rentalBundle) return false;
  // Exclude retail items when in the HTO flow
  if (item.isRetail && inHTOFlow()) return false;

  return true;
};

type Props = {
  animateDirection?: string;
  mediaQueryXsMatches?: boolean;
  setAnimateDirection: Dispatch<SetStateAction<string>>;
};

const Gallery = ({ mediaQueryXsMatches, animateDirection, setAnimateDirection }: Props) => {
  const sideCarCategory = SideCarStore.category as string;
  const filteredItems = ItemStore.cachedItemsByCategory[sideCarCategory].filter(galleryFilter).slice();
  const filteredSortedItems = customBundleSort(filteredItems);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [listedItems, setListedItems] = useState(filteredSortedItems.slice(0, START_PRODUCT_COUNT));
  const [trackedGalleryItemLoaded, setTrackedGalleryItemLoaded] = useState(false);
  const colorFilter = GalleryStore.getFilters('colors');
  const patternFilter = GalleryStore.getFilters('patterns');
  const categoryFilter = GalleryStore.getFilters('categories');
  const filterKey = `${GalleryStore.getFilters('categories').toString()}-${GalleryStore.getFilters(
    'patterns'
  ).toString()}-${GalleryStore.getFilters('colors').toString()}`;
  const filterIsActive = () => [...colorFilter, ...patternFilter, ...categoryFilter].length > 0;

  const fetchMoreProducts = () => {
    if (filterIsActive()) {
      return setListedItems((state) => [
        ...state,
        ...ItemStore.groupedItemsCache[sideCarCategory][filterKey].slice(
          state.length,
          state.length + START_PRODUCT_COUNT
        ),
      ]);
    }

    return setListedItems((state) => [
      ...state,
      ...filteredSortedItems.slice(state.length, state.length + START_PRODUCT_COUNT),
    ]);
  };

  const productsLoaded = () => {
    if (filterIsActive()) {
      return !(listedItems.length < ItemStore.groupedItemsCache[sideCarCategory][filterKey].length);
    }
    return !(listedItems.length < filteredSortedItems.length);
  };

  // set ref for div at bottom of gallery
  const [ref, { entry }] = useIntersect({ threshold: 0, rootMargin: '0px 0px 30% 0px' });

  // respond to above ref once it's intersected
  if (entry && entry.isIntersecting && !productsLoaded() && !isLoadingProducts) {
    setIsLoadingProducts(true);
    fetchMoreProducts();
  }

  useEffect(() => {
    PreviewStore.setActiveItemHasChanged(false);
    const trackedElement = GalleryStore.trackedGalleryItemElement;
    if (!!trackedElement) {
      if (filterIsActive()) {
        // if there is an item to be auto-scrolled to in gallery store and filters
        // are selected. Find product and load in minimum amount of products to load found product.

        const trackedItemIndex = ItemStore.groupedItemsCache[sideCarCategory][filterKey].findIndex(
          (product) => product.id === Number(trackedElement.id)
        );

        if (trackedItemIndex + 6 >= START_PRODUCT_COUNT) {
          setListedItems(ItemStore.groupedItemsCache[sideCarCategory][filterKey].slice(0, trackedItemIndex + 6));
        } else {
          setListedItems(ItemStore.groupedItemsCache[sideCarCategory][filterKey].slice(0, START_PRODUCT_COUNT));
        }
      } else {
        // if there is an item to be auto-scrolled to in gallery store and no filters
        // are selected. Find product and load in minimum amount of products to load found product.

        const trackedItemIndex = filteredSortedItems.findIndex((product) => product.id === Number(trackedElement.id));

        if (trackedItemIndex + 6 >= START_PRODUCT_COUNT) {
          setListedItems(filteredSortedItems.slice(0, trackedItemIndex + 6));
        } else {
          setListedItems(filteredSortedItems.slice(0, START_PRODUCT_COUNT));
        }
      }

      // this will allow auto-scroll logic to have knowledge of
      // when the product that needs to come into view has loaded.
      setTrackedGalleryItemLoaded(true);
    } else {
      updateListedItems(GalleryStore.galleryItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideCarCategory]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsLoadingProducts(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isLoadingProducts]);

  const updateListedItems = (galleryItems: GalleryItemType[]) => {
    galleryItems.forEach((g) => {
      const gColorsFilter = g.filters.colors;
      const gPatternsFilter = g.filters.patterns;
      const gCategoriesFilter = g.filters.categories;
      if (g.category === sideCarCategory) {
        // if galleryItem filter has been updated and filters are selected
        if ([...gColorsFilter, ...gPatternsFilter, ...gCategoriesFilter].length > 0) {
          const key = `${gCategoriesFilter.toString()}-${gPatternsFilter.toString()}-${gColorsFilter.toString()}`;

          // if groupedItemCache category and key has not been populated by items,
          // then fill cached category with items to cache. This will prevent us
          // from re-filtering items that have already been filtered.
          if (
            ItemStore.groupedItemsCache[sideCarCategory] === undefined ||
            ItemStore.groupedItemsCache[sideCarCategory][key] === undefined
          ) {
            const cacheItems = filteredSortedItems
              .filter((product) => (gColorsFilter.length > 0 ? gColorsFilter.indexOf(product.color!) > -1 : true))
              .filter((product) => (gPatternsFilter.length > 0 ? gPatternsFilter.indexOf(product.pattern!) > -1 : true))
              .filter((product) => {
                if (sideCarCategory === JACKET_AND_PANTS || sideCarCategory === SHIRT) {
                  const filterNames = product
                    .displayName!.toLowerCase()
                    .concat(product.category! && product.category!.toLowerCase())
                    .concat(product.type! && product.type!.toLowerCase());
                  return gCategoriesFilter.length > 0
                    ? gCategoriesFilter.filter((c) => filterNames.includes(c)).length > 0
                    : true;
                }

                if (sideCarCategory === TIE) {
                  return gCategoriesFilter.length > 0
                    ? gCategoriesFilter.indexOf(product.attributePrimary ? product.attributePrimary : '') > -1 ||
                        gCategoriesFilter.indexOf(product.attributeSecondary ? product.attributeSecondary : '') > -1
                    : true;
                }
                if (sideCarCategory === LAPEL_PIN) {
                  return gCategoriesFilter.length > 0
                    ? gCategoriesFilter.indexOf(product.attributePrimary ? product.attributePrimary : '') > -1 ||
                        gCategoriesFilter.indexOf(product.attributeSecondary ? product.attributeSecondary : '') > -1 ||
                        gCategoriesFilter.indexOf(product.attributeTertiary ? product.attributeTertiary : '') > -1
                    : true;
                }
                if (sideCarCategory === VEST_AND_CUMMERBUND) {
                  return gCategoriesFilter.length > 0
                    ? gCategoriesFilter.indexOf(product.category!) > -1 ||
                        gCategoriesFilter.indexOf(product.attributePrimary ? product.attributePrimary : '') > -1
                    : true;
                }

                return true;
              })
              .slice()
              .sort((a, b) => sortProductRealPretty(a, b));

            ItemStore.cacheGroupedItems(sideCarCategory, key, cacheItems);
          }

          setListedItems(ItemStore.groupedItemsCache[sideCarCategory][key].slice(0, START_PRODUCT_COUNT));
        } else {
          setListedItems(filteredSortedItems.slice(0, START_PRODUCT_COUNT));
        }
      }
    });
  };

  reaction(
    () => GalleryStore.galleryItems,
    (galleryItems, reaction) => {
      updateListedItems(galleryItems);
      reaction.dispose();
    }
  );

  // Auto-scroll to previous gallery scroll position
  // when coming back to gallery from details view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const trackedElement = GalleryStore.trackedGalleryItemElement;

    if (!!trackedElement) {
      const body = document.body;
      const html = document.documentElement;

      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      // Only auto-scroll to previous gallery scroll position
      // when document height is greater than previous scroll position.
      // This logic is to ensure that the document has had time to render
      // all product elements and images before attemping to bring previous
      // scroll position into view.
      if (trackedGalleryItemLoaded && docHeight > trackedElement.yPosition) {
        window.scrollTo({ top: trackedElement.yPosition, behavior: 'auto' });
        GalleryStore.resetTrackedGalleryItemElement();

        setTrackedGalleryItemLoaded(false);
      }
    }
  });

  return (
    <Transition
      appear
      show
      enter="transition duration-500"
      enterFrom={`opacity-0 ${
        mediaQueryXsMatches ? '' : animateDirection === 'right' ? 'translate-x-[-50%]' : 'translate-x-[50%]'
      }`}
      enterTo="opacity-100"
    >
      <div data-testid="lookbuilder-gallery" className="flex flex-col">
        <div>
          <div className="top-0 z-50 bg-gray-light py-4 xs:sticky">
            <GalleryHeader setAnimateDirection={setAnimateDirection} />
            <GalleryFilter isBundle={sideCarCategory === JACKET_AND_PANTS} />
          </div>

          {sideCarCategory !== JACKET_AND_PANTS &&
            PreviewStore.bundle &&
            getBundleRecommendedItems(PreviewStore.bundle).map(
              (product) =>
                sideCarCategory &&
                isProductInListCategory(sideCarCategory, product) && (
                  <div
                    className="mb-4 flex justify-center bg-gray-lighter px-16 py-16 md:px-32 xl:px-64 xl:py-32"
                    key={product.id}
                  >
                    <div className="flex items-center justify-center pr-16 sm:pr-32 lg:pr-64">
                      <div>
                        <h5 className="normal-case text-h5-display mb-4">Recommended</h5>
                        <Line className="my-32 sm:my-16" />
                        <p className="text-sm mb-8 text-gray-dark">This item matches your jacket selection.</p>
                      </div>
                    </div>
                    <div className="mr-0 max-w-[180px] lg:mr-32">
                      <GalleryItem setAnimateDirection={setAnimateDirection} item={product} />
                    </div>
                  </div>
                )
            )}

          <div className={`grid grid-cols-2 gap-4 p-0 md:grid-cols-3 ${styles.gridContainer}`}>
            <>
              {PreviewStore.lookPreview
                .filter((item) => item.category === sideCarCategory)
                .map((item) => (
                  <div
                    className={`group relative flex flex-col justify-between  tracker-btn-look_builder-unset-${sideCarCategory!.replace(
                      /-/g,
                      '_'
                    )}-20200508-111100`}
                    key={item.id}
                    style={{ minHeight: 180 }}
                    onTouchStart={() => {}}
                    onClick={() => item.activeItem && PreviewStore.unsetActiveItem(sideCarCategory!)}
                  >
                    <div
                      className={`relative flex flex-grow flex-col transition ${
                        item.activeItem
                          ? 'cursor-pointer bg-white noMouse:group-active:scale-95 mouse:hover:z-[20] mouse:hover:shadow-gallery-item mouse:group-hover:scale-[.98] mouse:group-hover:shadow-gallery-item'
                          : 'scale-[.95] bg-brand-lighter shadow-gallery-item'
                      }`}
                    >
                      <div
                        className={`flex grow items-center justify-center ${
                          sideCarCategory === JACKET_AND_PANTS ? 'bg-gray-lighter' : 'bg-white'
                        } transform `}
                      >
                        <div className="relative after:absolute after:left-1/2 after:top-1/2 after:h-[2px] after:w-[200%] after:translate-x-[-50%] after:translate-y-[-50%] after:-rotate-45 after:transform after:bg-gray-dark">
                          {PreviewStore.lookPreview.map(
                            (previewItem) =>
                              previewItem.category === sideCarCategory && (
                                <div key={previewItem.id}>
                                  <CategoryIcon
                                    category={previewItem.category}
                                    key={previewItem.id}
                                    className="h-32 w-32"
                                  />
                                </div>
                              )
                          )}
                        </div>
                      </div>

                      <hr
                        className={`my-0 text-gray-lighter sm:my-0 ${item.activeItem ? 'opacity-100' : 'opacity-0'}`}
                      />

                      <div className={`p-8 after:text-xs after:block after:opacity-0 after:content-['-'] `}>
                        <p className="text-xs">None</p>
                      </div>
                    </div>
                  </div>
                ))}

              {listedItems.map((item) => (
                <GalleryItem
                  setAnimateDirection={setAnimateDirection}
                  key={`${item.id}-${item.sku}`}
                  item={item}
                  isBundle={sideCarCategory === JACKET_AND_PANTS}
                  modelToggle={sideCarCategory === JACKET_AND_PANTS}
                />
              ))}
            </>
          </div>

          {!productsLoaded() && (
            <div ref={ref} style={{ width: '100%' }}>
              <Spinner className="h-[300vh] md:h-[120vh]" type="minimal" />
            </div>
          )}

          <SaveBox setAnimateDirection={setAnimateDirection} />
        </div>
      </div>
    </Transition>
  );
};

export default observer(Gallery);
