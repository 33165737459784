import IconArrowRight from '../../../components/IconArrowRight';
import { forwardRef, RefObject } from 'react';

type ExistingLooksPromptProps = {
  className?: string;
  onAccepted: () => any;
  onDecline: () => any;
  eventId: string;
};

const ExistingLooksPrompt = forwardRef<HTMLElement | null, ExistingLooksPromptProps>(
  (props: ExistingLooksPromptProps, ref) => {
    return (
      <div
        className="flex flex-col content-between space-y-24 px-32 py-32 xs:px-56 sm:px-64 md:px-[72px]"
        ref={ref as RefObject<HTMLDivElement>}
      >
        <h5 id="existing-looks-heading" className="text-h5 mt-16 uppercase">
          Would you rather order a home try-on of one of the looks you've already made?
        </h5>

        <p>
          We see you've already gotten started with outfits on your Looks page, so we recommend ordering your Home
          Try-On from there, that way your outfit is saved to your event.
        </p>

        <a
          href={`/event-flow/looks?eventId=${props.eventId}`}
          onClick={props.onAccepted}
          className="btn text-h4 btn-info"
          autofocus="true"
        >
          Go To My Looks Page <IconArrowRight />
        </a>

        <button onClick={props.onDecline} className="text-xs underline">
          No thanks I want to build a new Look
        </button>
      </div>
    );
  }
);

export default ExistingLooksPrompt;
