import type { Item, Product, Bundle, ItemWithRetailBundle } from '../../types';
import { itemIsBlocked } from './blockoutDates';

export const isLongTie = (tie: Product) => {
  return tie.attributePrimary?.toLowerCase() !== 'bow tie';
};

export const shirtIsPleated = (product: Product) => {
  return product.attributeTertiary?.toLowerCase().includes('pleated');
};

export const isSolidWhiteShirt = (product: Product) => {
  return product.color?.toLowerCase() === 'white' && product.pattern?.toLowerCase() === 'solid';
};

export const shirtIsWinged = (product: Product) => {
  return product.attributePrimary?.toLowerCase().includes('wing');
};

export const isTuxedo = (bundle: Bundle) => {
  return bundle.type === 'tuxedo';
};

export const isSuit = (bundle: Bundle) => {
  return bundle.type === 'suit';
};

export const isBundle = (item: Item): item is Bundle => {
  const type = item.type?.toLowerCase() ?? '';
  const category = item.category?.toLowerCase() ?? '';

  return type === 'tuxedo' || type === 'suit' || category === 'preconfigured';
};

export const isItemDisabled = (item: Item, productsAndBundle: Item[]) =>
  Boolean(itemIsBlocked(item)) || Boolean(outfitShouldNotDisplay(item, productsAndBundle));

export const itemHasRetailBundle = (item: Item): item is ItemWithRetailBundle =>
  !!item.retailBundle && !!item.retailBundle.isActive && !!item.retailBundle.displayable;

export const outfitWithoutBlockedItems = (outfitItems: Item[]) =>
  outfitItems.filter((item: Item) => !itemIsBlocked(item));

export const outfitShouldNotDisplay = (item: Item, items: Item[]) =>
  item &&
  items.find((i) => Boolean(i.type) && i.type!.toLowerCase().includes('tux')) !== undefined &&
  item.category!.toLowerCase().includes('belt');

export const getCost = (outfit: Item[]): number => {
  return outfit.reduce((total: number, currentItem: Item) => total + Number(currentItem.cost ?? 0), 0);
};

export const getProductsByColor = (color: string, products: Item[]) => {
  return products.filter((product) => product.color?.toLowerCase() === color.toLowerCase());
};

export const bothProductsHaveMatchingSwatch = (a: Item, b: Item) => {
  if (!a.swatch || !b.swatch) {
    return false;
  }

  return a.swatch.id === b.swatch.id;
};

export const bothProductsHaveMatchingColor = (a: Item, b: Item) => {
  if (!a.color || !b.color) {
    return false;
  }

  return a.color.toLowerCase() === b.color.toLowerCase();
};

export const getImageUrl = (item: Item, label: string) => {
  if (item.media && item.media.length > 0) {
    const image = item.media.find((m) => m.label === label);
    if (image) {
      return image.url;
    }
  }
  return 'https://media.gentux.com/uUVtG8eenezNdwQtbfBrxkxpvaMp';
};

export const getSwatchImageUrl = (swatch: Item) => {
  const { lookBuilderAssetsBaseUrl: url } = window.gt;

  return `${url}/${swatch.sku}-swatch.png?auto=format&w=172&fit=crop&crop=focalpoint&fp-z=1.2`;
};

export const getSwatchStackImageUrl = (swatch: Item, swatchSize: number): string => {
  const imgixProps = `&w=${swatchSize}&h=${swatchSize}&fit=crop&crop=focalpoint&fp-z=2`;
  return `${window.gt.lookBuilderAssetsBaseUrl}/${
    swatch.sku || swatch.catalogNumber
  }-swatch.png?auto=format${imgixProps}`;
};
