// imports
import { camelToSnake } from '../utils/utils';

// types
import {
  RequestMeasurementsData,
  ValidateMeasurementsData,
  CreateOrUpdateMeasurementsData,
} from '../types';

// functions
const convertObjectToFormData = (obj: { [key: string]: any }, snake: boolean = false): FormData => {
  let formData = new FormData();
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      formData.append(snake ? camelToSnake(key) : key, obj[key]);
    }
  });
  return formData;
};

// requests
const requestMeasurements = (data: RequestMeasurementsData) =>
  fetch('/api/predict-measurements', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(data, true),
  });

const predictMeasurementsV2 = (data: RequestMeasurementsData) =>
  fetch('/api/v2/predict-measurements', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(data, true),
  });

const predictMeasurements = (data: RequestMeasurementsData) =>
  fetch('/api/predict-measurements', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(data, true),
  });

const createOrUpdateMeasurements = (data: CreateOrUpdateMeasurementsData) =>
  fetch('/api/create-or-update-measurements', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(data),
  });

const validateMeasurements = (data: ValidateMeasurementsData) =>
  fetch('/api/v2/validate-measurements', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(data),
  });

const getMeasurements = () =>
  fetch('/api/get-measurements', {
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(res => res.json())
    .then(data => {
      if (data.customer.measurements === null) {
        return undefined;
      } else {
        return data.customer.measurements;
      }
    });

// export
export {
  predictMeasurementsV2,
  requestMeasurements,
  createOrUpdateMeasurements,
  getMeasurements,
  validateMeasurements,
  predictMeasurements,
};
