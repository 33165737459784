import MarketingPreferences, {
  Props as ParentProps,
  State as ParentState,
  SetStateFunction,
} from '../../shared/components/MarketingPreferences';
import { updatedPreferences } from '../../utils/metrics';
import auth from '../../services/Auth';
import { withAuthRequired } from '../../utils/HOC/RequiresAuthentication';
import { getCommunicationPreferences } from '../../services/Accounts';
import { getDataFromResponse } from '../../utils/GraphQL';
import { CommunicationPreferences } from '../../utils/CommunicationPreferences';
import CustomerStore from '../../stores/CustomerStore';

type State = ParentState & {
  userEmail: string;
  preferencesUpdated: boolean;
};

const defaultState = {
  userEmail: '',
  preferencesUpdated: false,
};

const getPreferences = async (customerId: string) => {
  try {
    const response = await getCommunicationPreferences(customerId);

    const data = await getDataFromResponse(response);

    if (!data.communicationPreferences) {
      throw new Error(`Failed to retrieve customer's communication preferences`);
    }

    return new CommunicationPreferences(data.communicationPreferences);
  } catch (e) {
    return null;
  }
};

const onComponentMount = async (_: ParentProps, state: State, setState: SetStateFunction<State>) => {
  const user = auth.user();

  if (!user.email) {
    throw new Error('Email must be provided.');
  }

  CustomerStore.loadCustomer(user);

  const channels = (await getPreferences(user.id)) ?? state.channels;

  setState({
    channels,
    userEmail: user.email,
    state: user.state ?? '',
    smsAccountOptIn: channels.getSmsTransactional().subscribed,
    smsPromotionOptIn: channels.getSmsMarketing().subscribed,
    emailOptIn: channels.getEmailMarketing().subscribed,
  });
};

const onSubmit = async (_: ParentProps, state: State, setState: SetStateFunction<State>, __: VoidFunction) => {
  const user = auth.user();

  updatedPreferences(state.userEmail, {
    state: state.state,
    smsOptIn: state.smsOptIn,
    emailOptIn: state.emailOptIn,
  });

  if (user.state && user.state !== state.state) {
    await CustomerStore.update({
      ...CustomerStore.customer,
      state: state.state,
    });
  }

  setState({ preferencesUpdated: true });
};

const onExit = () => null;

const shouldShowNavigation = () => false;

const shouldShowSuccessDialog = (_: ParentProps, state: State) => state.preferencesUpdated;

const OptOutLanding = MarketingPreferences({
  updateUserOnSave: onSubmit,
  setQueryParamsOnExit: onExit,
  defaultChildState: defaultState,
  onComponentMount: onComponentMount,
  shouldShowNavigation: shouldShowNavigation,
  shouldShowSuccess: shouldShowSuccessDialog,
});

export default withAuthRequired(OptOutLanding);
