import React from 'react';

interface ItemHeaderProps {
  displayName?: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLHeadingElement> & ItemHeaderProps>;

const ItemHeader: Props = ({ displayName, ...props }) => <h2 {...props}>{displayName}</h2>;

export default ItemHeader;
