import { useMemo, useState, KeyboardEvent } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';

import EventStore from '../../stores/EventStore';
import MemberStore from '../../stores/MemberStore';
import { IsOwner } from '../../utils/HOC';
import { isNonParticipant } from '../../utils/utils';
import { EventRouteProps, GlobalContextTyping, Member } from '../../types';
import { getAbbreviationForMonth } from '../../event-form-flow/components/date-picker/utils';
import DatePicker from '../../shared/components/DatePicker';
import NavBarSub from '../../partials/NavBarSub';
import PageHeader from '../../partials/PageHeader';
import Navbar from '../../partials/Navbar';
import DetailsOwnerCard from './DetailsOwnerCard';
import FormInput from '../../components/FormInput';

import IconPlus from '../../components/IconPlus';
import IconCheck from '../../components/IconCheck';
import { pageFadeIn } from '../../utils/Component/Animations';
import { Transition } from '@headlessui/react';

interface Props extends EventRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

const getEventDateFromString = (dateAsString: string) => {
  const month = Number(dateAsString.substring(5, 7));
  const year = Number(dateAsString.substring(0, 4));
  const day = Number(dateAsString.substring(10, 8));

  return new Date(year, month - 1, day);
};

const hasPaidMembers = (members: Member[]) => members.some((x) => x.isPaid && !isNonParticipant(x));

const isEventEditable = () => !hasPaidMembers(MemberStore.members);

const DetailsPage = (props: Props) => {
  const initDate = getEventDateFromString(EventStore.event.startDate!);

  const [inputDate, setInputDate] = useState(initDate);
  const [eventDate, setEventDate] = useState(initDate);
  const [savingName, setSavingName] = useState(false);
  const [savingDate, setSavingDate] = useState(false);
  const [error, setError] = useState('');
  const [tempName, setTempName] = useState(EventStore.event.name);
  const [isActive, setIsActive] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const validateName = async (value: string) => {
    setSavingName(true);
    const trimmedValue = value.trim();

    // prevent empty chars
    if (!(trimmedValue.length > 0)) {
      setSavingName(false);
      setError('events must have a name');
      setTempName('');
      return;
    }

    try {
      await eventNameOnChange();
      setSavingName(false);
      setError('');
      setTempName(value.trim());
    } catch (e) {
      setSavingName(false);
      setError('error saving name');
    }
  };

  const handleTempNameChange = async (value: string) => {
    setTempName(value);
  };

  const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLTextAreaElement;
      target.blur();

      setSavingName(true);
      setError('');

      await validateName(tempName!);
    }
  };

  const eventNameOnChange = async () => {
    try {
      await EventStore.update({ name: tempName });
      setError('');
      setSavingDate(false);
    } catch (e) {
      let errorMessage = 'Failed to update event name.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      setError(errorMessage);
    }
  };

  const saveDate = useMemo(() => {
    return async () => {
      setSavingDate(true);
      try {
        await EventStore.update({
          year: inputDate.getFullYear(),
          month: inputDate.getMonth() + 1,
          day: inputDate.getDate(),
        });
        setError('');
        setSavingDate(false);
        setIsEditing(false);
        setEventDate(inputDate);
      } catch (e) {
        var errorMessage = 'Failed to save date.';

        if (e instanceof Error) {
          errorMessage = e.message;
        }

        setError(errorMessage);
      }
    };
  }, [inputDate, setEventDate]);

  const cancelDate = useMemo(() => {
    return () => {
      const dateInStore = getEventDateFromString(EventStore.event.startDate!);
      setInputDate(dateInStore);
      setEventDate(dateInStore);
      setIsEditing(false);
    };
  }, []);

  const deleteEvent = async () => {
    setDeleting(true);
    setDeleteError('');
    try {
      await EventStore.delete(() => {
        setDeleting(false);
        window.location.href = '/account/contact';
      }, false);
    } catch (e) {
      setDeleting(false);
      setDeleteError('Event was unable to be deleted.');
      console.error(e);
    }
  };

  const handleIsEditing = () => {
    setIsEditing(true);
  };

  const owners = MemberStore.getOwners();

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-span-12 sm:col-span-10 sm:col-start-2">
            <div className="m-section mt-32 hidden sm:block">
              <NavBarSub />
            </div>

            <Transition {...pageFadeIn}>
              <PageHeader header="Event Details" />

              <div className="row">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7 lg:col-start-4 xl:col-span-7 xl:col-start-4">
                  <div className="mb-64">
                    {!isEventEditable() ? (
                      <>
                        <h2 className="text-h4 mb-8">Event Name</h2>
                        <p>{tempName}</p>
                      </>
                    ) : (
                      <>
                        <FormInput
                          name={`eventName`}
                          placeholder="event name"
                          value={tempName}
                          errorMessage={error ? error : ''}
                          required
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => validateName(tempName!)}
                          onFocus={() => setIsActive(true)}
                          disabled={savingName}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTempNameChange(e.target.value)}
                          label={'Event Name'}
                          combineLabel
                        />
                        {isActive && (
                          <button
                            onClick={() => validateName(tempName!)}
                            // buttonIcon={savingName ? undefined : <Check />}
                            className="tracker-btn-event_details-save_name-200619-103313 btn btn-sm btn-info mt-8 block"
                          >
                            {savingName ? 'Saving...' : tempName === EventStore.event.name ? <IconCheck /> : 'Save'}
                          </button>
                        )}
                      </>
                    )}
                  </div>

                  <h2 className="text-h4 mb-8">Event Date</h2>

                  {!isEditing && (
                    <>
                      <p>
                        {EventStore.event.startDate !== undefined
                          ? format(EventStore.event.startDate || '', 'MMM D, YYYY')
                          : ''}
                      </p>

                      <button
                        onClick={() => handleIsEditing()}
                        className="btn btn-sm btn-info mt-16"
                        disabled={!isEventEditable()}
                      >
                        Edit Date
                      </button>
                    </>
                  )}

                  {isEditing && (
                    <>
                      <div className="space-between mb-16 flex max-w-md items-center gap-8">
                        <DatePicker
                          eventDate={eventDate}
                          onChange={(input) => setInputDate(input)}
                          formatMonth={(m) => getAbbreviationForMonth(m)}
                        />
                      </div>

                      <div className="mb-32 flex items-center gap-8">
                        <button
                          onClick={() => saveDate()}
                          className="tracker-btn-event_details-save_date-200619-103313 btn btn-sm btn-info"
                        >
                          Save
                        </button>

                        <button
                          onClick={() => cancelDate()}
                          className="tracker-btn-event_details-cancel_date-200619-103313 btn btn-sm btn-default-outline bg-white"
                        >
                          Cancel
                        </button>

                        {savingDate && <span className="text-sm text-gray-dark">saving...</span>}
                      </div>
                    </>
                  )}

                  <div className="mt-64 bg-gray-light p-32">
                    <h5 className="text-h5 mb-8">Owners</h5>
                    <p className="mb-8 text-gray-darker">
                      Owners can help manage the event by editing event details, building looks and assigning members.
                    </p>
                    <p className="mb-32 text-gray-darker">
                      If an owner doesn't show on the&nbsp;
                      <button
                        className="tracker-link-assign-go_to_looks-20191029-133321 text-brand-darker"
                        onClick={() => props.history.push(`/event-flow/members${props.location.search}`)}
                      >
                        Members Page
                      </button>
                      , change their role to a renting one below.
                    </p>

                    <div className="mb-32">
                      <div className="row mb-8 p-16">
                        <h5 className="text-h5 col-span-5 text-gray-dark">Name</h5>
                        <h5 className="text-h5 col-span-7 text-gray-dark">Role</h5>
                      </div>
                      {owners &&
                        owners.length > 0 &&
                        owners.map((owner) => <DetailsOwnerCard key={owner.id} owner={owner} />)}
                    </div>
                    <button
                      onClick={() => props.history.push(`/event/owner/email?eventId=${EventStore.event.id}`)}
                      className="tracker-cta-event_details-add_owner-20191029-133226 btn btn-default-outline w-full bg-white"
                    >
                      Add Owner
                      <IconPlus />
                    </button>
                  </div>

                  {confirmDelete ? (
                    <div className="mt-32">
                      <p>Are you sure you want to delete this&nbsp;event?</p>
                      <div className="row my-16">
                        <button
                          className="tracker-btn-event_details-delete_event-200619-103313 btn btn-danger col-span-6 block w-full"
                          disabled={deleting}
                          onClick={() => deleteEvent()}
                        >
                          {deleting ? 'Deleting...' : 'Yes'}
                        </button>
                        <button
                          className="tracker-btn-event_details-cancel_delete_event-200619-103313 btn btn-default-outline col-span-6 block w-full bg-white"
                          disabled={deleting}
                          onClick={() => setConfirmDelete(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="my-32">
                      <button
                        onClick={() => setConfirmDelete(true)}
                        className="tracker-cta-event_details-deleted_event-200619-103432 btn btn-danger"
                        disabled={!EventStore.isDeletable()}
                      >
                        Delete Event
                      </button>
                      {!EventStore.isDeletable() && (
                        <p className="text-xs pt-16 text-gray-dark">
                          * Event cannot be deleted if members have paid or orders are associated to it.
                        </p>
                      )}
                    </div>
                  )}
                  {deleteError !== '' && <p className="text-sm mb-8 bg-red p-16 font-sans text-white">{deleteError}</p>}
                </div>
              </div>
            </Transition>

            <div className="pt-32 xs:pt-64 sm:hidden">
              <NavBarSub />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsOwner(observer(DetailsPage));
