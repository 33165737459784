import { useState, type ChangeEvent, type SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import type { FlowRouteProps } from 'types';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';
import Message from '../../components/Message';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { AccessContext } from '../../utils/HOC';
import { howDidYouHearAboutUsTrack } from '../../utils/metrics';
import Flow from '../../utils/HOC/Flow';
import { ArrayShuffle } from '../../utils/utils';

type ReferralOptionItem = { label: string; value: string };

const referralOptions: ReferralOptionItem[] = [
  ...ArrayShuffle([
    {
      label: 'Referral From Family/Friend',
      value: 'family_or_friend',
    },
    {
      label: 'Mailer/Postcard',
      value: 'mailer_or_postcard',
    },
    {
      label: 'My Wedding Planner',
      value: 'wedding_planner',
    },
    {
      label: 'Radio',
      value: 'radio',
    },
    {
      label: 'Wedding Website (ex. brides.com, etc.)',
      value: 'wedding_website',
    },
    {
      label: 'Search Engine (Google, Bing, etc.)',
      value: 'search_engine',
    },
    {
      label: 'Instagram or Facebook',
      value: 'instagram_or_facebook',
    },
    {
      label: 'Pinterest or TikTok',
      value: 'pinterest_or_tiktok',
    },
    {
      label: 'Bridal Boutique',
      value: 'bridal_boutique',
    },
    {
      label: 'Bridal Show',
      value: 'bridal_show',
    },
  ]),
  {
    label: 'Other',
    value: 'other',
  },
];

type FormState = {
  other: string;
  referral: ReferralOptionItem['value'];
};

const initialFormState: FormState = {
  other: '',
  referral: '',
};

type ErrorState = {
  other: boolean;
  referral: boolean;
};

const initialErrorState: ErrorState = {
  other: false,
  referral: false,
};

type State = {
  error?: string;
  isSubmitting: boolean;
  outflowIndex: number;
};

const initialState: State = {
  error: undefined,
  isSubmitting: false,
  outflowIndex: 0,
};

const errorMsgRequired = 'This field is required.';

export type ReferralSurveyFormProps = {} & FlowRouteProps<any>;

const ReferralSurveyForm = (props: ReferralSurveyFormProps) => {
  const [form, setForm] = useState<FormState>(initialFormState);
  const [errors, setErrors] = useState<ErrorState>(initialErrorState);
  const [state, setState] = useState<State>(initialState);

  const handleChange =
    (field: keyof FormState) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newFormState = { ...form, [field]: value };

      setForm(newFormState);

      // also clear error while updating field
      setErrors({ ...errors, [field]: false });
    };

  const goBack = (ev: SyntheticEvent<HTMLButtonElement>) => {
    ev.preventDefault();

    return props.history.goBack();
  };

  const nextPage = () => {
    setState({
      ...state,
      error: undefined,
    });

    props.flow!(props.location.search);
  };

  const isFormValid = () => {
    const errors = {
      other: false,
      referral: false,
    };

    if (form.referral === '') {
      errors.referral = true;
    }

    if (form.referral === 'other') {
      errors.other = form.other === '';
    }

    setErrors(errors);

    return !Object.values(errors).some((value) => Boolean(value));
  };

  const handleSubmit = async (ev: SyntheticEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setState({ ...state, isSubmitting: true });

    if (isFormValid()) {
      let index;
      const selectedReferralOption = referralOptions.find((r, i) => {
        if (r.value === form.referral) {
          index = i;

          return true;
        }

        return false;
      });

      howDidYouHearAboutUsTrack.answered({
        answer: selectedReferralOption?.value ?? form.referral,
        details: form.other ?? null,
        email: window.gt?.user?.email,
        eventId: window.gt?.user?.primaryEventId,
        index,
      });

      nextPage();
    } else {
      setState({ ...state, isSubmitting: false });
    }
  };

  return (
    <>
      <FormFlowLogo />

      <div className="container">
        <div className="mx-auto max-w-md">
          <h2 className="text-h2-display mb-16">{'How did you hear about us?'}</h2>

          <form className="w-full" onSubmit={handleSubmit}>
            <div className="mb-32 mt-32 xs:mb-64">
              <div className="my-16">
                <FormSelect
                  className="text-sm w-full border-gray bg-gray-lighter"
                  data-testid="select-referral"
                  id="referralSelect"
                  value={form.referral}
                  onChange={handleChange('referral')}
                >
                  <option value="">{'Please select'}</option>
                  {referralOptions.map((option) => (
                    <option key={`${option.value}`} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormSelect>
                {errors.referral && <Message type="error" message={errorMsgRequired} />}
              </div>

              {form.referral === 'other' && (
                <div className="my-16">
                  <FormInput
                    className="text-sm w-full border-gray"
                    data-testid="input-other"
                    id="other"
                    name="other"
                    placeholder="Please specify where you heard about us..."
                    value={form.other}
                    onChange={handleChange('other')}
                  />
                  {errors.other && <Message type="error" message={errorMsgRequired} />}
                </div>
              )}
            </div>

            <div className="mb-32 mt-32 flex flex-row-reverse gap-8 xs:mb-64">
              <button
                className="tracker-cta-address-submit-231003-111519 btn btn-info grow"
                data-testid="btn-submit"
                disabled={state.isSubmitting}
                role="link"
                type="submit"
              >
                {'Next'} <IconArrowRight />
              </button>
              <button
                className="tracker-button-address-back-231003-111520 btn btn-default"
                onClick={goBack}
                aria-label="Back"
              >
                {<IconArrowLeft />}
              </button>
            </div>

            {state.error && <Message type="error" message={state.error} />}
          </form>
        </div>
      </div>
    </>
  );
};

export default Flow(AccessContext(observer(ReferralSurveyForm)));
