import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import OwnerFormStore from '../../stores/OwnerFormStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import Flow from '../../utils/HOC/Flow';
import { FlowEventRouteProps } from '../../types';

interface Props extends FlowEventRouteProps<{}> {}
interface State {
  firstName?: string;
}

class OwnerFirstName extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: OwnerFormStore.firstName || undefined,
    };
  }

  handleChange = (field: keyof State, value: string) => {
    this.setState((state) => ({
      ...state,
      [field]: value,
    }));
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    OwnerFormStore.update('firstName', this.state.firstName!);
    this.nextPage();
  };

  nextPage() {
    this.props.flow!(this.props.location.search);
  }

  render() {
    return (
      <>
        <FormFlowLogo />

        <div>
          <FormSingleInput
            key="member-first-name"
            property="firstName"
            label="First Name"
            type="text"
            title="What is their first name?"
            value={this.state.firstName || ''}
            required={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange('firstName', e.target.value)}
            onSubmit={(e) => this.handleSubmit(e)}
          />
        </div>
      </>
    );
  }
}

export default Flow(observer(OwnerFirstName));
