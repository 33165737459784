import IconArrowLeft from '../../components/IconArrowLeft';

type Props = {
  disabled?: boolean;
  className?: string;
  iconOnly?: boolean;
  trackerClass?: string;
} & DataTestIdProp;

const BackButton = ({ disabled = false, className = 'w-full', iconOnly = false, trackerClass, ...props }: Props) => (
  <button
    className={`${
      trackerClass ? trackerClass : 'tracker-button-back_button-200619-115408'
    } btn btn-default ${className}`}
    data-testid={props['data-testid'] ?? 'btn-back'}
    disabled={disabled}
    onClick={() => {
      window.history.back();
    }}
    type="button"
  >
    <IconArrowLeft /> {iconOnly ? '' : 'Back'}
  </button>
);

export default BackButton;
