import React, { Component } from 'react';

// local components
import FormSingleInput from '../../utils/Component/FormSingleInput';
import Loading from '../../utils/Component/Loading';

// types
import { FitActions, FlowRouteProps } from '../../types';

// utils
import Flow from '../../utils/HOC/Flow';

// interfaces
interface State {
  outFlowIndex: number;
}

interface Props extends FlowRouteProps<any> {
  reducer: (action: FitActions, changes: any) => void;
  pantWaist?: string;
  heightFt?: number | undefined;
  heightIn?: number | undefined;
  weight?: string;
  requireAbrMeasurements?: () => boolean;
  submitting: boolean;
  error: string | undefined;
  submit?: () => void;
}

class FitWaist extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      outFlowIndex: 0,
    };
  }

  setWaist = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { reducer } = this.props;
    const pantWaist = event.target.value;

    reducer('WAIST_IN_SET', { pantWaist });
  };

  onSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.props.history.location.pathname.includes('/abr/')) {
      if (this.props.requireAbrMeasurements!()) {
        this.setState({ outFlowIndex: 1 });
      } else {
        await this.props.submit!();
      }
    }

    this.props.flow!(`${this.props.location.search}`, this.state.outFlowIndex);
  };

  render() {
    const { pantWaist } = this.props;

    if (this.props.submitting) {
      return <Loading />;
    }

    return (
      <div data-testid="fitflow-waist">
        <FormSingleInput
          key="fit-waist"
          property="text"
          label="Jean Waist (inches)"
          type="number"
          value={pantWaist || ''}
          title="What is your jean waist?"
          onChange={this.setWaist}
          onSubmit={this.onSubmit}
          validExternally={pantWaist ? pantWaist.length > 1 : false}
          error={this.props.error}
        />
      </div>
    );
  }
}

export default Flow(FitWaist);
