import { getHexCodeForFilter, HexCode, isColorFilter } from '../../look-builder/utils/utils';
import React from 'react';
import IconCheck from '../../components/IconCheck';
import { capitalizeFirstLetter } from '../../utils/utils';

type FilterProps = {
  filterColor?: string;
  filterLabel?: string;
  isActive?: boolean;
  isGrayBackground?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLLabelElement & HTMLInputElement>) => void;
};

type Props = React.HTMLProps<HTMLLabelElement> & FilterProps;

const Filter = ({
  children,
  filterColor = 'transparent',
  filterLabel = 'Filter',
  id,
  isActive = false,
  isGrayBackground = false,
  onChange,
  ...props
}: Props) => {
  const effectiveColor: string | HexCode = isColorFilter(filterColor)
    ? getHexCodeForFilter(filterColor, isGrayBackground)
    : filterColor;
  const isWhiteColorFilter = capitalizeFirstLetter(filterColor) === 'White';

  const borderColor =
    filterColor === 'transparent' ? '' : isWhiteColorFilter && isGrayBackground ? '#bdbdbd' : effectiveColor;

  return (
    <label
      className={`relative z-0 inline-flex cursor-pointer items-center overflow-hidden tracker-filter-${filterLabel}-20190611-145632 outline-inherit group ${props.className}`}
      htmlFor={id}
      title={filterLabel}
      {...props}
    >
      <input
        aria-checked={isActive}
        className="peer absolute col-start-1 row-start-1 m-0 h-0 w-0 border-0 p-0 opacity-0"
        defaultChecked={isActive}
        id={id}
        onChange={onChange}
        type="checkbox"
      />

      <span className="flex items-center peer-focus:shadow-form-focus">
        <span
          className={`relative z-[20] flex h-0 w-0 origin-[right,center] items-center justify-center overflow-hidden border border-solid p-[12px] text-center ${
            effectiveColor === 'transparent' ? 'border-gray-light' : ''
          }`}
          style={{
            backgroundColor: isActive ? 'white' : effectiveColor,
            borderColor: borderColor,
            transition: 'all 0.2s ease 0s',
          }}
        >
          <IconCheck
            className={`absolute transform text-gray-darker transition-all ${
              isActive ? 'translate-x-0 opacity-100' : 'translate-x-[-200%] opacity-0'
            }`}
            style={{ transition: 'all 0.2s ease' }}
          />
        </span>

        <span
          className="overflow-hidden p-8 text-gray-darker transition-all"
          style={{ lineHeight: 1, transition: 'all 0.2s ease' }}
        >
          <span className="text-xs block overflow-hidden text-ellipsis" style={{ lineHeight: 1 }}>
            {filterLabel}
          </span>
        </span>
      </span>
    </label>
  );
};

export default Filter;
