import React from 'react';
import { Route } from 'react-router-dom';
import { RouteProps, RouteComponentProps } from 'react-router';
import Loading from '../utils/Component/Loading';
import auth from '../services/Auth';
import { getRedirectUrlFromQueryString } from '../utils/HOC/Flow';

export interface RouteMiddlewareProps extends RouteProps {
  render?: (props: RouteComponentProps) => React.ReactNode;
}

const LOGGED_IN_REDIRECT = [
  '/signup/password',
  '/signup/email',
  '/swatch/email',
  '/swatch/password',
  '/hto/email',
  '/hto/password',
  '/customize/email',
  '/customize/signup/email',
  '/customize/signup/password',
  '/account/login',
  '/swatch/login',
  '/hto/login',
  '/customize/login',
  '/customize/signup/login',
  '/gate/signup/email',
  '/gate/signup/password',
  '/gate/login',
];

/**
 * You would use this exactly like a Route in react router.
 */
const RouteMiddleware: React.FC<RouteMiddlewareProps> = ({ component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<any>) => {
        if (LOGGED_IN_REDIRECT.find((r) => r === props.location.pathname) !== undefined && auth.signedIn()) {
          if (!auth.user().primaryEventId) {
            props.history.push('/login/event/welcome-back');
          } else if (auth.user().firstName === '' || auth.user().lastName === '') {
            props.history.push('/login/customer/welcome-back');
          } else {
            const redirectUrl = getRedirectUrlFromQueryString();

            if (redirectUrl) {
              window.location.href = redirectUrl;
            } else {
              props.history.push(`/event-flow/looks?eventId=${auth.user().primaryEventId}`);
            }
          }

          return (
            <>
              <Loading />
            </>
          );
        }

        return render ? render(props) : component ? React.createElement(component, props) : null;
      }}
    />
  );
};

export default RouteMiddleware;
