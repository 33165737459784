import React from 'react';

const PlaceholderImage = () => {
  return (
    <img
      loading="lazy"
      src="https://gentux.imgix.net/1584373843_placeholder.png"
      alt="placeholder"
      className="placeholder"
    />
  );
};

export default PlaceholderImage;
