import { Component } from 'react';
import type { ChangeEvent, FormEvent, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import EventFormStore from '../../stores/EventFormStore';
import CustomerFormStore from '../../stores/CustomerFormStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { AccessContext } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import FormInput from '../../components/FormInput';
import FormNavigation from '../../utils/Component/FormNavigation';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  firstName: string;
  lastName: string;
  error?: string;
}

class Name extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      lastName: '',
      firstName: '',
      error: undefined,
    };
  }

  componentDidMount() {
    if (window.gt.user && !CustomerFormStore.firstName) {
      CustomerFormStore.update('firstName', window.gt.user.firstName);
    }
    if (window.gt.user && !CustomerFormStore.lastName) {
      CustomerFormStore.update('lastName', window.gt.user.lastName);
    }
    if (window.gt.user && !CustomerFormStore.phone) {
      CustomerFormStore.update('phone', window.gt.user.phone);
    }

    if (CustomerFormStore.firstName && CustomerFormStore.lastName) {
      this.setState({
        firstName: CustomerFormStore.firstName!,
        lastName: CustomerFormStore.lastName!,
      });
    }
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => this.setState({ lastName: e.target.value });

  onSubmit = async (e: FormEvent<HTMLFormElement> | SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    CustomerFormStore.update('firstName', this.state.firstName);
    CustomerFormStore.update('lastName', this.state.lastName);
    EventFormStore.update('name', `${this.state.lastName} ${EventFormStore.type}`);

    this.nextPage();
  };

  nextPage = () => {
    this.setState({
      error: undefined,
    });

    this.props.flow!(this.props.location.search);
  };

  isValid = () => {
    if (this.state.firstName === '' || this.state.lastName === '') {
      return false;
    }
    return true;
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <h1 className="normal-case text-h2-display mb-32 text-center xs:mb-64">What is your name?</h1>
          <form onSubmit={this.isValid() ? (e: FormEvent<HTMLFormElement>) => this.onSubmit(e) : undefined}>
            <div className="mx-auto max-w-lg">
              <h2 className="text-h6">Name</h2>
              <div className="mb-32 flex space-x-4">
                <FormInput
                  className="w-full"
                  data-testid="input-first-name"
                  hideIcon
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ firstName: e.target.value })}
                  placeholder="first name"
                  value={this.state.firstName}
                />
                <FormInput
                  className="w-full"
                  data-testid="input-last-name"
                  hideIcon
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ lastName: e.target.value })}
                  placeholder="last name"
                  value={this.state.lastName}
                />
              </div>

              <FormNavigation
                next={this.onSubmit}
                disabled={!this.isValid()}
                trackerClassBack="tracker-cta-name-back-200619-103126"
                trackerClassNext="tracker-cta-name-next-200619-103126"
              />
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default Flow(AccessContext(observer(Name)));
