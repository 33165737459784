import React from 'react';
import FormContainer from './FormContainer';

interface FormRadioProps extends React.HTMLProps<HTMLLabelElement & HTMLInputElement> {
  combineLabel?: Boolean;
  darkMode?: Boolean;
  inputAddOn?: String;
  errorMessage?: String;
  disabledMessage?: String;
  description?: string;
  onChange?: (event: React.ChangeEvent<HTMLLabelElement & HTMLInputElement>) => void;
}

type Props = React.FC<FormRadioProps>;

const FormRadio: Props = ({ placeholder = 'Enter something...', ...props }) => {
  var { combineLabel, label } = props;

  const hasCombinedLabel = !!combineLabel && !props.description && !!label;

  return (
    <FormContainer
      className={props.className}
      label={label}
      disabled={props.disabled}
      darkMode={props.darkMode}
      inputAddOn={props.inputAddOn}
      errorMessage={props.errorMessage}
      disabledMessage={props.disabledMessage}
      description={props.description}
      useFieldset
      hasCombinedLabel={hasCombinedLabel}
    >
      <div className="form-group-radio border border-gray bg-white py-8">{props.children}</div>
    </FormContainer>
  );
};

export default FormRadio;
