import React, { Component, FormEvent } from 'react';
import { FlowRouteProps, GlobalContextTyping } from '../../types';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { themeCopy } from '../../utils/theme';
import { url } from '../../utils/window';
import { createNPS } from '../../services/Marketing';
import Message from '../../components/Message';
import FormTextArea from '../../components/FormTextArea';
import IconArrowRight from '../../components/IconArrowRight';
import IconArrowLeft from '../../components/IconArrowLeft';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  comments: string;
  email: string | null;
  score: number | null;
  missingParams: boolean;
  shipmentId: number | null;
  submitting: boolean;
  submitError: boolean;
  submitSuccess: boolean;
  errorMessage: string;
}

class NPS extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      comments: '',
      email: null,
      score: null,
      missingParams: false,
      shipmentId: null,
      submitting: false,
      submitError: false,
      submitSuccess: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    const email = url.param(this.props.location.search, 'email');
    const score = url.param(this.props.location.search, 'score');
    const shipmentId = url.param(this.props.location.search, 'shipment_id');

    if (!email || !score) {
      return this.setState({
        missingParams: true,
      });
    }

    this.setState({ email, score: parseInt(score), shipmentId: shipmentId ? parseInt(shipmentId) : null });
  }

  handleSubmit = async (e: FormEvent) => {
    this.setState({ submitting: true, submitError: false });
    try {
      e.preventDefault();
      await createNPS(this.state.email!, this.state.score!, this.state.comments, this.state.shipmentId);
      this.setState({ submitting: false, submitSuccess: true });
    } catch (e) {
      console.error(e);
      this.setState({
        submitError: true,
        errorMessage: 'There has been an error submitting your comments. Please try again.',
      });
    }
  };

  render() {
    const isValid = this.state.comments === '';

    return (
      <>
        <FormFlowLogo />
        <div className="container">
          <div className="row flex-wrap">
            <div className="col-start-0 col-span-12 sm:col-span-6 sm:col-start-4">
              {!this.state.missingParams ? (
                !this.state.submitSuccess ? (
                  <form onSubmit={isValid ? this.handleSubmit : undefined}>
                    <h2 className="text-h2-display mb-32 text-center">Almost There&hellip;</h2>
                    <div className="mb-32">
                      <FormTextArea
                        className="w-full"
                        id="comment"
                        label="What is the most important reason for your score?"
                        placeholder="Comment here..."
                        value={this.state.comments}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          this.setState({ comments: e.target.value })
                        }
                      />
                      {this.state.submitError && <Message message={this.state.errorMessage} type="error" />}
                    </div>

                    <div className="flex flex-col content-between sm:flex-row-reverse">
                      <button
                        className="tracker-cta-nps-submit-20210106-134124 btn btn-info mb-16 grow"
                        onClick={this.handleSubmit}
                        disabled={isValid || this.state.submitting}
                        type="submit"
                      >
                        {this.state.submitting ? 'Submitting...' : 'Submit'} <IconArrowRight />
                      </button>
                      <button
                        className="tracker-cta-nps-back-20210106-134144 btn btn-default mb-16 mr-0 grow sm:mr-16"
                        onClick={() => (window.location.href = `${process.env.REACT_APP_ECOMM_URL}`)}
                      >
                        <IconArrowLeft /> Back
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <h2 className="normal-case text-h2-display mb-32 text-center">
                      Thank you! Your review has been submitted.
                    </h2>
                    <div className="flex flex-wrap justify-center">
                      <button
                        className="tracker-cta-nps-thanks-20210106-134202 btn btn-info"
                        onClick={() => (window.location.href = `${process.env.REACT_APP_ECOMM_URL}`)}
                      >
                        To {themeCopy.brandName[window.organization]} <IconArrowRight />
                      </button>
                    </div>
                  </>
                )
              ) : (
                <>
                  <h2 className="normal-case text-h2-display mb-32 text-center">
                    Sorry, you must have taken a wrong turn.
                  </h2>
                  <div className="flex flex-wrap justify-center">
                    <button
                      className="tracker-cta-nps-sorry-20210106-134215 btn btn-info"
                      onClick={() => (window.location.href = `${process.env.REACT_APP_ECOMM_URL}`)}
                    >
                      To {themeCopy.brandName[window.organization]} <IconArrowRight />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NPS;
