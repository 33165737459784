import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconShoe: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 22 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-[11px] w-[22px] stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Shoe Icon</title>
      <path d="M13.5999 4.4802l-.6413 1.4964-3.4915.7125-1.8526 1.2826H1.914M10.0384 2.6277L8.6133 3.9815" />
      <path d="M1.6307 3.0552L1.203 9.967H7.83V8.5419l5.6291 1.425 7.3393-.855-.0713-.9263c-.0712-.9263-.6413-1.7814-1.5676-2.0664l-5.6291-1.7101-4.7029-3.2777-.855.7125C6.5472 3.0552 4.6946 3.4828 2.9132 3.269l-1.2825-.2138zM11.4642 3.34l-1.4251 1.354" />
    </svg>
  );
};

export default IconShoe;
