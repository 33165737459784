import React from 'react';
import { observer } from 'mobx-react';

import { makeProductImageObjects, makeShadowImageObjects, makeHangerObjects } from '../../../utils/utils';
import { Item } from '../../../../types';

interface Props {
  product: Item;
  sideCarCategory: string | null;
  previewProducts: Item[];
}

const PreviewLayer = (props: Props) => {
  const productObjects = makeProductImageObjects(props.product);
  const shadowObjects = makeShadowImageObjects(productObjects);
  const hangerObject = makeHangerObjects(productObjects, props.previewProducts) || [];
  const layers = [...productObjects, ...shadowObjects, ...hangerObject];

  const handleLoad = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.classList.add('loaded');
    e.target.classList.remove('loading');
  };

  return (
    <>
      {layers.map((layer, index) => {
        const { classStr, url, catalogNumber } = layer;
        return (
          <img
            loading="lazy"
            key={url + catalogNumber + index}
            onLoad={(e: React.ChangeEvent<HTMLImageElement>) => handleLoad(e)}
            className={classStr + ' loading'}
            src={url}
            alt={`${catalogNumber}`}
          />
        );
      })}
    </>
  );
};

export default observer(PreviewLayer);
