import React from 'react';
import { FlowRouteProps } from '../../types';
import EventStore from '../../stores/EventStore';
import MemberStore from '../../stores/MemberStore';
import CustomerStore from '../../stores/CustomerStore';
import AddressesStore from '../../stores/AddressesStore';
import InvitedMemberFlowStore from '../../stores/InvitedMemberFlowStore';
import Loading from '../Component/Loading';
import auth from '../../services/Auth';
import { isNonParticipant } from '../utils';
import { url } from '../window';

interface State {
  loading: boolean;
}

const IsEventMember = <P extends FlowRouteProps<any>>(WrappedComponent: React.ComponentType<P>) =>
  class IsEventMember extends React.Component<P, State> {
    constructor(props: P) {
      super(props);
      this.state = {
        loading: false,
      };
    }

    async componentDidMount() {
      if (!this.props.history.location.pathname.includes('/invited')) {
        return;
      }

      this.setState({
        loading: true,
      });

      if (auth.signedIn()) {
        CustomerStore.loadCustomer(auth.user());
      }

      if (!EventStore.event.id) {
        try {
          const eventId = url.param(this.props.location.search, 'eventId');
          await EventStore.loadEvent(eventId!);
        } catch (e) {
          var errorMessage = 'Failed to mount event store';

          if (e instanceof Error) {
            errorMessage = e.message;
          }

          throw new Error(errorMessage);
        }
      }

      const clonedEvent = EventStore.event.clonedEvent;
      if (!!clonedEvent) {
        const member = clonedEvent.members!.find((m) => m.accountId === window.gt.user.id);
        if (member) {
          // if the invited member is a member of the cloned event, redirect
          // them to the cloned event instead of source event
          return window.location.replace(
            `${this.props.location.pathname}?eventId=${clonedEvent.id}&memberId=${member.id}`
          );
        } else {
          // if the invited member is not a member of the cloned event
          // then they will need to wait for the event owner to send
          // out a new invited link to the newly cloned event
          return window.location.replace('/');
        }
      }

      const member = MemberStore.getSignedInMember();

      if (member === undefined) {
        return window.location.replace('/');
      }

      if (isNonParticipant(member) || member.isOwner) {
        return this.props.history.push(`/event-flow/looks?eventId=${EventStore.event.id}`);
      }

      if (!window.gt.user.state && !window.gt.user.isCx) {
        return this.props.history.push(`/invited/signup/missing-information?eventId=${EventStore.event.id}`);
      }

      // Redirect to Fit if they haven't given us fit info
      if (!member.isMeasured && !this.props.history.location.pathname.includes('/fit-flow/')) {
        return this.props.history.push(
          `/fit-flow/invited/height?memberId=${member.id!}&eventId=${EventStore.event.id!}`
        );
      }

      // Redirect to Shipping if paid and missing a default address, otherwise, redirect to completed
      if (member.isMeasured && member.isPaid && this.props.history.location.pathname.includes('/fit-flow')) {
        await AddressesStore.loadAddresses();
        const hasDefaultAddress = AddressesStore.addresses.filter((a) => a.isDefault).length > 0;

        if (hasDefaultAddress)
          return this.props.history.push(`/invited/completed?memberId=${member.id!}&eventId=${EventStore.event.id!}`);

        return this.props.history.push(`/invited/shipping?memberId=${member.id!}&eventId=${EventStore.event.id!}`);
      }

      // Redirect to Preview if they haven't paid to get them on the unpaid flow and to confirm shipping
      if (member.isMeasured && !member.isPaid && !InvitedMemberFlowStore.fitConfirmed) {
        return this.props.history.push(`/invited/confirm-fit?memberId=${member.id!}&eventId=${EventStore.event.id!}`);
      }

      // Otherwise keep them on their current page
      this.setState({
        loading: false,
      });
    }

    render() {
      return this.state.loading ? <Loading /> : <WrappedComponent {...this.props} />;
    }
  };

export default IsEventMember;
