import React, { useEffect } from 'react';
import PreviewLayer from './PreviewLayer';
import { Transition } from '@headlessui/react';
import {
  lookLayers,
  prettifyCategory,
  isCategoryValidInCurrentFlow,
  isItemValidInCurrentFlow,
} from '../../../utils/utils';
import PreviewLayerThumbnails from '../preview/PreviewLayerThumbnails';
import CategoryIcon from '../../../shared/components/CategoryIcon';
import PreviewStore from '../../../../stores/look-builder/PreviewStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import './LookPreview.css';

import Line from '../../../../components/Line';
import IconArrowLeft from '../../../../components/IconArrowLeft';
import IconCheck from '../../../../components/IconCheck';
import './PreviewExplodedStyle.css';
import { lookBuilderViewedTrack } from '../../../../utils/metrics';

interface Props {
  mediaQueryXsMatches: boolean;
}

const PreviewExploded = (props: Props) => {
  useEffect(() => {
    if (PreviewStore.previewExploded) {
      lookBuilderViewedTrack('exploded');
    }
  }, []);

  return (
    <>
      <div className="flex justify-center">
        <div
          className="LookPreview preview-exploded px-16 py-16 xs:py-32 sm:px-32 md:px-64 lg:py-64"
          onTouchStart={() => {}}
        >
          <h1 className="normal-case text-h4-display mb-16">Select an Item</h1>
          <Line className="hidden xs:block" />

          {props.mediaQueryXsMatches ? (
            <Transition
              appear
              show={true}
              enter="transition-all duration-[600ms]"
              enterFrom="opacity-0 w-[50%]"
              enterTo="opacity-100 w-[100%]"
            >
              <div className="exploded-layer-wrap-placeholder poop">
                <img
                  loading="lazy"
                  src="https://gentux.imgix.net/1591704711_200608-151436-all.png"
                  alt="empty look"
                  className="preview-illustrated"
                />
              </div>
              {PreviewStore.lookPreview
                .filter((l) => isCategoryValidInCurrentFlow(l.category) && isItemValidInCurrentFlow(l))
                .map((look, i) => (
                  <div
                    key={i}
                    title={look.category}
                    className={`exploded-layer-wrap-${
                      look.activeItem
                        ? look.isBundle
                          ? PreviewStore.bundle
                              .products!.filter((p) => !p.category!.includes('Pant'))[0]
                              .category!.replace(/ /g, '-')
                              .toLowerCase()
                          : look.activeItem.category!.replace(/ /g, '-').toLowerCase()
                        : look.icon + '-empty'
                    }`}
                  >
                    <div
                      className={`hit-target tracker-btn-look_builder-exploded_${look.category.replace(
                        /-/g,
                        '_'
                      )}-200619-101534`}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        SideCarStore.set({ car: 'gallery', category: look.category, id: null });
                        PreviewStore.setPreviewExploded(!PreviewStore.previewExploded);
                      }}
                    >
                      <span className="banner text-xs">
                        <span className="banner-label">{prettifyCategory(look.category)}</span>
                      </span>
                    </div>

                    <div key={i} className={`exploded-image-wrap${look.activeItem ? '' : '-empty'}`}>
                      {look.activeItem ? (
                        <>
                          {look.isBundle ? (
                            PreviewStore.bundle &&
                            PreviewStore.bundle.products!.map((product) => (
                              <PreviewLayer
                                key={product.id}
                                sideCarCategory={null}
                                product={product}
                                previewProducts={lookLayers(PreviewStore.productsAndBundle)}
                              />
                            ))
                          ) : (
                            <PreviewLayer
                              sideCarCategory={null}
                              product={look.activeItem}
                              previewProducts={lookLayers(PreviewStore.productsAndBundle)}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <img loading="lazy" src={look.placeholder} alt="empty look" />
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </Transition>
          ) : (
            <div className="grid-mobile">
              {PreviewStore.lookPreview
                .filter((l) => isCategoryValidInCurrentFlow(l.category))
                .map((previewItem, i) => (
                  <div
                    key={i}
                    title={previewItem.category}
                    onClick={() => {
                      SideCarStore.set({ car: 'gallery', category: previewItem.category, id: null });
                      PreviewStore.setPreviewExploded(!PreviewStore.previewExploded);
                      window.scrollTo(0, 0);
                    }}
                    className={`tracker-btn-look_builder-exploded_mobile_${previewItem.category.replace(
                      /-/g,
                      '_'
                    )}-200619-101534`}
                  >
                    <span className="banner text-xs whitespace-nowrap">
                      <span className="banner-label">{prettifyCategory(previewItem.category)}</span>
                    </span>
                    <React.Fragment key={i}>
                      {previewItem.activeItem ? (
                        <PreviewLayerThumbnails
                          product={
                            previewItem.isBundle
                              ? PreviewStore.bundle.products!.filter((p) => !p.category!.includes('Pant'))[0]
                              : previewItem.activeItem
                          }
                        />
                      ) : (
                        <CategoryIcon category={previewItem.category} className="!text-brand-darker" />
                      )}
                    </React.Fragment>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <div className="sticky-box sticky bottom-32 z-[100] mt-32">
        <div className="container">
          <div className="row justify-center">
            <div className="col-span-12 md:col-span-8 md:col-start-3">
              <div className="my-4 flex bg-white px-4 py-4 shadow-2xl">
                <button
                  className="tracker-btn-look-builder-exploaded_mobile-back-200619-102308 btn btn-default-outline mr-4 px-[24px] py-16 sm:px-32 sm:py-32"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    PreviewStore.setPreviewExploded(false);
                  }}
                >
                  <IconArrowLeft />
                </button>

                <button
                  className="tracker-btn-look-builder-exploaded_mobile-confirm-200619-102326 btn btn-info grow py-16 sm:py-32"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    PreviewStore.setPreviewExploded(false);
                    SideCarStore.set({ car: 'list', category: null, id: null });
                  }}
                >
                  <IconCheck />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewExploded;
