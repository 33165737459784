import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PotentialMemberStore from '../../stores/PotentialMemberStore';
import MemberStore from '../../stores/MemberStore';
import LookStore from '../../stores/LookStore';
import EventStore from '../../stores/EventStore';

import { EventRouteProps, Look, Member, PotentialMember } from '../../types';
import { isNonParticipant, hasNonCancelledStandardOrders, isNonParticipantLook, unique } from '../../utils/utils';
import { sortLooks } from '../../utils/looks';
import { IsOwner } from '../../utils/HOC/';
import { participantReassigned } from '../../utils/metrics';

import LookPreview from '../../look-builder/components/look-builder/preview/LookPreview';
import PageHeader from '../../partials/PageHeader';
import NavBarSub from '../../partials/NavBarSub';
import Navbar from '../../partials/Navbar';
import AssignMemberCard, { Participant } from './AssignMemberCard';
import styles from './Assign.module.css';
import { Transition } from '@headlessui/react';
import { pageFadeIn } from '../../utils/Component/Animations';
import HowItWorks from '../../partials/HowItWorks';

const partyRolePlaceHolder = [
  'Groom',
  'Groomsmen',
  'Groomsmen',
  'Groomsmen',
  'Father of Bride',
  'Father of Groom',
  'Usher',
  'Ring Bearer',
];

type ParticipantsByPartyRole = {
  partyRoleName: string;
  members: Participant[];
};

type LookCard = Look & {
  participantsByRole: ParticipantsByPartyRole[];
};

export const isParticipatingPotentialMember = (pm: PotentialMember): pm is Required<PotentialMember> =>
  !isNonParticipant(pm) && pm.member === null;

export const isParticipatingMember = (m: Member): m is Required<Member> => !isNonParticipant(m);

const getFormattedParticipants = (members: Member[]) =>
  members.filter(isParticipatingMember).map((m) => ({
    id: m.id,
    nickname: m.potentialMember?.nickname ?? '',
    partyRoleName: m.potentialMember?.partyRole?.name ?? '',
    isLocked: hasNonCancelledStandardOrders(m) || m.isPaid,
    currentLookId: m.role.id,
    isPotentialMember: false,
    email: m.customer?.email,
  }));

const getFormattedPotentialParticipants = (potentialMembers: PotentialMember[]) =>
  potentialMembers.filter(isParticipatingPotentialMember).map((pm) => ({
    id: pm.id,
    nickname: pm.nickname,
    partyRoleName: pm.partyRole.name,
    currentLookId: pm.role.id,
    isLocked: false,
    isPotentialMember: true,
  }));

export const getParticipants = (potentialMembers: PotentialMember[], members: Member[]): Participant[] => {
  const potentialParticipants = getFormattedPotentialParticipants(potentialMembers);

  const participants = getFormattedParticipants(members);

  const allParticipants = [...potentialParticipants, ...participants];

  return allParticipants;
};

export const getSortedPartyRoles = (participants: Participant[]) => {
  const partyRoles = unique(participants.map((p) => p.partyRoleName));

  return partyRoles.sort((a, b) => {
    const indexForA = partyRolePlaceHolder.indexOf(a);
    const indexForB = partyRolePlaceHolder.indexOf(b);

    if (indexForA === -1) {
      return 1;
    } else if (indexForB === -1) {
      return -1;
    }

    return indexForA - indexForB;
  });
};

/**
 * Returns a list of looks for this event, where each look also contains a list of
 * the participants that are assigned to it
 */
export const getParticipantsByLook = (looks: Look[], participants: Participant[]): LookCard[] => {
  const participantLooks = looks.filter((l) => !isNonParticipantLook(l));

  const participantsByLook = participantLooks.map((look) => {
    const activePartyRoles = getSortedPartyRoles(participants);

    const participantsByRole = activePartyRoles.map((partyRole) => ({
      partyRoleName: partyRole,
      members: participants.filter((m) => m.partyRoleName === partyRole),
    }));

    return {
      ...look,
      participantsByRole,
    };
  });

  return participantsByLook.sort(sortLooks);
};

const AssignPage = (props: EventRouteProps<any>) => {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [lookCards, setLookCards] = useState<LookCard[]>([]);

  useEffect(() => {
    const participants = getParticipants(PotentialMemberStore.potentialMembers, MemberStore.members);
    setParticipants(participants);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PotentialMemberStore.potentialMembers, MemberStore.members]);

  useEffect(() => {
    LookStore.deleteEmptyLooks().then(() => {
      const lookCards = getParticipantsByLook(LookStore.looks, participants);

      setLookCards(lookCards);
    });
  }, [participants]);

  const assignLookToMember = async (memberId: string, partyRoleName: string, lookId: string, isPotential: boolean) => {
    // do not assign if already assigned
    if (isPotential) {
      const potentialMember = PotentialMemberStore.potentialMembers.find((p) => p.id === memberId);
      if (potentialMember && potentialMember.role!.id === lookId) {
        return;
      }
    } else {
      const member = MemberStore.members.find((m) => m.id === memberId);
      if (member && member!.role!.id === lookId) {
        return;
      }
    }

    const updatedCards = lookCards.map((l) => ({
      ...l,
      participantsByRole: l.participantsByRole.map((p) => {
        if (p.partyRoleName !== partyRoleName) {
          return p;
        }

        return {
          ...p,
          members: p.members.map((m) => {
            if (m.id !== memberId) {
              return m;
            }

            return {
              ...m,
              currentLookId: lookId,
            };
          }),
        };
      }),
    }));

    setLookCards(updatedCards);

    const currParticipant = participants.find((participant) => participant.id === memberId);
    const previousLook = LookStore.looks.find((look) => look.id === currParticipant?.currentLookId);

    try {
      if (isPotential) {
        await PotentialMemberStore.update(memberId, { roleId: lookId });
      } else {
        await MemberStore.updateMemberLook(
          MemberStore.members.find((m) => m.id === memberId)!,
          LookStore.looks.find((l) => l.id === lookId)!
        );
      }

      const currentLook = LookStore.looks.find((l) => l.id === lookId);

      participantReassigned(EventStore.event, currentLook, previousLook, currParticipant);
    } catch (e) {
      console.error(e);
      window.alert('Something went wrong when assigning looks. Please refresh and try again.');
    }
  };

  return (
    <>
      <Navbar />
      <div data-testid="eventflow-assign" className="container">
        <div className="row">
          <div className="col-span-12 sm:col-span-10 sm:col-start-2">
            <div className="m-section mt-32 hidden sm:block">
              <NavBarSub />
            </div>

            <Transition {...pageFadeIn}>
              <PageHeader header="Assign">
                <p>
                  Assign people to the looks they should wear. If you need to edit a look,{' '}
                  <button
                    className="tracker-link-assign-go_to_looks-20191029-133321 text-anchor"
                    onClick={() => props.history.push(`/event-flow/looks${props.location.search}`)}
                  >
                    go to the looks page
                  </button>
                  .
                </p>
              </PageHeader>

              {PotentialMemberStore.potentialMembers.filter((m) => !isNonParticipant(m)).length > 0 ? (
                <div className="row gap-y-32">
                  {lookCards.map((look, index) => (
                    <div
                      key={look.name}
                      className={`col-span-6 sm:col-span-4 lg:col-span-3 ${
                        lookCards.length < 4 && index === 0 ? 'lg:col-start-4' : ''
                      }`}
                    >
                      <div className="bg-white shadow-2xl">
                        <div>
                          <h3 className="text-h5 px-16 pt-16">{`${look.name}`}</h3>
                          <div className={`relative h-0 overflow-hidden pb-[30%]  ${styles.croppedPreview}`}>
                            <LookPreview items={[...look.items!, ...look.bundles!]} />
                          </div>
                        </div>
                        <hr className="border-gray-lighter" />
                        {look.participantsByRole.map((p) => (
                          <div key={p.partyRoleName} className="px-8 pb-8">
                            <h6 className="text-h5 mt-8 text-gray-dark">{p.partyRoleName}</h6>
                            {p.members.map((member) => (
                              <div key={member.id}>
                                <AssignMemberCard
                                  lookId={look.id}
                                  assignLookToMember={assignLookToMember}
                                  participant={member}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>
                  You don't have any members yet. Go to the{' '}
                  <button
                    className="tracker-link-assign-go_to_members-20191029-133321 text-anchor"
                    onClick={() => props.history.push(`/event-flow/members${props.location.search}`)}
                  >
                    members page
                  </button>{' '}
                  to add members.
                </p>
              )}
            </Transition>

            <div className="pt-32 xs:pt-64 sm:hidden">
              <NavBarSub />
            </div>
          </div>
        </div>
        <HowItWorks className="mt-256" />
      </div>
    </>
  );
};

export default IsOwner(observer(AssignPage));
