import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { themeCopy } from '../utils/theme';

interface Props extends RouteComponentProps<{}> {}

class Footer extends React.Component<Props> {
  render() {
    return (
      <div className="text-xs pb-16 pt-64 text-center">
        <div>
          <a
            href={`${process.env.REACT_APP_ECOMM_URL}/support`}
            className="tracker-link-footer-support-20191029-134947 text-brand-darker hover:underline"
          >
            Support
          </a>
        </div>
        <div className="text-gray">
          All text and images &copy;{new Date().getFullYear()} Tux Wholesale Services LLC. All rights reserved.{' '}
          {themeCopy.brandName[window.organization]} is a registered trademark.
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
