import React, { Component } from 'react';
import { GlobalContext } from '../context';
import { GlobalContextTyping } from '../../types';
import { RouteComponentProps } from 'react-router';

interface CustomProps {
  globalContext?: GlobalContextTyping;
}

const AccessContext = <P extends RouteComponentProps<any>>(
  WrappedComponent: React.ComponentType<P & CustomProps>
): React.ComponentType<P & CustomProps> =>
  class AccessContext extends Component<P & CustomProps, any> {
    render() {
      return (
        <GlobalContext.Consumer>
          {(globalContext: GlobalContextTyping) => <WrappedComponent {...this.props} globalContext={globalContext} />}
        </GlobalContext.Consumer>
      );
    }
  };

export default AccessContext;
