import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import MemberStore from '../stores/MemberStore';
import { isRentingOwner } from '../utils/utils';
import IconArrowLeft from '../components/IconArrowLeft';
import IconArrowRight from '../components/IconArrowRight';

interface Props extends RouteComponentProps<any> {}

class NavBarSub extends React.Component<Props> {
  handleNextPageClick = () => {
    const currentPath = this.props.location.pathname;

    if (currentPath.includes('details')) {
      return this.props.history.push(`/event-flow/looks${this.props.location.search}`);
    }

    if (currentPath.includes('looks')) {
      return this.props.history.push(`/event-flow/members${this.props.location.search}`);
    }

    if (currentPath.includes('members')) {
      return this.props.history.push(`/event-flow/assign${this.props.location.search}`);
    }

    if (currentPath.includes('assign')) {
      return this.props.history.push(`/event-flow/invite${this.props.location.search}`);
    }

    if (currentPath.includes('invite')) {
      return this.props.history.push(`/event-flow/fit${this.props.location.search}`);
    }

    if (currentPath.includes('fit')) {
      return this.props.history.push(`/event-flow/checkout${this.props.location.search}`);
    }
  };

  handlePreviousPageClick = () => {
    const currentPath = this.props.location.pathname;

    if (currentPath.includes('looks')) {
      return this.props.history.push(`/event-flow/details${this.props.location.search}`);
    }

    if (currentPath.includes('members')) {
      return this.props.history.push(`/event-flow/looks${this.props.location.search}`);
    }

    if (currentPath.includes('assign')) {
      return this.props.history.push(`/event-flow/members${this.props.location.search}`);
    }

    if (currentPath.includes('invite')) {
      return this.props.history.push(`/event-flow/assign${this.props.location.search}`);
    }

    if (currentPath.includes('fit')) {
      return this.props.history.push(`/event-flow/invite${this.props.location.search}`);
    }

    if (currentPath.includes('checkout')) {
      return isRentingOwner(MemberStore.getSignedInMember()!)
        ? this.props.history.push(`/event-flow/fit${this.props.location.search}`)
        : this.props.history.push(`/event-flow/invite${this.props.location.search}`);
    }
  };

  getPrevPageName = () => {
    const currentPath = this.props.location.pathname;

    if (currentPath.includes('details')) {
      return null;
    }

    if (currentPath.includes('looks')) {
      return 'Event Details';
    }

    return 'Previous';
  };

  getNextPageName = () => {
    const currentPath = this.props.location.pathname;

    if (currentPath.includes('checkout')) {
      return null;
    }

    if (currentPath.includes('details')) {
      return 'Looks';
    }

    return 'Next';
  };

  render() {
    const prevPageName = this.getPrevPageName();
    const nextPageName = this.getNextPageName();
    return (
      <div className="row">
        <div className="col-span-6 xs:col-span-4 md:col-span-3">
          {prevPageName &&
            (prevPageName === 'Event Details' ? (
              <>
                <button
                  onClick={() => this.handlePreviousPageClick()}
                  className="tracker-cta-subnav-event_details-20191029-132412 btn btn-default-outline w-full !px-8"
                  aria-label="Event Details"
                >
                  <IconArrowLeft /> Event Details
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => this.handlePreviousPageClick()}
                  className="tracker-cta-subnav-prev-20191029-132450 btn btn-default w-full !px-8"
                >
                  <IconArrowLeft />
                  <span className="inline sm:hidden">Prev</span>
                  <span className="hidden sm:inline">Previous</span>
                </button>
              </>
            ))}
        </div>
        <div className="col-span-6 xs:col-span-8 sm:col-span-4 sm:col-start-9">
          {nextPageName &&
            (nextPageName === 'Looks' ? (
              <>
                <button
                  onClick={() => this.handleNextPageClick()}
                  className="tracker-cta-subnav-next-20191029-132534 btn btn-default-outline w-full"
                >
                  {nextPageName} <IconArrowRight />
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => this.handleNextPageClick()}
                  className="tracker-cta-subnav-next-20191029-132534 group btn btn-primary w-full"
                >
                  Next <IconArrowRight />
                </button>
              </>
            ))}
        </div>
      </div>
    );
  }
}

export default withRouter(observer(NavBarSub));
