import React from 'react';

interface IconRedoProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconRedoProps>;

const IconRedo: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-[10px] w-[10px] fill-current stroke-current text-black ${className ? className : ''}`}
    >
      <title>Redo Icon</title>
      <path d="M5 6l4-3-4-3v6z" stroke="none" />
      <path d="M6 3H.5v6H10" fill="none" />
    </svg>
  );
};

export default IconRedo;
