// Store for LookBuilder Gallery Filters
import { observable, action } from 'mobx';
import { GalleryItem, Item } from '../../types';
import {
  JACKET_AND_PANTS,
  SHIRT,
  TIE,
  VEST_AND_CUMMERBUND,
  POCKET_SQUARE,
  LAPEL_PIN,
  CUFFLINKS,
  BELT_AND_SUSPENDERS,
  SHOES,
  SOCKS,
} from '../../look-builder/data/categories';
import SideCarStore from './SideCarStore';
import { isItemDisabled, itemHasRetailBundle } from '../../utils/items';
import PreviewStore from './PreviewStore';
import { areAllOptionsDisabled } from '../../look-builder/utils/utils';

const galleryStub = [
  {
    id: 1,
    category: JACKET_AND_PANTS,
    mktCopy:
      'The best starting point. Choose your color and jacket style, and start designing the perfect suit or tuxedo for your event.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 2,
    category: SHIRT,
    mktCopy: 'Different patterns, different weights, but all the same high quality look.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 3,
    category: TIE,
    mktCopy:
      'A small touch of color that brings your wedding party together. Choose a bow tie or standard tie to make your suit or tuxedo perfect.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 4,
    category: VEST_AND_CUMMERBUND,
    mktCopy:
      'Complete the look. Include a vest on your suit or tuxedo to create a timeless look that won’t be forgotten.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 5,
    category: POCKET_SQUARE,
    mktCopy:
      'A small touch that makes a big difference. Include a pocket square to finish off the look on your big day.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 6,
    category: LAPEL_PIN,
    mktCopy:
      'A finishing touch that tops off your suit or tuxedo. Our Lapel Pins come in a variety of colors that will go on easy, but won’t go unnoticed.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 7,
    category: CUFFLINKS,
    mktCopy: 'Details matter when all eyes are on you. Cufflinks add a little extra, but go a long way.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 8,
    category: BELT_AND_SUSPENDERS,
    mktCopy:
      'Sometimes the most underestimated part of the look. Matching the right belt to your shoes makes the suit perfect.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 9,
    category: SHOES,
    mktCopy:
      'Shoes can really change the overall look of your suit or tuxedo. You can’t go wrong with one of our three options.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    id: 10,
    category: SOCKS,
    mktCopy: 'Let a little of your personality out with the subtle touch of socks.',
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
];

type RadioOptions = 'Buy' | 'Rent';

class GalleryStore {
  @observable isFilterOpen = false;
  @observable galleryItems: GalleryItem[] = galleryStub;
  @observable trackedGalleryItemElement: { id: string; yPosition: number } | undefined = undefined;
  @observable radioSelection: RadioOptions = 'Rent';

  @action setInitialRadioSelection = (item: Item) =>
    this.isRetailBundleOptionPreselected(item) ? this.setRadioSelection('Buy') : this.setRadioSelection('Rent');

  @action getInitialRadioSelectionValue = (item: Item) => (this.isRetailBundleOptionPreselected(item) ? 'Buy' : 'Rent');

  @action setRadioSelection = (value: RadioOptions) => (this.radioSelection = value);

  @action selectGalleryItem = (item: Item) => {
    if (areAllOptionsDisabled(item, PreviewStore.productsAndBundle)) {
      return null;
    }

    if (itemHasRetailBundle(item) && this.radioSelection === 'Buy') {
      return (
        !isItemDisabled(item.retailBundle!, PreviewStore.productsAndBundle) &&
        PreviewStore.setActiveItem(SideCarStore.category!, item.retailBundle!)
      );
    }

    return (
      !isItemDisabled(item, PreviewStore.productsAndBundle) && PreviewStore.setActiveItem(SideCarStore.category!, item)
    );
  };

  @action selectGalleryItemOption = (value: RadioOptions, item: Item) => {
    this.setRadioSelection(value);

    if (!isItemDisabled(item, PreviewStore.productsAndBundle)) {
      return PreviewStore.setActiveItem(SideCarStore.category!, item);
    }
  };

  @action setTrackedGalleryItemElement = (id: string, yPosition: number) =>
    (this.trackedGalleryItemElement = { id, yPosition });

  @action resetTrackedGalleryItemElement = () => (this.trackedGalleryItemElement = undefined);

  @action setFilters = (filterType: 'categories' | 'patterns' | 'colors', filter: string) => {
    this.galleryItems = this.galleryItems.map((galleryItem) => {
      if (galleryItem.category === SideCarStore.category) {
        const mergeFilters = { ...galleryItem.filters, [filterType]: [...galleryItem.filters[filterType], filter] };

        return { ...galleryItem, filters: mergeFilters };
      }
      return galleryItem;
    });
  };

  @action removeFilters = (filterType: 'categories' | 'patterns' | 'colors', filter: string) =>
    (this.galleryItems = this.galleryItems.map((galleryItem) => {
      if (galleryItem.category === SideCarStore.category) {
        const mergeFilters = {
          ...galleryItem.filters,
          [filterType]: galleryItem.filters[filterType].filter((f) => !f.includes(filter)),
        };
        return { ...galleryItem, filters: mergeFilters };
      }
      return galleryItem;
    }));

  @action setIsFilterOpen = (isOpen: boolean) => (this.isFilterOpen = isOpen);

  getFilters = (filterType: 'categories' | 'patterns' | 'colors') => {
    const filters = this.galleryItems.find((galleryItem) => galleryItem.category === SideCarStore.category);
    if (!!filters && filters.filters) {
      return filters.filters[filterType];
    }
    return [];
  };

  isActiveFilter = (filterType: 'colors' | 'patterns' | 'categories', filter: string) =>
    this.galleryItems.find((galleryItem) => {
      if (galleryItem.category === SideCarStore.category) {
        return galleryItem.filters[filterType].includes(filter);
      }
      return false;
    }) !== undefined;

  // default to retail if retail option is active OR if the rental option is disabled
  isRetailBundleOptionPreselected = (item: Item) =>
    (itemHasRetailBundle(item) && PreviewStore.isItemActive(item.retailBundle)) ||
    (itemHasRetailBundle(item) && isItemDisabled(item, PreviewStore.productsAndBundle));
}

const singleton = new GalleryStore();
export default singleton;
