import type { ChangeEvent, HTMLProps } from 'react';
import FormContainer from './FormContainer';
import IconPencil from './IconPencil';
import { formBaseClassNames, formBaseClassNamesDarkMode, hasCombinedLabel } from './FormUtils';

interface FormInputProps extends HTMLProps<HTMLLabelElement & HTMLInputElement>, DataTestIdProp {
  combineLabel?: Boolean;
  darkMode?: Boolean;
  description?: string;
  disabledMessage?: String;
  errorMessage?: String;
  hideIcon?: Boolean;
  inputAddOn?: String;
  inputClassName?: string;
  onChange?: (event: ChangeEvent<HTMLLabelElement & HTMLInputElement>) => void;
}

const FormInput = ({
  hideIcon,
  inputClassName = '',
  inputMode = 'text',
  pattern,
  placeholder = 'Enter something...',
  ...props
}: FormInputProps) => {
  const {
    className,
    combineLabel,
    darkMode,
    description,
    disabledMessage,
    errorMessage,
    inputAddOn,
    label,
    ...inputProps
  } = props;

  const combinedLabel = hasCombinedLabel(props);

  return (
    <FormContainer
      className={className}
      darkMode={darkMode}
      description={description}
      disabled={props.disabled}
      disabledMessage={disabledMessage}
      errorMessage={errorMessage}
      formIcon={!hideIcon && <IconPencil className={props.disabled ? 'text-gray' : 'text-black'} />}
      hasCombinedLabel={combinedLabel}
      inputAddOn={inputAddOn}
      label={label}
    >
      <input
        placeholder={placeholder}
        {...inputProps}
        className={`
          ${formBaseClassNames} 
          ${combinedLabel ? 'pt-40' : ''} 
          ${darkMode ? formBaseClassNamesDarkMode : ''}
          ${hideIcon ? 'pr-16' : 'pr-40'}
          ${inputClassName}
        `}
        inputMode={inputMode}
        {...(pattern && { pattern: pattern })}
      />
    </FormContainer>
  );
};

export default FormInput;
