import { observable, configure, action, computed } from 'mobx';
import { GTEventForm } from '../types';

configure({ enforceActions: 'observed' });

class EventFormStore {
  @observable day: GTEventForm['day'] = undefined;
  @observable month: GTEventForm['month'] = undefined;
  @observable year: GTEventForm['year'] = undefined;
  @observable name: GTEventForm['name'] = undefined;
  @observable type: GTEventForm['type'] = undefined;
  @observable partyRoleId: GTEventForm['partyRoleId'] = undefined;
  @observable source: GTEventForm['source'] = undefined;
  @observable metadata: GTEventForm['metadata'] = undefined;
  @observable usedDefaultEventDate: GTEventForm['usedDefaultEventDate'] = true;

  @computed get event(): GTEventForm {
    return {
      day: this.day,
      month: this.month,
      year: this.year,
      name: this.name,
      type: this.type,
      partyRoleId: this.partyRoleId,
      source: this.source,
      metadata: this.metadata,
      usedDefaultEventDate: this.usedDefaultEventDate
    };
  }

  @action update = <K extends keyof GTEventForm>(field: K, value: GTEventForm[K]): void => {
    this[field] = value as any;
  };
}

const singleton = new EventFormStore();
export default singleton;
