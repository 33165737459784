import React from 'react';
import PropTypes from 'prop-types';

function SetButtonType(error: string, warning: string, activeMeasurement: string, measurement: string) {
  let buttonType = 'btn-info-outline';
  if (error) {
    buttonType = 'btn-danger';
  } else if (warning) {
    buttonType = 'btn-danger';
  } else if (activeMeasurement === measurement) {
    buttonType = 'btn-info-outline';
  } else {
    buttonType = 'btn-default';
  }
  return buttonType;
}

interface Props {
  activeMeasurement: string;
  title: string;
  measurement: string;
  currentValue: string;
  setActiveMeasurement: (value: string) => void;
  error: string;
  warning: string;
}

const MeasurementNavItem: React.FC<Props> = ({
  activeMeasurement,
  title,
  measurement,
  currentValue,
  setActiveMeasurement,
  error,
  warning,
}) => {
  const buttonClass = SetButtonType(error, warning, activeMeasurement, measurement);
  const trackerClass = `tracker-nav-measurement_card-${currentValue}-200619-111519`;

  return (
    <>
      <button
        className={`btn relative w-full justify-between hover:z-20 ${buttonClass} ${trackerClass}`}
        onClick={() => setActiveMeasurement(measurement)}
      >
        <span className="mr-1">{title} </span>
        <span>
          {currentValue}
          {currentValue ? '"' : ''}
        </span>
      </button>
    </>
  );
};

MeasurementNavItem.propTypes = {
  activeMeasurement: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  warning: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  measurement: PropTypes.string.isRequired,
  setActiveMeasurement: PropTypes.func.isRequired,
};

export default MeasurementNavItem;
