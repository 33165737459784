import FormCheckItem from '../components/FormCheckItem';
import { CommunicationPreference, MessageMedium, MessageType } from '../utils/CommunicationPreferences';
import { FunctionComponent } from 'react';

const channelInfo = {
  [MessageMedium.Email]: {
    [MessageType.Promos]: {
      label: 'Promotional',
      description: 'Receive information about special offers, discounts, our suits and tuxedos, and services.',
    },

    [MessageType.Updates]: {
      label: 'Progress Updates',
      description: 'Receive updates about the status of your account and order progress.',
    },
  },

  [MessageMedium.SMS]: {
    [MessageType.Promos]: {
      label: 'Promotional Offers',
      description: 'Receive SMS text messages about special offers and promotions. Msg & data rates may apply.',
    },

    [MessageType.Updates]: {
      label: 'Event & Order Updates',
      description: 'Receive SMS text messages about my account status and order progress. Msg & data rates may apply.',
    },
  },
} as const;

export const Channel: FunctionComponent<{
  channel: CommunicationPreference;
  toggleChannel: (channel: CommunicationPreference) => void;
}> = ({ channel, toggleChannel }) => {
  const { label, description } = channelInfo[channel.medium][channel.type];

  return (
    <FormCheckItem
      name={`${channel.type}-${channel.medium}`}
      label={label}
      checked={channel.subscribed}
      onChange={() => toggleChannel(channel)}
      description={description}
    />
  );
};
