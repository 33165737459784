import { computed, observable, runInAction, action } from 'mobx';
import { EventWithDeletedLooks, GTEvent, GTEventForm, Order } from '../types';
import {
  getEventDetails,
  deleteEvent,
  updateEvent,
  createShortCode,
  createEventv2,
  sendInviteLinkToMember,
  sendMemberInvites,
} from '../services/Events';
import MemberStore from './MemberStore';
import LookStore from './LookStore';
import PotentialMemberStore from './PotentialMemberStore';
import { lookIsEmpty } from '../utils/looks';
import { format } from 'date-fns';
import auth from '../services/Auth';

class EventStore {
  @observable id: GTEvent['id'] = undefined;
  @observable startDate: GTEvent['startDate'] = undefined;
  @observable name: GTEvent['name'] = undefined;
  @observable eventType: GTEvent['eventType'] = undefined;
  @observable isTrial: GTEvent['isTrial'] = false;
  @observable role: GTEvent['role'] = undefined;
  @observable status: GTEvent['status'] = undefined;
  @observable lastCampaign: GTEvent['lastCampaign'] = undefined;
  @observable metadata: GTEvent['metadata'] = undefined;
  @observable source: GTEvent['source'] = undefined;
  @observable shortCode: GTEvent['shortCode'] = undefined;
  @observable gtEventType: GTEvent['gtEventType'] = undefined;
  @observable clonedEvent: GTEvent['clonedEvent'] = undefined;
  @observable deletedRoles: EventWithDeletedLooks['deletedRoles'] = undefined;

  @computed get event(): EventWithDeletedLooks {
    return {
      id: this.id,
      startDate: this.startDate,
      name: this.name,
      eventType: this.eventType,
      isTrial: this.isTrial,
      role: this.role,
      status: this.status,
      lastCampaign: this.lastCampaign,
      metadata: this.metadata,
      source: this.source,
      shortCode: this.shortCode,
      gtEventType: this.gtEventType,
      clonedEvent: this.clonedEvent,
      deletedRoles: this.deletedRoles,
      get roles() {
        return LookStore.looks;
      },
      get members() {
        return MemberStore.members;
      },
      get potentialMembers() {
        return PotentialMemberStore.potentialMembers;
      },
    };
  }

  loadEvent = async (id: string) => {
    try {
      const response = await getEventDetails(id);

      if (response.status !== 200) {
        throw new Error();
      }

      const json = await response.json();

      if (json.errors) {
        throw new Error(json.errors[0].message);
      }

      this.set(json.data.event);
    } catch (e) {
      var errorMessage = 'Failed to load event.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  @action set = (event: EventWithDeletedLooks) => {
    this.id = event.id;
    this.startDate = event.startDate;
    this.name = event.name;
    this.eventType = event.eventType;
    this.isTrial = event.isTrial;
    this.role = event.role;
    this.status = event.status;
    this.lastCampaign = event.lastCampaign;
    this.metadata = event.metadata;
    this.source = event.source;
    this.shortCode = event.shortCode;
    this.gtEventType = event.gtEventType;
    this.clonedEvent = event.clonedEvent;
    this.deletedRoles = event.allRoles?.filter((look) => look.deletedAt !== null);

    MemberStore.setMembers(event.members!);

    LookStore.setLooks(event.roles!);
    PotentialMemberStore.set(event.potentialMembers!);
  };

  create = async (event: GTEventForm): Promise<string | undefined> => {
    try {
      const response = await createEventv2(
        window.gt.user.id,
        format(
          event.year + '-' + ('0' + event.month!.toString()).slice(-2) + '-' + ('0' + event.day!.toString()).slice(-2),
          'MM/DD/YYYY'
        ),
        event.name!,
        event.type!,
        event.partyRoleId!,
        event.source!,
        event.usedDefaultEventDate!
      );
      if (response.status !== 200) {
        const dataError = await response.json();
        throw new Error(dataError);
      }

      const data = await response.json();

      await this.loadEvent(data.data.CreateEvent.id);
      auth.storeUserJson({ ...window.gt.user, primaryEventId: data.data.CreateEvent.id });
      return data;
    } catch (e) {
      var errorMessage = 'Failed to create event.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  update = async (event: GTEventForm) => {
    try {
      const startDate = event.day
        ? format(
            event.year +
              '-' +
              ('0' + event.month!.toString()).slice(-2) +
              '-' +
              ('0' + event.day!.toString()).slice(-2),
            'YYYY-MM-DD'
          )
        : this.event.startDate!;
      const response = await updateEvent({
        eventId: this.event.id!,
        name: event.name ? event.name : this.event.name!,
        start_date: startDate,
      });

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      runInAction(() => {
        if (event.name) {
          this.name = event.name;
        }
        if (event.day) {
          this.startDate = startDate;
        }
      });
    } catch (e) {
      var errorMessage = 'Failed to update event.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  createShortCode = async () => {
    try {
      const res = await createShortCode(this.id!);
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const data = await res.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      runInAction(() => {
        this.shortCode = data.data.CreateShortCode;
      });
    } catch (e) {
      var errorMessage = 'Failed to create shortcode.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  sendInviteLinkToMember = async () => {
    try {
      const res = await sendInviteLinkToMember(MemberStore.getSignedInMember()!.id!);
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const data = await res.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      if (!data.data.SendInviteLinkToMember) {
        throw new Error('Unable to send invite link. Please try again.');
      }
    } catch (e) {
      var errorMessage = 'Failed to send invite link.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  sendMemberInvites = async (emails: string) => {
    try {
      const res = await sendMemberInvites(emails, this.id!);
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const data = await res.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      if (!data.data.SendMemberInvites) {
        throw new Error('Unable to send invite link. Please try again.');
      }
    } catch (e) {
      var errorMessage = 'Failed to send member invites.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  hasOnlyEmptyLooks = (): boolean | undefined =>
    this.event && this.event.roles && this.event.roles.filter((r) => !lookIsEmpty(r)).length === 0;

  isDeletable = (): boolean => {
    const authedMemberIsCreator =
      this.event &&
      this.event.members &&
      this.event.members.find((m) => m.accountId! === window.gt.user.id) &&
      this.event.members.find((m) => m.accountId! === window.gt.user.id)!.isCreator;

    const eventIsNotInactive = this.event.status !== 'Inactive';

    const hasMembersWithOrders =
      this.event.members &&
      this.event.members.filter(
        (member) =>
          member.orders &&
          member.orders.length > 0 &&
          member.orders.filter((order: Order) =>
            order.isSwatch
              ? order.status!.trim().toUpperCase() === 'IN_PROGRESS'
              : order.status!.trim().toUpperCase() !== 'CANCELLED'
          ).length > 0
      ).length > 0;

    const hasMembersWithPayments =
      this.event.members && this.event.members.filter((member) => member.isPaid).length > 0;

    return (authedMemberIsCreator && !eventIsNotInactive && !hasMembersWithOrders && !hasMembersWithPayments) || false;
  };

  delete = async (callback?: () => void, reset = true) => {
    try {
      const response = await deleteEvent(this.event.id!);

      if (response.status !== 200) {
        throw new Error();
      }
      const json = await response.json();
      if (json.errors) {
        throw new Error(json.errors[0].message);
      }

      if (callback) callback();

      if (reset) this.reset();
    } catch (e) {
      var errorMessage = 'Failed to delete event.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  @action reset = () => {
    this.id = undefined;
    this.startDate = undefined;
    this.name = undefined;
    this.eventType = undefined;
    this.isTrial = false;
    this.role = undefined;
    this.status = undefined;
    this.lastCampaign = undefined;
    this.metadata = undefined;
    this.source = undefined;
    this.shortCode = undefined;
    this.deletedRoles = undefined;

    MemberStore.reset();
    LookStore.reset();
    PotentialMemberStore.reset();
  };
}

const singleton = new EventStore();
export default singleton;
