import { ChangeEvent, useState, forwardRef, MouseEvent, RefObject } from 'react';
import FormSelect from '../../components/FormSelect';
import FormTextArea from '../../components/FormTextArea';
import IconArrowRight from '../../components/IconArrowRight';
import IconCheck from '../../components/IconCheck';
import IconAlert from '../../components/IconAlert';
import { manualMeasurementOptions, hasAtLeastOneMeasurement, ManualMeasurements } from '../../utils/manualMeasurements';

type FormState = 'loading' | 'interactive' | 'error' | 'submitted';

type ManualMeasurementsFormProps = {
  onSubmit: (measurements: ManualMeasurements) => Promise<any>;
  onError?: (e: unknown) => any;
};

export const ManualMeasurementsForm = forwardRef<HTMLElement | null, ManualMeasurementsFormProps>(
  (props: ManualMeasurementsFormProps, ref) => {
    const { onSubmit, onError } = props;

    const [state, setState] = useState<FormState>('interactive');

    const [measurements, setMeasurements] = useState<ManualMeasurements>({
      jacketChest: null,
      jacketLength: null,
      dressPantWaist: null,
      dressPantInseam: null,
      shirtNeck: null,
      shirtSleeve: null,
      additionalNotes: null,
    });

    const inputDisabled = state === 'loading';

    const canSubmit = !inputDisabled && hasAtLeastOneMeasurement(measurements);

    const getChangeHandler = (key: keyof ManualMeasurements) => {
      return (event: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => {
        let value: string | number | null = event.currentTarget.value;

        if (value.trim() === '') {
          value = null;
        } else if (key !== 'jacketLength' && key !== 'additionalNotes') {
          value = Number(value);
        }

        setMeasurements((prevMeasurements) => ({
          ...prevMeasurements,
          [key]: value,
        }));
      };
    };

    const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
      try {
        event.preventDefault();

        setState('loading');

        await onSubmit(measurements);

        setState('submitted');
      } catch (e) {
        console.error(e);

        setState('error');

        if (onError) {
          onError(e);
        }
      }
    };

    if (state === 'submitted') {
      return (
        <div
          className="manual-measurements-form--submitted flex flex-col content-evenly items-center justify-center space-y-32 py-64"
          ref={ref as RefObject<HTMLDivElement>}
        >
          <div className="!h-128 !w-128 rounded-[50%] border-4 border-solid border-green bg-transparent">
            <IconCheck className="col-span-12 !h-128 !w-128 text-center text-green" />
          </div>

          <p className="manual-measurements-form--submitted__header text-h2-display">Thank you!</p>

          <p className="manual-measurements-form--submitted__content-success">
            Your sizes have been submitted successfully.
          </p>

          <p className="manual-measurements-form--submitted__content-resubmit">
            If you would like to modify, please submit the form again.
          </p>
        </div>
      );
    }

    return (
      <div
        className="manual-measurements-form m-auto grid space-y-32 px-32 py-32 sm:p-64 sm:py-64 xl:px-80"
        ref={ref as RefObject<HTMLDivElement>}
      >
        <div className="col-span-12">
          <header className="manual-measurements-form__header text-h2-display mb-16">Enter Your Sizes</header>

          <p className="manual-measurements-form__description text-gray-dark">
            This form is optional <br />
            Submit sizes you are confident that you know
          </p>
        </div>

        <div
          role="alert"
          className={`col-span-12 flex flex-row content-between items-center justify-start space-x-8 ${
            state !== 'error' ? 'hidden' : ''
          }`}
        >
          <IconAlert className="h-32 w-32 shrink-0 text-red" />

          <p className="manual-measurements-form__error text-gray-dark">
            There was an error while trying to submit your measurements. Please try again.
          </p>
        </div>

        <FormSelect
          className="col-span-12 lg:col-span-6 lg:mr-16"
          key="jacketChest"
          name="jacketChest"
          label="Jacket Chest"
          combineLabel={true}
          value={String(measurements.jacketChest ?? '')}
          disabled={inputDisabled}
          onChange={getChangeHandler('jacketChest')}
        >
          <option key="n/a" value=""></option>

          {manualMeasurementOptions.jacketChest.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          className="col-span-12 lg:col-span-6"
          key="jacketLength"
          name="jacketLength"
          label="Jacket Length"
          combineLabel={true}
          value={String(measurements.jacketLength ?? '')}
          disabled={inputDisabled}
          onChange={getChangeHandler('jacketLength')}
        >
          <option key="n/a" value=""></option>

          {manualMeasurementOptions.jacketLength.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          className="col-span-12"
          key="dressPantWaist"
          name="dressPantWaist"
          label="Dress Pant Waist"
          combineLabel={true}
          value={String(measurements.dressPantWaist ?? '')}
          disabled={inputDisabled}
          onChange={getChangeHandler('dressPantWaist')}
        >
          <option key="n/a" value=""></option>

          {manualMeasurementOptions.dressPantWaist.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          className="col-span-12"
          key="dressPantInseam"
          name="dressPantInseam"
          label="Dress Pant Inseam"
          combineLabel={true}
          value={String(measurements.dressPantInseam ?? '')}
          disabled={inputDisabled}
          onChange={getChangeHandler('dressPantInseam')}
        >
          <option key="n/a" value=""></option>

          {manualMeasurementOptions.dressPantInseam.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          className="col-span-12"
          key="shirtNeck"
          name="shirtNeck"
          label="Shirt Neck"
          combineLabel={true}
          value={String(measurements.shirtNeck ?? '')}
          disabled={inputDisabled}
          onChange={getChangeHandler('shirtNeck')}
        >
          <option key="n/a" value=""></option>

          {manualMeasurementOptions.shirtNeck.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          className="col-span-12"
          key="shirtSleeve"
          name="shirtSleeve"
          label="Shirt Sleeve Length"
          combineLabel={true}
          value={String(measurements.shirtSleeve ?? '')}
          disabled={inputDisabled}
          onChange={getChangeHandler('shirtSleeve')}
        >
          <option key="n/a" value=""></option>

          {manualMeasurementOptions.shirtSleeve.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </FormSelect>

        <FormTextArea
          className="col-span-12"
          key="additionalNotes"
          name="additionalNotes"
          label="Additional Fit Notes"
          combineLabel={true}
          value={String(measurements.additionalNotes ?? '')}
          disabled={inputDisabled}
          onChange={getChangeHandler('additionalNotes')}
        />

        <button
          className="manual-measurements-form__submit btn btn-info col-span-12"
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          Submit <IconArrowRight />
        </button>

        <div className="manual-measurements-form__resubmit col-span-12">
          <p className="text-gray-dark">
            If you would like to change a previous selection, please submit the form again.
          </p>
        </div>
      </div>
    );
  }
);
