import React from 'react';

const CreateLooks = () => (
  <svg width="159px" height="84px" viewBox="0 0 159 84" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Create Looks Illustration</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="create-looks-linearGradient-1">
        <stop stopColor="#4B494B" offset="0%" />
        <stop stopColor="#383638" offset="100%" />
      </linearGradient>
      <rect id="create-looks-path-2" x="80" y="11" width="38" height="38" rx="2" />
      <filter
        x="-26.3%"
        y="-13.2%"
        width="152.6%"
        height="152.6%"
        filterUnits="objectBoundingBox"
        id="create-looks-filter-3"
      >
        <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-9.5%"
        y="-27.8%"
        width="119.0%"
        height="211.1%"
        filterUnits="objectBoundingBox"
        id="create-looks-filter-4"
      >
        <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter
        x="-13.9%"
        y="-15.2%"
        width="127.8%"
        height="143.5%"
        filterUnits="objectBoundingBox"
        id="create-looks-filter-5"
      >
        <feOffset dx="0" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g id="create-looks-Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="create-looks-menguin-welcome" transform="translate(-211.000000, -376.000000)">
        <g id="create-looks-Group-5" transform="translate(217.000000, 379.000000)">
          <rect id="create-looks-Rectangle" fill="#FFFFFF" x="35" y="19" width="90" height="59" />
          <g id="create-looks-Rectangle-Copy-11">
            <use fill="black" fillOpacity="1" filter="url(#create-looks-filter-3)" xlinkHref="#create-looks-path-2" />
            <use fill="url(#create-looks-linearGradient-1)" fillRule="evenodd" xlinkHref="#create-looks-path-2" />
          </g>
          <circle id="create-looks-Oval" fill="#525254" cx="99" cy="30" r="12" />
          <g
            id="create-looks-Group-7"
            filter="url(#create-looks-filter-4)"
            transform="translate(43.000000, 53.000000)"
            fill="url(#create-looks-linearGradient-1)"
          >
            <rect
              id="create-looks-Rectangle-Copy-12"
              transform="translate(98.500000, 9.000000) scale(1, -1) translate(-98.500000, -9.000000) "
              x="92"
              y="0"
              width="13"
              height="18"
              rx="2"
            />
            <rect
              id="create-looks-Rectangle-Copy-12"
              transform="translate(80.500000, 9.000000) scale(1, -1) translate(-80.500000, -9.000000) "
              x="74"
              y="0"
              width="13"
              height="18"
              rx="2"
            />
            <rect
              id="create-looks-Rectangle-Copy-12"
              transform="translate(61.500000, 9.000000) scale(1, -1) translate(-61.500000, -9.000000) "
              x="55"
              y="0"
              width="13"
              height="18"
              rx="2"
            />
            <rect
              id="create-looks-Rectangle-Copy-12"
              transform="translate(43.500000, 9.000000) scale(1, -1) translate(-43.500000, -9.000000) "
              x="37"
              y="0"
              width="13"
              height="18"
              rx="2"
            />
            <rect
              id="create-looks-Rectangle-Copy-12"
              transform="translate(24.500000, 9.000000) scale(1, -1) translate(-24.500000, -9.000000) "
              x="18"
              y="0"
              width="13"
              height="18"
              rx="2"
            />
            <rect
              id="create-looks-Rectangle-Copy-12"
              transform="translate(6.500000, 9.000000) scale(1, -1) translate(-6.500000, -9.000000) "
              x="0"
              y="0"
              width="13"
              height="18"
              rx="2"
            />
          </g>
          <g
            id="create-looks-Group"
            filter="url(#create-looks-filter-5)"
            stroke="#00B8FF"
            strokeLinecap="round"
            strokeWidth="2"
          >
            <path
              d="M66.7213,32.2194 C69.4303,33.0624 71.3973,35.5884 71.3973,38.5744 C71.3973,42.2504 68.4173,45.2294 64.7423,45.2294 L7.1543,45.2294 C3.4793,45.2294 0.5003,42.2504 0.5003,38.5744 C0.5003,35.6144 2.4323,33.1064 5.1043,32.2414"
              id="create-looks-Stroke-1"
            />
            <path d="M35.9484,20.5202 C35.9484,18.0142 37.3334,15.8312 39.3804,14.6962" id="create-looks-Stroke-3" />
            <path
              d="M28.4494,7.9989 C28.4494,3.8569 31.8064,0.4999 35.9484,0.4999 C40.0904,0.4999 43.4474,3.8569 43.4474,7.9989 C43.4474,10.8079 41.9024,13.2569 39.6164,14.5409"
              id="create-looks-Stroke-5"
            />
            <path d="M35.9484,20.9098 L66.7914,32.2448" id="create-looks-Stroke-7" />
            <path d="M35.9484,20.9098 L5.1054,32.2448" id="create-looks-Stroke-9" />
          </g>
          <path d="M99,26 L99,34" id="create-looks-Path-2" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
          <path d="M103,30 L95,30" id="create-looks-Path-3" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
        </g>
      </g>
    </g>
  </svg>
);

export default CreateLooks;
