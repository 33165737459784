const jacketChestOptions = [34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66] as const;

const jacketLengthOptions = ['S', 'R', 'L', 'XL'] as const;

const dressPantWaistOptions = [28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62] as const;

const dressPantInseamOptions = [22, 24, 26, 28, 30, 32, 34, 36, 38] as const;

const shirtNeckOptions = [13.5, 14.5, 15.5, 16.5, 17.5, 18.5, 20, 22, 24] as const;

const shirtSleeveOptions = [31, 33, 35, 37, 39] as const;

/**
 * A map of the options that users can select for each of the measurements we collect
 * via the manual measurements form
 */
export const manualMeasurementOptions = {
  jacketChest: jacketChestOptions,
  jacketLength: jacketLengthOptions,
  dressPantWaist: dressPantWaistOptions,
  dressPantInseam: dressPantInseamOptions,
  shirtNeck: shirtNeckOptions,
  shirtSleeve: shirtSleeveOptions,
} as const;

export type JacketChest = (typeof jacketChestOptions)[number];
export type JacketLength = (typeof jacketLengthOptions)[number];
export type DressPantWaist = (typeof dressPantWaistOptions)[number];
export type DressPantInseam = (typeof dressPantInseamOptions)[number];
export type ShirtNeck = (typeof shirtNeckOptions)[number];
export type ShirtSleeve = (typeof shirtSleeveOptions)[number];

export type ManualMeasurements = {
  jacketChest: JacketChest | null;
  jacketLength: JacketLength | null;
  dressPantWaist: DressPantWaist | null;
  dressPantInseam: DressPantInseam | null;
  shirtNeck: ShirtNeck | null;
  shirtSleeve: ShirtSleeve | null;
  additionalNotes: string | null;
};

export type ManualMeasurement = keyof ManualMeasurements;

export const hasAtLeastOneMeasurement = (measurements: ManualMeasurements) => {
  const values = Object.values(measurements);

  return values.some((value) => {
    if (typeof value === 'string') {
      return value.trim() !== '';
    }

    return value !== null;
  });
};
