import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import ItemStore from '../../stores/ItemStore';
import LookStore from '../../stores/LookStore';
import PreviewStore from '../../stores/look-builder/PreviewStore';
import MemberStore from '../../stores/MemberStore';
import EventStore from '../../stores/EventStore';

import {
  EventRouteProps,
  GlobalContextTyping,
  Look,
  Bundle,
  Item,
  LookEdited,
  ItemList,
  LookAdded,
  EventWithDeletedLooks,
} from '../../types';
import { AccessContext, IsOwner } from '../../utils/HOC';
import { lookSaved, lookEditedTrack, lookAdded } from '../../utils/metrics';
import { lookHasItems, lookIsLocked } from '../../utils/looks';
import { snakeToCamel } from '../../utils/utils';
import {
  JACKET_AND_PANTS,
  SHIRT,
  TIE,
  VEST_AND_CUMMERBUND,
  POCKET_SQUARE,
  LAPEL_PIN,
  CUFFLINKS,
  BELT_AND_SUSPENDERS,
  SHOES,
  SOCKS,
} from '../../look-builder/data/categories';

import LookBuilder from '../components/LookBuilder';

export const getItemListFromLook = (look: Look) => {
  let itemList: ItemList = {};
  const categoryToKey = {
    Shirt: 'shirt',
    Tie: 'tie',
    Vest: 'vestAndCummerbund',
    Cummerbund: 'vestAndCummerbund',
    'Pocket Square': 'pocketSquare',
    Cufflinks: 'cufflinks',
    Belt: 'beltAndSuspenders',
    Suspenders: 'beltAndSuspenders',
    Shoe: 'shoes',
    Socks: 'socks',
    'Lapel Pin': 'lapelPin',
  };

  const items = look.items;
  const bundle = look?.bundles ? look.bundles[0] : null;

  if (bundle) {
    itemList['jacketAndPants'] = bundle.displayName;
  }

  items?.forEach((item: Item) => {
    const key = categoryToKey[item.category as keyof typeof categoryToKey] as keyof ItemList;

    if (key) {
      itemList[key] = item.displayName;
    }
  });
  return itemList;
};

const lookEditSaved = (look: Look, updatedLook: Look) => {
  const prevLookItems = getItemListFromLook(look!);
  const updatedLookItems = getItemListFromLook(updatedLook!);
  const isLookChanged = JSON.stringify(prevLookItems) !== JSON.stringify(updatedLookItems);

  if (!isLookChanged) {
    return;
  }

  const categories = [
    JACKET_AND_PANTS,
    SHIRT,
    TIE,
    VEST_AND_CUMMERBUND,
    POCKET_SQUARE,
    LAPEL_PIN,
    CUFFLINKS,
    BELT_AND_SUSPENDERS,
    SHOES,
    SOCKS,
  ].map((category) => snakeToCamel(category.replaceAll('-', '_')) as keyof ItemList);

  let lookEditedData: LookEdited = {
    lookId: look!.id,
    added: {} as ItemList,
    changed: {} as ItemList,
    removed: {} as ItemList,
  };

  categories.forEach((category) => {
    let isAdded = false,
      isChanged = false,
      isRemoved = false;

    //Changed
    if (category in updatedLookItems && category in prevLookItems) {
      isChanged = updatedLookItems[category] !== prevLookItems[category];

      //Added if the new item added to replace old item.
      if (isChanged) isAdded = true;
    }

    //Added when there wasn't a category
    if (!(category in prevLookItems) && category in updatedLookItems) {
      isAdded = true;
    }

    //Removed
    if (!(category in updatedLookItems) && category in prevLookItems) {
      isRemoved = true;
    }

    if (isAdded) lookEditedData.added[category] = updatedLookItems[category];
    if (isChanged) lookEditedData.changed[category] = prevLookItems[category];
    if (isRemoved) lookEditedData.removed[category] = prevLookItems[category];
  });

  lookEditedTrack(lookEditedData);
};

export const sendLookAddedData = (currentLook: Look, event: EventWithDeletedLooks) => {
  const bundle = currentLook?.bundles ? currentLook.bundles[0] : null;

  const numOfActiveLooks = event.roles?.length ?? 0;
  const numOfDeletedLooks = event.deletedRoles?.length ?? 0;
  const totalAddedLooks = numOfActiveLooks + numOfDeletedLooks ?? 0;

  const lookAddedData: LookAdded = {
    lookId: currentLook.id,
    lookName: currentLook.name ?? '',
    eventId: event.id!,
    bundleId: bundle?.id ?? null,
    isRetailBundle: bundle?.isRetail ?? null,
    currentActiveLooks: numOfActiveLooks,
    totalDeletedLooks: numOfDeletedLooks,
    totalAddedLooks: totalAddedLooks,
  };

  lookAdded(lookAddedData);
};

interface RouteParams {
  lookId: string;
}

interface Props extends EventRouteProps<RouteParams> {
  globalContext?: GlobalContextTyping;
}

const LookEdit = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [look, setLook] = useState<Look | undefined>(undefined);

  const { history, match, eventId } = props;

  useEffect(() => {
    (async () => {
      if (!ItemStore.itemsFetched) {
        try {
          await ItemStore.fetchAndCache();
        } catch (e) {
          setError('Something Went Wrong');
        }
      }

      const lookId = match.params.lookId;

      if (lookId == null) {
        history.goBack();
        return;
      }

      const look = LookStore.findLook(lookId);

      if (!look) {
        // If the look doesn't exist or if it was deleted because it was empty, go to looks page.
        history.goBack();
        return;
      }

      if (lookIsLocked(look, MemberStore.members, window.gt.user.id)) {
        return props.history.replace(`/event-flow/looks/locked/${lookId}?eventId=${props.eventId}`);
      }

      if (PreviewStore.lookPreview.filter((preview) => preview.activeItem !== null).length === 0) {
        if (look.items && look.items.length > 0) {
          PreviewStore.setProducts(look.items);
        }
        if (look.bundles && look.bundles.length > 0) {
          PreviewStore.setBundle(look.bundles[0]);
        }
      }

      setLook(look);
      PreviewStore.setLookName(look.name!);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLookItems = () => (look ? look.items! : []);
  const getLookBundles = () => (look ? look.bundles! : []);

  const getProductsToAdd = () =>
    PreviewStore.products
      .filter(
        (i: Item) =>
          getLookItems().find(
            (lookItem: Item) => i.category!.toLowerCase() === lookItem.category!.toLowerCase() && i.id === lookItem.id
          ) === undefined
      )
      .map((i) => i.id);

  const getProductsToDelete = () =>
    getLookItems()
      .filter(
        (lookItem: Item) =>
          PreviewStore.products.find(
            (i: Item) => i.category!.toLowerCase() === lookItem.category!.toLowerCase() && i.id === lookItem.id
          ) === undefined
      )
      .map((i) => i.id);

  const getBundlesToDelete = () => {
    const currentLookBundle = getLookBundles()[0];
    if (currentLookBundle) {
      const lookPreviewBundle = PreviewStore.bundle;
      if (lookPreviewBundle) {
        return currentLookBundle.id !== lookPreviewBundle.id ? currentLookBundle.id : undefined;
      }
      return currentLookBundle.id;
    }
    return undefined;
  };

  const getBundlesToAdd = () => {
    const toAdd = [PreviewStore.bundle].filter(
      (i: Bundle) => getLookBundles().find((lookItem: Item) => i && i.id === lookItem.id) === undefined
    )[0];

    return toAdd === null || toAdd === undefined ? undefined : toAdd.id;
  };

  const handleSubmit = async () => {
    if (!look) {
      throw new Error(`handleSubmit() expects a look, but none was set at call time`);
    }

    if (lookIsLocked(look, MemberStore.members, window.gt.user.id)) {
      return props.history.replace(`/event-flow/looks/locked/${look.id}?eventId=${props.eventId}`);
    }

    setIsSubmitting(true);
    setError('');

    try {
      await LookStore.updateOutfitItems(
        look.id,
        getProductsToAdd(),
        getProductsToDelete(),
        getBundlesToAdd(),
        getBundlesToDelete()
      ).then(() => {
        const updatedLook = LookStore.findLook(look.id);

        if (!updatedLook || !EventStore.event) {
          throw new Error('There was no updated look or event found.');
        }

        const lookHadItems = lookHasItems(look);
        const lookNowHasItems = lookHasItems(updatedLook);

        if (!lookHadItems && lookNowHasItems) {
          sendLookAddedData(updatedLook, EventStore.event);
        }

        lookEditSaved(look, updatedLook);
      });

      lookSaved(PreviewStore.productsAndBundle, eventId, false);
      PreviewStore.reset();
      setIsSubmitting(false);

      history.push(`/event-flow/looks?eventId=${eventId}`);
    } catch (e) {
      let errorMessage = 'Failed to update outfit items.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(e);
      setIsSubmitting(false);
      setError(errorMessage);
    }
  };

  const handleClose = () => {
    PreviewStore.reset();
    history.push(`/event-flow/looks?eventId=${eventId}`);
  };

  const builderAction = !look || !lookHasItems(look) ? 'create' : 'edit';

  return (
    <LookBuilder
      action={builderAction}
      error={error}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      displayLookCost
    />
  );
};

export default IsOwner(AccessContext(observer(LookEdit)));
