import React from 'react';

interface LineProps {}

type Props = React.FC<React.HTMLAttributes<HTMLHRElement> & LineProps>;

const Line: Props = ({ className = '', ...props }) => {
  return (
    // wrapper with my-0 prevents space-y class on parent from adding space to this element
    <div className="!my-0" {...props}>
      <hr className={`my-16 w-32 text-gray sm:my-32 ${className}`} />
    </div>
  );
};

export default Line;
