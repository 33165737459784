import React from 'react';
import { Product } from '../../types';
import { getImageUrl, getImageAlt } from '../utils';

interface SwatchProps {
  selected?: boolean;
  disabled?: boolean;
  empty?: boolean;
  swatch?: Product;
}

const renderSwatchImages = (swatch: Product, selected?: boolean, disabled?: boolean) => (
  <>
    <img
      src={getImageUrl(swatch)}
      alt={getImageAlt(swatch)}
      className="absolute top-0 block h-full w-full max-w-none object-cover"
    />
    {disabled && (
      <div className="absolute left-0 top-0 z-[300] m-4 inline-block bg-white px-8">
        <p className={`text-sm ${selected ? 'text-brand-darker' : ''}`}>Out</p>
      </div>
    )}
  </>
);

const Swatch = ({
  disabled,
  selected,
  empty,
  onClick,
  swatch,
  className,
  ...props
}: SwatchProps & React.HTMLProps<HTMLDivElement>): JSX.Element => (
  <div
    className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${!disabled && !empty ? 'group' : ''} ${
      className ? className : ''
    }`}
    style={{ lineHeight: 1 }}
    onClick={onClick}
  >
    <div className="relative pt-[100%]">
      {empty ? (
        <div className="cursor-disabled absolute top-0 h-full w-full border border-solid border-gray-dark" />
      ) : (
        <>
          <div
            className={`absolute left-0 top-0 z-[200] block h-full w-full border-[16px] border-white opacity-0 transition-opacity ${
              selected ? 'opacity-100' : ''
            }`}
          />
          <div
            className={`absolute left-0 top-0 z-[200] block h-full w-full border-8 border-white opacity-0 transition-opacity ${
              selected ? '' : 'group-hover:opacity-100'
            }`}
          />
          <div
            className={`absolute left-0 top-0 z-[200] block h-full w-full border-2 border-brand opacity-0 transition-opacity hover:opacity-100 ${
              selected ? 'opacity-100' : ''
            }`}
          />
        </>
      )}
      {!empty && swatch && renderSwatchImages(swatch, selected, disabled)}
    </div>

    <p className="text-xs mt-8 inline">{swatch ? swatch.displayName : '\u00A0'}</p>
  </div>
);

export default Swatch;
