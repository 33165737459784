import React from 'react';

interface IconXProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconXProps>;

const IconX: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>X</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.646 12.354l-8-8 .708-.708 8 8-.708.708z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.354 4.354l-8 8-.708-.707 8-8 .707.707z" />
    </svg>
  );
};

export default IconX;
