import PageHeader from '../../partials/PageHeader';
import NavBarSub from '../../partials/NavBarSub';
import MemberStore from '../../stores/MemberStore';
import { EventRouteProps, Member } from '../../types';
import { IsOwner } from '../../utils/HOC';
import { observer } from 'mobx-react';

import { isRentingOwner, isNonParticipant } from '../../utils/utils';
import Navbar from '../../partials/Navbar';
import IconCheck from '../../components/IconCheck';
import IconX from '../../components/IconX';
import { pageFadeIn, pageFadeInDelayed } from '../../utils/Component/Animations';
import { Transition } from '@headlessui/react';
import HowItWorks from '../../partials/HowItWorks';
import { checkoutStarted } from '../../utils/metrics';
import auth from '../../services/Auth';

interface Props extends EventRouteProps<any> {}

const renderOwnerName = (owner: Member) => {
  const fullName = `${owner.customer!.firstName!} ${owner.customer!.lastName!}`;
  if (fullName.toLowerCase() === owner.potentialMember!.nickname!.toLowerCase()) {
    return fullName;
  } else {
    return (
      <>
        {`${fullName}`}
        <span className="text-gray-dark">({`${owner.potentialMember!.nickname!}`})</span>
      </>
    );
  }
};

const CheckoutLink = (props: Props) => {
  return (
    <>
      <Navbar />
      <div className="container sm:mb-56">
        <div className="row">
          <div className="col-span-12 sm:col-span-10 sm:col-start-2">
            <div className="m-section mt-32 hidden sm:block">
              <NavBarSub />
            </div>

            <Transition {...pageFadeIn}>
              <PageHeader header="Checkout">
                <p>Complete the list below to checkout.</p>
              </PageHeader>
              <>
                <div className="row justify-center">
                  <div className="col-span-12 lg:col-span-7 lg:col-start-4 xl:col-span-7 xl:col-start-4">
                    <Transition {...pageFadeInDelayed}>
                      <div data-testid="eventflow-checkout" className="space-y-32 bg-white p-40 shadow-2xl sm:p-64">
                        {MemberStore.members.filter((m) => !m.isPaid && !isNonParticipant(m)).length > 0 ? (
                          <div>
                            <p className="relative mb-8 flex items-center text-green">
                              <span className="absolute -left-24">
                                <IconCheck className="text-green" />{' '}
                              </span>
                              You can checkout for:
                            </p>
                            <ul>
                              {MemberStore.members
                                .filter((m) => !m.isPaid && !isNonParticipant(m))
                                .map((member: Member, key) => (
                                  <li key={key} className="text-sm">
                                    {member.isOwner ? renderOwnerName(member) : `${member.potentialMember!.nickname!}`}
                                  </li>
                                ))}
                            </ul>
                            <p className="text-sm">
                              To pay for more people,{' '}
                              <button
                                onClick={() => props.history.push(`/event-flow/members${props.location.search}`)}
                                className="tracker-link-checkout-go_to_members-20191029-133321 text-brand-darker"
                              >
                                add them
                              </button>{' '}
                              as members,{' '}
                              <button
                                onClick={() => props.history.push(`/event-flow/assign${props.location.search}`)}
                                className="tracker-link-checkout-go_to_assign-20191029-133321 text-brand-darker"
                              >
                                assign them
                              </button>{' '}
                              to looks, and{' '}
                              <button
                                onClick={() => props.history.push(`/event-flow/invite${props.location.search}`)}
                                className="tracker-link-checkout-go_to_invite-20191029-133321 text-brand-darker"
                              >
                                invite them
                              </button>
                              .
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="relative mb-8 flex items-center text-red">
                              <span className="absolute -left-24">
                                <IconX className="text-red" />{' '}
                              </span>
                              There are no members to pay for.
                            </p>
                            <p className="text-sm">
                              Members must be{' '}
                              <button
                                onClick={() => props.history.push(`/event-flow/assign${props.location.search}`)}
                                className="tracker-link-checkout-go_to_assign-20191029-133321 text-brand-darker"
                              >
                                assigned
                              </button>{' '}
                              to a look and{' '}
                              <button
                                onClick={() => props.history.push(`/event-flow/invite${props.location.search}`)}
                                className="tracker-link-checkout-go_to_invite-20191029-133321 text-brand-darker"
                              >
                                signed up
                              </button>{' '}
                              before they can be paid for.
                            </p>
                          </div>
                        )}
                        {isRentingOwner(MemberStore.getSignedInMember()!) && (
                          <div>
                            {MemberStore.getSignedInMember()!.isMeasured! ? (
                              <>
                                <p className="relative mb-2 flex items-center text-green">
                                  <span className="absolute -left-24">
                                    <IconCheck className="text-green" />{' '}
                                  </span>
                                  Fit is complete.
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="relative mb-8 flex items-center text-red">
                                  <span className="absolute -left-24">
                                    <IconX className="text-red" />{' '}
                                  </span>
                                  Fit is incomplete.
                                </p>
                                <p className="text-sm">
                                  You must{' '}
                                  <button
                                    onClick={() => props.history.push(`/event-flow/fit${props.location.search}`)}
                                    className="tracker-link-checkout-go_to_measurements-20191029-133321 text-brand-darker"
                                  >
                                    enter your measurements
                                  </button>{' '}
                                  before you can checkout.
                                </p>
                              </>
                            )}
                          </div>
                        )}

                        <button
                          className="tracker-cta-checkout-proceed_to_checkout-20191029-135739 btn btn-info w-full"
                          onClick={() => {
                            try {
                              checkoutStarted(auth.user(), props.eventId);
                            } catch (e) {
                              console.error(e);
                            } finally {
                              // always direct the user to checkout, regardless of what happens
                              // with the track event
                              props.history.push(`/event/shipping?eventId=${props.eventId}`);
                            }
                          }}
                          disabled={
                            !(isRentingOwner(MemberStore.getSignedInMember()!)
                              ? MemberStore.getSignedInMember()!.isMeasured! &&
                                MemberStore.members.filter((m) => !m.isPaid && !isNonParticipant(m)).length > 0
                              : MemberStore.members.filter((m) => !m.isPaid && !isNonParticipant(m)).length > 0)
                          }
                        >
                          Proceed to Checkout
                        </button>
                      </div>
                    </Transition>
                  </div>
                </div>
              </>
            </Transition>
          </div>
        </div>

        <div className="pt-32 xs:pt-64 sm:hidden">
          <NavBarSub />
        </div>
        <HowItWorks className="mt-256" />
      </div>
    </>
  );
};

export default IsOwner(observer(CheckoutLink));
