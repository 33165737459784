import { Component } from 'react';
import * as parse from 'url-parse';
import { Switch, Route } from 'react-router-dom';
import { Redirect, RouteComponentProps } from 'react-router';

import MemberStore from '../stores/MemberStore';
import CustomerStore from '../stores/CustomerStore';
import EventStore from '../stores/EventStore';
import InvitedMemberFlowStore from '../stores/InvitedMemberFlowStore';
import {
  predictMeasurementsV2,
  requestMeasurements,
  getMeasurements,
  createOrUpdateMeasurements,
} from '../services/Fit';
import { convertToFtIn, getHeightInInches } from '../utils/utils';
import FormFlowLogo from '../utils/Component/FormFlowLogo';

import Spinner from '../shared/components/Spinner';
import FitAge from './components/FitAge';
import FitBuild from './components/FitBuild';
import FitHeight from './components/FitHeight';
import FitPreference from './components/FitPreference';
import FitShoe from './components/FitShoe';
import FitWaist from './components/FitWaist';
import FitWeight from './components/FitWeight';
import FitOptionalMeasurements from './components/FitOptionalMeasurements';
import FitOptionalWelcome from './components/FitOptionalWelcome';
import FitMeasuredByTailor from './components/FitMeasuredByTailor';
import FitOrderTapeMeasure from './components/FitOrderTapeMeasure';

import FitReducer from './FitReducer';

import {
  FitActions,
  FitData,
  GetMeasurementsResponse,
  RequestMeasurementsData,
  CreateOrUpdateMeasurementsData,
} from '../types';
import auth from '../services/Auth';
import { pageFadeIn } from '../utils/Component/Animations';
import { Transition } from '@headlessui/react';
import { completedFit, updatedFit } from '../utils/metrics';

interface Props extends RouteComponentProps<any> {
  eventId: string;
}

interface State {
  fit: FitData;
  loading: boolean;
  submitting: boolean;
  error: string | undefined;
}

class Fit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      submitting: false,
      error: undefined,
      // set default fit data
      fit: {
        age: '',
        armLength: '',
        buildProfile: undefined,
        buildStomach: undefined,
        chest: '',
        heightFt: undefined,
        heightIn: undefined,
        id: undefined,
        measuredByTailor: undefined,
        neck: '',
        outseam: '',
        overarm: '',
        pantWaist: '',
        preference: undefined,
        shoeSize: undefined,
        shoeType: 'M',
        stomach: '',
        waist: '',
        weight: '',
        algorithmId: undefined,
      },
    };
  }

  async componentDidMount() {
    if (!window.gt.user) {
      return this.props.history.push('/account/login');
    }
    CustomerStore.loadCustomer(auth.user());

    if (!EventStore.event.id) {
      try {
        await EventStore.loadEvent(this.props.eventId);
      } catch (e) {
        let errorMessage = 'Failed to load event.';

        if (e instanceof Error) {
          errorMessage = e.message;
        }

        throw new Error(errorMessage);
      }
    }
    await this.loadFitData();
  }

  // Loads any existing FitProfile data that the user has previously
  // entered. This data is then set to state.
  loadFitData = async () => {
    // fetch measurements
    const measurements = (await getMeasurements()) as GetMeasurementsResponse;

    // check if measurements exist
    if (!this.areMeasurementsValid(measurements)) {
      return this.entryPointRedirect();
    }

    // map new fit data to the existing structure
    const fit = {
      age: measurements.age ? measurements.age.toString() : '',
      armLength: measurements.armLength.toString(),
      buildProfile: measurements.buildProfile,
      buildStomach: measurements.stomachProfile,
      chest: measurements.chest.toString(),
      heightFt: convertToFtIn(measurements.height)[0],
      heightIn: convertToFtIn(measurements.height)[1],
      id: measurements.id,
      neck: measurements.neck.toString(),
      outseam: measurements.outseam.toString(),
      overarm: measurements.overarm.toString(),
      pantWaist: measurements.pantWaist.toString(),
      preference: measurements.fitPreference,
      shoeSize: measurements.shoeSize,
      shoeType: measurements.shoeWidth,
      stomach: measurements.stomach.toString(),
      waist: measurements.waist.toString(),
      weight: measurements.weight.toString(),
      algorithmId: measurements.algorithmId,
    };

    this.setState({
      fit,
      loading: false,
    });
  };

  areMeasurementsValid = (measurements: GetMeasurementsResponse): boolean =>
    measurements !== undefined && measurements.chest !== null;

  // The main entry point for either updating, or submitting data. This
  // function will format the data and automatically update or create as
  // needed. It will also handle the logic for optional measurements and non-
  // optional measurements.
  submit = async () => {
    this.setState({ submitting: true, error: undefined });

    try {
      const fitData = this.mapDataForSubmit(this.state.fit);
      const isAbrRequest = this.props.history.location.pathname.includes('/abr/');

      // make sure we have data
      if (!fitData) {
        alert('error');
        return this.setState({ submitting: false });
      }

      // If the user entered optional measurements dont use the ones
      // returned by the fit algorithm and therefore do not need to
      // make the request.
      if (this.useOptionalMeasurements()) {
        const request = this.state.fit;

        if (isAbrRequest) {
          request.algorithmId = 2;
        }

        await this.createOrUpdate(request);

        return this.setState({ submitting: false });
      }

      // get new measurements
      const result = isAbrRequest ? await predictMeasurementsV2(fitData) : await requestMeasurements(fitData);

      const data = await result.json();

      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors[0].message);
      }

      if (result.status !== 200) {
        throw new Error(data);
      }

      const updatedMeasurements = {
        ...this.state.fit,
        armLength: data.arm_length,
        chest: data.chest,
        neck: data.neck,
        outseam: data.outseam,
        overarm: data.overarm,
        stomach: data.stomach,
        waist: data.waist,
        algorithmId: data.algorithm_id,
      };

      // set the measurements to state
      this.setState({ fit: updatedMeasurements });

      await this.createOrUpdate(updatedMeasurements);

      InvitedMemberFlowStore.updateFitConfirmed(true);

      this.setState({ submitting: false });
    } catch (e) {
      this.setState({
        submitting: false,
        error: 'There was an issue submitting your Fit Profile. Please try again.',
      });
      console.error(e);
    }
  };

  // Places data in the correct format to be submitted, it is the same
  // format for request, create, and update.
  mapDataForSubmit = (fitData: FitData): CreateOrUpdateMeasurementsData | RequestMeasurementsData => {
    const {
      age,
      armLength,
      buildProfile,
      buildStomach,
      chest,
      heightFt,
      heightIn,
      id,
      neck,
      outseam,
      overarm,
      pantWaist,
      preference,
      shoeSize,
      shoeType,
      stomach,
      waist,
      weight,
      algorithmId,
      cxComments,
    } = fitData;

    return {
      age,
      armLength,
      buildProfile,
      chest,
      fitPreference: preference || 'modern', // fallback to modern
      height: getHeightInInches(heightFt, heightIn),
      id: id || '',
      neck,
      outseam,
      overarm,
      pantWaist,
      shoeSize: shoeSize ? shoeSize.toString() : '',
      shoeWidth: shoeType,
      stomach,
      stomachProfile: buildStomach,
      waist,
      weight,
      algorithmId: algorithmId || 1,
      cxComments: cxComments || '',
    };
  };

  requireAbrMeasurements = (): boolean => {
    const heightFt = this.state.fit.heightFt!,
      heightIn = this.state.fit.heightIn!,
      weight = parseInt(this.state.fit.weight!, 10),
      pantWaist = parseInt(this.state.fit.pantWaist!, 10);

    return (
      heightFt < 5 ||
      (heightFt === 5 && heightIn === 0) ||
      weight < 95 ||
      pantWaist < 28 ||
      heightFt > 7 ||
      (heightFt === 6 && heightIn > 5) ||
      weight > 300 ||
      pantWaist > 48
    );
  };

  // Returns a boolean determining if we should ask the user for the optional
  // measurements such as chest, overarm, sleeve, ect..
  useOptionalMeasurements = (): boolean => {
    const { age, measuredByTailor } = this.state.fit;

    if (this.props.history.location.pathname.includes('/abr/')) {
      return this.requireAbrMeasurements();
    }

    return parseInt(age, 10) <= 16 || measuredByTailor === true;
  };

  createOrUpdate = async (fitData: FitData) =>
    new Promise(async (resolve, reject) => {
      const completeFitData = this.mapDataForSubmit(fitData);
      const result = await createOrUpdateMeasurements(completeFitData);
      const data = await result.json();
      const partyRole = MemberStore.getSignedInMember()?.potentialMember?.partyRole?.name;

      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors[0].message);
      }

      if (result.status !== 200) {
        throw new Error(data);
      }

      this.setState(
        (state: State) => ({
          fit: {
            ...state.fit,
            id: data.createOrUpdateMeasurement.id,
          },
        }),
        () => resolve('')
      );

      const currentMember = MemberStore.getSignedInMember()!;

      currentMember.isMeasured ? updatedFit({ partyRole }) : completedFit({ partyRole });

      MemberStore.updateMemberIsMeasured(currentMember.id!);

      resolve(data);
    });

  // Returns the url that we will direct too once the fit flow is complete. This
  // url exists in the query params.
  getRedirectUrl = () => parse(window.location.toString(), true).query.redirect;

  // Returns true if the current user comes from the B2B program.
  isB2BReferal = (): boolean => parse(window.location.toString(), true).query.B2BReferal === 'true';

  // Called if a user enters the fit-flow with no existing data, it will
  // validate that they have entered the flow in the correct location.
  entryPointRedirect = () => {
    const { pathname } = this.props.history.location;
    // Checks to see if the user has entered the flow, with no data, at a
    // point that is not considered an entry point.
    if (
      pathname === '/fit-flow' ||
      pathname === '/fit-flow/height' ||
      pathname === '/fit-flow/hto/height' ||
      pathname === '/fit-flow/abr/height' ||
      pathname === '/fit-flow/invited/height'
    ) {
      // They have entered at an entry point.
      return this.setState({ loading: false });
    }

    this.props.history.push(`/fit-flow${this.props.location.search}`);
  };

  pathDivergenceSelection = () => {
    // Determine if we should submit or go to optional measurements flow based
    // on the fit data the user provide.
    if (this.useOptionalMeasurements()) {
      return this.props.history.push(`/fit-flow/optional/welcome${this.props.location.search}`);
    }

    // Check if the user was measured by a tailor, if they were direct them
    // to the optional measured flow. If `measuredByTailor` is set to undefined
    // it means the question has not yet been asked. This question is only
    // valid for people who come from the B2B program.
    if (this.isB2BReferal() && this.state.fit.measuredByTailor === undefined) {
      return this.props.history.push(`/fit-flow/tailor${this.props.location.search}`);
    }

    // Submit their fit profile
    this.setState({ loading: true }, () => this.submit());
  };

  reducer = (action: FitActions, changes: any) =>
    this.setState(
      (state: State) => {
        const fit = {
          ...state.fit,
          ...FitReducer(action, changes),
        };

        return { fit };
      },
      () => {
        // Specific triggers based on the action. Such as submitting.
        // Check the `pathDivergenceSelection()` for more information on
        // where the user is directed at these specific actions.
        if (action === 'MEASURED_BY_TAILOR_SET') {
          this.pathDivergenceSelection();
        }
      }
    );

  render() {
    const { loading, fit } = this.state;

    const {
      age,
      armLength,
      buildProfile,
      buildStomach,
      heightFt,
      heightIn,
      measuredByTailor,
      pantWaist,
      preference,
      shoeType,
      shoeSize,
      weight,
      neck,
      overarm,
      chest,
      stomach,
      waist,
      outseam,
    } = fit;

    return (
      <div className="container py-32">
        <FormFlowLogo />
        {loading ? (
          <Transition key={1} {...pageFadeIn}>
            <Spinner className="h-[100vh] md:h-[50vh]" type="minimal" />
          </Transition>
        ) : (
          <Transition key={2} {...pageFadeIn}>
            <Switch>
              {/**
              Begin Event Fit Flow
            */}
              <Route
                path="/fit-flow/height"
                exact={true}
                render={(props) => (
                  <FitHeight {...props} reducer={this.reducer} heightFt={heightFt} heightIn={heightIn} />
                )}
              />

              <Route
                path="/fit-flow/weight"
                exact={true}
                render={(props) => <FitWeight {...props} reducer={this.reducer} weight={weight} />}
              />

              <Route
                path="/fit-flow/age"
                exact={true}
                render={(props) => <FitAge {...props} reducer={this.reducer} age={age} />}
              />

              <Route
                path="/fit-flow/shoe"
                exact={true}
                render={(props) => (
                  <FitShoe {...props} reducer={this.reducer} shoeType={shoeType} shoeSize={shoeSize} />
                )}
              />

              <Route
                path="/fit-flow/jeanwaist"
                exact={true}
                render={(props) => (
                  <FitWaist
                    {...props}
                    reducer={this.reducer}
                    pantWaist={pantWaist}
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/build"
                exact={true}
                render={(props) => (
                  <FitBuild {...props} reducer={this.reducer} buildStomach={buildStomach} buildProfile={buildProfile} />
                )}
              />

              <Route
                path="/fit-flow/preference"
                exact={true}
                render={(props) => (
                  <FitPreference
                    {...props}
                    error={this.state.error}
                    submitting={this.state.submitting}
                    submit={this.submit}
                    reducer={this.reducer}
                    preference={preference}
                    age={age}
                  />
                )}
              />
              {/**
              End Event Fit Flow
            */}

              {/**
              Begin Event Measurement Flow
            */}
              <Route
                path="/fit-flow/optional/welcome"
                exact={true}
                render={(props) => (
                  <FitOptionalWelcome {...props} B2BReferal={this.isB2BReferal()} nextUrl="/fit-flow/optional/neck" />
                )}
              />

              <Route
                path="/fit-flow/optional/neck"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="neck"
                    {...props}
                    onChange={(neck: string) => {
                      this.reducer('NECK_SET', { neck });
                    }}
                    nextUrl="/fit-flow/optional/overarm"
                    title="Neck"
                    value={neck}
                    imageSrc="https://gentux.imgix.net/1531487302_neck.gif"
                    type="neck"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/optional/overarm"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="overarm"
                    {...props}
                    onChange={(overarm: string) => {
                      this.reducer('OVERARM_SET', { overarm });
                    }}
                    nextUrl="/fit-flow/optional/chest"
                    title="Overarm"
                    value={overarm}
                    imageSrc="https://gentux.imgix.net/1531487302_overarm.gif"
                    type="overarm"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/optional/chest"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="chest"
                    {...props}
                    onChange={(chest: string) => {
                      this.reducer('CHEST_SET', { chest });
                    }}
                    nextUrl="/fit-flow/optional/sleeve"
                    title="Chest"
                    value={chest}
                    imageSrc="https://gentux.imgix.net/1531487302_chest.gif"
                    type="chest"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/optional/sleeve"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="sleeve"
                    {...props}
                    onChange={(armLength: string) => {
                      this.reducer('ARMLENGTH_SET', { armLength });
                    }}
                    nextUrl="/fit-flow/optional/stomach"
                    title="Sleeve"
                    value={armLength}
                    imageSrc="https://gentux.imgix.net/1531487302_sleeve.gif"
                    type="sleeve"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/optional/stomach"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="stomach"
                    {...props}
                    onChange={(stomach: string) => {
                      this.reducer('STOMACH_SET', { stomach });
                    }}
                    nextUrl="/fit-flow/optional/waist"
                    title="Stomach"
                    value={stomach}
                    imageSrc="https://gentux.imgix.net/1531487302_stomach.gif"
                    type="stomach"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/optional/waist"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="waist"
                    {...props}
                    onChange={(waist: string) => {
                      this.reducer('WAIST_SET', { waist });
                    }}
                    nextUrl="/fit-flow/optional/outseam"
                    title="Waist"
                    value={waist}
                    imageSrc="https://gentux.imgix.net/1531487302_waist.gif"
                    type="waist"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/optional/outseam"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="outseam"
                    {...props}
                    onChange={(outseam: string) => {
                      this.reducer('OUTSEAM_SET', { outseam });
                    }}
                    onSubmit={this.submit}
                    title="Outseam"
                    value={outseam}
                    imageSrc="https://gentux.imgix.net/1531487302_outseam.gif"
                    type="outseam"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />
              {/**
              End Event Measurement Flow
            */}

              {/**
              Begin HTO Fit Flow
            */}
              <Route
                path="/fit-flow/hto/height"
                exact={true}
                render={(props) => (
                  <FitHeight {...props} reducer={this.reducer} heightFt={heightFt} heightIn={heightIn} />
                )}
              />

              <Route
                path="/fit-flow/hto/weight"
                exact={true}
                render={(props) => <FitWeight {...props} reducer={this.reducer} weight={weight} />}
              />

              <Route
                path="/fit-flow/hto/age"
                exact={true}
                render={(props) => <FitAge {...props} reducer={this.reducer} age={age} />}
              />

              <Route
                path="/fit-flow/hto/shoe"
                exact={true}
                render={(props) => (
                  <FitShoe {...props} reducer={this.reducer} shoeType={shoeType} shoeSize={shoeSize} />
                )}
              />

              <Route
                path="/fit-flow/hto/jeanwaist"
                exact={true}
                render={(props) => (
                  <FitWaist
                    {...props}
                    reducer={this.reducer}
                    pantWaist={pantWaist}
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/hto/build"
                exact={true}
                render={(props) => (
                  <FitBuild {...props} reducer={this.reducer} buildStomach={buildStomach} buildProfile={buildProfile} />
                )}
              />

              <Route
                path="/fit-flow/hto/preference"
                exact={true}
                render={(props) => (
                  <FitPreference
                    {...props}
                    error={this.state.error}
                    submitting={this.state.submitting}
                    submit={this.submit}
                    reducer={this.reducer}
                    preference={preference}
                    age={age}
                  />
                )}
              />
              {/**
              End HTO Fit Flow
            */}

              {/**
              Begin HTO Measurement Flow
            */}
              <Route
                path="/fit-flow/hto/welcome"
                exact={true}
                render={(props) => (
                  <FitOptionalWelcome {...props} B2BReferal={this.isB2BReferal()} nextUrl="/fit-flow/hto/neck" />
                )}
              />

              <Route
                path="/fit-flow/hto/neck"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="neck"
                    {...props}
                    onChange={(neck: string) => {
                      this.reducer('NECK_SET', { neck });
                    }}
                    nextUrl="/fit-flow/hto/overarm"
                    title="Neck"
                    value={neck}
                    imageSrc="https://gentux.imgix.net/1531487302_neck.gif"
                    type="neck"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/hto/overarm"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="overarm"
                    {...props}
                    onChange={(overarm: string) => {
                      this.reducer('OVERARM_SET', { overarm });
                    }}
                    nextUrl="/fit-flow/hto/chest"
                    title="Overarm"
                    value={overarm}
                    imageSrc="https://gentux.imgix.net/1531487302_overarm.gif"
                    type="overarm"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/hto/chest"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="chest"
                    {...props}
                    onChange={(chest: string) => {
                      this.reducer('CHEST_SET', { chest });
                    }}
                    nextUrl="/fit-flow/hto/sleeve"
                    title="Chest"
                    value={chest}
                    imageSrc="https://gentux.imgix.net/1531487302_chest.gif"
                    type="chest"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/hto/sleeve"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="sleeve"
                    {...props}
                    onChange={(armLength: string) => {
                      this.reducer('ARMLENGTH_SET', { armLength });
                    }}
                    nextUrl="/fit-flow/hto/stomach"
                    title="Sleeve"
                    value={armLength}
                    imageSrc="https://gentux.imgix.net/1531487302_sleeve.gif"
                    type="sleeve"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/hto/stomach"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="stomach"
                    {...props}
                    onChange={(stomach: string) => {
                      this.reducer('STOMACH_SET', { stomach });
                    }}
                    nextUrl="/fit-flow/hto/waist"
                    title="Stomach"
                    value={stomach}
                    imageSrc="https://gentux.imgix.net/1531487302_stomach.gif"
                    type="stomach"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/hto/waist"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="waist"
                    {...props}
                    onChange={(waist: string) => {
                      this.reducer('WAIST_SET', { waist });
                    }}
                    nextUrl="/fit-flow/hto/outseam"
                    title="Waist"
                    value={waist}
                    imageSrc="https://gentux.imgix.net/1531487302_waist.gif"
                    type="waist"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/hto/outseam"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="outseam"
                    {...props}
                    onChange={(outseam: string) => {
                      this.reducer('OUTSEAM_SET', { outseam });
                    }}
                    error={this.state.error}
                    submitting={this.state.submitting}
                    onSubmit={this.submit}
                    title="Outseam"
                    value={outseam}
                    imageSrc="https://gentux.imgix.net/1531487302_outseam.gif"
                    type="outseam"
                  />
                )}
              />
              {/**
              End HTO Measurement Flow
            */}

              {/**
              Begin Abr Fit Flow
            */}
              <Route
                path="/fit-flow/abr/height"
                exact={true}
                render={(props) => (
                  <FitHeight {...props} reducer={this.reducer} heightFt={heightFt} heightIn={heightIn} />
                )}
              />

              <Route
                path="/fit-flow/abr/weight"
                exact={true}
                render={(props) => <FitWeight {...props} reducer={this.reducer} weight={weight} />}
              />

              <Route
                path="/fit-flow/abr/jeanwaist"
                exact={true}
                render={(props) => (
                  <FitWaist
                    {...props}
                    reducer={this.reducer}
                    heightFt={heightFt}
                    heightIn={heightIn}
                    weight={weight}
                    pantWaist={pantWaist}
                    requireAbrMeasurements={this.requireAbrMeasurements}
                    error={this.state.error}
                    submitting={this.state.submitting}
                    submit={this.submit}
                  />
                )}
              />
              {/**
              Begin Abr Fit Flow
            */}

              {/**
              Begin HTO Abr Measurement Flow
            */}
              <Route
                path="/fit-flow/abr/welcome"
                exact={true}
                render={(props) => (
                  <FitOptionalWelcome {...props} B2BReferal={this.isB2BReferal()} nextUrl="/fit-flow/abr/neck" />
                )}
              />

              <Route
                path="/fit-flow/abr/neck"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="neck"
                    {...props}
                    onChange={(neck: string) => {
                      this.reducer('NECK_SET', { neck });
                    }}
                    nextUrl="/fit-flow/abr/overarm"
                    title="Neck"
                    value={neck}
                    imageSrc="https://gentux.imgix.net/1531487302_neck.gif"
                    type="neck"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/abr/overarm"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="overarm"
                    {...props}
                    onChange={(overarm: string) => {
                      this.reducer('OVERARM_SET', { overarm });
                    }}
                    nextUrl="/fit-flow/abr/chest"
                    title="Overarm"
                    value={overarm}
                    imageSrc="https://gentux.imgix.net/1531487302_overarm.gif"
                    type="overarm"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/abr/chest"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="chest"
                    {...props}
                    onChange={(chest: string) => {
                      this.reducer('CHEST_SET', { chest });
                    }}
                    nextUrl="/fit-flow/abr/sleeve"
                    title="Chest"
                    value={chest}
                    imageSrc="https://gentux.imgix.net/1531487302_chest.gif"
                    type="chest"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/abr/sleeve"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="sleeve"
                    {...props}
                    onChange={(armLength: string) => {
                      this.reducer('ARMLENGTH_SET', { armLength });
                    }}
                    nextUrl="/fit-flow/abr/stomach"
                    title="Sleeve"
                    value={armLength}
                    imageSrc="https://gentux.imgix.net/1531487302_sleeve.gif"
                    type="sleeve"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/abr/stomach"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="stomach"
                    {...props}
                    onChange={(stomach: string) => {
                      this.reducer('STOMACH_SET', { stomach });
                    }}
                    nextUrl="/fit-flow/abr/waist"
                    title="Stomach"
                    value={stomach}
                    imageSrc="https://gentux.imgix.net/1531487302_stomach.gif"
                    type="stomach"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/abr/waist"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="waist"
                    {...props}
                    onChange={(waist: string) => {
                      this.reducer('WAIST_SET', { waist });
                    }}
                    nextUrl="/fit-flow/abr/outseam"
                    title="Waist"
                    value={waist}
                    imageSrc="https://gentux.imgix.net/1531487302_waist.gif"
                    type="waist"
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/abr/outseam"
                exact={true}
                render={(props) => (
                  <FitOptionalMeasurements
                    key="outseam"
                    {...props}
                    onChange={(outseam: string) => {
                      this.reducer('OUTSEAM_SET', { outseam });
                    }}
                    error={this.state.error}
                    submitting={this.state.submitting}
                    onSubmit={this.submit}
                    title="Outseam"
                    value={outseam}
                    imageSrc="https://gentux.imgix.net/1531487302_outseam.gif"
                    type="outseam"
                  />
                )}
              />
              {/**
              End HTO Abr Measurement Flow
            */}

              {/**
              Begin Invited Member Fit Flow
            */}
              <Route
                path="/fit-flow/invited/height"
                exact={true}
                render={(props) => (
                  <FitHeight
                    {...props}
                    reducer={this.reducer}
                    heightFt={heightFt}
                    heightIn={heightIn}
                    triggerFitStartEvent
                  />
                )}
              />

              <Route
                path="/fit-flow/invited/weight"
                exact={true}
                render={(props) => <FitWeight {...props} reducer={this.reducer} weight={weight} />}
              />

              <Route
                path="/fit-flow/invited/age"
                exact={true}
                render={(props) => <FitAge {...props} reducer={this.reducer} age={age} />}
              />

              <Route
                path="/fit-flow/invited/shoe"
                exact={true}
                render={(props) => (
                  <FitShoe {...props} reducer={this.reducer} shoeType={shoeType} shoeSize={shoeSize} />
                )}
              />

              <Route
                path="/fit-flow/invited/jeanwaist"
                exact={true}
                render={(props) => (
                  <FitWaist
                    {...props}
                    reducer={this.reducer}
                    pantWaist={pantWaist}
                    error={this.state.error}
                    submitting={this.state.submitting}
                  />
                )}
              />

              <Route
                path="/fit-flow/invited/build"
                exact={true}
                render={(props) => (
                  <FitBuild {...props} reducer={this.reducer} buildStomach={buildStomach} buildProfile={buildProfile} />
                )}
              />

              <Route
                path="/fit-flow/invited/preference"
                exact={true}
                render={(props) => (
                  <FitPreference
                    {...props}
                    error={this.state.error}
                    submitting={this.state.submitting}
                    submit={this.submit}
                    reducer={this.reducer}
                    preference={preference}
                    age={age}
                  />
                )}
              />
              {/**
              End Invited Member Fit Flow
            */}

              <Route
                path="/fit-flow/tailor"
                render={(props) => (
                  <FitMeasuredByTailor {...props} reducer={this.reducer} measuredByTailor={measuredByTailor} />
                )}
              />

              <Route path="/fit-flow/ordertapemeasure" render={(props) => <FitOrderTapeMeasure {...props} />} />

              <Route
                path="/fit-flow"
                render={(props) => <Redirect to={`/fit-flow/height${props.location.search}`} />}
              />
            </Switch>
          </Transition>
        )}
      </div>
    );
  }
}

export default Fit;
