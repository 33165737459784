import { isAfter } from 'date-fns';
import { Customer, GTUser, JsonResponse, SessionUser } from '../types';
import fetchMiddleware from '../utils/fetchMiddleware';
import { authUrl } from './urls';

const auth = {
  /**
   * Start a browser session for the logged in user
   * @param response
   * @param type
   */
  signIn(response: Customer | GTUser) {
    auth.storeUserJson(response);
  },

  /**
   * Determine if the user is signed in
   * @returns {boolean}
   */
  signedIn() {
    return window.gt.user !== null;
  },

  /**
   * Determine if the user is signed out
   * @returns {boolean}
   */
  signedOut() {
    return window.gt.user === null;
  },

  /**
   * Returns the logged in user from session storage
   * @returns {*}
   */
  user() {
    return window.gt.user;
  },

  /**
   * Returns the logged in user's id
   * @returns {*}
   */
  userId() {
    return window.gt.user ? window.gt.user.id : null;
  },

  storeUserJson(response: Customer | GTUser) {
    window.gt.user = {
      id: response.id!,
      firstName: response.firstName!,
      lastName: response.lastName!,
      email: response.email!,
      phone: response.phone!,
      smsOptIn: response.smsOptIn!,
      primaryEventId: response.primaryEventId!,
      emailOptIn: response.emailOptIn!,
      state: response.state!,
      ...(response.isCx && { isCx: response.isCx }),
    };
    if (response.jwt) {
      this.setToken(response.jwt);
    }
  },

  updateLocalPrimaryEventId(id: string) {
    window.gt.user.primaryEventId = id;
  },

  setToken(jwt: string) {
    window.localStorage.setItem('jwt', jwt);
  },

  getToken() {
    return window.localStorage.getItem('jwt');
  },

  parseJwt() {
    let base64Url = this.getToken()!.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  },

  isJwtExpired() {
    if (this.getToken() !== null && this.parseJwt()) {
      if (isAfter(this.parseJwt().exp * 1000, Date.now())) {
        return false;
      }
    }
    return true;
  },
};

export const forgotPassword = async (email: string) =>
  fetchMiddleware(
    `${authUrl()}/auth/reset-password`,
    {
      method: 'POST',
      body: JSON.stringify({ email, organizationId: window.gt.orgId }),
    },
    false
  );

export const fetchSession = async (): Promise<JsonResponse<SessionUser>> =>
  fetch('/api/user/session', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  });

export default auth;
