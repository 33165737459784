import { Component } from 'react';
import { observer } from 'mobx-react';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import { AccessContext } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';
import Line from '../../components/Line';
import IconArrowRight from '../../components/IconArrowRight';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  error?: string;
  loading: boolean;
}

class Confirmation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: undefined,
      loading: false,
    };
  }

  nextPage = () => {
    this.setState({
      error: undefined,
    });

    this.props.flow!();
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-lg">
            <h2 className="text-h2-display mb-8 sm:mb-0">Your swatches are on their&nbsp;way</h2>

            <Line />

            <p className="mb-32 text-gray-dark">
              You will receive an email confirmation soon and a second email when your swatches ship with tracking
              information.&nbsp;Thanks!
            </p>

            <div className="row">
              <div className="col-span-12">
                <button
                  role="link"
                  onClick={() => this.nextPage()}
                  className="tracker-cta-swatch_confirmation-view_orders-200619-115234 btn btn-info w-full"
                >
                  View Orders <IconArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(AccessContext(observer(Confirmation)));
