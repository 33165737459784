import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import Nav from '../../look-builder/components/Nav';
import { scrollTop } from '../../utils/utils';
import Fuse from 'fuse.js';
import SwatchComponent from '../components/SwatchComponent';
import ItemStore from '../../stores/ItemStore';
import Loading from '../../utils/Component/Loading';
import { Item } from '../../types';
import IconMagnifyingGlass from '../../components/IconMagnifyingGlass';
import FormInput from '../../components/FormInput';
import { Transition } from '@headlessui/react';
import { pageFadeIn } from '../../utils/Component/Animations';

interface Props extends RouteComponentProps<any> {}

interface State {
  fuzzyMatches: Fuse.FuseResult<Item>[] | undefined;
  loading: boolean;
  searchString?: string;
}

class Browse extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fuzzyMatches: undefined,
      loading: true,
      searchString: undefined,
    };
  }

  async componentDidMount() {
    if (!ItemStore.itemsFetched) {
      try {
        await ItemStore.fetchAndCache();
      } catch (e) {
        console.error(e);
        throw Error('Something Went Wrong');
      }
    }

    this.setState({ loading: false });
  }

  search = (value: string) => {
    if (value.trim() === '') {
      return undefined;
    }

    const fuse = new Fuse(
      ItemStore.cachedItemsByCategory['Swatch (accessory)'].filter((p) => p.displayable),
      {
        includeScore: true,
        keys: [
          { name: 'displayName', weight: 1 },
          { name: 'id', weight: 1 },
          { name: 'color', weight: 1 },
          { name: 'sku', weight: 1 },
        ],
      }
    );

    const search = fuse.search(value);
    return search;
  };

  handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    scrollTop();
    document && (document.activeElement as HTMLElement).blur();

    this.setState({
      fuzzyMatches: this.search(this.state.searchString || ''),
    });
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <>
        <Nav />

        <Transition {...pageFadeIn}>
          <div className="container pb-64 pt-128">
            <div className="row">
              <div className="col-span-12 space-y-32 md:col-span-8 md:col-start-3 lg:col-start-3">
                <div>
                  <h1 className="text-h4 text-gray-dark">Color Recommendations</h1>

                  <h2 className="text-h2-display max-w-[15em]">
                    Select a color to see looks curated by our style team.
                  </h2>
                </div>

                <div className="pointer-events-auto sticky top-0 z-20 border-b border-gray-light bg-white p-32 shadow-2xl translate-z-0">
                  <div className="relative">
                    <form
                      onSubmit={(e) => {
                        this.handleSubmit(e);
                      }}
                    >
                      <div className="flex">
                        <FormInput
                          className="w-full"
                          tabIndex={1}
                          placeholder="Search for a color"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({ searchString: e.target.value })
                          }
                        />

                        <button
                          className="tracker-btn-recomendation-search-20210610-104743 btn btn-info"
                          type="submit"
                          tabIndex={2}
                        >
                          <IconMagnifyingGlass />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-16 py-64 md:gap-32">
                  {this.state.fuzzyMatches
                    ? this.state.fuzzyMatches
                        .sort((a, b) => a.score! - b.score!)
                        .map((swatch) => (
                          <div className="col-span-6 xs:col-span-4 lg:col-span-3" key={swatch.item.id}>
                            <SwatchComponent
                              to={`/customize/swatch/browse/${swatch.item.id}${this.props.history.location.search}`}
                              swatch={swatch.item}
                              linkText="Select"
                              iconRight={true}
                              className={`tracker-btn-recommendation-swatch_${swatch.item.id}-20210610-104148`}
                            />
                          </div>
                        ))
                    : ItemStore.cachedItemsByCategory['Swatch (accessory)']
                        .filter((p) => p.displayable)
                        .map((swatch) => (
                          <div className="col-span-6 xs:col-span-4 lg:col-span-3" key={swatch.id}>
                            <SwatchComponent
                              to={`/customize/swatch/browse/${swatch.id}${this.props.history.location.search}`}
                              swatch={swatch}
                              linkText="Select"
                              iconRight={true}
                              className={`tracker-btn-recommendation-swatch_${swatch.id}-20210610-104148`}
                            />
                          </div>
                        ))}
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </>
    );
  }
}

export default observer(Browse);
