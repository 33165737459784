import { useState } from 'react';
import IconLock from '../../components/IconLock';
import IconX from '../../components/IconX';

export type Participant = {
  id: string;
  nickname: string;
  isLocked: boolean;
  partyRoleName: string;
  currentLookId: string;
  isPotentialMember: boolean;
  email?: string;
};

type AssignMemberCardProps = {
  participant: Participant;
  assignLookToMember: (memberId: string, partyRoleName: string, lookId: string, isPotential: boolean) => void;
  lookId: string;
};

const AssignMemberCard = ({ assignLookToMember, participant, lookId }: AssignMemberCardProps) => {
  const { id, currentLookId, isLocked, nickname, isPotentialMember, partyRoleName } = participant;

  const [showMemberLockWarning, setShowMemberLockWarning] = useState(false);

  const isAssignedToThisLook = lookId === currentLookId;

  return (
    <div
      className={`border transition
        ${
          isAssignedToThisLook
            ? 'border-brand bg-brand'
            : `border-gray-lighter bg-gray-lighter ${!isLocked && 'cursor-pointer hover:border-brand hover:bg-white'}`
        } 
        tracker-cta-assign-assign_member-20191029-133716 mt-8 flex items-center justify-start p-8
      `}
      onClick={
        isLocked && !isAssignedToThisLook
          ? () => setShowMemberLockWarning(!showMemberLockWarning)
          : () => assignLookToMember(id, partyRoleName, lookId, isPotentialMember)
      }
    >
      {showMemberLockWarning ? (
        <div className="flex justify-between">
          <p className="text-sm">
            Members can't be moved to new looks after they have checked out. Contact our{' '}
            <a className="text-sm" href={`${process.env.REACT_APP_ECOMM_URL}/support`}>
              customer experience team
            </a>{' '}
            to make changes.
          </p>
          <div>
            <IconX />
          </div>
        </div>
      ) : (
        <>
          <div className="mr-8 flex items-center">
            {isLocked ? (
              <div className="flex w-16 justify-center">
                <IconLock />
              </div>
            ) : (
              <div
                className={`
                  relative inline-flex h-16 w-16 items-center justify-center rounded-full border after:absolute after:inset-2 after:rounded-full 
                  ${isAssignedToThisLook ? 'border-brand-darker after:bg-brand-darker' : `border-gray`}
                `}
              />
            )}
          </div>

          <span className={`${isAssignedToThisLook ? 'text-black' : 'text-gray-dark'} text-sm break-words`}>
            {nickname}
          </span>
        </>
      )}
    </div>
  );
};

export default AssignMemberCard;
