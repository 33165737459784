import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';

import { GlobalContextTyping } from '../../types';

import { validateEmailIsAvailable, validateEmail } from '../../services/Accounts';

import CustomerStore from '../../stores/CustomerStore';

import { validatePhoneArea, formatPhone } from '../../utils/utils';
import { segmentIdentify } from '../../utils/metrics';
import { AccessContext } from '../../utils/HOC';
import { pageFadeIn, pageFadeInDelayed } from '../../utils/Component/Animations';

import MyAccountWrapper from '../hoc/MyAccountWrapper';
import Line from '../../components/Line';
import Message from '../../components/Message';
import FormInput from '../../components/FormInput';
import { Transition } from '@headlessui/react';
import SignificantOtherContactInfo from './contact-info/components/SignificantOtherContactInfo';

class EmailValidationError extends Error {}

const EMAIL_IS_INVALID = 'Please provide a valid email.';
const EMAIL_IS_TAKEN = 'An account already exists with this email address.';
const EMAIL_API_ERROR = 'There has been an error validating your email. Please try again.';

interface Props extends RouteComponentProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  submitting: boolean;
  submitError: Boolean;
  submitSuccess: Boolean;
  firstNameError: Boolean;
  lastNameError: Boolean;
  phoneError: Boolean;
  phoneAreaError: Boolean;
  emailError: string;
}

class ContactInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      submitting: false,
      submitError: false,
      submitSuccess: false,
      firstNameError: false,
      lastNameError: false,
      phoneError: false,
      phoneAreaError: false,
      emailError: '',
    };
  }

  componentDidMount = () => {
    this.setState(() => ({
      firstName: CustomerStore.customer.firstName!,
      lastName: CustomerStore.customer.lastName!,
      phone: CustomerStore.customer.phone!,
      email: CustomerStore.customer.email!,
    }));
  };

  validateProfile = () => {
    const firstNameError = this.state.firstName === '';
    const lastNameError = this.state.lastName === '';
    const phoneError = this.state.phone === '' || this.state.phone.length !== 14;
    const phoneAreaError = !validatePhoneArea(this.state.phone);
    const emailError = this.state.email === '' ? EMAIL_IS_INVALID : '';

    this.setState(() => ({
      firstNameError,
      lastNameError,
      phoneError,
      phoneAreaError,
      emailError,
    }));

    return !(firstNameError || lastNameError || phoneError || phoneAreaError || emailError);
  };

  updateCustomer = async () => {
    try {
      await CustomerStore.update({
        ...CustomerStore.customer,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email,
      });

      await segmentIdentify(window.gt.user);

      this.setState(() => ({
        submitting: false,
        submitSuccess: true,
        submitError: false,
        emailError: '',
      }));
    } catch (e) {
      console.error(e);
      this.setState(() => ({ submitting: false, submitError: true }));
    }
  };

  isEmailValid = async (email: string): Promise<boolean> => {
    const res = await validateEmailIsAvailable(email);

    if (res.status === 422) {
      throw new EmailValidationError(EMAIL_IS_TAKEN);
    }

    if (res.status !== 200) {
      throw new EmailValidationError(EMAIL_API_ERROR);
    }

    const response = await validateEmail(email);

    const data = await response.json();

    if (data.errors) {
      return false;
    }

    return data.isValid;
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!this.validateProfile()) return;

    this.setState(() => ({
      submitting: true,
      submitSuccess: false,
      submitError: false,
      emailError: '',
    }));

    if (this.state.email === CustomerStore.customer.email) {
      return this.updateCustomer();
    }

    try {
      const emailIsValid = await this.isEmailValid(this.state.email);

      if (!emailIsValid) {
        throw new EmailValidationError(EMAIL_IS_INVALID);
      }

      this.updateCustomer();
    } catch (e) {
      const isValidationError = e instanceof EmailValidationError;

      const submitError = !isValidationError;
      const emailError = isValidationError ? e.message : '';

      this.setState(() => ({
        emailError,
        submitError,
        submitting: false,
      }));
    }
  };

  render() {
    return (
      <>
        <Transition {...pageFadeIn}>
          <h2 className="normal-case text-h2-display">Contact Info</h2>
          <Line />
        </Transition>

        <Transition {...pageFadeInDelayed}>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <h2 className="text-h3 normal-case mb-8">Your Info</h2>
            <div className="mb-32 bg-white p-32 shadow-2xl">
              <div className="space-y-8">
                <FormInput
                  className="w-full"
                  combineLabel
                  value={this.state.firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ firstName: e.target.value })}
                  label="First Name"
                />
                {this.state.firstNameError && <Message type="error">This field is required</Message>}
                <FormInput
                  className="w-full"
                  combineLabel
                  value={this.state.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ lastName: e.target.value })}
                  label="Last Name"
                />
                {this.state.lastNameError && <Message type="error">This field is required</Message>}
                <FormInput
                  className="w-full"
                  combineLabel
                  value={this.state.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value })}
                  label="Email"
                />
                {this.state.emailError && <Message type="error">{this.state.emailError}</Message>}
                <FormInput
                  className="w-full"
                  combineLabel
                  value={this.state.phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ phone: formatPhone(e.target.value) })
                  }
                  label="Phone"
                />
                {this.state.phoneError && <Message type="error">This field is required</Message>}
                {this.state.phoneAreaError && <Message type="error">Area codes cannot begin with "1".</Message>}
              </div>
              <button className="btn btn-info mb-8 mt-32" type="submit" disabled={this.state.submitting}>
                Save Changes
              </button>
            </div>
          </form>
        </Transition>
        {this.state.submitError && (
          <>
            <Message type="error">There was an error updating your profile.</Message>
          </>
        )}
        {this.state.submitSuccess && (
          <>
            <Message type="success">Profile updated!</Message>
          </>
        )}
        <SignificantOtherContactInfo customer={window.gt.user} />
      </>
    );
  }
}

export default observer(AccessContext(MyAccountWrapper(ContactInfo)));
