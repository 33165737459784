import React, { Component } from 'react';

// local components
import FormSingleInput from '../../utils/Component/FormSingleInput';

// types
import { FitActions, FlowRouteProps } from '../../types';

// utils
import Flow from '../../utils/HOC/Flow';

interface Props extends FlowRouteProps<any> {
  reducer: (action: FitActions, changes: any) => void;
  age: string;
}

class FitAge extends Component<Props> {
  setAge = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { reducer } = this.props;
    reducer('AGE_SET', { age: event.target.value });
  };

  onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.flow!(`${this.props.location.search}`);
  };

  render() {
    const { age } = this.props;

    return (
      <div data-testid="fitflow-age">
        <FormSingleInput
          key="fit-age"
          property="text"
          label="Age"
          type="number"
          value={age || ''}
          title="How old are you?"
          onChange={this.setAge}
          onSubmit={this.onSubmit}
          validExternally={age.length !== 0}
        />
      </div>
    );
  }
}

export default Flow(FitAge);
