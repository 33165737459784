import React from 'react';

const PlaceOrder = () => (
  <svg width="133px" height="82px" viewBox="0 0 133 82" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Place Order Illustration</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="web-welcome-" transform="translate(-861.000000, -377.000000)">
        <g id="place-orders" transform="translate(861.000000, 376.000000)">
          <path d="M49.8541,16.221 L57.7431,16.221" id="Stroke-1" stroke="#CCCCCC" />
          <polyline id="Stroke-2" stroke="#CCCCCC" points="57.7433 72.9693 23.9313 72.9693 23.9313 67.4313" />
          <path d="M84.6041,72.9693 L75.0411,72.9693" id="Stroke-3" stroke="#CCCCCC" />
          <path d="M109.5551,30.869 L109.5551,49.244" id="Stroke-4" stroke="#CCCCCC" />
          <polyline id="Stroke-5" stroke="#CCCCCC" points="75.3541 16.221 109.5551 16.221 109.5551 20.744" />
          <path d="M23.9318,34.5252 L23.9318,32.5572" id="Stroke-6" stroke="#CCCCCC" />
          <g id="Group-50" transform="translate(0.000000, 0.927500)">
            <path
              d="M86.9464,72.004 L86.9464,58.005 C86.9464,54.232 90.0054,51.173 93.7784,51.173 L124.7844,51.173 C128.5574,51.173 131.6164,54.232 131.6164,58.005 L131.6164,72.004 C131.6164,75.777 128.5574,78.836 124.7844,78.836 L93.7784,78.836 C90.0054,78.836 86.9464,75.777 86.9464,72.004"
              id="Fill-7"
              fill="#FFFFFF"
            />
            <path
              d="M86.9464,72.004 L86.9464,58.005 C86.9464,54.232 90.0054,51.173 93.7784,51.173 L124.7844,51.173 C128.5574,51.173 131.6164,54.232 131.6164,58.005 L131.6164,72.004 C131.6164,75.777 128.5574,78.836 124.7844,78.836 L93.7784,78.836 C90.0054,78.836 86.9464,75.777 86.9464,72.004 Z"
              id="Stroke-9"
              stroke="#CCCCCC"
            />
            <mask id="mask-2" fill="white">
              <polygon id="path-1" points="0 82 132.117 82 132.117 0.073 0 0.073" />
            </mask>
            <g id="Clip-12" />
            <polygon
              id="Fill-11"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="0.5 30.015 47.364 30.015 47.364 0.573 0.5 0.573"
            />
            <polygon
              id="Stroke-13"
              stroke="#CCCCCC"
              mask="url(#mask-2)"
              points="0.5 30.015 47.364 30.015 47.364 0.573 0.5 0.573"
            />
            <polygon
              id="Fill-14"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="0.5 64.798 47.364 64.798 47.364 35.356 0.5 35.356"
            />
            <polygon
              id="Stroke-15"
              stroke="#CCCCCC"
              mask="url(#mask-2)"
              points="0.5 64.798 47.364 64.798 47.364 35.356 0.5 35.356"
            />
            <path d="M16.382,15.4517 L31.481,15.4517" id="Stroke-16" stroke="#B0893A" mask="url(#mask-2)" />
            <path d="M16.382,50.077 L31.481,50.077" id="Stroke-17" stroke="#B0893A" mask="url(#mask-2)" />
            <polygon
              id="Fill-18"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="60.69 81.5 72.797 81.5 72.797 5.835 60.69 5.835"
            />
            <polygon
              id="Stroke-19"
              stroke="#CCCCCC"
              mask="url(#mask-2)"
              points="60.69 81.5 72.797 81.5 72.797 5.835 60.69 5.835"
            />
            <g id="Group-22" mask="url(#mask-2)">
              <g transform="translate(60.000000, 15.000000)">
                <path d="M0.6898,0.2935 L6.7428,0.2935" id="Fill-20" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.6898,0.2935 L6.7428,0.2935" id="Stroke-21" stroke="#CCCCCC" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-25" mask="url(#mask-2)">
              <g transform="translate(60.000000, 24.000000)">
                <path d="M0.6898,0.7515 L6.7428,0.7515" id="Fill-23" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.6898,0.7515 L6.7428,0.7515" id="Stroke-24" stroke="#CCCCCC" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-28" mask="url(#mask-2)">
              <g transform="translate(60.000000, 34.000000)">
                <path d="M0.6898,0.2095 L6.7428,0.2095" id="Fill-26" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.6898,0.2095 L6.7428,0.2095" id="Stroke-27" stroke="#CCCCCC" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-31" mask="url(#mask-2)">
              <g transform="translate(92.000000, 66.000000)">
                <path d="M0.5809,0.1295 L33.9519,0.1295" id="Fill-29" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.5809,0.1295 L33.9519,0.1295" id="Stroke-30" stroke="#B0893A" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-34" mask="url(#mask-2)">
              <g transform="translate(92.000000, 72.000000)">
                <path d="M0.5809,0.0845 L13.2799,0.0845" id="Fill-32" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.5809,0.0845 L13.2799,0.0845" id="Stroke-33" stroke="#B0893A" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-37" mask="url(#mask-2)">
              <g transform="translate(60.000000, 43.000000)">
                <path d="M0.6898,0.6676 L6.7428,0.6676" id="Fill-35" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.6898,0.6676 L6.7428,0.6676" id="Stroke-36" stroke="#CCCCCC" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-40" mask="url(#mask-2)">
              <g transform="translate(60.000000, 53.000000)">
                <path d="M0.6898,0.1258 L6.7428,0.1258" id="Fill-38" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.6898,0.1258 L6.7428,0.1258" id="Stroke-39" stroke="#CCCCCC" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-43" mask="url(#mask-2)">
              <g transform="translate(60.000000, 62.000000)">
                <path d="M0.6898,0.5838 L6.7428,0.5838" id="Fill-41" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.6898,0.5838 L6.7428,0.5838" id="Stroke-42" stroke="#CCCCCC" strokeWidth="1" fill="none" />
              </g>
            </g>
            <g id="Group-46" mask="url(#mask-2)">
              <g transform="translate(60.000000, 72.000000)">
                <path d="M0.6898,0.0418 L6.7428,0.0418" id="Fill-44" stroke="none" fill="#FFFFFF" fillRule="evenodd" />
                <path d="M0.6898,0.0418 L6.7428,0.0418" id="Stroke-45" stroke="#CCCCCC" strokeWidth="1" fill="none" />
              </g>
            </g>
            <polyline
              id="Stroke-47"
              stroke="#B0893A"
              mask="url(#mask-2)"
              points="129.0927 4.7252 99.5377 35.2712 87.5087 21.8732"
            />
            <polygon
              id="Fill-48"
              fill="#FFFFFF"
              mask="url(#mask-2)"
              points="92.249 60.855 98.912 60.855 98.912 56.459 92.249 56.459"
            />
            <polygon
              id="Stroke-49"
              stroke="#B0893A"
              mask="url(#mask-2)"
              points="92.249 60.855 98.912 60.855 98.912 56.459 92.249 56.459"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PlaceOrder;
