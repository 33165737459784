import { convertObjectToFormData } from '../utils/utils';

interface AddressValidation {
  [key: string]: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface UpdateShipmentInputType {
  id: number;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export const updateShipment = async (shipment: UpdateShipmentInputType) => {
  const r = await fetch('/api/update-shipment', {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({ shipment }),
  });

  if (r.status !== 200) {
    throw new Error('There was an issue updating this shipment. Please try again.');
  }

  const res = await r.json();
  if (res.errors) {
    throw new Error(res.errors[0].message);
  }

  return res.data;
};

export const validateAddress = async (address: AddressValidation) => {
  const r = await fetch('/api/validate-address', {
    method: 'POST',
    credentials: 'same-origin',
    body: convertObjectToFormData(address),
  });

  if (r.status !== 200) {
    throw new Error('There was an issue validating this address. Please try again.');
  }

  const res = await r.json();
  // check for request errors
  if (res.error || res.data.validatedAddress.isValid === false) {
    throw new Error(`The address you have entered may be invalid. If it looks 
          correct please contact Customer Service at 1.844.726.4889 
          and we will be happy to assist you.`);
  }

  // Pull out the address.
  const validatedAddress: AddressValidation = JSON.parse(res.data.validatedAddress.suggestedAddress);

  // Compare the address to the one that we passed in
  // to validateAddress().
  const addressChanged =
    Object.keys(validatedAddress).filter(i => (address[i] ? validatedAddress[i] !== address[i] : false)).length !== 0;

  return {
    validatedAddress: validatedAddress,
    addressChanged: addressChanged,
  };
};
