import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import OwnerFormStore from '../../stores/OwnerFormStore';
import MemberStore from '../../stores/MemberStore';
import EventStore from '../../stores/EventStore';
import PotentialMemberStore from '../../stores/PotentialMemberStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import Flow from '../../utils/HOC/Flow';
import { FlowEventRouteProps } from '../../types';
import PartyRoleStore from '../../stores/PartyRoleStore';

interface Props extends FlowEventRouteProps<{}> {}
interface State {
  lastName?: string;
  submitting: boolean;
  error?: string;
}

class OwnerLastName extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      lastName: OwnerFormStore.lastName || undefined,
      submitting: false,
      error: undefined,
    };
  }

  handleChange = (field: keyof State, value: string | boolean) => {
    this.setState((state) => ({
      ...state,
      [field]: value,
    }));
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    this.setState({ submitting: true });
    e.preventDefault();
    OwnerFormStore.update('lastName', this.state.lastName!);

    try {
      const newOwner = await MemberStore.addOwner(
        { customer: { ...OwnerFormStore.owner, isRegistrationComplete: true } },
        EventStore.event.id!
      );

      const partyRoleId = PartyRoleStore.partyRoles.find((p) => p.name.toLowerCase() === 'Not Renting'.toLowerCase())!
        .id!;

      await PotentialMemberStore.add({
        nickname: `${newOwner.customer.firstName} ${newOwner.customer.lastName}`,
        partyRoleId,
        eventId: EventStore.event!.id!,
        roleId: newOwner.role.id,
        memberId: newOwner.id,
      });

      this.setState({ submitting: false });
      this.nextPage();
    } catch (e) {
      console.error(e);
      this.setState({
        error: 'Something went wrong when adding you to this event.',
      });
    }
  };

  nextPage() {
    this.props.flow!(this.props.location.search);
  }

  render() {
    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          key="last-name"
          property="lastName"
          label="Last Name"
          type="text"
          title={`What is ${OwnerFormStore.firstName}'s last name?`}
          value={this.state.lastName || ''}
          required={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange('lastName', e.target.value)}
          onSubmit={(e) => this.handleSubmit(e)}
          loading={this.state.submitting}
          error={this.state.error ? 'There was a problem creating your account' : ''}
        />
      </>
    );
  }
}

export default Flow(observer(OwnerLastName));
