import { useEffect, useState } from 'react';

import type { FitActions, FlowRouteProps } from '../../types';
import FormSelect from '../../components/FormSelect';
import MemberStore from '../../stores/MemberStore';
import FormNavigation from '../../utils/Component/FormNavigation';
import Flow from '../../utils/HOC/Flow';
import { IsEventMember } from '../../utils/HOC/';
import { startedFit } from '../../utils/metrics';

// interfaces
type State = {
  ftSelected?: number;
  inSelected?: number;
};

type Props = FlowRouteProps<any> & {
  heightFt?: number;
  heightIn?: number;
  reducer: (action: FitActions, changes: any) => void;
  triggerFitStartEvent?: boolean;
};

// move flag for event start outside the component to prevent trigger on back button
let fitStartEvSent = false;

const FitHeight = ({ heightFt, heightIn, reducer, triggerFitStartEvent, ...props }: Props) => {
  const [state, setState] = useState<State>({ ftSelected: heightFt, inSelected: heightIn });

  const setFeet = (heightFt: Props['heightFt']) => {
    reducer('HEIGHT_SET', { heightFt, heightIn: undefined });
    setState({ ...state, ftSelected: heightFt, inSelected: undefined });
  };

  const setInches = (heightIn: Props['heightIn']) => {
    reducer('HEIGHT_SET', { heightFt: state.ftSelected, heightIn });
    setState({ ...state, inSelected: heightIn });
  };

  const onSubmit = (ev: React.SyntheticEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    props.flow!(`${props.location.search}`);
  };

  useEffect(() => {
    if (triggerFitStartEvent && !fitStartEvSent) {
      const partyRole = MemberStore.getSignedInMember()?.potentialMember?.partyRole?.name;
      startedFit({ partyRole });
      fitStartEvSent = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="fitflow-height" className="mx-auto max-w-sm">
      <h2 className="text-h2-display mb-32 text-center">How tall are you?</h2>

      <div className="flex flex-col gap-16">
        <FormSelect
          data-testid="select-height-feet"
          combineLabel
          className="grow"
          label="Feet"
          value={state.ftSelected === undefined ? '-' : state.ftSelected}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setFeet(e.target.value === '-' ? undefined : Number(e.target.value))
          }
        >
          <option value="-">-</option>
          {[3, 4, 5, 6].map((f) => (
            <option key={f} value={f} className={`tracker-btn-fit_height-${f}-200619-105338`}>
              {f}'
            </option>
          ))}
        </FormSelect>

        <FormSelect
          data-testid="select-height-inches"
          combineLabel
          className="grow"
          label="Inches"
          value={state.inSelected === undefined ? '-' : state.inSelected}
          onChange={(ev: React.ChangeEvent<HTMLSelectElement>) =>
            setInches(ev.target.value === '-' ? undefined : Number(ev.target.value))
          }
        >
          <option value="-">-</option>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
            <option key={i} value={i} className={`tracker-btn-fit_height-${i}-200619-105338`}>
              {i}"
            </option>
          ))}
        </FormSelect>
      </div>

      {/* Navigation */}
      <div className="mt-32">
        <FormNavigation next={onSubmit} disabled={state.ftSelected === undefined || state.inSelected === undefined} />
      </div>
    </div>
  );
};

export default IsEventMember(Flow(FitHeight));
