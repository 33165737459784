type Props = {
  className?: string;
};

const IconLink = ({ className }: Props) => {
  return (
    <svg
      viewBox="0 0 20 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={`h-[16px] w-[16px] stroke-current ${className ?? ''}`}
    >
      <title>Link Icon</title>

      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C5 4.34315 6.34315 3 8 3H11C12.6569 3 14 4.34315 14 6C14 7.00346 13.5073 7.89186 12.7507 8.43649C12.8605 8.77025 12.936 9.11952 12.9733 9.48018C14.1836 8.79244 15 7.49156 15 6C15 3.79086 13.2091 2 11 2H8C5.79086 2 4 3.79086 4 6C4 8.20914 5.79086 10 8 10H10C10 9.63571 9.90261 9.29417 9.73244 9H8C6.34315 9 5 7.65685 5 6Z"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0267 6.51982C1.81639 7.20756 1 8.50844 1 10C1 12.2091 2.79086 14 5 14H8C10.2091 14 12 12.2091 12 10C12 7.79086 10.2091 6 8 6H6C6 6.36429 6.09739 6.70583 6.26756 7H8C9.65685 7 11 8.34315 11 10C11 11.6569 9.65685 13 8 13H5C3.34315 13 2 11.6569 2 10C2 8.99654 2.49267 8.10815 3.24932 7.56351C3.13954 7.22975 3.06395 6.88048 3.0267 6.51982Z"
      />
    </svg>
  );
};

export default IconLink;
