import React from 'react';
import { EventRouteProps } from '../../types';
import EventStore from '../../stores/EventStore';
import MemberStore from '../../stores/MemberStore';
import CustomerStore from '../../stores/CustomerStore';
import PartyRoleStore from '../../stores/PartyRoleStore';
import Loading from '../Component/Loading';
import auth from '../../services/Auth';

interface State {
  loading: boolean;
}

const IsOwner = <P extends EventRouteProps<any>>(WrappedComponent: React.ComponentType<P>) =>
  class IsOwner extends React.Component<P, State> {
    constructor(props: P) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      if (auth.signedIn()) {
        CustomerStore.loadCustomer(auth.user());
      }

      if (!EventStore.event.id) {
        try {
          await EventStore.loadEvent(this.props.eventId);
        } catch (e) {
          var errorMessage = 'Failed to mount event store';

          if (e instanceof Error) {
            errorMessage = e.message;
          }

          if (errorMessage.includes('GtEvents matching query does not exist')) {
            return this.props.history.push(`/account/events`);
          } else {
            throw new Error(errorMessage);
          }
        }
      }

      const clonedEvent = EventStore.event.clonedEvent;
      if (!!clonedEvent) {
        return window.location.replace(`${this.props.location.pathname}?eventId=${clonedEvent.id}`);
      }

      await PartyRoleStore.loadIfEmpty();

      const member = MemberStore.getSignedInMember();

      if (member === undefined) {
        return window.location.replace('/');
      }

      if (!member.isOwner) {
        // Kick them to fit for the IsEventMember HOC to determine where the invited member should be
        return window.location.replace(
          `/fit-flow/invited/height?memberId=${member.id!}&eventId=${EventStore.event.id!}`
        );
      }

      this.setState({
        loading: false,
      });
    }

    render() {
      return this.state.loading ? <Loading /> : <WrappedComponent {...this.props} />;
    }
  };

export default IsOwner;
