import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';

import PreviewStore from '../../../../stores/look-builder/PreviewStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';

import LookPreview from './LookPreview';
import { Bundle } from '../../../../types';
import useClickOutside from '../../../../utils/hooks/useClickOutside';
import IconArrowLeft from '../../../../components/IconArrowLeft';
import IconUndo from '../../../../components/IconUndo';
import IconRedo from '../../../../components/IconRedo';
import IconTriangleUp from '../../../../components/IconTriangleUp';
import BuilderPreviewMobileStyles from './BuilderPreviewMobile.module.css';
import AnimateHeight from '../../../../components/AnimateHeight';

const ButtonIcon = ({ ...props }) => (
  <button
    {...props}
    className={`btn btn-sm pointer-events-auto grid place-items-center gap-4 transition ${
      props.className ? props.className : ''
    }`}
  >
    {props.children}
  </button>
);

interface Props {
  mediaQueryXsMatches: boolean;
  setAnimateDirection?: React.Dispatch<React.SetStateAction<string>>;
}

const BuilderPreview = (props: Props) => {
  const [prevExpanded, setPrevExapnded] = useState(false);

  const compiledImages = useRef(null);

  const sideCarCategory = SideCarStore.category;

  useClickOutside(compiledImages, () => setPrevExapnded(false));

  useEffect(() => {
    if (!sideCarCategory) {
      setPrevExapnded(false);
    }
  }, [sideCarCategory]);

  const togglePreviewExpanded = () => {
    if (!props.mediaQueryXsMatches && sideCarCategory !== null) {
      if (prevExpanded) {
        setPrevExapnded(false);
      } else {
        setPrevExapnded(true);
      }
    }
  };

  const productLayers = PreviewStore.lookPreview.reduce((acc, preview) => {
    if (!preview.activeItem) {
      return acc;
    }

    if (preview.isBundle) {
      return [...acc, ...(preview.activeItem as Bundle).products!];
    }

    return [...acc, preview.activeItem];
  }, []);

  const isUndoable = PreviewStore.history.length > 1 && PreviewStore.currentHistoryIndex !== 0;
  const isRedoable = Boolean(PreviewStore.currentHistoryIndex < PreviewStore.history.length - 1);

  return (
    <div ref={compiledImages}>
      <AnimateHeight
        open={prevExpanded}
        closedHeight={64}
        duration={300}
        easing="ease-in-out"
        className="
          pointer-events-auto
          fixed
          left-0
          right-0
          top-0
          z-50
          m-auto
          inline-block
          origin-top-right
          overflow-hidden
          bg-white
          text-center
          shadow-2xl
          will-change-transform
        "
      >
        <div className="p-16">
          <div className="flex justify-start">
            <button
              className="tracker-btn-look_builder-zoom_in-20200619-100904 btn btn-sm btn-default-outline mb-8"
              onClick={() => {
                props.setAnimateDirection && props.setAnimateDirection('right');
                window.scrollTo(0, 0);
                SideCarStore.car === 'details'
                  ? SideCarStore.set({ car: 'gallery', category: SideCarStore.category, id: null })
                  : SideCarStore.set({ car: 'list', category: null, id: null });
              }}
            >
              <IconArrowLeft /> Back
            </button>
          </div>

          <div
            className={`
            ${BuilderPreviewMobileStyles.wrapper} 
            relative
            origin-top-right
            transition
            duration-[450ms]
            ease-in-out
            will-change-transform
            ${prevExpanded ? 'translate-y-0 scale-[1]' : 'translate-y-[-48px] scale-[0.6]'}
          `}
            onTouchStart={() => {}}
          >
            <div className="flex items-center">
              {(isUndoable || isRedoable) && (
                <ButtonIcon
                  disabled={!isUndoable}
                  className={`${
                    prevExpanded ? '' : 'pointer-events-none opacity-0'
                  } undo tracker-btn-look_builder-undo_history-20200508-111100`}
                  onClick={() => PreviewStore.undoHistory()}
                >
                  <IconUndo />
                  Undo
                </ButtonIcon>
              )}

              <div
                className={`tracker-btn-look_builder-toggle_preview_image_wrapper-20200508-111100 cursor-pointer transition noMouse:active:scale-[.95]`}
                onClick={() => {
                  togglePreviewExpanded();
                }}
              >
                <LookPreview items={productLayers} />
              </div>

              {(isUndoable || isRedoable) && (
                <ButtonIcon
                  disabled={!isRedoable}
                  className={`${
                    prevExpanded ? '' : 'pointer-events-none !opacity-0'
                  } redo tracker-btn-look_builder-redo_history-20200508-111100`}
                  onClick={() => PreviewStore.redoHistory()}
                >
                  <IconRedo />
                  Redo
                </ButtonIcon>
              )}
            </div>

            <button
              className={`${
                prevExpanded ? 'opacity-100' : 'opacity-0'
              } tracker-btn-look_builder-toggle_preview_image_wrapper-20200508-111100 btn btn-sm btn-default-outline mt-8 w-full`}
              onClick={() => {
                togglePreviewExpanded();
              }}
            >
              Close <IconTriangleUp />
            </button>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default observer(BuilderPreview);
