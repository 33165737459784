import React, { useState } from 'react';

import EventStore from '../../stores/EventStore';
import MemberStore from '../../stores/MemberStore';
import PartyRoleStore from '../../stores/PartyRoleStore';

import { Member } from '../../types';
import { isNonParticipant, isRentingRole, rentingRoles, nonRentingRoles } from '../../utils/utils';
import FormSelect from '../../components/FormSelect';
import IconTrashcan from '../../components/IconTrashcan';

const renderOwnerName = (owner: Member) => {
  if (owner.customer!.firstName !== '' || owner.customer!.lastName !== '') {
    const fullName = `${owner.customer!.firstName} ${owner.customer!.lastName}`;
    if (fullName.toLowerCase() === owner.potentialMember!.nickname!.toLowerCase() || isNonParticipant(owner)) {
      return fullName;
    } else {
      return (
        <>
          {`${owner.customer!.firstName} ${owner.customer!.lastName} `}
          <span className="text-gray-dark">({owner.potentialMember!.nickname!})</span>
        </>
      );
    }
  } else {
    return owner.customer!.email;
  }
};

const DetailsOwnerCard = ({ owner }: { owner: Member }) => {
  const [error, setError] = useState<string | null>(null);
  const [tempRoleName, setTempRoleName] = useState(owner.potentialMember?.partyRole?.name);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const eventTypeId = EventStore.gtEventType?.id!;
  const partyRoles = PartyRoleStore.partyRoles;

  const deleteOwner = async () => {
    setDeleting(true);
    try {
      if (isRentingRole(partyRoles, eventTypeId, owner.potentialMember?.partyRole?.name!))
        return await MemberStore.deleteOwner(owner.id!, true);

      await MemberStore.deleteOwner(owner.id!, false);
    } catch (e) {
      console.error(e);
    }

    setDeleting(false);
    setConfirmDelete(false);
  };

  const editOwnerRole = async (newRoleName: string) => {
    setTempRoleName(newRoleName);

    const newPartyRole = partyRoles.find((p) => p.name.toLowerCase() === newRoleName.toLowerCase())!;

    try {
      await MemberStore.updateOwnerRole(owner.id!, newPartyRole.name, owner.role!.name!);
    } catch (e) {
      setError('There was a problem updating this member.');
    }
  };

  return (
    <>
      {confirmDelete ? (
        <div className="row my-24">
          <button
            className="btn btn-danger col-span-6 block w-full py-16"
            disabled={deleting}
            onClick={() => deleteOwner()}
          >
            {deleting ? 'Deleting...' : 'Delete'}
          </button>
          <button
            className="tracker-cta-event_details-cancel-20191029-133321 btn btn-default-outline col-span-6 block w-full bg-white py-16"
            disabled={deleting}
            onClick={() => setConfirmDelete(false)}
          >
            Cancel
          </button>
        </div>
      ) : (
        <div
          className="row mb-8 items-center justify-start bg-gray-light p-16"
          key={`${owner.customer!.firstName}-${owner.customer!.lastName}`}
        >
          <div className="col-span-5">
            {renderOwnerName(owner)}
            {owner.id === MemberStore.getSignedInMember()!.id && <p className="text-sm text-gray-dark">This is you.</p>}
            {error && error !== '' && <span className="text-danger text-sm">{error}</span>}
          </div>

          <div className="col-span-6">
            <FormSelect
              disabled={Boolean(owner.isPaid)}
              defaultValue={tempRoleName}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => editOwnerRole(e.target.value)}
            >
              <optgroup label="Renting">
                {rentingRoles(partyRoles, eventTypeId).map((role) => (
                  <option key={role.name} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Non-Renting">
                {nonRentingRoles(partyRoles, eventTypeId).map((role) => (
                  <option key={role.name} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </optgroup>
            </FormSelect>
          </div>
          {!Boolean(owner.isCreator) && owner.id !== MemberStore.getSignedInMember()!.id && (
            <button
              className="tracker-cta-event_details-delete_owner-20191029-133321 col-span-1"
              onClick={() => setConfirmDelete(true)}
              aria-label="Delete"
            >
              <IconTrashcan className="block" />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default DetailsOwnerCard;
