import Layout3x1 from '../components/Layout3x1';
import IllustrationDesign from '../components/IllustrationDesign';
import IllustrationFit from '../components/IllustrationFit';
import IllustrationShip from '../components/IllustrationShip';

type Props = React.HTMLAttributes<HTMLDivElement>;

const HowItWorks = ({ className }: Props): JSX.Element => {
  const displayFeature = window.gt.user && window.organization === 'gentux';

  return (
    <>
      {displayFeature && (
        <Layout3x1
          className={`m-section ${className}`}
          block1={
            <div className="mb-32 flex flex-col items-center xs:mb-0">
              <IllustrationDesign />

              <div className="mt-16 flex">
                <p className="text-sm mr-8 text-gray-dark">01</p>

                <p className="text-sm text-gray-darker">Design your suit or tuxedo in minutes.</p>
              </div>
            </div>
          }
          block2={
            <div className="mb-32 flex flex-col items-center xs:mb-0">
              <IllustrationFit />

              <div className="mt-16 flex">
                <p className="text-sm mr-8 text-gray-dark">02</p>

                <p className="text-sm text-gray-darker">Easy online fitting for you and your wedding party.</p>
              </div>
            </div>
          }
          block3={
            <div className="mb-32 flex flex-col items-center xs:mb-0">
              <IllustrationShip />

              <div className="mt-16 flex">
                <p className="text-sm mr-8 text-gray-dark">03</p>

                <p className="text-sm text-gray-darker">Get your order 14 days before your event.</p>
              </div>
            </div>
          }
        >
          <h2 className="text-h2 mb-32">How It Works</h2>
        </Layout3x1>
      )}
    </>
  );
};

export default HowItWorks;
