import { Item } from '../types';

const windowUtils = {
    updateQueryString(name: string, value: string) {
        let params = new URLSearchParams(window.location.search);
        params.set(name, value);
        return window.history.replaceState(null, '', `${getCurrentUrl()}?${params}`);
    },

    removeQueryParam(name: string) {
        let params = new URLSearchParams(window.location.search);
        params.delete(name);
        window.history.replaceState(null, '', `${getCurrentUrl()}?${params}`);
    },

    // update window query string to reflect an array of Items passed
    updateProductsAndBundleQueryString(items: Item[]) {
        const bundleIds = items.filter(i => i.category === 'preconfigured').map(b => b.id);
        const skus = items.filter(i => i.category !== 'preconfigured').map(p => p.sku);

        if (bundleIds.length > 0) {
            this.updateQueryString('bundle_ids', bundleIds.join());
        } else {
            this.removeQueryParam('bundle_ids');
        }
        if (skus.length > 0) {
            this.updateQueryString('product_skus', skus.join());
        } else {
            this.removeQueryParam('product_skus');
        }
    }
}

export const getCurrentUrl = () => {
    return window.location.origin + window.location.pathname
};

export const url = {
    param: (search: string, key: string): string | null => {
        const val = new URLSearchParams(search).get(key);
        return val !== null ? val : null;
    },
};

export const getUrlParams = () => {
    const hashes = window.location.href
        .replace('#_=_', '')
        .slice(window.location.href.indexOf('?') + 1)
        .split('&');

    return hashes.reduce((acc, hash) => {
        const split = hash.split('=');
        return {
            ...acc,
            [split[0]]: split[1],
        };
    }, {});
};

export const getParameterByName = (name: string) => {
    const urlParams: { [key: string]: string } = getUrlParams();
    if (!urlParams[name]) {
        return null;
    }
    return urlParams[name];
};

export default windowUtils;
