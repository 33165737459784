import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconVest: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-16 w-16 stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Vest Icon</title>
      <path d="M12.9034 9.3354H9.5117M10.6915.3955L7.7422 10.918v3.6393M5.3086.3955l2.6544 9.9686" />
      <path d="M13.6391 1.3449V3.481c0 2.69.7373 5.3008 1.9908 7.5951l-.0738 3.5602-15.1152-.0791-.0737-3.5602C1.6944 8.7027 2.358 6.0127 2.358 3.3228V1.1867L5.3073.3955h5.3825l2.9493.9494z" />
    </svg>
  );
};

export default IconVest;
