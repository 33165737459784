import React from 'react';

interface LogoIconProps {
  className?: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & LogoIconProps>;

const LogoIcon: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-40 w-40 fill-current text-black ${className ? className : ''}`}
    >
      <title>Generation Tux icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 0C17.907 0 0 17.91 0 40c0 22.092 17.91 40 40 40 22.092 0 40-17.91 40-40C79.975 17.917 62.08.023 40 0zm0 78.43C18.774 78.43 1.57 61.224 1.57 40S18.776 1.57 40 1.57 78.43 18.775 78.43 40C78.407 61.213 61.214 78.406 40 78.43zm10.786-20.153c0 3.81 1.407 5.744 4.044 5.744 2.872 0 4.865-2.404 5.627-6.799h.469c-.47 5.627-2.58 9.788-7.854 9.788-4.572 0-7.444-2.871-7.444-8.558v-4.055c-3.008 2.116-7.374 3.302-12.215 3.302-6.335 0-13.902-2.17-13.902-7.508 0-3.52 3.402-5.749 8.388-6.336-3.285-.351-6.394-2.11-6.394-5.689 0-2.933 2.816-4.868 5.69-5.63a9.182 9.182 0 01-4.927-8.155c0-5.279 4.047-9.56 11.38-9.56 4.164 0 7.449 1.407 9.443 3.812 1.115-1.819 2.816-4.516 5.514-4.516 2.464 0 3.813 1.29 3.813 3.343 0 2.932-2.64 3.871-4.81 3.871v-.117a2.25 2.25 0 001.056-2.112c0-1.114-.763-1.877-2.112-1.877-1.349 0-2.464 1.056-3.109 1.877a8.812 8.812 0 011.643 5.28c0 5.279-4.048 9.56-11.438 9.56a14.641 14.641 0 01-5.632-.997c-1.525.529-3.167 1.701-3.167 3.344 0 1.994 1.584 2.816 4.575 2.816l2.672-.003 3.344-.003c8.876 0 13.416-3.664 14.592-10.478h.754v9.318h9.143l-.293 1.524h-8.85v18.814zm-17.139-42.46c4.048 0 5.983 3.93 5.983 8.564s-1.935 8.564-5.983 8.564c-3.988 0-5.924-3.93-5.924-8.564 0-4.633 1.936-8.563 5.925-8.563zm-9.444 34.021c0 4.165 3.93 6.746 9.151 6.746 7.39 0 11.907-2.816 11.907-7.098 0-3.93-2.463-5.572-7.097-5.572h-6.452c-4.693 0-7.509 1.936-7.509 5.924z"
      />
    </svg>
  );
};

export default LogoIcon;
