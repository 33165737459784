export const EXPERIMENT_FIT_PAGE = 'fitPageTest' as const;
export const EXPERIMENT_FIT_PAGE_ORIGINAL = 'original' as const;
export const EXPERIMENT_FIT_PAGE_LANGUAGE_CHANGE = 'languageChange' as const;

export const EXPERIMENT_WELCOME_PACKET = 'welcomePacketTest' as const;
export const EXPERIMENT_WELCOME_PACKET_ON = 'on' as const;

export const EXPERIMENT_LOOK_BUILDER_TEST = 'lookBuilderTest' as const;
export const EXPERIMENT_LOOK_BUILDER_ORIGINAL = 'lookBuilderOriginal' as const;
export const EXPERIMENT_LOOK_BUILDER_VARIATION = 'lookBuilderVariation' as const;
