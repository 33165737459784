import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns';

import IconCalendar from '../../../../components/IconCalendar';
import IconTruck from '../../../../components/IconTruck';
import Line from '../../../../components/Line';

import { url } from '../../../../utils/window';
import { orderHasShipped } from '../../../../utils/checkout';
import { Order as OrderType, Carton } from '../../../../types';
import IconArrowRight from '../../../../components/IconArrowRight';
import { formatCurrency } from '../../../../utils/utils';

interface Props extends RouteComponentProps<any> {
  order: OrderType;
}

const showPrintLabel = (order: OrderType) =>
  order.shipment &&
  order.shipment!.cartons!.filter((carton: Carton) => carton.type === 'INBOUND' && !carton.isSwatch!).length > 0;

const showTrackPackage = (order: OrderType) =>
  order.shipment && order.shipment!.cartons!.filter((carton: Carton) => carton.type === 'OUTBOUND').length > 0;

const Order: React.FC<Props> = ({ order, history, location }) => (
  <div key={order.id} className="mb-32 bg-gray-lighter shadow-2xl">
    <div className="bg-white p-32 xs:flex xs:justify-between">
      <div className="mb-16 sm:mb-0">
        <h6 className="text-h6 text-gray-dark">{format(order.createdAt!, 'MMM D, YYYY')}</h6>
        <h4 className="normal-case text-h4-display">Order&nbsp;{order.gtuxId}</h4>
        <p className="text-gray-dark">{formatCurrency(order.total ?? 0)}</p>
      </div>

      <div className="xs:flex xs:flex-col xs:items-end">
        <h6 className="text-h6 text-gray-dark">Status</h6>

        <div>
          <p className="capitalize text-green">
            {!order.shipment || ['BLOCKED', 'CREATED'].includes(order.shipment!.status!)
              ? order.status!.replace('_', ' ').toLowerCase()
              : order.shipment!.status!.replace('_', ' ').toLowerCase()}
          </p>
        </div>
      </div>
    </div>

    <div
      className="m-auto max-w-[60%] place-items-start gap-x-16 py-32 sm:grid md:py-64"
      style={{ gridTemplateColumns: '1fr 1fr' }}
    >
      <div className="flex content-start items-center pt-4">
        <IconCalendar className="mr-8" />
        <h5 className="text-h5">Event Details</h5>
      </div>
      <div>
        <p className="text-sm text-gray-darker">{order.member!.gtEvent!.name}</p>
        <p className="text-sm text-gray-darker">{format(order.member!.gtEvent!.startDate!, 'MMM D, YYYY')}</p>
      </div>
      <div className="col-start-1 col-end-3 w-full">
        <hr className="my-16 text-gray-light md:my-32" />
      </div>
      <div className="flex content-start items-center pt-4">
        <IconTruck className="mr-8" />
        <h5 className="text-h5">Shipping Info</h5>
      </div>

      <div>
        {showPrintLabel(order) && (
          <>
            <a
              className="tracker-cta-orders-details-print_label-200819-174854 text-sm text-brand-darker"
              href={order.shipment!.cartons!.filter((carton: Carton) => carton.type === 'INBOUND')[0].labelUrl!}
              target="_blank"
              rel="noreferrer"
            >
              Print&nbsp;Label
            </a>
            <br />
          </>
        )}
        {showTrackPackage(order) && (
          <a
            className="tracker-cta-orders-details-track_package-200819-174854 text-sm text-brand-darker"
            target="_blank"
            rel="noreferrer"
            href={order.shipment!.cartons!.filter((carton: Carton) => carton.type === 'OUTBOUND')[0].trackingUrl}
          >
            Track&nbsp;Package
          </a>
        )}

        {(showPrintLabel(order) || showTrackPackage(order)) && <Line className="sm:my-16" />}

        {!!order.address ? (
          <>
            <div className="mb-8">
              {order.address.addressLine1 && <p className="text-sm text-gray-darker">{order.address.addressLine1}</p>}
              {order.address.city && order.address.state && order.address.zip && (
                <p className="text-sm text-gray-darker">
                  {order.address.city}, {order.address.state} {order.address.zip}
                </p>
              )}
            </div>

            {!orderHasShipped(order!) && (
              <button
                className="tracker-cta-orders-details-edit_shipping-200819-174854 btn text-xs btn-xs btn-info-outline bg-transparent"
                onClick={() => {
                  if (url.param(location.search, 'eventId'))
                    return history.push(`/account/orders/${order.id}${location.search}&editing=true`);
                  return history.push(`/account/orders/${order.id}${location.search}?editing=true`);
                }}
              >
                Edit Address
              </button>
            )}
          </>
        ) : (
          <a
            className="tracker-cta-orders-details-enter_shipping-200819-174854 btn text-xs btn-xs btn-info"
            href={`/fit-flow/invited/height?memberId=${order.member!.id}&eventId=${order.member!.gtEvent!.id}`}
          >
            Enter Shipping Address
          </a>
        )}
      </div>
    </div>

    <div className="bg-white p-32">
      <button
        className="tracker-cta-orders-details-200619-111519 btn btn-info w-full"
        onClick={() => history.push(`/account/orders/${order.id}${location.search}`)}
      >
        View Details <IconArrowRight />
      </button>
    </div>
  </div>
);

export default Order;
