import { observable, action, runInAction } from 'mobx';
import { Member, Look, Customer, PotentialMember } from '../types';
import {
  addMember,
  getMember,
  updateMember,
  addOwner,
  updateOwnership,
  deleteMember,
  addInvitedMember,
  updateOwnerRole,
  getMembersInEvent,
} from '../services/Events';
import LookStore from './LookStore';
import PotentialMemberStore from './PotentialMemberStore';

class MemberStore {
  @observable members: Member[] = [];

  getSignedInMember = () => this.members.find((m: Member) => m.accountId === window.gt.user.id);

  getNonSignedInMembers = () => this.members.filter((m: Member) => m.accountId !== window.gt.user.id);

  getOwners = () => this.members.filter((m: Member) => m.isOwner);

  hasMember = (id: string) => Boolean(this.members.find((m) => m.id === id));

  @action setMembers = (members: Member[]) => (this.members = members);

  @action updateMember = (member: Member) => {
    this.members = this.members.map((m: Member) => {
      if (m!.id === member.id) {
        return member;
      } else {
        return m;
      }
    });
  };

  @action updateLookOnMembers = (look: Look) =>
    (this.members = this.members.map((m: Member) => {
      if (m.role!.id === look.id) {
        return { ...m, role: look };
      } else {
        return m;
      }
    }));

  @action updatePotentialMemberOnMembers = (potentialMember: PotentialMember) => {
    this.members = this.members.map((m: Member) => {
      if (m.id === potentialMember.member!.id) {
        return { ...m, potentialMember };
      } else {
        return m;
      }
    });
  };

  @action setPotentialMemberOnMembers = (id: string, potentialMember: PotentialMember) => {
    this.members = this.members.map((m: Member) => {
      if (m.id === id) {
        return { ...m, potentialMember };
      } else {
        return m;
      }
    });
  };

  @action removePotentialMemberOnMembers = (memberId: string) => {
    this.members = this.members.map((m: Member) => {
      if (m.id === memberId) {
        return { ...m, potentialMember: null };
      } else {
        return m;
      }
    });
  };

  @action updateIsPaid = (memberId: string, isPaid: boolean) => {
    this.members = this.members.map((m: Member) => {
      if (m.id === memberId) {
        LookStore.updateMemberIsPaidOnLook(m.id, m.role!.id!, isPaid);
        return { ...m, isPaid };
      } else {
        return m;
      }
    });
  };

  addMember = async (member: Member, eventId: string, lookId: string) => {
    try {
      const results = await addMember(eventId, member, lookId);
      if (results.status !== 200) {
        throw new Error(results.statusText);
      }
      const jsonMember = (await results.json()) as Member;

      const resMember = await getMember(jsonMember.id!);
      if (resMember.status !== 200) {
        throw new Error(resMember.statusText);
      }
      const omniRes = await resMember.json();

      if (omniRes.errors) {
        throw new Error(omniRes.errors[0].message);
      }
      const fetchedMember = omniRes.data.member;

      LookStore.setMemberOnLook(fetchedMember, lookId);

      runInAction(() => {
        this.members = this.members.concat(fetchedMember);
      });
      return fetchedMember;
    } catch (e) {
      var errorMessage = 'Failed to add member.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  addInvitedMember = async (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    eventId: string,
    lookId: string,
    potentialMemberId: string
  ) => {
    try {
      const results = await addInvitedMember(firstName, lastName, email, phone, eventId, lookId, potentialMemberId);
      if (results.status !== 200) {
        throw new Error(results.statusText);
      }
      const data = await results.json();

      if (data.errors) {
        throw new Error(data.errors[0].message);
      }
      const fetchedMember = data.data.member;

      LookStore.setMemberOnLook(fetchedMember, lookId);
      PotentialMemberStore.updateMemberOnPotentialMembers(fetchedMember);

      runInAction(() => {
        this.members = this.members.concat(fetchedMember);
      });

      return fetchedMember;
    } catch (e) {
      var errorMessage = 'Failed to add invited member.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }
      throw new Error(errorMessage);
    }
  };

  addOwner = async (owner: Member, eventId: string) => {
    try {
      const results = await addOwner(owner, eventId);
      if (results.status !== 200) {
        throw new Error(results.statusText);
      }
      const jsonMember = (await results.json()) as Member;

      const resMember = await getMember(jsonMember.id!);
      if (resMember.status !== 200) {
        throw new Error(resMember.statusText);
      }
      const omniRes = await resMember.json();

      if (omniRes.errors) {
        throw new Error(omniRes.errors[0].message);
      }
      const fetchedMember = omniRes.data.member;

      // LookStore.setMemberOnLook(fetchedMember, lookId);

      runInAction(() => {
        this.members = this.members.concat(fetchedMember);
      });

      return fetchedMember;
    } catch (e) {
      var errorMessage = 'Failed to add owner.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }
      throw new Error(errorMessage);
    }
  };

  updateMemberOwnership = async (memberId: string, isOwner: boolean) => {
    try {
      const results = await updateOwnership(memberId, isOwner);
      if (results.status !== 200) {
        throw new Error(results.statusText);
      }

      runInAction(() => {
        this.members = this.members.map((m: Member) => {
          if (m.id === memberId) {
            return {
              ...m,
              isOwner,
              ...(isOwner && { isInvited: true }),
            };
          }

          return m;
        });
      });
    } catch (e) {
      var errorMessage = 'Failed to update member ownership.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }
      throw new Error(errorMessage);
    }
  };

  updateOwnerRole = async (memberId: string, partyRoleName: string, previousRoleName: string) => {
    try {
      const results = await updateOwnerRole(memberId, partyRoleName);

      if (results.status !== 200) {
        throw new Error(results.statusText);
      }

      const res = await results.json();

      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const omniResults = await getMember(res.data.UpdateOwnerRole.id);

      if (omniResults.status !== 200) {
        throw new Error(results.statusText);
      }

      const omniRes = await omniResults.json();

      if (omniRes.errors) {
        throw new Error(res.errors[0].message);
      }

      const { member }: { member: Member } = omniRes.data;

      this.updateMember(member);
      PotentialMemberStore.updateRoleAndPartyRoleOnPotentialMembers(member);

      LookStore.setMemberOnLook(member, member.role?.id!);
      LookStore.setPotentialMemberOnLook(member.potentialMember!, member.role?.id!);

      const previousRole = LookStore.findLookByName(previousRoleName);

      if (previousRole && previousRole.id) {
        LookStore.removeMemberFromLook(member.id!, previousRole.id);
        LookStore.removePotentialMemberFromLook(member.potentialMember?.id!, previousRole.id);
      }

      return member;
    } catch (e) {
      var errorMessage = 'Failed to update owner role.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  @action
  updateMemberIsMeasured = (memberId: string) => {
    this.members = this.members.map((m: Member) => {
      if (m.id === memberId) {
        return {
          ...m,
          isMeasured: true,
        };
      } else {
        return m;
      }
    });
  };

  updateMemberLook = async (member: Member, look: Look) => {
    try {
      const results = await updateMember(member.id!, look.id!);
      if (results.status !== 200) {
        throw new Error(results.statusText);
      }

      LookStore.removeMemberFromLook(member.id!, member.role!.id!);
      LookStore.setMemberOnLook(member, look.id!);

      runInAction(() => {
        this.members = this.members.map((m: Member) => {
          if (m.id === member.id!) {
            return { ...m, role: look };
          } else {
            return m;
          }
        });
      });
    } catch (e) {
      var errorMessage = 'Failed to update member look.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  @action
  updateMemberCustomer = (customer: Customer) => {
    this.members = this.members.map((m: Member) => {
      if (m.customer!.id === customer.id) {
        return { ...m, customer: customer };
      } else {
        return m;
      }
    });
  };

  delete = async (memberId: string) => {
    try {
      const results = await deleteMember(memberId);
      PotentialMemberStore.removePotentialMember(this.members.find((m) => m.id === memberId)!.potentialMember!.id!);

      if (results.status !== 200) {
        throw new Error(results.statusText);
      }
      const data = await results.json();
      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      runInAction(() => {
        this.members = this.members.filter((m: Member) => m.id !== memberId);
      });
    } catch (e) {
      var errorMessage = 'Failed to delete member.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(e);
      throw new Error(errorMessage);
    }
  };

  deleteOwner = async (id: string, isRenting: boolean) => {
    try {
      await this.updateMemberOwnership(id, false);
      if (isRenting) return; // do not delete member, just ownership
      await this.delete(id);
    } catch (e) {
      var errorMessage = 'Failed to delete owner.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(e);
      throw new Error(errorMessage);
    }
  };

  @action reset = () => (this.members = []);

  getLatestMembers = async (eventId: string) => {
    const res = await getMembersInEvent(eventId);

    if (res.status !== 200) {
      throw new Error(res.statusText);
    }

    const jsonMembers = await res.json();

    if (jsonMembers.errors && jsonMembers.errors > 0) {
      throw new Error(`Failed to get latest members: ${JSON.stringify(jsonMembers.errors)}`);
    }

    const members = jsonMembers.data.event.members as Member[];

    return members;
  };
}

const singleton = new MemberStore();
export default singleton;
