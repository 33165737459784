import { observable, action, runInAction } from 'mobx';
import { PartyRole } from '../types';
import { getPartyRoles } from '../services/Events';

class PartyRoleStore {
  @observable partyRoles: PartyRole[] = [];

  @action set = (partyRoles: PartyRole[]) => (this.partyRoles = partyRoles);

  load = async () => {
    const res = await getPartyRoles();
    if (res.status !== 200) {
      throw new Error();
    }
    const prjson = await res.json();
    if (prjson.errors) {
      throw new Error(prjson.errors[0].message);
    }

    runInAction(() => {
      this.partyRoles = prjson.data.partyRoles;
    });
  };

  loadIfEmpty = async () => {
    if (this.partyRoles.length > 0) {
      return;
    }

    return await this.load();
  };

  find = (id: number) => this.partyRoles.find((p) => p.id === id);

  getRolesByEventType = (eventType: string) => {
    return this.partyRoles.filter((p) => p.gtEventType?.name?.toLowerCase() === eventType.toLowerCase());
  };

  findByName = (name: string, eventType?: string) =>
    this.partyRoles.find(
      (p) =>
        p.name.toLowerCase() === name.toLowerCase() &&
        (!eventType || p.gtEventType.name?.toLowerCase() === eventType.toLowerCase())
    );
}

export default new PartyRoleStore();
