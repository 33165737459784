import React from 'react';

interface SpinnerProps {
  type?: 'minimal' | 'standard';
}

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & SpinnerProps>;

const animationTypes = {
  minimal: ['animate-none', 'animate-pulse'],
  standard: ['animate-rotate', 'animate-none'],
};

const Spinner: Props = ({ children = null, type = 'minimal', className = '' }) => (
  <div className={`flex items-center justify-center ${className ? className : 'h-[80vh]'}`}>
    <div>
      <div className="table h-full w-full">
        <div
          className={`table-cell origin-center text-center align-middle ${animationTypes[type][0]}`}
          style={{ padding: '15px' }}
        >
          <div className="relative inline-block h-24" style={{ width: '36px' }}>
            <div
              className={`absolute left-0 top-1/2 inline-block h-0 w-0 -translate-y-1/2 transform ${animationTypes[type][1]}`}
              style={{
                borderLeft: '18px solid black',
                borderTop: '12px solid transparent',
                borderBottom: '12px solid transparent',
              }}
            />
            <div
              className={`absolute right-0 top-1/2 inline-block h-0 w-0 -translate-y-1/2 transform ${animationTypes[type][1]}`}
              style={{
                animationDelay: '.5s',
                borderRight: '18px solid black',
                borderTop: '12px solid transparent',
                borderBottom: '12px solid transparent',
              }}
            />
          </div>
        </div>
      </div>

      {children}
    </div>
  </div>
);

export default Spinner;
