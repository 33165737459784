import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import LookStore from '../../stores/LookStore';
import PreviewStore from '../../stores/look-builder/PreviewStore';

import { IsOwner } from '../../utils/HOC';
import { EventRouteProps, Look } from '../../types';

import LookPreview from '../../look-builder/components/look-builder/preview/LookPreview';
import Spinner from '../../shared/components/Spinner';
import IconLock from '../../components/IconLock';
import IconArrowLeft from '../../components/IconArrowLeft';

interface RouteParams {
  lookId: string;
}

interface Props extends EventRouteProps<RouteParams> {}

const LookIsLocked = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [look, setLook] = useState<Look | undefined>(undefined);

  const { history, match } = props;

  useEffect(() => {
    (async () => {
      const lookId = match.params.lookId;

      if (lookId == null) {
        history.goBack();
        return;
      }

      const look = LookStore.findLook(lookId);

      if (!look) {
        history.goBack();
        return;
      }

      if (PreviewStore.lookPreview.filter((preview) => preview.activeItem !== null).length === 0) {
        if (look.bundles && look.bundles.length > 0) {
          PreviewStore.setBundle(look.bundles[0]);
        }
        if (look.items && look.items.length > 0) {
          PreviewStore.setProducts(look.items);
        }
      }
      setLook(look);

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    PreviewStore.reset();
    history.push(`/event-flow/looks${props.location.search}`);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="bg-gray-lighter xs:py-32 md:py-64">
      <div className="container">
        <div className="row">
          <div className="col-span-12 py-16 xs:col-span-6 xs:py-0 md:col-span-5 lg:col-span-6">
            <LookPreview items={PreviewStore.productsAndBundle} />
          </div>
          <div className="col-span-12 xs:col-span-6 lg:col-span-5">
            <div>
              <h3 className="text-h3 flex items-center gap-x-4">
                {look!.name}
                <span className="text-gray-dark">-</span>
                <span className="text-gray-dark">
                  $
                  {PreviewStore.productsAndBundle.reduce(
                    (identity, item) =>
                      identity + (Number.isNaN(parseInt(item.cost!, 10)) ? 0 : parseInt(item.cost!, 10)),
                    0
                  )}
                </span>
              </h3>
              <div className="border-grayDark my-32 border bg-white p-16 xs:p-32">
                <h4 className="text-h4 mb-8 flex items-center">
                  <IconLock className="mr-4" /> Locked
                </h4>
                <p>
                  This look cannot be changed because you or an attendee has paid. Please contact{' '}
                  <a
                    href={`${window.location.protocol}//${window.location.host}/support`}
                    className="tracker-link-locked_look-support-200511-123129"
                  >
                    Customer Experience
                  </a>{' '}
                  for more&nbsp;info.
                </p>
              </div>
              {PreviewStore.productsAndBundle.map((item) => (
                <div key={`${item.id}-${item.name}`} className="mb-8">
                  <p className="text-sm text-gray-dark">
                    {item.displayName} — {`$${item.cost}`}
                  </p>
                </div>
              ))}

              <button
                type="submit"
                onClick={handleClose}
                className="tracker-cta-locked_look-back-200619-111519 btn btn-info mt-32 w-full"
              >
                <IconArrowLeft /> Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsOwner(observer(LookIsLocked));
