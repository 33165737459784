export class CommunicationPreference {
  constructor(
    public readonly medium: MessageMedium,
    public readonly type: MessageType,
    protected _subscribed: boolean,
  ) {}

  get subscribed() {
    return this._subscribed;
  }

  toggle() {
    this._subscribed = !this._subscribed;
  }
}

export enum MessageMedium {
  SMS = 'sms',
  Email = 'email',
}

export enum MessageType {
  Updates = 'transactional',
  Promos = 'marketing',
}

export type ByMedium<T = {}> = {[key in MessageMedium]: T};

export type ByMessageType<T = {}> = {[key in MessageType]: T};

export type ByPreference<T = {}> = ByMedium<ByMessageType<T>>;

export type IsSubscribedByMessageType = ByMessageType<boolean>;

export type CommunicationPreferencesByMedium = ByMedium<IsSubscribedByMessageType>;

export const isSmsChannel = (channel: CommunicationPreference) =>
  channel.medium === MessageMedium.SMS;

export const isEmailChannel = (channel: CommunicationPreference) =>
  channel.medium === MessageMedium.Email;

export const isTransactionalChannel = (channel: CommunicationPreference) =>
  channel.type === MessageType.Updates;

export const isMarketingChannel = (channel: CommunicationPreference) =>
  channel.type === MessageType.Promos;

export const isSmsMarketing = (channel: CommunicationPreference) =>
  isSmsChannel(channel) && isMarketingChannel(channel);

export const isSmsTransactional = (channel: CommunicationPreference) =>
  isSmsChannel(channel) && isTransactionalChannel(channel);

export const isEmailMarketing = (channel: CommunicationPreference) =>
  isEmailChannel(channel) && isMarketingChannel(channel);

export const isEmailTransactional = (channel: CommunicationPreference) =>
  isEmailChannel(channel) && isTransactionalChannel(channel);

export class CommunicationPreferences {
  protected preferences: CommunicationPreference[];

  constructor(preferences: CommunicationPreferencesByMedium) {
    this.preferences = getPreferencesAsArray(preferences);
  }

  getEmailMarketing() {
    return this.preferences.find(preference => isEmailMarketing(preference))!;
  }

  getEmailTransactional() {
    return this.preferences.find(preference => isEmailTransactional(preference))!;
  }

  getSmsMarketing() {
    return this.preferences.find(preference => isSmsMarketing(preference))!;
  }

  getSmsTransactional() {
    return this.preferences.find(preference => isSmsTransactional(preference))!;
  }

  asObject() {
    return getPreferencesAsObject(this.preferences);
  }

  asArray() {
    return this.preferences;
  }
}

export const getPreferencesAsArray = (
  preferences: CommunicationPreferencesByMedium
): CommunicationPreference[] => {
  return Object.entries(preferences).flatMap(
    (entry: [MessageMedium, IsSubscribedByMessageType]) => {
      const [medium, preferencesByType] = entry;

      return Object.entries(preferencesByType).map((e: [MessageType, boolean]) => {
        const [type, subscribed] = e;

        return new CommunicationPreference(medium, type, subscribed);
      });
    }
  );
};

export const getPreferencesAsObject = (
  preferences: CommunicationPreference[]
): CommunicationPreferencesByMedium => {
  return preferences.reduce((acc, preference) => {
    const byType = acc[preference.medium] ?? {};

    return {
      ...acc,
      [preference.medium]: {
        ...byType,
        [preference.type]: preference.subscribed,
      },
    };
  }, {} as CommunicationPreferencesByMedium);
}

/**
 * Mainly used as a stub / fallback value, like when we have a component that
 * stores an instance of this class in state and don't want to deal with null
 */
export const getUnsubscribedFromAll = () =>
  new CommunicationPreferences({
    [MessageMedium.SMS]: {
      [MessageType.Promos]: false,
      [MessageType.Updates]: false,
    },
    [MessageMedium.Email]: {
      [MessageType.Promos]: false,
      [MessageType.Updates]: false,
    },
  });

/**
 * The communication preferences we expect a customer to have at sign up, after
 * they've been opted out of our optional channels
 */
export const getUnsubscribedFromOptional = () =>
  new CommunicationPreferences({
    [MessageMedium.SMS]: {
      [MessageType.Promos]: false,
      [MessageType.Updates]: false,
    },
    [MessageMedium.Email]: {
      [MessageType.Promos]: true,
      [MessageType.Updates]: true,
    },
  });
