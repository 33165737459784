import { Item } from '../../../../types';
import { lookLayers, previewLayerNames } from '../../../../look-builder/utils/utils';
import PreviewLayer from './PreviewLayer';
import PlaceholderImage from './PlaceholderImage';
import './LookPreview.css';

interface Props {
  items: Item[];
}

const LookPreview = ({ items }: Props) => {
  const layers = lookLayers(items);

  return (
    <div style={{ position: 'relative', zIndex: 0 }}>
      <div className={`LookPreview ${previewLayerNames(layers)}`}>
        <PlaceholderImage />
        {layers.length > 0 ? (
          layers.map((product: Item) => (
            <PreviewLayer key={product.id} sideCarCategory={product.type!} product={product} previewProducts={layers} />
          ))
        ) : (
          <div>
            <img
              loading="lazy"
              src="https://gentux.imgix.net/1591704711_200608-151436-all.png"
              alt="empty look"
              className="preview-illustrated !opacity-60"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LookPreview;
