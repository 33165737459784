export const gtagHasLoaded = () => typeof window.gtag === 'function';

/**
 * Resolves to a boolean which indicates that Google Tag Manager was able to load
 * within the expected amount of time, or rejects with an error message
 *
 * This function will call itself again after 500ms if `gtag()` is undefined, up
 * to five times
 */
export const waitUntilGtagHasLoaded = (attempt = 1) => {
  return new Promise<boolean>((resolve, reject) => {
    if (gtagHasLoaded()) {
      return resolve(true);
    }

    if (attempt > 5) {
      return reject("Rejecting because Google Tag Manager hasn't loaded within the expected amount of time (~2.5s)");
    }

    setTimeout(() => {
      waitUntilGtagHasLoaded(attempt + 1).then(resolve, reject);
    }, 500);
  });
};
