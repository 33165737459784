import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconJacket: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-16 w-[18px] stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Jacket Icon</title>
      <path d="M12.5 2L17 4L16 15H1.99999L1 4L5.5 2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 1L9 14L12 1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 10H11.5" strokeLinecap="round" />
      <path d="M14 5L9 15L4 5L6 1H12L14 5Z" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconJacket;
