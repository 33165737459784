import React from 'react';

interface IconSwatchProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconSwatchProps>;

const IconSwatch: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Swatch card</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M11 1H1v12h10V1zM0 0v14h12V0H0z" />
      <path d="M2 4h8v8H2V4zM2 2h6v1H2V2z" />
    </svg>
  );
};

export default IconSwatch;
