import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { Member, Item } from '../../../../types';
import EventStore from '../../../../stores/EventStore';
import { getInvitedMemberDetails } from '../../../../services/Events';
import { buildCompositeImageFromSkus } from '../../../../utils/metrics';
import Spinner from '../../../../shared/components/Spinner';

interface Props extends RouteComponentProps<any> {
  id: string;
  name: string;
  startDate: string;
  primaryEvent: boolean;
  isOwner: boolean;
  memberId: string;
}

interface SpinnerProps {
  className?: string;
}

type BundleProduct = {
  category: string;
  sku: string;
};

type ProductItem = Item & {
  products?: Array<BundleProduct>;
};

//We get certain skus of the look for just the suit, tie, pocket square, shirt and vest to only show in the card.
const getSkus = async (eventId: string, memberId: string) => {
  const json = await getInvitedMemberDetails(eventId).then((res) => {
    if (res.status !== 200) {
      throw new Error('Unable to retrieve event information');
    }

    return res.json();
  });

  const member = json.event.members.find((m: Member) => m.id === memberId);

  const skus = member.role.bundles
    .concat(member.role.items)
    .map((item: ProductItem) => {
      const categoryText =
        item.category === 'preconfigured' && item?.products ? getBundleJacketCategory(item.products) : item.category;
      const category = categoryText ? categoryText.toLowerCase() : '';

      if (category && ['jacket', 'shirt', 'tie', 'vest', 'pocket square'].includes(category)) {
        const sku = category === 'jacket' && item?.products ? getBundleJacketSku(item.products) : item.sku;
        return sku;
      }

      return '';
    })
    .filter((sku: string) => sku);

  return skus;
};

const getBundleJacketCategory = (bundleProducts: Array<BundleProduct>) => {
  return bundleProducts.some((bundleItem) => bundleItem.category === 'Jacket') ? 'jacket' : '';
};

const getBundleJacketSku = (bundleProducts: Array<BundleProduct>) => {
  const bundleProduct = bundleProducts.find((item) => item.category === 'Jacket');
  return bundleProduct?.sku ?? '';
};

const getRenderedLook = async (skus: string[]) => {
  const json = await buildCompositeImageFromSkus(skus).then((res) => {
    if (res.status !== 200) {
      throw new Error('Unable to retrieve rendered look.');
    }

    return res.json();
  });

  return json?.imageUrl;
};

const LoadingSpinner = (props: SpinnerProps) => {
  return (
    <Spinner type="minimal" className={`${props.className ?? ''}`}>
      <h2 className="text-h3 text-center text-gray-dark">Loading</h2>
    </Spinner>
  );
};

const Event = ({ id, name, startDate, primaryEvent, isOwner, memberId, history }: Props) => {
  const [lookImageUrl, setLookImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const skus = await getSkus(id, memberId);
        setLookImageUrl(await getRenderedLook(skus));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, memberId]);

  return (
    <div className="mb-32">
      {primaryEvent && <h3 className="text-h6 inline-block bg-gray p-8 pb-4">Primary Event</h3>}
      <div className={(primaryEvent ? 'border-4  border-gray' : 'shadow-none') + ' flex bg-white'}>
        <div className="mr-0 w-4/5 p-32 sm:mr-32">
          <h4 className="text-h4 text-gray-dark">
            <time>{format(startDate, 'M/D/YY')}</time>
          </h4>
          <h3 className="text-h3-display">{name}</h3>

          <button
            className="tracker-cta-event-view-200619-111519 btn btn-info mt-12 w-full py-8"
            onClick={async () => {
              await EventStore.loadEvent(id);
              isOwner
                ? history.push(`/event-flow/looks?eventId=${id}`)
                : history.push(`/fit-flow/invited/height?eventId=${id}&memberId=${memberId}`);
            }}
          >
            View Event
          </button>
        </div>
        <div className={`inline ${!loading ? 'shadow-xl' : 'w-full'}`}>
          {loading ? (
            <LoadingSpinner className="mt-32" />
          ) : (
            lookImageUrl && (
              <button
                className="block"
                onClick={async () => {
                  await EventStore.loadEvent(id);
                  isOwner
                    ? history.push(`/event-flow/looks?eventId=${id}`)
                    : history.push(`/fit-flow/invited/height?eventId=${id}&memberId=${memberId}`);
                }}
              >
                <img
                  className="mt-32 object-cover sm:mt-0"
                  src={`${lookImageUrl}?ar=7:5&fit=crop&crop=focalpoint&fp-x=.5&fp-y=.125&fp-z=1.45`}
                  alt="Composite Look"
                />
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Event);
