import { ComponentType } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import auth from '../../services/Auth';

/**
 * Wraps the given `RouteComponent` with logic to redirect unauthenticated users
 * to the login screen
 */
export const withAuthRequired = (RouteComponent: ComponentType<RouteComponentProps>) => {
  return <T extends {} = {}>(props: RouteComponentProps<T>) => {
    if (auth.signedOut()) {
      const { pathname, search, hash } = props.location;

      return <Redirect to={`/gate/login?redirect=${pathname + search + hash}`} />;
    }

    return <RouteComponent {...props} />;
  };
};
