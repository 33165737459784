import { ReactElement } from 'react';

import IconTrashcan from '../../../components/IconTrashcan';
import LookCardBaseModal, { BaseModalProps } from './BaseModal';
import LookStore from '../../../stores/LookStore';
import { isGentux } from '../../../utils/utils';

import './DeleteModal.css';

type Props = BaseModalProps & {
  deleteMsg: ReactElement;
  lookId?: string;
};

const DeleteModal = ({ deleteMsg, lookId, onClose }: Props) => (
  <LookCardBaseModal onClose={onClose}>
    {!lookId && deleteMsg ? (
      deleteMsg
    ) : (
      <button
        className={`btn btn-danger w-full uppercase ${isGentux() ? 'text-xs md:text-sm' : 'font-condensed text-base'}`}
        onClick={() => {
          if (lookId) LookStore.deleteLook(lookId);
        }}
      >
        <IconTrashcan style={{ cursor: 'pointer', color: 'white' }} />
        <span className="xl:hidden">DELETE</span>
        <span className="hidden xl:block">DELETE THIS LOOK</span>
      </button>
    )}
  </LookCardBaseModal>
);

export default DeleteModal;
