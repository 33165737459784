import React from 'react';
import FormContainer from './FormContainer';
import { formBaseClassNames, formBaseClassNamesDarkMode, hasCombinedLabel } from './FormUtils';
import IconTriangleDown from './IconTriangleDown';

interface FormInputProps extends React.HTMLProps<HTMLLabelElement & HTMLSelectElement> {
  combineLabel?: Boolean;
  darkMode?: Boolean;
  inputAddOn?: String;
  errorMessage?: String;
  disabledMessage?: String;
  description?: string;
  selectClassName?: string;
  onChange?: (event: React.ChangeEvent<HTMLLabelElement & HTMLSelectElement>) => void;
}

type Props = FormInputProps & DataTestIdProp;

const FormInput = ({ ...props }: Props) => {
  const {
    label,
    combineLabel,
    inputAddOn,
    errorMessage,
    disabledMessage,
    darkMode,
    className,
    description,
    selectClassName = '',
    ...cleanedProps
  } = props;

  const combinedLabel = hasCombinedLabel(props);

  return (
    <FormContainer
      className={className}
      label={label}
      disabled={props.disabled}
      darkMode={darkMode}
      inputAddOn={inputAddOn}
      errorMessage={errorMessage}
      disabledMessage={disabledMessage}
      description={description}
      hasCombinedLabel={combinedLabel}
      formIcon={<IconTriangleDown className={props.disabled ? 'text-gray' : 'text-black'} />}
    >
      <select
        {...cleanedProps}
        className={`
          ${formBaseClassNames} 
          ${combinedLabel ? 'pt-40' : ''} 
          ${darkMode ? formBaseClassNamesDarkMode : ''}
          pr-40
          ${selectClassName}
        `}
      >
        {props.children}
      </select>
    </FormContainer>
  );
};

export default FormInput;
