import Line from '../../components/Line';

const Page404 = () => (
  <div data-testid="404-page" className="container">
    <div className="row m-section gap-y-32">
      <div className="col-span-12 sm:col-span-5 sm:col-start-6">
        <h1 className="text-h1-display">404 Error</h1>
        <h2 className="text-h3">Oops! We're not quite ready.</h2>
        <Line />
        <a className="btn btn-primary" href={`${process.env.REACT_APP_ECOMM_URL}`}>
          Go Home
        </a>
      </div>

      <div className="col-span-12 sm:col-span-3 sm:col-start-3 sm:row-start-1">
        <img
          loading="lazy"
          id="page-not-found-img"
          alt="Not Found."
          src="https://gentux.imgix.net/1550091090_404-error-image.png"
          width="250px"
          height="485px"
        />
      </div>
    </div>
  </div>
);

export default Page404;
