import React from 'react';

const Dots: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => (
  <div
    {...props}
    className={`mx-4 grow overflow-hidden text-gray ${className ? className : ''}`}
    style={{ letterSpacing: '0.05em', lineHeight: 1.15 }}
  >
    ...............................................................................................................................................
  </div>
);

export default Dots;
