import { Order, Carton } from '../../types';
import IconCheck from '../../components/IconCheck';
import IconX from '../../components/IconX';

const Td = ({ className = '', ...props }) => (
  <div className={`table-cell grow whitespace-nowrap py-16 align-middle xs:w-[16%] ${className}`}>{props.children}</div>
);

const InviteStatusCard = ({
  name,
  hasAccount,
  isFit,
  isPaid,
  isShipped,
  hasAddress,
  orders,
}: {
  name: string;
  hasAccount: boolean;
  isFit: boolean;
  isPaid: boolean;
  isShipped: boolean;
  hasAddress: boolean;
  orders: Order[];
}) => {
  const ordersWithoutSwatches = orders && orders.filter((o: Order) => Boolean(o.isSwatch) === false);

  const trackingUrls =
    ordersWithoutSwatches &&
    ordersWithoutSwatches.reduce((acc: Array<Carton['trackingUrl']>, order: Order) => {
      const carton =
        order.shipment &&
        order.shipment.cartons &&
        order.shipment.cartons.find((carton: Carton) => carton.type.toLowerCase() === 'outbound');

      return carton && carton.trackingUrl ? [...acc, carton.trackingUrl] : acc;
    }, []);

  const url = trackingUrls && trackingUrls.length !== 0 && trackingUrls[0];

  const renderIsShipped = () => {
    if (isShipped) {
      if (url) {
        return (
          <a className="btn btn-sm btn-info uppercase" href={url} target="_blank" rel="noopener noreferrer">
            Track
          </a>
        );
      }

      return <IconCheck />;
    }
    return <IconX className="text-red" />;
  };

  return (
    <>
      <div className="flex flex-wrap xs:table-row">
        <Td className="bg-white pl-16">
          <p className="text-sm whitespace-nowrap">{name}</p>
        </Td>
        <div className="w-full xs:hidden">
          <hr className="border-gray-light" />
        </div>
        <Td className={`${hasAccount ? 'complete bg-white' : 'incomplete bg-gray-light'}`}>
          <div className="xs:hidden">
            <p className="text-sm text-center">Sign Up</p>
          </div>
          <span className="flex justify-center">{hasAccount ? <IconCheck /> : <IconX className="text-red" />}</span>
        </Td>
        <Td className={`${isFit ? 'complete bg-white' : 'incomplete bg-gray-light'}`}>
          <div className="xs:hidden">
            <p className="text-sm text-center">Fit</p>
          </div>
          <span className="flex justify-center">{isFit ? <IconCheck /> : <IconX className="text-red" />}</span>
        </Td>
        <Td className={`${isFit ? 'complete bg-white' : 'incomplete bg-gray-light'}`}>
          <div className="xs:hidden">
            <p className="text-sm text-center">Address</p>
          </div>
          <span className="flex justify-center">{hasAddress ? <IconCheck /> : <IconX className="text-red" />}</span>
        </Td>
        <Td className={`${isPaid ? 'complete bg-white' : 'incomplete bg-gray-light'}`}>
          <div className="xs:hidden">
            <p className="text-sm text-center">Check Out</p>
          </div>
          <span className="flex justify-center">{isPaid ? <IconCheck /> : <IconX className="text-red" />}</span>
        </Td>
        <Td className={`${isShipped ? 'complete bg-white' : 'incomplete bg-gray-light'}`}>
          <div className="xs:hidden">
            <p className="text-sm text-center">Ship</p>
          </div>
          <span className="flex justify-center">{renderIsShipped()}</span>
        </Td>
      </div>
      <div className="table-row">
        <div className="table-cell py-16" />
        <div className="table-cell py-16" />
        <div className="table-cell py-16" />
        <div className="table-cell py-16" />
        <div className="table-cell py-16" />
        <div className="table-cell py-16" />
      </div>
    </>
  );
};
export default InviteStatusCard;
