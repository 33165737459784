import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { AccessContext } from '../../utils/HOC/';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import Flow from '../../utils/HOC/Flow';
import InvitedMemberFormStore from '../../stores/InvitedMemberFormStore';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  firstName?: string;
  error?: string;
  loading: boolean;
}

class FirstName extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      firstName: InvitedMemberFormStore.firstName,
    });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ firstName: e.target.value });

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    InvitedMemberFormStore.update('firstName', this.state.firstName);
    this.nextPage();
  };

  nextPage = () => {
    this.setState({
      loading: false,
    });

    this.props.flow!(this.props.location.search);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          key="first-name"
          property="firstName"
          label="First Name"
          title="What is your first name?"
          value={this.state.firstName!}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          loading={this.state.loading}
        />
      </>
    );
  }
}

export default Flow(AccessContext(observer(FirstName)));
