import { addDays, differenceInDays, endOfMonth, format, getDaysInMonth, isWednesday } from 'date-fns';

import { HTO_MAX_DAYS } from '../../../constants';
import { isDateLessThanNDaysAway } from '../../../utils/date';
import { range } from '../../../utils/utils';

/**
 * Returns a boolean indicating whether we're fifteen days or less away from the
 * first Wednesday of the upcoming year
 *
 * This is used to determine whether we should increment the default year that's
 * selected when the event date picker is loaded
 */
export const shouldIncrementYear = (years: number[], now = new Date()) => {
  const nextYear = years[1];

  const dayOfFirstWed = getFirstWednesdayOfYear(nextYear, now);

  if (dayOfFirstWed === -1) {
    // this shouldn't occur since we only ever call this function with years that
    // are relative to the date at runtime, so this is here for posterity's sake
    throw new Error(`No Wednesday in Jan. ${years[1]} is two weeks or more away (relative to ${now})`);
  }

  const formattedDate = `${nextYear}-01-${withLeadingZero(dayOfFirstWed)}`;

  const daysUntilFirstWednesday = differenceInDays(formattedDate, now.getTime());

  return daysUntilFirstWednesday < 16;
};

/**
 * Returns the first Wednesday of the given year that's valid for scheduling an
 * event
 *
 * Any Wednesdays that are less than two weeks away are considered invalid, so
 * on Dec. 29, 2020, that upcoming Wednesday (Jan 5th) would be invalid and
 * Jan. 13, 2021 would be the returned day
 */
export const getFirstWednesdayOfYear = (year: number, now = new Date()) => {
  const validDays = range(1, getDaysInMonth(`${year}-01`)).filter((day) => {
    let date = `${year}-01-${withLeadingZero(day)}`;

    return !isDateLessThanNDaysAway(date, 14, now);
  });

  return (
    validDays.find((day) => {
      return isWednesday(`${year}-01-${withLeadingZero(day)}`);
    }) ?? -1
  );
};

export const withLeadingZero = (monthOrDay: number) => `0${monthOrDay}`.slice(-2);

/**
 * A map with months (1 - 12) as keys, and arrays of dates as values
 */
export type MonthToDays = Map<number, number[]>;

/**
 * A map with years as keys and `MonthToDays` objects as values
 */
export type YearToMonths = Map<number, MonthToDays>;

/**
 * Represents the years that can be selected in an instance of the event date
 * picker
 *
 * The first element is the current year, and the subsequent elements are the
 * following two years
 *
 * @example [2024, 2025, 2026]
 */
export type YearOptions = [number, number, number];

export interface DatePickerOptions {
  options: YearToMonths;

  /**
   * An array of the years that can be selected in an instance of the date picker
   */
  getYears(): number[];

  /**
   * The default year that will be shown in the event date picker
   */
  getDefaultYear(): number;

  /**
   * The default month that will be shown in the event date picker
   */
  getDefaultMonth(): number;

  /**
   * The default date (day of the month) that will be shown in the event date picker
   */
  getDefaultDay(): number;

  /**
   * @throws {Error} if the given year isn't a selectable option
   */
  getMonthsInYear(year: number): number[];

  /**
   * @throws {Error} if the given year or month aren't selectable options
   */
  getDaysInMonth(year: number, month: number): number[];
}

export class EventDateOptions implements DatePickerOptions {
  protected defaultYear = 1970;
  protected defaultMonth = 1;
  protected defaultDay = 1;

  protected _options: YearToMonths;

  constructor(protected now = new Date()) {
    const years = [now.getFullYear(), now.getFullYear() + 1, now.getFullYear() + 2];

    this.defaultYear = shouldIncrementYear(years, now) ? years[2] : years[1];

    this.defaultDay = getFirstWednesdayOfYear(this.defaultYear, now);

    this._options = this.getSelectableDates(years, now);
  }

  get options() {
    return this._options;
  }

  getMonthsInYear(year: number) {
    const months = this._options.get(year);

    if (!months) {
      throw new Error(`${year} is not a valid year for selection`);
    }

    return Array.from(months.keys());
  }

  getDaysInMonth(year: number, month: number) {
    if (!this.getMonthsInYear(year).includes(month)) {
      throw new Error(`${year}-${withLeadingZero(month)} is not a valid month for selection`);
    }

    return this._options.get(year)?.get(month) ?? [];
  }

  getYears() {
    return Array.from(this._options.keys());
  }

  /**
   * @inheritdoc
   *
   * For new events this is always January
   */
  getDefaultMonth() {
    return this.defaultMonth;
  }

  /**
   * @inheritdoc
   *
   * This will be the year after the present, unless we're less than sixteen
   * days away from the first or second Wednesday of that year, in which case
   * it will be the year after next
   */
  getDefaultYear() {
    return this.defaultYear;
  }

  /**
   * @inheritdoc
   *
   * This will be the first or second Wednesday of the next coming year, whichever
   * one is at minimum two weeks away. If both of those dates fall within less than
   * two weeks, this will be the first Wednesday of the year after next.
   */
  getDefaultDay() {
    return this.defaultDay;
  }

  protected getSelectableDates(years: number[], now: Date): YearToMonths {
    const yearsToMonths = years.map((year) => this.getValidYearToMonths(year, now));

    const validYears = yearsToMonths.filter(([_, months]) => months.size > 0);

    return new Map(validYears);
  }

  /**
   * Returns a pair in the form of `[number, MonthToDays]`, that can be used as
   * an entry when constructing a `YearToMonths` object
   */
  protected getValidYearToMonths(year: number, now: Date): [number, Map<number, number[]>] {
    const months = range(1, 12).map((month) => this.getValidMonthToDays(year, month, now));

    const validMonths = months.filter(([_, days]) => days.length > 0);

    return [year, new Map(validMonths)];
  }

  /**
   * Returns a pair in the form of `[number, number[]]`, that can be used as an
   * entry when constructing a `MonthToDays` object
   */
  protected getValidMonthToDays(year: number, month: number, now: Date): [number, number[]] {
    const monthDate = `${year}-${withLeadingZero(month)}`;

    const days = range(1, getDaysInMonth(monthDate)).filter((day) => {
      const dateWithDay = `${monthDate}-${withLeadingZero(day)}`;

      return this.isDateAfterCutOff(dateWithDay, now);
    });

    return [month, days];
  }

  /**
   * Returns a boolean that indicates whether the given date is within the cut off
   * range for registering a new event
   *
   * For new events, the given date must be at least 14 days away to be considered
   * valid for selection
   */
  protected isDateAfterCutOff(date: string | Date, now: Date) {
    return !isDateLessThanNDaysAway(date, 14, now);
  }
}

/**
 * Represents the dates that can be selected for an event that will qualify for an HTO
 */
export class HtoQualifyingEventDateOptions extends EventDateOptions {
  /**
   * Returns a boolean that indicates whether the given date is within the cut off range
   * for registering a new event that qualifies for an HTO
   *
   * To qualify for an HTO, the given date must be at least 45 (HTO_MAX_DAYS constant) days away to be considered
   * valid for selection
   */
  protected isDateAfterCutOff(date: string, now: Date) {
    return !isDateLessThanNDaysAway(date, HTO_MAX_DAYS, now);
  }
}

/**
 * Represents the dates that can be selected for an existing event (i.e. when a
 * user changes their event date)
 */
export class ExistingEventDateOptions extends EventDateOptions {
  constructor(protected eventDate: Date, now = new Date()) {
    super(now);

    const year = eventDate.getFullYear();
    const month = eventDate.getMonth() + 1;
    const day = eventDate.getDate();

    const datesInYear = this._options.get(year) ?? new Map<number, number[]>();
    const daysInMonth = datesInYear.get(month) ?? [];

    if (!daysInMonth.includes(day)) {
      daysInMonth.push(day);

      daysInMonth.sort((a, b) => a - b);
    }

    datesInYear.set(month, daysInMonth);

    // re-sort the months in case it wasn't already in the map
    this._options.set(year, this.getDatesSortedByKey(datesInYear));
    // re-sort the years in case the year wasn't already set
    this._options = this.getDatesSortedByKey(this._options);

    this.defaultYear = year;
    this.defaultMonth = month;
    this.defaultDay = day;
  }

  protected getDatesSortedByKey(dates: Map<number, any>) {
    const entries = [...dates.entries()];

    entries.sort((a, b) => {
      const [keyA] = a;
      const [keyB] = b;

      return keyA - keyB;
    });

    return new Map(entries);
  }
}

const defaultOptions = new EventDateOptions();

export const YEARS = defaultOptions.getYears();

export const DEFAULT_YEAR = defaultOptions.getDefaultYear();
export const DEFAULT_MONTH = defaultOptions.getDefaultMonth();
export const DEFAULT_DAY = defaultOptions.getDefaultDay();

// add 14 days because we can only select events more than 14 days away
export const isMonthInPast = (date: string, now = new Date()) => endOfMonth(date) < addDays(now, 14);

// find the first day of the month for the currently selected year
// this will allow us to find the last day of month and see if that month has passed
export const isMonthNotSelectable = (year: number, month: number, now = new Date()) => {
  if (!year) {
    return false;
  }
  return isMonthInPast(`${year}-${withLeadingZero(month)}-01`, now);
};

export const getFirstSelectableMonth = (year: number, now = new Date()) => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter((month) => !isMonthNotSelectable(year, month, now))[0];
};

export const getFirstSelectableDay = (year: number, month: number, now = new Date()) => {
  const day = daysInMonth(year, month).find((d) => !isDayNotSelectable(d, now));
  return day !== undefined ? Number(format(day, 'D')) : 1;
};

export const isDayNotSelectable = (monthDay: string, now = new Date()) => isDateLessThanNDaysAway(monthDay, 14, now);

export const daysInMonth = (year: number, month: number) => {
  const numberOfDays = getDaysInMonth(format(`${year}-${withLeadingZero(month)}`, 'YYYY-MM'));

  return range(1, numberOfDays).map((day) => {
    let date = `${year}-${withLeadingZero(month)}-${withLeadingZero(day)}`;

    return format(date, 'YYYY-MM-DD');
  });
};

export const monthIsTooSoon = (month: number, year: number, now = new Date()) =>
  month < getFirstSelectableMonth(year, now);

export const dayIsTooSoon = (month: number, day: number, year: number, now = new Date()) =>
  month === getFirstSelectableMonth(year, now) &&
  getFirstSelectableDay(year, getFirstSelectableMonth(year, now), now) > day;

export const formatMonth = (month: number, year: number) => format(`${year}-${withLeadingZero(month)}`, 'MMM');

export const formatDay = (day: string) => format(day, 'D');

// Select Options
export const monthOptions = (year: number, now = new Date()) =>
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter((month) => !isMonthNotSelectable(year, month, now));

export const dayOptions = (year: number, month: number, now = new Date()) =>
  daysInMonth(year, month).filter((day) => !isDayNotSelectable(day, now));

export const hasUsedDefaultEventDate = (day: number, month: number, year: number, defaults = defaultOptions) =>
  day === defaults.getDefaultDay() && month === defaults.getDefaultMonth() && year === defaults.getDefaultYear();

export const getAbbreviationForMonth = (month: number) => {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';
  }

  throw new Error(`Couldn't find an abbreviation for month #${month}`);
};
