import { Component } from 'react';
import { Link } from 'react-router-dom';

// local components
import FormNavigation from '../../utils/Component/FormNavigation';

// types
import { FlowRouteProps } from '../../types';

import Flow from '../../utils/HOC/Flow';

// interfaces
interface Props extends FlowRouteProps<any> {
  nextUrl?: string;
  B2BReferal: boolean;
}

class FitOptionalWelcome extends Component<Props> {
  onSubmit = () => {
    this.props.flow!(`${this.props.location.search}`);
  };

  render() {
    const { B2BReferal } = this.props;

    return (
      <>
        <div className="mx-auto max-w-sm">
          <h1 className="text-h1 text-center">Almost There!</h1>
          <p className="mb-16 text-center">
            {B2BReferal
              ? 'We want to get the measurements the tailor gave you.'
              : 'We need a few measurements to fit you best.'}
          </p>
          <div className="mb-32">
            {this.props.history.location.pathname.includes('/abr/') ? (
              <p className="text-sm text-center">
                {' '}
                If you need a tape measure,{' '}
                <a
                  href={`${process.env.REACT_APP_ECOMM_URL}/support`}
                  className="tracker-link-fit_optional-support-210701-100030"
                >
                  contact our support team
                </a>
                .
              </p>
            ) : (
              <p className="text-sm text-center">
                <Link
                  className="tracker-text-fit_optional-order_tape_measure-211115-153806"
                  to={`/fit-flow/ordertapemeasure${this.props.location.search}`}
                >
                  Need a tape measure?
                </Link>
              </p>
            )}
          </div>

          <FormNavigation next={this.onSubmit} />
        </div>
      </>
    );
  }
}

export default Flow(FitOptionalWelcome);
