import { PureComponent, SyntheticEvent } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';

import { Member } from '../../types';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';
import './CardInfo.css';
import { paymentInfoEntered } from '../../utils/metrics';
import auth from '../../services/Auth';

interface Props extends ReactStripeElements.InjectedStripeProps {
  handleTokenSuccess: (token: string) => Promise<void>;
  handleTokenError: (error: string) => void;
  back: () => void;
  orderType: 'hto' | 'checkout';
  membersToPayFor: Member[];
}

interface State {
  cardNumber: string;
  billingZip: string;
  billingZipStyle: object;
  submitting: boolean;
}

const emptyBillingZipStyle = {
  fontSize: '12.800000190734863px',
  fontFamily: '"Roboto", sans-serif',
  lineHeight: '24px',
  width: '100%',
  padding: '8px 0px',
  display: 'block',
  appearance: 'none',
  boxShadow: 'none',
  backgroundColor: 'none',
  backgroundImage: 'none',
  borderRadius: '0px',
  transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s',
  color: '#000',
};

const defaultBillingZipStyle = {
  ...emptyBillingZipStyle,
  letterSpacing: '-.5px',
};

const errorBillingZipStyle = {
  ...defaultBillingZipStyle,
  color: '#b45250',
};

const StripeReset = {
  base: {
    fontSize: '12.800000190734863px',
    fontFamily: '"Roboto", sans-serif',
    lineHeight: '24px',
    color: '#000',
    fontWeight: '300',
    '::placeholder': {
      color: '#888',
      fontWeight: '300',
    },
  },
  invalid: {
    color: '#b45250',
  },
};

class CardInfo extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cardNumber: '',
      billingZip: '',
      billingZipStyle: emptyBillingZipStyle,
      submitting: false,
    };
  }

  submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    this.setState({ submitting: true });

    this.getPaymentToken();
  };

  getPaymentToken = async () => {
    try {
      if (!this.props.stripe) {
        throw new Error("Stripe's SDK is unavailable to CardInfo at the time of submission");
      }

      const res = await this.props.stripe.createToken({ address_zip: this.state.billingZip });

      if (res.error) {
        throw new Error(res.error.message);
      } else if (!res.token) {
        throw new Error('No token was returned with the response from Stripe');
      }

      try {
        paymentInfoEntered(auth.user());
      } catch (e) {
        console.error(e);
      } finally {
        await this.props.handleTokenSuccess(res.token.id);
      }
    } catch (e) {
      console.error(e);
      this.props.handleTokenError('Something went wrong when validating your credit card. Please try again.');
    } finally {
      this.setState({ submitting: false });
    }
  };

  renderSubmitText = () => {
    switch (this.props.orderType) {
      case 'hto':
        return 'Send Me My Home Try-On!';
      default:
        return 'Place Order';
    }
  };

  handleBillingZipChange = (val: string) => {
    if (!val.length) {
      this.setState({
        billingZip: val,
        billingZipStyle: emptyBillingZipStyle,
      });
    } else if (val.length <= 5) {
      this.setState({
        billingZip: val,
        billingZipStyle: defaultBillingZipStyle,
      });
    } else {
      return;
    }
  };

  checkErrorStyling = () => {
    let billingZipLength = this.state.billingZip.length;
    if (billingZipLength && billingZipLength < 5) {
      this.setState({ billingZipStyle: errorBillingZipStyle });
    }
  };

  render() {
    return (
      <form data-testid="shared-card-info" className="space-y-32" onSubmit={(e) => e.preventDefault()}>
        <div className="space-y-32 bg-white p-32">
          <div className="row">
            <div className="col-span-12">
              <label className="text-sm font-condensed uppercase text-gray-dark" htmlFor="checkout-card">
                Card Number
              </label>

              <div className="stripe-input">
                <CardNumberElement
                  id="checkout-card"
                  placeholder="Card Number"
                  style={StripeReset}
                  classes={{ focus: 'stripeFocus' }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-span-6">
              <label className="text-sm font-condensed uppercase text-gray-dark" htmlFor="expiry-year">
                Exp. Date
              </label>

              <div className="stripe-input">
                <CardExpiryElement
                  id="cc-exp"
                  placeholder="Exp. Date"
                  style={StripeReset}
                  classes={{ focus: 'stripeFocus' }}
                />
              </div>
            </div>

            <div className="col-span-6" id="cvc-box">
              <label className="text-sm font-condensed uppercase text-gray-dark" htmlFor="checkout-cvc">
                CVC
              </label>

              <div className="stripe-input">
                <CardCVCElement id="cvc" placeholder="CVC" style={StripeReset} classes={{ focus: 'stripeFocus' }} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-span-6">
              <label className="text-sm font-condensed uppercase text-gray-dark" htmlFor="billing-zip">
                Zip Code
              </label>

              <input
                className="border-b-solid border-b border-b-black placeholder-gray-dark focus:border-b-brand-dark focus:outline-none"
                id="zip"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                name="zip"
                placeholder="Zip Code"
                onBlur={this.checkErrorStyling}
                value={this.state.billingZip}
                style={this.state.billingZipStyle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleBillingZipChange(e.target.value.trim())
                }
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-span-3">
            <button
              className="tracker-button-card-back-200619-111519 btn btn-default w-full"
              role="link"
              disabled={this.state.submitting}
              onClick={() => this.props.back()}
              aria-label="Back"
            >
              <IconArrowLeft />
            </button>
          </div>

          <div className="col-span-9">
            <button
              className="tracker-button-card-submit-200619-111519 btn btn-info w-full"
              data-testid="btn-submit"
              disabled={this.props.membersToPayFor.length === 0 || this.state.submitting}
              onClick={this.submit}
              type="submit"
            >
              {this.state.submitting ? 'Submitting...' : this.renderSubmitText()} <IconArrowRight />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default injectStripe(CardInfo);
