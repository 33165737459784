import { Component, ComponentType } from 'react';
import { RouteComponentProps } from 'react-router';

import type { Flow as FlowType, FlowStep } from '../../types';
import { welcomePacketOn } from '../../static/experiments';
import flows from '../../flows';
import EventFormStore from '../../stores/EventFormStore';
import FlowStore from '../../stores/FlowStore';
import PartyRoleStore from '../../stores/PartyRoleStore';
import { duplicates } from '../utils';
import { getParameterByName } from '../window';
import withOptimizelyData, { type OptimizelyProps } from './withOptimizelyData';

type FlowProps = {
  flow: FlowStep;
};

type BaseComponentProps = Partial<FlowProps>;

type WrappedComponentProps = BaseComponentProps & Partial<OptimizelyProps>;

const isWeddingTypeCondition = () => EventFormStore?.event?.type === 'Wedding';

const isGroomOrBrideCondition = () => {
  const allowedRoles = ['Groom', 'Bride'];

  if (EventFormStore.partyRoleId) {
    const role = PartyRoleStore.find(EventFormStore.partyRoleId);

    return allowedRoles.includes(String(role?.name));
  }

  return false;
};

// add additional check condition for wedding type
welcomePacketOn.conditions.push(isWeddingTypeCondition);

// add additional check for only Groom and Bride
welcomePacketOn.conditions.push(isGroomOrBrideCondition);

/**
 * A map of URLs to conditionally render based on which variation a user was bucketed
 * into for an A/B test
 */
const urlPathToExperimentsMap = {
  '/customize/address': welcomePacketOn,
  '/customize/signup/address': welcomePacketOn,
  '/gate/signup/address': welcomePacketOn,
  '/signup/address': welcomePacketOn,
} as const;

type ExperimentPath = keyof typeof urlPathToExperimentsMap;

const experimentExistsForUrl = (path: string): path is ExperimentPath => path in urlPathToExperimentsMap;

// Returns duplicate Flow stream values
const duplicateFlowStreams = () => {
  const emptyStreams: string[] = [];
  const streams = emptyStreams.concat(...flows.map((flow: FlowType) => flow.stream));
  return duplicates(streams);
};

const getCurrentFlow = (url: string) => {
  // We need to find both top level Flows and
  // Flows that are nested inside of an outflow
  return flows.reduce((acc: FlowType | null, i: FlowType) => {
    if (acc) {
      return acc;
    }

    // If the stream exists on a top level flow: return flow
    if (i.stream.indexOf(url) > -1) {
      return i;
    }

    // If a Flow has an outflow that is an array,
    // we want to check the nested outflows to see
    // grab an anonymous Flow as the curernt Flow
    if (Array.isArray(i.outflow)) {
      const currentOutflow = i.outflow as Array<FlowType | string>;
      // Check all nested outflows to see if
      // any nested flows contain the current url
      const outflowFlow: FlowType | undefined = currentOutflow.find(
        (f: FlowType | string) => typeof f === 'object' && f.stream.indexOf(url) > -1
      ) as FlowType | undefined;
      if (outflowFlow) {
        return outflowFlow;
      }

      return acc;
    }

    // If an outflow is a Flow object, check
    // if its stream matches the current url
    if (typeof i.outflow === 'object') {
      const currentOutflow = i.outflow as FlowType;
      if (currentOutflow.stream!.indexOf(url) > -1) {
        return i.outflow;
      }
    }

    return acc;
  }, null) as FlowType;
};

const getRedirectUrl = (urlParams?: string) => {
  let redirectUrl = FlowStore.redirect ?? '/';

  FlowStore.unsetRedirect();

  if (urlParams) {
    const params = new URLSearchParams(urlParams);
    params.delete('redirect');

    redirectUrl += '?' + decodeURIComponent(params.toString());
  }

  return redirectUrl;
};

const getNextStreamUrl = (currentFlow: FlowType, currentUrl: string, outflowIndex?: number) => {
  const streamIndex = currentFlow.stream.indexOf(currentUrl);

  // if the current stream of a flow is not the last value
  // in the stream array, return the next value
  if (streamIndex + 1 < currentFlow.stream.length) {
    return currentFlow.stream[streamIndex + 1];

    // if the current stream is last stream value
    // of the stream array. Direct user to outflow.
  } else {
    // if outflow is a string, return outflow
    if (typeof currentFlow.outflow === 'string') {
      return currentFlow.outflow;

      // if outflow is an array, find outflow according
      // to outflowIndex passed from wrapped Flow component
    } else if (Array.isArray(currentFlow.outflow)) {
      //@TODO: Refactor to getOutflow Function
      if (currentFlow.outflow.length === 0) {
        throw new Error(`Flow with stream ${currentFlow.stream[streamIndex]} contains an outfow with an empty array. Outflows cannot contain an empty array.
        `);
      }

      if (outflowIndex) {
        const currentOutflow = currentFlow.outflow[outflowIndex];

        // if outflowIndex is out of bounds of outflow array, throw error
        if (!currentFlow) {
          throw new Error(`outflowIndex: ${outflowIndex} is invalid for current outflow.`);
        }

        // if found outflow is a string, return string
        if (typeof currentOutflow === 'string') {
          return currentOutflow;

          // else: found outflow is a Flow object,
          // return first stream array value
        } else {
          return currentOutflow.stream[0];
        }

        // provide default functionality if no outflowIndex
        // is passed, but outflow is an array
      } else {
        const currentOutflow = currentFlow.outflow[0];

        // if first outflow is a string, return string
        if (typeof currentOutflow === 'string') {
          return currentOutflow;

          // else: found outflow is a Flow object,
          // return first stream array value
        } else {
          return currentOutflow.stream[0];
        }
      }

      // outflow is a Flow object: return first stream value
    } else if (typeof currentFlow.outflow === 'object') {
      return currentFlow.outflow.stream[0];
    } else {
      throw new Error('Invalid outflow type used in flow');
    }
  }
};

/**
 * Strips away query string
 * e.g. "/some/path?qs=true" => "/some/path"
 */
const getUrlPath = (url: string) => (url.indexOf('?') !== -1 ? url.slice(0, url.indexOf('?')) : url);

// If outflow is set to redirect and Flow has reached the last
// stream value: return that the user should redirect
const shouldRedirect = (currentFlow: FlowType, currentUrl: string) =>
  currentFlow.outflow === 'redirect' && currentFlow.stream.indexOf(currentUrl) + 1 === currentFlow.stream.length;

const getNextStepUrl = (currentFlow: FlowType, currentUrl: string, urlParams?: string, outflowIndex?: number) => {
  // If current step requires a flow redirect, use
  // redirect property in the Flow Store
  if (shouldRedirect(currentFlow, currentUrl)) {
    return getRedirectUrl();

    // Otherwise, gather next step via flow path or fork
  } else {
    const nextStreamUrl = getNextStreamUrl(currentFlow, currentUrl, outflowIndex);

    // attach query params to the next step url
    return urlParams ? nextStreamUrl + urlParams : nextStreamUrl;
  }
};

export const getRedirectUrlFromQueryString = (): string | null => {
  if (!getParameterByName('redirect')) {
    return null;
  }

  let destination = getParameterByName('redirect') ?? '';

  if (getParameterByName('type') === 'ecomm') {
    const decodeDestination = decodeURIComponent(destination);
    destination = process.env.REACT_APP_ECOMM_URL + decodeDestination;
  }

  return destination;
};

const Flow = <P extends RouteComponentProps<any>>(
  WrappedComponent: ComponentType<P & BaseComponentProps>
): ComponentType<P & WrappedComponentProps> => {
  return withOptimizelyData(
    class extends Component<P & WrappedComponentProps, any> {
      constructor(props: P & WrappedComponentProps) {
        super(props);
        const dupeStreams = duplicateFlowStreams();

        // Checks if flows.ts file has duplicate Flow stream values.
        // All values within Flow streams across all Flows must be unique.
        if (dupeStreams.length > 0) {
          try {
            throw new Error(
              `Duplicate flow streams were found. This could lead to problematic user experiences if not resolved. 
              Stream values must be unique: ${dupeStreams.toString()}`
            );
          } catch (e) {
            let errorMessage = 'Failed to init flow.';

            if (e instanceof Error) {
              errorMessage = e.message;
            }
            console.error(errorMessage, dupeStreams);
          }
        }
      }

      /**
       * Primary API for a component to make use of Flows
       *
       * @param urlParams (Optional) Query string to populate the `FlowStore` with
       * @param outflowIndex (Optional) Outflow index to send the user to at the end of the stream
       * @param currentUrl (Optional) The current URL
       */
      flow = async (urlParams?: string, outflowIndex?: number, currentUrl?: string): Promise<void> => {
        currentUrl ??= this.props.location.pathname;

        if (urlParams) {
          const redirectUrl = getRedirectUrlFromQueryString();

          if (redirectUrl) {
            FlowStore.setRedirect(redirectUrl);
          }
        }

        const currentFlow = getCurrentFlow(currentUrl!);

        if (!currentFlow) {
          const errorMessage = `No flow stream was found containing the following URL: ${currentUrl}`;

          console.error(errorMessage);

          throw new Error(errorMessage);
        }

        try {
          // Get next step for user to direct to
          let nextUrl = getNextStepUrl(currentFlow, currentUrl!, urlParams, outflowIndex);

          if (nextUrl.includes(process.env.REACT_APP_ECOMM_URL!) || nextUrl.startsWith('/app/')) {
            window.location.href = nextUrl;
            return;
          }

          const navigateToPage = await this.canGoToPage(nextUrl);

          if (navigateToPage) {
            return this.props.history.push(nextUrl);
          }

          /**
           * Move to the next URL in the flow if the current step is inaccessible
           */
          const nextPath = getUrlPath(nextUrl); // remove qs from url for next flow

          return this.flow(urlParams, outflowIndex, nextPath);
        } catch (e) {
          let nextStepErrorMessage = 'Failed to get next steps.';

          if (e instanceof Error) {
            nextStepErrorMessage = e.message;
          }

          console.error(nextStepErrorMessage);
          throw e;
        }
      };

      /**
       * Returns a boolean indicating whether the current user can navigate to the
       * given URL
       *
       * This function will wait for up to 1s for Optimizely's data to become available,
       * after which point it will give up and assume that the user shouldn't be navigated
       * to the given page
       */
      canGoToPage = (url: string, attempt = 1): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
          const urlPath = getUrlPath(url);

          if (!experimentExistsForUrl(urlPath)) {
            return resolve(true);
          }

          if (this.props.optimizely?.loaded) {
            const { conditions, forExperiment, expectedVariation } = urlPathToExperimentsMap[urlPath];
            const chosenVariation = this.props.optimizely.data?.[forExperiment];
            const isCorrectVariationSelected = expectedVariation === chosenVariation;
            let additionalConditionsPassed = true;

            // iterate over additional conditions for the experiment
            if (isCorrectVariationSelected && Array.isArray(conditions) && conditions?.length > 0) {
              additionalConditionsPassed = conditions.every((condition) => condition());
            }

            return resolve(isCorrectVariationSelected && additionalConditionsPassed);
          }

          if (attempt === 5) {
            return resolve(false);
          }

          setTimeout(() => {
            this.canGoToPage(url, attempt + 1).then(resolve, reject);
          }, attempt * 100);
        });
      };

      render() {
        return <WrappedComponent flow={this.flow} {...this.props} />;
      }
    }
  );
};

export default Flow;
