import { Component } from 'react';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { FlowRouteProps } from '../../types';
import Flow from '../../utils/HOC/Flow';
import Line from '../../components/Line';
import IconArrowRight from '../../components/IconArrowRight';

class WelcomeBack extends Component<FlowRouteProps<any>> {
  nextPage = () => {
    this.props.flow!();
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-lg">
            <h1 className="normal-case text-h2-display">Welcome back!</h1>
            <Line />
            <p className="mb-32 text-gray-dark">We need a few more details to setup your account and event.</p>
            <button
              className="tracker-cta-welcome-next-200619-111519 btn btn-info w-full"
              aria-label="Next"
              onClick={() => this.nextPage()}
            >
              <IconArrowRight />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(WelcomeBack);
