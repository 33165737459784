import { observable, action, computed } from 'mobx';
import { CategoryState } from '../types';

const categoriesStub = [
  {
    name: 'preconfigured',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Shirt',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Tie',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Vest,Cummerbund',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Shoe',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Pocket Square',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Socks',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Belt,Suspenders',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Lapel Pin',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Cufflinks',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
  {
    name: 'Tie Bar',
    page: 1,
    filters: {
      categories: [],
      patterns: [],
      colors: [],
    },
  },
];

class DrawerStore {
  @observable categories: CategoryState[] = categoriesStub;
  @observable currentCategoryName: string | undefined = undefined;

  @computed
  get category() {
    return this.categories.find(p => p.name === this.currentCategoryName)!;
  }

  setCurrentCategory = (categoryName: string) => (this.currentCategoryName = categoryName);

  @action setFilters = (filterType: 'categories' | 'patterns' | 'colors', filters: string[]) =>
    (this.categories = this.categories.map(category => {
      if (category.name === this.currentCategoryName) {
        return { ...category, filters: { ...category.filters, [filterType]: filters }, page: 1 };
      }
      return category;
    }));

  @action setPage = (pageNumber: number) =>
    (this.categories = this.categories.map(category => {
      if (category.name === this.currentCategoryName) {
        return { ...category, page: pageNumber };
      }
      return category;
    }));

  @action reset = () => (this.categories = categoriesStub);

  @action resetPages = () =>
    (this.categories = this.categories.map(category => {
      if (category.name === this.currentCategoryName) {
        return { ...category, page: 1 };
      }
      return category;
    }));

  @action resetProductFilter = () =>
    (this.categories = this.categories.map(category => {
      if (category.name === this.currentCategoryName) {
        return { ...category, filters: { categories: [], patterns: [], colors: [] } };
      }
      return category;
    }));
}

export default new DrawerStore();
