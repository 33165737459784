import type { Item, BlockoutDate } from '../../types';
import { getParameterByName } from '../window';
import EventStore from '../../stores/EventStore';

import { isWithinRange } from 'date-fns';

export const isCurrentBlockOutDate = (blockout: BlockoutDate, relativeDate?: string | Date) => {
  const startDate = blockout.startDate ?? blockout.start_date ?? '';
  const endDate = blockout.endDate ?? blockout.end_date ?? '';

  return isWithinRange(relativeDate ?? new Date(), startDate, endDate);
};

export const hasCurrentBlockOutDates = (blockoutDates: BlockoutDate[], relativeDate?: string | Date) =>
  blockoutDates.some((blockout) => isCurrentBlockOutDate(blockout, relativeDate));

export const getCurrentBlockOutDates = (blockoutDates: BlockoutDate[], relativeDate?: string | Date) =>
  blockoutDates.filter((blockout) => isCurrentBlockOutDate(blockout, relativeDate));

export const itemIsComingSoon = (item: Item) => {
  const blockoutDates = item.blockoutDates ?? item.blockout_dates ?? [];

  const comingSoon = blockoutDates.filter((date) => date.availability === 'coming-soon');

  return hasCurrentBlockOutDates(comingSoon, EventStore.event?.startDate);
};

export const itemIsBlocked = (item: Item) => {
  const blockoutDates = item.blockoutDates ?? item.blockout_dates ?? [];
  const onHtoPage = window.location.pathname.includes('hto');
  var relativeDate: string | Date = new Date();

  if (!onHtoPage && EventStore.event?.startDate) {
    relativeDate = EventStore.event.startDate;
  }

  // Disallow selection of HTO blocked items in all forms of HTO item views
  const isHTOAndBlocked = (date: BlockoutDate) =>
    (getParameterByName('htoFlow') !== null || onHtoPage) && date.availability === 'hto';

  return blockoutDates.some((date) => {
    const withinRange = hasCurrentBlockOutDates([date], relativeDate);

    if (date.availability === 'hto') {
      return isHTOAndBlocked(date) && withinRange;
    }

    return withinRange;
  });
};
