import React from 'react';

const PlaceOrder = () => (
  <svg width="142px" height="91px" viewBox="0 0 142 91" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Place Order Illustration</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="place-order-linearGradient-1">
        <stop stopColor="#4B494B" offset="0%" />
        <stop stopColor="#383638" offset="100%" />
      </linearGradient>
      <rect id="place-order-path-2" x="0" y="0" width="48" height="30" rx="2" />
      <filter
        x="-20.8%"
        y="-16.7%"
        width="141.7%"
        height="166.7%"
        filterUnits="objectBoundingBox"
        id="place-order-filter-3"
      >
        <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <rect id="place-order-path-4" x="0" y="35" width="48" height="30" rx="2" />
      <filter
        x="-20.8%"
        y="-16.7%"
        width="141.7%"
        height="166.7%"
        filterUnits="objectBoundingBox"
        id="place-order-filter-5"
      >
        <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <rect id="place-order-path-6" x="87" y="51" width="45" height="28" rx="4" />
      <filter
        x="-22.2%"
        y="-17.9%"
        width="144.4%"
        height="171.4%"
        filterUnits="objectBoundingBox"
        id="place-order-filter-7"
      >
        <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <path
        d="M101.968127,37.6001286 L30.6942895,37.6001286 C29.9792318,37.6001286 29.3998714,38.1764318 29.3998714,38.8865368 L29.3998714,48.9770996 C29.3998714,49.6872046 29.9792318,50.2635078 30.6942895,50.2635078 L37.114411,50.2635078 L37.114411,47.0981408 L38.0768036,47.0981408 L38.0768036,50.2635078 L46.8307272,50.2635078 L46.8307272,43.9318182 L47.7931199,43.9318182 L47.7931199,50.2635078 L56.5480059,50.2635078 L56.5480059,47.0981408 L57.5103985,47.0981408 L57.5103985,50.2635078 L66.2633597,50.2635078 L66.2633597,43.9318182 L67.2257524,43.9318182 L67.2257524,50.2635078 L75.979676,50.2635078 L75.979676,47.0981408 L76.9420686,47.0981408 L76.9420686,50.2635078 L85.6969546,50.2635078 L85.6969546,43.9318182 L86.6593473,43.9318182 L86.6593473,50.2635078 L95.4123085,50.2635078 L95.4123085,47.0981408 L96.3747012,47.0981408 L96.3747012,50.2635078 L101.968127,50.2635078 C102.683185,50.2635078 103.263508,49.6872046 103.263508,48.9770996 L103.263508,38.8865368 C103.263508,38.1764318 102.683185,37.6001286 101.968127,37.6001286"
        id="place-order-path-8"
      />
      <filter
        x="-20.3%"
        y="-79.0%"
        width="127.1%"
        height="257.9%"
        filterUnits="objectBoundingBox"
        id="place-order-filter-9"
      >
        <feOffset dx="-5" dy="0" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <polyline id="place-order-path-10" points="128.584 6.0807 99.029 36.6267 87 23.2287" />
      <filter
        x="-23.6%"
        y="-22.1%"
        width="147.3%"
        height="163.2%"
        filterUnits="objectBoundingBox"
        id="place-order-filter-11"
      >
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology radius="1" operator="erode" in="SourceAlpha" result="shadowInner" />
        <feOffset dx="0" dy="3" in="shadowInner" result="shadowInner" />
        <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g id="place-order-Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="place-order-menguin-welcome" transform="translate(-870.000000, -389.000000)">
        <g id="place-order-Group-3" transform="translate(875.000000, 389.000000)">
          <rect id="place-order-Rectangle-Copy-2" fill="#FFFFFF" x="22" y="14" width="90" height="59" />
          <g id="place-order-Rectangle-Copy-3">
            <use fill="black" fillOpacity="1" filter="url(#place-order-filter-3)" xlinkHref="#place-order-path-2" />
            <use fill="url(#place-order-linearGradient-1)" fillRule="evenodd" xlinkHref="#place-order-path-2" />
          </g>
          <g id="place-order-Rectangle-Copy-4">
            <use fill="black" fillOpacity="1" filter="url(#place-order-filter-5)" xlinkHref="#place-order-path-4" />
            <use fill="url(#place-order-linearGradient-1)" fillRule="evenodd" xlinkHref="#place-order-path-4" />
          </g>
          <g id="place-order-Rectangle-Copy-5">
            <use fill="black" fillOpacity="1" filter="url(#place-order-filter-7)" xlinkHref="#place-order-path-6" />
            <use fill="url(#place-order-linearGradient-1)" fillRule="evenodd" xlinkHref="#place-order-path-6" />
          </g>
          <g
            id="place-order-Fill-1"
            transform="translate(66.331690, 43.931818) rotate(-90.000000) translate(-66.331690, -43.931818) "
          >
            <use fill="black" fillOpacity="1" filter="url(#place-order-filter-9)" xlinkHref="#place-order-path-8" />
            <use fill="#00B8FF" fillRule="evenodd" xlinkHref="#place-order-path-8" />
          </g>
          <g id="place-order-Page-1" strokeLinecap="round" strokeLinejoin="round">
            <use fill="black" fillOpacity="1" filter="url(#place-order-filter-11)" xlinkHref="#place-order-path-10" />
            <use stroke="#00B8FF" strokeWidth="2" xlinkHref="#place-order-path-10" />
          </g>
          <rect id="place-order-Rectangle-5" fill="#525254" x="12" y="10" width="24" height="10" />
          <rect id="place-order-Rectangle-5-Copy" fill="#525254" x="12" y="45" width="24" height="10" />
          <path
            d="M108,72 L96,72"
            id="place-order-Path-3-Copy-2"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M117,66 L96,66"
            id="place-order-Path-3-Copy-3"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PlaceOrder;
