import React from 'react';
import { AddMembers } from '../artwork/artwork';
import { AddMembers as AddMembersMenguin } from '../artwork/artwork-menguin';
import { CreateLooks } from '../artwork/artwork';
import { CreateLooks as CreateLooksMenguin } from '../artwork/artwork-menguin';
import { PlaceOrder } from '../artwork/artwork';
import { PlaceOrder as PlaceOrderMenguin } from '../artwork/artwork-menguin';
import LogoGentux from '../components/LogoGentux';
import LogoMenguin from '../components/LogoMenguin';
import { promos } from '../data/promos';

export const themeComponents: {
  [index: string]: {
    [index: string]: (props: any) => React.ReactElement<any>;
  };
} = {
  welcomeLogo: {
    gentux: (props: any) => React.createElement(LogoGentux, { className: 'w-[50px] h-[50px]' }),
    menguin: (props: any) => React.createElement(LogoMenguin, { className: 'w-[50px] h-[50px]' }),
  },
  logo: {
    gentux: (props: any) =>
      React.createElement(LogoGentux, {
        className: 'w-40 h-40 block',
      }),
    menguin: (props: any) =>
      React.createElement(LogoMenguin, {
        className: 'w-40 h-40 block',
      }),
  },
  emptyLook: {
    gentux: (props: any) =>
      React.createElement('img', {
        src: 'https://gentux.imgix.net/1531328048_empty.png',
        alt: 'empty look',
      }),
    menguin: (props: any) =>
      React.createElement('img', {
        src: 'https://gentux.imgix.net/1524775934_empty.jpg',
        alt: 'empty look',
      }),
  },
  navLogo: {
    gentux: (props: any) =>
      React.createElement(LogoGentux, {
        className: 'mr-3 w-40 h-40 min-w-40 text-black',
      }),
    menguin: (props: any) =>
      React.createElement(LogoMenguin, {
        className: 'mt-1 mr-3 w-40 h-40 min-w-40 text-white',
      }),
  },
  addMembers: {
    gentux: (props: any) => React.createElement(AddMembers, props),
    menguin: (props: any) => React.createElement(AddMembersMenguin, props),
  },
  createLooks: {
    gentux: (props: any) => React.createElement(CreateLooks, props),
    menguin: (props: any) => React.createElement(CreateLooksMenguin, props),
  },
  placeOrder: {
    gentux: (props: any) => React.createElement(PlaceOrder, props),
    menguin: (props: any) => React.createElement(PlaceOrderMenguin, props),
  },
};

export const themeStyles: {
  [index: string]: { [index: string]: string };
} = {
  swatchPosition: {
    gentux: 'top',
    menguin: 'center',
  },
};

export const themeCopy: {
  [index: string]: {
    [index: string]: string;
  };
} = {
  brandName: {
    gentux: 'Generation Tux',
    menguin: 'Menguin',
  },
  memberDisclaimer: {
    gentux: `*Free Rental: Applies to wedding events only. Jacket and pants are required for the free rental promotion. With ${promos.freeTux.requirements.paidMembers} paid rentals (jacket and pants minimum), the wedding couple will receive one suit or tuxedo rental for free. Discount cannot be applied to rush fees, taxes or alterations. Discount offer may not be combined with other offers or be redeemed for cash or credit. Limit one per event. Promo Code: ${promos.freeTux.code}. Discount expires ${promos.freeTux.expiresAt}. `,
    menguin: `*Jacket and pants are required for the free rental promotion. Applies to wedding events only. With ${promos.freeSuit.requirements.paidMembers} paid rentals (jacket and pants minimum), the wedding couple will receive one suit or tuxedo rental for free. Discount cannot be applied to rush fees, taxes or alterations. Discount offer may not be combined with other offers or be redeemed for cash or credit. Limit one per event. Promo Code: ${promos.freeSuit.code}. Discount expires ${promos.freeSuit.expiresAt}. `,
  },
};

export const themeImages: {
  [index: string]: {
    [index: string]: string;
  };
} = {
  swatchImage: {
    gentux: 'https://gentux.imgix.net/1531319767_2018-07-10-gentux-swatches-trimmed.png',
    menguin: 'https://gentux.imgix.net/1527278516_menguin-swatches.png',
  },
  roundedMuscular: {
    gentux: 'https://gentux.imgix.net/1531418666_gt-fit-rounded-muscular.png',
    menguin: 'https://gentux.imgix.net/1531417987_rounded-muscular.png',
  },
  roundedAverage: {
    gentux: 'https://gentux.imgix.net/1531418666_gt-fit-rounded-average.png',
    menguin: 'https://gentux.imgix.net/1531417987_rounded-average.png',
  },
  averageAverage: {
    gentux: 'https://gentux.imgix.net/1531418665_gt-fit-average-average.png',
    menguin: 'https://gentux.imgix.net/1531417987_average-average.png',
  },
  averageMuscular: {
    gentux: 'https://gentux.imgix.net/1531418665_gt-fit-average-muscular.png',
    menguin: 'https://gentux.imgix.net/1531417987_average-muscular.png',
  },
  roundedSkinny: {
    gentux: 'https://gentux.imgix.net/1531418666_gt-fit-rounded-skinny.png',
    menguin: 'https://gentux.imgix.net/1531417988_rounded-skinny.png',
  },
  averageSkinny: {
    gentux: 'https://gentux.imgix.net/1531418665_gt-fit-average-skinny.png',
    menguin: 'https://gentux.imgix.net/1531417988_average-skinny.png',
  },
  flatAverage: {
    gentux: 'https://gentux.imgix.net/1531418665_gt-fit-flat-average.png',
    menguin: 'https://gentux.imgix.net/1531417988_flat-average.png',
  },
  flatMuscular: {
    gentux: 'https://gentux.imgix.net/1531418665_gt-fit-flat-muscular.png',
    menguin: 'https://gentux.imgix.net/1531417988_flat-muscular.png',
  },
  flatSkinny: {
    gentux: 'https://gentux.imgix.net/1531418666_gt-fit-flat-skinny.png',
    menguin: 'https://gentux.imgix.net/1531417988_flat-skinny.png',
  },
};

export const themeUrls: {
  [index: string]: {
    [index: string]: string;
  };
} = {
  smsTerms: {
    gentux: '/sms-terms-and-conditions',
    menguin: '/support/sms-terms-and-conditions',
  },
  smsPrivacy: {
    gentux: '/sms-privacy',
    menguin: '/support/sms-privacy-policy',
  },
};
