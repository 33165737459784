import { Component } from 'react';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { FlowRouteProps } from '../../types';
import EventStore from '../../stores/EventStore';
import CustomerStore from '../../stores/CustomerStore';
import Flow from '../../utils/HOC/Flow';
import Loading from '../../utils/Component/Loading';
import { Link } from 'react-router-dom';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';

interface State {
  loading: boolean;
}

class ExistingHTO extends Component<FlowRouteProps<any>, State> {
  constructor(props: FlowRouteProps<any>) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    if (!EventStore.event.id) {
      await EventStore.loadEvent(window.gt.user.primaryEventId);
    }
    this.setState({ loading: false });
  }

  nextPage = () => {
    this.onExit();
  };

  onExit = async () => {
    // Direct the flow of the /hto/redirect stream depending on what customer/event info we have
    let outflowIndex = 0;

    if (window.gt.user.firstName === '' || window.gt.user.lastName === '') {
      outflowIndex = 1;
    }

    const params = new URLSearchParams(this.props.location.search);
    if (EventStore.event.id) {
      params.set('eventId', EventStore.event.id!);
    } else {
      outflowIndex = 2;
    }

    params.set('edit', CustomerStore.customer.id!);
    this.props.flow!(`?${params.toString()}`, outflowIndex);
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-md space-y-32">
            <h1 className="text-h2-display">You currently have a Home Try-On that hasn't been returned.</h1>
            <p className="text-gray-dark">
              You can{' '}
              <Link
                to={`/account/orders${this.props.location.search}`}
                className="tracker-link-existing_hto-view_orders-200619-111519"
              >
                view your orders
              </Link>
              . If you have any questions please contact&nbsp;
              <a
                href={`${process.env.REACT_APP_ECOMM_URL}/support`}
                className="tracker-text-existing_hto-body-211116-113540"
              >
                support
              </a>
              .
            </p>

            <div className={`flex flex-row-reverse gap-8`}>
              <button
                role="link"
                onClick={() => this.nextPage()}
                className="tracker-cta-existing_hto-view_orders-200619-111519 btn btn-info grow"
              >
                Next <IconArrowRight />
              </button>

              <button
                onClick={() => (window.location.href = `${process.env.REACT_APP_ECOMM_URL}/home-try-on`)}
                className="tracker-cta-existing_hto-view_orders-200619-111519 btn btn-default"
              >
                <IconArrowLeft />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(ExistingHTO);
