import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import InvitedMemberFormStore from '../../stores/InvitedMemberFormStore';
import Flow from '../../utils/HOC/Flow';
import { AccessContext } from '../../utils/HOC/';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import { getUrlToTermsPage } from '../../utils/utils';
import { Link } from 'react-router-dom';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  password?: string;
  loading: boolean;
  error?: string;
}

class Password extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
      loading: false,
      error: undefined,
    };
  }
  componentDidMount() {
    this.setState({
      password: InvitedMemberFormStore.password,
    });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value });

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.password && this.state.password.length < 8) {
      return this.setState(() => ({ error: 'The password must be at least 8 characters.' }));
    }
    InvitedMemberFormStore.update('password', this.state.password);

    this.nextPage();
  };

  nextPage = () => {
    this.setState(() => ({
      error: undefined,
    }));

    this.props.flow!(this.props.location.search);
  };

  render() {
    const customSubtitle = (
      <p className="text-center">
        By creating an account I agree to the <br />
        <Link to={getUrlToTermsPage(window.organization)}>Terms and Conditions</Link>.
      </p>
    );

    return (
      <>
        <FormFlowLogo />

        <div>
          <FormSingleInput
            key="password"
            property="password"
            label="Password"
            type="password"
            title="Set a password."
            customSubtitle={customSubtitle}
            value={this.state.password!}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            error={this.state.error!}
            loading={this.state.loading}
          />
        </div>
      </>
    );
  }
}

export default Flow(AccessContext(observer(Password)));
