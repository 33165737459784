import { useEffect, useState, type ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import type { FlowRouteProps, Address } from 'types';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';
import Message from '../../components/Message';
import ConfirmAddress from '../../utils/Component/ConfirmAddress';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { AccessContext } from '../../utils/HOC';
import Flow from '../../utils/HOC/Flow';
import usStates from '../../utils/usStates';
import { isPOBox } from '../../utils/utils';
import { welcomePacketTrack } from '../../utils/metrics';
import auth from '../../services/Auth';
import { validateAddress as validateAddressService } from '../../services/Warehouse';
import AddressesStore from '../../stores/AddressesStore';
import CustomerStore from '../../stores/CustomerStore';

export type AddressFormProps = {} & FlowRouteProps<any>;

type FormState = Pick<Address, 'addressLine1' | 'city' | 'state' | 'zip'>;

const initialFormState: FormState = {
  addressLine1: '',
  city: '',
  state: '',
  zip: '',
};

type ErrorState = {
  addressLine1: boolean;
  city: boolean;
  poBox: boolean;
  state: boolean;
  zip: boolean;
};

const initialErrorState: ErrorState = {
  addressLine1: false,
  city: false,
  poBox: false,
  state: false,
  zip: false,
};

type State = {
  confirmAddress: boolean;
  error?: string;
  isSubmitting: boolean;
  outflowIndex: number;
  suggestedAddress: Address;
  useDefault: boolean;
};

const initialState: State = {
  confirmAddress: false,
  error: undefined,
  isSubmitting: false,
  outflowIndex: 0,
  suggestedAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: 'US',
    firstName: '',
    id: undefined,
    isDefault: true,
    lastName: '',
    state: '',
    zip: '',
  },
  useDefault: false,
};

const errorMsgRequired = 'This field is required.';

const loadAddresses = async () => {
  await AddressesStore.loadAddresses();
};

let didSendViewEvent = false;

const AddressForm = (props: AddressFormProps) => {
  const [form, setForm] = useState<FormState>(initialFormState);
  const [errors, setErrors] = useState<ErrorState>(initialErrorState);
  const [state, setState] = useState<State>(initialState);
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  useEffect(() => {
    loadAddresses();

    setState({
      ...state,
      useDefault: AddressesStore.currentAddress.id ? AddressesStore.currentAddress.isDefault : false,
    });

    if (!didSendViewEvent) {
      didSendViewEvent = true;
      welcomePacketTrack.pageview();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange =
    (field: string) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newFormState = { ...form, [field]: value };

      // if at least one field has value mark the form for validation on submit
      if (JSON.stringify(newFormState) !== JSON.stringify(initialFormState)) {
        setShouldValidate(true);
      }

      AddressesStore.handleCurrentAddressChange(field, value);
      setForm(newFormState);

      // also clear error while updating field
      if (field === 'addressLine1') {
        setErrors({ ...errors, [field]: false, poBox: false });
      } else {
        setErrors({ ...errors, [field]: false });
      }
    };

  const goBack = (ev: React.SyntheticEvent<HTMLButtonElement>) => {
    ev.preventDefault();

    return props.history.goBack();
  };

  const nextPage = () => {
    setState({
      ...state,
      error: undefined,
      confirmAddress: false,
    });

    props.flow!(props.location.search);
  };

  const addOrUpdateAddress = async () => {
    try {
      await AddressesStore.addOrUpdateAddress();

      welcomePacketTrack.request({
        addressLine1: AddressesStore.currentAddress.addressLine1,
        addressLine2: AddressesStore.currentAddress.addressLine2,
        city: AddressesStore.currentAddress.city,
        country: AddressesStore.currentAddress.country,
        state: AddressesStore.currentAddress.state,
        zip: AddressesStore.currentAddress.zip,
      });

      nextPage();
    } catch (err) {
      setState({
        ...state,
        confirmAddress: false,
        isSubmitting: false,
        error: 'There was a problem creating or updating this address. Please try again.',
      });
    }
  };

  const setSuggestionAddress = () => {
    AddressesStore.setCurrentAddress(state.suggestedAddress);
    addOrUpdateAddress();
  };

  const unconfirmAddress = () => {
    setState({
      ...state,
      confirmAddress: false,
      isSubmitting: false,
    });
  };

  const validateInput = () => {
    const addressLine1 = form.addressLine1 === '';
    const city = form.city === '';
    const state = form.state === '';
    const zip = form.zip === '';
    const poBox = isPOBox(form.addressLine1);

    setErrors({
      addressLine1,
      city,
      state,
      zip,
      poBox,
    });

    return !(addressLine1 || city || state || zip || poBox);
  };

  const validateAddress = async () => {
    try {
      const {
        validatedAddress,
        addressChanged,
      }: {
        validatedAddress: {
          addressLine1: string;
          addressLine2: string;
          city: string;
          state: string;
          zip: string;
        };
        addressChanged: boolean;
      } = await validateAddressService({
        addressLine1: form.addressLine1,
        addressLine2: '',
        city: form.city,
        state: form.state,
        zip: form.zip,
        country: 'US',
      });

      CustomerStore.loadCustomer(auth.user());

      const fullAddress = {
        id: AddressesStore.currentAddress.id,
        firstName: AddressesStore.currentAddress.firstName || CustomerStore.firstName || '',
        lastName: AddressesStore.currentAddress.lastName || CustomerStore.lastName || '',
        addressLine1: `${validatedAddress.addressLine1} ${
          validatedAddress.addressLine2 ? validatedAddress.addressLine2 : ''
        }`,
        city: validatedAddress.city,
        state: validatedAddress.state,
        zip: validatedAddress.zip,
        isDefault: true,
        country: 'US',
      };

      // If the address has changed we want to suggest the changes to the
      // user.
      if (addressChanged) {
        // set the address and the confirm address modal
        setState({
          ...state,
          suggestedAddress: fullAddress,
          confirmAddress: true,
        });
      } else {
        AddressesStore.setCurrentAddress(fullAddress);

        addOrUpdateAddress();
      }
    } catch {
      setState({
        ...state,
        isSubmitting: false,
        error: 'This address is invalid. Please re-enter a valid address and try again.',
      });
    }
  };

  const onSubmit = async (ev: React.SyntheticEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setState({ ...state, isSubmitting: true });

    if (!shouldValidate) {
      nextPage();
    } else if (shouldValidate && validateInput()) {
      validateAddress();
    } else {
      setState({ ...state, isSubmitting: false });
    }
  };

  return (
    <>
      <FormFlowLogo />

      <div className="container">
        <div className="mx-auto max-w-md">
          <h2 className="text-h2-display mb-16">{'Get The Free Wedding Welcome Packet'}</h2>
          <p className="text-md mb-16">{'(8 out of 9 Brides & Grooms Found this Helpful)'}</p>
          <p className="text-xs text-gray-dark">{'Your information is NEVER sold and is always confidential.'}</p>

          {state.confirmAddress ? (
            <ConfirmAddress
              suggestedAddressStreet={state.suggestedAddress.addressLine1}
              suggestedAddressCity={state.suggestedAddress.city}
              suggestedAddressState={state.suggestedAddress.state}
              suggestedAddressZip={state.suggestedAddress.zip}
              currentAddressStreet={AddressesStore.currentAddress.addressLine1}
              currentAddressCity={AddressesStore.currentAddress.city}
              currentAddressState={AddressesStore.currentAddress.state}
              currentAddressZip={AddressesStore.currentAddress.zip}
              setEntryAddress={addOrUpdateAddress}
              setSuggestionAddress={setSuggestionAddress}
              unconfirmAddress={unconfirmAddress}
            />
          ) : (
            <>
              <form className="w-full" onSubmit={onSubmit}>
                <div className="mb-32 mt-32 xs:mb-64">
                  <div className="my-16">
                    <label htmlFor="street" className="sr-only">
                      {'Street Address'}
                    </label>

                    <FormInput
                      className="text-sm w-full border-gray"
                      id="street"
                      name="street"
                      type="text"
                      placeholder="Street Address"
                      value={form.addressLine1}
                      onChange={handleChange('addressLine1')}
                    />
                    {errors.addressLine1 && <Message type="error" message={errorMsgRequired} />}
                    {errors.poBox && <Message type="error" message="Address cannot be a P.O. Box" />}
                  </div>

                  <div className="my-16">
                    <label htmlFor="city" className="sr-only">
                      {'City'}
                    </label>

                    <FormInput
                      className="text-sm w-full border-gray"
                      id="city"
                      type="text"
                      name="city"
                      placeholder="City"
                      value={form.city}
                      onChange={handleChange('city')}
                    />
                    {errors.city && <Message type="error" message={errorMsgRequired} />}
                  </div>

                  <div className="my-16">
                    <label htmlFor="state" className="sr-only">
                      {'Select State'}
                    </label>

                    <FormSelect
                      className="text-sm w-full border-gray bg-gray-lighter"
                      id="stateSelect"
                      value={form.state}
                      onChange={handleChange('state')}
                    >
                      <option value="">{'Select State'}</option>
                      {usStates.map((state) => (
                        <option key={`${state.abbreviation}-${state.name}`} value={state.abbreviation}>
                          {state.name}
                        </option>
                      ))}
                    </FormSelect>
                    {errors.state && <Message type="error" message={errorMsgRequired} />}
                  </div>

                  <div className="my-16">
                    <label htmlFor="zip" className="sr-only">
                      {'Zip Code'}
                    </label>

                    <FormInput
                      className="text-sm w-full border-gray"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      id="zip"
                      type="number"
                      name="zip"
                      placeholder="Zip Code"
                      value={form.zip}
                      onChange={handleChange('zip')}
                    />
                    {errors.zip && <Message type="error" message={errorMsgRequired} />}
                  </div>
                </div>

                <div className="mb-32 mt-32 flex flex-row-reverse gap-8 xs:mb-64">
                  <button
                    className="tracker-cta-address-submit-231003-111519 btn btn-info grow"
                    data-testid="btn-submit"
                    disabled={state.isSubmitting}
                    role="link"
                    type="submit"
                  >
                    {'Next'} <IconArrowRight />
                  </button>
                  <button
                    className="tracker-button-address-back-231003-111520 btn btn-default"
                    onClick={goBack}
                    aria-label="Back"
                  >
                    {<IconArrowLeft />}
                  </button>
                </div>

                {state.error && <Message type="error" message={state.error} />}
              </form>

              <p className="text-center">
                <button
                  className="tracker-cta-address-skip-231003-111521 text-sm text-gray-dark underline underline-offset-4"
                  data-testid="btn-skip"
                  onClick={() => nextPage()}
                  role="link"
                >
                  {"I don't want the free packet"}
                </button>
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Flow(AccessContext(observer(AddressForm)));
