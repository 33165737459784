import { Fragment } from 'react';
import { ProductToMember, Product, Bundle } from '../../types';
import LookPreview from '../../look-builder/components/look-builder/preview/LookPreview';
import { formatCurrency } from '../../utils/utils';

interface Props {
  products: ProductToMember[];
  showCost?: boolean;
}

const productLayers = (items: ProductToMember[]) =>
  items.reduce((acc, item) => {
    if (item.product.category! === 'preconfigured') {
      return [...acc, ...(item.product as Bundle).products!];
    }

    return [...acc, item.product! as Product];
  }, []);

const ProductList = ({ products, showCost = true }: Props) => (
  <div className="flex flex-col items-start justify-between py-16 xs:flex-row sm:py-32">
    <div className="mx-auto max-w-[50%] xs:max-w-[30%]">
      <LookPreview items={productLayers(products)} />
    </div>

    <div className="mr-16 grid" style={{ gridTemplateColumns: showCost ? 'auto 1fr' : '1fr' }}>
      {products
        .sort((a, b) => Number(b.product.cost) - Number(a.product.cost))
        .map((p) => (
          <Fragment key={`${p.product.id}-${p.product.displayName}`}>
            <p className="text-sm mb-4 mr-8">{p.product.displayName}</p>
            {showCost && <p className="text-sm text-right">{formatCurrency(Number(p.product.cost))}</p>}
          </Fragment>
        ))}
    </div>
  </div>
);

export default ProductList;
