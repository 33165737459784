import React from 'react';

interface IconPlusProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconPlusProps>;

const IconPlus: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current ${className ? className : ''}`}
    >
      <title>Plus</title>

      <path fillRule="evenodd" clipRule="evenodd" d="M7.5 12.5V3.5H8.5V12.5H7.5Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 8.5H3.5V7.5H12.5V8.5Z" />
    </svg>
  );
};

export default IconPlus;
