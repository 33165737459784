import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getInvitedMemberDetails } from '../../services/Events';
import { Member, FlowEventRouteProps, Item } from '../../types';
import { isParticipant, objectToCamel } from '../../utils/utils';
import { url } from '../../utils/window';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import Flow from '../../utils/HOC/Flow';
import { IsEventMember } from '../../utils/HOC';
import Loading from '../../utils/Component/Loading';
import LookPreview from '../../look-builder/components/look-builder/preview/LookPreview';
import Line from '../../components/Line';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';

interface Props extends FlowEventRouteProps<{}> {}

interface State {
  member?: Member;
  error?: string;
  loading?: Boolean;
  b2b?: Boolean;
  items: Item[];
}

class OutfitPreview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      member: undefined,
      error: '',
      loading: true,
      b2b: false,
      items: [],
    };
  }

  async componentDidMount() {
    try {
      const res = await getInvitedMemberDetails(this.props.eventId);

      if (res.status !== 200) {
        this.setState({ error: 'Unable to retrieve event member information', loading: false });
      }
      const response = await res.json();
      const memberId = url.param(this.props.location.search, 'memberId')!;
      const member = response.event.members.find((m: Member) => m.id === memberId);

      const b2b =
        response.event.lastCampaign &&
        response.event.lastCampaign.attributions &&
        response.event.lastCampaign.attributions.filter((a: any) => a.key === 'REF_SOURCE' && a.value === 'B2B')
          .length > 0;

      this.setState({
        member: { ...member, isParticipant: isParticipant(member.role.name) },
        b2b,
        loading: false,
        items: member.role.bundles.concat(member.role.items).map((item: Item) => objectToCamel(item)),
      });
    } catch {
      this.setState({ error: `We're sorry, something went wrong. Please try again.`, loading: false });
    }
  }

  nextPage = () => {
    this.props.flow!(`${this.props.location.search}`);
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <div className="relative">
          <FormFlowLogo />

          <div className="absolute bottom-0 right-0 top-0 mr-16 flex items-center sm:mr-32">
            <button
              tabIndex={0}
              className="tracker-text-invited_member-logout-20201020-103304 btn btn-sm btn-info-outline"
              onClick={() => {
                window.localStorage.removeItem('jwt');
                window.location.replace('/account/logout');
              }}
            >
              Log Out
            </button>
          </div>
        </div>

        <div className="bg-gray-lighter xs:py-32 md:py-64">
          <div className="container">
            <div className="row">
              <div className="col-span-12 xs:col-span-6 md:col-span-5 lg:col-span-6 xl:col-span-5">
                <LookPreview items={this.state.items} />
              </div>

              <div className="col-span-12 xs:col-span-6 lg:col-span-5">
                <div className="space-y-32">
                  <h2 className="text-h2-display">Your Look</h2>

                  <Line />

                  <div className="grid gap-8" style={{ gridTemplateColumns: 'auto 1fr' }}>
                    {this.state.items.map((item) => (
                      <React.Fragment key={`${item.id}-${item.name}`}>
                        <p className="text-sm text-gray-dark">{`$${item.cost}`}</p>

                        <p className="text-sm text-gray-dark">{item.displayName}</p>
                      </React.Fragment>
                    ))}
                  </div>

                  <Line />

                  <h3 className="text-h4">
                    ${this.state.items.reduce((identity, item) => identity + parseInt(item.cost!, 10), 0)} Total
                  </h3>

                  <div className="grid gap-8" style={{ gridTemplateColumns: 'auto 1fr' }}>
                    <button
                      onClick={() => {
                        this.props.history.push(
                          `/fit-flow/invited/preference?memberId=${this.state.member!.id}&eventId=${this.props.eventId}`
                        );
                      }}
                      aria-label="Previous"
                      className="tracker-cta-outfit_preview-back-200619-111519 btn btn-default"
                    >
                      <IconArrowLeft />
                    </button>

                    <button
                      type="submit"
                      onClick={() => this.nextPage()}
                      className="tracker-cta-outfit_preview-to_checkout-200619-111519 btn btn-info"
                    >
                      {'Proceed to Checkout'} <IconArrowRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(IsEventMember(observer(OutfitPreview)));
