import React from 'react';

interface IconLockProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconLockProps>;

const IconLock: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Lock</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M11 7H5v5h6V7zm1-1v7H4V6h8z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3c-1.206 0-2 .858-2 1.7V6h4V4.7C10 3.858 9.206 3 8 3zm3 1.7C11 3.209 9.657 2 8 2S5 3.209 5 4.7V7h6V4.7z"
      />
    </svg>
  );
};

export default IconLock;
