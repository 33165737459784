import { observable, action } from 'mobx';

interface CheckoutResponse {
  damageWaiverFees: number;
  discount: number;
  eventDate: string;
  eventId: string;
  last4: string;
  orderDate: string;
  chargeDate: string;
  orderId: string;
  rushCost: number;
  shipAddressLine1: string;
  shipCity: string;
  shipFirstName: string;
  shipLastName: string;
  shipState: string;
  shipZip: string;
  shippingCost: number;
  subTotal: number;
  tax: number;
  total: number;
  rentalCost: number;
}

class CheckoutStore {
  @observable response: CheckoutResponse = {
    damageWaiverFees: 0,
    discount: 0,
    eventDate: '',
    eventId: '',
    last4: '',
    orderDate: '',
    chargeDate: '',
    orderId: '',
    rushCost: 0,
    shipAddressLine1: '',
    shipCity: '',
    shipFirstName: '',
    shipLastName: '',
    shipState: '',
    shipZip: '',
    shippingCost: 0,
    subTotal: 0,
    tax: 0,
    total: 0,
    rentalCost: 0,
  };

  @action
  setResponse = (response: CheckoutResponse) => (this.response = response);
}

export default new CheckoutStore();
