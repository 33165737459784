import { Component } from 'react';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { FlowEventRouteProps, GetMeasurementsResponse } from '../../types';
import Flow from '../../utils/HOC/Flow';
import InvitedMemberFlowStore from '../../stores/InvitedMemberFlowStore';
import { getMeasurements } from '../../services/Fit';
import { Transition } from '@headlessui/react';
import Spinner from '../../shared/components/Spinner';
import FitPreview from '../../shared/components/FitPreview';
import Line from '../../components/Line';
import { pageFadeIn } from '../../utils/Component/Animations';

interface Props extends FlowEventRouteProps<{}> {}

interface State {
  measurements: GetMeasurementsResponse | null;
  loading: boolean;
}

class ConfirmFit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      measurements: null,
    };
  }

  async componentDidMount() {
    this.setState({
      measurements: (await getMeasurements()) as GetMeasurementsResponse,
      loading: false,
    });
  }

  nextPage = (input: 'yes' | 'no') => {
    InvitedMemberFlowStore.updateFitConfirmed(true);

    if (input === 'yes') {
      return this.props.history.push(`/invited/preview${this.props.location.search}`);
    }

    return this.props.history.push(`/fit-flow/invited/height${this.props.location.search}`);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-sm space-y-32">
            <h2 className="text-h2-display">Confirm Your Fit.</h2>

            <Line />

            <p className="text-gray-dark">
              Looks like you have entered fit with us before. Are these sizes are still accurate?
            </p>

            <div className="relative">
              {this.state.loading ? (
                <Transition key={1} {...pageFadeIn}>
                  <div className="py-128 md:py-32">
                    <Spinner className="h-[30vh]" type="minimal" />
                  </div>
                </Transition>
              ) : (
                <Transition
                  key={2}
                  appear
                  show
                  enter="transition duration-300"
                  enterFrom="opacity-0 scale-75"
                  enterTo="opacity-100"
                >
                  <FitPreview measurements={this.state.measurements!} />
                </Transition>
              )}
            </div>

            <div className="flex flex-col gap-8 sm:flex-row-reverse">
              <button
                role="link"
                onClick={() => this.nextPage('yes')}
                className="tracker-cta-confirm-fit-yes-200619-031221 btn btn-info grow"
              >
                Yes
              </button>

              <button
                role="link"
                onClick={() => this.nextPage('no')}
                className="tracker-cta-confirm-fit-no-200619-031221 btn btn-default grow"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(ConfirmFit);
