import { Component } from 'react';
import { observer } from 'mobx-react';

import { GlobalContextTyping, FlowRouteProps } from '../../types';
import { HTO_MAX_DAYS } from '../../constants';
import { hasUsedDefaultEventDate, getAbbreviationForMonth } from './date-picker/utils';
import Message from '../../components/Message';
import DatePicker, { CutOff } from '../../shared/components/DatePicker';
import EventFormStore from '../../stores/EventFormStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import FormNavigation from '../../utils/Component/FormNavigation';
import Loading from '../../utils/Component/Loading';
import { AccessContext } from '../../utils/HOC';
import Flow from '../../utils/HOC/Flow';
import { getStringCookie, getSupportNumber } from '../../utils/utils';

const organizationId = Number(process.env.REACT_APP_ORGANIZATION_ID ?? 1);

type Props = FlowRouteProps<any> & {
  globalContext?: GlobalContextTyping;
  inHtoFlow?: boolean;
};

type State = {
  error?: string;
  loading: boolean;
  selectedDate: Date;
};

class EventDate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: undefined,
      loading: false,
      selectedDate: new Date(),
    };
  }

  componentDidMount() {
    if (EventFormStore.day && EventFormStore.month && EventFormStore.year) {
      this.setState({
        selectedDate: new Date(EventFormStore.year, EventFormStore.month - 1, EventFormStore.day),
      });
    }
  }

  onSubmit = async () => {
    this.setState({ loading: true });

    const year = this.state.selectedDate.getFullYear();
    const month = this.state.selectedDate.getMonth() + 1;
    const day = this.state.selectedDate.getDate();

    EventFormStore.update('day', day);
    EventFormStore.update('month', month);
    EventFormStore.update('year', year);
    EventFormStore.update('usedDefaultEventDate', hasUsedDefaultEventDate(day, month, year));
    EventFormStore.update('source', this.props.history.location.pathname);

    const campaignId = getStringCookie('iterableEmailCampaignId');

    if (campaignId !== undefined) {
      const cookie = { campaignId: Number(campaignId) };
      EventFormStore.update('metadata', JSON.stringify(cookie));
    }

    this.onExit();
  };

  onExit = () => this.props.flow!(this.props.location.search, 0);

  setSelectedDate = (selectedDate: Date) => this.setState({ selectedDate });

  render() {
    const { loading, error } = this.state;

    return (
      <>
        <FormFlowLogo />

        <div className="container">
          {loading && <Loading />}
          {error && <Message type="error">{error}</Message>}

          {!loading && !error && (
            <div className="mx-auto max-w-lg">
              <h1 className="text-h2-display mb-64 text-center">{'What is your event date?'}</h1>
              <p className="text-md mb-24 text-center">
                {`Less than ${this.props.inHtoFlow ? HTO_MAX_DAYS : 14} days from your event? Call us:`}{' '}
                <a href={getSupportNumber(organizationId).asTelLink()}>{getSupportNumber(organizationId).value}</a>
              </p>

              <form onSubmit={this.onSubmit}>
                <h6 className="text-h6 mb-8 text-gray-dark">{'Event Date'}</h6>

                <div className="space-between mb-32 flex items-center gap-8">
                  <DatePicker
                    cutOff={this.props.inHtoFlow ? CutOff.NearestEventDateWithHto : CutOff.NearestEventDate}
                    onChange={(eventDate) => this.setSelectedDate(eventDate)}
                    formatMonth={(m) => getAbbreviationForMonth(m)}
                  />
                </div>

                <FormNavigation next={this.onSubmit} />
              </form>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Flow(AccessContext(observer(EventDate)));
