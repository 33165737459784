import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconPocketSquare: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-[17px] w-[17px] stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Pocket Square Icon</title>
      <path d="M12.5282 4.4736l1.7894 1.7895L16.5545 8.5l-8.0527 8.0526L.4492 8.4999l2.2369-2.2368 1.7894-1.7895" />
      <path d="M2.6836 6.263L8.4994.4474l5.8158 5.8158M2.6836 6.2632h11.6316M14.3152 6.2632L8.4994 12.079 2.6836 6.2632" />
    </svg>
  );
};

export default IconPocketSquare;
