import React, { useState } from 'react';
import PageHeader from '../../partials/PageHeader';
import NavBarSub from '../../partials/NavBarSub';
import Navbar from '../../partials/Navbar';
import EventStore from '../../stores/EventStore';
import MemberStore from '../../stores/MemberStore';
import PotentialMemberStore from '../../stores/PotentialMemberStore';
import CopyToClipboard from 'react-copy-to-clipboard';
import { EventRouteProps, GlobalContextTyping } from '../../types';
import { IsOwner } from '../../utils/HOC/';
import { observer } from 'mobx-react';
import { isNonParticipant } from '../../utils/utils';
import InviteStatusCard from './InviteStatusCard';
import Line from '../../components/Line';
import Message from '../../components/Message';
import IconCheck from '../../components/IconCheck';
import IconAlert from '../../components/IconAlert';
import FormTextArea from '../../components/FormTextArea';
import IconTriangleDown from '../../components/IconTriangleDown';
import { Disclosure } from '@headlessui/react';
import AnimateHeight from '../../components/AnimateHeight';
import { Transition } from '@headlessui/react';
import { pageFadeIn } from '../../utils/Component/Animations';
import HowItWorks from '../../partials/HowItWorks';
import auth from '../../services/Auth';
import { setCommunicationPreferences } from '../../services/Accounts';
import { MessageMedium, MessageType } from '../../utils/CommunicationPreferences';

interface Props extends EventRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

const InvitePage = (props: Props) => {
  const [hasCopied, setHasCopied] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSendingLink, setIsSendingLink] = useState(false);
  const [generatePartyLinkError, setGeneratePartyLinkError] = useState('');
  const [textToMyselfError, setTextToMyselfError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false);
  const [emails, setEmails] = useState('');

  const generatePartyLink = async () => {
    try {
      setGeneratePartyLinkError('');
      setIsSaving(true);
      await EventStore.createShortCode();
      setIsSaving(false);
    } catch (e) {
      console.error(e);
      setGeneratePartyLinkError('There was an issue generating your link. Try again.');
    }
  };

  const textToMyself = async () => {
    try {
      setTextToMyselfError('');
      setIsSendingLink(true);

      await setCommunicationPreferences(auth.user().id, {
        [MessageMedium.SMS]: {
          [MessageType.Updates]: true,
        },
      });

      await EventStore.sendInviteLinkToMember();
      setIsSendingLink(false);
    } catch (e) {
      console.error(e);
      setTextToMyselfError((e as Error).message);
      setIsSendingLink(false);
    }
  };

  const sendEmails = async () => {
    try {
      setEmailError('');
      setSendEmailSuccess(false);
      setIsSendingEmail(true);
      await EventStore.sendMemberInvites(emails);
      setIsSendingEmail(false);
      setSendEmailSuccess(true);
    } catch (e) {
      console.error(e);
      setEmailError((e as Error).message);
      setIsSendingEmail(false);
    }
  };

  const partyLink =
    EventStore.event.shortCode &&
    `https://${window.gt.orgId === 1 ? 'gentux.me' : 'mengu.in'}/${EventStore.event.shortCode.code}`;

  return (
    <>
      <Navbar />
      <div data-testid="eventflow-invite" className="container">
        <div className="row">
          <div className="col-span-12 sm:col-span-10 sm:col-start-2">
            <div className="m-section mt-32 hidden sm:block">
              <NavBarSub />
            </div>
            <Transition {...pageFadeIn}>
              <PageHeader header="Invite">
                <p>Invite members so they can sign up, get fit, and&nbsp;checkout.</p>
              </PageHeader>

              <div className="row">
                <div className="col-span-12 space-y-64 lg:col-span-9 lg:col-start-4 xl:col-span-7 xl:col-start-4">
                  {EventStore.event.shortCode === null && (
                    <>
                      <div className="mb-32 bg-white p-32 shadow-2xl">
                        <p className="text-red">
                          Finish{' '}
                          <button
                            onClick={() => props.history.push(`/event-flow/looks${props.location.search}`)}
                            className="tracker-link-invite-building_looks-20200619-103658 text-red underline hover:text-black"
                          >
                            building looks
                          </button>{' '}
                          and{' '}
                          <button
                            onClick={() => props.history.push(`/event-flow/assign${props.location.search}`)}
                            className="tracker-link-invite-assign-20200619-103658 text-red underline hover:text-black"
                          >
                            assigning&nbsp;members
                          </button>{' '}
                          before sending&nbsp;invites.
                        </p>

                        <Disclosure as="div" className="mb-16 mt-8">
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="group flex w-full items-center justify-start py-8">
                                <p className="text-sm mr-8 text-gray-dark">More Info</p>

                                <IconTriangleDown
                                  className={`fill-current transition duration-150 group-hover:text-brand-darker ${
                                    open ? '' : 'rotate-90 transform'
                                  }`}
                                />
                              </Disclosure.Button>
                              <AnimateHeight open={open}>
                                <Disclosure.Panel static className="pb-16">
                                  <p className="text-sm text-gray-dark">
                                    Once&nbsp;a member checks out, you won’t be able to change their look without
                                    contacting our customer experience&nbsp;team.
                                  </p>
                                </Disclosure.Panel>
                              </AnimateHeight>
                            </>
                          )}
                        </Disclosure>

                        <button
                          onClick={() => generatePartyLink()}
                          disabled={isSaving}
                          className="tracker-cta-start-20191029-112258 btn btn-info w-full p-32"
                        >
                          {isSaving ? 'Generating...' : 'Start'}
                        </button>
                      </div>
                      {generatePartyLinkError && <p className="text-red">{generatePartyLinkError}</p>}
                    </>
                  )}

                  {typeof EventStore.event.shortCode !== 'undefined' && EventStore.event.shortCode !== null && (
                    <>
                      <div>
                        <h2 className="text-h4 mb-16">Invite link</h2>
                        <div className="bg-gray-light px-16 py-32 xs:px-32 sm:py-64 md:px-64">
                          <p className="normalcase text-h3-display mb-16 text-gray-darker">
                            Text or email this link to your&nbsp;members.
                          </p>
                          <div className="flex flex-wrap items-center justify-between bg-white p-16 shadow-xl">
                            <span className="grow break-words p-8 pl-0 text-center">{partyLink}</span>
                            <div style={{ flexGrow: 1 }}>
                              <CopyToClipboard text={partyLink!}>
                                <button
                                  onClick={() => setHasCopied(true)}
                                  className="tracker-cta-copy-invite-url-20191029-112134 btn btn-info w-full px-16 xs:px-32 md:px-64"
                                >
                                  {hasCopied ? `Copied` : `Copy Link`}
                                </button>
                              </CopyToClipboard>
                            </div>
                            {textToMyselfError && <p className="text-red">{textToMyselfError}</p>}
                          </div>
                        </div>

                        <div className="mt-16 bg-gray-light">
                          <Disclosure as="div" className="tracker-toggle-invite-let_us-191111-111301 mb-16 mt-8">
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="group flex w-full justify-between px-16 py-32 xs:px-32 md:px-64">
                                  <p className="text-h4">Or, let us send the&nbsp;link.</p>

                                  <IconTriangleDown
                                    className={`fill-current transition duration-150 group-hover:text-brand-darker ${
                                      open ? '' : 'rotate-90 transform'
                                    }`}
                                  />
                                </Disclosure.Button>
                                <AnimateHeight open={open}>
                                  <Disclosure.Panel static className="pb-16">
                                    <>
                                      <hr className="border-gray" />
                                      <div className="px-16 py-32 xs:px-32 md:px-64">
                                        <button
                                          disabled={isSendingLink}
                                          onClick={() => textToMyself()}
                                          className="tracker-cta-invite-text_link_to_myself-191111-111432 btn btn-info px-16 xs:px-32 md:px-64"
                                        >
                                          {isSendingLink ? `Sending...` : `Text Link To Myself`}
                                        </button>
                                        <p className="text-sm pt-16 text-gray-dark" style={{ fontSize: '10.24px' }}>
                                          * By clicking the text link, you are opting into SMS&nbsp;communication.
                                        </p>

                                        <Line />

                                        <h5 className="text-h5 mb-1">Email link to your members</h5>
                                        <p className="text-sm mb-16 text-gray-darker">
                                          Enter your member’s email addresses below and we’ll send an invite link
                                          right&nbsp;away.
                                        </p>

                                        <FormTextArea
                                          placeholder="example1@gmail.com, example2@gmail.com"
                                          value={emails}
                                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                            setEmails(e.target.value)
                                          }
                                          className="w-full"
                                        />

                                        <button
                                          onClick={sendEmails}
                                          disabled={isSendingEmail}
                                          className="tracker-cta-invite-send_links-191111-111747 btn btn-primary mt-16 px-16 xs:px-32 md:px-64"
                                        >
                                          Send Links
                                        </button>
                                      </div>
                                      {(sendEmailSuccess || emailError !== '') && (
                                        <div className="bg-gray-light px-16 pb-32 xs:px-32 md:px-64">
                                          {sendEmailSuccess && (
                                            <Message type="success" message="Email(s) successfully sent">
                                              <IconCheck />
                                            </Message>
                                          )}
                                          {emailError !== '' && (
                                            <Message type="error" message={emailError}>
                                              <IconAlert />
                                            </Message>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  </Disclosure.Panel>
                                </AnimateHeight>
                              </>
                            )}
                          </Disclosure>
                        </div>
                      </div>

                      <div>
                        <table className="table w-full border-collapse">
                          <div className="flex flex-wrap xs:table-row">
                            <td className="table-cell pb-32">
                              <h3 className="text-h4">Member Status</h3>
                            </td>
                            <td className="hidden pb-32 xs:table-cell">
                              <p className="text-sm text-center">Signed Up</p>
                            </td>
                            <td className="hidden pb-32 xs:table-cell">
                              <p className="text-sm text-center">Fit</p>
                            </td>
                            <td className="hidden pb-32 xs:table-cell">
                              <p className="text-sm text-center">Address</p>
                            </td>
                            <td className="hidden pb-32 xs:table-cell">
                              <p className="text-sm text-center">Checked Out</p>
                            </td>
                            <td className="hidden pb-32 xs:table-cell">
                              <p className="text-sm text-center">Shipped</p>
                            </td>
                          </div>
                          {MemberStore.members
                            .filter((m) => !isNonParticipant(m))
                            .map((member) => (
                              <InviteStatusCard
                                key={member.id}
                                name={member.potentialMember!.nickname!}
                                hasAccount={true}
                                isFit={member.isMeasured!}
                                isPaid={member.isPaid!}
                                isShipped={member.isShipped!}
                                hasAddress={
                                  member.customer!.addresses !== undefined && member.customer!.addresses.length !== 0
                                }
                                orders={member.orders!}
                              />
                            ))}
                          {PotentialMemberStore.potentialMembers
                            .filter((m) => !isNonParticipant(m) && m.member === null)
                            .map((member) => (
                              <InviteStatusCard
                                key={member.id}
                                name={member.nickname!}
                                hasAccount={false}
                                isFit={false}
                                isPaid={false}
                                isShipped={false}
                                hasAddress={false}
                                orders={[]}
                              />
                            ))}
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Transition>

            <div className="pt-32 xs:pt-64 sm:hidden">
              <NavBarSub />
            </div>
          </div>
        </div>
        <HowItWorks className="mt-256" />
      </div>
    </>
  );
};
export default IsOwner(observer(InvitePage));
