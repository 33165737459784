import { differenceInCalendarDays, parse } from 'date-fns';

/**
 * Returns a copy of the given `Date` object
 */
export const cloneDate = (date: Date) => new Date(date.getTime());

/**
 * Returns the given date at midnight in UTC
 *
 * This can be used to get an accurate number of calendar days between two dates,
 * since it accounts for things like timezone differences
 */
export const getDateAtMidnight = (date: string | Date) => {
  const dateAtMidnight = typeof date === 'string' ? parse(date) : cloneDate(date);

  dateAtMidnight.setUTCHours(0, 0, 0, 0);

  return dateAtMidnight;
};

/**
 * Returns a boolean indicating whether the number of days between `now` and the
 * given `date` is less than the number specified in `days`
 */
export const isDateLessThanNDaysAway = (date: string | Date, days: number, now = new Date()) => {
  const nowAsUtc = getDateAtMidnight(now);
  const thenAsUtc = getDateAtMidnight(date);

  return differenceInCalendarDays(thenAsUtc, nowAsUtc) < days;
};
