import React from 'react';
import { GetMeasurementsResponse } from '../../types';
import { convertToFtIn, isGentux } from '../../utils/utils';
import { TaggedText } from '../../components/TaggedText';

interface DefinitionProps {
  term: string;
  def: string;
}

const Definition: React.FC<DefinitionProps> = ({ term, def }) => (
  <div>
    <h3 className="text-h5 text-gray-dark">{term}</h3>
    <p>{def}</p>
  </div>
);

interface DefinitionRowProps {
  title: string;
}

const DefinitionRow: React.FC<DefinitionRowProps> = ({ title, children }) => (
  <div className="flex flex-col gap-16 ">
    <h2 className="text-h4">{title}</h2>
    <div className="flex flex-wrap gap-x-32 gap-y-8">{children}</div>
  </div>
);

type FitPreviewProps = {
  measurements: GetMeasurementsResponse;
  showEdit?: boolean;
  handleEdit?: () => void;
  editButtonClasses?: string;
  showManualInputToggle?: boolean;
  toggleManualInput?: () => void;
};

const FitPreview = (props: FitPreviewProps) => {
  const {
    measurements,
    showEdit,
    handleEdit,
    editButtonClasses = '',
    showManualInputToggle = false,
    toggleManualInput = () => null,
  } = props;

  return (
    <div className="space-y-32 bg-white p-40 shadow-2xl sm:p-64">
      <DefinitionRow title="Measurements">
        <Definition
          term="Height"
          def={`${convertToFtIn(measurements!.height)[0]}ft ${convertToFtIn(measurements!.height)[1]}in`}
        />
        <Definition term="Weight" def={`${measurements!.weight.toString()}lbs`} />
        <Definition term="Age" def={`${measurements!.age ? measurements!.age.toString() : ''}`} />
        <Definition term="Shoe Size" def={`${measurements!.shoeSize} ${measurements!.shoeWidth}`} />
        <Definition term="Jean Waist" def={`${measurements!.pantWaist.toString()}in`} />
      </DefinitionRow>

      <DefinitionRow title="Body Type">
        <Definition term="Stomach" def={`${measurements!.stomachProfile}`} />
        <Definition term="Build" def={`${measurements!.buildProfile}`} />
      </DefinitionRow>

      <DefinitionRow title="Preference">
        <Definition term="Fit Preference" def={`${measurements!.fitPreference}`} />
      </DefinitionRow>

      {showEdit && (
        <button
          onClick={() => handleEdit!()}
          className={`tracker-cta-fit-edit_fit-20191029-134800 btn btn-info w-full ${editButtonClasses}`}
        >
          Edit Fit
        </button>
      )}

      {showManualInputToggle && isGentux() && (
        <button
          onClick={() => toggleManualInput()}
          className={`tracker-cta-fit-manual_measurements-20240726-144700 fit-page__content--after-fit-submitted__announcement !mt-16 block w-full py-16 text-brand-darker hover:underline`}
        >
          <TaggedText tag="New">Enter Your Sizes (Optional)</TaggedText>
        </button>
      )}
    </div>
  );
};

export default FitPreview;
