export const JACKET_AND_PANTS = 'jacket-and-pants';
export const SHIRT = 'shirt';
export const TIE = 'tie';
export const VEST_AND_CUMMERBUND = 'vest-and-cummerbund';
export const POCKET_SQUARE = 'pocket-square';
export const LAPEL_PIN = 'lapel-pin';
export const CUFFLINKS = 'cufflinks';
export const BELT_AND_SUSPENDERS = 'belt-and-suspenders';
export const SHOES = 'shoes';
export const SOCKS = 'socks';
