import React, { Component } from 'react';
import OwnerFormStore from '../../stores/OwnerFormStore';
import { FlowEventRouteProps } from '../../types';
import Flow from '../../utils/HOC/Flow';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';

interface Props extends FlowEventRouteProps<{}> {}

class OwnerConfirmation extends Component<Props> {
  handleSubmit() {
    this.nextPage();
  }

  nextPage() {
    OwnerFormStore.reset();
    this.props.flow!(this.props.location.search);
  }

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="row">
            <div className="col-span-12 sm:col-span-6 sm:col-start-4">
              <h1 className="normalcase text-h1-display col-span-12 mb-40 text-center">Owner Added!</h1>
              <p className="col-span-12 text-center xs:col-span-7 lg:col-span-5 ">
                {`An email was sent to ${OwnerFormStore.email} inviting them to help manage this event.`}
              </p>

              <button
                className="tracker-cta-event_details-add_owner-20200619-102712 btn btn-info mb-40 mt-100 w-full"
                onClick={() => this.handleSubmit()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(OwnerConfirmation);
