import React from 'react';

interface IconTriangleUpProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconTriangleUpProps>;

const IconTriangleUp: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Triangle pointing up</title>
      <path d="M4 10l4-5 4 5H4z" />
    </svg>
  );
};

export default IconTriangleUp;
