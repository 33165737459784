import React, { PureComponent } from 'react';
import { validateEmail } from '../../utils/utils';
import { forgotPassword } from '../../services/Auth';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { FlowRouteProps } from '../../types';
import FormInput from '../../components/FormInput';
import IconArrowLeft from '../../components/IconArrowLeft';
import Message from '../../components/Message';

export interface Props extends FlowRouteProps<any> {}

export interface State {
  email: string;
  emailError: boolean;
  submitSuccess: boolean;
  submitting: boolean;
}

const EMAIL_ERROR = 'The email you entered does not exist.';

class ForgotPassword extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
      submitSuccess: false,
      submitting: false,
    };
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: event.target.value });

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail(this.state.email)) {
      this.setState({ emailError: true });
      return;
    }

    this.setState({
      submitting: true,
      emailError: false,
    });

    try {
      const result = await forgotPassword(this.state.email);

      if (result.status === 200 || result.status === 202) {
        this.setState({
          submitting: false,
          submitSuccess: true,
        });
        return;
      }

      // There was an error
      this.setState({
        submitting: false,
        emailError: true,
      });
    } catch {
      // There was an error
      this.setState({
        submitting: false,
        emailError: true,
      });
    }
  };

  render() {
    // Success Message
    if (this.state.submitSuccess) {
      return (
        <>
          <FormFlowLogo />
          <div className="container">
            <div className="mx-auto max-w-lg text-center">
              <h1 className="normal-case text-h1-display mb-16 text-center xs:mb-32">
                Password Reset Email&nbsp;Sent!
              </h1>
              <p className="mb-16">We sent you an email with instructions to reset your password!</p>
              <button
                className="tracker-cta-forgot_password-back-200619-111519 btn btn-info"
                type="button"
                onClick={() => this.props.history.push(`/account/login`)}
              >
                Back to Login
              </button>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-lg">
            <h1 className="normal-case text-h1-display mb-16 text-center xs:mb-32">Reset Password</h1>
            <form onSubmit={this.handleSubmit}>
              <div className="mb-32">
                {this.state.emailError && <Message type="error">{EMAIL_ERROR}</Message>}
                <FormInput
                  combineLabel
                  label="Email"
                  value={this.state.email}
                  onChange={this.onChange}
                  hideIcon
                  className="w-full"
                />
              </div>
              <div className="flex flex-col-reverse xs:flex-row xs:space-x-4">
                <button
                  type="button"
                  onClick={() => window.history.back()}
                  className="tracker-cta-name-back-200619-103126 btn btn-default mb-16 w-full"
                >
                  <IconArrowLeft /> Back
                </button>
                <button
                  className="tracker-cta-name-next-200619-103126 btn btn-info mb-16 w-full"
                  type="submit"
                  disabled={this.state.submitting || !validateEmail(this.state.email)}
                >
                  {this.state.submitting ? `Submitting...` : 'Reset'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default ForgotPassword;
