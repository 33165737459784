import React from 'react';

interface IconStopwatchProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconStopwatchProps>;

const IconStopwatch: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Stopwatch</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7a6 6 0 0 1 5.5-5.98V0h1v1.02a5.974 5.974 0 0 1 3.374 1.398l.772-.772.707.708-.771.772A6 6 0 1 1 0 7Zm6 5A5 5 0 1 0 6 2a5 5 0 0 0 0 10Zm-.5-8v3.207l.146.147 2 2 .708-.708L6.5 6.793V4h-1Z"
      />
    </svg>
  );
};

export default IconStopwatch;
