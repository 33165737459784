const WAIST_GREATER_STOMACH = 'WAIST_GREATER_STOMACH';
const HEIGHT_GREATER_WEIGHT = 'HEIGHT_GREATER_WEIGHT';
const WAIST_GREATER_CHEST = 'WAIST_GREATER_CHEST';
const CHEST_GREATER_OVERARM = 'CHEST_GREATER_OVERARM';

const messages = {
  getOutOfValidRangeMessage(display, error) {
    return (
      `Sorry, this doesn’t look right. As a rule, your ${display} must be between ` +
      `${error.min}" and ${error.max}" based on your other entries.`
    );
  },

  getOutOfNormalRangeMessage(display, error) {
    return `We think your ${display} should be between ${error.min} and ${error.max}".`;
  },

  getOutOfNormalRangeListItemMessage(display, error) {
    return `For your ${display}, we expected something between ${error.min} and ${error.max}".`;
  },

  getBadMeasurementMessage(code) {
    switch (code) {
      case WAIST_GREATER_STOMACH:
        return 'We expected your waist to be smaller than your stomach.';
      case HEIGHT_GREATER_WEIGHT:
        return 'As a rule, your chest must be smaller than your weight.';
      case WAIST_GREATER_CHEST:
        return 'We expected your waist to be smaller than your chest.';
      case CHEST_GREATER_OVERARM:
        return 'As a rule, your chest must be smaller than your overarm.';
      default:
        return '';
    }
  },
};

export default messages;
