import { observable, action, computed } from 'mobx';
import { LookPreviewItem, Item, Bundle, Product } from '../../types';
import { htoWasStartedInApp, inHTOFlow, isItemValidInCurrentFlow } from '../../look-builder/utils/utils';

import {
  JACKET_AND_PANTS,
  SHIRT,
  TIE,
  VEST_AND_CUMMERBUND,
  POCKET_SQUARE,
  LAPEL_PIN,
  CUFFLINKS,
  BELT_AND_SUSPENDERS,
  SHOES,
  SOCKS,
} from '../../look-builder/data/categories';
import {
  isCategoryValidInCurrentFlow,
  getBundleRecommendedItems,
  itemCategoryMatchesBuilderCategory,
} from '../../look-builder/utils/utils';
import { getCost } from '../../utils/items';
import { itemIsBlocked } from '../../utils/items/blockoutDates';

const lookPreviewStub = [
  {
    id: 1,
    category: JACKET_AND_PANTS,
    activeItem: null,
    isBundle: true,
    icon: 'jacket',
    placeholder: 'https://gentux.imgix.net/1591704711_200608-151436-jacket.png',
  },
  {
    id: 2,
    category: SHIRT,
    activeItem: null,
    isBundle: false,
    icon: 'shirt',
    placeholder: 'https://gentux.imgix.net/1591704711_200608-151436-shirt.png',
  },
  {
    id: 3,
    category: TIE,
    activeItem: null,
    isBundle: false,
    icon: 'tie',
    placeholder: 'https://gentux.imgix.net/1591704712_200608-151436-tie.png',
  },
  {
    id: 4,
    category: VEST_AND_CUMMERBUND,
    activeItem: null,
    isBundle: false,
    icon: 'vest',
    placeholder: 'https://gentux.imgix.net/1591704712_200608-151436-vest.png',
  },
  {
    id: 5,
    category: POCKET_SQUARE,
    activeItem: null,
    isBundle: false,
    icon: 'pocket_square',
    placeholder: 'https://gentux.imgix.net/1591704711_200608-151436-pocket_square.png',
  },
  {
    id: 6,
    category: LAPEL_PIN,
    activeItem: null,
    isBundle: false,
    icon: 'lapel_pin',
    placeholder: 'https://gentux.imgix.net/1591704711_200608-151436-lapel_pin.png',
  },
  {
    id: 7,
    category: CUFFLINKS,
    activeItem: null,
    isBundle: false,
    icon: 'cufflinks',
    placeholder: 'https://gentux.imgix.net/1591704711_200608-151436-cufflinks.png',
  },
  {
    id: 8,
    category: BELT_AND_SUSPENDERS,
    activeItem: null,
    isBundle: false,
    icon: 'belt',
    placeholder: 'https://gentux.imgix.net/1591704711_200608-151436-belt.png',
  },
  {
    id: 9,
    category: SHOES,
    activeItem: null,
    isBundle: false,
    icon: 'shoe',
    placeholder: 'https://gentux.imgix.net/1591704711_200608-151436-shoes.png',
  },
  {
    id: 10,
    category: SOCKS,
    activeItem: null,
    isBundle: false,
    icon: 'socks',
    placeholder: 'https://gentux.imgix.net/1591704712_200608-151436-socks.png',
  },
];

class LookPreview {
  @observable lookPreview: LookPreviewItem[] = lookPreviewStub;
  @observable currentHistoryIndex: number = 0;
  @observable history: LookPreviewItem[][] = [lookPreviewStub];
  @observable flow: string | undefined = undefined;
  @observable lookName: string = 'Look';
  @observable previousUrl: string | undefined = undefined;
  @observable activeItemHasChanged: boolean = false;
  @observable previewExploded: boolean = false;

  @computed get bundle(): Bundle {
    return this.lookPreview[0].activeItem as Bundle;
  }

  @computed get products() {
    return this.lookPreview.reduce((acc, look) => {
      if (look.category.toLowerCase() !== JACKET_AND_PANTS.toLowerCase() && look.activeItem !== null) {
        return [...acc, look.activeItem];
      }
      return acc;
    }, []);
  }

  @computed get productsAndBundle() {
    const bundle = this.bundle;
    return bundle ? this.products.concat(bundle) : this.products;
  }

  @computed get productLayers() {
    return this.lookPreview.reduce((acc, preview) => {
      if (!preview.activeItem || !isItemValidInCurrentFlow(preview)) {
        return acc;
      }

      if (preview.isBundle) {
        return [...acc, ...(preview.activeItem as Bundle).products!];
      }

      return [...acc, preview.activeItem];
    }, []);
  }

  @action setLookName = (name: string) => (this.lookName = name);

  @action setFlow = (flow: string) => (this.flow = flow);

  @action setPreviousUrl = (url: string) => (this.previousUrl = url);

  @action setActiveItemHasChanged = (changed: boolean) => (this.activeItemHasChanged = changed);

  @action setPreviewExploded = (exploded: boolean) => (this.previewExploded = exploded);

  @action setActiveItem = (category: string, activeItem: Item) => {
    this.lookPreview = this.lookPreview.map((look) => {
      if (look.category.toLowerCase() === category.toLowerCase()) {
        if (!look.activeItem || (look.activeItem && look.activeItem.id !== activeItem.id)) {
          this.setActiveItemHasChanged(true);
        }
        return { ...look, activeItem: activeItem };
      }
      return look;
    });

    const onlyBundleIsSet = this.lookPreview.slice(1, -1).every((itemType) => {
      return itemType.activeItem === null;
    });

    if (category === JACKET_AND_PANTS && onlyBundleIsSet) {
      if (!inHTOFlow() || !htoWasStartedInApp()) {
        this.setBundleRecommendedItems(activeItem);
      }
    }

    if (
      activeItem.type &&
      activeItem.type.toLowerCase().includes('tux') &&
      this.products &&
      this.products.find((i) => i.category!.toLowerCase().includes('belt')) !== undefined
    ) {
      this.unsetActiveItem(BELT_AND_SUSPENDERS);
    }
  };

  @action setBundle = (bundle: Bundle) => this.setActiveItem(JACKET_AND_PANTS, bundle);

  @action setProducts = (products: Product[]) => {
    products.forEach((product) => {
      if (product.category!.toLowerCase() === 'shirt') {
        return this.setActiveItem(SHIRT, product);
      }
      if (product.category!.toLowerCase() === 'tie') {
        return this.setActiveItem(TIE, product);
      }
      if (product.category!.toLowerCase() === 'cummerbund' || product.category!.toLowerCase() === 'vest') {
        return this.setActiveItem(VEST_AND_CUMMERBUND, product);
      }
      if (product.category!.toLowerCase() === 'pocket square') {
        return this.setActiveItem(POCKET_SQUARE, product);
      }
      if (product.category!.toLowerCase() === 'lapel pin') {
        return this.setActiveItem(LAPEL_PIN, product);
      }
      if (product.category!.toLowerCase() === 'cufflinks') {
        return this.setActiveItem(CUFFLINKS, product);
      }
      if (product.category!.toLowerCase() === 'belt' || product.category!.toLowerCase() === 'suspenders') {
        return this.setActiveItem(BELT_AND_SUSPENDERS, product);
      }
      if (product.category!.toLowerCase() === 'shoe') {
        return this.setActiveItem(SHOES, product);
      }
      if (product.category!.toLowerCase() === 'socks') {
        return this.setActiveItem(SOCKS, product);
      }
    });
  };

  @action unsetActiveItem = (category: string) =>
    (this.lookPreview = this.lookPreview.map((look) => {
      if (look.category.toLowerCase() === category.toLowerCase()) {
        return { ...look, activeItem: null };
      }
      return look;
    }));

  @action addToHistory = (newLookPreview: LookPreviewItem[]) => {
    const lookPreview = this.history[this.currentHistoryIndex];
    if (JSON.stringify(newLookPreview) !== JSON.stringify(lookPreview)) {
      const currentHistory = this.history.splice(0, this.currentHistoryIndex + 1);
      this.history = [...currentHistory, newLookPreview];
      this.currentHistoryIndex = this.history.length - 1;
    }
  };

  @action undoHistory = () => {
    this.currentHistoryIndex = this.currentHistoryIndex - 1;
    const lookPreview = this.history[this.currentHistoryIndex];
    this.lookPreview = lookPreview;
  };

  @action redoHistory = () => {
    this.currentHistoryIndex = this.currentHistoryIndex + 1;
    const lookPreview = this.history[this.currentHistoryIndex];
    this.lookPreview = lookPreview;
  };

  @action setBundleRecommendedItems = (bundle: Bundle) => {
    // Check to see if there are valid recommended items from a bundle
    const products = getBundleRecommendedItems(bundle);

    this.setProducts(
      products.filter((p) => isCategoryValidInCurrentFlow(p.category!) && !itemIsBlocked(p) && p.displayable)
    );
  };

  @action
  reset = () => {
    this.lookPreview = lookPreviewStub;
  };

  isItemActive = (item: Item) =>
    this.lookPreview.find(
      (lookPreviewItem) =>
        lookPreviewItem.activeItem &&
        lookPreviewItem.activeItem.id === item.id &&
        itemCategoryMatchesBuilderCategory(lookPreviewItem.category!, item.category!)
    ) !== undefined;

  getCost = () => getCost(this.productsAndBundle);
}

const PreviewStore = new LookPreview();

export default PreviewStore;
