import React from 'react';

interface LogoMenguinWordmarkProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & LogoMenguinWordmarkProps>;

const LogoMenguinWordmark: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 256 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-40 w-100 fill-current text-black ${className ? className : ''}`}
    >
      <title>Menguin Logo</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.362 21.279c0 2.68-.654 4.641-1.963 5.883-1.308 1.242-3.204 1.863-5.686 1.863-2.516 0-4.429-.62-5.737-1.863-1.308-1.242-1.963-3.203-1.963-5.883V0h-5.384v21.573c0 2.027.335 3.808 1.006 5.344.671 1.536 1.586 2.811 2.743 3.824 1.158 1.013 2.542 1.773 4.152 2.28 1.61.506 3.337.76 5.183.76 1.844 0 3.564-.254 5.158-.76 1.593-.507 2.969-1.267 4.126-2.28 1.158-1.013 2.071-2.288 2.743-3.824.671-1.536 1.006-3.317 1.006-5.344V0h-5.384V21.28Zm18.999 12.049h5.385V0h-5.385v33.328Zm-68.206-12.524h9.159v5.84c-.971.637-2.126 1.138-3.464 1.504-1.339.366-2.946.548-4.819.548-1.942 0-3.7-.302-5.272-.907-1.573-.604-2.912-1.448-4.015-2.53a11.003 11.003 0 0 1-2.536-3.82c-.586-1.464-.879-3.056-.879-4.775 0-1.719.293-3.31.879-4.775.586-1.463 1.397-2.737 2.435-3.82a10.976 10.976 0 0 1 3.714-2.53c1.439-.604 3.029-.907 4.77-.907 1.238 0 2.326.103 3.263.31a11.76 11.76 0 0 1 2.51.835c.737.351 1.398.757 1.984 1.218.585.462 1.129.963 1.631 1.504l3.724-3.342a16.42 16.42 0 0 0-2.315-2.077 14.565 14.565 0 0 0-2.841-1.624c-1.055-.46-2.227-.819-3.516-1.074-1.289-.254-2.72-.382-4.294-.382-2.445 0-4.73.422-6.856 1.265-2.127.844-3.985 2.006-5.575 3.486a15.97 15.97 0 0 0-3.742 5.276c-.904 2.038-1.356 4.25-1.356 6.637 0 2.387.46 4.6 1.383 6.637a15.937 15.937 0 0 0 3.825 5.276c1.627 1.48 3.564 2.642 5.812 3.486 2.248.843 4.68 1.265 7.297 1.265 1.61 0 3.07-.12 4.378-.358a23.82 23.82 0 0 0 3.598-.931 16.93 16.93 0 0 0 2.944-1.337 18.546 18.546 0 0 0 2.365-1.624v-12.81h-14.191v4.536ZM16.959 17.677 1.635 0H0v33.328h5.032V11.135l11.674 13.94h.253l3.157-3.753-3.148-3.656-.01.011ZM250.905 0v21.603L230.311.017h-1.749v33.31h5.032V10.835l21.541 22.58h.802L255.938 0h-5.033ZM21.148 12.786l3.023 3.716 4.513-5.367v22.193h5.032V.017L32.097 0 21.148 12.786Zm83.785 8.817L84.338.017h-1.749v33.31h5.032V10.835l21.48 22.474.862.02L109.965 0h-5.032v21.603Zm-51.962-1.899h14.544v-5.113H52.97V4.656h16.406V0h-21.79v33.328h22.494v-4.656h-17.11v-8.968Z"
      />
    </svg>
  );
};

export default LogoMenguinWordmark;
