import { Flow } from './types';

/**
 * Signup Flow Entrypoint
 */
export const signUp: Flow = {
  stream: [
    '/signup/email',
    '/signup/password',
    '/signup/occasion',
    '/signup/role',
    '/signup/date',
    '/signup/name',
    '/signup/phone',
    '/signup/referral-survey',
    '/signup/address',
    '/signup/preferences',
  ],
  outflow: '/event-flow/looks',
};

/**
 * Normal Customize Look Flow Entrypoint
 */
const normalCustomizeLookFlow: Flow = {
  stream: [
    '/customize/email',
    '/customize/password',
    '/customize/occasion',
    '/customize/role',
    '/customize/date',
    '/customize/name',
    '/customize/phone',
    '/customize/referral-survey',
    '/customize/address',
    '/customize/preferences',
  ],
  outflow: '/event-flow/looks',
};

/**
 * Create Event Flow Entrypoint
 */
const createEvent: Flow = {
  stream: [
    '/create-event/occasion',
    '/create-event/role',
    '/create-event/date',
    '/create-event/name',
    '/create-event/phone',
    '/create-event/preferences',
  ],
  outflow: '/event-flow/looks',
};

/**
 * Login Flow Entrypoint
 */
const login: Flow = {
  stream: ['/account/login'],
  outflow: [
    '/event-flow/looks',
    { stream: ['/login/event/welcome-back'], outflow: signUp.stream[2] },
    {
      stream: ['/login/customer/welcome-back'],
      outflow: signUp.stream[7],
    },
  ],
};

/**
 * Edit Event Name Entrypoint
 */
const editEventName: Flow = {
  stream: ['/edit/event/name'],
  outflow: '/event-flow/looks',
};

/**
 * Add Co Owner Flow Entrypoint
 */
const addOwnerFlow: Flow = {
  stream: [
    '/event/owner/email',
    '/event/owner/phone',
    '/event/owner/first-name',
    '/event/owner/last-name',
    '/event/owner/confirmation',
  ],
  outflow: '/event-flow/details',
};

const swatchCheckout: Flow = {
  stream: ['/swatch/shipping', '/swatch/summary', '/swatch/confirmation'],
  outflow: '/account/orders',
};

const swatchNewAccount: Flow = {
  stream: [
    '/swatch/email',
    '/swatch/password',
    '/swatch/occasion',
    '/swatch/role',
    '/swatch/date',
    '/swatch/name',
    '/swatch/phone',
    '/swatch/referral-survey',
    '/swatch/preferences',
  ],
  outflow: swatchCheckout,
};

const swatchLogin: Flow = {
  stream: ['/swatch/login'],
  outflow: [
    swatchCheckout,
    {
      stream: ['/swatch/login/event/welcome-back'],
      outflow: swatchNewAccount.stream[2],
    },
    {
      stream: ['/swatch/login/customer/welcome-back'],
      outflow: swatchNewAccount.stream[6],
    },
  ],
};

const eventCheckout: Flow = {
  stream: ['/event/shipping', '/event/checkout', '/event/confirmation'],
  outflow: '/account/orders',
};

/**
 * Swatch Flow Entrypoint
 */
const swatch: Flow = {
  stream: ['/swatch/build'],
  outflow: [swatchNewAccount, swatchCheckout, swatchNewAccount.stream[2], swatchNewAccount.stream[6]],
};

const htoCheckout: Flow = {
  stream: ['/hto/shipping', '/hto/payment', '/hto/confirmation'],
  outflow: '/account/orders',
};

const htoAbrCheckout: Flow = {
  stream: ['/hto-abr/shipping', '/hto-abr/payment', '/hto-abr/confirmation'],
  outflow: '/account/orders',
};

const htoTapeMeasure: Flow = {
  stream: [
    '/fit-flow/hto/welcome',
    '/fit-flow/hto/neck',
    '/fit-flow/hto/overarm',
    '/fit-flow/hto/chest',
    '/fit-flow/hto/sleeve',
    '/fit-flow/hto/stomach',
    '/fit-flow/hto/waist',
    '/fit-flow/hto/outseam',
  ],
  outflow: htoCheckout,
};

const htoAbrTapeMeasure: Flow = {
  stream: [
    '/fit-flow/abr/welcome',
    '/fit-flow/abr/neck',
    '/fit-flow/abr/overarm',
    '/fit-flow/abr/chest',
    '/fit-flow/abr/sleeve',
    '/fit-flow/abr/stomach',
    '/fit-flow/abr/waist',
    '/fit-flow/abr/outseam',
  ],
  outflow: htoAbrCheckout,
};

const htoFit: Flow = {
  stream: [
    '/fit-flow/hto/height',
    '/fit-flow/hto/weight',
    '/fit-flow/hto/age',
    '/fit-flow/hto/shoe',
    '/fit-flow/hto/jeanwaist',
    '/fit-flow/hto/build',
    '/fit-flow/hto/preference',
  ],
  outflow: [htoCheckout, htoTapeMeasure],
};

const htoFitAbr: Flow = {
  stream: ['/fit-flow/abr/height', '/fit-flow/abr/weight', '/fit-flow/abr/jeanwaist'],
  outflow: [htoAbrCheckout, htoAbrTapeMeasure],
};

const htoRedirect: Flow = {
  stream: ['/hto/redirect'],
  outflow: ['/event-flow/looks', signUp.stream[6], createEvent],
};

const htoExisting: Flow = {
  stream: ['/hto/existing'],
  outflow: ['/event-flow/looks', signUp.stream[6], createEvent],
};

const htoCustomerInfo: Flow = {
  stream: ['/hto/name', '/hto/phone', '/hto/referral-survey', '/hto/preferences'],
  outflow: htoFit,
};

const htoNewAccount: Flow = {
  stream: ['/hto/email', '/hto/password', '/hto/occasion', '/hto/role', '/hto/date'],
  outflow: [htoCustomerInfo, htoRedirect],
};

const htoLogin: Flow = {
  stream: ['/hto/login'],
  outflow: [
    htoFit,
    {
      stream: ['/hto/login/event/welcome-back'],
      outflow: htoNewAccount.stream[2],
    },
    {
      stream: ['/hto/login/customer/welcome-back'],
      outflow: htoNewAccount.stream[6],
    },
    htoRedirect,
    htoExisting,
  ],
};

/**
 * HTO Flow Entrypoint
 */
const hto: Flow = {
  stream: ['/hto/looks/build'],
  outflow: [htoNewAccount, htoFit, htoNewAccount.stream[2], htoNewAccount.stream[7], htoRedirect],
};

const optionalMeasurementFlow: Flow = {
  stream: [
    '/fit-flow/optional/welcome',
    '/fit-flow/optional/neck',
    '/fit-flow/optional/overarm',
    '/fit-flow/optional/chest',
    '/fit-flow/optional/sleeve',
    '/fit-flow/optional/stomach',
    '/fit-flow/optional/waist',
    '/fit-flow/optional/outseam',
  ],
  outflow: '/event-flow/fit',
};

/**
 * Checkout Fit Flow Entrypoint
 */
const checkoutFitFlow: Flow = {
  stream: [
    '/fit-flow/height',
    '/fit-flow/weight',
    '/fit-flow/age',
    '/fit-flow/shoe',
    '/fit-flow/jeanwaist',
    '/fit-flow/build',
    '/fit-flow/preference',
  ],
  outflow: ['/event-flow/fit', optionalMeasurementFlow],
};

/**
 * Invited Member Flow
 */

const invitedMemberUnpaid: Flow = {
  stream: ['/invited/checkout', '/invited/confirmation'],
  outflow: '/account/orders',
};

const invitedMemberShipping: Flow = {
  stream: ['/invited/shipping'],
  outflow: [invitedMemberUnpaid, '/invited/completed'],
};

const invitedMemberPreview: Flow = {
  stream: ['/invited/preview'],
  outflow: [invitedMemberShipping],
};

const invitedMemberFitFlow: Flow = {
  stream: [
    '/fit-flow/invited/height',
    '/fit-flow/invited/weight',
    '/fit-flow/invited/age',
    '/fit-flow/invited/shoe',
    '/fit-flow/invited/jeanwaist',
    '/fit-flow/invited/build',
    '/fit-flow/invited/preference',
  ],
  outflow: [invitedMemberShipping, invitedMemberPreview, optionalMeasurementFlow],
};

const invitedMemberSignUp: Flow = {
  stream: [
    '/invited/signup/email',
    '/invited/signup/password',
    '/invited/signup/phone',
    '/invited/signup/first-name',
    '/invited/signup/last-name',
    '/invited/signup/preferences',
  ],
  outflow: [invitedMemberFitFlow],
};

const missingInformation: Flow = {
  stream: ['/signup/missing-information'],
  outflow: ['/signup/preferences'],
};

const invitedMemberMissingInformation: Flow = {
  stream: ['/invited/signup/missing-information'],
  outflow: ['/invited/signup/preferences'],
};

const invitedMemberJoin: Flow = {
  stream: ['/invited/join/:shortCode'],
  outflow: [invitedMemberSignUp, invitedMemberPreview, invitedMemberFitFlow, '/event-flow/looks'],
};

/**
 * Customize Look FLow
 */
const customizeLookLoginFlow: Flow = {
  stream: ['/customize/login'],
  outflow: [
    '/event-flow/looks',
    {
      stream: ['/customize/login/event/welcome-back'],
      outflow: createEvent,
    },
    {
      stream: ['/customize/login/customer/welcome-back'],
      outflow: normalCustomizeLookFlow.stream[6],
    },
  ],
};

const customizeLookFlow: Flow = {
  stream: ['/customize'],
  outflow: [
    {
      stream: ['/customize/email'],
      outflow: normalCustomizeLookFlow.stream[1],
    },
    '/event-flow/looks',
    createEvent,
    normalCustomizeLookFlow.stream[6],
  ],
};

/**
 * Customize Look FLow
 */
const customizeLookLoginGateFlow: Flow = {
  stream: ['/customize/signup/login'],
  outflow: '/customize',
};

const customizeLookGateFlow: Flow = {
  stream: [
    '/customize/signup/email',
    '/customize/signup/password',
    '/customize/signup/occasion',
    '/customize/signup/role',
    '/customize/signup/date',
    '/customize/signup/name',
    '/customize/signup/phone',
    '/customize/signup/referral-survey',
    '/customize/signup/address',
    '/customize/signup/preferences',
  ],
  outflow: '/customize',
};

const genericGateLoginFlow: Flow = {
  stream: ['/gate/login'],
  outflow: 'redirect',
};

const genericGateFlow: Flow = {
  stream: [
    '/gate/signup/email',
    '/gate/signup/password',
    '/gate/signup/occasion',
    '/gate/signup/role',
    '/gate/signup/date',
    '/gate/signup/name',
    '/gate/signup/phone',
    '/gate/signup/referral-survey',
    '/gate/signup/address',
    '/gate/signup/preferences',
  ],
  outflow: 'redirect',
};

const flows: Flow[] = [
  signUp,
  createEvent,
  editEventName,
  login,
  swatchCheckout,
  swatchNewAccount,
  swatch,
  swatchLogin,
  htoRedirect,
  htoCustomerInfo,
  htoExisting,
  htoCheckout,
  htoAbrCheckout,
  htoNewAccount,
  hto,
  htoLogin,
  htoTapeMeasure,
  htoAbrTapeMeasure,
  htoFit,
  htoFitAbr,
  optionalMeasurementFlow,
  checkoutFitFlow,
  customizeLookLoginFlow,
  customizeLookFlow,
  customizeLookGateFlow,
  customizeLookLoginGateFlow,
  genericGateLoginFlow,
  genericGateFlow,
  normalCustomizeLookFlow,
  invitedMemberFitFlow,
  invitedMemberPreview,
  invitedMemberShipping,
  invitedMemberUnpaid,
  invitedMemberJoin,
  invitedMemberSignUp,
  addOwnerFlow,
  eventCheckout,
  missingInformation,
  invitedMemberMissingInformation,
];

export default flows;
