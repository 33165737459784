import { useRef, PropsWithChildren } from 'react';

import IconX from '../../../../components/IconX';
import useClickOutside from '../../../../utils/hooks/useClickOutside';
import './BaseModal.css';

export type BaseModalProps = PropsWithChildren<{
  show?: boolean;
  onClose: () => void;
}>;

const BaseModal = ({ children, show = true, onClose }: BaseModalProps) => {
  const ref = useRef(null);

  const transitionClasses = 'transition duration-100 delay-1000 opacity-0';

  useClickOutside(ref, () => {
    onClose();
  });

  if (!show) {
    return null;
  }

  return (
    <div className={`modal fixed left-0 top-0 z-30 h-full w-full pb-2/6 pt-0 ${!show ? transitionClasses : ''}`}>
      <div
        className="border-1 relative z-30 mx-4 mt-1/6 flex h-full flex-col justify-evenly rounded border-solid bg-white p-8 pt-0"
        ref={ref}
        style={{ contain: 'content' }}
      >
        <div
          className="my-0 flex w-full justify-end self-start pt-8"
          onClick={(ev) => {
            ev.stopPropagation();
            onClose();
          }}
        >
          <button className="text-xs text-gray-dark">
            {'Cancel'}
            <IconX className="text-xs ml-4 inline text-gray-dark" style={{ cursor: 'pointer' }} />
          </button>
        </div>

        <div className="flex flex-grow flex-col justify-center overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default BaseModal;
