import { observable, action, runInAction } from 'mobx';
import { Address } from '../types';
import { getAddresses, createAddress, updateAddress } from '../services/Accounts';

class AddressesStore {
  @observable addresses: Array<Address> = [];
  @observable
  currentAddress: Address = {
    id: undefined,
    firstName: '',
    lastName: '',
    addressLine1: '',
    city: '',
    state: '',
    zip: '',
    isDefault: true,
    country: 'US',
  };

  @action
  loadAddresses = async () => {
    const res = await getAddresses();
    const data = await res.json();
    this.addresses = data.data.customer.addresses;
    this.addresses.find((a: Address) => {
      if (!this.currentAddress.id && a.isDefault) {
        this.currentAddress = a;
        return true;
      }
      return false;
    });
  };

  @action
  addOrUpdateAddress = async () => {
    if (this.addresses.find(a => a.id === this.currentAddress.id)) {
      await this.updateCurrentAddress();
    } else {
      await this.addCurrentAdress();
    }
  };

  @action
  addCurrentAdress = async () => {
    try {
      const res = await createAddress(this.currentAddress);
      const data = await res.json();
      if (data.errors && data.errors.length > 0) {
        throw data.errors[0].message;
      }
      runInAction(() => {
        this.addresses = this.addresses.concat(data.data.createAddress).map(a => {
          if (a.id !== this.currentAddress.id && a.isDefault) {
            return { ...a, isDefault: false };
          } else {
            return a;
          }
        });
      });
      runInAction(() => {
        this.currentAddress = data.data.createAddress;
      });
    } catch (err) {
      let errorMessage = 'Failed to add current address.';

      if (err instanceof Error) {
        errorMessage = err.message;
      }

      console.error(err);

      throw new Error(errorMessage);
    }
  };

  @action
  updateCurrentAddress = async () => {
    try {
      const res = await updateAddress(this.currentAddress);
      const data = await res.json();
      if (data.errors && data.errors.length > 0) {
        throw data.errors[0].message;
      }
      runInAction(() => {
        this.addresses = this.addresses.map(a => {
          if (a.id === this.currentAddress.id) {
            return this.currentAddress;
          }
          if (a.id !== this.currentAddress.id && a.isDefault) {
            return { ...a, isDefault: false };
          }
          return a;
        });
      });
    } catch (err) {
      let errorMessage = 'Failed to update current address.';

      if (err instanceof Error) {
        errorMessage = err.message;
      }

      console.error(err);
      throw new Error(errorMessage);
    }
  };

  @action
  setCurrentAddress = (address: Address) => (this.currentAddress = address);

  @action
  setCurrentAddressAsDefault = () => {
    this.addresses.find((a: Address) => {
      if (a.isDefault && a.id) {
        this.currentAddress = a;
        return true;
      }
      return false;
    });
  };

  @action
  handleCurrentAddressChange = (field: string, val: string) => {
    this.currentAddress = {
      ...this.currentAddress,
      [field]: val,
    };
  };
}

export default new AddressesStore();
