import { isGroomOrBride } from './utils';
import { EventCreated } from '../types';

export const event = {
  created: async (event: EventCreated) => {
    try {
      if (isGroomOrBride(event)) {
        /**
         * Trade Desk Pixel
         */
        await fetch(`//insight.adsrvr.org/track/pxl/?adv=i9ejtsv&ct=0:19zv16v&fmt=3&td1=${event.id}`, {
          method: 'GET',
          credentials: 'include',
        });
      }
    } catch (e) {
      console.error('error loading pixels', e);
    }
  },
  brideGroomSignup: () => {
    try {
      if (window.gt.env === 'production') {
        /**
         * Kenshoo Pixel
         */
        fetch(
          `${
            'https:' === document.location.protocol ? 'https' : 'http'
          }://5212.xg4ken.com/pixel/v1?track=1&token=eb56caa9-d82b-45c1-871d-a05ba6aaa2ef&conversionType=Wedding%20Signup&revenue=&orderId=&currency=USD&promoCode=&ref=${
            document.referrer
          }`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );
      }
    } catch (e) {
      console.error('error loading pixels', e);
    }
  },
};

export const page = {
  loaded: () => {
    try {
      window.ttd_dom_ready(function() {
        if (typeof window.TTDUniversalPixelApi === 'function') {
          var universalPixelApi = new window.TTDUniversalPixelApi();
          universalPixelApi.init('i9ejtsv', ['6k59jxe'], 'https://insight.adsrvr.org/track/up');
        }
      });
    } catch (e) {
      console.error('error loading pixels', e);
    }
  },
};

export const account = {
  created: () => {
    try {
      if (window.gt.env === 'production') {
        /**
         * Begin Unbounce Tracker
         */
        window._ubaq = window._ubaq || [];

        // @ts-ignore
        window._ubaq.push(['trackGoal', 'convert']);
        let ub_script = document.createElement('script');
        ub_script.type = 'text/javascript';
        // eslint-disable-next-line
        ub_script.src =
          // eslint-disable-next-line
          ('https:' == document.location.protocol ? 'https://' : 'http://') + 'd3pkntwtp2ukl5.cloudfront.net/uba.js';
        let s = document.getElementsByTagName('script')[0];
        // @ts-ignore
        s.parentNode.insertBefore(ub_script, s);
        /**
         * End Unbounce Tracker
         */
      }
    } catch (e) {
      console.error('error loading pixels', e);
    }
  },
};
