import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { flatten } from '../../utils/utils';

import { Order as OrderType, GlobalContextTyping, Member } from '../../types';
import auth from '../../services/Auth';
import CustomerStore from '../../stores/CustomerStore';
import MyAccountWrapper from '../hoc/MyAccountWrapper';
import Spinner from '../../shared/components/Spinner';
import Order from './orders/components/Order';
import { pageFadeIn, pageFadeInDelayed } from '../../utils/Component/Animations';
import Line from '../../components/Line';
import Message from '../../components/Message';
import { Transition } from '@headlessui/react';

interface Props extends RouteComponentProps<any> {
  globalContext?: GlobalContextTyping;
}

const Orders = ({ history, location, match }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    (async () => {
      CustomerStore.loadCustomer(auth.user());
      try {
        await CustomerStore.getMembers();
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setLoadError(true);
      }
    })();
  }, []);

  const orders = flatten(
    CustomerStore.fetchedMembers
      .map((member: Member) => ({
        ...member,
        orders: member.orders
          ? member.orders.map((order: OrderType) => ({
              ...order,
              member,
            }))
          : [],
      }))
      .map((member: Member) => member.orders)
  );

  return (
    <>
      <Transition {...pageFadeIn}>
        <h2 className="text-h2-display mb-32">Orders</h2>
        <Line />
      </Transition>
      <Transition {...pageFadeInDelayed}>
        {isLoading && !loadError && <Spinner type="minimal" />}

        {!isLoading && loadError && (
          <Message type="error" message="There was an issue loading this page. Please refresh and try again." />
        )}

        {!isLoading && !loadError && (
          <>
            {orders.length > 0 &&
              orders.map((order) => (
                <Order order={order} history={history} location={location} match={match} key={order.id} />
              ))}

            {orders.length === 0 && (
              <div>
                <p className="mb-32">There are no orders associated with your account.</p>
              </div>
            )}
          </>
        )}
      </Transition>
    </>
  );
};

export default observer(MyAccountWrapper(Orders));
