import { observable, action } from 'mobx';
import { Product } from '../types';
import Products from '../services/Products';
import { getOrderedProductColors } from '../look-builder/utils/utils';

class SwatchsStore {
  @observable swatches: Array<Product> = [];
  @observable swatchColors: Array<string> = [];
  @observable selectedSwatches: Array<Product> = [];

  @action
  loadSwatches = async () => {
    const getProductsResponse = await Products.getProducts({
      category: 'Swatch (suit/tux),Swatch (accessory)',
      with: 'media',
      displayable: true,
    });

    const response = await getProductsResponse.json();

    this.swatches = response.map((swatch: { [key: string]: any }) => ({
      ...swatch,
      displayName: swatch.display_name,
      blockoutDates: swatch.blockout_dates,
    }));

    const swatchColors = this.swatches.map((s) => s.color).filter((c): c is string => Boolean(c));
    this.swatchColors = getOrderedProductColors(swatchColors);
  };

  @action
  addSelectedSwatch = (swatch: Product) => (this.selectedSwatches = this.selectedSwatches.concat(swatch));

  @action
  deleteSelectedSwatch = (index: number) =>
    (this.selectedSwatches = this.selectedSwatches.filter((_, i) => i !== index));

  @action deleteSelectedSwatches = () => (this.selectedSwatches = []);

  generateSelectedSwatchesSkus = (): Array<string> => this.selectedSwatches.map((s) => s.sku);
}

export default new SwatchsStore();
