import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// local components
import FormRadio from '../../components/FormRadio';
import FormRadioItem from '../../components/FormRadioItem';
import FormNavigation from '../../utils/Component/FormNavigation';

// types
import { FitActions } from '../../types';

// interfaces
interface Props extends RouteComponentProps<any> {
  reducer: (action: FitActions, changes: any) => void;
  measuredByTailor?: boolean;
}

interface State {
  measuredByTailor: boolean | undefined;
}

class FitMeasuredByTailor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      measuredByTailor: this.props.measuredByTailor,
    };
  }

  setMeasuredByTailor = (measuredByTailor: boolean) => {
    this.setState({ measuredByTailor: measuredByTailor });
  };

  onSubmit = () => {
    const { reducer } = this.props;
    if (reducer) {
      reducer('MEASURED_BY_TAILOR_SET', {
        measuredByTailor: this.state.measuredByTailor,
      });
    }
  };

  render() {
    return (
      <div className="mx-auto max-w-sm">
        <h2 className="text-h2-display mb-32 text-center">Were you measured by a tailor?</h2>

        <FormRadio label={'Measured by Tailor'} darkMode className="mb-32 w-full">
          <FormRadioItem
            label={'Yes'}
            checked={this.state.measuredByTailor !== undefined && this.state.measuredByTailor}
            onChange={() => this.setMeasuredByTailor(true)}
            className={`tracker-button-yes_no-Yes-200619-111519`}
          />
          <FormRadioItem
            label={'No'}
            checked={this.state.measuredByTailor !== undefined && !this.state.measuredByTailor}
            onChange={() => this.setMeasuredByTailor(false)}
            className={`tracker-button-yes_no-No-200619-111519`}
          />
        </FormRadio>

        <FormNavigation next={this.onSubmit} />
      </div>
    );
  }
}

export default FitMeasuredByTailor;
