import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AddressesStore from '../../stores/AddressesStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { GlobalContextTyping, Address, FlowEventRouteProps } from '../../types';
import { AccessContext, IsEventMember } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';
import { isPOBox, isNumber } from '../../utils/utils';
import ConfirmAddress from '../../utils/Component/ConfirmAddress';
import { validateAddress } from '../../services/Warehouse';
import usStates from '../../utils/usStates';
import Loading from '../../utils/Component/Loading';
import MemberStore from '../../stores/MemberStore';
import EventStore from '../../stores/EventStore';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import Message from '../../components/Message';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';

interface Props extends FlowEventRouteProps<{}> {
  globalContext?: GlobalContextTyping;
}

interface State {
  suggestedAddress: Address;
  firstNameErr: boolean;
  lastNameErr: boolean;
  addressLine1Err: boolean;
  cityErr: boolean;
  stateErr: boolean;
  zipErr: boolean;
  poBoxErr: boolean;
  confirmAddress: boolean;
  useDefault: boolean;
  submitting: boolean;
  error?: string;
  loading: boolean;
  outflowIndex: number;
}

const emptyAddressStub: Address = {
  id: undefined,
  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  isDefault: true,
  country: 'US',
};

class Shipping extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      suggestedAddress: {
        id: undefined,
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        isDefault: true,
        country: 'US',
      },
      firstNameErr: false,
      lastNameErr: false,
      addressLine1Err: false,
      cityErr: false,
      stateErr: false,
      zipErr: false,
      poBoxErr: false,
      confirmAddress: false,
      useDefault: false,
      submitting: false,
      error: undefined,
      loading: true,
      outflowIndex: 0,
    };
  }
  async componentDidMount() {
    await AddressesStore.loadAddresses();

    this.setState({
      useDefault: AddressesStore.currentAddress.id ? AddressesStore.currentAddress.isDefault : false,
      loading: false,
    });
  }

  handleAddressChange = (field: string, val: string) => {
    AddressesStore.handleCurrentAddressChange(field, val);
    this.setState({
      error: undefined,
    });
  };

  handleAddressZipChange = (val: string) => {
    if (val.length <= 5 || (val === '' && isNumber(val))) {
      AddressesStore.handleCurrentAddressChange('zip', val);
      this.setState({
        error: undefined,
      });
    } else {
      return;
    }
  };

  handleUseDefaultChange = () => {
    if (!this.state.useDefault) {
      AddressesStore.setCurrentAddressAsDefault();
    } else {
      AddressesStore.setCurrentAddress(emptyAddressStub);
    }
    this.setState((state) => ({
      useDefault: !state.useDefault,
    }));
  };

  handleResetToDefault = () => {
    AddressesStore.setCurrentAddressAsDefault();
    this.setState((state) => ({
      useDefault: !state.useDefault,
    }));
  };

  nextPage = () => {
    let outflowIndex = 0;
    if (this.props.history.location.pathname.includes('/invited')) {
      outflowIndex = MemberStore.getSignedInMember()!.isPaid! ? 1 : 0;
    }

    this.setState({
      error: undefined,
      confirmAddress: false,
      outflowIndex,
    });

    this.props.flow!(`${this.props.location.search}`, this.state.outflowIndex);
  };

  validateInput = () => {
    const firstNameErr = AddressesStore.currentAddress.firstName === '';
    const lastNameErr = AddressesStore.currentAddress.lastName === '';
    const addressLine1Err = AddressesStore.currentAddress.addressLine1 === '';
    const cityErr = AddressesStore.currentAddress.city === '';
    const stateErr = AddressesStore.currentAddress.state === '';
    const zipErr = AddressesStore.currentAddress.zip === '';
    const poBoxErr = isPOBox(AddressesStore.currentAddress.addressLine1);

    this.setState({
      firstNameErr,
      lastNameErr,
      addressLine1Err,
      cityErr,
      stateErr,
      zipErr,
      poBoxErr,
    });

    return !(firstNameErr || lastNameErr || addressLine1Err || cityErr || stateErr || zipErr || poBoxErr);
  };

  validateAddress = async () => {
    try {
      const {
        validatedAddress,
        addressChanged,
      }: {
        validatedAddress: {
          addressLine1: string;
          addressLine2: string;
          city: string;
          state: string;
          zip: string;
        };
        addressChanged: boolean;
      } = await validateAddress({
        addressLine1: AddressesStore.currentAddress.addressLine1,
        addressLine2: '',
        city: AddressesStore.currentAddress.city,
        state: AddressesStore.currentAddress.state,
        zip: AddressesStore.currentAddress.zip,
        country: 'US',
      });

      // If the address has changed we want to suggest the changes to the
      // user.
      if (addressChanged) {
        // construct the
        const suggestedAddress = {
          id: AddressesStore.currentAddress.id,
          firstName: AddressesStore.currentAddress.firstName,
          lastName: AddressesStore.currentAddress.lastName,
          addressLine1: `${validatedAddress.addressLine1} ${
            validatedAddress.addressLine2 ? validatedAddress.addressLine2 : ''
          }`,
          city: validatedAddress.city,
          state: validatedAddress.state,
          zip: validatedAddress.zip,
          isDefault: true,
          country: 'US',
        };

        // set the address and the confirm address modal
        this.setState({
          suggestedAddress: suggestedAddress,
          confirmAddress: true,
        });
      } else {
        this.addOrUpdateAddress();
      }
    } catch {
      this.setState({
        submitting: false,
        error: 'This address is invalid. Please re-enter a valid address and try again.',
      });
    }
  };

  unconfirmAddress = () => {
    this.setState({
      confirmAddress: false,
      submitting: false,
    });
  };

  setSuggestionAddress = () => {
    AddressesStore.setCurrentAddress(this.state.suggestedAddress);
    this.addOrUpdateAddress();
  };

  goBack = () => {
    if (this.props.history.location.pathname.includes('/hto-abr/')) {
      return this.props.history.push(`/hto-abr/black-tux-try-on`);
    }

    if (this.props.history.location.pathname.includes('/hto/')) {
      return this.props.history.push(`/hto/looks/build?htoFlow=true`);
    }

    if (this.props.history.location.pathname.includes('/event/')) {
      return this.props.history.push(`/event-flow/checkout${this.props.location.search}`);
    }

    if (this.props.history.location.pathname.includes('/invited/') && MemberStore.getSignedInMember()!.isPaid!) {
      return this.props.history.push(
        `/fit-flow/invited/preference?memberId=${MemberStore.getSignedInMember()!.id!}&eventId=${EventStore.event.id!}`
      );
    }

    if (
      this.props.history.location.pathname.includes('/invited/') &&
      !MemberStore.getSignedInMember()!.isPaid! &&
      AddressesStore.currentAddress.id !== undefined
    ) {
      return this.props.history.push(
        `/invited/preview?memberId=${MemberStore.getSignedInMember()!.id!}&eventId=${EventStore.event.id!}`
      );
    }

    if (
      this.props.history.location.pathname.includes('/invited/') &&
      !MemberStore.getSignedInMember()!.isPaid! &&
      AddressesStore.currentAddress.id === undefined
    ) {
      return this.handleResetToDefault();
    }
  };

  addOrUpdateAddress = async () => {
    try {
      await AddressesStore.addOrUpdateAddress();
      this.nextPage();
    } catch (err) {
      this.setState({
        confirmAddress: false,
        submitting: false,
        error: 'There was a problem creating or updating this address. Please try again.',
      });
    }
  };

  onSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ submitting: true });
    if (this.validateInput()) {
      this.validateAddress();
    } else {
      this.setState({ submitting: false });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const { firstName, lastName, addressLine1, city, state, zip } = AddressesStore.currentAddress;

    return (
      <>
        <FormFlowLogo />

        <div data-testid="shared-shipping" className="container">
          <div className="mx-auto max-w-lg">
            <h2 className="text-h2-display mb-8">Shipping address</h2>

            <p className="text-sm text-gray-dark">* We do not ship internationally</p>

            {this.state.confirmAddress ? (
              <ConfirmAddress
                suggestedAddressStreet={this.state.suggestedAddress.addressLine1}
                suggestedAddressCity={this.state.suggestedAddress.city}
                suggestedAddressState={this.state.suggestedAddress.state}
                suggestedAddressZip={this.state.suggestedAddress.zip}
                currentAddressStreet={AddressesStore.currentAddress.addressLine1}
                currentAddressCity={AddressesStore.currentAddress.city}
                currentAddressState={AddressesStore.currentAddress.state}
                currentAddressZip={AddressesStore.currentAddress.zip}
                setEntryAddress={this.addOrUpdateAddress}
                setSuggestionAddress={this.setSuggestionAddress}
                unconfirmAddress={this.unconfirmAddress}
              />
            ) : (
              <form className="w-full" onSubmit={this.onSubmit}>
                {this.state.useDefault ? (
                  AddressesStore.addresses.find((a) => a.isDefault) && (
                    <>
                      {/* Checkbox */}
                      <div className="mb-32 mt-32 flex flex-wrap justify-between bg-white px-16 py-32 xs:mb-64 xs:px-32">
                        <p>
                          {typeof firstName !== 'undefined' && (
                            <>
                              {firstName} {lastName}
                            </>
                          )}
                          <br />
                          {addressLine1}
                          <br />
                          {city}, {state} {zip}
                        </p>
                        <div>
                          <button
                            className="tracker-button-shipping-change-200619-111519 btn btn-sm btn-default"
                            onClick={this.handleUseDefaultChange}
                          >
                            Change
                          </button>
                        </div>
                      </div>
                      {/* Checkbox end */}
                    </>
                  )
                ) : (
                  <div className="mb-32 mt-32 xs:mb-64">
                    {typeof firstName !== 'undefined' && (
                      <>
                        <div className="my-16">
                          <label htmlFor="firstName" className="sr-only">
                            First Name
                          </label>
                          <FormInput
                            className="text-sm w-full border-gray"
                            id="firstName"
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              this.handleAddressChange('firstName', e.target.value.trim())
                            }
                          />
                          {this.state.firstNameErr && <Message type="error" message="This field is required." />}
                        </div>

                        <div className="my-16">
                          <label htmlFor="lastName" className="sr-only">
                            Last Name
                          </label>
                          <FormInput
                            className="text-sm w-full border-gray"
                            id="lastName"
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              this.handleAddressChange('lastName', e.target.value.trim())
                            }
                          />
                          {this.state.lastNameErr && <Message type="error" message="This field is required." />}
                        </div>
                      </>
                    )}

                    <div className="my-16">
                      <label htmlFor="addressLine1" className="sr-only">
                        Street Address
                      </label>

                      <FormInput
                        className="text-sm w-full border-gray"
                        id="addressLine1"
                        name="addressLine1"
                        type="text"
                        placeholder="Street Address"
                        value={addressLine1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleAddressChange('addressLine1', e.target.value)
                        }
                      />
                      {this.state.addressLine1Err && <Message type="error" message="This field is required." />}
                      {this.state.poBoxErr && <Message type="error" message="Address cannot be a P.O. Box" />}
                    </div>

                    <div className="my-16">
                      <label htmlFor="city" className="sr-only">
                        City
                      </label>
                      <FormInput
                        className="text-sm w-full border-gray"
                        id="city"
                        type="text"
                        name="city"
                        placeholder="City"
                        value={city}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleAddressChange('city', e.target.value)
                        }
                      />
                      {this.state.cityErr && <Message type="error" message="This field is required." />}
                    </div>

                    <div className="my-16">
                      <label htmlFor="stateSelect" className="sr-only">
                        State
                      </label>
                      <FormSelect
                        className="text-sm w-full border-gray bg-gray-lighter"
                        id="stateSelect"
                        value={state}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          this.handleAddressChange('state', e.target.value.trim())
                        }
                      >
                        <option value="">Select State</option>
                        {usStates.map((state) => (
                          <option key={`${state.abbreviation}-${state.name}`} value={state.abbreviation}>
                            {state.name}
                          </option>
                        ))}
                      </FormSelect>
                      {this.state.stateErr && <Message type="error" message="This field is required." />}
                    </div>

                    <div className="my-16">
                      <label htmlFor="zip" className="sr-only">
                        Zip code
                      </label>
                      <FormInput
                        className="text-sm w-full border-gray"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        id="zip"
                        type="text"
                        name="zip"
                        placeholder="Zip Code"
                        value={zip}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.handleAddressZipChange(e.target.value.trim())
                        }
                      />
                      {this.state.zipErr && <Message type="error" message="This field is required." />}
                    </div>
                  </div>
                )}

                <div className="mb-32 mt-32 flex flex-row-reverse gap-8 xs:mb-64">
                  <button
                    className="tracker-cta-shipping-submit-200619-111519 btn btn-info grow"
                    data-testid="btn-submit"
                    disabled={this.state.submitting}
                    type="submit"
                  >
                    {!this.state.submitting ? 'Submit' : 'Submitting...'} <IconArrowRight />
                  </button>

                  <button
                    className="tracker-button-shipping-back-200619-111519 btn btn-default"
                    onClick={() => this.goBack()}
                    aria-label="Back"
                  >
                    {<IconArrowLeft />}
                  </button>
                </div>

                {this.state.error && <Message type="error" message={this.state.error} />}
              </form>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default IsEventMember(Flow(AccessContext(observer(Shipping))));
