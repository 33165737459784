import React from 'react';

const AddMember = () => (
  <svg width="124px" height="90px" viewBox="0 0 124 90" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Add Members Illustration</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="web-welcome-" transform="translate(-553.000000, -371.000000)">
        <g id="add-members" transform="translate(553.000000, 372.000000)">
          <path d="M74.1822,35.9226 L74.1822,33.0476" id="Stroke-1" stroke="#CCCCCC" />
          <path d="M33.4212,36.4666 L33.4212,33.5916" id="Stroke-2" stroke="#CCCCCC" />
          <path d="M33.4212,55.1958 L33.4212,52.3208" id="Stroke-3" stroke="#CCCCCC" />
          <path d="M33.4212,73.9414 L33.4212,71.0664" id="Stroke-4" stroke="#CCCCCC" />
          <polyline id="Stroke-5" stroke="#CCCCCC" points="52.2686 81.449 74.1826 81.449 74.1826 78.11" />
          <polygon id="Fill-6" fill="#FFFFFF" points="0.5 50.4391 49.156 50.4391 49.156 38.3321 0.5 38.3321" />
          <polygon id="Stroke-7" stroke="#CCCCCC" points="0.5 50.4391 49.156 50.4391 49.156 38.3321 0.5 38.3321" />
          <polygon id="Fill-9" fill="#FFFFFF" points="0.5 69.1841 49.156 69.1841 49.156 57.0771 0.5 57.0771" />
          <polygon id="Stroke-10" stroke="#CCCCCC" points="0.5 69.1851 49.156 69.1851 49.156 57.0781 0.5 57.0781" />
          <polygon id="Fill-12" fill="#FFFFFF" points="0.5 87.9311 49.156 87.9311 49.156 75.8231 0.5 75.8231" />
          <polygon id="Stroke-13" stroke="#CCCCCC" points="0.5 87.9301 49.156 87.9301 49.156 75.8231 0.5 75.8231" />
          <polygon id="Fill-15" fill="#FFFFFF" points="0.5 31.6931 49.156 31.6931 49.156 19.5861 0.5 19.5861" />
          <polygon id="Stroke-16" stroke="#CCCCCC" points="0.5 31.6931 49.156 31.6931 49.156 19.5861 0.5 19.5861" />
          <polygon id="Fill-18" fill="#FFFFFF" points="58.271 75.2601 122.959 75.2601 122.959 37.7691 58.271 37.7691" />
          <g id="Group-36" transform="translate(6.000000, 0.430100)">
            <polygon id="Stroke-19" stroke="#CCCCCC" points="52.271 74.83 116.959 74.83 116.959 37.339 52.271 37.339" />
            <path
              d="M116.9593,37.3387 L89.1523,63.6107 C86.6063,66.0167 82.6253,66.0167 80.0793,63.6107 L52.2713,37.3387"
              id="Stroke-21"
              stroke="#CCCCCC"
            />
            <g id="Group-24" transform="translate(94.000000, 58.000000)">
              <path d="M22.9593,16.8314 L0.7403,0.3354" id="Fill-22" fill="#606060" />
              <path d="M22.9593,16.8314 L0.7403,0.3354" id="Stroke-23" stroke="#CCCCCC" />
            </g>
            <g id="Group-27" transform="translate(52.000000, 58.000000)">
              <path d="M0.2718,16.8314 L22.4908,0.3354" id="Fill-25" fill="#606060" />
              <path d="M0.2718,16.8314 L22.4908,0.3354" id="Stroke-26" stroke="#CCCCCC" />
            </g>
            <path
              d="M78.8504,25.5865 C75.8844,27.7285 72.2414,28.9905 68.3024,28.9905 C64.5114,28.9905 60.9934,27.8205 58.0904,25.8225"
              id="Stroke-28"
              stroke="#B0893A"
              strokeLinecap="round"
            />
            <path
              d="M78.8455,25.5865 C78.6815,19.5135 73.5645,15.4855 68.4475,15.4855 C63.2465,15.4855 58.0455,19.6465 58.0455,25.8875"
              id="Stroke-29"
              stroke="#B0893A"
            />
            <path d="M0.6873,25.2098 L30.7873,25.2098" id="Stroke-30" stroke="#B0893A" />
            <path d="M0.6873,43.9554 L19.5373,43.9554" id="Stroke-31" stroke="#B0893A" />
            <path d="M0.6873,62.701 L13.9123,62.701" id="Stroke-32" stroke="#B0893A" />
            <path d="M0.6873,81.4464 L25.1623,81.4464" id="Stroke-33" stroke="#B0893A" />
            <path
              d="M73.9432,5.7645 C73.9432,11.4595 71.3932,13.3575 68.2482,13.3575 C65.1032,13.3575 62.5542,11.4595 62.5542,5.7645 C62.5542,2.6195 65.1032,0.0695 68.2482,0.0695 C71.3932,0.0695 73.9432,2.6195 73.9432,5.7645 Z"
              id="Stroke-34"
              stroke="#B0893A"
            />
            <polyline id="Stroke-35" stroke="#CCCCCC" points="58.0435 12.8092 27.1625 12.8092 27.1625 15.4942" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AddMember;
