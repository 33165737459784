import { useState, useEffect } from 'react';
import { ExperimentToBuckets } from '../../static/experiments';
import { getExperiments, getUserBuckets, onUserBucketed } from '../../services/optimizely';

type MaybeUserBuckets = Partial<ExperimentToBuckets> | null;

export type OptimizelyData = {
  loaded: boolean;
  data: MaybeUserBuckets;
};

const useOptimizelyData = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<MaybeUserBuckets>(null);

  const setUserInfo = async () => {
    const experiments = await getExperiments();

    if (!experiments) {
      return;
    }

    const buckets = getUserBuckets(experiments);

    setData(buckets);
  };

  useEffect(() => {
    setUserInfo().then((_) => setLoaded(true));

    onUserBucketed((_) => setUserInfo());
  }, []);

  return {
    loaded,
    data,
  };
};

export default useOptimizelyData;
