import React from 'react';
import { FitBuildStomach, FitbuildProfile, FitOutfitPreferences } from '../../../../types';
import Message from '../../../../components/Message';
import FormSelect from '../../../../components/FormSelect';
import FormInput from '../../../../components/FormInput';
import FormRadioItem from '../../../../components/FormRadioItem';
import FormRadio from '../../../../components/FormRadio';

interface Props {
  heightFeet: string;
  heightInches: string;
  weight: string;
  shoeSize: string;
  pantWaist: string;
  age: string;
  stomachProfile: FitBuildStomach;
  buildProfile: FitbuildProfile;
  fitPreference: FitOutfitPreferences;
  heightFeetError: boolean;
  heightInchesError: boolean;
  weightError: boolean;
  shoeSizeError: boolean;
  pantWaistError: boolean;
  ageError: boolean;
  buildProfileError: boolean;
  stomachProfileError: boolean;
  fitPreferenceError: boolean;
  handleSubmit: () => void;
  handleChange: (key: string, value: React.ChangeEvent<HTMLFormElement> | string) => void;
  isLoaded: boolean;
  loadError: boolean;
  submitting: boolean;
}

const FitProfileCard: React.StatelessComponent<Props> = ({
  heightFeet,
  heightInches,
  weight,
  shoeSize,
  pantWaist,
  age,
  stomachProfile,
  buildProfile,
  fitPreference,
  heightFeetError,
  heightInchesError,
  weightError,
  shoeSizeError,
  pantWaistError,
  ageError,
  buildProfileError,
  stomachProfileError,
  fitPreferenceError,
  handleSubmit,
  handleChange,
  isLoaded,
  loadError,
  submitting,
}) => (
  <>
    <div className="mb-32 space-y-24 bg-white p-32 pb-46 shadow-2xl">
      {heightFeetError || heightInchesError ? <Message type="error">Please select a height.</Message> : false}
      <div>
        <p className="text-h4 normal-case mb-8 ml-16">Height</p>
        <fieldset className="m-0 flex p-0">
          <FormSelect
            combineLabel
            className="mr-8 grow"
            id="heightFeet"
            value={heightFeet}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange('heightFeet', e.target.value)}
            inputAddOn="test"
          >
            <option value="3">3'</option>
            <option value="4">4'</option>
            <option value="5">5'</option>
            <option value="6">6'</option>
          </FormSelect>
          <FormSelect
            id="heightInches"
            className="grow"
            value={heightInches}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange('heightInches', e.target.value)}
          >
            <option value="0">0"</option>
            <option value="1">1"</option>
            <option value="2">2"</option>
            <option value="3">3"</option>
            <option value="4">4"</option>
            <option value="5">5"</option>
            <option value="6">6"</option>
            <option value="7">7"</option>
            <option value="8">8"</option>
            <option value="9">9"</option>
            <option value="10">10"</option>
            <option value="11">11"</option>
          </FormSelect>
        </fieldset>
      </div>

      <FormInput
        className="w-full"
        label="Weight"
        combineLabel
        type="text"
        id="weight"
        name="weight"
        inputAddOn="LBS"
        value={weight}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('weight', e.target.value)}
        errorMessage={weightError ? 'Please provide a weight.' : ''}
      />
      <FormInput
        className="w-full"
        label="Age"
        combineLabel
        type="text"
        id="age"
        name="age"
        inputAddOn="YRS"
        value={age}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('age', e.target.value)}
        errorMessage={ageError ? 'Please provide an age.' : ''}
      />
      <FormSelect
        className="w-full"
        label="Shoe Size"
        combineLabel
        id="shoeSize"
        value={shoeSize}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange('shoeSize', e.target.value)}
        errorMessage={shoeSizeError ? 'Please select a shoe size.' : ''}
      >
        <option />
        <optgroup label="Mens">
          <option value="6.0M">6.0 M</option>
          <option value="6.5M">6.5 M</option>
          <option value="7.0M">7.0 M</option>
          <option value="7.5M">7.5 M</option>
          <option value="8.0M">8.0 M</option>
          <option value="8.5M">8.5 M</option>
          <option value="9.0M">9.0 M</option>
          <option value="9.5M">9.5 M</option>
          <option value="10.0M">10.0 M</option>
          <option value="10.5M">10.5 M</option>
          <option value="11.0M">11.0 M</option>
          <option value="11.5M">11.5 M</option>
          <option value="12.0M">12.0 M</option>
          <option value="13.0M">13.0 M</option>
          <option value="14.0M">14.0 M</option>
          <option value="15.0M">15.0 M</option>
          <option value="16.0M">16.0 M</option>
        </optgroup>
        <optgroup label="Youth">
          <option value="1.0Y">1 Y</option>
          <option value="2.0Y">2 Y</option>
          <option value="3.0Y">3 Y</option>
          <option value="4.0Y">4 Y</option>
          <option value="5.0Y">5 Y</option>
          <option value="6.0Y">6 Y</option>
        </optgroup>
        <optgroup label="Kids">
          <option value="8.0K">8 K</option>
          <option value="9.0K">9 K</option>
          <option value="10.0K">10 K</option>
          <option value="11.0K">11 K</option>
          <option value="12.0K">12 K</option>
          <option value="13.0K">13 K</option>
        </optgroup>
      </FormSelect>

      <FormInput
        className="w-full"
        label="Jean Waist"
        combineLabel
        type="text"
        id="waist"
        name="waist"
        inputAddOn="IN"
        value={pantWaist}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('pantWaist', e.target.value)}
        errorMessage={pantWaistError ? 'Please provide a pant waist.' : ''}
      />
    </div>

    <div className="mb-32 space-y-24 bg-white p-32 shadow-2xl">
      <h4 className="text-h4">Body Type</h4>
      <div className={stomachProfile === 'average' && buildProfile === 'skinny' ? 'block' : 'hidden'}>
        <img
          className="m-auto"
          height="230"
          width="230"
          src="https://gentux.imgix.net/1554128060_gt-fit-average-skinny.png"
          alt="illustration of average skinny fit profile"
        />
      </div>
      <div
        className={
          (stomachProfile === undefined ||
          buildProfile === undefined ||
          (stomachProfile === 'average' && buildProfile === 'average')
            ? 'block'
            : 'hidden') +
          ' ' +
          (stomachProfile === undefined || buildProfile === undefined ? 'opacity-25' : 'opacity-100')
        }
      >
        <img
          className="m-auto"
          width="230"
          height="230"
          src="https://gentux.imgix.net/1554128061_gt-fit-average-average.png"
          alt="illustration of average average fit profile"
        />
      </div>
      <div className={stomachProfile === 'average' && buildProfile === 'muscular' ? 'block' : 'hidden'}>
        <img
          className="m-auto"
          width="230"
          height="230"
          src="https://gentux.imgix.net/1554128060_gt-fit-average-muscular.png"
          alt="illustration of average muscular fit profile"
        />
      </div>
      <div className={stomachProfile === 'flat' && buildProfile === 'skinny' ? 'block' : 'hidden'}>
        <img
          className="m-auto"
          width="230"
          height="230"
          src="https://gentux.imgix.net/1554128061_gt-fit-flat-skinny.png"
          alt="illustration of flat skinny fit profile"
        />
      </div>
      <div className={stomachProfile === 'flat' && buildProfile === 'average' ? 'block' : 'hidden'}>
        <img
          className="m-auto"
          width="230"
          height="230"
          src="https://gentux.imgix.net/1554128060_gt-fit-flat-average.png"
          alt="illustration of flat average fit profile"
        />
      </div>
      <div className={stomachProfile === 'flat' && buildProfile === 'muscular' ? 'block' : 'hidden'}>
        <img
          className="m-auto"
          width="230"
          height="230"
          src="https://gentux.imgix.net/1554128060_gt-fit-flat-muscular.png"
          alt="illustration of flat muscular fit profile"
        />
      </div>
      <div className={stomachProfile === 'rounded' && buildProfile === 'skinny' ? 'block' : 'hidden'}>
        <img
          className="m-auto"
          width="230"
          height="230"
          src="https://gentux.imgix.net/1554128061_gt-fit-rounded-skinny.png"
          alt="illustration of rounded skinny fit profile"
        />
      </div>
      <div className={stomachProfile === 'rounded' && buildProfile === 'average' ? 'block' : 'hidden'}>
        <img
          className="m-auto"
          width="230"
          height="230"
          src="https://gentux.imgix.net/1554128061_gt-fit-rounded-average.png"
          alt="illustration of rounded average fit profile"
        />
      </div>
      <div className={stomachProfile === 'rounded' && buildProfile === 'muscular' ? 'block' : 'hidden'}>
        <img
          className="m-auto"
          width="230"
          height="230"
          src="https://gentux.imgix.net/1554128061_gt-fit-rounded-muscular.png"
          alt="illustration of rounded muscular fit profile"
        />
      </div>

      <div className="flex flex-col">
        <FormRadio label="Stomach" errorMessage={stomachProfileError ? 'Required' : ''}>
          <FormRadioItem
            label="Flat"
            id="stomach-flat"
            checked={stomachProfile === 'flat'}
            onChange={() => handleChange('stomachProfile', 'flat')}
          />
          <FormRadioItem
            label="Average"
            id="stomach-avg"
            checked={stomachProfile === 'average'}
            onChange={() => handleChange('stomachProfile', 'average')}
          />
          <FormRadioItem
            label="Rounded"
            id="stomach-rounded"
            checked={stomachProfile === 'rounded'}
            onChange={() => handleChange('stomachProfile', 'rounded')}
          />
        </FormRadio>
      </div>
      <div className="flex flex-col">
        <FormRadio label="Build" errorMessage={buildProfileError ? 'Required' : ''}>
          <FormRadioItem
            label="Skinny"
            id="build-skinny"
            checked={buildProfile === 'skinny'}
            onChange={() => handleChange('buildProfile', 'skinny')}
          />
          <FormRadioItem
            label="Average"
            id="build-avg"
            checked={buildProfile === 'average'}
            onChange={() => handleChange('buildProfile', 'average')}
          />
          <FormRadioItem
            label="Muscular"
            id="build-muscular"
            checked={buildProfile === 'muscular'}
            onChange={() => handleChange('buildProfile', 'muscular')}
          />
        </FormRadio>
      </div>
    </div>

    <div className="mb-32 bg-white p-32 shadow-2xl">
      {fitPreferenceError && <Message type="error">Please select a fit preference</Message>}
      <FormRadio label="Fit Preference">
        <FormRadioItem
          label="Modern"
          id="fit-preference-modern"
          value="modern"
          checked={fitPreference === 'modern'}
          onChange={() => handleChange('fitPreference', 'modern')}
          description="Our standard fit with a slightly slim and tapered fit. Flatters most body types."
        />
        <FormRadioItem
          label="Slim"
          id="fit-preference-slim"
          value="slim"
          checked={fitPreference === 'slim'}
          onChange={() => handleChange('fitPreference', 'slim')}
          description="A more fashion forward look. Our slim fit has a shorter coat, a closer fit and a more narrow, tapered pant leg."
        />
      </FormRadio>
    </div>

    <button
      className=" tracker-cta-fit_profile-save-200619-111519 btn btn-info mb-24"
      onClick={handleSubmit}
      disabled={submitting}
    >
      {submitting ? 'Saving Fit' : 'Save Fit'}
    </button>

    {loadError && (
      <Message type="error">
        We ran into an error loading your fit profile. Please contact Customer Service at 1-844-726-4889.
      </Message>
    )}
  </>
);

export default FitProfileCard;
