import { Bundle, Look, Product } from '../../types';
import { random } from '../../utils/utils';
import {
  isSuit,
  isTuxedo,
  isSolidWhiteShirt,
  shirtIsPleated,
  shirtIsWinged,
  isLongTie,
  getProductsByColor,
  bothProductsHaveMatchingSwatch,
  bothProductsHaveMatchingColor,
} from '../../utils/items';

export interface RandomizedLook extends Look {
  id: string;
  bundles: Bundle[];
  items: Product[];
}

export class AlgorithmMatchError extends Error {
  readonly kind = 'AlgorithmMatchError';
}

export interface LookByColorAlgorithm {
  generate(swatch: Product, products: Product[], bundles: Bundle[]): LookBySwatchResultSet;
}

interface BundleToProductMap {
  bundle: Bundle;
  products: ProductMap;
}

enum ProductTypes {
  Ties = 'ties',
  Vests = 'vests',
  Shoes = 'shoes',
  Socks = 'socks',
  Belts = 'belts',
  Shirts = 'shirts',
  Cufflinks = 'cufflinks',
  LapelPins = 'lapelPins',
  Suspenders = 'suspenders',
  Cummerbunds = 'cummerbunds',
  PocketSquares = 'pocketSquares',
}

const SWATCH_DEPENDENT_TYPES = [ProductTypes.Ties, ProductTypes.Cummerbunds, ProductTypes.PocketSquares] as const;

type ProductMap = Record<ProductTypes, Product[]>;

type SwatchProductMap = Pick<ProductMap, (typeof SWATCH_DEPENDENT_TYPES)[number]>;

const getProductMap = (products: Product[]): ProductMap => {
  const entries = Object.entries({
    [ProductTypes.Ties]: 'tie',
    [ProductTypes.PocketSquares]: 'pocket square',
    [ProductTypes.Cummerbunds]: 'cummerbund',
    [ProductTypes.Vests]: 'vest',
    [ProductTypes.Shoes]: 'shoe',
    [ProductTypes.Socks]: 'socks',
    [ProductTypes.Belts]: 'belt',
    [ProductTypes.Shirts]: 'shirt',
    [ProductTypes.Cufflinks]: 'cufflinks',
    [ProductTypes.Suspenders]: 'suspenders',
    [ProductTypes.LapelPins]: 'lapel pin',
  });

  return entries.reduce((map, entry) => {
    const [key, category] = entry;

    return {
      ...map,
      [key]: products.filter((product) => product.category?.toLowerCase() === category),
    };
  }, {} as ProductMap);
};

const hasProductsInEachCategory = (map: BundleToProductMap) => {
  const entries = Object.entries(map.products);

  return entries.every(([category, products]) => {
    if (products.length > 0 || category === ProductTypes.LapelPins) {
      return true;
    }

    if (isSuit(map.bundle)) {
      return category === ProductTypes.Cummerbunds || category === ProductTypes.Suspenders;
    }

    if (category === ProductTypes.Vests || category === ProductTypes.Cummerbunds) {
      return map.products.vests.length > 0 || map.products.cummerbunds.length > 0;
    }

    return category === ProductTypes.Belts;
  });
};

const getBeltsMatchingShoes = (belts: Product[], shoes: Product) => {
  return belts.filter((belt) => {
    return belt.color?.toLowerCase() === shoes.color?.toLowerCase();
  });
};

export class LookBySwatchResultSet {
  readonly canGenerateSuit: boolean;

  readonly canGenerateTuxedo: boolean;

  readonly bundleToPotentialOutcomes: { [key: number]: number };

  readonly totalPossibleLooks: number;

  constructor(protected readonly bundleToProducts: BundleToProductMap[]) {
    this.canGenerateSuit = bundleToProducts.some(({ bundle }) => bundle.type?.toLowerCase() === 'suit');

    this.canGenerateTuxedo = bundleToProducts.some(({ bundle }) => bundle.type?.toLowerCase() === 'tuxedo');

    this.bundleToPotentialOutcomes = this.getBundleToPotentialOutcomes(bundleToProducts);

    const totals = Object.values(this.bundleToPotentialOutcomes);

    this.totalPossibleLooks = totals.reduce((count, total) => count + total, 0);
  }

  getLooks = (max = 1, type?: 'suit' | 'tuxedo') => {
    const looks: RandomizedLook[] = [];

    if (max > this.totalPossibleLooks) {
      max = this.totalPossibleLooks;
    }

    for (let looksGenerated = 0; looksGenerated < max; ) {
      const look = this.getLook(type);

      if (looks.includes(look)) {
        continue;
      }

      looks.push(look);
      looksGenerated++;
    }

    return looks;
  };

  getLook = (type?: 'suit' | 'tuxedo') => {
    const look: RandomizedLook = {
      id: 'hasNoId',
      items: [],
      bundles: [],
      members: [],
    };

    if (this.totalPossibleLooks === 0) {
      throw new AlgorithmMatchError('No looks could be generated with the given result set');
    }

    const potentialBundles = this.getBundlesByType(type);

    if (potentialBundles.length === 0) {
      throw new AlgorithmMatchError(`Couldn't generate a look for the given type: ${type}`);
    }

    const { bundle, products } = random(potentialBundles);

    const tie = random(products.ties);

    const vestOrCummerbund = this.getVestOrCummerbund(bundle, tie, products.vests, products.cummerbunds);

    const shoes = random(products.shoes);

    const beltOrSuspenders = this.getBeltOrSuspenders(bundle, shoes, products.belts, products.suspenders);

    look.items = [
      tie,
      shoes,
      vestOrCummerbund,
      beltOrSuspenders,
      random(products.shirts),
      random(products.socks),
      random(products.cufflinks),
      random(products.pocketSquares),
    ];

    if (products.lapelPins.length > 0) {
      look.items.push(random(products.lapelPins));
    }

    look.bundles?.push(bundle);

    look.id = this.getLookId(bundle, look.items);

    return look;
  };

  protected getBundlesByType = (type?: 'suit' | 'tuxedo') => {
    return this.bundleToProducts.filter(({ bundle }) => {
      return !type || bundle.type?.toLowerCase() === type;
    });
  };

  protected getLookId = (bundle: Bundle, products: Product[]) => {
    return products.reduce((str, product) => `${str}+${product.id}`, String(bundle.id));
  };

  protected getVestOrCummerbund = (bundle: Bundle, tie: Product, vests: Product[], cummerbunds: Product[]) => {
    const bundleIsSuit = isSuit(bundle);
    const mustPickVest = bundleIsSuit || isLongTie(tie);
    const shouldPickVest = mustPickVest || Math.random() < 0.65;

    if (shouldPickVest && vests.length > 0) {
      return random(vests);
    }

    if (cummerbunds.length === 0) {
      throw new AlgorithmMatchError(
        `Neither a vest nor cummerbund could be matched to the current bundle (${bundle.id})`
      );
    }

    return random(cummerbunds);
  };

  protected getBeltOrSuspenders = (bundle: Bundle, shoes: Product, belts: Product[], suspenders: Product[]) => {
    if (!isSuit(bundle)) {
      return random(suspenders);
    }

    const beltsMatchingShoes = getBeltsMatchingShoes(belts, shoes);

    return random(beltsMatchingShoes);
  };

  protected getNumberOfBeltCombos = (belts: Product[], shoes: Product[]) => {
    return shoes.reduce((count, product) => {
      const matchingBelts = getBeltsMatchingShoes(belts, product);

      return count + matchingBelts.length;
    }, 0);
  };

  protected getNumberOfVestCombos = (vests: Product[], ties: Product[], bundle: Bundle) => {
    return vests.reduce((count, vest) => {
      const matchesBundle = bothProductsHaveMatchingSwatch(vest, bundle);

      if (matchesBundle) {
        return count + ties.length;
      }

      const matchingTies = ties.filter((tie) => bothProductsHaveMatchingSwatch(tie, vest));

      return count + matchingTies.length;
    }, 0);
  };

  protected getBundleToPotentialOutcomes = (bundleToProducts: BundleToProductMap[]) => {
    return bundleToProducts.reduce((totals, map) => {
      const { bundle, products } = map;

      const productOfAllItems = Object.values(products).reduce((count, list) => {
        return count * (list.length || 1);
      }, 1);

      const beltCombos = this.getNumberOfBeltCombos(products.belts, products.shoes);
      const beltDiff = products.shoes.length * products.belts.length - beltCombos;

      const vestCombos = this.getNumberOfVestCombos(products.vests, products.ties, bundle);
      const vestDiff = products.ties.length * products.vests.length - vestCombos;

      // if we're dealing with a suit then we'll have some number of belts and shoes that
      // can't work together, so we subtract that; otherwise we'll have some number of ties
      // and vests that don't work together and that'll be the subtrahend
      const subtrahend = isSuit(bundle) ? beltDiff : vestDiff;

      return {
        ...totals,
        [bundle.id]: productOfAllItems - subtrahend,
      };
    }, {});
  };
}

export class RandomLookBySwatch implements LookByColorAlgorithm {
  generate = (swatch: Product, products: Product[], bundles: Bundle[]) => {
    const productMap = getProductMap(products);

    const productsMatchingSwatch = SWATCH_DEPENDENT_TYPES.reduce((map, type) => {
      const items = productMap[type].filter((item) => item.swatch?.id === swatch.id);

      return {
        ...map,
        [type]: items,
      };
    }, {} as SwatchProductMap);

    const swatchHasAtLeastOneMatch = Object.values(productsMatchingSwatch).some((list) => list.length > 0);

    const swatchDependentProducts = this.getFallbackSwatchProducts(productsMatchingSwatch, productMap);

    productMap.lapelPins = this.getLapelPinsByTies(swatchDependentProducts.ties, productMap.lapelPins);

    const potentialBundles = bundles.map((bundle) => {
      if (!swatchHasAtLeastOneMatch) {
        return null;
      }

      return this.getBundleToProductMap(bundle, productMap, swatchDependentProducts);
    });

    const bundleToProducts = potentialBundles.filter((map): map is BundleToProductMap => map !== null);

    return new LookBySwatchResultSet(bundleToProducts);
  };

  getLapelPinsByTies = (ties: Product[], lapelPins: Product[]) => {
    return lapelPins.filter((pin) => {
      return ties.some((tie) => bothProductsHaveMatchingColor(pin, tie));
    });
  };

  getShirtsMatchingBundle = (shirts: Product[], bundle: Bundle) => {
    return shirts.filter((shirt) => {
      const isPleated = shirtIsPleated(shirt);
      const isWinged = shirtIsWinged(shirt);

      if (isTuxedo(bundle)) {
        return isSolidWhiteShirt(shirt) && (isPleated || isWinged);
      }

      return !isWinged && !isPleated;
    });
  };

  getShoesMatchingBundle = (shoes: Product[], bundle: Bundle) => {
    const bundleIsSuit = isSuit(bundle);

    const isBlueSuit = bundleIsSuit && bundle.color?.toLowerCase() === 'blue';

    return shoes.filter((shoe) => {
      const isBrownShoe = shoe.color?.toLowerCase() === 'brown';

      const isGlossy = shoe.displayName?.toLowerCase().includes('glossy');

      if (!bundleIsSuit) {
        return !isBrownShoe;
      }

      if (isBlueSuit) {
        return isBrownShoe;
      }

      return !isGlossy;
    });
  };

  getSocksMatchingBundle = (socks: Product[], bundle: Bundle) => {
    const tanPants =
      bundle.products?.filter((product) => {
        const isPants = product.category?.toLowerCase() === 'pant';

        const isTan = product.color?.toLowerCase() === 'tan';

        return isPants && isTan;
      }) ?? [];

    const bundleHasTanPants = tanPants.length > 0;

    return socks.filter((product) => {
      const isTan = product.color?.toLowerCase() === 'tan';

      if (bundleHasTanPants) {
        return isTan;
      }

      return !isTan;
    });
  };

  getVestsForSuit = (vests: Product[], bundle: Bundle) => {
    return vests.filter((vest) => bothProductsHaveMatchingSwatch(vest, bundle));
  };

  getVestsForTuxedo = (vests: Product[], ties: Product[], bundle: Bundle) => {
    return vests.filter((vest) => {
      if (vest.attributePrimary?.toLowerCase() === 'suit') {
        return false;
      }

      const matchesBundleSwatch = bothProductsHaveMatchingSwatch(vest, bundle);

      const hasAtLeastOneMatchingTie = ties.some((tie) => bothProductsHaveMatchingSwatch(tie, vest));

      return hasAtLeastOneMatchingTie || matchesBundleSwatch;
    });
  };

  getBeltsMatchingShoes = (belts: Product[], shoes: Product[]) => {
    return belts.filter((belt) => {
      return shoes.some((shoe) => bothProductsHaveMatchingColor(belt, shoe));
    });
  };

  getFallbackSwatchProducts = (swatchProducts: SwatchProductMap, allProducts: ProductMap) => {
    let { ties, pocketSquares } = swatchProducts;

    if (ties.length === 0) {
      ties = getProductsByColor('black', allProducts.ties);
    }

    if (pocketSquares.length === 0) {
      pocketSquares = getProductsByColor('white', allProducts.pocketSquares);
    }

    return {
      ...swatchProducts,
      pocketSquares,
      ties,
    };
  };

  getBundleToProductMap = (bundle: Bundle, products: ProductMap, productsMatchingSwatch: SwatchProductMap) => {
    const shirts = this.getShirtsMatchingBundle(products.shirts, bundle);
    const shoes = this.getShoesMatchingBundle(products.shoes, bundle);
    const socks = this.getSocksMatchingBundle(products.socks, bundle);

    const bundleIsSuit = isSuit(bundle);
    const defaultColor = bundleIsSuit ? 'brown' : 'black';

    const qualifiedProducts = {
      ...products,
      shirts,
      shoes,
      socks,
    };

    if (qualifiedProducts.shoes.length === 0) {
      qualifiedProducts.shoes = getProductsByColor(defaultColor, products.shoes);
    }

    if (bundleIsSuit) {
      qualifiedProducts.vests = this.getVestsForSuit(products.vests, bundle);
      qualifiedProducts.belts = this.getBeltsMatchingShoes(products.belts, qualifiedProducts.shoes);
    } else {
      qualifiedProducts.vests = this.getVestsForTuxedo(products.vests, productsMatchingSwatch.ties, bundle);

      if (bundle.color && productsMatchingSwatch.cummerbunds.length === 0) {
        productsMatchingSwatch.cummerbunds = getProductsByColor(bundle.color.toLowerCase(), products.cummerbunds);
      }
    }

    if (qualifiedProducts.belts.length === 0) {
      qualifiedProducts.belts = getProductsByColor(defaultColor, products.belts);
    }

    const map: BundleToProductMap = {
      bundle,
      products: {
        ...qualifiedProducts,
        ...productsMatchingSwatch,
      },
    };

    if (!hasProductsInEachCategory(map)) {
      return null;
    }

    return map;
  };
}

export const getTwoLooksForEachBundleType = (
  algorithm: LookByColorAlgorithm,
  swatch: Product,
  products: Product[],
  bundles: Bundle[]
) => {
  const results = algorithm.generate(swatch, products, bundles);

  if (results.totalPossibleLooks === 0) {
    return [];
  }

  // if we're unable to generate a specific kind of look with the given swatch,
  // we will just return up to four random results from what can be generated
  if (!results.canGenerateSuit || !results.canGenerateTuxedo) {
    return results.getLooks(4);
  }

  const tuxedos = results.getLooks(2, 'tuxedo');
  const suits = results.getLooks(2, 'suit');

  return [...tuxedos, ...suits];
};
