import { ReactNode, ChangeEvent, FC, HTMLProps, useCallback } from 'react';
import IconCheck from './IconCheck';
import IconLock from './IconLock';

interface FormCheckItemProps extends HTMLProps<HTMLInputElement> {
  name: string;
  combineLabel?: boolean;
  darkMode?: boolean;
  inputAddOn?: string;
  errorMessage?: string;
  disabledMessage?: string;
  description?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const FormCheckItem: FC<FormCheckItemProps> = (props) => {
  const { onChange, name, disabled, disabledMessage, checked, label, className, description } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
      return true;
    },
    [onChange]
  );

  return (
    <label
      className={`group relative z-0 mx-8 grid cursor-pointer items-center gap-x-16 px-8 py-8 ${className ?? ''}`}
      style={{ gridTemplateColumns: 'auto 1fr' }}
      htmlFor={name}
    >
      <input
        type="checkbox"
        className="peer absolute col-start-1 row-start-1 m-0 h-0 w-0 border-0 p-0 opacity-0"
        id={name}
        name={name}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />

      {/* checkbox */}
      <span className="pointer-events-none z-10 col-start-1 row-start-1 flex h-16 w-16 items-center justify-center border border-gray-dark bg-gray-lighter group-hover:bg-brand-lighter peer-checked:border-brand-darker peer-disabled:cursor-not-allowed peer-disabled:bg-transparent"></span>

      <span className="z-10 col-start-1 row-start-1 opacity-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed">
        <IconCheck className="!text-brand-darker" />
      </span>

      {/* label */}
      {label && (
        <div className="z-10 peer-checked:text-brand-darker peer-disabled:cursor-not-allowed peer-disabled:text-gray-dark">
          <span className="relative block leading-none">{label}</span>
        </div>
      )}

      {/* description */}
      {description && (
        <div className="z-10 col-start-2 row-start-2 peer-disabled:cursor-not-allowed">
          <span className="text-sm block text-gray-dark">{description}</span>
        </div>
      )}

      {/* background */}
      <span
        className={`pointer-events-auto absolute inset-0 z-0 cursor-pointer border border-transparent bg-white transition group-hover:border-brand-darker group-hover:bg-brand-lighter peer-focus:border-brand peer-focus:shadow-form-focus peer-disabled:cursor-not-allowed peer-disabled:border-transparent peer-disabled:bg-gray-lighter`}
      />

      {disabled && disabledMessage && (
        <div className="z-10 col-start-1 col-end-3 row-start-3 mt-8 flex cursor-not-allowed items-center gap-4 bg-gray-light py-8 pl-32 pr-8">
          <IconLock />
          <p className="text-sm text-black">{disabledMessage}</p>
        </div>
      )}
    </label>
  );
};

export default FormCheckItem;
