import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { CustomerClonedEventPromo } from '../../types';
import CustomerStore from '../../stores/CustomerStore';
import Line from '../../components/Line';

import { Disclosure } from '@headlessui/react';
import AnimateHeight from '../../components/AnimateHeight';
import IconTriangleDown from '../../components/IconTriangleDown';

const CustomerClonedEventPromos = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await CustomerStore.getClonedEventPromos();
      await CustomerStore.getMembers();
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) return null;

  const promos = CustomerStore.clonedEventPromos;
  const showCustomerClonedEventPromos = !!promos && promos.length > 0;
  if (!showCustomerClonedEventPromos) return null;

  return (
    <>
      {promos
        .filter((promo) => promo.promotion.active)
        .map((clonedEventPromo: CustomerClonedEventPromo, index) => (
          <Disclosure defaultOpen as="div" key={`disclosure-${index}`} className=" mb-64">
            {({ open }) => (
              <>
                <Disclosure.Button className="group flex w-full items-center justify-between bg-brand px-32 py-16">
                  <h3 className="text-h3-display">Credit for your rescheduled event</h3>

                  <IconTriangleDown
                    className={`shrink-0 fill-current transition  duration-150 group-hover:text-brand-darker ${
                      !open ? '' : 'rotate-90 transform'
                    }`}
                  />
                </Disclosure.Button>

                <AnimateHeight open={open}>
                  <Disclosure.Panel static className="border border-brand bg-gray-lighter p-32">
                    <div className="">
                      <div className="row">
                        <div className="col-span-12 md:col-span-6">
                          <p>
                            The {CustomerStore.sourceEventByPromo(clonedEventPromo)!.name} has been rescheduled due to
                            circumstances surrounding the COVID-19 Pandemic. As you have already paid for your rental
                            for the previously scheduled event, you now have a credit that you can apply to the
                            rescheduled event. At checkout, enter the code below in the box marked “Promo code”.
                          </p>
                        </div>
                        <div className="col-span-12 flex items-start md:col-span-6 md:justify-end lg:col-span-4 lg:col-start-9">
                          <div className="bg-white p-32">
                            <div className="flex items-center">
                              <div>
                                <p className="text-gray-darker">Promo code:&nbsp;&nbsp;</p>
                              </div>
                              <div>
                                <h4 className="text-h4">{clonedEventPromo.promotion.code || ''}</h4>
                              </div>
                            </div>
                            <div className="flex items-center">
                              <div>
                                <p className="text-gray-darker">Event link:&nbsp;&nbsp;</p>
                              </div>
                              <div>
                                <a
                                  href={`/event-flow/looks?eventId=${
                                    CustomerStore.clonedEventByPromo(clonedEventPromo)!.id
                                  }`}
                                  title="Event Link"
                                >
                                  {CustomerStore.clonedEventByPromo(clonedEventPromo)!.name}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-span-12 md:col-span-6">
                          <Line />
                          <p className="text-xs text-gray-darker">
                            The credit amount is equal to the net value of your original order. Credit cannot be applied
                            with other offers, is bound to the email address used on your original order, and is limited
                            to one (1) use. If you have any questions or need assistance processing your order, please
                            contact our Customer Experience team.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </AnimateHeight>
              </>
            )}
          </Disclosure>
        ))}
    </>
  );
};

export default observer(CustomerClonedEventPromos);
