import React from 'react';

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;

const IconLapelPin: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
      className={`h-[14px] w-[14px] stroke-current text-gray-dark ${className ? className : ''}`}
    >
      <title>Lapel Pin Icon</title>
      <path d="M9.222 7.0001c-.7412 1.5647-2.306 2.6353-4.2 2.6353-2.553 0-4.6118-2.0588-4.6118-4.6118C.4102 2.4706 2.469.412 5.0219.412c.6589 0 1.2353.1647 1.8118.3294" />
      <path d="M6.9986 9.2236c-1.5647-.7411-2.6353-2.3058-2.6353-4.2C4.3633 2.4706 6.422.412 8.975.412c2.553 0 4.6118 2.0588 4.6118 4.6117 0 .6589-.1647 1.2353-.3294 1.8118" />
      <path d="M6.9984 4.7764c1.5647.7411 2.6353 2.3059 2.6353 4.2 0 2.5529-2.0588 4.6117-4.6118 4.6117-2.553 0-4.6117-2.0588-4.6117-4.6117 0-.7412.1647-1.4.4117-1.9765" />
      <path d="M4.8594 7c.7412-1.5647 2.3059-2.6353 4.1176-2.6353 2.553 0 4.6118 2.0589 4.6118 4.6118 0 2.553-2.0588 4.6118-4.6118 4.6118-.7412 0-1.4-.1647-1.9765-.4118" />
    </svg>
  );
};

export default IconLapelPin;
