import EventStore from '../../stores/EventStore';
import InvitedMemberFormStore from '../../stores/InvitedMemberFormStore';
import CustomerStore from '../../stores/CustomerStore';
import MemberStore from '../../stores/MemberStore';
import Flow from '../../utils/HOC/Flow';
import { AccessContext } from '../../utils/HOC/';
import { observer } from 'mobx-react';
import MarketingPreferences, {
  Props as ParentProps,
  State as ParentState,
  SetStateFunction,
  experimentalInputText,
} from '../../shared/components/MarketingPreferences';
import { getUnsubscribedFromOptional } from '../../utils/CommunicationPreferences';

interface State {
  memberId: string;
}

const defaultState = {
  memberId: '',
};

type CombinedState = ParentState & State;

const onComponentMount = async (_: ParentProps, __: CombinedState, setState: SetStateFunction<CombinedState>) => {
  setState({
    channels: getUnsubscribedFromOptional(),
    smsOptIn: InvitedMemberFormStore.smsOptIn,
    emailOptIn: InvitedMemberFormStore.emailOptIn,
  });
};

const onExit = (params: URLSearchParams, parsed: any, state: CombinedState) => {
  params.set('eventId', EventStore.event.id || parsed.eventId);
  params.set('memberId', state.memberId);

  InvitedMemberFormStore.reset();
};

const onSubmit = async (
  _: ParentProps,
  state: CombinedState,
  setState: SetStateFunction<CombinedState>,
  proceed: VoidFunction
) => {
  InvitedMemberFormStore.update('smsOptIn', state.smsOptIn);
  InvitedMemberFormStore.update('emailOptIn', state.emailOptIn);

  await CustomerStore.update({
    ...window.gt.user,
    smsOptIn: state.smsOptIn,
    emailOptIn: state.emailOptIn,
    state: state.state,
  });

  const memberId = MemberStore.getSignedInMember()!.id!;

  setState({ memberId });

  proceed();
};

const InvitedMemberPreferences = MarketingPreferences({
  updateUserOnSave: onSubmit,
  setQueryParamsOnExit: onExit,
  defaultChildState: defaultState,
  onComponentMount: onComponentMount,
  headerText: 'Get Important Updates',
  preferenceToInputText: experimentalInputText,
});

export default Flow(AccessContext(observer(InvitedMemberPreferences)));
