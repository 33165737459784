import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { GlobalContextTyping } from '../../types';
import MyAccountWrapper from '../hoc/MyAccountWrapper';
import Spinner from '../../shared/components/Spinner';
import { pageFadeIn, pageFadeInDelayed } from '../../utils/Component/Animations';
import Line from '../../components/Line';
import Message from '../../components/Message';
import { Channel } from '../../partials/Channel';
import FormCheckGroup from '../../components/FormCheckGroup';
import { Transition } from '@headlessui/react';
import { getCommunicationPreferences, setCommunicationPreferences } from '../../services/Accounts';
import { getDataFromResponse } from '../../utils/GraphQL';
import {
  isEmailChannel,
  isSmsChannel,
  getUnsubscribedFromAll,
  CommunicationPreference,
  CommunicationPreferences as UserPreferences,
} from '../../utils/CommunicationPreferences';
import auth from '../../services/Auth';

interface Props extends RouteComponentProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  isLoading: boolean;
  loadError: boolean;
  submitting: boolean;
  preferences: UserPreferences;
  submitType: string;
  optInSuccess: boolean;
}

class CommunicationPreferences extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      preferences: getUnsubscribedFromAll(),
      loadError: false,
      submitting: false,
      submitType: 'email',
      optInSuccess: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await getCommunicationPreferences(auth.user().id);

      const data = await getDataFromResponse(response);

      if (!data.communicationPreferences) {
        throw new Error(`Unable to retrieve customer's communication preferences`);
      }

      const preferences = new UserPreferences(data.communicationPreferences);

      this.setState({
        isLoading: false,
        preferences,
      });
    } catch (e) {
      this.setState({
        loadError: true,
        isLoading: false,
      });
      console.error(e);
    }
  }

  optInClick = async (preference: CommunicationPreference) => {
    if (this.state.optInSuccess) {
      return;
    }

    await this.togglePreference(preference);

    this.setState({ optInSuccess: true });
  };

  updatePreference = async (preference: CommunicationPreference) => {
    try {
      this.setState({ submitting: true });

      const res = await setCommunicationPreferences(auth.user().id, {
        [preference.medium]: {
          [preference.type]: preference.subscribed,
        },
      });

      const data = await getDataFromResponse(res);

      if (!data.updateCommunicationPreferences) {
        throw new Error(`There was a problem updating the customer's communication preferences`);
      }
    } finally {
      this.setState({ submitting: false });
    }
  };

  togglePreference = async (preference: CommunicationPreference) => {
    preference.toggle();

    this.updatePreference(preference);
  };

  render() {
    const { isLoading, loadError } = this.state;

    return (
      <>
        <Transition {...pageFadeIn}>
          <h2 className="normal-case text-h2-display">Communication Preferences</h2>
          <Line />
        </Transition>

        <Transition {...pageFadeInDelayed}>
          {isLoading && !loadError && <Spinner type="minimal" />}

          {!isLoading && loadError && (
            <Message type="error">There was an issue loading this page. Please refresh and try again.</Message>
          )}

          {!isLoading && !loadError && (
            <div className="mb-64 space-y-64">
              <FormCheckGroup
                darkMode
                key="email-channels"
                label="Email"
                description="Select which types of email communication you would like to&nbsp;receive:"
              >
                {this.state.preferences
                  .asArray()
                  .filter((c) => isEmailChannel(c))
                  .map((channel, _) => (
                    <div key={`${channel.medium}-${channel.type}`} className="flex flex-col">
                      <Channel
                        key={`${channel.medium}-${channel.type}`}
                        channel={channel}
                        toggleChannel={this.togglePreference}
                      />
                    </div>
                  ))}
              </FormCheckGroup>

              <FormCheckGroup
                darkMode
                key="sms-channels"
                label="Text Message"
                description="Select which types of text message communication you would like to&nbsp;receive:"
              >
                {this.state.preferences
                  .asArray()
                  .filter((c) => isSmsChannel(c))
                  .map((channel, _) => (
                    <div key={`${channel.medium}-${channel.type}`} className="flex flex-col">
                      <Channel
                        key={`${channel.medium}-${channel.type}`}
                        channel={channel}
                        toggleChannel={this.togglePreference}
                      />
                    </div>
                  ))}
              </FormCheckGroup>
            </div>
          )}
        </Transition>
      </>
    );
  }
}

export default observer(MyAccountWrapper(CommunicationPreferences));
