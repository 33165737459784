import { useRef } from 'react';
import { observer } from 'mobx-react';

import PreviewStore from '../../../../stores/look-builder/PreviewStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';

import LookPreview from './LookPreview';
import BuilderPreviewStyles from './BuilderPreview.module.css';
import IconUndo from '../../../../components/IconUndo';
import IconRedo from '../../../../components/IconRedo';

import useOptimizelyData from '../../../../utils/hooks/useOptimizelyData';
import { EXPERIMENT_LOOK_BUILDER_VARIATION } from '../../../../constants/experiments';

const ButtonIcon = ({ ...props }) => (
  <button
    {...props}
    className={`btn btn-sm pointer-events-auto grid place-items-center gap-4 ${props.className ? props.className : ''}`}
  >
    {props.children}
  </button>
);

interface Props {
  mediaQueryXsMatches: boolean;
}

const BuilderPreview = (props: Props) => {
  const compiledImages = useRef(null);
  const sideCarCategory = SideCarStore.category;

  const isUndoable = PreviewStore.history.length > 1 && PreviewStore.currentHistoryIndex !== 0;
  const isRedoable = Boolean(PreviewStore.currentHistoryIndex < PreviewStore.history.length - 1);
  const previewHasItems = Boolean(PreviewStore.productLayers.length > 0);

  const optimizely = useOptimizelyData();
  const optimizelyUserBucket = optimizely.data?.lookBuilderTest;

  return (
    <div
      className="pointer-events-auto relative flex h-auto items-center overflow-visible text-center"
      ref={compiledImages}
      onTouchStart={() => {}}
    >
      {(isUndoable || isRedoable) && (
        <ButtonIcon
          disabled={!isUndoable}
          className="undo tracker-btn-look_builder-undo_history-20200508-111100"
          onClick={() => PreviewStore.undoHistory()}
        >
          <IconUndo />
          Undo
        </ButtonIcon>
      )}
      <div
        onClick={() => {
          if (optimizelyUserBucket === EXPERIMENT_LOOK_BUILDER_VARIATION) {
            if (SideCarStore.car === 'list') {
              PreviewStore.setPreviewExploded(!PreviewStore.previewExploded);
            }
          } else {
            PreviewStore.setPreviewExploded(!PreviewStore.previewExploded);
          }

          window.scrollTo(0, 0);
        }}
        className={`
          ${BuilderPreviewStyles.wrapper}
          tracker-btn-look_builder-toggle_preview_image_wrapper-20200508-111100
          group
          relative
          origin-center
          transition
          hover:cursor-pointer
          active:scale-[.95]
        `}
      >
        <button
          className={`
            btn-outline
            btn
            bg-white
            ${sideCarCategory ? 'hidden' : 'block'}
            absolute
            left-1/2
            top-[40%]
            z-50
            -translate-x-1/2
            ${previewHasItems ? 'opacity-0 group-hover:-translate-y-1/2' : 'opacity-100'}
            group-hover:opacity-100
          `}
        >
          Customize
        </button>

        <LookPreview items={PreviewStore.productLayers} />
      </div>

      {(isUndoable || isRedoable) && (
        <ButtonIcon
          disabled={!isRedoable}
          className="redo tracker-btn-look_builder-redo_history-20200508-111100"
          onClick={() => PreviewStore.redoHistory()}
        >
          <IconRedo />
          Redo
        </ButtonIcon>
      )}
    </div>
  );
};

export default observer(BuilderPreview);
