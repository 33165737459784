import React from 'react';
import { SVG } from 'types';

type Props = {
  label: string;
  disabled?: boolean;
  className?: string;
  icon?: React.ReactElement<SVG>;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const IconButton = ({ label, disabled = false, icon, onClick, className }: Props) => {
  const iconWithProps = icon ? React.cloneElement(icon, { fill: disabled ? '#888' : '#404042' }) : null;

  const classNames = {
    button: [
      className ?? '',
      'h-80 w-[320px] rounded-lg flex cursor-pointer items-center justify-between p-16 shadow-[0_20px_70px_rgba(0,0,0,0.15)]',
      disabled ? 'bg-[#CCC]' : 'bg-brand-light hover:bg-[#FCFAF4] hover:shadow-[0_30px_90px_rgba(0,0,0,0.30)]',
    ].join(' '),
  };

  return (
    <button disabled={disabled} onClick={onClick} type="button" className={classNames.button}>
      {iconWithProps && (
        <span className="flex h-[48px] w-[48px] flex-shrink-0 items-center justify-center">{iconWithProps}</span>
      )}
      {label && (
        <span
          className={`w-[208px] text-start text-[22px] font-normal leading-[130%] text-gray-darker ${
            disabled ? 'text-[#888]' : 'text-gray-darker'
          }`}
        >
          {label}
        </span>
      )}
    </button>
  );
};

export default IconButton;
