import React from 'react';

import GalleryStore from '../../../../stores/look-builder/GalleryStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import { Item } from '../../../../types';
import IconMagnifyingGlass from '../../../../components/IconMagnifyingGlass';

interface Props {
  item: Item;
  setAnimateDirection?: React.Dispatch<React.SetStateAction<string>>;
}

const SeeMore = ({ setAnimateDirection, item }: Props) => (
  <div className="absolute right-0 top-0 -translate-y-full transform">
    <button
      className={`btn btn-xs btn-default mb-8 mr-8 tracker-btn-look_builder-open_details-${SideCarStore.category!.replace(
        /-/g,
        '_'
      )}-${item.id}-20200508-111100`}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
        setAnimateDirection && setAnimateDirection('left'); /*
            since we use the rental bundle's images on the product page,
            left this to always use the rental bundle's/item's PDP images
          */
        GalleryStore.setTrackedGalleryItemElement(item.id.toString(), window.pageYOffset);
        window.scrollTo(0, 0);
        SideCarStore.set({
          car: 'details',
          category: SideCarStore.category,
          id: item.id,
        });
      }}
      title="See more details"
      aria-label="See more details"
      style={{ fontSize: '8px' }}
    >
      <div className="flex items-center">
        <IconMagnifyingGlass className="pointer-events-none" />
      </div>
    </button>
  </div>
);

export default SeeMore;
