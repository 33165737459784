import { ComponentType } from 'react';
import useOptimizelyData, { type OptimizelyData } from '../hooks/useOptimizelyData';

export type OptimizelyProps = {
  optimizely?: OptimizelyData;
};

/**
 * Provides interoperability with class based components until we're able to switch them
 * over to functional component style
 *
 * @deprecated for new components and those that can easily be converted to functional.
 *  Prefer [`useOptimizelyData()`](../hooks/useOptimizelyData.tsx) for cases like these.
 */
export default function withOptimizelyData<BaseProps = {}>(Component: ComponentType<BaseProps & OptimizelyProps>) {
  return (props: BaseProps) => {
    const optimizely = useOptimizelyData();

    return <Component optimizely={optimizely} {...props} />;
  };
}
