import React, { ReactNode } from 'react';

type Layout3x1Props = {
  block1: ReactNode;
  block2: ReactNode;
  block3: ReactNode;
  contentClassName?: string;
  matchHeight?: boolean;
};

type Props = React.HTMLAttributes<HTMLDivElement> & Layout3x1Props;

const Layout3x1 = ({
  className = '',
  children,
  block1,
  block2,
  block3,
  contentClassName = '',
  matchHeight = false,
  ...props
}: Props): JSX.Element => {
  return (
    <div {...props} className={`container ${className}`}>
      {children && (
        <div className="row">
          <div
            className={`
              col-span-12
              mb-32 space-y-16
              sm:col-span-10 sm:col-start-2
              ${contentClassName}
            `}
          >
            {children}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-span-12 sm:col-span-10 sm:col-start-2">
          <div className="row gap-y-32">
            <div
              className={`${
                matchHeight ? 'flex flex-1' : ''
              } col-span-9 col-start-3 space-y-16 sm:col-span-4 lg:col-span-4 lg:col-start-1`}
            >
              {block1}
            </div>

            <div
              className={`${
                matchHeight ? 'flex flex-1' : ''
              } col-span-9 col-start-3 space-y-16 sm:col-span-4 lg:col-span-4 lg:col-start-5`}
            >
              {block2}
            </div>

            <div
              className={`${
                matchHeight ? 'flex flex-1' : ''
              } col-span-9 col-start-3 space-y-16 sm:col-span-4 lg:col-span-4 lg:col-end-13`}
            >
              {block3}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout3x1;
