import React, { Component } from 'react';

// local components
import FormNavigation from '../../utils/Component/FormNavigation';
import FormRadio from '../../components/FormRadio';
import FormRadioItem from '../../components/FormRadioItem';
import FormSelect from '../../components/FormSelect';

// types
import { FitActions, FitShoeSizes, FitShoeTypes, FlowRouteProps } from '../../types';

// utils
import Flow from '../../utils/HOC/Flow';

// interfaces
interface State {
  shoeSizeTemp: number | undefined;
}

interface Props extends FlowRouteProps<any> {
  reducer: (action: FitActions, changes: any) => void;
  shoeType?: FitShoeTypes;
  shoeSize?: number | undefined;
}

// consts
const SIZES: FitShoeSizes = {
  M: [6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5, 12.0, 13.0, 14.0, 15.0, 16.0],
  Y: [1, 2, 3, 4, 5, 6],
  K: [8, 9, 10, 11, 12, 13],
};

class FitShoe extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shoeSizeTemp: this.props.shoeSize,
    };
  }

  setType = (shoeType: FitShoeTypes) => {
    this.setState({ shoeSizeTemp: undefined });
    this.props.reducer('SHOE_SET', { shoeSize: undefined, shoeType });
  };

  setSize = (shoeSize: number | undefined) => {
    this.setState({ shoeSizeTemp: shoeSize });
    const { reducer, shoeType } = this.props;
    reducer('SHOE_SET', { shoeSize, shoeType });
  };

  onSubmit = () => {
    this.props.flow!(`${this.props.location.search}`);
  };

  render() {
    const { shoeType, shoeSize } = this.props;

    return (
      <div data-testid="fitflow-shoe" className="mx-auto max-w-sm">
        <h2 className="text-h2-display mb-32 text-center">What is your shoe size?</h2>

        <FormRadio label={'Shoe Type'} darkMode className="mb-16 w-full">
          <FormRadioItem
            label="Mens"
            checked={shoeType === 'M'}
            onChange={() => this.setType('M')}
            className="tracker-btn-fit_shoe-mens-200619-110945"
          />

          <FormRadioItem
            label="Youth"
            checked={shoeType === 'Y'}
            onChange={() => this.setType('Y')}
            className="tracker-btn-fit_shoe-youth-200619-110945"
          />

          <FormRadioItem
            label="Kids"
            checked={shoeType === 'K'}
            onChange={() => this.setType('K')}
            className="tracker-btn-fit_shoe-kids-200619-110945"
          />
        </FormRadio>

        {shoeType && (
          <FormSelect
            data-testid="select-shoe-size"
            combineLabel
            className="w-full"
            label="Shoe Size"
            value={this.state.shoeSizeTemp === undefined ? '-' : this.state.shoeSizeTemp}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              this.setSize(e.target.value === '-' ? undefined : Number(e.target.value))
            }
          >
            <option value="-">-</option>
            {SIZES[shoeType].map((size) => (
              <option key={size} value={size} className={`tracker-btn-fit_shoe-size_${size}-200619-110945`}>
                {size}
              </option>
            ))}
          </FormSelect>
        )}

        {/* Navigation */}
        <FormNavigation className="mt-32" disabled={shoeSize === undefined || shoeSize === null} next={this.onSubmit} />
      </div>
    );
  }
}

export default Flow(FitShoe);
