import { Component } from 'react';
import { observer } from 'mobx-react';
import { format, addDays, setDay } from 'date-fns';
import AddressesStore from '../../stores/AddressesStore';
import { createHTOEvent } from '../../services/Events';
import { checkout } from '../../services/Payments';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { GlobalContextTyping, FlowRouteProps, Item, ProductToMember } from '../../types';
import { AccessContext } from '../../utils/HOC/';
import CardInfo from '../../shared/components/CardInfo';
import { Elements } from 'react-stripe-elements';
import Flow from '../../utils/HOC/Flow';
import { getStringCookie } from '../../utils/utils';
import PreviewStore from '../../stores/look-builder/PreviewStore';
import { eventCreated } from '../../utils/metrics';
import Message from '../../components/Message';
import auth from '../../services/Auth';
import { htoOrdered } from '../../utils/metrics';
import { MemberToggle } from '../../shared/components/Checkout';

const LineItem = ({ costClassName = '', ...props }) => (
  <div className="flex items-end justify-between" data-testid={props['data-testid']}>
    <div className="text-h5">{props.label}</div>
    <div
      className="h-2 grow"
      style={{
        background:
          'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAACCAYAAACQahZdAAAABGdBTUEAALGPC/xhBQAAABZJREFUCB1jePbs2X8QZkACTEhsOBMAFPMHZX2zwFAAAAAASUVORK5CYII=) repeat-x bottom',
      }}
    />
    <div className={costClassName}>{props.cost}</div>
  </div>
);

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  error?: string;
  loading: boolean;
  token: string;
  memberId: string | undefined;
  eventId: string | undefined;
}

const getTemporaryCartFromItems = (items: Item[]): ProductToMember[] => {
  const sortedByPrice = items.sort((a, b) => Number(b.cost) - Number(a.cost));

  return sortedByPrice.map((item) => ({
    isPaidFor: false,
    product: {
      ...item,
      cost: '0.00',
    },
  }));
};

class Payment extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: undefined,
      loading: false,
      token: '',
      memberId: undefined,
      eventId: undefined,
    };
  }

  async componentDidMount() {
    if (PreviewStore.productsAndBundle.length === 0) {
      if (this.props.history.location.pathname.includes('/hto-abr/')) {
        this.props.history.push(`/hto-abr/black-tux-try-on`);
      } else {
        this.props.history.push(`/hto/looks/build?htoFlow=true`);
      }
      return;
    }

    if (AddressesStore.currentAddress.id === undefined) {
      this.props.history.push(`/hto/shipping${this.props.location.search}`);
      return;
    }
  }

  /**
   * Returns a transient member object for use with the look preview
   */
  getTemporaryMember = () => {
    const user = auth.user();

    return {
      customer: {
        firstName: user.firstName!,
        lastName: user.lastName!,
      },
      productToMember: getTemporaryCartFromItems(PreviewStore.productsAndBundle),
    };
  };

  createHTOEvent = async () => {
    try {
      const res = await createHTOEvent(
        format(setDay(new Date(), 2), 'MM/DD/YYYY'),
        `${auth.user().lastName} Home Try-On Trial`,
        'Wedding',
        'Groom',
        PreviewStore.productsAndBundle,
        this.props.history.location.pathname
      );

      const data = await res.json();

      eventCreated({
        startDate: format(setDay(new Date(), 2), 'MM/DD/YYYY'),
        name: `${auth.user().lastName} Home Try-On Trial`,
        eventType: 'Wedding',
        role: 'Groom',
        isTrial: true,
        source: this.props.history.location.pathname,
        id: data.event.id,
        creatorEmail: auth.user().email,
      });

      this.setState({
        eventId: data.event.id,
        memberId: data.member ? data.member.id : undefined,
      });

      htoOrdered();
    } catch (e) {
      throw Error('There was an issue creating your home try on event.');
    }
  };

  getNeedsByDate = () => {
    const proposedDate = addDays(new Date(), 6);
    // If propsed date is the weekend: return following Monday
    if (format(proposedDate, 'dddd') === 'Saturday' || format(proposedDate, 'dddd') === 'Sunday') {
      return format(setDay(proposedDate, 1), 'YYYY-MM-DD');
    } else {
      return format(proposedDate, 'YYYY-MM-DD');
    }
  };

  getMetaData = () => {
    const metadata = JSON.stringify({
      flow: PreviewStore.flow !== undefined ? PreviewStore.flow : undefined,
      campaignId:
        getStringCookie('iterableEmailCampaignId') !== undefined
          ? Number(getStringCookie('iterableEmailCampaignId'))
          : undefined,
    });
    return metadata !== '{}' ? metadata : undefined;
  };

  sendCheckoutRequest = async () => {
    try {
      if (this.state.eventId && this.state.memberId && this.state.token) {
        const res = await checkout({
          accountId: auth.user().id,
          eventId: this.state.eventId,
          eventDate: format(addDays(new Date(), 15), 'MM/DD/YYYY'),
          needsBy: this.getNeedsByDate(),
          shipFirstName: AddressesStore.currentAddress.firstName,
          shipLastName: AddressesStore.currentAddress.lastName,
          shipAddressLine1: AddressesStore.currentAddress.addressLine1,
          shipAddressLine2: '',
          shipAddressCity: AddressesStore.currentAddress.city,
          shipAddressState: AddressesStore.currentAddress.state,
          shipAddressZip: AddressesStore.currentAddress.zip,
          paymentToken: this.state.token,
          customInviteMsg: '',
          promoCode: '',
          saveShipAddress: true,
          rushCost: 0,
          'memberId-0': this.state.memberId,
          waiveDamageWaiverFee: true,
          orderMetaData: this.getMetaData(),
        });
        const data = await res.json();
        if (data.heading.status === 'failure') {
          throw Error(data.heading.error);
        }
      }
    } catch (e) {
      let errorMessage = 'Failed to checkout.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw Error(errorMessage);
    }
  };

  handleTokenSuccess = async (token: string) => {
    this.setState({ token });
    try {
      await this.createHTOEvent();
    } catch (e) {
      let errorMessage = 'Failed to create HTO event.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      this.setState({ error: errorMessage });
      console.error(e);
      return;
    }

    try {
      await this.sendCheckoutRequest();

      PreviewStore.reset();
      this.nextPage();
    } catch (e) {
      let errorMessage = 'Failed to send checkout request.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      this.setState({ error: errorMessage });
      console.error(e);
    }
  };

  handleTokenError = (error: string) => this.setState({ error });

  nextPage = () => {
    this.setState({
      error: undefined,
    });

    this.props.flow!(this.props.location.search);
  };

  render() {
    const temporaryMember = this.getTemporaryMember();

    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-md space-y-32">
            <h1 className="text-h2-display" data-testid="hto-payment-title">
              Card Info
            </h1>

            <div className="space-y-16">
              <h2 className="text-h3 uppercase">100% free, no obligation</h2>

              <div className="space-y-8">
                <h3 className="text-h5 text-gray-dark">You</h3>

                <MemberToggle member={temporaryMember} editable={false} defaultOpen={true} showItemCost={false} />
              </div>
            </div>

            <div className="border border-gray-light">
              <div className="space-y-16 p-32">
                <LineItem
                  cost="$0.00"
                  costClassName="text-gray-dark"
                  data-testid="hto-payment-line-item"
                  label="Free Trial"
                />

                <LineItem
                  cost="$0.00"
                  costClassName="text-gray-dark"
                  data-testid="hto-payment-line-item"
                  label="Shipping"
                />
              </div>

              <div className="border-t border-gray-light bg-gray-lighter p-32">
                <LineItem label="Total" cost="$0.00" data-testid="hto-payment-total" />
              </div>
            </div>

            <p className="text-gray-dark" data-testid="hto-payment-desc">
              We require a valid credit card or debit card to ship your Free Home Try-On. You will not be charged for
              the Free Home Try-On or Shipping. A temporary authorization will be placed on the card; you will only be
              billed if the outfit is not returned.
            </p>

            <Elements>
              <CardInfo
                back={this.props.history.goBack}
                handleTokenError={this.handleTokenError}
                handleTokenSuccess={this.handleTokenSuccess}
                membersToPayFor={[{ id: this.state.memberId }]}
                orderType={'hto'}
              />
            </Elements>

            {this.state.error && <Message type="error" message={this.state.error} />}
          </div>
        </div>
      </>
    );
  }
}

export default Flow(AccessContext(observer(Payment)));
