import React from 'react';
import PropTypes from 'prop-types';
import EnterMeasurementsNav from './EnterMeasurementsNav';
import MeasurementCard from './MeasurementCard';
import TapeMeasureForm from './TapeMeasureForm';
import { measurementsRange } from '../../../../utils/utils';
import Line from '../../../../components/Line';
import Message from '../../../../components/Message';
import AccordionItem from '../../../../components/AccordionItem';

interface Props {
  overarm: string;
  neck: string;
  armLength: string;
  chest: string;
  stomach: string;
  waist: string;
  outseam: string;
  overarmError: string;
  neckError: string;
  armLengthError: string;
  chestError: string;
  stomachError: string;
  waistError: string;
  outseamError: string;
  overarmWarning: string;
  neckWarning: string;
  armLengthWarning: string;
  chestWarning: string;
  stomachWarning: string;
  waistWarning: string;
  outseamWarning: string;
  outOfNormalRangeErrors: string[];
  activeMeasurement: string;
  isSubmitting: boolean;
  saveError: boolean;
  setActiveCard: (value: string) => void;
  setActiveMeasurement: (value: string) => void;
  handleSubmit: () => void;
  handleChange: (key: string, value: string) => void;
  saveMeasurements: () => void;
  resetOutOfNormalRangeErrors: () => void;
}

const EnterMeasurements: React.StatelessComponent<Props> = ({
  overarm,
  neck,
  armLength,
  chest,
  stomach,
  waist,
  outseam,
  overarmError,
  neckError,
  armLengthError,
  chestError,
  stomachError,
  waistError,
  outseamError,
  overarmWarning,
  neckWarning,
  armLengthWarning,
  chestWarning,
  stomachWarning,
  waistWarning,
  outseamWarning,
  outOfNormalRangeErrors,
  activeMeasurement,
  isSubmitting,
  saveError,
  setActiveCard,
  setActiveMeasurement,
  handleSubmit,
  handleChange,
  saveMeasurements,
  resetOutOfNormalRangeErrors,
}) =>
  outOfNormalRangeErrors.length > 0 ? (
    <>
      <p className="mb-16">We just want you to double-check some things based on your other entries:</p>
      <ul>
        {outOfNormalRangeErrors.map((error: string) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
      <p className="mb-8 mt-16">How would you like to proceed?</p>

      <button
        className="tracker-cta-enter_measurements-submit-200619-111519 btn btn-default mb-16 mr-8"
        onClick={() => {
          saveMeasurements();
        }}
      >
        Submit As Is
      </button>
      <button
        className="tracker-cta-enter_measurements-fix-200619-111519 btn btn-info"
        onClick={() => {
          resetOutOfNormalRangeErrors();
        }}
      >
        I&apos;ll Fix It
      </button>
    </>
  ) : (
    <>
      <div className="mb-32">
        <h2 className="text-h2">Enter Measurements</h2>
        <Line />
        <p className="mb-24">
          Save the trip to the tailor by taking your measurements at home. We promise it will take less than ten
          minutes! All you need is a flexible tape measure (available at most department or drug stores), and a friend
          to assist.
        </p>
        <p className="mb-32">Remember, for accuracy, always start with the lowest-numbered end of the tape measure.</p>
        <button
          className="tracker-cta-enter_measurements-edit-200619-111519 btn btn-primary-outline mb-24"
          onClick={() => {
            setActiveCard('fitProfile');
          }}
        >
          Edit Fit Profile
        </button>
        <AccordionItem title="I need a tape measure">
          <TapeMeasureForm />
        </AccordionItem>
      </div>
      <div>
        <EnterMeasurementsNav
          setActiveMeasurement={setActiveMeasurement}
          activeMeasurement={activeMeasurement}
          overarm={overarm}
          neck={neck}
          armLength={armLength}
          chest={chest}
          stomach={stomach}
          waist={waist}
          outseam={outseam}
          overarmError={overarmError}
          neckError={neckError}
          armLengthError={armLengthError}
          chestError={chestError}
          stomachError={stomachError}
          waistError={waistError}
          outseamError={outseamError}
          overarmWarning={overarmWarning}
          neckWarning={neckWarning}
          armLengthWarning={armLengthWarning}
          chestWarning={chestWarning}
          stomachWarning={stomachWarning}
          waistWarning={waistWarning}
          outseamWarning={outseamWarning}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />

        <MeasurementCard
          measurement="neck"
          warning={neckWarning}
          currentValue={neck}
          handleChange={handleChange}
          setActiveMeasurement={setActiveMeasurement}
          nextMeasurment="overarm"
          activeMeasurement={activeMeasurement}
          options={[''].concat(measurementsRange(10, 0.5, 29))}
          error={neckError}
          buttonId="neck-btn"
        >
          <h4 className="text-h4 mb-16 mt-32">You</h4>
          <ol>
            <li>Stand tall with your chin up.</li>
          </ol>

          <h4 className="text-h4 mb-8 mt-32">The person doing the measuring</h4>
          <ol>
            <li>Measure where a shirt collar would touch, just below the Adam’s apple.</li>
            <li>Keep one finger between the neck and the measuring tape.</li>
          </ol>
        </MeasurementCard>

        <MeasurementCard
          measurement="overarm"
          warning={overarmWarning}
          currentValue={overarm}
          handleChange={handleChange}
          setActiveMeasurement={setActiveMeasurement}
          nextMeasurment="chest"
          activeMeasurement={activeMeasurement}
          options={[''].concat(measurementsRange(25.5, 0.5, 100))}
          error={overarmError}
          buttonId="overarm-btn"
        >
          <h4 className="text-h4 mb-8 mt-32">You</h4>
          <ol>
            <li>Let your arms hang naturally at your side.</li>
          </ol>

          <h4 className="text-h4 mb-8 mt-32">The person doing the measuring</h4>
          <ol>
            <li>Wrap the tape around the widest part of the chest and across the shoulder blades on the back.</li>
            <li>Keep the tape straight and parallel to the floor.</li>
            <li>Make sure there is no slack in the tape.</li>
          </ol>
        </MeasurementCard>

        <MeasurementCard
          measurement="chest"
          warning={chestWarning}
          currentValue={chest}
          handleChange={handleChange}
          setActiveMeasurement={setActiveMeasurement}
          nextMeasurment="armLength"
          activeMeasurement={activeMeasurement}
          options={[''].concat(measurementsRange(25.5, 0.5, 100))}
          error={chestError}
          buttonId="chest-btn"
        >
          <h4 className="text-h4 mb-8 mt-32">You</h4>
          <ol>
            <li>Let your arms hang naturally at your side.</li>
            <li>
              <strong>DO NOT</strong> square up your shoulders or puff your chest.
            </li>
            <li>Relax.</li>
          </ol>

          <h4 className="text-h4 mb-16 mt-32">The person doing the measuring</h4>
          <ol>
            <li>Stand tall and raise your arms to the sides so the tape measure can be brought under your armpits.</li>
            <li>Once the tape is in place, place your arms back to your sides and relax.</li>
            <li>
              <strong>DO NOT</strong> square up your shoulders or puff your chest.
            </li>
          </ol>
        </MeasurementCard>

        <MeasurementCard
          measurement="armLength"
          warning={armLengthWarning}
          title="Sleeve"
          currentValue={armLength}
          handleChange={handleChange}
          setActiveMeasurement={setActiveMeasurement}
          nextMeasurment="stomach"
          activeMeasurement={activeMeasurement}
          options={[''].concat(measurementsRange(18, 0.5, 61))}
          error={armLengthError}
          buttonId="arm-btn"
        >
          <h4 className="text-h4 mb-16 mt-32">You</h4>
          <ol>
            <li>Let your arms hang naturally at your side.</li>
            <li>
              <strong>DO NOT</strong> lock your elbows.
            </li>
            <li>Hold your hands in a loose fist.</li>
          </ol>

          <h4 className="text-h4 mb-16 mt-32">The person doing the measuring</h4>
          <ol>
            <li>Place the tape at the most prominent vertebrae at the base of the neck. Hold it there.</li>
            <li>
              Using your other hand, run the tape to the side, across the shoulder, extending to the break of the wrist
              at the wrist bone.
            </li>
          </ol>
        </MeasurementCard>

        <MeasurementCard
          measurement="stomach"
          warning={stomachWarning}
          currentValue={stomach}
          handleChange={handleChange}
          setActiveMeasurement={setActiveMeasurement}
          nextMeasurment="waist"
          activeMeasurement={activeMeasurement}
          options={[''].concat(measurementsRange(17.5, 0.5, 94))}
          error={stomachError}
          buttonId="stomach-btn"
        >
          <h4 className="text-h4 mb-16 mt-32">You</h4>
          <ol>
            <li>Place your index finger on your belly button and leave your finger there.</li>
          </ol>

          <h4 className="text-h4 mb-16 mt-32">The person doing the measuring</h4>
          <ol>
            <li>Wrap the tape completely around the stomach at the widest part of the stomach.</li>
            <li>Be sure to run the tape below the finger that is touching the belly button.</li>
            <li>The tape should be straight and level across the stomach and lower back.</li>
            <li>Overlap the metal end of the tape where the tape comes together and tighten until it is snug.</li>
          </ol>
        </MeasurementCard>

        <MeasurementCard
          measurement="waist"
          warning={waistWarning}
          currentValue={waist}
          handleChange={handleChange}
          setActiveMeasurement={setActiveMeasurement}
          nextMeasurment="outseam"
          activeMeasurement={activeMeasurement}
          options={[''].concat(measurementsRange(17.5, 0.5, 85))}
          error={waistError}
          buttonId="waist-btn"
        >
          <h4 className="text-h4 mb-16 mt-32">You</h4>
          <ol>
            <li>
              Tuck in your shirt and ensure your pants are pulled up to where you naturally prefer to wear them. (We
              recommend keeping them at the hip bone for the best fit, but it&apos;s up to you.)
            </li>
          </ol>

          <h4 className="text-h4 mb-16 mt-32">The person doing the measuring</h4>
          <ol>
            <li>
              Hold one end of the tape measure and reach around the pant waistline, using the top of the pants as a
              guide.
            </li>
            <li>Get as close as possible to the waistline without overlapping with it.</li>
            <li>Pull the tape snug so there is no slack.</li>
          </ol>
        </MeasurementCard>

        <MeasurementCard
          measurement="outseam"
          warning={outseamWarning}
          currentValue={outseam}
          handleChange={handleChange}
          setActiveMeasurement={setActiveMeasurement}
          nextMeasurment="summary"
          activeMeasurement={activeMeasurement}
          options={[''].concat(measurementsRange(26, 0.5, 47))}
          error={outseamError}
          buttonId="outseam-btn"
        >
          <h4 className="text-h4 mb-16 mt-32">You</h4>
          <ol>
            <li>Take off your shoes and tuck in your shirt.</li>
            <li>Ensure your pants are pulled up to where you naturally prefer to wear them.</li>
            <li>
              We recommend keeping pants at the hip bone for the best fit, especially since slacks are generally worn
              higher than casual pants — but it&apos;s up to you.
            </li>
          </ol>

          <h4 className="text-h4 mb-16 mt-32">The person doing the measuring</h4>
          <ol>
            <li>This works best if you are kneeling. Don’t be shy: This is important.</li>
            <li>Hold one end of the tape at the top of the pant waistband and hold it there.</li>
            <li>Extend the tape straight down the side of the leg to where the foot meets the floor.</li>
            <li> Make sure the tape is straight and without slack.</li>
          </ol>
        </MeasurementCard>

        {saveError && (
          <Message type="error">
            <p className="text-sm">
              We ran into an error saving your measurements. Please contact Customer Service at 1-844-726-4889.
            </p>
          </Message>
        )}
      </div>
    </>
  );

EnterMeasurements.propTypes = {
  overarm: PropTypes.string.isRequired,
  neck: PropTypes.string.isRequired,
  armLength: PropTypes.string.isRequired,
  chest: PropTypes.string.isRequired,
  stomach: PropTypes.string.isRequired,
  waist: PropTypes.string.isRequired,
  outseam: PropTypes.string.isRequired,
  overarmError: PropTypes.string.isRequired,
  neckError: PropTypes.string.isRequired,
  armLengthError: PropTypes.string.isRequired,
  chestError: PropTypes.string.isRequired,
  stomachError: PropTypes.string.isRequired,
  waistError: PropTypes.string.isRequired,
  outseamError: PropTypes.string.isRequired,
  overarmWarning: PropTypes.string.isRequired,
  neckWarning: PropTypes.string.isRequired,
  armLengthWarning: PropTypes.string.isRequired,
  chestWarning: PropTypes.string.isRequired,
  stomachWarning: PropTypes.string.isRequired,
  waistWarning: PropTypes.string.isRequired,
  outseamWarning: PropTypes.string.isRequired,
  outOfNormalRangeErrors: PropTypes.array.isRequired,
  activeMeasurement: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  saveError: PropTypes.bool.isRequired,
  setActiveCard: PropTypes.func.isRequired,
  setActiveMeasurement: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveMeasurements: PropTypes.func.isRequired,
  resetOutOfNormalRangeErrors: PropTypes.func.isRequired,
};

export default EnterMeasurements;
